import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-cell-color-picker',
  templateUrl: './cell-color-picker.component.html',
  styleUrls: ['./cell-color-picker.component.scss']
})
export class CellColorPickerComponent implements ICellEditorAngularComp {
  value: string;
  params: any;
  rowData: any;

  constructor() {}

  agInit(params): void {
    this.params = params;
    this.rowData = params.data;
    if (params.value !== null) {
      this.value = params.value;
    }
  }

  onChange(event) {
    this.value = event.target.value;
    this.rowData.color = this.value;
    this.params.context.gridService.cellColorChanged({
      key: this.params.colDef.field,
      data: this.rowData
    });
  }

  onClick(event) {
    event.stopPropagation();
  }

  getValue(): string {
    return this.value;
  }
}
