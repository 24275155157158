import { environment } from '@environments/environment';

export class ManageOrganizationFinancialModelsApiConstants {
  public static userUri = environment.serverUri + `api/${ environment.apiVersion }/organizations`;

  public static findAllOrgModels = (organizationId: string) => {
    return `${ ManageOrganizationFinancialModelsApiConstants.userUri }/${ organizationId }/financial-models/`;
  };

  public static createModel = (organizationId: string) => {
    return `${ ManageOrganizationFinancialModelsApiConstants.userUri }/${ organizationId }/financial-models/`;
  };

  public static updateModel = (organizationId: string, organizationFinancialModelId: string) => {
    return `${ ManageOrganizationFinancialModelsApiConstants.userUri }/${ organizationId }/financial-models/${ organizationFinancialModelId }`;
  };

  public static deleteModel = (organizationId: string, organizationFinancialModelId: string) => {
    return `${ ManageOrganizationFinancialModelsApiConstants.userUri }/${ organizationId }/financial-models/${ organizationFinancialModelId }`;
  };

  public static uploadTemplate = (organizationId: string, organizationFinancialModelId: string) => {
    return `${ ManageOrganizationFinancialModelsApiConstants.userUri }/${ organizationId }/financial-models/${ organizationFinancialModelId }/upload`;
  };
}
