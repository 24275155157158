import { BaseModel } from '@shared/base.model';
import { PROGRAM_PORTFOLIO_MODULE_FLAGS } from './program-portfolio-module-flags.enum';

export class ProgramPortfolioRules extends BaseModel<ProgramPortfolioRules> {
  projectOverviewModuleRequirementFlag: PROGRAM_PORTFOLIO_MODULE_FLAGS;
  businessPlanModuleRequirementFlag: PROGRAM_PORTFOLIO_MODULE_FLAGS;
  dataRoomModuleRequirementFlag: PROGRAM_PORTFOLIO_MODULE_FLAGS;
  portfolioSubmissionModuleRequirementFlag: PROGRAM_PORTFOLIO_MODULE_FLAGS;
  summaryModuleRequirementFlag: PROGRAM_PORTFOLIO_MODULE_FLAGS;

  constructor(rules?: any) {
    super();
    this.projectOverviewModuleRequirementFlag = rules && rules.projectOverviewModuleRequirementFlag || PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
    this.businessPlanModuleRequirementFlag = rules && rules.businessPlanModuleRequirementFlag || PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
    this.dataRoomModuleRequirementFlag = rules && rules.dataRoomModuleRequirementFlag || PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
    this.portfolioSubmissionModuleRequirementFlag = rules && rules.portfolioSubmissionModuleRequirementFlag || PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
    this.summaryModuleRequirementFlag = rules && rules.summaryModuleRequirementFlag || PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
  }
}
