import { ActionButton } from '@shared/ag-grid/component/action-button/action-button';
import { BosCategory } from './bos-category.enum';
import { BosGridSettingsService } from './bos-grid-settings.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EventService } from '@shared/event.service';
import { GenerationDesign } from '@project/detail/generation-design/shared/generation-design.model';
import { GenerationDesignCostItem } from '@project/detail/generation-design/shared/generation-design-cost-item.model';
import { GenerationDesignCostItemService } from '@project/detail/generation-design/shared/generation-design-cost-item.service';
import { GenerationDesignCostSource } from '@project/detail/generation-design/shared/generation-design-cost-source.model';
import { GenerationDesignCostSourceService } from '@project/detail/generation-design/shared/generation-design-cost-source.service';
import { GenerationDesignService } from '@project/detail/generation-design/shared/generation-design.service';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { ProjectService } from '@project/shared/project.service';
import { REFERENCE_TYPE } from '@shared/reference-types';
import { ReferenceService } from '@shared/references.service';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { zip, forkJoin, Subject } from 'rxjs';
import { GridState } from '@shared/ag-grid/gird-state';

@Component({
  selector: 'oes-generation-design-bos',
  templateUrl: './bos.component.html',
  styleUrls: ['./bos.component.scss'],
  providers: [BosGridSettingsService],
})
export class GenerationDesignBosComponent implements OnInit, OnDestroy {
  @Input() generationDesign: GenerationDesign;
  @Input() readOnly: boolean;

  buttons = {
    add: <ActionButton>{
      disabled: false,
      class: 'primary',
      icon: '<i class="fa fa-plus" aria-hidden="true"></i>',
    },
    delete: <ActionButton>{
      disabled: true,
      class: 'destructive',
      icon: '<img src="assets/images/project-page/icn-delete.svg">',
    },
  };
  columnApi: ColumnApi;
  currency: string = 'USD';
  defaultGridState: GridState;
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridState: GridState;
  optionReady = false;
  showSaveCheck = false;
  symbol: string = 'symbol';
  totalBosCost = 0;

  private categoryString = {};
  private costItems: GenerationDesignCostItem[];
  private costSourceList: GenerationDesignCostSource[] = [];
  private defaultValue = {};
  private generationDesignId: string;
  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();
  private projectId: string;

  constructor(
    private _projectService: ProjectService,
    public _bosGridSettingsService: BosGridSettingsService,
    private _referenceService: ReferenceService,
    private _generationDesignCostSourceService: GenerationDesignCostSourceService,
    private _generationDesignService: GenerationDesignService,
    private _generationDesignCostItemService: GenerationDesignCostItemService,
    private _eventService: EventService,
    private _translateService: TranslateService
  ) {
    this.categoryString = this._translateService.instant(
      'generation-design-item.bos.options.category'
    );
    this.defaultValue = {
      category: this.categoryString[BosCategory.Generator],
      count: 1,
      description: 'Item description',
      source: {
        code: 'generation-design-source.enclosure',
        expenseType: 'CAPEX',
        id: 'd43450cd-e64c-40d5-9435-6a8dd4a68d76',
        name: 'Enclosure',
      },
      unitCost: 1,
      units: 'Unit',
    };
  }

  ngOnInit(): void {
    this.getCategoryType();
    this.gridOptions = this._bosGridSettingsService.getGridOptions();
    this.subscribeGenerationDesign();
    this._bosGridSettingsService.readOnly = this.readOnly;
    this.projectId = this._projectService.projectId;
    this.gridStateStorageKey =
      this._bosGridSettingsService.buildGridStateStorageKey(
        'bos',
        this.projectId
      );
    const { currency, symbol } = this._projectService.currencyInfo();
    this.currency = currency;
    this.symbol = symbol;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // ag-grid is ready
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setColumnDefs(this._bosGridSettingsService.getColumnOption());
    this._bosGridSettingsService.setGridApi(params.api, params.columnApi);
    this.defaultGridState =
      this._bosGridSettingsService.buildDefaultGridState();

    if (this.costItems?.length > 0) {
      this.gridApi.showLoadingOverlay();
      this.gridApi.setRowData(this.costItems);
      this._bosGridSettingsService.applyStoredGridState(
        this.gridStateStorageKey,
        this.defaultGridState
      );
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._bosGridSettingsService.storeGridStateByApis(
        this.gridStateStorageKey,
        this.gridApi,
        this.columnApi
      );
    }
  }

  // callback of Action buttons
  clicked(event) {
    if (event === 'add') {
      this.addButton();
    } else if (event === 'delete') {
      this.deleteButton();
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._bosGridSettingsService.exportCsv(this.gridApi, 'bos', true);
        break;
      case GridSubAction.clearFilter:
        this._bosGridSettingsService.clearStoredGridState(
          this.gridStateStorageKey
        );
        break;
    }
  }

  // Callback from rowValueChanged (finished editing a row)
  rowValueChanged(event) {
    if (!this._bosGridSettingsService.readOnly) {
      this.gridApi.refreshCells({ rowNodes: [event.node], force: true });
      this.gridApi.redrawRows();
      const costItem = this.convertSelectOption(event.data);
      if (costItem.id && costItem.id !== '') {
        this.update(costItem);
      } else {
        this.add(costItem);
      }
    }
  }

  // Callback: call this when checkbox status is changed
  selectionChanged($event) {
    const rowCount = this.gridApi.getSelectedNodes().length;
    this.buttons.delete.disabled = rowCount ? false : true;
  }

  // ag-grid callback: to disable button during editing
  cellEditingStarted(event) {
    if (!this._bosGridSettingsService.readOnly) {
      this.buttons.add.disabled = true;
    }
  }

  // ag-grid callback: to enable button during editing
  cellEditingStopped(event) {
    // if (!this._bosGridSettingsService.readOnly) {
    //   this.buttons.add.disabled = false;
    // }
  }

  private subscribeGenerationDesign() {
    // Summary page
    if (this.generationDesign) {
      this.setData(this.generationDesign);
    } else {
      // Generation Design page
      this._generationDesignService.generationDesign$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((generationDesign: GenerationDesign) => {
          this.buttons.add.disabled = false;
          if (generationDesign) {
            this.setData(generationDesign);
          }
        });
    }
  }

  private setData(generationDesign: GenerationDesign) {
    this.generationDesignId = generationDesign.id;
    if (generationDesign.projectGenerationDesignCostItems) {
      this.costItems = generationDesign.projectGenerationDesignCostItems.map(
        (item) => {
          return {
            canAlter: item.canAlter,
            category: this.categoryString[item.category],
            count: item.count,
            description: item.description,
            id: item.id,
            source: new GenerationDesignCostSource(item.source || {}),
            totalCost: item.totalCost,
            unitCost: item.unitCost,
            units: item.units,
          };
        }
      );
      this.calculateTotalBosCost();
      if (this.gridApi) {
        this.gridApi.setRowData(this.costItems);
      }
    }
  }

  private addButton() {
    const newCostItem = new GenerationDesignCostItem(this.defaultValue);
    newCostItem.description =
      newCostItem.description + ' ' + (this.costItems.length + 1);
    // add a row to rowData
    this.costItems.push(newCostItem);
    // add a row to ag-grid
    this.gridApi.updateRowData({ add: [newCostItem] });
    const costItem = this.convertSelectOption(newCostItem);
    this.add(costItem);
    this.buttons.add.disabled = true;
  }

  private deleteButton() {
    const rows = this.gridApi.getSelectedRows();
    this.delete(rows);
    this.buttons.add.disabled = true;
    this.buttons.delete.disabled = true;
  }

  private convertSelectOption(
    data: GenerationDesignCostItem
  ): GenerationDesignCostItem {
    const costItem = { ...data };
    if (data.category) {
      costItem.category = BosCategory[data.category];
    }
    if (data.source) {
      costItem.source = this.costSourceList.find((item) => {
        return item.name === data.source.name;
      });
    }
    costItem.unitCost = Number(data.unitCost);
    costItem.count = Number(data.count);
    return costItem;
  }

  private add(item: GenerationDesignCostItem) {
    this._generationDesignCostItemService
      .create(this.projectId, this.generationDesignId, item)
      .pipe(take(1))
      .subscribe(
        (result) => {
          this._generationDesignService.reloadDesign();
        },
        (error) => {
          this._eventService.error(
            this._translateService.instant(
              'generation-design-item.bos.error.duplicate'
            )
          );
          this.buttons.add.disabled = false;
        }
      );
  }

  private update(item: GenerationDesignCostItem) {
    if (
      item.category &&
      item.count &&
      item.count >= 0 &&
      item.description &&
      item.description !== '' &&
      item.source &&
      item.unitCost &&
      item.unitCost >= 0 &&
      item.units &&
      item.units !== ''
    ) {
      this._generationDesignCostItemService
        .update(this.projectId, this.generationDesignId, item)
        .pipe(take(1))
        .subscribe((result) => {
          this._generationDesignService.reloadDesign();
        });
    }
  }

  private delete(costItems: any[]) {
    const subscribers = costItems
      .filter((item) => item.id)
      .map((costItem: GenerationDesignCostItem) => {
        return this._generationDesignCostItemService.remove(
          this.projectId,
          this.generationDesignId,
          costItem.id
        );
      });
    if (subscribers && subscribers.length > 0) {
      zip(...subscribers)
        .pipe(take(1))
        .subscribe((result) => {
          this._generationDesignService.reloadDesign();
        });
    }
  }

  private calculateTotalBosCost() {
    if (this.costItems && this.costItems.length > 0) {
      this.totalBosCost = this.costItems
        .map((costItem) => costItem.totalCost)
        .reduce((prev, curr) => {
          return prev + (curr || 0);
        }, 0);
    }
  }

  private getCategoryType() {
    forkJoin([
      this._referenceService.getType(
        REFERENCE_TYPE.PROJECT_GENERATION_DESIGN_COST_CATEGORY
      ),
      this._generationDesignCostSourceService.list(),
    ])
      .pipe(take(1))
      .subscribe((result) => {
        this._bosGridSettingsService.categoryStringList = result[0];
        this._bosGridSettingsService.costSourceStringList = result[1];
        this.costSourceList = result[1];
        this.getUnits();
        this.optionReady = true;
      });
  }

  private getUnits() {
    const units = [
      { id: 'unit', code: 'unit' },
      { id: 'meter', code: 'meter' },
      { id: 'feet', code: 'feet' },
      { id: 'customer', code: 'customer' },
      { id: 'kva', code: 'kva' },
      { id: 'kw', code: 'kw' },
      { id: 'year', code: 'year' },
      { id: 'person', code: 'person' },
      { id: 'person-hour', code: 'person-hour' },
    ];
    this._bosGridSettingsService.units = units;
  }
}
