import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormErrorType } from './form-error-type.enum';

@Component({
  selector: 'oes-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss']
})
export class FormErrorMessageComponent implements OnInit, OnChanges {
  @Input() showInvalid = false;
  @Input() showRequired = false;
  @Input() text: string;
  public invalidError: boolean;
  public requiredError: boolean;

  constructor() {
  }

  ngOnInit() {
    this.invalidError = this.showInvalid;
    this.requiredError = this.showRequired;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges) {
      if (simpleChanges.showRequired) {
        this.requiredError = simpleChanges.showRequired.currentValue;
      }
      if (simpleChanges.showInvalid) {
        this.invalidError = simpleChanges.showInvalid.currentValue;
      }
    }
  }
}
