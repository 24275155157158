<ng-container *ngIf="name">
  <div class="detail">

    <!-- Program Name -->
    <div class="detail-item mr-2">
      <div class="title mr-1">
        {{ 'program.column.program-name' | translate }}:
      </div>
      <div
        class="value"
        [ngClass]="{ 'text-red' : getIsPastSubmissionDeadline() }">
        {{ name }}
      </div>
    </div>

    <!-- Submission Deadline -->
    <div class="detail-item mr-2">
      <div class="title mr-1">
        {{ 'program.column.endDateTime' | translate }}:
      </div>
      <div class="value"
           [ngClass]="{ 'text-red' : getIsPastSubmissionDeadline() }">
        {{ submissionDeadline | date: 'dd MMMM yyyy HH:mm' }} <span class="time-zone">({{ timeZone }})</span>
      </div>
    </div>

    <!-- Status -->
    <div class="detail-item mr-2">
      <div class="title mr-1">
        {{ 'project-group-submit.status' | translate }}:
      </div>
      <div class="value"
           [ngClass]="{ 'text-red' : getIsPastSubmissionDeadline() }">
        {{ getStatus() }}
      </div>
    </div>

  </div>
</ng-container>
