import { Component, Input } from '@angular/core';
import { ProjectFinancialModel } from '@project/detail/financials/project-financial-model.model';
import { Project } from '@project/shared/project.model';

@Component({
  selector: 'oes-summary-ci-kpis',
  templateUrl: './summary-ci-kpis.component.html',
  styleUrls: ['./summary-ci-kpis.component.scss']
})
export class SummaryCiKpisComponent {
  @Input() projectFinancialModel: ProjectFinancialModel;
  @Input() project: Project;
  @Input() displayType: 'all' | 'overview';

  constructor() {
  }
}
