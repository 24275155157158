import { Component, Input } from '@angular/core';
import { Evaluation } from '../../evaluation/evaluation.model';
import { ColumnApi, GridOptions } from 'ag-grid-community';
import { CriteriaDisplayGridSettingService } from './criteria-display-grid-setting-service';

@Component({
  selector: 'oes-criteria-display',
  templateUrl: './criteria-display.component.html',
  styleUrls: ['./criteria-display.component.scss']
})
export class CriteriaDisplayComponent {
  @Input() evaluations: Evaluation[];

  columnApi: ColumnApi;
  gridOptions: GridOptions;

  private gridApi: any;

  constructor(private _gridSettingService: CriteriaDisplayGridSettingService) {
    this.gridOptions = this._gridSettingService.getGridOptions();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._gridSettingService.setGridApi(params.api, params.columnApi);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setRowData(this.evaluations);
  }

}
