<ol class="breadcrumb">
  <li *ngFor="let item of prependedCrumbs">
    <a
      [routerLink]="[item.url]"
      [queryParams]="item.params"
      routerLinkActive="router-link-active">
      {{ item.label | translate }}
    </a>
  </li>
  <li *ngFor="let item of breadcrumbItems; let last = last">
    <ng-container *ngIf="!last; else LastChild">
      <a
        [routerLink]="[item.url]"
        [queryParams]="item.params"
        routerLinkActive="router-link-active">
        {{ item.label | translate }}
      </a>
    </ng-container>
    <ng-template #LastChild>
      <p>{{ item.label | translate }}</p>
    </ng-template>
  </li>
</ol>
