import {BaseModel} from '@shared/base.model';
import {ProjectDistributionDesignSources} from './design-sources/project-distribution-design-sources.model';

export class ProjectDistributionDesignItem extends BaseModel<ProjectDistributionDesignItem> {
  canAlter: boolean;
  count: number;
  description: string;
  source: ProjectDistributionDesignSources;
  totalCost: number;
  unitCost: number;
  units: string;
  type: string;
  distributionDesignToolGeneratedFlag: boolean;

  constructor(projectDistributionDesignItemInfo: any) {
    super();
    this.id = projectDistributionDesignItemInfo.id;
    this.canAlter = projectDistributionDesignItemInfo.canAlter;
    this.count = projectDistributionDesignItemInfo.count || 0;
    this.description = projectDistributionDesignItemInfo.description || '-';
    this.totalCost = projectDistributionDesignItemInfo.totalCost;
    this.source = new ProjectDistributionDesignSources(projectDistributionDesignItemInfo.source || {});
    this.units = projectDistributionDesignItemInfo.units || 0;
    this.unitCost = projectDistributionDesignItemInfo.unitCost || 0;
    this.distributionDesignToolGeneratedFlag = projectDistributionDesignItemInfo.distributionDesignToolGeneratedFlag;
  }
}
