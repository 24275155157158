import { Injectable } from '@angular/core';

/*
 * Follows AWS S3 keyName (Object key name) rule.
 * https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
 */
@Injectable({
  providedIn: 'root'
})
export class FileNameCheckerService {

  constructor() {
  }

  public s3Safe(keyName: string): string {
    // Characters That Might Require Special Handling
    // Ignoring "ASCII character ranges 00–1F hex (0–31 decimal) and 7F (127 decimal)"
    let key = '';
    key = keyName.replace(/\&/g, ' ');
    key = key.replace(/\$/g, ' ');
    key = key.replace(/\@/g, ' ');
    key = key.replace(/\=/g, ' ');
    key = key.replace(/\;/g, ' ');
    key = key.replace(/\:/g, ' ');
    key = key.replace(/\+/g, ' ');
    key = key.replace(/\,/g, ' ');
    key = key.replace(/\?/g, ' ');

    // Characters to Avoid
    // Ignoring "Non-printable ASCII characters (128–255 decimal characters)"
    key = key.replace(/\\/g, ' ');
    key = key.replace(/\{/g, ' ');
    key = key.replace(/\}/g, ' ');
    key = key.replace(/\^/g, ' ');
    key = key.replace(/\[/g, ' ');
    key = key.replace(/\]/g, ' ');
    key = key.replace(/\%/g, ' ');
    key = key.replace(/\`/g, ' ');
    key = key.replace(/\"/g, ' ');
    key = key.replace(/\</g, ' ');
    key = key.replace(/\>/g, ' ');
    key = key.replace(/\~/g, ' ');
    key = key.replace(/\#/g, ' ');
    key = key.replace(/\|/g, ' ');
    return key.replace(/  +/g, ' '); // replace multiple spaces to a space
  }
}
