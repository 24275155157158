<button
  [type]="type"
  [disabled]="disabled"
  [name]="name"
  [ngClass]="ngButtonClass"
  [value]="value"
  [autofocus]="autofocus"
  [attr.class]="class"
  [attr.form]="form"
  [attr.formaction]="formaction"
  [attr.formenctype]="formenctype"
  [attr.formmethod]="formmethod"
  [attr.formnovalidate]="formnovalidate"
  [attr.formtarget]="formtarget"
  (click)="handleClick($event)"
>
  <div class="button__content">
    <ng-container *ngIf="leadingIconSrc">
      <div class="button__leading">
        <div class="button__leading-icon">
          <img [src]="leadingIconSrc" role="presentation" />
        </div>
      </div>
    </ng-container>
    <ng-content></ng-content>
    <ng-container *ngIf="trailingIconSrc">
      <div class="button__trailing">
        <div class="button__trailing-icon">
          <img [src]="trailingIconSrc" role="presentation" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!trailingIconSrc && trailingArrow">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99992 3.33337L8.82492 4.50837L13.4749 9.16671H3.33325V10.8334H13.4749L8.82492 15.4917L9.99992 16.6667L16.6666 10L9.99992 3.33337Z" fill="currentColor"/>
      </svg>
    </ng-container>
  </div>
</button>
