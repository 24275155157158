import {BaseModel} from '@shared/base.model';

export class FinanceBusinessPlanRisk extends BaseModel<FinanceBusinessPlanRisk> {
  description: string;
  id: string;

  constructor(financeBusinessPlanRiskInfo: any) {
    super();
    this.description = financeBusinessPlanRiskInfo.description;
    this.id = financeBusinessPlanRiskInfo.id;
  }
}
