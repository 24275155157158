import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';
import { Location } from '@angular/common';
import { StorageMap } from '@ngx-pwa/local-storage';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicHomeService {
  private useHistory = false;
  private storeKey = 'pageLocation';

  constructor(private _router: Router,
              private _location: Location,
              private _storageMap: StorageMap) {
  }

  set url(url: string) {
    this._storageMap.set(this.storeKey, {url: url}).pipe(take(1)).subscribe(() => {});
  }

  public getUrl(): Observable<any> {
    return this._storageMap.get(this.storeKey);
  }

  public saveUrl(useHistory?: boolean) {
    this.useHistory = useHistory;
    if (!this.useHistory) {
      const previousUrlTree = this._router.parseUrl(this._router.url);
      if (previousUrlTree.root?.children?.primary?.segments?.length > 0) {
        const pageLocation = {
          url: previousUrlTree.root.children.primary.segments.map(segment => segment.path).join('/'),
          queryParams: previousUrlTree.queryParams
        };
        // save IndexedDB
        this._storageMap.set(this.storeKey, pageLocation).pipe(take(1)).subscribe(() => {});
      }
    }
  }

  public backTo() {
    if (!this.useHistory) {
      this._storageMap.get(this.storeKey)
      .pipe(take(1))
      .subscribe((previous: any) => {
        if (previous?.queryParams) {
          this._router.navigate([previous.url], {queryParams: previous.queryParams});
        } else {
          this._router.navigate([previous.url]);
        }
      });
    } else {
      this._location.back();
    }
  }
}
