<div class="user-list">
  <div class="top-page-heading">
    <h3>
      {{ isAdminPage
        ? ('main-navigation.admin-users' | translate)
        : ('main-navigation.organization-users' | translate) }}
    </h3>
    <div class="actions">
      <oes-button class="btn btn-primary create-button btn-lg"
                  (click)="openDialog()">{{'buttons.create'| translate}}
      </oes-button>
    </div>
  </div>
  <div class="sub-action-container">
    <oes-grid-sub-action
      *ngIf="gridApi"
      [gridState]="gridState"
      [defaultGridState]="defaultGridState"
      (action)="subAction($event)">
    </oes-grid-sub-action>
  </div>
  <div class="ag-grid-header-sticky" oesAgColumns oesAgHorizontalScroll>
    <ag-grid-angular
      id="adminUserList"
      style="width: auto;"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      [overlayNoRowsTemplate]="'No users in filter'"
      [serverSideFilterOnServer]="true"
      [serverSideSortOnServer]="true"
      (columnMoved)="gridStatusChanged($event)"
      (gridReady)="onGridReady($event)"
      (rowDoubleClicked)="rowDoubleClicked($event)"
      (cellClicked)="cellClicked($event)">
    </ag-grid-angular>
  </div>
</div>


<!-- User Edit -->
<oes-modal-dialog
  [title]="'user-admin.add-header'| translate"
  [submitButtonText]="'buttons.save'| translate"
  [size]="'medium'"
  [allowDelete]="true"
  [deleteButtonText]="'user-admin.delete-user' | translate"
  (close)="cancelCreate()"
  (delete)="softDeleteWarning()"
  (submit)="saveUser()">
  <form [formGroup]="formGroup">
    <!-- organization select -->
    <div class="form-group">
      <label [ngClass]="{'required': systemAdmin}" for="organization">
        {{'user-admin.organization' | translate}}
      </label>
      <ng-container *ngIf="systemAdmin; else NonSystemAdmin">
        <!-- system admin -->
        <oes-dropdown
          [parentFormGroup]="formGroup"
          [items]="organizationOptions"
          [initialSelections]="selectedOrganization"
          [enableUncheckAll]="true"
          [enableSearch]="true"
          (selectionChanged)="selectionChanged($event)">
        </oes-dropdown>
      </ng-container>
      <ng-template #NonSystemAdmin>
        <input
          type="text"
          id="organization"
          class="form-control"
          disabled
          value={{currentUser?.organization?.name}}>
      </ng-template>
    </div>
    <div class="form-group">
      <label class="required" for="emailAddress">{{'user-admin.emailAddress'| translate}}</label>
      <input type="text"
             class="form-control"
             name="emailAddress"
             id="emailAddress"
             placeholder="{{'user-admin.emailAddress'| translate}}"
             formControlName="emailAddress">
    </div>
    <!-- First name -->
    <div class="form-group">
      <label for="firstName">{{'user-admin.firstName'| translate}}</label>
      <input type="text"
             class="form-control"
             name="firstName"
             id="firstName"
             placeholder="{{'user-admin.firstName'| translate}}"
             formControlName="firstName">
    </div>
    <!-- Last name -->
    <div class="form-group">
      <label for="lastName">{{'user-admin.lastName'| translate}}</label>
      <input type="text"
             class="form-control"
             name="lastName"
             id="lastName"
             placeholder="{{'user-admin.lastName'| translate}}"
             formControlName="lastName">
    </div>
    <!-- Active -->
    <div class="form-group d-flex align-items-center">
      <label for="active-flag" class="mr-2">{{'user-admin.active'| translate}}</label>
      <input type="checkbox"
              name="active-flag"
              id="active-flag"
              formControlName="active">
    </div>
    <!-- only for Admin -->
    <ng-container *ngIf="systemAdmin || orgAdmin">
      <label>
        {{'user-admin.roles' | translate}}
      </label>
      <div class="role-list">
        <label
          formArrayName="roles"
          class="pill"
          *ngFor="let role of formGroup?.controls?.roles?.controls; let i = index"
          [ngClass]="{'active': role?.value}">
          <input
            type="checkbox"
            style="display: none;"
            [formControlName]="i">
          {{'user-admin.role-list.' + roles[i].name | translate}}
        </label>
      </div>
    </ng-container>
  </form>
</oes-modal-dialog>

<!-- Delete Warning -->
<oes-modal-dialog
  #deleteUserModal
  [title]="'user-admin.delete-user'| translate"
  [size]="'small'"
  [destructive]="true"
  [submitButtonText]="'user-admin.yes-delete' | translate"
  (submit)="softDeleteUser()">
  {{ 'user-admin.delete-text' | translate }}
</oes-modal-dialog>
