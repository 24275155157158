<oes-breadcrumb></oes-breadcrumb>
<oes-notify
  class="hint"
  [type]="'hint'"
  [iconName]="'fa-lightbulb-o'">
  <span class="hint-content">
    {{ 'vendor-profiles.tooltip' | translate }}
  </span>
</oes-notify>
<oes-organization-list
  *ngIf="organizations?.length >= vendorSize"
  [organizations]="organizations"
  [exportName]="'Vendor List'"
  [profileType]="'vendor'">
</oes-organization-list>
