import {Component, Input, OnChanges, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'oes-product-select',
  templateUrl: 'product-select.component.html',
  styleUrls: ['product-select.component.scss']
})

export class ProductSelectComponent implements OnChanges {
  @Input()
  product: any;
  @Input()
  readonly = false;
  @Output() selectionChange = new EventEmitter<boolean>();
  filteredKeys: string[];

  showButton(key: string): boolean {
    if (this.readonly && !this.product[key]) {
      return false;
    } else {
      return true;
    }
  }

  getImage(key: string): string {
    const imgPrefix = 'assets/images/organization/vendor/icn-';
    return imgPrefix + this.toDashCase(key) + '.svg';
  }

  toDashCase(key: string): string {
    // fix key to match translation & img src
    if (key === 'quoteTurnkeyProvider') {
      key = 'quoteTurnkeySystems';
    }
    // remove 'offer' or 'quote' prefix and convert to lower-dash case.
    return key.substring(5).replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
  }

  isSelected(key: string): boolean {
    if (this.product[key] && !this.readonly) {
      return true;
    } else {
      return false;
    }
  }

  toggleSelection(key: string) {
    this.product[key] = !this.product[key];
    this.selectionChange.emit(true);
  }

  ngOnChanges() {
    this.filteredKeys = [];
    if (this.product) {
      const keys = Object.keys(this.product);
      this.filteredKeys = keys.filter(el => {
        return el.substr(0, 5) === 'offer' || el.substr(0, 5) === 'quote';
      });

      // remove items that are not buttons
      if (this.filteredKeys.indexOf('quoteShipping') >= 0) {
        this.filteredKeys.splice(this.filteredKeys.indexOf('quoteShipping'), 1);
      }
      if (this.filteredKeys.indexOf('quoteThirdParty') >= 0) {
        this.filteredKeys.splice(this.filteredKeys.indexOf('quoteThirdParty'), 1);
      }
    }
  }
}
