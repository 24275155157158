import { Injectable } from '@angular/core';
import { User } from '@user/user.model';
import { ROLE_TYPE } from '@user/role-type';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserAdminApiConstants } from './user-admin-api.constant';

// TODO: a duplicate function with UserService, can we merge?
@Injectable({
  providedIn: 'root'
})
export class UserAdminService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(options: Object): Observable<any> {
    return this.get<any>(UserAdminApiConstants.list(), options, true);
  }

  public createUpdate(user: User): Observable<User> {
    if (user.id) {
      return this.put<User>(UserAdminApiConstants.saveUpdate(user.id), JSON.stringify(user))
              .pipe(
                map(result => new User(result))
              );
    }
    const options = { headers: { 'user-org-id': user.organization.id }};
    return this.post<User>(UserAdminApiConstants.saveUpdate(), JSON.stringify(user), options)
            .pipe(
              map(result => new User(result))
            );
  }

  public softDeleteUser(userId: string): Observable<User> {
    if (userId) {
      return this.delete<User>(UserAdminApiConstants.softDelete(userId))
              .pipe(
                map(result => new User(result))
              );
    }
  }

  public addRoles(userId: string, roleTypes: ROLE_TYPE[]): Observable<User> {
    return this.put<User>(UserAdminApiConstants.addRoles(userId), JSON.stringify({ 'roles': roleTypes }))
            .pipe(
              map(result => new User(result))
            );
  }

  public removeRoles(userId: string, roleTypes: ROLE_TYPE[]): Observable<any> {
    return this.put(UserAdminApiConstants.removeRole(userId), JSON.stringify({ 'roles': roleTypes }))
            .pipe(
              map(result => new User(result))
            );
  }

  public resetPassword(userId: string): Observable<any> {
    return this.put<any>(UserAdminApiConstants.resetPassword(userId), JSON.stringify({}));
  }
}
