"use strict";

var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.set = exports.isOn = void 0;
var version = '6.1.0';
var settings = (_a = {}, _a[version] = {}, _a);
/**
 * Checks if Feature toggle/flag exists and it's on.
 *
 * @param key - Feature toggle/flag key to be checked
 * @param debug - Prints configuration, version and settings for debug purposes
 *
 * @returns Boolean
 *
 */
var isOn = function (key, debug) {
  if (!!debug) {
    // tslint:disable-next-line
    console.log("\n[DEBUG] Execution Time in milliseconds: ".concat(Date.now(), " version: \"").concat(version, "\" key: \"").concat(key, "\" settings: ").concat(JSON.stringify(settings), "\n"));
  }
  return typeof key === 'string' && !!settings[version][key];
};
exports.isOn = isOn;
/**
 * Set feature toggle/flag configuration
 *
 * @param obj - Configuration of your feature toggle/flag
 *
 * @returns void
 *
 */
var set = function (obj) {
  settings[version] = Object.assign({}, settings[version], obj);
};
exports.set = set;