import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'oes-cell-admin-notes',
  templateUrl: './cell-admin-notes.component.html',
  styleUrls: ['./cell-admin-notes.component.scss']
})
export class CellAdminNotesComponent implements ICellRendererAngularComp {
  formGroup: UntypedFormGroup;
  tags = [];

  constructor() { }

  // ICellRendererAngularComp
  agInit(params: any): void {
    // init form
    this.formGroup = new UntypedFormGroup({
      adminNotes: new UntypedFormControl()
    });

    const rows = params.api.getModel()?.rowsToDisplay?.map(item => {
      return item?.data?.adminNotes;
    })
    .filter(item => item); // remove undefined
    // remove dup
    this.tags = Array.from(new Set(rows));

    this.formGroup.controls['adminNotes'].setValue(params?.value, {emitEvent: false});
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  getValue() {
    return this.formGroup?.controls['adminNotes']?.value;
  }
}
