import {BlockRate} from './block-rate.model';
import {TouPeriod} from './tou-period.model';
import {BaseModel} from '@shared/base.model';
import { AssignedLoadItemGroup } from '@project/detail/load/shared/assigned-load-item-group.model';

export class RevenueModelItem extends BaseModel<RevenueModelItem> {
  initialRevenue = 0;
  annualRevenue = 0;
  connectionFee = 0;
  altConnectionFee = 0;
  monthlyFee = 0;
  altMonthlyFee = 0;
  useTariffPlan: boolean;
  tariffType: string;
  standardTariffRate: number;
  altStandardTariffRate: number;
  useTimeOfUsePlan: false;
  adjustmentApplied: number;
  assignedLoadItemGroup: AssignedLoadItemGroup;
  blockRates: Array<BlockRate>;
  touPeriods: Array<TouPeriod>;

  constructor(revenueModelItemInfo: any) {
    super();
    this.id = revenueModelItemInfo.id;
    this.connectionFee = revenueModelItemInfo.connectionFee;
    this.altConnectionFee = revenueModelItemInfo.altConnectionFee;
    this.monthlyFee = revenueModelItemInfo.monthlyFee;
    this.altMonthlyFee = revenueModelItemInfo.altMonthlyFee;
    this.useTariffPlan = revenueModelItemInfo.useTariffPlan;
    this.tariffType = revenueModelItemInfo.tariffType;
    this.adjustmentApplied = revenueModelItemInfo.adjustmentApplied;
    this.standardTariffRate = revenueModelItemInfo.standardTariffRate;
    this.altStandardTariffRate = revenueModelItemInfo.altStandardTariffRate;
    this.useTimeOfUsePlan = revenueModelItemInfo.useTimeOfUsePlan;
    if (revenueModelItemInfo.annualRevenue == null) {
      revenueModelItemInfo.annualRevenue = 0;
    }
    if (revenueModelItemInfo.initialRevenue == null) {
      revenueModelItemInfo.initialRevenue = 0;
    }
    if (revenueModelItemInfo.assignedLoadItemGroup != null) {
      this.assignedLoadItemGroup = new AssignedLoadItemGroup(
        revenueModelItemInfo.assignedLoadItemGroup);
    }
    this.initialRevenue = revenueModelItemInfo.initialRevenue;
    this.annualRevenue = revenueModelItemInfo.annualRevenue;
    // Handle Block Rates and TOU
    this.blockRates = [];
    if (revenueModelItemInfo.blockRates !== undefined) {
      for (let i = 0; i < revenueModelItemInfo.blockRates.length; i++) {
        this.blockRates.push(
          new BlockRate(revenueModelItemInfo.blockRates[i]));
      }
    }
    this.touPeriods = [];
    if (revenueModelItemInfo.touPeriods !== undefined) {
      for (let i = 0; i < revenueModelItemInfo.touPeriods.length; i++) {
        this.touPeriods.push(
          new TouPeriod(revenueModelItemInfo.touPeriods[i]));
      }
    }
  }
}
