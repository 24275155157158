<div class="text-input">
  <label *ngIf="label">
    {{ label }}<span *ngIf="required" class="color-brand">*</span>
  </label>
  <div
    [attr.class]="'text-input__container' + ' ' + containerClass"
    [ngClass]="{'disabled': disabled, isInvalid: isInvalid}"
    [ngStyle]="fullWidth ? {'width': '100%'} : {}"
  >
    <ng-container *ngIf="leadingIconSrc || leadingIconTemplate || leadingText">
      <div class="text-input__leading">
        <div class="text-input__leading-icon">
          <ng-container *ngIf="leadingIconSrc">
            <img [src]="leadingIconSrc" role="presentation" />
          </ng-container>
          <ng-container *ngIf="!leadingIconSrc && leadingIconTemplate">
            <ng-container *ngTemplateOutlet="leadingIconTemplate"></ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="!leadingIconSrc && !leadingIconTemplate && leadingText">
          <div class="text-input__leading-text">
            {{ leadingText }}
          </div>
        </ng-container>
      </div>
    </ng-container>
    <input
      type="text"
      [autofocus]="autofocus"
      [disabled]="disabled"
      [ngClass]="ngInputClass"
      [(ngModel)]="ngInputModel"
      [ngStyle]="ngInputStyle"
      [required]="required"
      [value]="value"
      [attr.aria-describedby]="ariaDescribedBy"
      [attr.aria-invalid]="isInvalid"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledBy"
      [attr.autocomplete]="autocomplete ? autocomplete : null"
      [attr.formControlName]="formControlName"
      [attr.id]="id"
      [attr.list]="list"
      [attr.maxlength]="maxlength"
      [attr.minlength]="minlength"
      [attr.name]="name"
      [attr.placeholder]="placeholder"
      [attr.style]="style"

      (blur)="handleBlur($event)"
      (focus)="handleFocus($event)"
      (input)="handleChange($event.target.value)"
    >
    <ng-container *ngIf="trailingIconSrc || trailingIconTemplate || trailingText">
      <div class="text-input__trailing">
        <div class="text-input__trailing-icon">
          <ng-container *ngIf="trailingIconSrc">
            <img [src]="trailingIconSrc" role="presentation" />
          </ng-container>
          <ng-container *ngIf="!trailingIconSrc && trailingIconTemplate">
            <ng-container *ngTemplateOutlet="trailingIconTemplate"></ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="!trailingIconSrc && !trailingIconTemplate && trailingText">
          <div class="text-input__trailing-text">
            {{ trailingText }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>