<form #assumptionEditorForm="ngForm">

  <section>
    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <label class="required" for="categories">{{'project-group.apply-revenue-model' | translate}}</label>
        <ngx-bootstrap-multiselect
          id="categories"
          name="tierCategoryFilter"
          class="tier-category-filter"
          [options]="tierCategoryOptions"
          [settings]="tierCateggoryFilterSettings"
          [(ngModel)]="selectedTierCategoryIndexes"
          (ngModelChange)="onTierCategoryChange($event)">
        </ngx-bootstrap-multiselect>
        <label for="filter">{{'project-group.customer-names' | translate}}</label>
          <input class="form-control form-control--sm"
                 id="filter"
                 name="filter"
                 placeholder="{{'project-list.filters.keyword' | translate}}"
                 [(ngModel)]="filter.name">
      </div>
  
      <div class="col-xs-12 col-sm-6">{{'project-group.selected-customer-types' | translate}}
        <ul class="customer-types-container">
          <li *ngFor="let customer of getCustomers()">{{customer+'&nbsp;'}}</li>
        </ul>
      </div>
    </div>
  </section>

  <section>
    <div class="fees-container">
      <div>
        <div>{{'currency-type.USD' | translate}}</div>
        <div *ngIf="revenueModel.alternateCurrency != null">
          {{'currency-type.' + revenueModel.alternateCurrency | translate}}
        </div>
      </div>

      <div class="row">
        <div class="form-group col-xs-12 col-sm-6">
          <label for="connection-fee">{{'revenue-model-item.connection-fee'|translate}}</label>
          <input type="number" class="form-control" id="connection-fee"
                  [(ngModel)]="revenueModelItem.connectionFee"
                  name="connection-fee"
                  [disabled]="revenueModel.baseCurrency != 'USD'" />
          <input *ngIf="revenueModel.alternateCurrency != null" type="number" class="form-control" id="alt-connection-fee"
                  [(ngModel)]="revenueModelItem.altConnectionFee"
                  name="alt-connection-fee"
                  [disabled]="revenueModel.baseCurrency == 'USD'" />
        </div>
  
        <div class="form-group col-xs-12 col-sm-6">
          <label for="monthly-fee">{{'revenue-model-item.monthly-fee'|translate}}</label>
          <input type="number" class="form-control" id="monthly-fee"
                  [(ngModel)]="revenueModelItem.monthlyFee"
                  name="monthly-fee"
                  [disabled]="revenueModel.baseCurrency != 'USD'" />
          <input *ngIf="revenueModel.alternateCurrency != null" type="number" class="form-control" id="alt-monthly-fee"
                  [(ngModel)]="revenueModelItem.altMonthlyFee"
                  name="alt-monthly-fee"
                  [disabled]="revenueModel.baseCurrency == 'USD'" />
        </div>
      </div>
    </div>

    <!-- Select Tariff Type -->
    <div class="my-4">
      <h4>{{'revenue-model-item.tariff-type'|translate}}</h4>
      <div class="input-group">
        <span class="mr-4" *ngFor="let tariffType of tariffTypes; let i = index">
          <label for="editor_{{tariffType.id}}" class="input-control--sm input-control--radio">
            {{tariffType.code | translate}}
            <input type="radio"
                  name="tariff-type-{{i}}"
                  id="editor_{{tariffType.id}}"
                  value="{{tariffType.id}}"
                  [(ngModel)]="revenueModelItem.tariffType"
                  (change)="changeTariffType(revenueModelItem.tariffType)"
                  [disabled]="isNotEditable()">
            <span class="input-control__indicator--sm"></span>
          </label>
        </span>
      </div>

      <!-- Set Standard Rate -->
      <div class="standard-rate-entry mt-4" *ngIf="useBlockRateStandard">
        <div>
          <div>{{'currency-type.USD' | translate}}/kWh</div>
          <div *ngIf="revenueModel.alternateCurrency != null">
            {{'currency-type.' + revenueModel.alternateCurrency | translate}}/kWh
          </div>
        </div>
        <div class="form-group" *ngIf="useBlockRateStandard">
          <label for="standard-rate">{{'revenue-model-item.standard-rate'|translate}}</label>
          <input type="number" class="form-control" id="standard-rate"
                  [(ngModel)]="revenueModelItem.standardTariffRate"
                  name="standard-rate"
                  [disabled]="revenueModel.baseCurrency != 'USD'" />
          <input *ngIf="revenueModel.alternateCurrency != null" type="number" 
                  class="form-control" id="alt-standard-rate"
                  [(ngModel)]="revenueModelItem.altStandardTariffRate"
                  name="alt-standard-rate"
                  [disabled]="revenueModel.baseCurrency == 'USD'">
        </div>
      </div>
    </div>

    <!-- Block Rate Entries -->
    <div *ngIf="useBlockRateBlock == true">
      <div>
        <p class="primary">{{'revenue-model-item.block-rate-helper' | translate}}</p>
      </div>
    </div>
    <div *ngIf="useBlockRateBlock == true">
      <div>
        <table width="100%" cellpadding="0">
          <thead>
          <tr>
            <th>{{'revenue-model-item.min-cum-energy'|translate}}</th>
            <th>{{'revenue-model-item.max-cum-energy'|translate}}</th>
            <th>{{'revenue-model-item.rate'|translate}}</th>
            <th *ngIf="revenueModel.alternateCurrency != null">{{revenueModel.alternateCurrency}} per kWh
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <!-- Existing Blocks -->
          <tr *ngFor="let blockRate of revenueModelItem.blockRates;let index=index">
            <td class="td-border">
              <div class="input-group">
                <input type="number" class="form-control idx-{{index}}"
                        [(ngModel)]="revenueModelItem.blockRates[index].minimum"
                        name="{{'minimum'+index}}"
                        disabled>
              </div>
            </td>
            <td class="td-border">
              <div class="input-group">
                <input type="number" class="form-control idx-{{index}}"
                        [(ngModel)]="revenueModelItem.blockRates[index].maximum"
                        name="{{'maximum'+index}}" type="number"
                        [min]="revenueModelItem.blockRates[index].minimum + 1"
                        (focus)="handleFocus('.idx-' + index)"
                        (blur)="handleBlur('.idx-' + index); validateBlockRateData(); updateMin(index)">
              </div>
            </td>
            <td class="td-border">
              <div class="input-group ">
                <input type="number" class="form-control idx-{{index}}"
                        [(ngModel)]="revenueModelItem.blockRates[index].rate"
                        (focus)="handleFocus('.idx-' + index)"
                        (blur)="handleBlur('.idx-' + index)"
                        name="{{'rate'+index}}" type="number"
                        [disabled]="revenueModel.baseCurrency != 'USD'">
              </div>
            </td>
            <td class="td-border" *ngIf="revenueModel.alternateCurrency != null">
              <div class="input-group ">
                <input type="number" class="form-control idx-{{index}}"
                        [(ngModel)]="revenueModelItem.blockRates[index].altRate"
                        (focus)="handleFocus('.idx-' + index)"
                        (blur)="handleBlur('.idx-' + index)"
                        name="{{'alt-rate'+index}}" type="number"
                        [disabled]="revenueModel.baseCurrency == 'USD'">
              </div>
            </td>
            <td class="pl-2" *ngIf="index === (revenueModelItem.blockRates.length - 1)">
              <i (click)="removeBlock(index)" class="fa fa-trash"></i>
            </td>
          </tr>
          <tr *ngIf="useBlockRateBlock === true">
            <td colspan="3">
              <div class="add-line-item">
                <button type="button" class="btn btn-primary"
                        (click)="addBlock()">{{'revenue-model-item.add-block'|translate}}
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <hr class="mb-4 brand-rule" />
    <!-- Select Time of Use Plan -->
    <div>
      <div>
        <h4>{{'revenue-model-item.set-time-of-use-plan'|translate}}</h4>
        <div class="d-flex">
          <div class="mr-4">
            <label for="editorTimeOfUsePlanNone" class="input-control--sm input-control--radio">
              {{'revenue-model-item.off' | translate}}
              <input type="radio" name="set-time-of-use-plan-off" id="editorTimeOfUsePlanNone" [value]="false"
                     [(ngModel)]="revenueModelItem.useTimeOfUsePlan"
                     (change)="changeTouSelection(false)"
                     [disabled]="isNotEditable()"/>
              <span class="input-control__indicator--sm"></span>
            </label>
          </div>
          <div>
            <label for="editorTimeOfUsePlanStandard" class="input-control--sm input-control--radio">
              {{'revenue-model-item.standard-plan' | translate}}
              <input type="radio"
                     name="set-time-of-use-plan-standard"
                     id="editorTimeOfUsePlanStandard"
                     [value]="true"
                     [(ngModel)]="revenueModelItem.useTimeOfUsePlan"
                     (change)="changeTouSelection(true)"
                     [disabled]="isNotEditable()"/>
              <span class="input-control__indicator--sm"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- List/Add Time of Use -->
    <div *ngIf="revenueModelItem.useTimeOfUsePlan">
      <div>
        <p class="primary">{{'revenue-model-item.tou-helper' | translate}}</p>
      </div>
    </div>

    <div *ngIf="revenueModelItem.useTimeOfUsePlan">
      <div>
        <table width="100%" cellpadding="0">
          <tr>
            <th>{{'revenue-model-item.start-time'|translate}}</th>
            <th>{{'revenue-model-item.end-time'|translate}}</th>
            <th>{{'revenue-model-item.modifier'|translate}}</th>
            <th></th>
          </tr>
          <tr *ngFor="let touPeriod of revenueModelItem.touPeriods;let index2=index">
            <td class="td-border">
              <div class="input-group">
                <input type="number" class="form-control idx2-{{index2}}"
                        [(ngModel)]="revenueModelItem.touPeriods[index2].start"
                        name="{{'start'+index2}}"
                        [min]="index2 === 0 ? 0 : revenueModelItem.touPeriods[index2 - 1].end"
                        max="23"
                        (focus)="handleFocus('.idx2-' + index2)"
                        (blur)="handleBlur('.idx2-' + index2); validateTOUData()">
              </div>
            </td>
            <td class="td-border">
              <div class="input-group">
                <input type="number" class="form-control idx2-{{index2}}"
                        [(ngModel)]="revenueModelItem.touPeriods[index2].end"
                        name="{{'end'+index2}}"
                        [min]="revenueModelItem.touPeriods[index2].start + 1 || 1"
                        max="24"
                        (focus)="handleFocus('.idx2-' + index2)"
                        (blur)="handleBlur('.idx2' + index2); validateTOUData()">
              </div>
            </td>
            <td class="td-border">
              <div class="input-group">
                <input type="number" class="form-control idx2-{{index2}}"
                        [(ngModel)]="revenueModelItem.touPeriods[index2].modifier"
                        name="{{'modifier'+index2}}"
                        (focus)="handleFocus('.idx2-' + index2)"
                        (blur)="handleBlur('.idx2-' + index2)">
              </div>
            </td>
            <td>
              <i (click)="removeTouPeriod(index2)" class="fa fa-trash pl-2"></i>
            </td>
          </tr>
          <tr *ngIf="revenueModelItem.useTimeOfUsePlan === true">
            <td colspan="3">
              <div class="add-line-item">
                <button type="button" class="btn btn-primary"
                        (click)="addTouPeriod()">{{'revenue-model-item.add-time-period'|translate}}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</form>

