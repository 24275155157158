"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var builder_ejs_1 = require("./builder.ejs");
var form_ejs_1 = require("./form.ejs");
exports.default = {
  form: form_ejs_1.default,
  builder: builder_ejs_1.default
};