<div
  bsModal
  #modalTemplate="bs-modal"
  class="bulk-upload"
  [config]="config">
  <div class="modal-header d-flex flex-row justify-content-between">
    <h4 class="modal-title">{{'project-list.bulk-upload.title' | translate}}</h4>
    <button
      type="button"
      class="close"
      (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body project-group-action-body">
    <div class="d-flex flex-column">
      <div class="panels d-flex flex-row justify-content-between">
        <div class="panel d-flex flex-row align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 39 37"><rect fill="#ff7f00" width="33.4" height="5.6" /><rect y="8" fill="#ff7f00" width="15.3" height="5.6" /><rect y="16" fill="#ff7f00" width="15.3" height="5.6" /><rect y="24" fill="#ff7f00" width="15.3" height="5.6" /><rect x="18" y="8" fill="#ff7f00" width="15" height="5.6" /><path fill="#ff7f00" d="M27.5,14c-2.4,0-4.6,0.7-6.5,2h-3v3c-1.3,1.8-2,4.1-2,6.5C16,31.8,21.2,37,27.5,37C33.8,37,39,31.8,39,25.5C39,19.2,33.8,14,27.5,14z M32,27.6l-4.5,4.5L23,27.6c-0.3-0.3-0.3-0.9,0-1.3c0.3-0.3,0.9-0.3,1.3,0l2.4,2.4v-8c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v8l2.3-2.3c0.3-0.3,0.9-0.3,1.3,0C32.3,26.7,32.3,27.3,32,27.6z" /></svg>
          <div class="bulk-upload-text-block">{{'project-list.bulk-upload.download-templates' | translate}}</div>
        </div>
        <div class="panel d-flex flex-row align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 41 36"><polygon fill="#ff7f00" points="19.7,25.8 26,32.2 35.4,22.7 29.1,16.3 	"/><path fill="#ff7f00" d="M16.7,34c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l6.3-1.8l-5.8-5.8L16.7,34z"/><path fill="#ff7f00" d="M40.1,16.6l-4.9-5c-0.4-0.4-1-0.4-1.4,0l-1.3,1.3l-2,2l6.3,6.4l3.2-3.3c0.2-0.2,0.3-0.4,0.3-0.7C40.4,17,40.3,16.7,40.1,16.6z"/><rect fill="#ff7f00" width="33.4" height="5.6"/><rect y="8.5" fill="#ff7f00" width="15.3" height="5.6"/><rect y="16.9" fill="#ff7f00" width="15.3" height="5.6"/><rect y="25.4" fill="#ff7f00" width="15.3" height="5.6"/><polygon fill="#ff7f00" points="33.4,8.5 18.1,8.5 18.1,14.1 28.5,14.1 	"/><polygon fill="#ff7f00" points="18.1,22.6 20.6,22.6 26.2,16.9 18.1,16.9 	"/></svg>
          <div class="bulk-upload-text-block">{{'project-list.bulk-upload.enter-your-data' | translate}}</div>
        </div>
        <div class="panel d-flex flex-row align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 38 34"><path fill="#ff7f00" d="M27.7,11.3c2.7,0,5.1,0.9,7.1,2.5V3.2c0-0.6-0.2-1.2-0.5-1.7L17.8,12c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1L0.5,1.6C0.2,2.1,0,2.6,0,3.2V21c0,1.8,1.4,3.2,3.2,3.2h12.7c0-0.3,0-0.5,0-0.8C15.8,16.8,21.2,11.3,27.7,11.3z"/><path fill="#ff7f00" d="M33.2,0.4c-0.5-0.3-1-0.4-1.5-0.4H3.2C2.6,0,2.1,0.2,1.7,0.4l15.8,10L33.2,0.4z"/><path fill="#ff7f00" d="M27.7,13c-5.7,0-10.3,4.7-10.3,10.5S22,34,27.7,34S38,29.3,38,23.5S33.4,13,27.7,13z M33.5,21.4l-6.6,7.4l-4.9-5c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l3.7,3.8l5.5-6.2c0.3-0.3,0.8-0.4,1.1-0.1C33.7,20.5,33.8,21,33.5,21.4z"/></svg>
          <div class="d-flex flex-column bulk-upload-text-block">
            <div>{{'project-list.bulk-upload.email.header' | translate}}</div>
            <a href="mailto:{{'support-at' | translate}}">{{'support-at' | translate}}</a>
            <div>{{'project-list.bulk-upload.email.footer' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="template-download">
        <h5>{{'project-list.bulk-upload.excel-template.title' | translate}}:</h5>
      </div>

      <div class="new-project category d-flex flex-row align-items-start">
        <img src="assets/images/shared/bulk-upload/icn-new-project.svg">
        <div class="d-flex flex-column">
          <h6 class="title">{{'project-list.bulk-upload.excel-template.new-project.title' | translate}}</h6>
          <div>
            {{'project-list.bulk-upload.excel-template.use-this' | translate}}
            {{'project-list.bulk-upload.excel-template.new-project.description' | translate}}
          </div>
          <a oesTemplateDownload [fileName]="'template_projects.xlsx'">
            {{'project-list.bulk-upload.excel-template.new-project.link' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer d-flex flex-row justify-content-end">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="bsModalRef.hide()">
      {{'buttons.close' | translate}}
    </button>
  </div>
</div>
