import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private activeRequests = 0;
  private currentProgress = 0;
  private debounceTimer: any;
  private progressTimer: any;

  private readonly minProgress = 3;
  private readonly minStep = 0.5;
  private readonly slowStep = 0.3;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private progressSubject = new BehaviorSubject<number>(0);
  progress$ = this.progressSubject.asObservable();

  private spinnerSubject = new BehaviorSubject<boolean>(false);
  spinner$ = this.spinnerSubject.asObservable();

  setLoading(isLoading: boolean) {
    this.loadingSubject.next(isLoading);
  }

  setSpinner(isSpinner: boolean) {
    this.spinnerSubject.next(isSpinner);
  }

  startProgress() {
    this.activeRequests++;
    if (this.activeRequests === 1) {
      if (this.progressTimer) {
        return;
      }

      this.currentProgress = this.minProgress;
      this.progressSubject.next(this.currentProgress);

      this.progressTimer = timer(0, 150).subscribe(value => {
        const progressValue = this.calculateProgress(value);

        if (progressValue > this.currentProgress + this.minStep) {
          this.currentProgress = progressValue;
          this.progressSubject.next(this.currentProgress);
        }

        if (this.currentProgress >= 85 && this.currentProgress < 100) {
          this.currentProgress += this.slowStep;
          this.progressSubject.next(this.currentProgress);
        }
      });

      this.setLoading(true);
    }

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  }

  completeProgress() {
    this.activeRequests--;

    if (this.activeRequests === 0) {
      if (this.progressTimer) {
        this.progressTimer.unsubscribe();
        this.progressTimer = null; // Ensure cleanup
      }
      this.currentProgress = 100;
      this.progressSubject.next(this.currentProgress);

      this.debounceTimer = setTimeout(() => {
        this.setLoading(false);
        this.currentProgress = 0;
        this.progressSubject.next(this.currentProgress);
      }, 300);
    }
  }

  private calculateProgress(value: number): number {
    if (value <= 27) {
      return Math.min(85, this.minProgress + value * 3);
    } else {
      return 85;
    }
  }
}
