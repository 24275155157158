<oes-breadcrumb></oes-breadcrumb>

<div class="page-container">
  <div class="page-top">
    <h1>{{ 'procurement.home.heading-1' | translate }} <span>{{ 'procurement.home.heading-2' | translate }}.<span class="crayon-underline-1"></span><span class="crayon-underline-2"></span></span></h1>
    <h4>{{ 'procurement.home.subheading' | translate }}.</h4>
    <a href="https://share.hsforms.com/1xMFHlniWTx-azQyzYBCdEw1f4zp" target="_blank">
      <button class="btn btn-primary">{{ 'procurement.home.join-the-club' | translate }}<img src="./assets/icons/line-icons/shared/white/arrow-narrow-down.svg" /></button>
    </a>
  </div>

  <hr class="horizontal-line" />

  <h2 class="section-heading">{{ 'procurement.home.join-to-access' | translate }}</h2>
  <ng-container *ngIf="iconCardItems">
    <oes-icon-cards
      [content]="iconCardItems"
      [maxCardsPerRow]="4"
      [headingSize]="20"
      [bodySize]="16"
    ></oes-icon-cards>
  </ng-container>

  <h2 class="section-heading">{{ 'procurement.home.product-highlights' | translate }}</h2>
  <ng-container *ngIf="imageCardContent">
    <oes-image-cards
      [content]="imageCardContent"
      [maxCardsPerRow]="4"
      [headingSize]="18"
    ></oes-image-cards>
  </ng-container>

  <h2 class="section-heading text-center">{{ 'procurement.home.partners' | translate }}</h2>
  <div class="partners">
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/sustain-logo.svg" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/sparkmeter-logo.svg" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/pylontech-logo.svg" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/growatt-logo.svg" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/jinko-logo.svg" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/atess-logo.svg" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/pace-partner-logos/morningstar-logo.svg" />
  </div>

  <div class="get-in-touch-box">
    <h2>{{ 'procurement.home.get-in-touch' | translate }}</h2>
    <h4>{{ 'procurement.home.fill-in-form' | translate }}.</h4>
    <a href="https://share.hsforms.com/1xMFHlniWTx-azQyzYBCdEw1f4zp" target="_blank">
      <button class="btn btn-primary">{{ 'procurement.home.join-the-club' | translate }}</button>
    </a>
  </div>
</div>