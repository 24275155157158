<div class="key-with-values">
  <div class="keys">
    <div *ngFor="let key of keys; let i = index;">
      <p><span class="key-color" [ngStyle]="{ 'backgroundColor' : chartColors[i] }"></span>{{ key }}</p>
    </div>
  </div>

  <div class="currency-values">
    <div *ngFor="let currencyValue of currencyValues;">
      <ng-container *ngIf="currencyValue && currencyValue !== 'null'">
        <ng-container *ngIf="currency === 'USD'; else OtherCurrencyValue">
          <p class="text-right">{{ (currencyValue || 0) | currency:'USD':'symbol':'1.0-0' }}</p>
        </ng-container>
        <ng-template #OtherCurrencyValue>
          <p class="text-right">{{ (currencyValue || 0) | number: '1.0-0' }} {{ currency }}</p>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="percentages">
    <div *ngFor="let percentage of percentages;">
      <ng-container *ngIf="percentage !== 'null'">
        <p>{{ (percentage || 0) | percent : '1.1-1' }}</p>
      </ng-container>
    </div>
  </div>
</div>
