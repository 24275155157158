import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LanguageService } from '@global/services/language.service';
import { FormatterService } from './formatters.service';
import { UserService } from '@user/user.service';
import { GridStateComparatorService } from './component/grid-state-comparator.service';

/**
 * To inject multiple services via one service
 */
@Injectable({
  providedIn: 'root'
})
export class AgGridServicesWrapperService {

  constructor(public _storageMap: StorageMap,
              public _translateService: TranslateService,
              public _languageService: LanguageService,
              public _formatterService: FormatterService,
              public _userService: UserService,
              public _gridStateComparatorService: GridStateComparatorService) {
  }
}
