<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="'basic'">
    <ng-container *ngTemplateOutlet="EvaluationBasic"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'full'">
    <ng-container *ngTemplateOutlet="EvaluationFull"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'edit'">
    <ng-container *ngTemplateOutlet="EvaluationEdit"></ng-container>
  </ng-container>
</ng-container>

<!-- Header -->
<ng-template #EvaluationHeader>
  <div class="eval-question-header">
    <div>
      <h5>{{ evaluation?.evaluationConfig?.name }}</h5>
      <p>{{ evaluation?.evaluationConfig?.description }}</p>
    </div>
    <div class="toggle" (click)="toggleStateView()">
      <img
        [ngClass]="{ 'basic' : state === 'basic' }"
        src="/assets/icons/line-icons/shared/black/chev-down.svg"
        width="10px" />
    </div>
  </div>
</ng-template>

<!-- Basic -->
<ng-template #EvaluationBasic>
  <div class="eval-question-container">
    <ng-container *ngTemplateOutlet="EvaluationHeader"></ng-container>
    <ng-container *ngIf="type === EvaluationQuestionType.SCORE">
      <h5>{{ 'program.evaluation.score' | translate }}: {{ evaluation?.scoreValue || 0 }}/{{ evaluation?.evaluationConfig?.rangeEnd }}</h5>
    </ng-container>
    <ng-container *ngIf="type === EvaluationQuestionType.PASS_FAIL">
      <h5>{{ 'program.evaluation.score' | translate }}: {{ 'program.evaluation.' + (evaluation?.boolValue !== null ? evaluation?.boolValue?.toString() : null) | translate }}</h5>
    </ng-container>
  </div>
</ng-template>

<!-- Full -->
<ng-template #EvaluationFull>
  <div class="eval-question-container">
    <ng-container *ngTemplateOutlet="EvaluationHeader"></ng-container>
    <ng-container *ngIf="type === EvaluationQuestionType.SCORE">
      <h5 class="full-subhead">{{ 'program.evaluation.score-cap' | translate }} ({{ evaluation?.evaluationConfig?.rangeStart }}-{{ evaluation?.evaluationConfig?.rangeEnd }})</h5>
      <h4>{{ evaluation?.scoreValue }}</h4>
    </ng-container>
    <ng-container *ngIf="type === EvaluationQuestionType.PASS_FAIL">
      <h5 class="full-subhead">{{ 'program.evaluation.score-cap' | translate }}</h5>
      <h4>{{ 'program.evaluation.' + (evaluation?.boolValue !== null ? evaluation?.boolValue?.toString() : null) | translate }}</h4>
    </ng-container>
    <h5 class="full-subhead">{{ 'program.evaluation.comments-cap' | translate }}</h5>
    <p>{{ evaluation?.comments }}</p>
    <div class="eval-question-footer">
      <p class="light">{{ 'program.evaluation.last-updated' | translate }}: {{ evaluation?.updated ? (evaluation?.updated | date: 'dd MMMM HH:mm') : ('program.evaluation.null' | translate) }}</p>
    </div>
    <a (click)="toggleStateEdit()" class="edit-eval-button">{{ 'program.evaluation.edit-eval' | translate }}</a>
  </div>
</ng-template>

<!-- Edit -->
<ng-template #EvaluationEdit>
  <div class="eval-question-container">
    <ng-container *ngTemplateOutlet="EvaluationHeader"></ng-container>
    <form *ngIf="formGroup" [formGroup]="formGroup">

      <!-- Pass/Fail -->
      <ng-container *ngIf="type === EvaluationQuestionType.PASS_FAIL">
        <label for="boolValue">
          {{ 'program.evaluation.pass-fail-cap' | translate }}
        </label>
        <oes-dropdown
          [parentFormGroup]="formGroup"
          [items]="passFailItems"
          [initialSelections]="boolValueInitial"
          [controlName]="'boolValue'"
          name="boolValue"
          [required]="false"
          [enableSearch]="false"
          [enableCheckAll]="false"
          [enableUncheckAll]="false"
          [selection]="'single'">
        </oes-dropdown>
      </ng-container>

      <!-- Score -->
      <ng-container *ngIf="type === EvaluationQuestionType.SCORE">
        <label for="scoreValue">
          {{ 'program.evaluation.score-cap' | translate }} ({{ evaluation?.evaluationConfig?.rangeStart }}-{{ evaluation?.evaluationConfig?.rangeEnd }})
        </label>
        <input
          type="number"
          class="form-control"
          id="scoreValue"
          name="scoreValue"
          formControlName="scoreValue" />
      </ng-container>

      <!-- Comments -->
      <label for="comments">
        {{ 'program.evaluation.comments-cap' | translate }}
      </label>
      <textarea
        type="text"
        class="form-control"
        id="comments"
        name="comments"
        rows="4"
        formControlName="comments" ></textarea>
    </form>

    <div class="form-buttons">
      <button class="btn btn-secondary" (click)="toggleStateEdit()">{{ 'buttons.cancel' | translate }}</button>
      <button class="btn btn-primary" (click)="saveEvaluation()" [disabled]="formGroup.invalid">{{ 'buttons.save-changes' | translate }}</button>
    </div>
  </div>
</ng-template>




