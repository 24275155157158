<div
  bsModal
  #modalTemplate="bs-modal"
  class="unsaved-changes-dialog"
  [config]="{animation: false}">
  <div class="modal-header d-flex flex-row justify-content-between">
    <h4 class="modal-title">{{'unsaved-changes-modal.title' | translate}}</h4>
    <button
      type="button"
      class="close"
      [disabled]="buttonDisabled"
      (click)="clickButton(status.CANCEL)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="message">
      {{message}}
    </div>
    <div *ngIf="!message">
      {{'unsaved-changes-modal.body' | translate}}
      <strong>{{pageName}}</strong>
      {{'unsaved-changes-modal.question' | translate}}
    </div>
  </div>

  <div class="modal-footer d-flex flex-row justify-content-end">
    <button *ngFor="let button of buttons"
      type="button"
      class="btn btn-small"
      [ngClass]="getButtonClass(button)"
      [disabled]="buttonDisabled"
      (click)="clickButton(button)">
      <span *ngIf="!buttonDisabled; else Loading">
        {{'buttons.' + button | translate}}
      </span>
      <ng-template #Loading>     
        <span *ngIf="button === status.CALCULATE; else NoCalButton" class="loading-dots">
          {{'buttons.calculating' | translate}}
        </span>
        <ng-template #NoCalButton>
          {{'buttons.' + button | translate}}
        </ng-template>
      </ng-template>
    </button>
  </div>
</div>
