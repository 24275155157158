import {BaseModel} from '@shared/base.model';

export class ProgramPreQualificationCriteria extends BaseModel<ProgramPreQualificationCriteria> {
  name: string;

  constructor(programPreQualificationCriteria) {
    super();
    this.id = programPreQualificationCriteria.id;
    this.name = programPreQualificationCriteria.name;
  }
}
