import {BaseModel} from '@shared/base.model';

export class ProjectCostSources extends BaseModel<ProjectCostSources> {
  code: string;
  expenseType: string;
  name: string; // it was number.
  projectCostType: string;

  constructor(projectCostSourcesInfo: any) {
    super();
    this.id = projectCostSourcesInfo.id;
    this.code = projectCostSourcesInfo.code;
    this.expenseType = projectCostSourcesInfo.expenseType;
    this.name = projectCostSourcesInfo.name;
    this.projectCostType = projectCostSourcesInfo.projectCostType;
  }
}
