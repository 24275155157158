import { environment } from '@environments/environment';

export class ProjectApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;
  public static project = {
    detail: (id: string) => {
      return `${ProjectApiConstants.projects}/${id}`;
    },
    find: () => {
      return `${ProjectApiConstants.projects}`;
    },
    myList: () => {
      return `${ProjectApiConstants.projects}/list`;
    },
    myListByProgram: (programId: string) => {
      return `${ProjectApiConstants.projects}/program/${programId}`;
    },
    sharedList: () => {
      return `${ProjectApiConstants.projects}/shared`;
    },
    sharedListByProgram: (programId: string) => {
      return `${ProjectApiConstants.projects}/shared/program/${programId}`;
    },
    sharedMilestonesByProgram: (programId: string) => {
      return `${ProjectApiConstants.projects}/shared/program/${programId}/milestones`;
    },
    projectMilestoneMap: () => {
      return `${ProjectApiConstants.projects}/milestones`;
    },
    sharedProjectMilestoneMap: () => {
      return `${ProjectApiConstants.projects}/shared/milestones`;
    },
    save: (id: string) => {
      return `${ProjectApiConstants.projects}/${id}`;
    },
    updateWithSideEffects: (id: string) => {
      return `${ProjectApiConstants.projects}/${id}/updateWithSideEffects`;
    },
    create: () => {
      return `${ProjectApiConstants.projects}`;
    },
    customerSummary: () => {
      return `${ProjectApiConstants.projects}/customers`;
    },
    financialOverview: () => {
      return `${ProjectApiConstants.projects}/overview`;
    },
    sample: () => {
      return `${ProjectApiConstants.projects}/sample`;
    },
    copy: (id: string) => {
      return `${ProjectApiConstants.projects}/${id}/copy`;
    },
    exportFinancialModel: () => {
      return `${ProjectApiConstants.projects}/financial-models/export`;
    },
    remove: (id: string) => {
      return `${ProjectApiConstants.projects}/${id}`;
    },
  };
}
