import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, Validator,
         AbstractControl, ValidationErrors } from '@angular/forms';
import { FinanceBusinessPlan } from '@finance/developer-portfolio/business-plan/finance-business-plan.model';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'oes-business-plan-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessPlanConstructionComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessPlanConstructionComponent),
      multi: true
    }
  ]
})
export class BusinessPlanConstructionComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() financeBusinessPlan: FinanceBusinessPlan;
  @Input() isPastSubmissionDeadline = false;

  public constructionFormGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.constructionFormGroup = new UntypedFormGroup({
      constructionDescription: new UntypedFormControl(''),
      maintenanceDescription: new UntypedFormControl(''),
      monitoringDescription: new UntypedFormControl(''),
      designSpecStatus: new UntypedFormControl(
        {value: this.financeBusinessPlan.designSpecStatus, disabled: this.isPastSubmissionDeadline},
        null),
      budgetEstimatedStatus: new UntypedFormControl(
        {value: this.financeBusinessPlan.budgetEstimatedStatus, disabled: this.isPastSubmissionDeadline},
        null),
      detailedQuoteStatus: new UntypedFormControl(
        {value: this.financeBusinessPlan.detailedQuoteStatus, disabled: this.isPastSubmissionDeadline},
        null),
      poIssuedStatus: new UntypedFormControl(
        {value: this.financeBusinessPlan.poIssuedStatus, disabled: this.isPastSubmissionDeadline},
        null),
      executedAgreementsStatus: new UntypedFormControl(
        {value: this.financeBusinessPlan.executedAgreementsStatus, disabled: this.isPastSubmissionDeadline},
        null),
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.constructionFormGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.constructionFormGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.constructionFormGroup.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.constructionFormGroup.disable() : this.constructionFormGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.constructionFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
