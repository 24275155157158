import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ColumnApi } from 'ag-grid-community';

@Component({
  selector: 'oes-grid-item',
  templateUrl: './grid-item.component.html',
  styleUrls: ['./grid-item.component.scss'],
})
export class GridItemComponent {
  @ViewChild('titleRef') titleEl: ElementRef;

  @Input() columnApi: ColumnApi;
  @Input() contentProperties: {key: string, value: string}[];
  @Input() icon: string;
  @Input() inlineTitle: boolean = false;
  @Input() subtitle: string;
  @Input() status: string;
  @Input() statusColor: string;
  @Input() title: string;

  private titleMouseEntered = false;

  constructor(private renderer: Renderer2) {};

  onTitleMouseEnter(event: MouseEvent) {
    const titleElement = this.titleEl.nativeElement;
    const widthDiff: number = titleElement.clientWidth - titleElement.scrollWidth;
    const overflow: number = widthDiff < 0 ? Math.abs(widthDiff) : 0;
    if (event.target === titleElement && overflow > 0) {
      this.renderer.addClass(titleElement, 'show-full');
      this.renderer.setStyle(titleElement, 'transform', `translateX(-${overflow}px)`);
      this.titleMouseEntered = true;
    }
  }

  onTitleMouseLeave(event: MouseEvent) {
    const titleScroll = this.titleEl.nativeElement;
    if (event.target === titleScroll) {
      this.renderer.setStyle(titleScroll, 'transform', 'translateX(0)');
      this.titleMouseEntered = false;
      setTimeout(() => {
        if (!this.titleMouseEntered) {
          this.renderer.removeClass(titleScroll, 'show-full');
        }
      }, 1800);
    }
  }
};
