Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = function (ctx) {
  var __t,
    __p = '',
    __j = Array.prototype.join;
  function print() {
    __p += __j.call(arguments, '');
  }
  __p += '<div class="ui grid">\n  ';
  if (ctx.dayFirst && ctx.showDay) {
    ;
    __p += '\n  <div class="four wide column">\n    <label for="' + ((__t = ctx.component.key) == null ? '' : __t) + '-day" class="">' + ((__t = ctx.t('Day')) == null ? '' : __t) + '</label>\n    ' + ((__t = ctx.day) == null ? '' : __t) + '\n  </div>\n  ';
  }
  ;
  __p += '\n  ';
  if (ctx.showMonth) {
    ;
    __p += '\n  <div class="five wide column">\n    <label for="' + ((__t = ctx.component.key) == null ? '' : __t) + '-month" class="">' + ((__t = ctx.t('Month')) == null ? '' : __t) + '</label>\n    ' + ((__t = ctx.month) == null ? '' : __t) + '\n  </div>\n  ';
  }
  ;
  __p += '\n  ';
  if (!ctx.dayFirst && ctx.showDay) {
    ;
    __p += '\n  <div class="four wide column">\n    <label for="' + ((__t = ctx.component.key) == null ? '' : __t) + '-day" class="">' + ((__t = ctx.t('Day')) == null ? '' : __t) + '</label>\n    ' + ((__t = ctx.day) == null ? '' : __t) + '\n  </div>\n  ';
  }
  ;
  __p += '\n  ';
  if (ctx.showYear) {
    ;
    __p += '\n  <div class="seven wide column">\n    <label for="' + ((__t = ctx.component.key) == null ? '' : __t) + '-year" class="">' + ((__t = ctx.t('Year')) == null ? '' : __t) + '</label>\n    ' + ((__t = ctx.year) == null ? '' : __t) + '\n  </div>\n  ';
  }
  ;
  __p += '\n</div>\n<input name="data[day]" type="hidden" class="form-control" lang="en" value="" ref="input">\n';
  return __p;
};