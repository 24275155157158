import {BaseModel} from '@shared/base.model';
import {Country} from '@shared/services/country/country.model';

export class VendorInformation extends BaseModel<VendorInformation> {
  id: string;
  marketsOfDeliveryCountries: Country[];
  marketsOfSaleCountries: Country[];
  marketsOfSupportCountries: Country[];
  offerAppliances = false;
  offerConsultingServices = false;
  offerControllers = false;
  offerEpcServices = false;
  offerIntegratedKits = false;
  offerMeters = false;
  offerOmSolutions = false;
  offerPvInverters = false;
  offerPvPanels = false;
  offerStorage = false;
  offerStorageConverters = false;
  offerTurnkeySystems = false;
  productOffering: string;
  totalProductsDeployed: string;

  constructor(vendorInfo: any) {
    super();
    this.id = vendorInfo.id;
    this.marketsOfDeliveryCountries = [];
    this.marketsOfSaleCountries = [];
    this.marketsOfSupportCountries = [];
    this.offerAppliances = vendorInfo.offerAppliances;
    this.offerConsultingServices = vendorInfo.offerConsultingServices;
    this.offerControllers = vendorInfo.offerControllers;
    this.offerEpcServices = vendorInfo.offerEpcServices;
    this.offerIntegratedKits = vendorInfo.offerIntegratedKits;
    this.offerMeters = vendorInfo.offerMeters;
    this.offerOmSolutions = vendorInfo.offerOmSolutions;
    this.offerPvInverters = vendorInfo.offerPvInverters;
    this.offerPvPanels = vendorInfo.offerPvPanels;
    this.offerStorage = vendorInfo.offerStorage;
    this.offerStorageConverters = vendorInfo.offerStorageConverters;
    this.offerTurnkeySystems = vendorInfo.offerTurnkeySystems;
    this.productOffering = vendorInfo.productOffering;
    this.totalProductsDeployed = vendorInfo.totalProductsDeployed;

    if (vendorInfo.marketsOfDeliveryCountries) {
      for (let i = 0; i < vendorInfo.marketsOfDeliveryCountries.length; i++) {
        this.marketsOfDeliveryCountries.push(new Country(vendorInfo.marketsOfDeliveryCountries[i]));
      }
    }

    if (vendorInfo.marketsOfSaleCountries) {
      for (let i = 0; i < vendorInfo.marketsOfSaleCountries.length; i++) {
        this.marketsOfSaleCountries.push(new Country(vendorInfo.marketsOfSaleCountries[i]));
      }
    }

    if (vendorInfo.marketsOfSupportCountries) {
      for (let i = 0; i < vendorInfo.marketsOfSupportCountries.length; i++) {
        this.marketsOfSupportCountries.push(new Country(vendorInfo.marketsOfSupportCountries[i]));
      }
    }
  }

  get isVendorInfoValid(): boolean {
    return (this.offerAppliances ||
            this.offerConsultingServices ||
            this.offerControllers ||
            this.offerEpcServices ||
            this.offerIntegratedKits ||
            this.offerMeters ||
            this.offerOmSolutions ||
            this.offerPvInverters ||
            this.offerPvPanels ||
            this.offerStorage ||
            this.offerStorageConverters ||
            this.offerTurnkeySystems
          );
  }
}
