export enum ActionType {
  copy = 'copy',
  createFolder = 'create folder',
  delete = 'delete',
  download = 'download',
  move = 'move',
  upload = 'upload',
  associations = 'associations',
  associationAdd = 'association add',
  associationDelete = 'association delete',
  tags = 'tags',
  tagAdd = 'tag add',
  tagDelete = 'tag delete',
  completed = 'completed',
  editName = 'edit name',
  export = 'export',
  preview = 'preview'
}
