import { Injectable } from '@angular/core';
import { ColumnConfigFactory } from '@project/shared/grid/column-config-factory';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { CellAuditScoreComponent } from '@shared/ag-grid/component/cell-audit-score/cell-audit-score.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { UtilityService } from '@shared/services/utility.service';
import { UserService } from '@user/user.service';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

import { ProjectListType } from './project-list-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SharedProjectListGridSettingService extends BaseAgGridSettingsService {
  public currentGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public _columnApi: ColumnApi;
  public _gridApi: GridApi;
  public _gridStateStorageKey: string;

  private column = {};
  private projectTypes = {};
  private stageStatus = {};
  private userService: UserService;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _utilityService: UtilityService,
              private _columnConfigFactory: ColumnConfigFactory) {
    super(_agGridServicesWrapperService);
    this.userService = _agGridServicesWrapperService._userService;
    this.column = _agGridServicesWrapperService._translateService.instant('projects.column');
    this.stageStatus = _agGridServicesWrapperService._translateService.instant('project-sharing.stage-status');
    this.projectTypes = _agGridServicesWrapperService._translateService.instant('project.types');
  }

  // Call this from ngOnInit();
  public getGridOptions(listType: ProjectListType): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(listType),
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      suppressRowClickSelection: true,
      rowMultiSelectWithClick: false,
      groupHideOpenParents: true,
      groupDefaultExpanded: -1,
      suppressDragLeaveHidesColumns: false,
      enableRangeSelection: true,
      rowSelection: 'multiple',
      components: {
        cellAuditScoreComponent: CellAuditScoreComponent
      },
      getRowHeight: (params) => {
        if (params.node.rowHeight > 80) {
          return params.node.rowHeight + 10;
        }
        return 80;
      },
      rowClass: 'row-with-tags',
      rowGroupPanelShow: 'always',
      autoGroupColumnDef: {
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true
        }
      },
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      sideBar: {
        toolPanels: ['columns']
      },
      localeText: this.globalTranslations,
      context: {
        this: this
      }
    };
  }

  // Column Properties
  // Call from subscribe()
  public getColumnSetting(listType: ProjectListType): ColDef[] {
    const columnConfigs = this._columnConfigFactory;
    const colDefs = [
      columnConfigs.get('checkbox'),
      {
        headerName: this.column['project-name'],
        field: 'name',
        headerTooltip: this.column['project-name'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          if (params.data) {
            let path = 'planning/summary';
            if (listType === ProjectListType.CLAIMS) {
              path = 'connections';
            }
            return `<a class="hyperlink" href="#/oes/projects/shared-projects/${params.data.id}/${path}">${params.data.name}</a><p style="opacity: .45; margin: 0;">${this.projectTypes[params.data?.projectType]}</p>`;
          }
        },
        pinned: 'left',
        filterParams: {
          caseSensitive: false,
          clearButton: true
        },
        suppressSizeToFit: true,
        resizable: true
      },
      {
        headerName: this.column['organization'],
        field: 'developer',
        headerTooltip: this.column['organization'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          caseSensitive: false,
          clearButton: true
        },
        suppressSizeToFit: true
      },
      columnConfigs.get('country'),
      columnConfigs.get('region'),
      columnConfigs.get('latitude'),
      columnConfigs.get('longitude'),
      columnConfigs.get('totalCustomers'),
      columnConfigs.get('totalPV'),
      columnConfigs.get('subsidyAmountPerConnection'),
      columnConfigs.get('irr'),
      columnConfigs.get('payback'),
      columnConfigs.get('lcoe'),
      columnConfigs.get('totalCapex'),
      columnConfigs.get('capexPerConnection'),
      columnConfigs.get('storage'),
      columnConfigs.get('generator'),
      columnConfigs.get('cod'),
      columnConfigs.get('submittedToProgramDateTime'),
      columnConfigs.get('status'),
      columnConfigs.get('projectType'),
      columnConfigs.get('arpuResidential'),
      columnConfigs.get('arpuCommercial'),
      columnConfigs.get('arpuPublic'),
      columnConfigs.get('arpuProductive'),
      columnConfigs.get('arpuAnchor'),
      columnConfigs.get('equityIRR'),
      columnConfigs.get('equityPayback')
    ];

    colDefs.splice(3, 0, {
      headerName: this.column['stage'],
      field: 'projectStages',
      headerTooltip: this.column['stage'],
      unSortIcon: true,
      enablePivot: true,
      enableRowGroup: true,
      enableValue: true,
      filter: 'agSetColumnFilter',
      comparator: (a, b) => {
        try {
          const statusesA: any = JSON.parse(a);
          const orderedStatusesA = this._utilityService.orderJSONStatusesByDate(statusesA)
            .filter(status => status.timestamp != null)
            .map(status => status.name);

          const statusesB: any = JSON.parse(b);
          const orderedStatusesB = this._utilityService.orderJSONStatusesByDate(statusesB)
            .filter(status => status.timestamp != null)
            .map(status => status.name);

          const latestStatusA = orderedStatusesA ? orderedStatusesA[orderedStatusesA.length - 1] : null;
          const latestStatusB = orderedStatusesB ? orderedStatusesB[orderedStatusesB.length - 1] : null;

          return this.caseInsensitiveSort(latestStatusA, latestStatusB);
        } catch (jsonParseError: any) {}
      },
      filterParams: {
        clearButton: true,
        valueGetter: (params) => {
          const statuses: any = JSON.parse(params.data.projectStages);
          const orderedStatuses = this._utilityService.orderJSONStatusesByDate(statuses)
            .filter(status => status.timestamp != null)
            .map(status => status.name);

          if (orderedStatuses?.length) {
            return orderedStatuses[orderedStatuses.length - 1];
          }
          return null;
        }
      },
      suppressSizeToFit: false,
      cellRenderer: (params) => {
        if (params?.value) {
          const statuses: any = JSON.parse(params.value);
          const orderedStatuses = this._utilityService.orderJSONStatusesByDate(statuses);
          const currentStatus = orderedStatuses && orderedStatuses[orderedStatuses.length - 1]?.timestamp ? orderedStatuses[orderedStatuses.length - 1] : null;
          if (!currentStatus) {
            return `<div style="display: flex; align-items: center;">
                      <i class="fa fa-caret-down" aria-hidden="true"></i>
                    </div>`;
          }
          return `<div style="display: flex; align-items: center;">
                    <div style="width: fit-content;
                          display: flex;
                          border-radius: 8px;
                          line-height: 0;
                          align-items: center;
                          justify-content: center;
                          padding: 0 11px;
                          height: 17px;
                          color: ${ this._utilityService.findTextColor(currentStatus.color) };
                          background-color: ${ currentStatus.color };">${ currentStatus.name }
                    </div>
                    <i class="fa fa-caret-down" style="margin-left: 5px;" aria-hidden="true"></i>
                  </div>`;
        }
      }
    });

    switch (listType) {
      case ProjectListType.ASSETS:
        colDefs.splice(28, 0, columnConfigs.get('inProgressMilestones'));
        colDefs.splice(29, 0, columnConfigs.get('completedMilestones'));
        colDefs.splice(30, 0, columnConfigs.get('latestCompletedMilestone'));
        colDefs.splice(31, 0, columnConfigs.get('latestMilestoneUpdate'));
        colDefs.splice(4, 0, columnConfigs.get('auditScore'));
        break;
      case ProjectListType.CLAIMS:
        colDefs.splice(4, 0, columnConfigs.get('payConnections'));
        colDefs.splice(5, 0, columnConfigs.get('submittedConnections'));
        colDefs.splice(6, 0, columnConfigs.get('approvedConnections'));
        colDefs.splice(7, 0, columnConfigs.get('paidConnections'));
        colDefs.splice(8, 0, columnConfigs.get('rejectedConnections'));
        colDefs.splice(9, 0, columnConfigs.get('connectionsEverSubmitted'));
        colDefs.splice(10, 0, columnConfigs.get('totalPaymentAmount'));
        colDefs.splice(11, 0, columnConfigs.get('totalClaimAmount'));
        break;
      case ProjectListType.MAIN:
        colDefs.splice(4, 0, columnConfigs.get('submittedConnections'));
        colDefs.splice(5, 0, columnConfigs.get('approvedConnections'));
        colDefs.splice(6, 0, columnConfigs.get('paidConnections'));
        colDefs.splice(7, 0, columnConfigs.get('rejectedConnections'));
        colDefs.splice(8, 0, columnConfigs.get('connectionsEverSubmitted'));
        colDefs.splice(9, 0, columnConfigs.get('totalPaymentAmount'));
        colDefs.splice(10, 0, columnConfigs.get('totalClaimAmount'));
        colDefs.splice(25, 0, columnConfigs.get('program'));
        colDefs.splice(26, 0, columnConfigs.get('submittedToProgramDateTime'));
        colDefs.splice(38, 0, columnConfigs.get('inProgressMilestones'));
        colDefs.splice(39, 0, columnConfigs.get('completedMilestones'));
        colDefs.splice(40, 0, columnConfigs.get('latestCompletedMilestone'));
        colDefs.splice(41, 0, columnConfigs.get('latestMilestoneUpdate'));
        break;
      default:
        break;
    }
    return colDefs;
  }
}

