<oes-modal-dialog
  #installerModal
  id="installerModal"
  [allowCancel]="true"
  [allowSubmit]="true"
  [hideCloseBtn]="true"
  [size]="'medium'"
  (submit)="handleSubmitInstaller()"
  (close)="closeInstallerModal()">
  <div class="form-header">
    <h3>{{ (action === 'create' ? 'installers.list.add-installer' : 'installers.list.edit-installer') | translate }}</h3>
    <button
      class="btn btn-fresh btn-link"
      (click)="deleteInstallerModal.show()"
      *ngIf="action === 'edit'">
      <img src="assets/icons/line-icons/shared/red/trash.svg" role="presentation" />
      {{ 'installers.list.delete-installer' | translate }}
    </button>
  </div>
  <form [formGroup]="formGroup" class="dialog-form">
    <oes-text-input
      [label]="'installers.form.company-name' | translate"
      [placeholder]="'installers.form.company-name-placeholder' | translate"
      [formControlName]="'companyName'"
      [fullWidth]="true"
      [required]="true">
    </oes-text-input>
    <span *ngIf="showError('companyName', 'required')" class="color-error">
      {{ 'installers.form.error.required' | translate }}
    </span>
    <span *ngIf="showError('companyName', 'maxlength')" class="color-error">
      {{ 'installers.form.error.max-length' | translate }}
    </span>
    <span *ngIf="duplicateName" class="color-error">
      {{ 'installers.form.error.duplicate-name' | translate }}
    </span>
    <oes-text-input
      [label]="'installers.form.email-address' | translate"
      [placeholder]="'installers.form.email-address-placeholder' | translate"
      [formControlName]="'email'"
      [fullWidth]="true"
      [required]="true">
    </oes-text-input>
    <span *ngIf="showError('email', 'required')" class="color-error">
      {{ 'installers.form.error.required' | translate }}
    </span>
    <span *ngIf="showError('email', 'email')" class="color-error">
      {{ 'installers.form.error.email' | translate }}
    </span>
    <span *ngIf="showError('email', 'maxlength')" class="color-error">
      {{ 'installers.form.error.max-length' | translate }}
    </span>
    <span *ngIf="duplicateEmail" class="color-error">
      {{ 'installers.form.error.duplicate-email' | translate }}
    </span>
  </form>
</oes-modal-dialog>

<oes-modal-dialog
  #deleteInstallerModal
  id="deleteInstallerModal"
  [allowCancel]="true"
  [allowSubmit]="true"
  [hideCloseBtn]="true"
  [destructive]="true"
  [size]="'small'"
  [title]="'installers.list.delete-installer' | translate"
  (submit)="handleDeleteInstaller()"
  [submitButtonText]="'buttons.delete' | translate"
  (close)="deleteInstallerModal.hide()">
  {{ 'installers.form.delete-warning' | translate }}
</oes-modal-dialog>
