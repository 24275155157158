
import { Subject } from 'rxjs';
import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FinanceConnectionStatusUpdateService } from '@finance/developer-portfolio/shared/finance-connection-status-update.service';
import { SubmitBitService } from '@shared/components/submit-bid/submit-bit.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSetPortfolioListGridSettingService } from './project-set-portfolio-list-grid-setting.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { GridState } from '@shared/ag-grid/gird-state';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';

@Component({
  selector: 'oes-developer-project-set-portfolio-list-display',
  templateUrl: 'developer-project-set-portfolio-list-display.component.html',
  styleUrls: ['./developer-project-set-portfolio-list-display.component.scss'],
  providers: [
    FinanceConnectionStatusUpdateService,
    SubmitBitService
  ]
})
export class DeveloperProjectSetPortfolioListDisplayComponent implements OnDestroy, OnChanges {
  @Input() projectSetPortfolios: ProjectSetPortfolio[];
  @Output() reload: EventEmitter<boolean> = new EventEmitter();

  columnApi: ColumnApi;
  defaultGridState: GridState;
  gridOptions: GridOptions = {};
  gridApi: GridApi;
  gridState: GridState;

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _projectSetPortfolioListGridSettingService: ProjectSetPortfolioListGridSettingService) {
    this.gridOptions = this._projectSetPortfolioListGridSettingService.getGridOptions();
    this.gridOptions['context'] = {
      gridService: this
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.projectSetPortfolios && this.gridApi) {
      this.displayData(this.projectSetPortfolios);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._projectSetPortfolioListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._projectSetPortfolioListGridSettingService.buildDefaultGridState();
    this.reload.next(true);
  }

  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._projectSetPortfolioListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const processCellCallback = function(params: any) {
          const columnId = params?.column?.colId;
          switch (columnId) {
            case 'projectSets': {
              const projectSets: ProjectSet[] = params?.value;
              const totals = projectSets?.reduce((accum, projectSet) => {
                accum += projectSet.totalProjects || 0;
                return accum;
              }, 0);
              return totals.toLocaleString();
            }
            case 'projectSets_1': {
              const projectSets1: ProjectSet[] = params?.value;
              const totals1 = projectSets1?.reduce((accum, projectSet) => {
                accum += (projectSet.averageSystemSize || 0) * (projectSet.totalProjects || 0);
                return accum;
              }, 0);
              return totals1.toLocaleString() + ' ' + 'kWp';
            }
            case 'projectSets_2': {
              const projectSets2: ProjectSet[] = params?.value;
              const totals2 =  projectSets2?.reduce((accum, projectSet) => {
                accum += (projectSet.totalCapex || 0);
                return accum;
              }, 0);
              const firstCurrencyType = projectSets2[0].currencyType;
              if (projectSets2.every(projectSet => projectSet.currencyType === firstCurrencyType)) {
                if (firstCurrencyType.toString() === 'USD') {
                  return '$' + totals2.toLocaleString();
                } else {
                  return totals2.toLocaleString() + ' ' + firstCurrencyType;
                }
              }
            }
            case 'projectSets_3': {
              const projectSets3: ProjectSet[] = params?.value;
              const totals3 = projectSets3?.reduce((accum, projectSet) => {
                accum += (projectSet.averagePvProduction || 0) * (projectSet.totalProjects || 0);
                return accum;
              }, 0);
              return totals3.toLocaleString() + ' ' + 'kWh/yr';
            }
            case 'sharedWithOdyssey': {
              if (params.value) {
                return 'Shared';
              }
              return 'Not Shared';
            }
          }
          return params.value;
        };
        this._projectSetPortfolioListGridSettingService.exportCsv(this.gridApi, 'C&I Portfolios', null, processCellCallback);
        break;
      }
      case GridSubAction.reload:
        this.reload.next(true);
        break;
      case GridSubAction.clearFilter:
        this._projectSetPortfolioListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private displayData(projectSetPortfolios: ProjectSetPortfolio[]) {
    if (projectSetPortfolios?.length > 0) {
      this.gridApi.setRowData(projectSetPortfolios);
      this._projectSetPortfolioListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }
}
