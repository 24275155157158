import { Component, OnInit, Input } from '@angular/core';
import { Project } from '@project/shared/project.model';
import { ProjectGroup } from '@shared/services/project-group.model';

@Component({
  selector: 'oes-fm-portfolio-header-image',
  templateUrl: './header-image.component.html',
  styleUrls: ['./header-image.component.scss']
})
export class FmPortfolioHeaderImageComponent implements OnInit {
  @Input() projectGroup: ProjectGroup;
  @Input() projects: Project[];
  public mapOptions: google.maps.MapOptions = {};
  public markerPositions: google.maps.LatLngLiteral[] = [];
  public markerOption: google.maps.MarkerOptions = {draggable: false};
  public imagePath = `${window.location.origin}/assets/images/map-cluster/map-cluster`;

  constructor() { }

  ngOnInit() {
    let minLat = 0.0;
    let maxLat = 0.0;
    let minLon = 0.0;
    let maxLon = 0.0;
    this.markerPositions = this.projects.map((project: Project) => {
      return {
        lat: project.latitude,
        lng: project.longitude
      };
    });
    this.projects.forEach((project, index) => {
      if (index === 0) {
        minLat = project.latitude;
        maxLat = project.latitude;
        minLon = project.longitude;
        maxLon = project.longitude;
      } else {
        if (project.latitude < minLat) {
          minLat = project.latitude;
        }
        if (project.latitude > maxLat) {
          maxLat = project.latitude;
        }
        if (project.longitude < minLon) {
          minLon = project.longitude;
        }
        if (project.longitude > maxLon) {
          maxLon = project.longitude;
        }
      }
    });
    const bounds = new google.maps.LatLngBounds(
      {lat: minLat, lng: minLon},
      {lat: maxLat, lng: maxLon}
    );
    this.mapOptions.center = bounds.getCenter().toJSON();
    this.mapSetup();
  }

  private mapSetup() {
    this.mapOptions.zoomControl = false;
    this.mapOptions.zoom = 6;
    this.mapOptions.mapTypeId = 'roadmap';
    this.mapOptions.scaleControl = false;
    this.mapOptions.mapTypeControl = false;
    this.mapOptions.streetViewControl = false;
    this.mapOptions.scrollwheel = false;
    this.mapOptions.draggable = false;
    this.mapOptions.fullscreenControl = false;
  }
}
