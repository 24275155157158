import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from './breadcrumb.service';
import { BreadcrumbItem } from './breadcrumb-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oes-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbItems: BreadcrumbItem[];
  nonUrlBreadcrumbs = false;
  prependedCrumbs: BreadcrumbItem[] = [];

  private currentUrlUpdatedSubscription: Subscription;

  constructor(private _activatedRoute: ActivatedRoute,
              private _breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    this.currentUrlUpdatedSubscription = this._breadcrumbService.currentUrlUpdated$.subscribe((url: string) => {
      this.processBreadcrumbs();
    });
  }

  ngOnDestroy(): void {
    if (this.currentUrlUpdatedSubscription) {
      this.currentUrlUpdatedSubscription.unsubscribe();
    }
  }

  processBreadcrumbs() {
    this._breadcrumbService.checkIfCurrentUrlIsPrepended();
    this.prependedCrumbs = this._breadcrumbService.prependedCrumbs;
    this.breadcrumbItems = this.generateCrumbs(this._activatedRoute.root);
    const breadcrumbHistory = this._breadcrumbService.breadcrumbHistory;

    let deepChangeInRoute = false;

    if (this._breadcrumbService.sameAsPreviousUrl()) {
      this.breadcrumbItems = [...breadcrumbHistory];
      return;
    }

    let breadcrumbHistoryUpToDate = false;
    if (this.breadcrumbItems.length && breadcrumbHistory?.length) {
      const previousUrl = this._breadcrumbService.previousUrl;
      breadcrumbHistoryUpToDate = breadcrumbHistory[breadcrumbHistory.length - 1].url === previousUrl;
    }

    if (!breadcrumbHistoryUpToDate) {
      this._breadcrumbService.resetPrependedCrumbs();
      this.prependedCrumbs = this._breadcrumbService.prependedCrumbs;
    }

    this.breadcrumbItems = this.breadcrumbItems.slice(this._breadcrumbService.generatedCrumbIndex);

    const breadcrumbsLongEnough = this.breadcrumbItems.length > 1 && breadcrumbHistory?.length > 1;
    deepChangeInRoute = breadcrumbHistory.every(breadcrumb => (
      !this.breadcrumbItems[this.breadcrumbItems.length - 1].url?.includes(breadcrumb?.url)
    ));

    if (breadcrumbsLongEnough && breadcrumbHistoryUpToDate && deepChangeInRoute) {
      // User navigated to a deeply divergent route, building new breadcrumbs on previous ones
      this.nonUrlBreadcrumbs = true;
      const generatedCrumbIndex = this.breadcrumbItems.length - 1;
      this._breadcrumbService.generatedCrumbIndex = generatedCrumbIndex;
      this.breadcrumbItems = [this.breadcrumbItems[generatedCrumbIndex]];
      this._breadcrumbService.prependedCrumbs = breadcrumbHistory;
      this.prependedCrumbs = this._breadcrumbService.prependedCrumbs;
    }

    this._breadcrumbService.currentBreadcrumbs = this.breadcrumbItems;
  }

  private generateCrumbs(route: ActivatedRoute, url = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      const customBreadcrumb = route?.snapshot?.queryParams?.bc;
      if (customBreadcrumb?.length) {
        breadcrumbs[breadcrumbs.length - 1].label = customBreadcrumb;
      }
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child?.snapshot?.url?.map(segment => segment.path).join('/');
      if (routeURL && routeURL !== '') {
        url += `/${routeURL}`;
      }

      if (child?.snapshot?.data) {
        const label = child.snapshot.data['breadcrumb'];
        if (label && label !== '') {
          const params = child.snapshot.data['params'];
          if (params) {
            breadcrumbs.push({label, url, params});
          } else {
            breadcrumbs.push({label, url});
          }
        }
      }

      return this.generateCrumbs(child, url, breadcrumbs);
    }
  }
}
