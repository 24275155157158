import { Program } from '@program/shared/program.model';
import { Project } from '@project/shared/project.model';
import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';
import { FinanceDealStatus } from '../finance-deal-status.enum';
import { ProjectSet } from '../project-set/project-set.model';

export class ProjectSetPortfolio extends BaseModel<ProjectSetPortfolio> {
  name: string;
  ownerOrganization: Organization;
  program: Program;
  projectSets: ProjectSet[];
  projects: Project[];
  sharedWithOdyssey: boolean;
  businessPlanUpdateDateTime: Date;
  businessPlanValidationDateTime: Date;

  status: FinanceDealStatus; // Transient

  constructor(projectSetPortfolio: any) {
    super();
    this.id = projectSetPortfolio.id;
    this.name = projectSetPortfolio.name;
    this.ownerOrganization = projectSetPortfolio.ownerOrganization;
    this.program = projectSetPortfolio.program;
    this.projectSets = projectSetPortfolio?.projectSets?.map(ps => new ProjectSet(ps));
    this.projects = projectSetPortfolio.projects;
    this.sharedWithOdyssey = projectSetPortfolio.sharedWithOdyssey;
    this.businessPlanUpdateDateTime = projectSetPortfolio.businessPlanUpdateDateTime;
    this.businessPlanValidationDateTime = projectSetPortfolio.businessPlanValidationDateTime;
  }
}
