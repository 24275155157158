import { Component, OnInit, ViewChild, ElementRef, Input, SecurityContext } from '@angular/core';
import { FilesService } from '@shared/components/files/shared/files.service';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EditName } from '@shared/components/files/shared/models/edit-name.model';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { Subject } from 'rxjs';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'oes-edit-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss']
})
export class EditNameComponent implements OnInit {
  @Input() docPathList: string[];

  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;
  @ViewChild('oesAutoFocus', {static: false}) input: ElementRef;

  editName: string;
  extension = '';
  formGroup: UntypedFormGroup;
  nameError = false;
  slashError = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _filesService: FilesService,
              private _eventService: EventService,
              private _sanitizer: DomSanitizer,
              private _translateService: TranslateService,
              private _documentDataService: DocumentDataService) { }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      newName: new UntypedFormControl('', Validators.required)
    });
    this.subscribeName();
  }

  subscribeName() {
    this.formGroup.controls['newName'].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((name: string) => {
        const editingNode = this._filesService.getHighlightedNodes();
        const proposedNewPath = editingNode[0].data.path.split(this.editName)[0] + name;
        this.nameError = this.docPathList.includes(proposedNewPath) ? true : false;
        this.slashError = name && name.includes('/') ? true : false;
      });
  }

  onKeyDown(e) {
    if (e.keyCode === 13 && !this.slashError && !this.nameError && this.formGroup.valid) {
      this.editDocumentName();
    }
  }

  public hide() {
    this.formGroup.reset();
    this.modalDialog.hide();
    this.extension = '';
  }

  editDocumentName() {
    const nodeToEdit = this._filesService.getHighlightedNodes();
    const newName = this._sanitizer.sanitize(SecurityContext.HTML, this.formGroup.controls['newName'].value);
    const editName: EditName = {
      id: nodeToEdit[0].data.id,
      newName: newName,
      affectedChildrenIds: nodeToEdit[0].allLeafChildren.map(child => child.data.id).filter(id => id !== nodeToEdit[0].data.id)
    };

    if (nodeToEdit[0].key !== newName) {
      this._documentDataService.editName(editName)
        .pipe(take(1))
        .subscribe(documents => {
          this.formGroup.reset();
          this._filesService.clickActionButton(ActionType.completed);
        },
        error => {
          if (error?.error?.message.includes('Illegal Name Exception (400)')) {
            this._eventService.error(this._translateService.instant('data-room.dialogs.edit-name.name-error'));
          } else {
            this._eventService.error(error?.error?.message);
            throw new Error(error.error.message);
          }
        });
    }
    this.hide();
  }

  show() {
    const selectedNodes = this._filesService.getSelectedNodes();
    this.editName = selectedNodes[0].key;
    this.setExtension(selectedNodes);
    this.modalDialog.show();

    // Workaround for autofocus within a modal
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, 0);
    }, 500);
  }

  setExtension(selectedNodes) {
    if (selectedNodes[0].data.documentKey.docType === 'FILE' && this.editName.split('.').length > 1) {
      this.extension = '.' + this.editName.split('.')[this.editName.split('.').length - 1];
    }
  }
}
