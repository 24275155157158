<oes-read-only-display
  [readOnly]="readOnly">
</oes-read-only-display>
<form [formGroup]="specificationGroup" novalidate>
  <oes-project-specification-form
    *ngIf="project"
    formControlName="specificationFormControl"
    [project]="project"
    [cancelButton]="false"
    [readOnly]="readOnly"
    (saved)="saved($event)">
  </oes-project-specification-form>
</form>
