import { BaseModel } from '@shared/base.model';
import { ConnectionsColumn } from './connections-column.model';

export class ConnectionsProfile extends BaseModel<ConnectionsProfile> {
  columns: ConnectionsColumn[];
  columnOrder: string[];
  lastUpdate: Date;
  updateUser: string;

  constructor(connectionsProfile: any) {
    super();
    this.columns = connectionsProfile.columns;
    this.columnOrder = connectionsProfile.columnOrder;
    this.lastUpdate = connectionsProfile.lastUpdate;
    this.updateUser = connectionsProfile.updateUser;
  }
}
