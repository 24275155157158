<ng-container [formGroup]="risksFormGroup">
  <div class="risks">
    <p class="text-xs color-font-tertiary mb-2">{{'deal-description.risks.title' | translate}}</p>
    <div formArrayName="financeSummaryRisks">
      <ng-container
        [formGroupName]="i"
        *ngFor="let control of risksFormGroup?.get('financeSummaryRisks')['controls']; let i=index; let first=first; let last=last">
        <div class="row mb-2">
          <div class="col-1 pr-0" *ngIf="!isPastSubmissionDeadline">
            <span class="plusSign mt-1 mr-1"
              [ngClass]="{'invisible':!last}"
              (click)="addRiskRow()"></span>
            <span class="minusSign mt-1"
              [ngClass]="{'invisible':(first && last)}"
              (click)="removeRiskRow(i)"></span>
            <p class="d-inline pull-right bold mb-0 mt-1">{{'deal-description.risks.label' | translate}}:</p>
          </div>
          <div class="col-11">
            <ng-container *ngIf="!isPastSubmissionDeadline; else readOnly">
              <textarea
                class="textarea input-control textarea-fresh textarea--60 py-1 px-2"
                placeholder="{{'deal-description.risks.placeholder' | translate}}"
                maxlength="3000"
                formControlName="description">
              </textarea>
              <p class="counter-warning-all text-xs color-font-tertiary">
                <span [ngClass]="{'counter-warning' : control.value?.description?.length >= 3000}">
                  {{control.value?.description?.length || 0}}
                </span> / 3000 {{'tiny-mce.character-counter.characters' | translate}}
              </p>
            </ng-container>
            <ng-template #readOnly>
              <div>{{control.value?.description}}</div>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
