<h4>{{ 'offtaker.project-set-portfolio.overview.overview' | translate }}</h4>

<div class="overview">
  <oes-display-psp-overview-metrics
    [projectSetPortfolio]="projectSetPortfolio">
  </oes-display-psp-overview-metrics>
</div>

<h4>{{ 'offtaker.project-set-portfolio.overview.site-groups-simple' | translate }}</h4>
<p class="subheading">{{ 'offtaker.project-set-portfolio.overview.site-groups-sub' | translate }}</p>

<div *ngIf="projectSets" class="project-sets">
  <oes-site-group-overview
    [readOnly]="readOnly"
    [projectSets]="projectSets"
    (reload)="reloadPortfolio()">
  </oes-site-group-overview>
</div>
