import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-read-only-display',
  templateUrl: './read-only-display.component.html',
  styleUrls: ['./read-only-display.component.scss']
})
export class ReadOnlyDisplayComponent {
  @Input() placement = 'left';
  @Input() readOnly: boolean;
  @Input() tooltip = this._translateService.instant('project.read-only.tooltip');

  constructor(private _translateService: TranslateService) {
  }
}
