<div class="form-error">
  <ng-container *ngIf="requiredError">
    <ng-container *ngIf="text && text !==''; else generalRequiredError">{{text}}</ng-container>
    <ng-template #generalRequiredError>{{'form.error.required' | translate}}</ng-template>
  </ng-container>
  <ng-container *ngIf="invalidError">
    <ng-container *ngIf="text && text !==''; else generalInvalidError">{{text}}</ng-container>
    <ng-template #generalInvalidError>{{'form.error.invalid' | translate}}</ng-template>
  </ng-container>
  <!-- For extra content -->
  <ng-content></ng-content>
</div>
