import {Injectable} from '@angular/core';
import {Organization} from '@shared/models/organization/organization.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { DeveloperInformation } from '@organization-management/organization/developer-information/developer-information.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationFilter } from '@shared/models/organization/organization-filter.model';

@Injectable({
  providedIn: 'root'
})
export class DeveloperProfileService extends BaseRestApiService {
  public organizationUri = environment.serverUri + `api/${environment.apiVersion}/vm/organizations`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(searchText: string = null): Observable<Organization[]> {
    const filter = new OrganizationFilter({ name: searchText });
    return this.get<Organization[]>(`${this.organizationUri}`, { params: { page: 0, size: 20, filter: JSON.stringify(filter) }})
          .pipe(
            map(results => results.map(result => new Organization(result)))
          );
  }

  public getDeveloperInformation(organizationId: string): Observable<DeveloperInformation> {
    return this.get<DeveloperInformation>(`${this.organizationUri}/${organizationId}/developer-information`)
            .pipe(
              map(result => new DeveloperInformation(result))
            );
  }

  public getOrganizationDetail(organizationId: string): Observable<Organization> {
    return this.get<Organization>(`${this.organizationUri}/${organizationId}`)
            .pipe(
              map(result => new Organization(result))
            );
  }
}
