import {BaseModel} from '@shared/base.model';
export class OrganizationImage extends BaseModel<OrganizationImage> {
  fileType: string;
  filename: string;

  constructor(organizationImageInfo: any) {
    super();
    this.fileType = organizationImageInfo.fileType;
    this.filename = organizationImageInfo.filename;
    this.id = organizationImageInfo.id;
  }
}
