import { environment } from '@environments/environment';

export class ProjectMilestonesConstant {
  public static projectMilestones = environment.serverUri + `api/${environment.apiVersion}/projects`;

  static milestoneConfig = {
    findAllByProjectId: (projectId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone`;
    },
    create: (projectId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone`;
    },
    delete: (projectId: string, milestoneId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/${milestoneId}`;
    },
    getById: (projectId: string, milestoneId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/${milestoneId}`;
    },
    update: (projectId: string, milestoneId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/${milestoneId}`;
    },
    updateAll: (projectId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/update-all`;
    },
    updateAllConfigs: (projectId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/update-all-configs`;
    }
  };

  static forms = {
    // PUT
    create: (projectId: string, milestoneConfigId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/${milestoneConfigId}/form`;
    },
    // DELETE
    delete: (projectId: string, milestoneId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/${milestoneId}/form`;
    },
    // GET
    getForms: (projectId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/forms`;
    },
    // POST
    saveFormData: (projectId: string, milestoneId: string) => {
      return `${ProjectMilestonesConstant.projectMilestones}/${projectId}/milestone/${milestoneId}/form`;
    }
  };
}
