import { environment } from '@environments/environment';

export class ManageOfftakersApiConstants {
  public static userUri = environment.serverUri + `api/${ environment.apiVersion }/system-admin`;

  public static findAllProjectSets = () => {
    return `${ ManageOfftakersApiConstants.userUri }/project-sets/find-all`;
  };

  public static findAllOfftakers = () => {
    return `${ ManageOfftakersApiConstants.userUri }/offtakers/find-all`;
  };
}
