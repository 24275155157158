import { Component } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { DateTime } from 'luxon';

@Component({
  selector: 'oes-date-cell-editor',
  templateUrl: './date-cell-editor.component.html',
  styleUrls: ['./date-cell-editor.component.scss']
})
export class DateCellEditorComponent implements ICellEditorAngularComp {
  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default ag-custom-component-popup',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD',
    adaptivePosition: true
  };
  // FormGroup doesn't work well for row editing mode, use ngModel
  public calendar: Date;

  constructor() {
  }

  agInit(params: any) {
    this.calendar = params.value ? DateTime.fromISO(params.value).toJSDate() :  new Date();
  }

  // Note: When a user edit input field directly and hit enter key, this component can't catch the value. Then we set readonly for <input>
  getValue(): string {
    return DateTime.fromJSDate(this.calendar).toFormat('yyyy-MM-dd');
  }

  isPopup() {
    // don't return true, the editor doesn't fit the cell width
    return false;
  }
}
