import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCase',
  pure: true
})
export class CamelCasePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Replace - to space
    let replaced = value.replace(/-/g, ' ');

    // to lowercase
    replaced = replaced.toLowerCase();

    // Replace every first chars to uppercase
    return replaced.split(' ').map((key, index) => {
      // the first keyword is lowercase
      if (index === 0) {
        return key;
      } else {
        return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase();
      }
    }).join('');
  }
}
