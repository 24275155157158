import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';
import { take } from 'rxjs/operators';
import { ManageOfftakersService } from './manage-offtakers.service';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { ManageOfftakersGridSettingService } from './manage-offtakers-grid-setting.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { Offtaker } from '@project/offtakers/offtaker.model';
import { Subject } from 'rxjs';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-manage-offtakers',
  templateUrl: 'manage-offtakers.component.html',
  styleUrls: ['manage-offtakers.component.scss']
})

export class ManageOfftakersComponent implements OnInit, OnDestroy {
  columnApi: ColumnApi;
  defaultGridState: GridState;
  displayedProjectSets: ProjectSet[];
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridState: GridState;
  offtakers: Offtaker[];

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _manageOfftakersService: ManageOfftakersService,
              private _translateService: TranslateService,
              private _manageOfftakersGridSettingService: ManageOfftakersGridSettingService) {
    this.gridOptions = this._manageOfftakersGridSettingService.getGridOptions();
  }

  ngOnInit() {
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this._manageOfftakersGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._manageOfftakersGridSettingService.buildDefaultGridState();
    this.getAllOfftakers();

  }

  getAllOfftakers() {
    this._manageOfftakersService.findAllOfftakers()
    .pipe(take(1))
    .subscribe((offtakers: Offtaker[]) => {
      this.offtakers = offtakers.map(ot => new Offtaker(ot));
      this.displayedProjectSets = this.offtakers.reduce((accum, offtaker) => {
        offtaker.projectSets.forEach(ps => accum.push(ps));
        return accum;
      }, []);
      this.setAgGrid(this.displayedProjectSets);
    });
  }

  private setAgGrid(projectSets: ProjectSet[]) {
    if (projectSets?.length > 0) {
      this.gridApi.setRowData(projectSets);
      this._manageOfftakersGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.collapseAll();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._manageOfftakersGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const contractingArrangementTypes = this._translateService.instant('offtaker.contracting-arrangement');
        const stages = this._translateService.instant('offtaker.project-stage');

        const processCellCallback = (params: any) => {
          switch (params.column?.colId) {
            case 'stage':
              return stages[params.value];
            case 'contractType':
              return contractingArrangementTypes[params.value];
            case 'currency':
            case 'totalSites':
            case 'country.name':
            default:
              return params.value;
          }
        };
        this._manageOfftakersGridSettingService.exportCsv(this.gridApi, 'Manage Offtakers', true, processCellCallback);
        break;
      }
      case GridSubAction.reload:
        this.getAllOfftakers();
        break;
      case GridSubAction.clearFilter:
        this._manageOfftakersGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private gridInit() {
    this.gridStateStorageKey = this._manageOfftakersGridSettingService.buildGridStateStorageKey('manageOfftakers');
  }
}
