<ng-container *ngIf="projectSetPortfolio">
  <div class="site-boxes">

    <div class="site-box">
      <h5>{{ 'offtaker.project-set-portfolio.overview.all-sites' | translate }}</h5>
      <h3><img src="/assets/icons/line-icons/shared/orange/all-sites.svg" />{{ getAllSitesCount() }}</h3>
      <p>{{ projectSetPortfolio?.projectSets?.length }} {{ 'offtaker.project-set-portfolio.overview.site-groups' | translate }}</p>
    </div>

    <div class="site-box">
      <h5>{{ 'offtaker.project-set-portfolio.overview.pre-construction' | translate }}</h5>
      <h3><img src="/assets/icons/line-icons/shared/orange/pre-construction.svg" />{{ getSitesCountByStage('PRE_CONSTRUCTION') }}</h3>
      <p>{{ 'offtaker.project-set-portfolio.overview.sites' | translate }}</p>
    </div>

    <div class="site-box">
      <h5>{{ 'offtaker.project-set-portfolio.overview.in-construction' | translate }}</h5>
      <h3><img src="/assets/icons/line-icons/shared/orange/in-construction.svg" />{{ getSitesCountByStage('IN_CONSTRUCTION') }}</h3>
      <p>{{ 'offtaker.project-set-portfolio.overview.sites' | translate }}</p>
    </div>

    <div class="site-box">
      <h5>{{ 'offtaker.project-set-portfolio.overview.operational' | translate }}</h5>
      <h3><img src="/assets/icons/line-icons/shared/orange/operational.svg" />{{ getSitesCountByStage('OPERATIONS') }}</h3>
      <p>{{ 'offtaker.project-set-portfolio.overview.sites' | translate }}</p>
    </div>

  </div>
</ng-container>

