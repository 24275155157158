import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, Validator,
         AbstractControl, ValidationErrors } from '@angular/forms';
import { FinanceBusinessPlan } from '@finance/developer-portfolio/business-plan/finance-business-plan.model';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'oes-business-plan-project-sites',
  templateUrl: './project-sites.component.html',
  styleUrls: ['./project-sites.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessPlanProjectSitesComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessPlanProjectSitesComponent),
      multi: true
    }
  ]
})
export class BusinessPlanProjectSitesComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() financeBusinessPlan: FinanceBusinessPlan;
  @Input() isPastSubmissionDeadline = false;

  public projectSitesFormGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.projectSitesFormGroup = new UntypedFormGroup({
      siteInnovationDescription: new UntypedFormControl(''),
      siteDescription: new UntypedFormControl(''),
      siteImpactDescription: new UntypedFormControl(''),
      siteOwnershipDescription: new UntypedFormControl(''),
      siteSelectionDescription: new UntypedFormControl(''),
      sitePermitDescription: new UntypedFormControl(''),
      siteSustainabilityDescription: new UntypedFormControl('')
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.projectSitesFormGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.projectSitesFormGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.projectSitesFormGroup.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.projectSitesFormGroup.disable() : this.projectSitesFormGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.projectSitesFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
