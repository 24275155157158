import { Component, Input } from '@angular/core';
import { View } from './view.enum';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'oes-view-toggle-button',
  templateUrl: './view-toggle-button.component.html',
  styleUrls: ['./view-toggle-button.component.scss']
})
export class ViewToggleComponent {
  @Input() gridView: boolean;
  @Input() mapView: boolean;

  view = View;

  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute) {
  }

  updateQueryParams(view: View) {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {type: view},
      queryParamsHandling: 'merge'
    });
  }

  isLinkActive(keyword: string): boolean {
    return this._router.url.includes(`type=${keyword}`);
  }
}
