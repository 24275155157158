import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LoadChartType } from './load-chart-type.enum';
import { SystemLoad } from '@project/detail/load/shared/system-load.model';
import { SystemLoadService } from '@project/detail/load/shared/system-load.service';

@Component({
  selector: 'oes-utilization-factor',
  templateUrl: './utilization-factor.component.html',
  styleUrls: ['./utilization-factor.component.scss']
})
export class UtilizationFactorComponent implements OnInit, OnDestroy {
  @Input() readOnly: boolean;
  public systemLoad: SystemLoad;
  public ngUnsubscribe: Subject<any> = new Subject();
  public currentChartType: LoadChartType = LoadChartType.Hour;
  public type = LoadChartType;
  public customerTypes: string[];

  constructor(private _systemLoadService: SystemLoadService) {
  }

  ngOnInit() {
    this.subscribeSystemLoad();
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeSystemLoad() {
    this._systemLoadService.systemLoadSubject$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((systemLoad: SystemLoad) => {
      if (systemLoad) {
        this.systemLoad = systemLoad;
        this.toggleChart(LoadChartType.Hour);
      }
    });
  }

  // Toggle Hour/Day/Month
  public toggleChart(chartType: LoadChartType) {
    this._systemLoadService.chartTypeSubject = chartType;
    // for CSS
    this.currentChartType = chartType;
  }
}
