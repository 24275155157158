import {environment} from '@environments/environment';
import { FORM_ID } from '@finance/developer-portfolio/shared/project-group-form.service';

export class ProjectSetPortfolioApiConstants {
  static projectSetPortfolios = environment.serverUri + `api/${environment.apiVersion}/project-set-portfolios`;

  static projectSetPortfolio = {
    list: () => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}`;
    },
    findAllConnections: () => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/connections/all`;
    },
    create: () => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}`;
    },
    detail: (projectSetPortfolioId: string) => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/${projectSetPortfolioId}`;
    },
    update: (projectSetPortfolioId: string) => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/${projectSetPortfolioId}`;
    },
    delete: (projectSetPortfolioId: string) => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/${projectSetPortfolioId}`;
    },
    shareWithOdyssey: (projectSetPortfolioId: string) => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/share-with-odyssey/${projectSetPortfolioId}`;
    }
  };

  static projectSetPortfolioForm = {
    getForm: (projectSetPortfolioId: string, formId: FORM_ID) => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/${projectSetPortfolioId}/forms/${formId}`;
    },
    updateForm: (projectSetPortfolioId: string, formId: FORM_ID) => {
      return `${ProjectSetPortfolioApiConstants.projectSetPortfolios}/${projectSetPortfolioId}/forms/${formId}`;
    }
  };
}
