import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Project } from '@project/shared/project.model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-map-to-project',
  templateUrl: './map-to-project.component.html',
  styleUrls: ['./map-to-project.component.scss']
})
export class MapToProjectComponent implements ICellRendererAngularComp {
  formGroup: UntypedFormGroup;
  projects: any[];

  private createProject = '';
  private ignoreProject = '';
  private params;

  constructor(private _translateService: TranslateService) {
    this.createProject = this._translateService.instant('data-integration.file.create-project');
    this.ignoreProject = this._translateService.instant('data-integration.file.ignore-project');
  }

  // ICellRendererAngularComp requires
  agInit(params: any): void {
    this.formGroup = new UntypedFormGroup({
      project: new UntypedFormControl()
    });
    this.params = params;
    if (this.params && this.params.context && this.params.context.gridService &&
        this.params.context.gridService.odysseyProjects && this.params.context.gridService.odysseyProjects.length > 0) {
      const projects = this.projectSort(this.params.context.gridService.odysseyProjects).map(project => {
        return {
          id: project.id,
          displayName: project.name.length > 50 ? project.name.substr(0, 50) + '...' : project.name,
          name: project.name
        };
      });
      const extraOptions = [{
        id: 'ignore-project',
        displayName: this.ignoreProject,
        name: 'ignore project'

      }, {
        id: 'create-project',
        displayName: this.createProject,
        name: 'create project'
      }];
      this.projects = [...extraOptions, ...projects];
      this.setInitialValue();
    }
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  private projectSort(projects: Project[]): Project[] {
    return projects.sort((a, b) => {
      const aLow = a.name.toLocaleLowerCase();
      const bLow = b.name.toLocaleLowerCase();
      if (aLow < bLow) {
        return -1;
      }
      if (aLow > bLow) {
        return 1;
      }
      return 0;
    });
  }

  // callback: dropdown
  onProjectChange(event) {
    let selectedProject = {};
    if (event.target.value === 'create-project' ||
        event.target.value !== this.params.data['odysseyProject'].id) {
      selectedProject = {
        row: this.params.rowIndex,
        projectId: event.target.value,
        name: this.params.data['name'],
        country: undefined,
        region: undefined,
        latitude: undefined,
        longitude: undefined,
        cod: undefined
      };
    } else if (event.target.value === 'ignore-project') {
      selectedProject = {
        row: this.params.rowIndex,
        projectId: event.target.value,
        name: undefined,
        country: undefined,
        region: undefined,
        latitude: undefined,
        longitude: undefined,
        cod: undefined
      };
    }
    this.params.context.gridService.projectSelected(selectedProject);
  }

  private setInitialValue() {
    if (this.params.data['odysseyProject'].id) {
      this.formGroup.controls['project'].setValue(this.params.data['odysseyProject'].id);
    }
  }
}
