import { Component, OnInit } from '@angular/core';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { Document } from '@shared/components/files/shared/models/document.model';
import { RequestItem } from '@shared/components/files/shared/models/request-item.model';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import FileSaver from 'file-saver';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-cell-uploader',
  templateUrl: './cell-uploader.component.html',
  styleUrls: ['./cell-uploader.component.scss']
})
export class CellUploaderComponent implements ICellRendererAngularComp {
  documents: Document[];
  params;
  readOnly: boolean;

  private rowData: any;
  private user: User;

  constructor(private _documentDataService: DocumentDataService,
              private _userService: UserService) { }

  downloadDocument(document: Document) {
    const requestItems = [document.documentKey.key];

    this._documentDataService.downloadDocuments(requestItems, this.rowData.project.organization.id)
    .pipe(take(1))
    .subscribe((data: any) => {
      if (data) {
        FileSaver.saveAs(data, document.path);
      }
    });
  }

  deleteDocument(document: Document) {
    const requestItem: RequestItem = {
      docType: 'FILE',
      key: document.documentKey.key
    };

    this._documentDataService.deleteDocuments([requestItem])
    .pipe(take(1))
    .subscribe(response => {
      this.reloadParent();
    });
  }

  parsePath(path: string) {
    return path.split('/')[path.split('/').length - 1];
  }

  reloadParent() {
    this.params.context.gridService.reloadEvent({});
  }

  // ICellRendererAngularComp requires
  agInit(params: any): void {
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.user = user;
      this.params = params;
      this.rowData = params.data;
      this.readOnly = this.rowData?.project?.organization?.id !== this.user?.organization?.id;
      if (this.rowData?.documents) {
        this.documents = this.rowData.documents.map(document => new Document(document));
      } else {
        this.documents = [];
      }
    });
  }

  documentCellEvent(event: any) {
    this.params.context.gridService.documentCellEvent({});
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  // callback: input type="file"
  getFilePath(event: any) {
    this.params.context.gridService.documentUploadEvent({
      data: this.rowData,
      file: event.target.files[0]
    });
  }
}
