import { environment } from '@environments/environment';

export class VendorInformationApiConstants {
  public static organizations = environment.serverUri + `api/${environment.apiVersion}/organizations`;

  public static vendorInformation = {
    list: (organizationId: string) => {
      return `${VendorInformationApiConstants.organizations}/${organizationId}/vendor-information`;
    },
    detail: (organizationId: string, informationId: string) => {
      return `${VendorInformationApiConstants.organizations}/${organizationId}/vendor-information/${informationId}`;
    },
    update: (organizationId: string, informationId: string) => {
      return `${VendorInformationApiConstants.organizations}/${organizationId}/vendor-information/${informationId}`;
    }

  };
}
