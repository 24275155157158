import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: 'oes-grid-cell-button-generic',
  templateUrl: './cell-button-generic.component.html',
  styleUrls: ['./cell-button-generic.component.scss']
})
export class CellButtonGenericComponent implements ICellRendererAngularComp {
  // this can be expanded on if different buttons are needed
  buttonStyle: string = 'link';
  params: any;
  displayActions = true;

  private masterGridApi;
  private masterRowIndex;
  private rowData: any;

  constructor() { }

  agInit(params: any): void {
    if (params.node.allChildrenCount) {
      this.displayActions = false;
    }
    this.params = params;
    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;
    this.rowData = params.data;
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  click() {
    this.params.context.gridService.buttonClicked(
      this.params.key,
      this.rowData
    );
  }
}
