import { BaseModel } from '@shared/base.model';

export class ApplicationStatusRules extends BaseModel<ApplicationStatusRules> {
  name: string;
  color: string;
  disabled: boolean;
  editableApplication: boolean;
  editableApplicationDocuments: boolean;
  canDeleteApplicationDocuments: boolean;
  canRenameApplicationDocuments: boolean;
  viewReporting: boolean;
  allowProjectProgramConnection: boolean;

  constructor(payload: any) {
    super();
    this.name = payload.name;
    this.color = payload.color;
    this.disabled = payload.disabled;
    this.editableApplication = payload.editableApplication;
    this.editableApplicationDocuments = payload.editableApplicationDocuments;
    this.canDeleteApplicationDocuments = payload.canDeleteApplicationDocuments;
    this.canRenameApplicationDocuments = payload.canRenameApplicationDocuments;
    this.viewReporting = payload.viewReporting;
    this.allowProjectProgramConnection = payload.allowProjectProgramConnection;
  }
}
