import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { NumericEditorComponent } from '@shared/ag-grid/component/numeric-editor/numeric-editor.component';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { ProjectService } from '../project.service';
import { ProjectType } from '../project-type.enum';
import { CurrencyPipe } from '@angular/common';

// Do not use global provider for ag-grid
@Injectable()
export class CostEditorGridSettingsService extends BaseAgGridSettingsService {
  _columnApi: ColumnApi;
  _gridApi: GridApi;
  _gridStateStorageKey: string;

  currencyInfo: any;

  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();

  detailCellRendererParams = {};
  errorRows = [];
  unitList = [
    'Unit',
    'Meter',
    'Feet',
    'Customer',
    'kVA',
    'kW',
    'Year',
    'Person',
    'Person-Hour',
    'Kilometer'
  ];
  private _isEditable = true;
  private _typeList: string[];
  private column = [];
  private columnOption = [];
  private components;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _currencyPipe: CurrencyPipe,
              private _projectService: ProjectService) {
    super(_agGridServicesWrapperService);
    _agGridServicesWrapperService._translateService.get('cost.column').subscribe(column => {
      this.column = column;
    });
    this.components = {
      numericEditor: NumericEditorComponent
    };
    if (this._projectService.project.projectType === ProjectType.ci) {
      this.currencyInfo = this._projectService.currencyInfo();
    }
  }

  set typeList(list: any[]) {
    this._typeList = list.map(item => item.value);
  }

  set isEditable(status: boolean) {
    this._isEditable = status;
  }

  getGridOptions(): GridOptions {
    return <GridOptions>{
      alwaysShowStatusBar: false,
      columnDefs: [],
      components: this.components,
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true
      },
      editType: 'fullRow',
      enableCellChangeFlash: true,
      enableRangeSelection: true,
      getRowStyle: (params) => {
        if (this.errorRows.includes(params.node.rowIndex)) {
          return { border: '1px solid #B52514' };
        }
      },
      rowHeight: 80,
      rowSelection: 'multiple',
      singleClickEdit: false,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agAggregationComponent', align: 'right' }
        ]
      },
      stopEditingWhenCellsLoseFocus: true,
      suppressColumnMoveAnimation: true,
      suppressPropertyNamesCheck : true,
      suppressRowClickSelection: true
    };
  }

  getColumnOption() {
    const basicColumn: any[] = [
      {
        headerName: this.column['type'],
        headerTooltip: this.column['type'],
        field: 'source.name',
        unSortIcon: true,
        width: 160,
        comparator: this.caseInsensitiveSort,
        filterParams: {
          clearButton: true
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this._typeList
        },
        editable: (params) => {
          return this._isEditable && !params.data.distributionDesignToolGeneratedFlag;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return !this._isEditable || params.data.distributionDesignToolGeneratedFlag;
          }
        }
      },
      {
        headerName: this.column['details'],
        headerTooltip: this.column['details'],
        field: 'description',
        unSortIcon: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return this._isEditable && !params.data.distributionDesignToolGeneratedFlag;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return !this._isEditable || params.data.distributionDesignToolGeneratedFlag;
          }
        },
        tooltipValueGetter: (params) => {
          if (params && params.value) {
            return params.value;
          }
        }
      },
      {
        headerName: this.column['quantity'],
        headerTooltip: this.column['quantity'],
        field: 'count',
        cellClass: 'number',
        unSortIcon: true,
        width: 140,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return this._isEditable && !params.data.distributionDesignToolGeneratedFlag;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return !this._isEditable || params.data.distributionDesignToolGeneratedFlag;
          }
        },
        cellEditor: 'numericEditor'
      },
      {
        headerName: this.column['unit-cost'],
        headerTooltip: this.column['unit-cost'],
        field: 'unitCost',
        cellClass: 'number',
        unSortIcon: true,
        width: 140,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return this._isEditable && !params.data.distributionDesignToolGeneratedFlag;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return !this._isEditable || params.data.distributionDesignToolGeneratedFlag;
          }
        },
        cellRenderer: (params) => {
          let perYear = '';
          if (params.value && params.data.source.projectCostType === 'OPERATING_COSTS') {
            perYear = '/yr';
          }
          if (this.currencyInfo !== null && this.currencyInfo !== undefined && this.currencyInfo?.currency !== 'USD') {
            return this._currencyPipe.transform(params.value, this.currencyInfo.currency, this.currencyInfo.symbol, '1.2-2') + perYear;
          }
          return this.formatCurrency(params, this.currentLang) + perYear;
        }
      },
      {
        headerName: this.column['unit'],
        headerTooltip: this.column['unit'],
        field: 'units',
        cellClass: 'number',
        width: 140,
        unSortIcon: true,
        comparator: this.caseInsensitiveSort,
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return this._isEditable && !params.data.distributionDesignToolGeneratedFlag;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return !this._isEditable || params.data.distributionDesignToolGeneratedFlag;
          }
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this.unitList
        },
      },
      {
        headerName: this.column['total-cost'],
        headerTooltip: this.column['total-cost'],
        field: 'totalCost',
        cellClass: 'number',
        unSortIcon: true,
        width: 140,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellRenderer: (params) => {
          let perYear = '';
          if (params.value && params.data.source.projectCostType === 'OPERATING_COSTS') {
            perYear = '/yr';
          }
          if (this.currencyInfo !== null && this.currencyInfo !== undefined && this.currencyInfo?.currency !== 'USD') {
            return this._currencyPipe.transform(params.value, this.currencyInfo.currency, this.currencyInfo.symbol, '1.2-2') + perYear;
          }
          return this.formatCurrency(params, this.currentLang) + perYear;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return !this._isEditable || params.data.distributionDesignToolGeneratedFlag;
          }
        }
      },
    ];

    if (this._isEditable) {
      const checkbox = {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left'
      };
      basicColumn.unshift(checkbox);
    }

    return basicColumn;
  }
}
