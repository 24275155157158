<div class="d-flex w-100 justify-content-between">
  <ng-container *ngIf="!readOnly && params?.data.id">
    <label for="upload-btn-{{ params?.data.id }}">
      <i class="fa fa-plus-circle"
         (click)="documentCellEvent()"
         aria-hidden="true">
      </i>
    </label>
    <input type="file"
           id="upload-btn-{{ params?.data.id }}"
           (change)="getFilePath($event)"/>
  </ng-container>

  <div class="documents-container">
    <div *ngFor="let document of documents"
         class="document-container">
      <p (click)="downloadDocument(document)">{{ document.pathTail }}</p>
      <i *ngIf="!readOnly"
         class="fa fa-times-circle"
         (click)="deleteDocument(document)">
      </i>
    </div>
  </div>
</div>
