import {BaseModel} from '@shared/base.model';
import {Role} from './role.model';
import {Organization} from '../shared/models/organization/organization.model';
import { ROLE_TYPE } from './role-type';

export class User extends BaseModel<User> {
  active: boolean;
  canUpdateHomerData: boolean;
  created: Date;
  emailAddress: string;
  eulaAgreementDateTime: Date;
  firstName: string;
  lastLogin: Date;
  lastName: string;
  organization: Organization;
  passwordChanged: Date;
  roles: Array<Role>;
  updated: Date;
  username: string;

  primaryPhone?: string;
  selectedRole?: ROLE_TYPE;

  constructor(userInfo: any) {
    super();
    this.id = userInfo.id;
    this.canUpdateHomerData = userInfo.canUpdateHomerData;
    this.username = userInfo.username;
    this.emailAddress = userInfo.emailAddress;
    this.firstName = userInfo.firstName;
    this.lastName = userInfo.lastName;
    this.primaryPhone = userInfo.primaryPhone;
    this.lastLogin = userInfo.lastLogin;
    this.active = userInfo.active;
    this.eulaAgreementDateTime = userInfo.eulaAgreementDateTime;
    this.passwordChanged = userInfo.passwordChanged;
    this.roles = [];
    this.created = userInfo.created;
    if (userInfo?.roles?.length > 0) {
      this.roles = userInfo.roles;
    }
    if (userInfo.organization) {
      this.organization = new Organization(userInfo.organization);
    } else {
      this.organization = new Organization({});
    }
  }

  /**
   * @param roleType: ROLE_TYPE
   * @returns boolean
   */
  public hasRole(roleType: ROLE_TYPE): boolean {
    return this.roles?.some(role => role.name === roleType);
  }
}
