import { Injectable } from '@angular/core';

export interface Color {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCategoryPaletteService {

  constructor() { }

  public getColorByName(name: string): string {
    if (name && name !== '') {
      const colorItem = this.palette.find(value => {
        return value.name === name.toLowerCase();
      });

      if (colorItem) {
        return colorItem.value;
      }
    }

    return '#FFF';
  }

  get twoTones(): string[] {
    return [
      '#006438',
      '#C9C9C9'
    ];
  }

  /*
   * return [orange, grey, blue]
   */
  get threeTones(): string[] {
    return [
      '#FF7F00',
      '#C9C9C9',
      '#30819F'
    ];
  }

  /**
   * return Blue, Light Green, Dark Green and Light Grey
   */
  get customDashboard(): string[] {
    return [
      '#0081A3',
      '#00C38B',
      '#006C36',
      '#ebebeb'
    ];
  }

  /*
   * return { name: customer category name, value: #RGB }
   */
  get palette(): Color[] {
    return [
      {
        name: 'internal load',
        value: '#CC2A16',
      },
      {
        name: 'public',
        value: '#30819F',
      },
      {
        name: 'anchor',
        value: '#C9C9C9',
      },
      {
        name: 'productive',
        value: '#FF7F00',
      },
      {
        name: 'commercial',
        value: '#0DBC8C',
      },
      {
        name: 'residential',
        value: '#F7B12D',
      },
      {
        name: 'unknown',
        value: '#F3F5F5'
      },
      {
        name: 'other',
        value: '#C9CBCB'
      }
    ];
  };

  /**
   * returns green base gradient colors
   */
  get paletteGeneral(): Color[] {
    return [
      {
        name: '',
        value: '#006438'
      },
      {
        name: '',
        value: '#289C3B'
      },
      // {
      //   name: '',
      //   value: '#73D866'
      // },
      {
        name: '',
        value: '#ABE8A3'
      },
      {
        name: '',
        value: '#C7EFC2'
      },
      {
        name: '',
        value: '#025F80'
      },
      {
        name: '',
        value: '#357F99'
      },
      {
        name: '',
        value: '#679FB3'
      },
      {
        name: '',
        value: '#A4C5D1'
      },
      {
        name: '',
        value: '#CCDFE6'
      },
      {
        name: '',
        value: '#FF7F00'
      },
      {
        name: '',
        value: '#FFB266'
      }
    ];
  }

  get paletteMoreColor(): Color[] {
    return [
      {
        name: '',
        value: '#499893'
      },
      {
        name: '',
        value: '#85BCB6'
      },
      {
        name: '',
        value: '#FF9D9A'
      },
      {
        name: '',
        value: '#78706E'
      },
      {
        name: '',
        value: '#D47295'
      },
      {
        name: '',
        value: '#9FCCE8'
      },
      {
        name: '',
        value: '#FFBF7D'
      },
      {
        name: '',
        value: '#8CD17C'
      },
      {
        name: '',
        value: '#B6992D'
      },
      {
        name: '',
        value: '#F1CE63'
      },
      {
        name: '',
        value: '#FABFD2'
      },
      {
        name: '',
        value: '#B07AA1'
      },
      {
        name: '',
        value: '#9D755F'
      },
      {
        name: '',
        value: '#D7B5A6'
      },
      {
        name: '',
        value: '#4E79A7'
      },
      {
        name: '',
        value: '#F28D2C'
      },
      {
        name: '',
        value: '#59A14F'
      }
    ];
  }

  /**
   * @param startColorHex: string. Dark/Cold color with hex (without #)
   * @param endColorHex: string. Bright/Hot color with hex (without #)
   * @param steps: gradient step number of dark color to bright color
   * @param middleColorHex: string. Middle color when you want to use tree tone color.
   */
  public generateGradientPalette(startColorHex: string, endColorHex: string, steps = 10): string[] {
    if (steps === 1) {
      return ['#' + endColorHex];
    } else if (steps === 2) {
      return ['#' + startColorHex, '#' + endColorHex];
    }

    // split and convert
    const startColor = this.hex2Rgb(startColorHex);

    // split and convert
    const endColor = this.hex2Rgb(endColorHex);

    let divDec: any;
    const color = [];
    // increment value
    divDec = {
      r: (endColor.r - startColor.r) / (steps),
      g: (endColor.g - startColor.g) / (steps),
      b: (endColor.b - startColor.b) / (steps)
    };

    color.push('#' + startColorHex);
    for (let i = 1; i <= steps - 2; i++) {
      color.push(
        '#' + this.hex2String(startColor.r + (divDec.r * i)) +
              this.hex2String(startColor.g + (divDec.g * i)) +
              this.hex2String(startColor.b + (divDec.b * i))
      );
    }
    color.push('#' + endColorHex);
    return color;
  }

  private hex2Rgb(color: string): {r: number, g: number, b: number} {
    return {
      r: parseInt(color.substr(0, 2), 16),
      g: parseInt(color.substr(2, 2), 16),
      b: parseInt(color.substr(4, 2), 16)
    };
  }

  private hex2String(n: number): string {
    return ('0' + Math.round(n).toString(16)).slice(-2);
  }

  public generateGradientHex(startColorHex: string, endColorHex: string, floatNumber: number, level = 100): string {
    if (floatNumber > 1) {
      return '#' + endColorHex;
    }
    if (floatNumber < 0.0) {
      return '#' + startColorHex;
    }

    // split cold color to r, g and b.
    const startColor = {
      r: parseInt(startColorHex.substr(0, 2), 16),
      g: parseInt(startColorHex.substr(2, 2), 16),
      b: parseInt(startColorHex.substr(4, 2), 16)
    };

    // split hot color to r, g and b.
    const endColor = {
      r: parseInt(endColorHex.substr(0, 2), 16),
      g: parseInt(endColorHex.substr(2, 2), 16),
      b: parseInt(endColorHex.substr(4, 2), 16)
    };

    // color step value
    const divDec = {
      r: (endColor.r - startColor.r) / level,
      g: (endColor.g - startColor.g) / level,
      b: (endColor.b - startColor.b) / level
    };

    const newDec = {
      r: startColor.r + (divDec.r * floatNumber * level),
      g: startColor.g + (divDec.g * floatNumber * level),
      b: startColor.b + (divDec.b * floatNumber * level)
    };

    return '#' +
      Math.round(newDec.r).toString(16) +
      Math.round(newDec.g).toString(16) +
      Math.round(newDec.b).toString(16);
  }
}
