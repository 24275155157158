import { BaseModel } from '@shared/base.model';
import { Project } from '@project/shared/project.model';
import { Organization } from '@shared/models/organization/organization.model';
import { Program } from '@program/shared/program.model';
import { QuoteConnection } from '@procurement/quote/shared/quote-connection.model';
import { FinanceConnection } from '@finance/developer-portfolio/shared/finance-connection.model';
import { FinanceIntroduction } from '@finance/developer-portfolio/shared/finance-introduction.model';

export class ProjectGroup extends BaseModel<ProjectGroup> {
  lastUpdatedDateTime: string;
  name: string;
  projects: Project[];
  financeConnections: FinanceConnection[];
  quote: boolean;
  financeDeal;
  // This is just data that is only populated in lists of financial deals.
  primaryImageId: string;
  primaryImageUri: string;
  currentConnection: FinanceConnection;
  currentQuoteConnections: QuoteConnection[];
  developerOrganization: Organization;
  totalConnections: number;
  countries: string;
  regions: string;
  totalProjects: number;
  totalCapacity: number;
  totalSubsidy: number;
  financeIntroduction: FinanceIntroduction;
  /**
   * @deprecated tender && tenderDeal
   */
  tender: Program;
  tenderDeal: boolean;
  program: Program;
  programDeal: boolean;
  businessPlanUpdateDateTime: Date;
  businessPlanValidationDateTime: Date;
  checklistUpdateDateTime: Date;
  checklistValidationDateTime: Date;

  // Set on FE if necessary (transient)
  isPastSubmissionDate: boolean;
  businessPlanComplete: boolean;
  checklistComplete: boolean;

  constructor(projectGroupInfo: any) {
    super();
    this.id = projectGroupInfo.id;
    this.financeDeal = projectGroupInfo.financeDeal;
    this.quote = projectGroupInfo.quote;
    this.lastUpdatedDateTime = projectGroupInfo.lastUpdatedDateTime;
    this.name = projectGroupInfo.name;
    this.totalConnections = projectGroupInfo.totalConnections;
    this.primaryImageId = projectGroupInfo.primaryImageId;
    if (projectGroupInfo.currentConnection != null) {
      this.currentConnection = new FinanceConnection(projectGroupInfo.currentConnection);
    }
    if (projectGroupInfo.currentQuoteConnections != null && projectGroupInfo.currentQuoteConnections.length) {
      this.currentQuoteConnections = [];
      projectGroupInfo.currentQuoteConnections.forEach(connection => {
        this.currentQuoteConnections.push(new QuoteConnection(connection));
      });
    }
    if (projectGroupInfo.developerOrganization != null) {
      this.developerOrganization = new Organization(projectGroupInfo.developerOrganization);
    }
    if (projectGroupInfo.financeIntroduction != null) {
      this.financeIntroduction = new FinanceIntroduction(projectGroupInfo.financeIntroduction);
    }
    /**
     * @deprecated tenderDeal
     * TODO: remove || statement
     */
    this.tenderDeal = projectGroupInfo.programDeal || projectGroupInfo.tenderDeal;
    this.programDeal = projectGroupInfo.programDeal || projectGroupInfo.tenderDeal;
    /**
     * @deprecated tenderDeal
     * TODO: remove || statements
     */
    this.tender = new Program(projectGroupInfo.program || projectGroupInfo.tender || {});
    this.program = new Program(projectGroupInfo.program || projectGroupInfo.tender || {});
    this.totalConnections = projectGroupInfo.totalConnections;
    this.totalCapacity = projectGroupInfo.totalCapacity;
    this.totalProjects = projectGroupInfo.totalProjects;
    this.totalSubsidy = projectGroupInfo.totalSubsidy;
    this.countries = projectGroupInfo.countries;
    this.regions = projectGroupInfo.regions;
    this.financeConnections = [];
    if (projectGroupInfo.financeConnections != null && projectGroupInfo.financeConnections.length > 0) {
      for (let i = 0; i < projectGroupInfo.financeConnections.length; i++) {
        const conn = new FinanceConnection(projectGroupInfo.financeConnections[i]);
        // Filter out connections that have ended or been declined.  At some point, the back-end will automatically
        // filter these out.
        if (!conn.ended() || !conn.declined()) {
          this.financeConnections.push(conn);
        }
      }
    }
    this.businessPlanUpdateDateTime = projectGroupInfo.businessPlanUpdateDateTime;
    this.businessPlanValidationDateTime = projectGroupInfo.businessPlanValidationDateTime;
    this.checklistUpdateDateTime = projectGroupInfo.checklistUpdateDateTime;
    this.checklistValidationDateTime = projectGroupInfo.checklistValidationDateTime;
  }

  hasActiveConnection(): boolean {
    let hasActiveConnection = false;
    for (let i = 0; i < this.financeConnections.length; i++) {
      if (this.financeConnections[i].accepted()) {
        return hasActiveConnection = true;
      }
    }
    return hasActiveConnection;
  }
}
