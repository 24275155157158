import { BaseModel } from '../../base.model';

export class OrganizationFilter extends BaseModel<OrganizationFilter> {
  name: string;
  slug: string;
  vendor: boolean;
  developer: boolean;
  financier: boolean;

  constructor(filterInfo: any) {
    super();
    this.name = filterInfo.name;
    this.slug = filterInfo.slug;
    this.vendor = filterInfo.vendor;
    this.developer = filterInfo.developer;
    this.financier = filterInfo.financier;
  }
}
