import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { IMultiSelectOption, IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { RegionService } from '@shared/services/region/region.service';
import { Region } from '@shared/services/region/region.model';
import { take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { CountryService } from '@shared/services/country/country.service';
import { Country } from '@shared/services/country/country.model';

@Component({
  selector: 'oes-cell-region-selector',
  templateUrl: './cell-region-selector.component.html',
  styleUrls: ['./cell-region-selector.component.scss']
})
export class CellRegionSelectorComponent implements INoRowsOverlayAngularComp {
  public regions: IMultiSelectOption[];
  public selectedRegion: string[];
  public dropdownSettings: IMultiSelectSettings = {
    enableSearch: true,
    selectionLimit: 1,
    minSelectionLimit: 1,
    checkedStyle: 'fontawesome',
    containerClasses: 'oes-region-select-container',
    buttonClasses: 'oes-region-select-button',
    itemClasses: 'oes-region-select-item',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: false,
    showCheckAll: false,
    showUncheckAll: false,
    autoUnselect: true,
    closeOnSelect: true
  };

  constructor(private _regionService: RegionService,
              private _countryService: CountryService) { }

  agInit(params: any) {
    const paramCountry = params.data.odysseyProject.country;
    if (paramCountry && paramCountry.name) {
      if (paramCountry.id) {
        // select a country an from dropdown
        this.getRegions(paramCountry.id);
      } else if (paramCountry.name && paramCountry.name !== '') {
        // copied a country name from an imported project
        let countryId = this._countryService.getCountryId(paramCountry.name);
        if (!countryId) {
          this._countryService.getCountries()
          .pipe(take(1))
          .subscribe((countries: Country[]) => {
            this._countryService.countries = countries;
            countryId = this._countryService.getCountryId(paramCountry.name);
            this.getRegions(countryId);
          });
        } else {
          this.getRegions(countryId);
        }
      }
    }
  }

  private getRegions(countryId: string) {
    const httpParams = new HttpParams().set('countryId', countryId);
    this._regionService.getRegions({params: httpParams})
    .pipe(take(1))
    .subscribe((regions: Region[]) => {
      // if the country has no region, it will be an empty []
      this.regions = regions;
    });
  }

  getValue(): any {
    // single selection
    return this.regions ? this.regions.find(country => country.id === this.selectedRegion[0]) : undefined;
  }

  isPopup() {
    return true;
  }
}
