import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectSetFinancialModelApiConstants } from './project-set-financial-model.constant';
import { ProjectSetFinancialModel } from './project-set-financial-model.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetFinancialModelService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  detail(projectSetId: string): Observable<ProjectSetFinancialModel> {
    return this.get<ProjectSetFinancialModel>(ProjectSetFinancialModelApiConstants.projectSetFinancialModels.detail(projectSetId))
      .pipe(map((psfm: ProjectSetFinancialModel) => {
        if (psfm) {
          return new ProjectSetFinancialModel(psfm);
        }
        return null;
      }));
  }

  run(projectSetId: string): Observable<ProjectSetFinancialModel> {
    return this.put<ProjectSetFinancialModel>(ProjectSetFinancialModelApiConstants.projectSetFinancialModels.run(projectSetId))
      .pipe(map((psfm: ProjectSetFinancialModel) => {
        return new ProjectSetFinancialModel(psfm);
      }));
  }

  updateToLatestModel(projectSetId: string, projectSetFinancialModelId: string): Observable<ProjectSetFinancialModel> {
    return this.put<ProjectSetFinancialModel>(ProjectSetFinancialModelApiConstants.projectSetFinancialModels.updateToLatestModel(projectSetId, projectSetFinancialModelId))
    .pipe(map((psfm: ProjectSetFinancialModel) => {
      return new ProjectSetFinancialModel(psfm);
    }));
  }

  downloadRun(projectSetId: string, runId: string): Observable<any> {
    const params = new HttpParams();
    const options = {
      responseType: 'blob' as const,
      params
    };
    return this.get<any>(ProjectSetFinancialModelApiConstants.projectSetFinancialModels.exportRun(projectSetId, runId), options);
  }
}
