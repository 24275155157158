import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'oes-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() autofocus: boolean = false;
  @Input() class: string = '';
  @Input() disabled: boolean = false;
  @Input() form: string;
  @Input() formaction: string;
  @Input() formenctype: string;
  @Input() formmethod: string;
  @Input() formnovalidate: boolean = false;
  @Input() formtarget: string;
  @Input() leadingIconSrc: string = '';
  @Input() name: string;
  @Input() ngButtonClass: any;
  @Input() trailingArrow: boolean = false;
  @Input() trailingIconSrc: string = '';
  @Input() type: string = 'button';
  @Input() value: string;

  @Output() buttonClick = new EventEmitter<Event>();

  constructor(private el: ElementRef) {};

  ngOnInit() {
    this.el.nativeElement.classList = '';
  };

  handleClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.buttonClick.emit(event);
    }
  };
}
