<oes-modal-dialog
  #offtakerModal
  id="offtakerModal"
  [allowCancel]="true"
  [allowSubmit]="true"
  [hideCloseBtn]="true"
  [size]="'medium'"
  (submit)="handleSubmitOfftaker()"
  (close)="closeOfftakerModal()">
  <div class="form-header">
    <h3>{{ (action === 'create' ? 'offtaker.form.add-offtaker' : 'offtaker.form.edit-offtaker') | translate }}</h3>
    <button
      class="btn btn-fresh btn-link"
      (click)="deleteOfftakerModal.show()"
      *ngIf="action === 'edit'">
      <img src="assets/icons/line-icons/shared/red/trash.svg" role="presentation" />
      {{ 'offtaker.form.delete-offtaker' | translate }}
    </button>
  </div>
  <form *ngIf="formGroup" [formGroup]="formGroup" class="dialog-form">
    <div>
      <oes-text-input
        [label]="'offtaker.form.customer-name' | translate"
        [placeholder]="'offtaker.form.customer-name-placeholder' | translate"
        [formControlName]="'customerName'"
        [fullWidth]="true"
        [required]="true">
      </oes-text-input>
      <span *ngIf="showError('customerName', 'required')" class="color-error">
        {{ 'offtaker.form.error.required' | translate }}
      </span>
      <span *ngIf="showError('customerName', 'maxlength')" class="color-error">
        {{ 'offtaker.form.error.max-length' | translate }}
      </span>
      <span *ngIf="duplicateName" class="color-error">
        {{ 'offtaker.form.error.duplicate-name' | translate }}
      </span>
    </div>

    <div class="offtaker-dropdown">
      <label>
        {{ 'offtaker.form.business-type' | translate }}<span class="color-brand">*</span>
      </label>
      <oes-dropdown
        #offtakerSelector
        id="offtakerSelector"
        class="style-overhaul-flag lg"
        [ngClass]="{ 'color-override': !formGroup.touched }"
        [parentFormGroup]="formGroup"
        [items]="offtakerTypeOptions"
        [selection]="'single'"
        [enableSearch]="false"
        [enableUncheckAll]="false">
      </oes-dropdown>
      <span class="placeholder" *ngIf="!businessType">{{ 'offtaker.form.select-offtaker' | translate }}</span>
      <span *ngIf="showBusinessTypeError" class="color-error">
        {{ 'offtaker.form.error.required' | translate }}
      </span>
    </div>

    <div>
    <oes-text-input
      [label]="'offtaker.form.email-address' | translate"
      [placeholder]="'offtaker.form.email-address-placeholder' | translate"
      [formControlName]="'email'"
      [fullWidth]="true"
      [required]="true">
    </oes-text-input>
    <span *ngIf="showError('email', 'required')" class="color-error">
      {{ 'offtaker.form.error.required' | translate }}
    </span>
    <span *ngIf="showError('email', 'email')" class="color-error">
      {{ 'offtaker.form.error.email' | translate }}
    </span>
    <span *ngIf="showError('email', 'maxlength')" class="color-error">
      {{ 'offtaker.form.error.max-length' | translate }}
    </span>
    <span *ngIf="duplicateEmail" class="color-error">
      {{ 'offtaker.form.error.duplicate-email' | translate }}
    </span>
  </div>
  </form>
</oes-modal-dialog>

<oes-modal-dialog
  #deleteOfftakerModal
  id="deleteOfftakerModal"
  [allowCancel]="true"
  [allowSubmit]="true"
  [hideCloseBtn]="true"
  [destructive]="true"
  [size]="'small'"
  [title]="'offtaker.form.delete-offtaker' | translate"
  (submit)="handleDeleteOfftaker()"
  [submitButtonText]="'buttons.delete' | translate"
  (close)="deleteOfftakerModal.hide()">
  {{ 'offtaker.form.delete-warning' | translate }}
</oes-modal-dialog>
