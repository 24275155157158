import { Component } from '@angular/core';

@Component({
  selector: 'oes-procure-banner-ad',
  templateUrl: './procure-banner-ad.component.html',
  styleUrls: ['./procure-banner-ad.component.scss'],
  providers: []
})
export class ProcureBannerAdComponent {

  constructor() {
  }
}
