import {BaseModel} from '@shared/base.model';

export class FinanceTier extends BaseModel<FinanceTier> {
  arpu: number;
  count: number;
  tierCategory: string;
  totalRevenuesPerYear: number;
  totalKWServedPerYear: number;
  averageKwPerUserPerDay: number;

  constructor(financeTierInfo: any) {
    super();
    this.arpu = financeTierInfo.arpu;
    this.count = financeTierInfo.count;
    this.tierCategory = financeTierInfo.tierCategory;
    this.totalRevenuesPerYear = financeTierInfo.totalRevenuesPerYear;
    this.totalKWServedPerYear = financeTierInfo.totalKWServedPerYear;
    this.averageKwPerUserPerDay = financeTierInfo.averageKwPerUserPerDay;
  }
}
