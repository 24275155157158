/**
 * @fileoverview added by tsickle
 * Generated from: formio.config.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
import { Injectable } from '@angular/core';
var FormioAppConfig = /** @class */function () {
  function FormioAppConfig() {
    this.appUrl = '';
    this.apiUrl = '';
  }
  FormioAppConfig.decorators = [{
    type: Injectable
  }];
  return FormioAppConfig;
}();
export { FormioAppConfig };
if (false) {
  /** @type {?} */
  FormioAppConfig.prototype.appUrl;
  /** @type {?} */
  FormioAppConfig.prototype.apiUrl;
  /** @type {?} */
  FormioAppConfig.prototype.icons;
  /** @type {?} */
  FormioAppConfig.prototype.formOnly;
}