import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'oes-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss']
})
export class TimeSelectorComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() date: Date;

  constructor() { }

  ngOnInit() {
    this.formGroup.controls[this.controlName].setValue(this.date);
  }
}
