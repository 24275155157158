import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-cell-status',
  templateUrl: './cell-status.component.html',
  styleUrls: ['./cell-status.component.scss']
})
export class CellStatusComponent implements ICellRendererAngularComp {
  private params;
  private masterGridApi;
  private masterRowIndex;
  private rowData: any;

  constructor() { }

  // ICellRendererAngularComp requires
  agInit(params: any): void {
    this.params = params;
    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;
    this.rowData = params.data;
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  getStatusClass() {
    if (this.rowData) {
      return this.rowData.enabled ? 'green' : 'red';
    }
    return 'grey';
  }

  getStatusText() {
    if (this.rowData) {
      return this.rowData.enabled ? 'active' : 'inactive';
    }
    return 'inactive';
  }
}
