import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LanguageService } from '@global/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class CsvExporterService {
  currentLang = 'en';

  constructor(private _languageService: LanguageService) {
    this.currentLang = this._languageService.getLanguage();
  }

  exportCsv(data: object[], prefix: string): void {
    const url = this.convertToCsv(data);
    const link = document.createElement('a');
    const filename = `${prefix}_${new DatePipe(this.currentLang).transform(new Date(), 'yyyy-MM-dd')}.csv`;
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  convertToCsv(data: any[]): string {
    const titleKeys = Object.keys(data[0]);
    const result = [];
    // Put values in quotes and escape inner quotes
    result.push(titleKeys.map(key => `"${key.replace(/"/g, '""')}"`));
    data.forEach((item) => {
      const values = Object.values(item).map(value =>
        `"${String(value).replace(/"/g, '""')}"`
      );
      result.push(values);
    });

    let csvContent = '';
    result.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    return URL.createObjectURL(blob);
  }
}
