import { DuplicationCheckRequirement } from './duplication-check-requirement.enum';
import { GrantAmountPerConnectionType } from './grant-amount-per-connection-type.enum';

export class ProgramSettings {
  public documents: boolean;
  public tenderedProjects: boolean;
  public tenderedPortfolios: boolean;
  public assetsProjects: boolean;
  public assetsPortfolios: boolean;
  public ciPortfolioSubmissions: boolean;
  public reportingTab: boolean;
  public claimTab: boolean;
  public duplicationCheckRequirement: DuplicationCheckRequirement;
  public grantAmountPerConnectionType: GrantAmountPerConnectionType;

  constructor(payload: any) {
    this.documents = payload?.documents;
    this.tenderedProjects = payload?.tenderedProjects;
    this.tenderedPortfolios = payload?.tenderedPortfolios;
    this.assetsProjects = payload?.assetsProjects;
    this.assetsPortfolios = payload?.assetsPortfolios;
    this.ciPortfolioSubmissions = payload?.ciPortfolioSubmissions;
    this.reportingTab = payload?.reportingTab;
    this.claimTab = payload?.claimTab;
    this.duplicationCheckRequirement = payload?.duplicationCheckRequirement;
    this.grantAmountPerConnectionType = payload?.grantAmountPerConnectionType;
  }
}
