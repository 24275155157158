import { Component, Input } from '@angular/core';

@Component({
  selector: 'oes-portfolio-generation-block',
  templateUrl: './generation-block.component.html',
  styleUrls: ['./generation-block.component.scss']
})
export class GenerationBlockComponent {
  @Input() imageUrl: string;
  @Input() title = '';
  @Input() average = 0;
  @Input() averageUnit = '';
  @Input() total = 0;
  @Input() totalUnit = '';

  constructor() { }

}
