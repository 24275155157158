import {BaseModel} from './base.model';

export class ReferenceType extends BaseModel<ReferenceType> {
    code: string;
    displayName: string;

    constructor(ReferenceTypeInfo: any) {
        super();
        this.id = ReferenceTypeInfo.id;
        this.code = ReferenceTypeInfo.code;
        this.displayName = ReferenceTypeInfo.displayName;
    }
}

