import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventService } from '@shared/event.service';
import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { TranslateService } from '@ngx-translate/core';

export enum FORM_ID {
  BUSINESS_PLAN = 'BUSINESS_PLAN',
  CHECKLIST = 'CHECKLIST'
};

// Note: Perhaps this service goes to Form Manager
@Injectable({
  providedIn: 'root'
})
export class ProjectGroupFormService extends BaseRestApiService {
  private projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _dynamicFormService: DynamicFormService,
              private _translateService: TranslateService,
              private _eventService: EventService) {
    super(_restApiWrapperService);
  }

  /**
   * Save/Upload a form
   * @param projectGroupId: string
   * @param fileName: string
   * @param json: {}
   */
  public uploadForm(projectGroupId: string, fileName: string, json: {}, formValid?: boolean): Observable<any> {
    const jsonObject = this._dynamicFormService.removeBlankNodes({...json});
    const formId = this.findFormId(fileName);
    if (formId) {
      const file = new File([JSON.stringify(jsonObject)], fileName, {type: 'text/json'});
      const formData = new FormData();
      const options = { params: { formValid: formValid !== null ? formValid : false } };
      formData.append('file', file);
      return this.put<any>(`${this.projectGroups}/${projectGroupId}/forms/${formId}`, formData, options);
    } else {
      this._eventService.error(this._translateService.instant('error-message.file-name-not-supported'));
    }
  }

  public getForm(projectGroupId: string, formId: FORM_ID): Observable<any> {
    return this.get<any>(`${this.projectGroups}/${projectGroupId}/forms/${formId}`);
  }

  private findFormId(fileName: string): FORM_ID {
    let result: FORM_ID;
    switch (fileName) {
      case this.getFormFilesList()['portfolioDescription']:
        result = FORM_ID.BUSINESS_PLAN;
        break;
      case this.getFormFilesList()['portfolioDocumentChecklist']:
        result = FORM_ID.CHECKLIST;
        break;
    }
    return result;
  }

  public getFormFilesList(): {} {
    return {
      portfolioDescription: 'Form-Portfolio-Description.json',
      portfolioDocumentChecklist: 'Form-Portfolio-Document-Checklist.json'
    };
  }
}
