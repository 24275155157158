import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DeveloperInformation } from './developer-information.model';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { DeveloperInformationApiConstants } from './developer-information.constant';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeveloperInformationService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  // TODO: backend returns no array. What is the difference with detail and this endpoint
  public list(organizationId: string): Observable<DeveloperInformation> {
    return this.get<DeveloperInformation>(
            DeveloperInformationApiConstants.developerInformation.list(organizationId),
            {params: {page: 0, size: 1000}}
           ).pipe(
             map(organization => new DeveloperInformation(organization))
           );
  }

  public detail(organizationId: string, informationId: string): Observable<DeveloperInformation> {
    return this.get<DeveloperInformation>(
            DeveloperInformationApiConstants.developerInformation.detail(organizationId, informationId)
           ).pipe(
             map(organization => new DeveloperInformation(organization))
           );
  }


  public update(organizationId: string, developerInformation: DeveloperInformation): Observable<any> {
    return this.put(
             DeveloperInformationApiConstants.developerInformation.update(organizationId, developerInformation.id),
             JSON.stringify(developerInformation)
           );
  }
}
