import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { map, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { ProjectSet } from './project-set.model';
import { ProjectSetApiConstants } from './project-set.constant';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public detail(projectSetId: string): Observable<ProjectSet> {
    return this.get<ProjectSet>(ProjectSetApiConstants.projectSet.detail(projectSetId))
      .pipe(map((ps: ProjectSet) => {
        return new ProjectSet(ps);
      }));
  }

  public list(params: any = {page: 0, size: 1000}): Observable<ProjectSet[]> {
    return this.get<ProjectSet[]>(ProjectSetApiConstants.projectSet.list(), {params: params})
      .pipe(map((projectSets: ProjectSet[]) => {
        return projectSets.map(projectSet => new ProjectSet(projectSet));
      }));
  }

  public create(projectSet: ProjectSet): Observable<ProjectSet> {
    return this.post(ProjectSetApiConstants.projectSet.create(), JSON.stringify(projectSet))
      .pipe(map((ps: ProjectSet) => {
        return new ProjectSet(ps);
      }));
  }

  public update(projectSet: ProjectSet): Observable<ProjectSet> {
    return this.put(ProjectSetApiConstants.projectSet.update(projectSet.id), JSON.stringify(projectSet))
      .pipe(map((ps: ProjectSet) => {
        return new ProjectSet(ps);
      }));
  }

  public remove(projectSetId: string): Observable<any> {
    return this.delete(ProjectSetApiConstants.projectSet.delete(projectSetId));
  }
}
