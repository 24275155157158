import { BaseModel } from '@shared/base.model';
import { EntityType } from './entity-type.enum';
import { EvaluationQuestionType } from './evaluation-question-type.enum';

export class EvaluationConfig extends BaseModel<EvaluationConfig> {
  configuredEntityId: string;
  entityType: EntityType;
  name: string;
  description: string;
  type: EvaluationQuestionType;
  weight: number;
  rangeStart: number;
  rangeEnd: number;
  ownerId: string;
  index: number;
  published: boolean;

  constructor(payload: any) {
    super();
    this.id = payload.id;
    this.configuredEntityId = payload.configuredEntityId;
    this.entityType = payload.entityType;
    this.name = payload.name;
    this.description = payload.description;
    this.type = payload.type;
    this.weight = payload.weight;
    this.rangeStart = payload.rangeStart;
    this.rangeEnd = payload.rangeEnd;
    this.ownerId = payload.ownerId;
    this.index = payload.index;
    this.published = payload.published;
  }
}
