import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, Validator,
         AbstractControl, ValidationErrors } from '@angular/forms';
import { FinanceBusinessPlan } from '@finance/developer-portfolio/business-plan/finance-business-plan.model';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'oes-business-plan-other-activities',
  templateUrl: './other-activities.component.html',
  styleUrls: ['./other-activities.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessPlanOtherActivitiesComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessPlanOtherActivitiesComponent),
      multi: true
    }
  ]
})
export class BusinessPlanOtherActivitiesComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() financeBusinessPlan: FinanceBusinessPlan;
  @Input() isPastSubmissionDeadline = false;

  public otherActivitiesFormGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.otherActivitiesFormGroup = new UntypedFormGroup({
      // quill
      equipmentLeasingDescription: new UntypedFormControl(''),
      waterSanitationDescription: new UntypedFormControl(''),
      smallBusinessFinancingDescription: new UntypedFormControl(''),
      otherOpportunitiesDescription: new UntypedFormControl(''),
      // checkbox
      equipmentLeasing: new UntypedFormControl(
        {value: this.financeBusinessPlan.equipmentLeasing, disabled: this.isPastSubmissionDeadline},
        null),
      waterSanitation: new UntypedFormControl(
        {value: this.financeBusinessPlan.waterSanitation, disabled: this.isPastSubmissionDeadline},
        null),
      smallBusinessFinancing: new UntypedFormControl(
        {value: this.financeBusinessPlan.smallBusinessFinancing, disabled: this.isPastSubmissionDeadline},
        null),
      otherOpportunities: new UntypedFormControl(
        {value: this.financeBusinessPlan.otherOpportunities, disabled: this.isPastSubmissionDeadline},
        null)
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.otherActivitiesFormGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.otherActivitiesFormGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.otherActivitiesFormGroup.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.otherActivitiesFormGroup.disable() : this.otherActivitiesFormGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.otherActivitiesFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
