import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { EventService } from '@shared/event.service';
import { SubmitBitService } from './submit-bit.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-portfolio-submit-bid',
  templateUrl: './submit-bid.component.html',
  styleUrls: ['./submit-bid.component.scss'],
  providers: [
    SubmitBitService
  ]
})
export class PortfolioSubmitBidComponent {
  @Input() projectGroupId: string;
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('bidWithdrawalConfirmation', {static: false}) bidWithdrawalConfirmation: ModalDirective;

  constructor(private _eventService: EventService,
              private _translateService: TranslateService,
              private _submitBitService: SubmitBitService) {
  }

  // portfolio list and submit.component call
  openDialog(projectGroupId) {
    this.projectGroupId = projectGroupId;
    this.bidWithdrawalConfirmation.show();
  }

  // portfolio list and submit.component call
  submitBid(projectGroup: ProjectGroup) {
    this.projectGroupId = projectGroup.id;
    this._submitBitService.submitBid(this.projectGroupId)
    .subscribe(financeConnection => {
      this._eventService.success(this._translateService.instant('success-message.bid-submitted-to') + ' ' + projectGroup.program.name + ' ' + this._translateService.instant('success-message.program'));
      this.completed.emit(true);
    });
  }

  // portfolio list and submit.component call
  resubmitBid(projectGroup: ProjectGroup) {
    this._submitBitService.resubmitBid(projectGroup.id)
    .subscribe(response => {
      this.bidWithdrawalConfirmation.hide();
      this._eventService.success(this._translateService.instant('success-message.bid-submitted-to') + ' ' + projectGroup.program.name + ' ' + this._translateService.instant('success-message.program'));
      this.completed.emit(true);
    });
  }

  // portfolio list, submit.component and withdrawBid button call
  withdrawBid() {
    this._submitBitService.withdrawBid(this.projectGroupId)
    .subscribe(() => {
      this.bidWithdrawalConfirmation.hide();
      this._eventService.success(this._translateService.instant('success-message.bid-withdrawn'));
      this.completed.emit(true);
    });
  }

  // close button: call from dialog
  closeDialog() {
    this.bidWithdrawalConfirmation.hide();
  }
}
