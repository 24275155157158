import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@global/services/language.service';
import { ChartConfiguration } from 'chart.js';
import { parseInt } from 'lodash-es';
import { ChartColors } from '../chart-colors.enum';
import { ChartDataset } from '../chart-dataset.model';
import { OesChart } from '../oes-chart.model';

@Component({
  selector: 'oes-bar-line-chart',
  templateUrl: 'bar-line-chart.component.html',
  styleUrls: ['./bar-line-chart.component.scss']
})
export class BarLineChartComponent implements OnInit {
  @Input() chart: OesChart;
  @Input() chartColors: string[] = Object.keys(ChartColors);

  barLineChartData: ChartConfiguration<'scatter'>['data'];
  barLineChartLegend: boolean;
  barLineChartPlugins = [];
  barLineChartOptions: ChartConfiguration<'scatter'>['options'];

  constructor(private _languageService: LanguageService) {
  }

  ngOnInit() {
    this.buildBarLineChart();
  }

  private buildDatasets(datasets: ChartDataset[]) {
    return datasets.map((dataset, index) => {
      return {
        label: dataset.label,
        data: dataset.data,
        type: dataset.type.toLowerCase(),
        backgroundColor: this.chartColors[index],
        hoverBackgroundColor: this.chartColors[index],
        borderColor: this.chartColors[index],
        maxBarThickness: 16,
        pointRadius: 0,
        fill: {
          target: 'origin',
          above: this.chartColors[3],
          below: this.chartColors[3]
        },
        borderRadius: 3
      };
    });
  }

  private buildBarLineChart() {
    const barLineChartLegend = this.chart?.labels?.length > 0;
    const barLineChartPlugins = [];
    const barLineChartData: ChartConfiguration<any>['data'] = {
      labels: this.chart?.labels,
      datasets: this.buildDatasets(this.chart.datasets)
    };
    const barLineChartOptions: ChartConfiguration<any>['options'] = {
      plugins: {
        title: {
          display: false
        },
        legend: {
          display: true,
          position: 'top',
          labels: {
            padding: 15,
            boxHeight: 5,
            boxPadding: 12,
            boxWidth: 25
          }
        }
      },
      scales: {
        x: {
          min: this.chart?.labels[0] - 1,
          grid: {
            display: false,
          },
          offset: false,
          ticks: {
            stepSize: 1,
            min: this.chart?.labels[0],
            callback: (label) => {
              return parseInt(label).toString();
            }
          },
          title: {
            display: true,
            text: this.chart?.options?.xname
          }
        },
        y: {
          offset: false,
          title: {
            display: true,
            text: this.chart?.options?.yname
          },
          ticks: {
            callback: (label) => {
              const formatted = new DecimalPipe(this._languageService.getLanguage()).transform(label, '1.0-0');
              if (this.chart?.options?.yname === 'USD') {
                return '$' + formatted;
              }
              return formatted;
            }
          },
        }
      },
      responsive: true,
      maintainAspectRatio: false
    };
    this.barLineChartLegend = barLineChartLegend;
    this.barLineChartPlugins = barLineChartPlugins;
    this.barLineChartData = barLineChartData;
    this.barLineChartOptions = barLineChartOptions;
  }



}
