import { MILESTONE_STATUS, MILESTONE_STATUS_COLOR, MILESTONE_STATUS_ICON, MILESTONE_STATUS_LABEL, MILESTONE_STATUS_TEXT_COLOR, MILESTONE_STATUS_TITLE_DECORATION } from '@program/shared/milestone-status.enum';
import { BaseModel } from '@shared/base.model';

export class MilestoneStatus extends BaseModel<MilestoneStatus> {
  milestoneId: string;
  name: MILESTONE_STATUS;
  label: MILESTONE_STATUS_LABEL;
  titleDecoration: MILESTONE_STATUS_TITLE_DECORATION;
  textColor: MILESTONE_STATUS_TEXT_COLOR;
  icon: MILESTONE_STATUS_ICON;
  color: MILESTONE_STATUS_COLOR;

  constructor(milestoneId: string, newStatus: string) {
    super();

    this.id = milestoneId;
    this.name = MILESTONE_STATUS[newStatus];
    this.label = MILESTONE_STATUS_LABEL[newStatus];
    this.titleDecoration = MILESTONE_STATUS_TITLE_DECORATION[newStatus];
    this.textColor = MILESTONE_STATUS_TEXT_COLOR[newStatus];
    this.icon = MILESTONE_STATUS_ICON[newStatus];
    this.color = MILESTONE_STATUS_COLOR[newStatus];
  }
}
