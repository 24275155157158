import { Injectable } from '@angular/core';
import { BaseRestApiService } from '../base-rest-api.service';
import { RestApiWrapperService } from '../rest-api-wrapper.service';
import { Country } from './country.model';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseRestApiService {
  private countriesUri = `api/${environment.apiVersion}/countries/`;
  private _countries: Country[];

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  set countries(countries: Country[]) {
    this._countries = countries;
  }

  public getCountries(): Observable<Country[]> {
    if (this._countries && this._countries.length > 0) {
      return of(this._countries);
    } else {
      return this.get<Country[]>(environment.serverUri + this.countriesUri)
            .pipe(
              map(countries => countries.map(country => new Country(country)))
            );
    }
  }

  public getCountryId(countryName: string): string {
    if (this._countries && this._countries.length > 0) {
      const matchedCountry =  this._countries.find(country => country.name === countryName);
      return matchedCountry ? matchedCountry.id : undefined;
    }
    return undefined;
  }

  public getCountry(countryName: string): Country {
    if (this._countries && this._countries.length > 0) {
      const matchedCountry =  this._countries.find(country => country.name === countryName);
      return matchedCountry;
    }
    return undefined;
  }
}
