<div *ngFor="let projectSet of projectSets;" class="project-set">

  <div class="project-set-header">
    <div class="d-flex flex-column">
      <ng-container *ngIf="projectSet.name">
        <h5>{{ 'offtaker.overview.site-group' | translate }} #{{ projectSet.name }}</h5>
      </ng-container>
      <div class="d-flex">
        <ng-container *ngIf="countryIconList[projectSet.country?.code]; else EarthIcon">
          <img [src]="'https://oes-hosted-images-public.s3.amazonaws.com/country-icons/'  + projectSet.country?.code + '.svg'" />
        </ng-container>
        <ng-template #EarthIcon>
          <img src="https://oes-hosted-images-public.s3.amazonaws.com/country-icons/EARTH.svg" />
        </ng-template>

        <h4>{{ projectSet.country.name }}<span class="text-light"><span class="spacer">|</span>{{ projectSet.totalProjects }} {{ 'offtaker.overview.sites' | translate }}</span></h4>
      </div>

    </div>
    <ng-container *ngIf="projectSet.created !== projectSet.updated; else NeedDetails">
      <ng-container *ngIf="projectSet?.stage === 'PRE_CONSTRUCTION'">
        <button class="btn btn-grey financial-model-button" (click)="openSiteDetailsModal(projectSet?.id, 'financialModel')"><span class="button-financial-model"></span>{{ 'offtaker.overview.financial-model' | translate }}</button>
      </ng-container>
      <button class="btn btn-primary-light" (click)="openSiteDetailsModal(projectSet?.id, 'details')"><span class="button-edit"></span>{{ !readOnly ? ('offtaker.overview.edit-details' | translate) : ('offtaker.overview.view-details' | translate) }}</button>
    </ng-container>
    <ng-template #NeedDetails>
      <ng-container *ngIf="projectSet.stage === 'PRE_CONSTRUCTION'">
        <button class="btn btn-grey financial-model-button" disabled><span class="button-financial-model"></span>{{ 'offtaker.overview.financial-model' | translate }}</button>
      </ng-container>
      <button class="btn btn-primary" (click)="openSiteDetailsModal(projectSet?.id, 'details')"><span class="button-plus">+</span>{{ 'offtaker.overview.add-details' | translate }}</button>
    </ng-template>
  </div>

  <div class="project-set-content">
    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.stage' | translate }}</h5>
      <p>{{ 'offtaker.project-stage.' + projectSet.stage | translate }}</p>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.grid-configuration' | translate }}</h5>
      <p>
        <ng-container *ngFor="let gridConfigItem of projectSet?.gridConfiguration; let i = index;">
          <span *ngIf="i !== 0">, </span><span>{{ 'offtaker.grid-configuration.' + gridConfigItem | translate }}</span>
        </ng-container>
      </p>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.currency' | translate }}</h5>
      <p>{{ 'currency-type.' + projectSet.currencyType | translate }}</p>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.total-system-size' | translate }}</h5>
      <ng-container *ngIf="projectSet?.averageSystemSize; else AddDetail">
        <p>{{ (projectSet.averageSystemSize * projectSet.totalProjects) | number: '1.0-2' }} {{ 'units.kWp' | translate }}</p>
      </ng-container>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.total-est-capex' | translate }}</h5>
      <ng-container *ngIf="projectSet?.totalCapex; else AddDetail">
        <p>{{ projectSet?.currencyType === 'USD' ? '$' : null }}{{ projectSet.totalCapex | number: '1.0-2' }}
            {{ projectSet?.currencyType !== 'USD' ? ' ' + projectSet?.currencyType : null }}</p>
      </ng-container>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.cost-per-watt' | translate }}</h5>
      <ng-container *ngIf="projectSet?.projectSetFinancialModel?.currentRun?.costPerWatt; else AddDetail">
        <p>{{ projectSet?.currencyType === 'USD' ? '$' : null }}{{ projectSet?.projectSetFinancialModel?.currentRun?.costPerWatt | number: '1.2-2' }}
          {{ projectSet?.currencyType !== 'USD' ? ' ' + projectSet?.currencyType : null }}</p>
      </ng-container>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.avg-ebitda-margin' | translate }}</h5>
      <ng-container *ngIf="projectSet?.projectSetFinancialModel?.currentRun?.ebitdaMargin; else AddDetail">
        <p>{{ projectSet?.projectSetFinancialModel?.currentRun?.ebitdaMargin | percent: '1.1-1' }}</p>
      </ng-container>
    </div>

    <div class="project-set-item">
      <h5>{{ 'offtaker.overview.payback-period' | translate }}</h5>
      <ng-container *ngIf="projectSet?.projectSetFinancialModel?.currentRun?.paybackPeriod; else AddDetail">
        <p>{{ projectSet?.projectSetFinancialModel?.currentRun?.paybackPeriod | number: '1.0-1' }} {{ 'units.years' | translate }}</p>
      </ng-container>
    </div>

  </div>

  <ng-template #AddDetail>
    <p class="add-detail" (click)="openSiteDetailsModal(projectSet?.id, 'details')">{{ !readOnly ? ('offtaker.overview.add-details' | translate) : ('offtaker.overview.view-details' | translate) }}</p>
  </ng-template>
</div>

<!-- Site Details modal (Full screen) -->
<div bsModal #siteDetailsModal="bs-modal"
     [config]="{ignoreBackdropClick:true,keyboard:true}"
     class="modal" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-full">
    <div class="modal-content">
      <ng-container *ngIf="selectedProjectSetId">
        <oes-project-set-edit
          [readOnly]="readOnly"
          [projectSetId]="selectedProjectSetId"
          [activeTab]="initialTab"
          (close)="hideSiteDetailsModal()">
        </oes-project-set-edit>
      </ng-container>
    </div>
  </div>
</div>
