import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectSetPortfolioNavigationService } from '../project-set-portfolio-navigation/project-set-portfolio-navigation.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';

@Component({
  selector: 'oes-developer-project-set-portfolio-profile',
  templateUrl: 'developer-project-set-portfolio-profile.component.html',
  styleUrls: ['./developer-project-set-portfolio-profile.component.scss']
})
export class ProjectSetPortfolioProfileComponent implements OnDestroy {
  projectSetPortfolio: ProjectSetPortfolio;
  projectSets: ProjectSet[];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService) {
    this.subscribeProjectSetPortfolio();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeProjectSetPortfolio() {
    this._projectSetPortfolioNavigationService.portfolioObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolio: ProjectSetPortfolio) => {
        this.projectSetPortfolio = projectSetPortfolio;
        this.projectSets = projectSetPortfolio.projectSets;
      });
  }
}
