import { environment } from '@environments/environment';

export class SystemLoadApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;

  public static get = (projectId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads`;
  };
  public static resize = (projectId: string, systemLoadId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads/${systemLoadId}/resize-load`;
  };
  public static update = (projectId: string, systemLoadId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads/${systemLoadId}/update-load`;
  };
  public static uploadTimeSeries = (projectId: string, systemLoadId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads/${systemLoadId}/upload/timeseries`;
  };
  public static downloadTimeSeries = (projectId: string, systemLoadId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads/${systemLoadId}/time-series`;
  };
  public static getChartData = (projectId: string, systemLoadId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads/${systemLoadId}/chart-data`;
  };
  public static getSummary = (projectId: string, systemLoadId: string) => {
    return `${SystemLoadApiConstants.projects}/${projectId}/system-loads/${systemLoadId}/summary`;
  };
}
