import {environment} from '@environments/environment';

export class OfftakersApiConstants {
  public static offtakers = environment.serverUri + `api/${environment.apiVersion}/offtakers`;

  public static offtaker = {
    list: () => {
      return `${OfftakersApiConstants.offtakers}`;
    },
    create: () => {
      return `${OfftakersApiConstants.offtakers}`;
    },
    detail: (offtakerId: string) => {
      return `${OfftakersApiConstants.offtakers}/${offtakerId}`;
    },
    update: (offtakerId: string) => {
      return `${OfftakersApiConstants.offtakers}/${offtakerId}`;
    },
    delete: (offtakerId: string) => {
      return `${OfftakersApiConstants.offtakers}/${offtakerId}`;
    },
  };

  public static projectOfftaker = {
    create: (offtakerId: string, projectId: string) => {
      return `${OfftakersApiConstants.offtakers}/project-offtaker/${offtakerId}/${projectId}`;
    }
  };
}
