<div *ngIf="content"
     class="content-container">
  <div *ngFor="let item of content"
       class="content-item"
       [ngStyle]="{ 'width': 'calc((100% / ' + maxCardsPerRow + ') - ((20px * (' + maxCardsPerRow + ' - 1)) / ' + maxCardsPerRow + '))' }">
    <div class="icon-container">
      <img [src]="item.iconPath">
    </div>
    <h4 [ngStyle]="{ 'font-size': headingSize + 'px' }">{{ item.heading }}</h4>
    <p [ngStyle]="{ 'font-size': bodySize + 'px' }">{{ item.body }}</p>
  </div>
</div>
