import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProjectSetPortfolio } from './project-set-portfolio.model';
import { ProjectSetPortfolioApiConstants } from './project-set-portfolio.constant';
import { FORM_ID } from '@finance/developer-portfolio/shared/project-group-form.service';
import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { ProjectSetPortfolioConnection } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-connection.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetPortfolioService extends BaseRestApiService {
  constructor(_restApiWrapperService: RestApiWrapperService,
              private _dynamicFormService: DynamicFormService) {
    super(_restApiWrapperService);
  }

  detail(projectSetPortfolioId: string): Observable<ProjectSetPortfolio> {
    return this.get<ProjectSetPortfolio>(ProjectSetPortfolioApiConstants.projectSetPortfolio.detail(projectSetPortfolioId))
      .pipe(map((psp: ProjectSetPortfolio) => {
        return new ProjectSetPortfolio(psp);
      }));
  }

  list(params: any = {page: 0, size: 1000}): Observable<ProjectSetPortfolio[]> {
    return this.get<ProjectSetPortfolio[]>(ProjectSetPortfolioApiConstants.projectSetPortfolio.list(), {params: params})
      .pipe(map((projectSetPortfolios: ProjectSetPortfolio[]) => {
        return projectSetPortfolios.map(projectSetPortfolio => new ProjectSetPortfolio(projectSetPortfolio));
      }));
  }

  findAllConnections(): Observable<ProjectSetPortfolioConnection[]> {
    return this.get<ProjectSetPortfolioConnection[]>(ProjectSetPortfolioApiConstants.projectSetPortfolio.findAllConnections())
      .pipe(map((projectSetPortfolioConnections: ProjectSetPortfolioConnection[]) => {
        return projectSetPortfolioConnections.map(pspc => new ProjectSetPortfolioConnection(pspc));
      }));
  }

  create(projectSetPortfolio: ProjectSetPortfolio): Observable<ProjectSetPortfolio> {
    return this.post(ProjectSetPortfolioApiConstants.projectSetPortfolio.create(), JSON.stringify(projectSetPortfolio))
      .pipe(map((psp: ProjectSetPortfolio) => {
        return new ProjectSetPortfolio(psp);
      }));
  }

  update(projectSetPortfolio: ProjectSetPortfolio): Observable<ProjectSetPortfolio> {
    return this.put(ProjectSetPortfolioApiConstants.projectSetPortfolio.update(projectSetPortfolio.id), JSON.stringify(projectSetPortfolio))
      .pipe(map((psp: ProjectSetPortfolio) => {
        return new ProjectSetPortfolio(psp);
      }));
  }

  remove(projectSetPortfolioId: string): Observable<any> {
    return this.delete(ProjectSetPortfolioApiConstants.projectSetPortfolio.delete(projectSetPortfolioId));
  }

  shareWithOdyssey(projectSetPortfolioId: string): Observable<ProjectSetPortfolio> {
    return this.put(ProjectSetPortfolioApiConstants.projectSetPortfolio.shareWithOdyssey(projectSetPortfolioId))
      .pipe(map((psp: ProjectSetPortfolio) => {
        return new ProjectSetPortfolio(psp);
      }));
  }

  getForm(projectSetPortfolioId: string, formId: FORM_ID): Observable<any> {
    return this.get(ProjectSetPortfolioApiConstants.projectSetPortfolioForm.getForm(projectSetPortfolioId, formId));
  }

  uploadForm(projectSetPortfolioId: string, fileName: string, json: {}, formValid?: boolean): Observable<any> {
    const jsonObject = this._dynamicFormService.removeBlankNodes({...json});
    const formId = FORM_ID.BUSINESS_PLAN;
    const file = new File([JSON.stringify(jsonObject)], fileName, {type: 'text/json'});
    const formData = new FormData();
    const options = { params: { formValid: formValid !== null ? formValid : false } };
    formData.append('file', file);
    return this.put(ProjectSetPortfolioApiConstants.projectSetPortfolioForm.getForm(projectSetPortfolioId, formId), formData, options);
  }

  getFormFilesList(): {} {
    return {
      portfolioDescription: 'Form-Portfolio-Description.json'
    };
  }
}
