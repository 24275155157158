import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { CountryIconList } from '@project/shared/country-icon-list.enum';
import { Offtaker } from '@project/offtakers/offtaker.model';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';

@Component({
  selector: 'oes-site-group-overview',
  templateUrl: 'site-group-overview.component.html',
  styleUrls: ['./site-group-overview.component.scss'],
  providers: [
  ],
})
export class SiteGroupOverviewComponent {
  @Input() offtaker: Offtaker;
  @Input() projectSets: ProjectSet[];
  @Input() readOnly: boolean;

  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('siteDetailsModal', {static: false}) siteDetailsModal: ModalDirective;

  countryIconList = CountryIconList;
  initialTab = 'details';
  selectedProjectSetId: string;
  updateForms: FormGroup[] = [];

  constructor() {
  }

  openSiteDetailsModal(projectSetId: string, tab: string) {
    this.selectedProjectSetId = projectSetId;
    this.initialTab = tab;
    this.siteDetailsModal.show();
  }

  hideSiteDetailsModal() {
    this.siteDetailsModal.hide();
    this.selectedProjectSetId = null;
    this.reload.next(true);
  }

}
