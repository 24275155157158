import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { MilestoneConfigAction, MilestoneConfigInputs, MilestoneConfigRaw } from '../milestone-util-types';
import { Milestone } from '../milestone.model';
import { MilestoneService } from '../milestone.service';
import { MILESTONE_TYPE } from '../milestone-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oes-milestone-config',
  templateUrl: './milestone-config.component.html',
  styleUrls: ['./milestone-config.component.scss'],
})
export class MilestoneConfigComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('milestoneConfigModal', {static: false}) milestoneConfigModal: ModalDialogComponent;
  @ViewChild('deleteWarningModal', {static: false}) deleteWarningModal: ModalDialogComponent;
  @ViewChild('configForm', {static: false}) configForm: any;

  @Output() deleteMilestone: EventEmitter<Milestone> = new EventEmitter<Milestone>();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  action: MilestoneConfigAction;
  formName: string;
  milestoneConfig: MilestoneConfigRaw;
  milestoneInput: Milestone;
  milestoneType: MILESTONE_TYPE;
  modalShowing: boolean = false;
  submitAttempted: boolean = false;

  private openMilestoneConfigSubscription: Subscription;

  constructor(private _milestoneService: MilestoneService) {};

  ngOnInit() {
    this.openMilestoneConfigSubscription = this._milestoneService.openMilestoneConfig$
    .subscribe((data: MilestoneConfigInputs) => {
      if (!data) {
        return;
      }
      this.action = data.action;
      this.milestoneInput = data.milestoneInput;
      this.milestoneType = data.milestoneType;
      this.milestoneConfig = {
        name: this.milestoneInput?.name || this.milestoneInput?.milestoneConfig?.name || '',
        description: this.milestoneInput?.description || this.milestoneInput?.milestoneConfig?.description || '',
        form: data.form
      };
      this.setDisplayFormName();
      this.modalShowing = true;
      this.milestoneConfigModal.show();
    });
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.milestoneConfig?.currentValue && this.milestoneConfig.form) {
      this.setDisplayFormName();
    }
  }

  ngOnDestroy(): void {
    if (this.openMilestoneConfigSubscription) {
      this.openMilestoneConfigSubscription.unsubscribe();
    }
  }

  onSubmit() {
    if (this.configForm.valid) {
      let data: any = {};
      if (!this.milestoneInput) {
        // if creating a new milestone
        data = this.milestoneConfig;
      } else {
        // if updating existing milestone config
        // Avoiding needless name & description overwrites
        if (this.milestoneConfig.name !== this.milestoneInput.name) {
          data.name = this.milestoneConfig.name;
        }
        if (this.milestoneConfig.description !== this.milestoneInput.description) {
          data.description = this.milestoneConfig.description;
        }
        data.form = this.milestoneConfig.form;
      }
      this._milestoneService.configMilestone({
        data,
        milestone: this.milestoneInput,
        milestoneType: this.milestoneType,
      }, this.action);
      this.isLoading.emit(true);
      this.milestoneConfigModal.hide();
      this.submitAttempted = false;
      this.configForm.reset();
    } else {
      this.submitAttempted = true;
    }
  };

  setDisplayFormName() {
    if (!this.milestoneConfig.form) {
      return;
    }
    this.formName = this.milestoneConfig.form.originalName
      ? this.milestoneConfig.form.originalName
      : JSON.parse(this.milestoneConfig.form)['originalName'];
  }

  uploadFormClick(event: MouseEvent) {
    event.preventDefault();
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      if (input.files.length) {
        this.milestoneConfig.form = input.files[0];
      }
    };
    input.click();
  }

  removeFormClick(event: MouseEvent) {
    event.preventDefault();
    this.milestoneConfig.form = undefined;
  }

  onDelete() {
    this._milestoneService.deleteMilestone(this.milestoneInput);
    this.deleteWarningModal.hide();
    this.milestoneConfigModal.hide();
  };

  handleOpen() {
    this.milestoneConfigModal.show();
  };

  handleOpenDeleteWarning() {
    this.deleteWarningModal.show();
  };
};
