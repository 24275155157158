<p class="audit-score">
  <ng-container *ngIf="averageTotals?.passFail">
    <ng-container *ngIf="averageTotals.passFail === 'PASS'">
      <span class="tag pass">{{ averageTotals?.passFail }}</span>
    </ng-container>
    <ng-container *ngIf="averageTotals.passFail === 'FAIL'">
      <span class="tag fail">{{ averageTotals?.passFail }} ({{ averageTotals?.totalFailedPassFailQuestions }}/{{ averageTotals?.totalPassFailQuestions }})</span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="averageTotals?.averageTotal">
    <span class="tag">{{ averageTotals?.averageTotal.toFixed(1) }}</span>
    <ng-container *ngIf="isOrgAdmin">
      <i class="fa fa-question-circle primary mr-1"
          tooltip="{{ 'program.pre-qualification.audit-tooltip' | translate }}"
          placement="right"
          containerClass="tool-tip">
      </i>
    </ng-container>
  </ng-container>
</p>
