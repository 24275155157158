<div class="section-border">
  <div class="installer-container">
    <div class="installer">
      <h5>{{ 'project.overview.installer' | translate }}</h5>
      <h4>{{ currentInstaller?.name || '--' }}</h4>
    </div>
    <div *ngIf="canAssign" class="assign">
      <button *ngIf="!currentInstaller" class="btn btn-primary-light" (click)="openAssignInstallerModal()">
        {{ 'project.overview.assign-installer' | translate }}
      </button>
      <button *ngIf="currentInstaller" class="btn btn-grey" (click)="openAssignInstallerModal()">
        <img style="width: 15px; height: 15px;" src="assets/icons/line-icons/shared/black/change.svg" role="presentation" />
        {{ 'buttons.change' | translate }}
      </button>
    </div>
  </div>
  <div class="line-break"></div>
  <div class="d-flex justify-content-between installer-projects">
    <div>
      {{ 'project.overview.installer-projects' | translate }}
    </div>
    <div class="weight-medium">{{ currentInstaller?.projectInstallers?.length || '--' }}</div>
  </div>
</div>

<oes-modal-dialog
  #assignInstallerModal
  [submitButtonText]="'buttons.save'| translate"
  [size]="'small'"
  [allowSubmit]="formGroup?.valid"
  [hideCloseBtn]="true"
  [submitButtonText]="'buttons.confirm' | translate"
  (submit)="assignInstaller()"
  (close)="closeAssignInstallerModal()">
  <div class="form-header">
    <h3>{{ 'project.overview.assign-installer' | translate }}</h3>
    <button
      class="btn btn-fresh btn-link"
      (click)="newInstallerClick()">
      <img src="assets/icons/line-icons/shared/orange/plus.svg" role="presentation" />
      {{ 'project.overview.new-installer' | translate }}
    </button>
  </div>
  <form *ngIf="formGroup" [formGroup]="formGroup" class="dialog-form">
    <div class="installer-dropdown">
      <oes-dropdown
        id="installerSelector"
        class="style-overhaul-flag lg"
        [lazySearch]="true"
        [parentFormGroup]="formGroup"
        [items]="installers"
        [selection]="'single'"
        [enableSearch]="true"
        [enableUncheckAll]="false"
        (searchChanged)="getFilteredInstallers($event)">
      </oes-dropdown>
      <span class="placeholder" *ngIf="!selectedInstaller">{{ 'project.overview.select-installer' | translate }}</span>
      <span class="color-error" *ngIf="showDropdownError">
        {{ 'installers.form.error.required' | translate }}
      </span>
    </div>
    <div class="same-as-dev-checkbox">
      <input
        id="installerSameAsDeveloper"
        type="checkbox"
        class="style-overhaul-flag"
        [formControlName]="'sameAsDeveloper'"/>
      <label for="installerSameAsDeveloper">{{ 'project.overview.same-as-developer' | translate }}</label>
      <div>
        <span class="color-error" *ngIf="showMissingDeveloperError">
          {{ 'project.overview.missing-developer' | translate }}
        </span>
      </div>
    </div>
  </form>
</oes-modal-dialog>

<oes-installer-form
  #installerForm
  [installers]="installers"
  [organization]="organization"
  (refreshInstallers)="refreshInstallers($event)">
></oes-installer-form>
