<div *ngIf="displayActions" class="cell-buttons-container">
  <ng-container *ngFor="let key of params?.keys">
    <ng-container *ngIf="key !== 'upload'; else uploadButton">
      <button class="btn btn-secondary" [ngClass]="'btn-' + key" (click)="click(key)">
        {{'data-integration.all-actions.' + key | translate}}
      </button>
    </ng-container>
    <ng-template #uploadButton>
      <label class="btn btn-secondary" for="upload-btn-{{params?.rowIndex}}">{{'data-integration.api.actions.' + key | translate}}</label>
      <input type="file" id="upload-btn-{{params?.rowIndex}}" (change)="getFilePath($event)"/>
    </ng-template>
  </ng-container>
</div>
