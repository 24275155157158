<div
  bsModal
  #modalTemplate="bs-modal"
  [config]="config"
  (onHidden)="onHide($event)"
  class="modal fade"
  [ngClass]="{'fresh': modalStyle === 'fresh'}">
  <div class="modal-dialog oes-modal" [ngClass]="setSize()" [ngStyle]="maxWidth()">
    <div class="modal-content">
      <div [ngClass]="{'modal-header': !destructive, 'modal-header-destructive': destructive}">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button
          *ngIf="!hideCloseBtn"
          type="button"
          class="close pull-right"
          (click)="hide(); takeBack();"
          data-testid="close-modal-x"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <ng-content></ng-content>
      </div>

      <ng-container *ngIf="allowCancel || allowSubmit">
        <div class="modal-footer">
          <div
            class="d-flex w-100 align-items-center"
            [ngClass]="{'justify-content-end': modalStyle !== 'fresh'}">
            <!-- Delete button -->
            <button *ngIf="allowDelete"
              type="button"
              class="btn btn-destructive mr-auto"
              (click)="clickDelete()">
              {{'buttons.delete' | translate}}
            </button>
            <!-- Back button -->
            <button *ngIf="takeUserBack"
              type="button"
              class="btn btn-secondary"
              (click)="takeBack()">
              {{'buttons.take-back' | translate}}
            </button>
            <!-- Cancel button -->
            <button *ngIf="allowCancel"
              data-testid="modal-cancel-button"
              type="button"
              class="btn btn-secondary"
              (click)="hide()">
              {{cancelButtonText ? cancelButtonText : ('buttons.cancel' | translate)}}
            </button>
            <!-- Submit button -->
            <button *ngIf="allowSubmit"
              type="button"
              class="btn"
              data-testid="modal-submit-button"
              [ngClass]="{'btn-primary': !destructive, 'btn-destructive': destructive}"
              (click)="clickSubmit()"
              [disabled]="activateSubmit">
              {{submitButtonText ? submitButtonText : ('buttons.submit' | translate)}}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
