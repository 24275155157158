import {environment} from '@environments/environment';

export class ProjectGroupApiConstants {
  public static projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;

  public static projectGroup = {
    list: () => {
      return `${ProjectGroupApiConstants.projectGroups}`;
    },
    create: () => {
      return `${ProjectGroupApiConstants.projectGroups}`;
    },
    get: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}`;
    },
    update: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}`;
    },
    delete: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}`;
    },
  };
  public static developerOrganization = {
    get: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/developer-organization`;
    },
  };
  public static finance = {
    get: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/finance`;
    },
    recalculate: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/finance/recalculate`;
    },
    updateStatus: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/finance/update-status`;
    },
  };
  public static forms = {
    get: (projectGroupId: string, formId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/forms/${formId}`;
    },
    save: (projectGroupId: string, formId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/forms/${formId}`;
    },
  };
  public static projects = {
    get: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/projects`;
    },
  };
  public static tender = {
    publish: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/publish-tender`;
    },
    republish: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/re-publish-tender`;
    },
    withdrawBid: (projectGroupId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/${projectGroupId}/withdraw-tender-bid`;
    },
  };
  public static investor = {
    get: () => {
      return `${ProjectGroupApiConstants.projectGroups}/finance`;
    },
    list: () => {
      return `${ProjectGroupApiConstants.projectGroups}/finance/organizations`;
    },
    detail: (organizationId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/finance/organizations/${organizationId}`;
    },
    information: (organizationId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/finance/organizations/${organizationId}/investor-information`;
    },
  };
  public static quotes = {
    get: () => {
      return `${ProjectGroupApiConstants.projectGroups}/quotes`;
    },
  };
  public static vendor = {
    list: () => {
      return `${ProjectGroupApiConstants.projectGroups}/vendor/organizations`;
    },
    detail: (organizationId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/vendor/organizations/${organizationId}`;
    },
    information: (organizationId: string) => {
      return `${ProjectGroupApiConstants.projectGroups}/vendor/organizations/${organizationId}/vendor-information`;
    },
  };
}
