import { environment } from '@environments/environment';
import { ROLE_TYPE } from '@user/role-type';

import { PROGRAM_RULE_TYPES } from './program-rule-types.enum';
import { ProgramSubmissionType } from './program.model';

export class ProgramConstant {
  public static programs = environment.serverUri + `api/${environment.apiVersion}/programs`;

  public static program = {
    list: () => {
      return `${ProgramConstant.programs}`;
    },
    detail: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}`;
    },
    create: () => {
      return `${ProgramConstant.programs}`;
    },
    update: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}`;
    },
    participating: () => {
      return `${ProgramConstant.programs}/participating`;
    },
    participantReports: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/participantReports`;
    },
    approved: () => {
      return `${ProgramConstant.programs}/approved`;
    },
    approvedByType: (submissionType: ProgramSubmissionType) => {
      return `${ProgramConstant.programs}/approved-by-type/${submissionType}`;
    },
    copyProject: () => {
      return `${ProgramConstant.programs}/copy-projects`;
    },
    copyProjectGroups: () => {
      return `${ProgramConstant.programs}/copy-project-groups`;
    },
    reportTemplate: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/reportTemplate`;
    },
    reportTemplateExists: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/reportTemplate/exists`;
    },
    getConnectionsTemplate: (programId: string, role: ROLE_TYPE) => {
      return `${ProgramConstant.programs}/${programId}/connectionsTemplate/${role}`;
    },
    getTemplateConfigExcel: () => {
      return `${ProgramConstant.programs}/blank-connections-template-creator`;
    },
    getUserTemplateConfigExcel: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/user-connections-template`;
    },
    uploadTemplateConfigExcel: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/update-connections-template/`;
    },
  };

  public static rule = {
    list: (programId: string, type: PROGRAM_RULE_TYPES) => {
      return `${ProgramConstant.programs}/${programId}/rules/${type}`;
    },
    save: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/rules`;
    }
  };

  static milestoneConfig = {
    findByProgramId: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/milestone-config`;
    },
    updateAllByProgramId: (programId: string) => {
      return `${ProgramConstant.programs}/${programId}/milestone-config/update-all`;
    },
    updateMilestoneConfig: (programId: string, milestoneConfigId: string) => {
      return `${ProgramConstant.programs}/${programId}/milestone-config/${milestoneConfigId}`;
    },
    delete: (programId: string, milestoneConfigId: string) => {
      return `${ProgramConstant.programs}/${programId}/milestone-config/${milestoneConfigId}`;
    },
    uploadForm: (programId: string, milestoneConfigId: string) => {
      return `${ProgramConstant.programs}/${programId}/milestone-config/${milestoneConfigId}/forms`;
    },
  };
}
