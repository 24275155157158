<form [formGroup]="dateTimeFormGroup"
      class="date-time-selector"
      [ngClass]="customClass ? customClass : ''">

  <label [ngClass]="{'required': required}">{{ label }}</label>
  <div class="selector-container">
    <oes-date-selector
      [formGroup]="dateTimeFormGroup"
      [controlName]="'dateSelector'"
      [date]="date"
      [dateFormat]="'DD MMMM YYYY'">
    </oes-date-selector>
    <oes-time-selector
      [formGroup]="dateTimeFormGroup"
      [date]="date"
      [controlName]="'timeSelector'">
    </oes-time-selector>
    <p class="mb-0 mr-auto">{{ timeZone }}</p>
  </div>

</form>
