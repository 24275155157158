<ng-container [formGroup]="constructionFormGroup">
  <div class="construction">
    <p class="text-xs color-font-tertiary mb-2">{{'deal-description.construction.overview-label' | translate}}</p>
    <oes-quill
      class="style-overhaul-flag"
      formControlName="constructionDescription"
      [editorDisabled]="isPastSubmissionDeadline"
      [characterLimit]="3000"
      [initialValue]="financeBusinessPlan?.constructionDescription"
      [placeholder]="'deal-description.construction.overview-placeholder' | translate">
    </oes-quill>
    <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.construction.describe-label' | translate}}</p>
    <oes-quill
      class="style-overhaul-flag"
      formControlName="maintenanceDescription"
      [editorDisabled]="isPastSubmissionDeadline"
      [characterLimit]="3000"
      [initialValue]="financeBusinessPlan?.maintenanceDescription"
      [placeholder]="'deal-description.construction.describe-label' | translate">
    </oes-quill>
    <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.construction.monitoring-label' | translate}}</p>
    <oes-quill
      class="style-overhaul-flag"
      formControlName="monitoringDescription"
      [editorDisabled]="isPastSubmissionDeadline"
      [characterLimit]="3000"
      [initialValue]="financeBusinessPlan?.monitoringDescription"
      [placeholder]="'deal-description.construction.monitoring-placeholder' | translate">
    </oes-quill>

    <!-- sliders -->
    <h4 class="mt-5 mb-4">{{'deal-description.construction.status-label' | translate}}</h4>
    <div class="status-chart">
      <div class="row mb-4">
        <div class="col-6 offset-2">
          <p class="mb-0">
            {{'deal-description.construction.status-legend' | translate}}
          </p>
        </div>
      </div>
      <!-- Design Spec -->
      <div class="row mb-3">
        <div class="col-3">
          <p class="status-chart-label">{{'deal-description.construction.design-spec-label' | translate}}:</p>
        </div>
        <div class="col-4">
          <input type="range" min="0" max="100" step="1" style="width: 100%;"
            formControlName="designSpecStatus"
            [value]="constructionFormGroup?.controls?.designSpecStatus?.value">
        </div>
        <div class="col-2 input-group input-group-sm">
          <input type="number" class="form-control"
            formControlName="designSpecStatus"
            [value]="constructionFormGroup?.controls?.designSpecStatus?.value">
          <span class="input-group-addon">%</span>
        </div>
      </div>
      <!-- Budgetary estimates from vendors -->
      <div class="row mb-3">
        <div class="col-3">
          <p class="status-chart-label">{{'deal-description.construction.budgetary-label' | translate}}:</p>
        </div>
        <div class="col-4">
          <input type="range" min="0" max="100" step="1" style="width: 100%;"
            formControlName="budgetEstimatedStatus"
            [value]="constructionFormGroup?.controls?.budgetEstimatedStatus?.value">
        </div>
        <div class="col-2 input-group input-group-sm">
          <input type="number" class="form-control"
            formControlName="budgetEstimatedStatus"
            [value]="constructionFormGroup?.controls?.budgetEstimatedStatus?.value">
          <span class="input-group-addon">%</span>
        </div>
      </div>
      <!-- Detailed quotes from vendors -->
      <div class="row mb-3">
        <div class="col-3">
          <p class="mb-0 bold">{{'deal-description.construction.quotes-label' | translate}}:</p>
        </div>
        <div class="col-4">
          <input type="range" min="0" max="100" step="1" style="width: 100%;"
            formControlName="detailedQuoteStatus"
            [value]="constructionFormGroup?.controls?.detailedQuoteStatus?.value">
        </div>
        <div class="col-2 input-group input-group-sm">
          <input type="number" class="form-control"
            formControlName="detailedQuoteStatus"
            [value]="constructionFormGroup?.controls?.detailedQuoteStatus?.value">
          <span class="input-group-addon">%</span>
        </div>
      </div>
      <!-- POs Issued -->
      <div class="row mb-3">
        <div class="col-3">
          <p class="status-chart-label">{{'deal-description.construction.pos-label' | translate}}:</p>
        </div>
        <div class="col-4">
          <input type="range" min="0" max="100" step="1" style="width: 100%;"
            formControlName="poIssuedStatus"
            [value]="constructionFormGroup?.controls?.poIssuedStatus?.value">
        </div>
        <div class="col-2 input-group input-group-sm">
          <input type="number" class="form-control"
            formControlName="poIssuedStatus"
            [value]="constructionFormGroup?.controls?.poIssuedStatus?.value">
          <span class="input-group-addon">%</span>
        </div>
      </div>
      <!-- Executed equipment agreements -->
      <div class="row">
        <div class="col-3">
          <p class="status-chart-label">{{'deal-description.construction.agreements-label' | translate}}:</p>
        </div>
        <div class="col-4">
          <input type="range" min="0" max="100" step="1" style="width: 100%;"
            formControlName="executedAgreementsStatus"
            [value]="constructionFormGroup?.controls?.executedAgreementsStatus?.value">
        </div>
        <div class="col-2 input-group input-group-sm">
          <input type="number" class="form-control"
            formControlName="executedAgreementsStatus"
            [value]="constructionFormGroup?.controls?.executedAgreementsStatus?.value">
          <span class="input-group-addon">%</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
