import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { FinanceIntroduction } from '../finance-introduction.model';
import { EventService } from '@shared/event.service';
import { FinanceImageService } from '../finance-image-upload.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { MarketplaceStatus } from './marketplace-status.model';
import { ProgramStatus } from './program-status.model';
import { ProjectGroupFormService, FORM_ID } from '../project-group-form.service';
import { ProjectGroup } from '@shared/services/project-group.model';

@Injectable({
  providedIn: 'root'
})
export class SubmitValidationService {
  private errors = {};
  private financeImages = false;
  private projectGroup: ProjectGroup;
  private financeIntroduction: FinanceIntroduction;
  private isCustomFormValid = {
    businessPlan: false,
    checklist: false
  };
  public isCustomDescriptionForm = false;
  public isCustomChecklistForm = false;
  public marketplaceStatus: MarketplaceStatus;
  public programStatus: ProgramStatus;

  // to track validation status
  private validationStatusSubject = new BehaviorSubject<boolean>(false);
  public validationStatus$ = this.validationStatusSubject.asObservable();

  private isPastSubmissionDeadlineSource = new BehaviorSubject<boolean>(false);
  // public isPastSubmissionDeadline = true;
  isPastSubmissionDeadlineObservable = this.isPastSubmissionDeadlineSource.asObservable();

  // private _stepUpdatedSubject = new BehaviorSubject<boolean>(false);
  // public stepUpdated$: Observable<boolean> = this._stepUpdatedSubject.asObservable();

  constructor(private _eventService: EventService,
              private _router: Router,
              private _dynamicFormService: DynamicFormService,
              private _projectGroupFormService: ProjectGroupFormService,
              private _financeImageService: FinanceImageService,
              private _translateService: TranslateService) {
    this._translateService.get('project-group-introduction.publish-errors')
    .pipe(take(1))
    .subscribe((message: string) => {
      this.errors = message;
    });
  }

  public runValidation(projectGroup: ProjectGroup, financeIntroduction: FinanceIntroduction) {
    this.projectGroup = projectGroup;
    this.financeIntroduction = financeIntroduction;
    this.validationStatusSubject.next(this.validationNavigation());
    this.setSubmissionDeadlinePermissions();
    if (this.financeIntroduction) {
      this.marketplaceStatus = this.getMarketplaceStatus(this.financeIntroduction);
      this.programStatus = this.getProgramStatus(this.financeIntroduction);
    }
  }

  // private checkCustomForms() {
  //   Observable.zip(
  //     this._projectGroupFormService.getForm(this.projectGroup.id, FORM_ID.BUSINESS_PLAN),
  //     this._projectGroupFormService.getForm(this.projectGroup.id, FORM_ID.CHECKLIST)
  //   )
  //   .subscribe(jsons => {
  //     if (jsons && jsons.length > 0) {
  //       // if there is no form, we will have an empty {}
  //       if (jsons[0]) {
  //         this.isCustomDescriptionForm = Object.keys(jsons[0]).length ? true : false;
  //         this.isCustomFormValid.businessPlan = this._dynamicFormService.checkRequiredFields(jsons[0]);
  //       }
  //       if (jsons[1]) {
  //         this.isCustomChecklistForm = Object.keys(jsons[1]).length ? true : false;
  //         this.isCustomFormValid.checklist = this._dynamicFormService.checkRequiredFields(jsons[1]);
  //       }
  //       this.checkForImages();
  //     }
  //   });
  // }

  // public checkForImages() {
  //   this._financeImageService.find(this._financeImageService.buildUri(this.projectGroup.id), null)
  //   .subscribe(financeImages => {
  //     if (financeImages.content != null && financeImages.content.length > 0) {
  //       this.financeImages = true;
  //     }
  //     this.validationStatusSubject.next(this.validationNavigation());
  //   });
  // }

  private setSubmissionDeadlinePermissions() {
    this.isPastSubmissionDeadlineSource.next(this.checkDeadline(this.projectGroup));
  }

  public checkDeadline(projectGroup: ProjectGroup): boolean {
    if (projectGroup && projectGroup.program && projectGroup.program.endDateTime) {
      const today = new Date();
      const programEnd = new Date(projectGroup.program.endDateTime);
      const currentResult = programEnd < today;
      if (!currentResult) {
        const millisecondsToExpiration = programEnd.getTime() - today.getTime();
        const millisecondsInWeek = 604800000;
        // This conditional is to deal with a JS quirk where large values in a setTimeout function are returned as 1.
        if (millisecondsToExpiration < millisecondsInWeek) {
          setTimeout(() => {
            this.isPastSubmissionDeadlineSource.next(true);
          }, millisecondsToExpiration);
        }
      }
      this.isPastSubmissionDeadlineSource.next(currentResult);
      return currentResult;
    } else {
      this.isPastSubmissionDeadlineSource.next(false);
      return false;
    }
  }

  public getMarketplaceStatus(financeIntroduction: FinanceIntroduction): MarketplaceStatus {
    const submitted = financeIntroduction.postedToMarketplaceDateTime;
    const withdrawn = financeIntroduction.removedFromMarketplaceDateTime;

    const marketplaceStatus: MarketplaceStatus = {
      activeInMarketplace: false,
      publishedMarketplaceDateTime: submitted
    };

    if (!submitted && !withdrawn) {
      marketplaceStatus.activeInMarketplace = false;
    } else if (!submitted && withdrawn) {
      marketplaceStatus.activeInMarketplace = false;
    } else if (submitted && !withdrawn) {
      marketplaceStatus.activeInMarketplace = true;
    } else if (submitted && withdrawn && (submitted > withdrawn)) {
      marketplaceStatus.activeInMarketplace = true;
    } else if (submitted && withdrawn && (submitted <= withdrawn)) {
      marketplaceStatus.activeInMarketplace = false;
    }
    return marketplaceStatus;
  }

  public getProgramStatus(financeIntroduction: FinanceIntroduction): ProgramStatus {
    const submitted = financeIntroduction.programBidPostedDateTime;
    const withdrawn = financeIntroduction.programBidWithdrawDateTime;

    const programStatus: ProgramStatus = {
      completedProgramSubmission: false,
      currentlySubmittedToProgram: false,
      publishedProgramDateTime: undefined
    };

    if (submitted) {
      programStatus.completedProgramSubmission = true;
    } else {
      programStatus.completedProgramSubmission = false;
      programStatus.currentlySubmittedToProgram = false;
      programStatus.publishedProgramDateTime = null;
    }

    if (submitted && !withdrawn) {
      programStatus.currentlySubmittedToProgram = true;
      programStatus.publishedProgramDateTime = submitted;
    } else if (submitted && withdrawn && (submitted > withdrawn)) {
      programStatus.currentlySubmittedToProgram = true;
      programStatus.publishedProgramDateTime = submitted;
    } else if (submitted && withdrawn && (submitted <= withdrawn)) {
      programStatus.currentlySubmittedToProgram = false;
      programStatus.publishedProgramDateTime = null;
    }
    return programStatus;
  }

  // reusable validation
  private validationNavigation(): boolean {
    if (this.projectGroup && this.financeIntroduction) {
      if (new Date(this.projectGroup.program.endDateTime) < new Date()) {
        this._eventService.error(this.errors['too-late']);
        return false;
      }
      // document checklist form
      if (this.isCustomChecklistForm && !this.isCustomFormValid.checklist) {
        this.goTo('/data-room');
        this._eventService.error(this.errors['required-fields']);
        return false;
      }
      return true;
    }
    return false;
  }

  private goTo(target: string) {
    this._router.navigate([`oes/finance/portfolios/${this.projectGroup.id}/${target}`]);
  }

  public sendErrorMessage(target: string) {
    this._eventService.error(this.errors[target]);
  }

}
