import {Pipe, PipeTransform} from '@angular/core';

/*
 * return Title Case
 */
@Pipe({
  name: 'decamelCase',
  pure: true
})
export class DecamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Insert a space between lower and upper, replace _ with space then capitalize each word
    return value.replace(/([a-z])([A-Z])/g, '$1 $2')
      .split('_')
      .map((s) => s.charAt(0).toUpperCase() + (s.length > 1 ? s.substring(1).toLowerCase() : ''))
      .join(' ');


  }
}
