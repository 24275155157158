import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SideNavigationService } from '@main-navigation/side-navigation/side-navigation.service';
import { NavHeaderService } from './nav-header.service';
import { UserService } from '@user/user.service';

import { NavItem } from '@main-navigation/shared/navi-item/nav-item.model';
import { PinnedTabMapping } from './nav-header.service';
import { User } from '@user/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'oes-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent implements OnInit, OnDestroy {
  @Input() isHome: boolean;

  navItem: NavItem;
  user: User;

  private ngUnsubscribe: Subject<any> = new Subject();
  private pinnedTabs: PinnedTabMapping;

  constructor(
    private _navHeaderService: NavHeaderService,
    private _sideNavigationService: SideNavigationService,
    private _router: Router,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.subscribeCurrentNavItem();
    this.subscribePinnedTabs();
    this._userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeCurrentNavItem() {
    this._sideNavigationService.currentNavItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((item: NavItem) => {
        if (item && this.navItem !== item) {
          this.navItem = item;
        }
      });
  }

  private subscribePinnedTabs() {
    this._navHeaderService.pinnedTabs$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((obj: PinnedTabMapping) => {
        if (obj) {
          this.pinnedTabs = obj;
        }
      });
  }

  handleClick(event, idx) {
    if (idx !== this.pinnedTabs[this.navItem.type]) {
      const newObj = {
        ...this.pinnedTabs,
        [this.navItem.type]: idx,
      };
      this._navHeaderService.pinnedTabs = newObj;
    }
  }

  isLinkActive(url: string): boolean {
    const queryParamsIndex = this._router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1
      ? this._router.url
      : this._router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }
}
