import {BaseModel} from '@shared/base.model';
import { ComponentType } from './component-type.enum';
import { ExternalType } from './external-type.enum';
import { SourceType } from './source-type.enum';

/*
TODO: New Generation Design Item model (draft)
Usage:
  e.g.)
    public generationDesignItems: (DesignItemCommon | DesignItemStorage | DesignItemGenerator | DesignItemGrid)[];

  e.g.)
    public generationDesignItem: DesignItemCommon = {
      type: ComponentType.PANELS,
      make: 'generic',
      model: 'generic',
      size: 0,
      fuelCost: 0,
      opex: 0,
      sourceType: SourceType.Manual,
      netMeteringPaymentType: 0,
      unitCost: 0,
      totalCostPerUnit: 0,
      homerExcluded: false,
      name: ''
    };
*/

export interface Fuel {
  runTime: number;
  fuelPrice: number;
  fuelType: string;
  fuelUsage: number;
}

export interface SpecificCost {
  balanceOfSystemCost?: number;
  totalCostUnit?: string;
  panelFoundationCost?: number;
  panelRackingCost?: number;
  operationsAndMaintenanceCost?: number;
}

export interface ReplacementNeeded {
  lifetime?: number;
  replacementCost?: number;
}

// Panels, Charge Controller, Solar Inverter, Battery Inverter and System Inverter
export interface DesignItemCommon extends SpecificCost, ReplacementNeeded {
  id?: string;
  type: ComponentType;
  make: string;
  model: string;
  description?: string;
  size: number;
  fuelCost: number;
  opex: number;
  sourceType: SourceType;
  netMeteringPaymentType: number;
  unitCost: number;
  totalCostPerUnit: number;
  homerExcluded: boolean; // Homer excludes some items during calculation in some conditions.
  created?: Date;
  updated?: Date;
  name: string;
  capex?: number; // total scaling cost
}

export interface DesignItemStorage extends DesignItemCommon {
  quantity: number;
  storageEfficiency: number;
  storageType: string;
}

export interface DesignItemGenerator extends DesignItemCommon, Fuel {
  operationsAndMaintenanceCost: number;
}

export interface DesignItemGrid {
  type: ComponentType;
  buyPrice: number;
  units: string;
  sellPrice: number;
  gridUnreliable: boolean;
  dayHoursOn: number;
  nightHoursOn: number;
  annualGridEnergyPurchased: number;
  annualGridEnergySold: number;
  annualGridEnergyCost: number;
  annualGridEnergyRevenue: number;  // unused: we are using own calculation which is in a financial model result, and it doesn't much with Homer result.
}

/**
 * Old GenerationDesignItem model (this is difficult to mock for testing)
 */
export class GenerationDesignItem extends BaseModel<GenerationDesignItem> {
  id: string;
  make: string;
  model: string;
  description: string;
  size: number;

  unitCost: number;
  balanceOfSystemCost: number;
  totalCostPerUnit: number;
  totalCostUnit: string;
  panelFoundationCost: number;
  panelRackingCost: number;
  operationsAndMaintenanceCost: number;

  controllerType: string; // unused
  capex: number;
  fuelCost: number;
  fuelPrice: number;
  fuelType: string;
  fuelUsage: number;
  runTime: number;

  lifetime: number;
  replacementCost: number;

  opex: number;
  shippingCost: number; // unused
  storageEfficiency: number;
  storageType: string;
  type: ComponentType;

  units: string;
  sourceType: ExternalType;
  // grid
  buyPrice: number;
  netMeteringPaymentType: number;
  sellPrice: number;
  gridUnreliable: boolean;
  dayHoursOn: number;
  nightHoursOn: number;
  annualGridEnergyPurchased: number;
  annualGridEnergySold: number;
  annualGridEnergyCost: number;
  annualGridEnergyRevenue: number;  // unused: we are using own calculation which is in a financial model result, and it doesn't much with Homer result.
  created: Date;
  homerExcluded: boolean; // Homer excludes some items during calculation in some conditions.
  updated: Date;
  name: string;
  quantity?: number;

  constructor(projectDesignItemInfo: any) {
    super();
    this.buyPrice = projectDesignItemInfo.buyPrice;
    this.netMeteringPaymentType = projectDesignItemInfo.netMeteringPaymentType || 0;
    this.sellPrice = projectDesignItemInfo.sellPrice;
    this.gridUnreliable = projectDesignItemInfo.gridUnreliable;
    this.dayHoursOn = projectDesignItemInfo.dayHoursOn;
    this.nightHoursOn = projectDesignItemInfo.nightHoursOn;
    this.annualGridEnergyPurchased = projectDesignItemInfo.annualGridEnergyPurchased;
    this.annualGridEnergySold = projectDesignItemInfo.annualGridEnergySold;
    this.annualGridEnergyCost = projectDesignItemInfo.annualGridEnergyCost;
    this.annualGridEnergyRevenue = projectDesignItemInfo.annualGridEnergyRevenue;
    this.id = projectDesignItemInfo.id;
    this.type = projectDesignItemInfo.type;
    this.make = projectDesignItemInfo.make;
    this.model = projectDesignItemInfo.model;
    this.description = projectDesignItemInfo.description;
    this.size = projectDesignItemInfo.size;
    this.unitCost = projectDesignItemInfo.unitCost;
    this.totalCostPerUnit = projectDesignItemInfo.totalCostPerUnit;
    this.balanceOfSystemCost = projectDesignItemInfo.balanceOfSystemCost;
    this.lifetime = projectDesignItemInfo.lifetime;
    this.replacementCost = projectDesignItemInfo.replacementCost;
    this.controllerType = projectDesignItemInfo.controllerType;
    this.storageType = projectDesignItemInfo.storageType;
    this.fuelType = projectDesignItemInfo.fuelType;
    this.shippingCost = projectDesignItemInfo.shippingCost;
    this.operationsAndMaintenanceCost = projectDesignItemInfo.operationsAndMaintenanceCost;
    this.runTime = projectDesignItemInfo.runTime;
    this.fuelPrice = projectDesignItemInfo.fuelPrice;
    this.fuelUsage = projectDesignItemInfo.fuelUsage;
    this.fuelCost = projectDesignItemInfo.fuelCost;
    this.panelRackingCost = projectDesignItemInfo.panelRackingCost;
    this.panelFoundationCost = projectDesignItemInfo.panelFoundationCost;
    this.storageEfficiency = projectDesignItemInfo.storageEfficiency;
    this.sourceType = projectDesignItemInfo.sourceType;
    this.capex = projectDesignItemInfo.capex;
    this.opex = projectDesignItemInfo.opex;
    this.units = projectDesignItemInfo.units;
    this.homerExcluded = projectDesignItemInfo.homerExcluded;
    this.created = projectDesignItemInfo.created ? new Date(projectDesignItemInfo.created) : undefined;
    this.quantity = projectDesignItemInfo?.quantity;
    this.updated = projectDesignItemInfo.updated ? new Date(projectDesignItemInfo.updated) : undefined;
    this.name = projectDesignItemInfo.name;
  }
}
