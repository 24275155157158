'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
function getDef(f, d) {
  if (typeof f === 'undefined') {
    return typeof d === 'undefined' ? f : d;
  }
  return f;
}
function boolean(func, def) {
  func = getDef(func, def);
  if (typeof func === 'function') {
    return function f() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return !!func.apply(this, args);
    };
  }
  return !!func ? function () {
    return true;
  } : function () {
    return false;
  };
}
function integer(func, def) {
  func = getDef(func, def);
  if (typeof func === 'function') {
    return function f() {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      var n = parseInt(func.apply(this, args), 10);
      return n != n ? 0 : n;
    };
  }
  func = parseInt(func, 10);
  return func != func ? function () {
    return 0;
  } : function () {
    return func;
  };
}
function string(func, def) {
  func = getDef(func, def);
  if (typeof func === 'function') {
    return function f() {
      for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }
      return '' + func.apply(this, args);
    };
  }
  func = '' + func;
  return function () {
    return func;
  };
}
exports.boolean = boolean;
exports.integer = integer;
exports.string = string;
