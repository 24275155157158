import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'oes-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() dateFormat = 'DD-MM-YYYY';
  @Input() date: Date;

  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    showWeekNumbers: false,
    dateInputFormat: ''
  };

  constructor() {
  }

  ngOnInit() {
    this.bsConfig.dateInputFormat = this.dateFormat;
    this.formGroup.controls[this.controlName].setValue(this.date);
  }
}
