<div class="coupled-system">
  <ng-container *ngIf="draggable">
    <oes-notify
      [iconName]="'fa-info'"
      [message]="'generation-design.*c-subheader' | translate"
      [type]="'hint'">
    </oes-notify>
  </ng-container>
  <table class="system-schema">
    <tbody>
      <tr id="second-row" class="dc-system-schema-row">
        <td></td>
        <td></td>
        <td>
          <p class="system-bus ac-coupled dc-bus">
            <span>
              <img src="../../../../assets/images/generation-design/icn-dc-bus.svg">
            </span>
          </p>
        </td>
        <td></td>
        <td></td>
        <td>
          <p class="system-bus ac-coupled ac-bus">
            <span>
              <img src="../../../../assets/images/generation-design/icn-ac-bus.svg">
            </span>
          </p>
        </td>
        <td></td>
      </tr>
      <tr id="second-row" class="dc-system-schema-row">
        <td></td>
        <td>
        </td>
        <td class="vertical-line">
        </td>
        <td class="dc-system-schema-component"
            data-testid2="gen-design-panels">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="componentStatus[componentType.PANELS].available"
            [inUse]="componentStatus[componentType.PANELS].inUse"
            [componentType]="componentType.PANELS"
            [capacity]="capacities[componentType.PANELS]">
          </oes-generation-system-component>
        </td>
        <td></td>
        <td class="vertical-line arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-double.svg">
        </td>
        <td id="third-row" class="dc-system-schema-component">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="componentStatus[componentType.GRID].available"
            [inUse]="componentStatus[componentType.GRID].inUse"
            [componentType]="componentType.GRID"
            [extra]="extra.grid">
          </oes-generation-system-component>
        </td>
      </tr>
      <tr id="fourth-row" class="dc-system-schema-row only-vertical-lines">
          <td></td>
          <td></td>
          <td class="vertical-line"></td>
          <td class="d-flex justify-content-center">
            <img _ngcontent-serverapp-c317="" src="../../../../assets/images/generation-design/icn-arrow-down.svg">
          </td>
          <td></td>
          <td class="vertical-line"></td>
      </tr>
      <tr id="fifth-row" class="dc-system-schema-row">
        <td></td>
        <td></td>
        <td class="vertical-line"></td>
        <td class="dc-system-schema-component">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="componentStatus[componentType.SOLAR_INVERTER].available"
            [inUse]="componentStatus[componentType.SOLAR_INVERTER].inUse"
            [componentType]="componentType.SOLAR_INVERTER"
            [capacity]="capacities[componentType.SOLAR_INVERTER]">
          </oes-generation-system-component>
        </td>
        <td class="arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-right.svg">
        </td>
        <td class="vertical-line arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-right.svg">
        </td>
        <td class="dc-system-schema-component">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="false"
            [componentType]="componentType.LOAD"
            [extra]="extra.load">
          </oes-generation-system-component>
        </td>
      </tr>
      <tr id="sixth-row" class="dc-system-schema-row spacer-row">
        <td></td>
        <td></td>
        <td class="vertical-line"></td>
        <td></td>
        <td></td>
        <td class="vertical-line"></td>
        <td></td>
        <td></td>
      </tr>
      <tr id="seventh-row" class="dc-system-schema-row">
        <td class="dc-system-schema-component">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="componentStatus[componentType.STORAGE].available"
            [inUse]="componentStatus[componentType.STORAGE].inUse"
            [componentType]="componentType.STORAGE"
            [capacity]="capacities[componentType.STORAGE]">
          </oes-generation-system-component>
        </td>
        <td class="arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-double.svg">
        </td>
        <td class="vertical-line arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-double.svg">
        </td>
        <td class="dc-system-schema-component">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="componentStatus[componentType.BATTERY_INVERTER].available"
            [inUse]="componentStatus[componentType.BATTERY_INVERTER].inUse"
            [componentType]="componentType.BATTERY_INVERTER"
            [capacity]="capacities[componentType.BATTERY_INVERTER]">
          </oes-generation-system-component>
        </td>
        <td class="arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-double.svg">
        </td>
        <td class="vertical-line arrow-cell">
          <img src="../../../../assets/images/generation-design/icn-arrow-left.svg">
        </td>
        <td class="dc-system-schema-component">
          <oes-generation-system-component
            data-testid="gen-design-type-label"
            [draggable]="componentStatus[componentType.GENERATOR].available"
            [inUse]="componentStatus[componentType.GENERATOR].inUse"
            [componentType]="componentType.GENERATOR"
            [capacity]="capacities[componentType.GENERATOR]">
          </oes-generation-system-component>
        </td>
      </tr>
    </tbody>
  </table>
</div>
