import { Component, Input, OnInit } from '@angular/core';
import { ChartColors } from '../../chart-colors.enum';

@Component({
  selector: 'oes-key-with-values',
  templateUrl: 'key-with-values.component.html',
  styleUrls: ['./key-with-values.component.scss']
})
export class KeyWithValuesComponent {
  @Input() chartColors = Object.keys(ChartColors);
  @Input() currency: string = 'USD';
  @Input() currencyValues: any[];
  @Input() keys: any[];
  @Input() percentages: any[];

  constructor() {
  }
}
