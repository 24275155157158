import {environment} from '@environments/environment';

export class ProjectSetApiConstants {
  public static projectSets = environment.serverUri + `api/${environment.apiVersion}/project-sets`;

  public static projectSet = {
    list: () => {
      return `${ProjectSetApiConstants.projectSets}`;
    },
    create: () => {
      return `${ProjectSetApiConstants.projectSets}`;
    },
    detail: (projectSetId: string) => {
      return `${ProjectSetApiConstants.projectSets}/${projectSetId}`;
    },
    update: (projectSetId: string) => {
      return `${ProjectSetApiConstants.projectSets}/${projectSetId}`;
    },
    delete: (projectSetId: string) => {
      return `${ProjectSetApiConstants.projectSets}/${projectSetId}`;
    }
  };
}
