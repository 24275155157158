<div class="stage-overview">
  <div class="d-flex flex-row justify-content-between header">
    <div class="name">{{sender}}</div>
    <div class="name name-right">{{receiver}}</div>
  </div>
  <div class="messages mb-3"
       #messageViewPort
       [scrollTop]="scrollTop">
    <div *ngIf="interactions?.length > 0; else noMessage">
      <div *ngFor="let interaction of interactions">
        <div *ngIf="interaction.projectStage">
          <!-- Status -->
          <div class="status">
            <div class="message">{{ interaction.projectStage }}</div>
            <div class="d-flex flex-row justify-content-between">
              <div class="author"
                   *ngIf="interaction.creatorUser?.organization?.name">
                {{interaction.creatorUser.organization.name}}
              </div>
              <div class="date">{{interaction.sentDateTime | date: 'MM/dd/yyyy hh:mm'}}</div>
            </div>
          </div>
        </div>
        <div class="message-container d-flex flex-row justify-content-center">
          <!-- Message Left -->
          <div class="bubble left">
            <div class="message"
                 *ngIf="interaction?.message && interaction?.creatorUser?.organization?.id === user?.organization.id">
              <div class="body" [innerHtml]="interaction.message | safeHtml"></div>
              <div class="d-flex flex-row justify-content-between">
                <div class="author"
                     *ngIf="interaction.creatorUser?.organization?.name">
                  {{interaction.creatorUser.organization.name}}
                </div>
                <div class="date">{{interaction.sentDateTime | date: 'MM/dd/yyyy hh:mm'}}</div>
              </div>
            </div>
          </div>
          <!-- Message Right -->
          <div class="bubble right">
            <div class="message"
                 *ngIf="interaction?.message && interaction?.creatorUser?.organization?.id !== user?.organization?.id">
              <div class="body" [innerHtml]="interaction.message | safeHtml"></div>
              <div class="d-flex flex-row justify-content-between">
                <div class="author"
                     *ngIf="interaction.creatorUser?.organization?.name">
                  {{interaction.creatorUser.organization.name}}
                </div>
                <div class="date">{{interaction.sentDateTime | date: 'MM/dd/yyyy hh:mm'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="end">
        <i class="fa fa-circle" aria-hidden="true"></i>
      </div>
    </div>
    <ng-template #noMessage>
      <div class="messages empty">
        {{'project-sharing.no-message' | translate}}
      </div>
    </ng-template>
  </div>

  <form [formGroup]="submitForm">
    <div class="submit-message d-flex flex-column">
      <!-- Status Select -->
      <div *ngIf="role && programOwner">
        <div class="btn-group status-dropdown" dropdown #dropdown="bs-dropdown" [autoClose]="true">
          <div class="required">{{ 'project-sharing.stage' | translate }}:</div>
          <button dropdownToggle type="button" class="dropdown-toggle">
            <div class="status-pill" [ngStyle]="{ 'background-color': getSelectColor(), 'color': getTextColor(getSelectColor()) }">
              {{ submitForm.controls['projectStage'].value }}
              <div class="caret"></div>
            </div>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li (click)="onSelectStage()" class="status-pill">None</li>
            <ng-container *ngFor="let stage of stages">
              <li [ngStyle]="{ 'background-color': stage.color, 'color': getTextColor(stage.color) }"
                  class="status-pill"
                  (click)="onSelectStage(stage.name)">
                {{ stage.name }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- Message -->
      <oes-quill
        [characterLimit]="3000"
        formControlName="body"
        [option]="quillOptions"
        [placeholder]="'project-sharing.placeholder' | translate">
      </oes-quill>

      <div class="d-flex justify-content-end">
        <button class="btn btn-primary"
                (click)="submit()"
                [disabled]="submitForm?.invalid">
          {{'buttons.submit' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
