import { Component, OnInit } from '@angular/core';
import { ROLE_TYPE } from '@user/role-type';
import { UserService } from '@user/user.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellAuditScoreService } from './cell-audit-score.service';

@Component({
  selector: 'oes-cell-audit-score',
  templateUrl: './cell-audit-score.component.html',
  styleUrls: ['./cell-audit-score.component.scss']
})
export class CellAuditScoreComponent implements OnInit, ICellRendererAngularComp {

  averageTotals: any;
  isOrgAdmin: boolean = false;
  constructor(private _userService: UserService,
              private _cellAuditScoreService: CellAuditScoreService) { }

  ngOnInit(): void {
    this.isOrgAdmin = this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
  }

  agInit(params: any): void {
    this.averageTotals = this._cellAuditScoreService.renderEvaluations(params?.data?.evaluations);
  }

  refresh(params: any): boolean {
    return false;
  }
}
