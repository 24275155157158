import { Component } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { ProjectSetPortfolioNavigationService } from '../project-set-portfolio-navigation/project-set-portfolio-navigation.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSetPortfolioConnection } from '../developer-project-set-portfolio-connection.model';
import { Program } from '@program/shared/program.model';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';

@Component({
  selector: 'oes-project-set-portfolio-data-room',
  templateUrl: 'project-set-portfolio-data-room.component.html',
  styleUrls: ['./project-set-portfolio-data-room.component.scss'],
})
export class ProjectSetPortfolioDataRoomComponent {
  projectSetPortfolio: ProjectSetPortfolio;
  projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection;
  readOnly: boolean;
  tagTypes: TagType[];
  currentOrgId: string;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService,
              private _userService: UserService) {
    this.getUserOrg();
    this.subscribeProjectSetPortfolio();
  }

  private getUserOrg() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.currentOrgId = user.organization.id;
      });
  }

  private subscribeProjectSetPortfolio() {
    this._projectSetPortfolioNavigationService.portfolioObservable
      .pipe(take(1))
      .subscribe((projectSetPortfolio: ProjectSetPortfolio) => {
        this.projectSetPortfolio = projectSetPortfolio;
        this.tagTypes = [{
          docTagItemId: this.projectSetPortfolio.id,
          docTagType: DOCUMENT_TAG_TYPE.PROJECT_SET_PORTFOLIO,
          name: this.projectSetPortfolio.name
        }];
      });
    this._projectSetPortfolioNavigationService.portfolioProgramConnectionObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolioConnection: ProjectSetPortfolioConnection) => {
        this.projectSetPortfolioProgramConnection = projectSetPortfolioConnection;
      });
    this._projectSetPortfolioNavigationService.portfolioReadOnlyObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((readOnly: boolean) => {
        this.readOnly = readOnly;
      });
  }

  get accessControl (): any {
    if (!this.determinePastSubmissionDeadline(this.projectSetPortfolio?.program) &&
        !this.projectSetPortfolioProgramConnection?.programShareDateTime &&
        !this.readOnly) {
      return new FilesActions({
        copy: true,
        create: true,
        delete: true,
        download: true,
        move: true,
        preview: true,
        search: true,
        share: true,
        tag: true,
        upload: true,
        editName: true
      });
    } else {
      return new FilesActions({
        download: true,
        preview: true
      });
    }
  }

  private determinePastSubmissionDeadline(program: Program)  {
    if (program?.endDateTime) {
      return new Date(program.endDateTime) < new Date();
    }
    return false;
  }
}
