<ng-container *ngIf="listType === 'MAIN'">
  <oes-nav-header></oes-nav-header>
</ng-container>
<div class="shared-projects">

  <ng-container *ngIf="listType !== 'MAIN' && program?.id; else MyProjects">
    <div>
      <oes-shared-project-action
        [listType]="listType"
        (changeStageBulk)="changeStageBulkDialog.show()"
        (installSampleProject)="installSampleProject()"
        [selectedProjects]="selectedProjects">
      </oes-shared-project-action>
    </div>
  </ng-container>
  <ng-template #MyProjects>
    <h3 [ngClass]="{'assets': listType === projectListType.ASSETS}"
    >{{ 'shared-project-list.shared-projects' | translate }}</h3>
  </ng-template>

  <div>
    <!-- Project list -->
    <ng-container *ngIf="currentView === view.LIST">
      <div class="project-table">
        <div class="d-flex flex-row justify-content-end align-items-end sub-action-container">
          <oes-grid-sub-action
            *ngIf="gridApi"
            [gridState]="gridState"
            [defaultGridState]="defaultGridState"
            (action)="subAction($event)">
          </oes-grid-sub-action>
        </div>

        <div class="ag-grid-header-sticky"
             [ngClass]="{'top-offset': program !== undefined}"
             oesAgHorizontalScroll
             oesAgColumns>
          <ag-grid-angular
            #agGridSharedProjectList
            id="agGridSharedProjectList"
            style="width: auto;"
            class="grid ag-theme-alpine"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            (selectionChanged)="selectionChanged($event)"
            (filterChanged)="gridStatusChanged($event, 'filter')"
            (sortChanged)="gridStatusChanged($event, 'sort')"
            (displayedColumnsChanged)="gridStatusChanged($event, 'column')"
            (cellClicked)="onCellClicked($event)">
          </ag-grid-angular>
        </div>
      </div>
    </ng-container>

    <!-- MAP -->
    <ng-container *ngIf="currentView === view.MAP">
      <oes-project-map
        [projects]="mapProjects">
      </oes-project-map>
    </ng-container>
  </div>
</div>

<!-- single change stage status -->
<oes-modal-dialog
  #changeStageSingle
  [allowSubmit]="false"
  [size]="'large'"
  [title]="'project-navigation.messages' | translate"
  [allowCancel]="false"
  (close)="reload()">
  <oes-stage-overview
    *ngIf="projectConnection">
  </oes-stage-overview>
</oes-modal-dialog>

<!-- bulk stage status -->
<ng-container *ngIf="listType !== 'MAIN'">
  <oes-modal-dialog
    #changeStageBulk
    [allowSubmit]="false"
    [size]="'large'"
    [title]="'project-navigation.bulk-stage-update' | translate"
    [allowCancel]="false"
    (close)="reload()">
    <oes-stage-update-bulk
      *ngIf="selectedProjects.length"
      (reload)="changeStageBulkDialog.hide()"
      [selectedProjects]="selectedProjects">
    </oes-stage-update-bulk>
  </oes-modal-dialog>
</ng-container>

<!-- Note: There is no organization.id in Simple Projects. We can't add organization modal dialog here. -->
