<div class="summary-box">
  <div class="basic-info">
    <!-- organization -->
    <div class="info-block">
      <img src="../../../assets/images/finance-marketplace/icn-organization.png">
      <div class="detail">
        <div class="title">{{'finance-marketplace.organization'|translate}}</div>
        <div class="value">
          <a
            class="text-black-grey"
            href="javascript:void(0);"
            (click)="openProfile()">
            {{projectGroup?.developerOrganization?.name}}
          </a>
        </div>
      </div>
    </div>
    <!-- Location -->
    <div class="info-block">
      <img src="../../../assets/images/finance-marketplace/icn-location.png">
      <div class="detail">
        <div class="title">{{'finance-marketplace.location'|translate}}</div>
        <div class="value">{{projectGroup.countries}}</div>
      </div>
    </div>
  </div>

  <div class="summary">
    <!-- portfolio size -->
    <div class="info-block">
      <img src="../../../assets/images/finance-marketplace/icn-deal-size.png">
      <div class="detail">
        <div class="title">{{'finance-marketplace.total-deal-size'|translate}}</div>
        <div class="value">${{formatNumber(projectGroup.financeIntroduction?.dealSize, 1)}}</div>
      </div>
    </div>
    <!-- total project -->
    <div class="info-block">
      <img src="../../../assets/images/finance-marketplace/icn-projects.png">
      <div class="detail">
        <div class="title">{{'finance-marketplace.total-projects'|translate}}</div>
        <div class="value">{{projectGroup.totalProjects}}</div>
      </div>
    </div>

    <!-- PV capacity -->
    <div class="info-block">
      <img src="../../../assets/images/finance-marketplace/icn-capacity.png">
      <div class="detail">
        <div class="title">{{'finance-marketplace.total-installed-capacity'|translate}}</div>
        <div class="value">{{projectGroup.totalCapacity | number:'1.0-0'}} {{'units.kw' | translate}}</div>
      </div>
    </div>
    
    <!-- total connection -->
    <div class="info-block">
      <img src="../../../assets/images/finance-marketplace/icn-connections.png">
      <div class="detail">
        <div class="title">{{'finance-marketplace.total-connections'|translate}}</div>
        <div class="value">{{projectGroup.totalConnections}}</div>
      </div>
    </div>
  </div>
</div>

<oes-modal-dialog
  #DeveloperProfileDialog
  [size]="'x-large'"
  [allowCancel]="false"
  [allowSubmit]="false"
  [title]="projectGroup?.developerOrganization?.name">
  <oes-developer-profile
    *ngIf="projectGroup?.developerOrganization?.id"
    [id]="projectGroup?.developerOrganization?.id">
  </oes-developer-profile>
</oes-modal-dialog>
