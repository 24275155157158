import { environment } from '@environments/environment';

export class ProjectSetFinancialModelApiConstants {
  public static projectSets = environment.serverUri + `api/${environment.apiVersion}/project-sets`;

  public static projectSetFinancialModels = {
    detail: (projectSetId: string) => {
      return `${ProjectSetFinancialModelApiConstants.projectSets}/${projectSetId}/financial-models`;
    },
    run: (projectSetId: string) => {
      return `${ProjectSetFinancialModelApiConstants.projectSets}/${projectSetId}/financial-models/run`;
    },
    exportRun: (projectSetId: string, runId: string) => {
      return `${ProjectSetFinancialModelApiConstants.projectSets}/${projectSetId}/financial-models/export-run/${runId}`;
    },
    updateToLatestModel: (projectSetId: string, psfmId: string) => {
      return `${ProjectSetFinancialModelApiConstants.projectSets}/${projectSetId}/financial-models/${psfmId}/update-latest`;
    }
  };
}
