import { Injectable } from '@angular/core';
import { InvestorInformation } from '@organization-management/organization/investor-information/investor-information.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Country } from '@shared/services/country/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesMapService {
  private countriesAfrica = [ 'ZA', 'NG', 'KE', 'MA', 'GH', 'DZ', 'ET', 'CD', 'TZ', 'UG', 'SN', 'ML', 'CM',
    'CI', 'ZW', 'SD', 'TN', 'ZM', 'MG', 'AO', 'NA', 'RW', 'SO', 'MZ', 'LY', 'MU', 'CV', 'GN', 'BW', 'BJ',
    'SC', 'BF', 'MW', 'ER', 'LR', 'TG', 'NG', 'NE', 'GM', 'TD', 'CF', 'GA', 'MR', 'SL', 'SZ', 'DJ', 'BI',
    'GQ', 'SS', 'RE', 'LS', 'CG', 'EG', 'ST', 'KM', 'EH', 'GW'];

  private _countryClassesSubject = new BehaviorSubject<any>({});
  public countryClasses$: Observable<{}> = this._countryClassesSubject.asObservable();

  private _allCountriesSubject = new BehaviorSubject<boolean>(false);
  public allCountries$: Observable<boolean> = this._allCountriesSubject.asObservable();

  private _allSelectedCountriesAreInAfricaSubject = new BehaviorSubject<boolean>(false);
  public allSelectedCountriesAreInAfrica$: Observable<boolean> = this._allSelectedCountriesAreInAfricaSubject.asObservable();

  constructor() { }

  buildCountryClasses(countries: Country[], allCountries: boolean) {
    const countryClasses = countries.reduce((acc, country) => {
      acc[country.code] = true;
      return acc;
    }, {});
    this._allCountriesSubject.next(allCountries);
    this._countryClassesSubject.next(countryClasses);

    this.determineAllAfrica(countries, allCountries);
  }

  determineAllAfrica(selectedCountries: Country[], allCountries: boolean) {
    if (allCountries) {
      this._allSelectedCountriesAreInAfricaSubject.next(false);
    } else {
      const allAfrica = selectedCountries.length && selectedCountries.every(country => this.countriesAfrica.includes(country.code));
      this._allSelectedCountriesAreInAfricaSubject.next(allAfrica);
    }
  }
}
