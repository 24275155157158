import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'oes-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent implements OnInit {
  // Input fields
  @Input() ariaDescribedBy: string = '';
  @Input() ariaLabel: string = '';
  @Input() ariaLabelledBy: string = '';
  @Input() autocomplete: string = '';
  @Input() autofocus: boolean = false;
  @Input() disabled: boolean = false;
  @Input() id: string = '';
  @Input() isInvalid: boolean = false;
  @Input() formControlName: string = '';
  @Input() dataTestId: string = '';
  @Input() list: string = '';
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() name: string = '';
  @Input() ngInputClass: string = '';
  @Input() ngInputStyle: any;
  @Input() ngInputModel: any;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() style: string = '';
  @Input() value: any;

  @Output() blur = new EventEmitter<Event>();
  @Output() change = new EventEmitter<Event>();
  @Output() focus = new EventEmitter<Event>();
  @Output() ngInputModelChange = new EventEmitter<string>();

  // Additional fields
  @Input() inputWidth: string = '';
  @Input() label: string = '';
  @Input() leadingIconSrc: string = '';
  @Input() leadingIconTemplate: TemplateRef<any>;
  @Input() leadingText: string = '';
  @Input() trailingIconSrc: string = '';
  @Input() trailingIconTemplate: TemplateRef<any>;
  @Input() trailingText: string = '';

  // Style Inputs
  @Input() fullWidth = false;

  containerClass: string = '';
  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private el: ElementRef) {
  };

  ngOnInit(): void {
    this.containerClass = this.el.nativeElement.classList.value;
    if (this.inputWidth) {
      this.ngInputStyle = {...this.ngInputStyle, width: this.inputWidth };
      this.containerClass = this.containerClass + 'width-auto';
    }
  }

  // ControlValueAccessor methods start
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // ControlValueAccessor methods end

  handleChange(value: any): void {
    if (this.formControlName) {
      this.value = value;
      this.onChange(value);
    } else {
      this.ngInputModelChange.emit(value);
    }
    if (this.change.observers.length > 0) {
      this.change.emit(value);
    }
  };

  handleFocus(event: Event): void {
    if (!this.disabled && this.focus.observers.length > 0) {
      this.focus.emit(event);
    }
  };

  handleBlur(event: Event): void {
    if (!this.disabled && this.blur.observers.length > 0) {
      this.blur.emit(event);
    }
  };
};
