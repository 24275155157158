<div class="actions-container">
  <!-- Preview -->
  <div class="action-buttons-block">
    <button type="button"
            class="btn btn-solo mr-2"
            (click)="emitAction(actionType.preview)"
            [disabled]="disablePreview()">
      <img src="assets/icons/line-icons/shared/grey/preview.svg" class="mr-2">
      {{ 'buttons.preview' | translate }}
    </button>

    <!-- Move -->
    <button *ngIf="!readOnly"
            type="button"
            class="btn btn-move btn-in-group"
            [disabled]="disableMove()"
            (click)="emitAction(actionType.move)"
            placement="top"
            tooltip="{{ 'buttons.move' | translate }}">
    <img src="assets/icons/line-icons/shared/grey/move.svg" width="19">
    </button>

    <!-- Rename -->
    <button *ngIf="!readOnly"
            type="button"
            class="btn btn-in-group"
            [disabled]="disableEditName()"
            (click)="emitAction(actionType.editName)"
            placement="top"
            tooltip="{{ 'buttons.edit-name' | translate }}">
      <img src="assets/icons/line-icons/shared/grey/rename.svg" width="19">
    </button>

    <!-- Copy -->
    <button *ngIf="!readOnly"
            type="button"
            class="btn btn-in-group"
            (click)="emitAction(actionType.copy)"
            [disabled]="disableCopy()"
            placement="top"
            tooltip="{{ 'buttons.copy' | translate }}">
      <img src="assets/icons/line-icons/shared/grey/copy-projects.svg" width="19">
    </button>

    <!-- Associations -->
    <div *ngIf="!readOnly" dropdown class="dropdown-container" [isDisabled]="disableAssociation()">
      <button class="btn dropdown-toggle btn-in-group"
              dropdownToggle
              placement="top"
              [disabled]="disableTagAdd() && disableTagDelete()"
              tooltip="{{ 'buttons.associations' | translate }}">
        <img src="assets/icons/line-icons/shared/grey/association.svg" class="mr-1" width="19">
      </button>
      <ul class="dropdown-menu dropdown-menu-left" role="menu">
        <li *ngIf="!disableTagAdd()" role="menuitem">
          <a class="dropdown-item" (click)="emitAction(actionType.associationAdd)">
            {{ 'buttons.add-association' | translate }}
          </a>
        </li>
        <li *ngIf="!disableTagDelete()" role="menuitem">
          <a class="dropdown-item" (click)="emitAction(actionType.associationDelete)">
            {{ 'buttons.delete-association' | translate }}
          </a>
        </li>
      </ul>
    </div>

    <!-- Tags -->
    <div *ngIf="!readOnly"dropdown class="dropdown-container" [isDisabled]="disableTag()">
      <button class="btn dropdown-toggle btn-in-group"
              dropdownToggle
              placement="top"
              tooltip="{{ 'buttons.tags' | translate }}">
        <img src="assets/icons/line-icons/shared/grey/tag.svg" class="mr-1" width="17">
      </button>
      <ul class="dropdown-menu dropdown-menu-left" role="menu">
        <li *ngIf="!disableTagAdd()" role="menuitem">
          <a class="dropdown-item" (click)="emitAction(actionType.tagAdd)">
            {{ 'buttons.add-tag' | translate }}
          </a>
        </li>
        <li *ngIf="!disableTagDelete()" role="menuitem">
          <a class="dropdown-item" (click)="emitAction(actionType.tagDelete)">
            {{ 'buttons.delete-tag' | translate }}
          </a>
        </li>
      </ul>
    </div>

    <!-- Delete -->
    <button *ngIf="!readOnly"
            type="button"
            class="btn btn-delete mr-2"
            [disabled]="disableDelete()"
            (click)="emitAction(actionType.delete)"
            placement="top"
            tooltip="{{ 'buttons.delete' | translate }}">
      <img src="assets/icons/line-icons/shared/grey/delete.svg" width="16">
    </button>

    <!-- Download -->
    <button type="button"
            class="btn btn-solo btn-download mr-2"
            (click)="emitAction(actionType.download)"
            [disabled]="disableDownload()">
      <img src="assets/icons/line-icons/shared/grey/download-cloud.svg" class="mr-2" width="19">
      {{ 'buttons.download-max' | translate }}
    </button>
  </div>

  <!-- Upload and Create Folders -->
  <div *ngIf="!readOnly" dropdown class="dropdown-container add-dropdown-container ml-auto" [isDisabled]="disableUpload() && disableCreateFolder()">
    <button class="btn btn-primary dropdown-toggle btn-add"
            dropdownToggle>
      <span>+</span> {{ 'buttons.new' | translate }}
    </button>
    <ul class="dropdown-menu dropdown-menu-right" role="menu">
      <li *ngIf="!disableUpload()" role="menuitem">
        <a class="dropdown-item" (click)="emitAction(actionType.upload)">
          <img src="assets/icons/line-icons/shared/grey/upload-cloud.svg" width="19" class="mr-2">
          {{ 'buttons.upload-file' | translate }}
        </a>
      </li>
      <li *ngIf="!disableCreateFolder()" role="menuitem">
        <a class="dropdown-item" (click)="emitAction(actionType.createFolder)">
          <img src="assets/icons/line-icons/shared/grey/add-folder.svg" width="19" class="mr-2">
          {{ 'buttons.new-folder' | translate }}
        </a>
      </li>
    </ul>
  </div>

</div>
