import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-payout-editor',
  templateUrl: './payout-editor.component.html',
  styleUrls: ['./payout-editor.component.scss']
})
export class PayoutEditorComponent implements ICellEditorAngularComp {
  @ViewChild('input', {static: false, read: ViewContainerRef}) public input;

  private params: any;
  public value: any;

  agInit(params: any): void {
    const exchangeRate = params?.data?.connectionPayoutExchangeRate;
    this.params = params;
    if (this.params?.value) {
      // Setting to string below prevents the cell renderer from recalculating exchange rate before we want
      this.value = parseFloat((this.params.value * (exchangeRate ? exchangeRate : 1)).toFixed(2)).toString();
    } else {
      this.value = 0;
    }
  }

  getValue(): any {
    return this.value;
  }

  onKeyDown(event): void {
    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return (typeof event.which === 'undefined') ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    // accept control keys
    if (charStr === 'Backspace' || charStr === 'Delete' ||
        charStr === 'ArrowLeft' || charStr === 'ArrowRight' ||
        charStr === 'ArrowUp' || charStr === 'ArrowDown' ||
        charStr === 'Tab' || charStr === '.') {
      // this stops the grid from receiving the event and executing keyboard navigation
      event.stopPropagation();
      return true;
    }
    return this.isCharNumeric(charStr);
  }
}
