import {Injectable} from '@angular/core';
import {Organization} from '@shared/models/organization/organization.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationAdminService extends BaseRestApiService {
  public organizationUri = environment.serverUri + `api/${environment.apiVersion}/system-admin/organizations`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(): Observable<Organization[]> {
    return this.get<Organization[]>(this.organizationUri, {params: {page: 0, size: 5000, sort: 'name'}})
            .pipe(
              map(results => results.map(result => new Organization(result)))
            );
  }

  public createUpdate(organization: Organization): Observable<Organization> {
    if (organization.id) {
      return this.put<Organization>(`${this.organizationUri}/${organization.id}`, JSON.stringify(organization))
              .pipe(
                map(result => new Organization(result))
              );
    }
    return this.post<Organization>(this.organizationUri, JSON.stringify(organization))
            .pipe(
              map(result => new Organization(result))
            );
  }
}
