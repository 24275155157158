import { EvaluationConfig } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-config.model';
import { BaseModel } from '@shared/base.model';

export class Evaluation extends BaseModel<Evaluation> {
  updated: Date;
  evaluationConfig: EvaluationConfig;
  evaluatedEntityId: string;
  boolValue: boolean;
  scoreValue: number;
  comments: string;
  editorId: string;
  editorName: string;

  constructor(payload: any) {
    super();
    this.id = payload.id;
    this.updated = payload.updated;
    this.evaluationConfig = payload.evaluationConfig;
    this.evaluatedEntityId = payload.evaluatedEntityId;
    this.boolValue = payload.boolValue;
    this.scoreValue = payload.scoreValue;
    this.comments = payload.comments;
    this.editorId = payload.editorId;
    this.editorName = payload.editorName;
  }
}
