import { Component, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PROGRAM_PORTFOLIO_MODULE_FLAGS } from '@program/shared/program-portfolio-module-flags.enum';
import { ProgramPortfolioRules } from '@program/shared/program-project-rules.model.1';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { EventService } from '@shared/event.service';
import { Organization } from '@shared/models/organization/organization.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProjectSetPortfolioConnection } from '../developer-project-set-portfolio-connection.model';
import { ProjectSetPortfolioConnectionService } from '../developer-project-set-portfolio-connection.service';
import { ProjectSetPortfolioNavigationService } from '../project-set-portfolio-navigation/project-set-portfolio-navigation.service';

@Component({
  selector: 'oes-project-set-portfolio-submit',
  templateUrl: './project-set-portfolio-submit.component.html',
  styleUrls: ['./project-set-portfolio-submit.component.scss']
})
export class ProjectSetPortfolioSubmitComponent implements OnDestroy {
  @ViewChild('shareWithAnyInvestorModal', {static: false}) public shareWithAnyInvestorModal: ModalDirective;

  projectSetPortfolio: ProjectSetPortfolio;
  projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection;
  rules: ProgramPortfolioRules;
  submitDisabled: boolean;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _projectSetPortfolioConnectionService: ProjectSetPortfolioConnectionService) {
    this.subscribeProjectSetPortfolio();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  submitProgram() {
    const portfolio = new ProjectSetPortfolioConnection({
      financeOrganization: new Organization({ id: this.projectSetPortfolio.program.sponsor.id }),
    });

    this._projectSetPortfolioConnectionService.create(this.projectSetPortfolio.id, portfolio)
      .pipe(take(1))
      .subscribe((connection: ProjectSetPortfolioConnection) => {
        this._projectSetPortfolioNavigationService.getProjectSetPortfolio();
        this._eventService.success(this._translateService.instant('offtaker.project-set-portfolio.submit.submit-success'));
      });
  }

  withdrawProgram() {
    this._projectSetPortfolioConnectionService.remove(this.projectSetPortfolio.id, this.projectSetPortfolioProgramConnection.id)
      .pipe(take(1))
      .subscribe((response: any) => {
        this._projectSetPortfolioNavigationService.getProjectSetPortfolio();
        this._eventService.success(this._translateService.instant('offtaker.project-set-portfolio.submit.withdraw-success'));
      });

  }

  private subscribeProjectSetPortfolio() {
    this._projectSetPortfolioNavigationService.portfolioObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolio: ProjectSetPortfolio) => {
        this.projectSetPortfolio = projectSetPortfolio;
        this.setSubmitDisabled();
      });
    this._projectSetPortfolioNavigationService.portfolioProgramConnectionObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection) => {
        this.projectSetPortfolioProgramConnection = projectSetPortfolioProgramConnection;
        this.setSubmitDisabled();
      });
    this._projectSetPortfolioNavigationService.portfolioRuleObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((rules: ProgramPortfolioRules) => {
        this.rules = rules;
        this.setSubmitDisabled();
      });
  }

  private setSubmitDisabled() {
    const x = this.projectSetPortfolioProgramConnection !== undefined && this.projectSetPortfolioProgramConnection?.programShareDateTime !== undefined;
    const y = this.projectSetPortfolio.businessPlanValidationDateTime === undefined && this.rules?.businessPlanModuleRequirementFlag === PROGRAM_PORTFOLIO_MODULE_FLAGS.REQUIRED;
    this.submitDisabled = x || y;
  }
}
