import { Component, OnInit, Input, forwardRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, UntypedFormControl, AbstractControl, ValidationErrors, ControlValueAccessor } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, delay } from 'rxjs/operators';
import Quill from 'quill';
const Link = Quill.import('formats/link');
Link.sanitize = function(url) {
  if (url.includes('htt')) {
    return url;
  }
  return 'https://' + url;
};

@Component({
  selector: 'oes-quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QuillComponent),
      multi: true
    }
  ]
})
export class QuillComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() characterLimit = 2000;
  @Input() controlName: string;
  @Input() editorDisabled = false;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue = '';
  @Input() option: any;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() setString: Observable<string>;
  @Input() style = {height: '166px'};

  @Output() autoSave: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  characters = 0;
  quillFormGroup: UntypedFormGroup;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor() {
  }

  ngOnInit() {
    this.createFormGroup();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private createFormGroup() {
    this.quillFormGroup = new UntypedFormGroup({
      textControl: new UntypedFormControl('')
    });

    // for auto save
    this.quillFormGroup.controls['textControl'].valueChanges
    .pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(3000),
      distinctUntilChanged()
    )
    .subscribe(updated => {
      // auto save
      if (this.quillFormGroup.valid) {
        this.autoSave.emit(true);
      }
    });

    // set value
    if (this.initialValue && this.initialValue !== '') {
      this.quillFormGroup.controls['textControl'].setValue(this.initialValue, {emitEvent: false});
      this.quillFormGroup.markAsPristine();
    }

    if (this.setString) {
      this.setString
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((text: string) => {
        this.quillFormGroup.controls['textControl'].setValue(text, {emitEvent: false});
      });
    }
  }

  // for initial char count
  public editorInit(event) {
    if (event?.container?.innerText) {
      this.characters = event.container.innerText.length;
    }
  }

  public contentChanged(event) {
    if (event?.text) {
      // Note: If we don't like ngx-quill char count, don't use maxLength and implement our char count with a custom validator.
      this.characters = event.text.length - 1; // There is one char of a new line as default
    }
    // inform the form is update
    this.onTouched();
  }

  get maxLengthError() {
    return this.quillFormGroup.controls['textControl'].getError('maxLengthError');
  }

  get requiredError() {
    return this.quillFormGroup.controls['textControl'].touched &&
           this.quillFormGroup.controls['textControl'].getError('required');
  }

  // ControlValueAccessor
  writeValue(val: any): void {
    if (val) {
      this.quillFormGroup.controls['textControl'].setValue(val, {emitEvent: false});
    } else {
      this.quillFormGroup.controls['textControl'].setValue(this.initialValue, {emitEvent: false});
    }
  }

  // For ControlValueAccessor
  public onTouched: () => void = () => {};

  // For ControlValueAccessor
  registerOnChange(fn: any): void {
    this.quillFormGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  // For ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.quillFormGroup.valueChanges.subscribe(fn);
  }

  // For ControlValueAccessor
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.quillFormGroup.disable() : this.quillFormGroup.enable();
  }

  // For ControlValueAccessor
  validate(c: AbstractControl): ValidationErrors | null {
    return this.quillFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
