import { ComponentType } from '@project/detail/generation-design/shared/component-type.enum';
import { GenerationCapacity } from './generation-system-component.model';
import { GenerationDesign } from '@project/detail/generation-design/shared/generation-design.model';
import { GenerationDesignService } from '@project/detail/generation-design/shared/generation-design.service';
import { OnInit, OnDestroy, Input, Component } from '@angular/core';
import { Subject } from 'rxjs';
import { SystemLoad } from '@project/detail/load/shared/system-load.model';
import { SystemLoadService } from '@project/detail/load/shared/system-load.service';
import { SystemSelectionTabService } from '@project/detail/generation-design/detail/system-selection-tab/system-selection-tab.service';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class AcDcCoupledComponent implements OnInit, OnDestroy {
  @Input() readOnly: boolean;
  @Input() generationDesign: GenerationDesign; // for summary page
  @Input() draggable = true;

  componentStatus =  {};
  componentType = ComponentType;
  capacities = {};
  extra = {
    grid: {
      sellPrice: null,
      buyPrice: null
    },
    load: {
      dailyAverageLoad: null,
      peakKw: null
    }
  };
  ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _generationDesignService: GenerationDesignService,
              private _systemSelectionTabService: SystemSelectionTabService,
              private _systemLoadService: SystemLoadService) {
  }

  ngOnInit(): void {
    // summary page will give a generation design data
    if (!this.generationDesign) {
      this._generationDesignService.generationDesign$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((generationDesign: GenerationDesign) => {
        this.componentStatus = this._systemSelectionTabService.createHomerExisting(generationDesign);
        this.getCapacity(generationDesign);
        this.getSystemLoads(generationDesign);
      });
    } else {
      this.componentStatus = this._systemSelectionTabService.createHomerExisting(this.generationDesign);
      this.getCapacity(this.generationDesign);
      this.getSystemLoads(this.generationDesign);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  public getSystemLoads(generationDesign: GenerationDesign) {
    this._systemLoadService.getSystemLoad(generationDesign.project.id)
    .pipe(take(1))
    .subscribe((systemLoads: SystemLoad[]) => {
      if (systemLoads.length > 0) {
        const systemLoad = systemLoads[0];
        this.extra['load'] = {
          dailyAverageLoad: systemLoad.dailyAverageLoad,
          peakKw: systemLoad.peakKw
        };
      }
    });
  }

  public getCapacity(generationDesign: GenerationDesign) {
    this.capacities = {};
    if (generationDesign) {
      if (generationDesign.projectGenerationDesignItems.length > 0) {
        generationDesign.projectGenerationDesignItems.forEach((data, index) => {
          if (data.type === ComponentType.GRID) {
            this.extra['grid'] = {
              sellPrice: data?.sellPrice,
              buyPrice: data?.buyPrice
            };
          } else {
            this.capacities[data.type] = new GenerationCapacity({
              value: this.capacities[data.type] ? this.capacities[data.type].value + data.size : data.size,
              capex: this.capacities[data.type] ? this.capacities[data.type].capex + data.capex : data.capex,
              unit: data.units,
              homerExcluded: data.homerExcluded
            });
          }
        });
      }
    }
  }
}
