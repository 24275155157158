import { Injectable } from '@angular/core';
import { ProgramProjectStageRules } from '@program/program-detail/program-configuration/program-stages/program-project-stage-rules.model';
import { ProgramApplicationStatus } from '@program/shared/program-application-status.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() {
  }

  // Given a particular background color, this method determines if
  // the overlayed text color should be black or white.
  findTextColor(hexCode: string) {
    hexCode = hexCode.replace('#', '');
    const r = parseInt(hexCode.substr(0, 2), 16);
    const g = parseInt(hexCode.substr(2, 2), 16);
    const b = parseInt(hexCode.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness >= 152 ? '#000000' : '#ffffff';
  }

  orderJSONStatusesByDate(statuses: ProgramProjectStageRules[]): ProgramProjectStageRules[] {
    return statuses.sort((a, b) => {
      const aTime = a.timestamp;
      const bTime = b.timestamp;

      if (aTime && !bTime) {
        return 1;
      }
      if (bTime && !aTime) {
        return -1;
      }
      if (!aTime && !bTime) {
        return 1;
      }
      if (aTime && bTime) {
        let winner;
        while(winner === undefined) {
          for (let i = 0; i < 7; i++) {
            if (aTime[i] > bTime[i]) {
              winner = 1;
              break;
            }
            if (bTime[i] > aTime[i]) {
              winner = -1;
              break;
            }
          }
        }
        return winner;
      }
    });
  }

  orderStatusesByDate(statuses: ProgramProjectStageRules[]): ProgramProjectStageRules[] {
    return statuses.sort((a, b) => {
      const aTime = a.timestamp;
      const bTime = b.timestamp;

      if (aTime && !bTime) {
        return 1;
      }
      if (bTime && !aTime) {
        return -1;
      }
      if (!aTime && !bTime) {
        return 1;
      }
      if (aTime && bTime) {
        if (new Date(aTime) >= new Date(bTime)) {
          return 1;
        } else {
          return -1;
        }
      }
    });
  }

  findProgramApplicationStatus(statuses: ProgramApplicationStatus[]): string | null {
    if (!statuses) {
      return null;
    }
    const sorted = statuses
      .filter(st => st.timestamp)
      .sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });
    if (!sorted?.length) {
      return null;
    }
    return sorted[0].name;
  }

  parseBoolean(str: string): boolean {
    return /^(true|1)$/i.test(str);
  }
}
