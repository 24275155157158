<ng-container *ngIf="chart">
  <div class="pie-chart">
    <h4>{{ chart?.title }}</h4>
    <div class="pie-with-y d-flex">
      <div class="y-name">
        {{ chart?.options?.yname }}
      </div>
      <div class="canvas-container">
        <canvas baseChart
                [data]="pieChartData"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend"
                [type]="'doughnut'">
        </canvas>
      </div>
    </div>
    <ng-container *ngIf="chart?.options?.keyWithValues">
      <oes-key-with-values [keys]="chart?.labels"
                           [percentages]="chart?.datasets[0]?.data"></oes-key-with-values>
    </ng-container>
  </div>
</ng-container>