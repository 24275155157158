import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import {SystemLoad} from './system-load.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SystemLoadScale } from './system-load-scale.model';
import { SliderItem } from '@shared/components/project-load-chart-scale/show-scale-load/slider/slider-item';
import { LoadChartType } from '@shared/components/project-load-chart-scale/load-chart-type.enum';
import { OAuthService } from 'angular-oauth2-oidc';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { SystemLoadApiConstants } from './system-load.constant';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SystemLoadService extends BaseRestApiService {
  private _sliderSubject = new BehaviorSubject<SliderItem[]>(undefined);
  slider$: Observable<SliderItem[]> = this._sliderSubject.asObservable();
  private _maximumSubject = new BehaviorSubject<number>(undefined);
  maximum$: Observable<number> = this._maximumSubject.asObservable();
  // one time delivery
  private _acceptAsNewLoadSubject = new Subject<boolean>();
  acceptAsNewLoad$: Observable<boolean> = this._acceptAsNewLoadSubject.asObservable();
  private _calculateSubject = new BehaviorSubject<boolean>(false);
  calculateSubject$: Observable<boolean> = this._calculateSubject.asObservable();
  private _systemLoadSubject = new BehaviorSubject<SystemLoad>(undefined);
  systemLoadSubject$: Observable<SystemLoad> = this._systemLoadSubject.asObservable();
  private _chartTypeSubject = new BehaviorSubject<LoadChartType>(LoadChartType.Hour);
  chartTypeSubject$: Observable<LoadChartType> = this._chartTypeSubject.asObservable();
  private _chartDataLoadedSubject = new BehaviorSubject<boolean>(false);
  chartDataLoadedSubject$: Observable<boolean> = this._chartDataLoadedSubject.asObservable();
  private order = [
    'other',
    'internal load',
    'anchor',
    'public',
    'productive',
    'commercial',
    'residential'
  ];
  private keyMapper = {
    commercialLoadScaleFactor: 'commercial',
    internalLoadScaleFactor: 'internal load',
    productiveLoadScaleFactor: 'productive',
    publicLoadScaleFactor: 'public',
    residentialLoadScaleFactor: 'residential',
    anchorLoadScaleFactor: 'anchor',
    otherLoadScaleFactor: 'other'
  };
  private _customerTypes: string[];

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _oauthService: OAuthService) {
    super(_restApiWrapperService);
  }

  clear() {
    this._sliderSubject.next(undefined);
    this._maximumSubject.next(undefined);
    this._acceptAsNewLoadSubject.next(false);
    this._systemLoadSubject.next(undefined);
  }

  set systemLoad(systemLoad: SystemLoad) {
    this._systemLoadSubject.next(systemLoad);
  }

  get systemLoad() {
    return this._systemLoadSubject.getValue();
  }

  set chartTypeSubject(chartTypeSubject: LoadChartType) {
    this._chartTypeSubject.next(chartTypeSubject);
  }

  // utilization factor chart set this value
  set customerTypes(customerTypes: any[]) {
    this._customerTypes = customerTypes;
  }

  set chartDataLoaded(status: boolean) {
    this._chartDataLoadedSubject.next(status);
  }

  set calculate(status: boolean) {
    this._calculateSubject.next(status);
  }

  getSystemLoad(projectId: string): Observable<SystemLoad[]> {
    // we were planning to have multiple system loads. page 0 and size 1000 are not necessary.
    return this.get<SystemLoad[]>(SystemLoadApiConstants.get(projectId), {params: {page: 0, size: 1000}})
            .pipe(
              map(results => results.map(result => new SystemLoad(result)))
            );
  }

  saveScaleFactor(projectId: string, systemLoadId: string, data: SystemLoadScale): Observable<SystemLoad> {
    return this.post<any>(SystemLoadApiConstants.resize(projectId, systemLoadId), JSON.stringify(data))
            .pipe(
              map(result => new SystemLoad(result))
            );
  }

  updateLoad(projectId: string, systemLoadId: string) {
    return this.post<SystemLoad>(SystemLoadApiConstants.update(projectId, systemLoadId))
            .pipe(
              map(result => new SystemLoad(result))
            );
  }



  timeSeries(projectId: string, systemLoadId: string): string {
    return `${SystemLoadApiConstants.uploadTimeSeries(projectId, systemLoadId)}`;
  }

  downloadTimeSeries(projectId: string, systemLoadName: string, systemLoadId: string) {
    const filename = systemLoadName + '.csv';
    let url = `${SystemLoadApiConstants.downloadTimeSeries(projectId, systemLoadId)}`;
    const newHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + this._oauthService.getAccessToken());
    const options = {
      headers: newHeaders,
      responseType: 'blob' as const
    };
    this.get(url, options).subscribe((response: any) => FileSaver.saveAs(response, filename));
  }

  // TODO: add a model
  getChartData(projectId: string, systemLoadId: string, periodType: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (periodType != null) {
      params = params.set('chartPeriodType', periodType);
    }
    return this.get(SystemLoadApiConstants.getChartData(projectId, systemLoadId), {params: params});
  }


  getTableData(projectId: string, systemLoadId: string) {
    return this.get(SystemLoadApiConstants.getSummary(projectId, systemLoadId), {params: {page: 0, size: 1000}});
  }

  isActiveCustomerType(key: string): boolean {
    return this._customerTypes.includes(this.keyMapper[key]);
  }

  get systemLoadScale(): SystemLoadScale {
    const sliderItems: SliderItem[] = this._sliderSubject.getValue();
    const result: SystemLoadScale = new SystemLoadScale({});
    sliderItems.forEach((sliderItem: SliderItem) => {
      if (sliderItem.name && sliderItem.name !== '') {
        switch (sliderItem.name.toLowerCase()) {
          case 'residential': {
            result.residentialLoadScaleFactor = sliderItem.scale;
            break;
          }
          case 'commercial': {
            result.commercialLoadScaleFactor = sliderItem.scale;
            break;
          }
          case 'public': {
            result.publicLoadScaleFactor = sliderItem.scale;
            break;
          }
          case 'productive': {
            result.productiveLoadScaleFactor = sliderItem.scale;
            break;
          }
          case 'anchor': {
            result.anchorLoadScaleFactor = sliderItem.scale;
            break;
          }
          case 'internal load': {
            result.internalLoadScaleFactor = sliderItem.scale;
            break;
          }
          case 'other': {
            result.otherLoadScaleFactor = sliderItem.scale;
            break;
          }
        }
      }
    });
    result.maximumLoadScaleFactor = this._maximumSubject.value;
    return result;
  }

  slideUpdate(item: SliderItem[]) {
    if (item) {
      this._sliderSubject.next(item);
    }
  }

  maximumUpdate(value: number) {
    this._maximumSubject.next(value);
  }

  acceptAsNewLoad(value: boolean) {
    this._acceptAsNewLoadSubject.next(value);
  }

  sort(data: any[], key = 'name'): any[] {
    const load = [];
    this.order.forEach(name => {
      const result = data.find(item => (item[key].replace('_', ' ')).toLowerCase() === name.toLowerCase());
      load.push(result);
    });
    // remove undefined
    return load.filter(item => item);
  }
}
