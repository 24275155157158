import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { ProjectFinancialModel } from './project-financial-model.model';
import { FinancialModelApiConstants } from './project-financial-model.constant';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class ProjectFinancialModelService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _oauthService: OAuthService) {
    super(_restApiWrapperService);
  }

  getFinancialModel(projectId: string): Observable<ProjectFinancialModel> {
    // we were planning to use multiple models, but not yet. page and size are not necessary.
    return this.get<ProjectFinancialModel>(FinancialModelApiConstants.get(projectId), {params: {page: 0, size: 1000}})
            .pipe(
              map(result => new ProjectFinancialModel(result))
            );
  }

  saveFinancialModel(projectId: string, financialModel: ProjectFinancialModel, async = false): Observable<ProjectFinancialModel> {
    if (!async) {
      return this.put<ProjectFinancialModel>(FinancialModelApiConstants.save(projectId, financialModel.id), JSON.stringify(financialModel))
              .pipe(
                map(result => new ProjectFinancialModel(result))
              );
    }
    return this.put<ProjectFinancialModel>(FinancialModelApiConstants.save(projectId, financialModel.id), JSON.stringify(financialModel))
            .pipe(
              map(result => new ProjectFinancialModel(result))
            );
  }

  importUrl(projectId: string): string {
    return FinancialModelApiConstants.import(projectId);
  }

  downloadFinancialModel(projectId: string, projectName?: string): void {
    const url: string = FinancialModelApiConstants.export(projectId);
    const options = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this._oauthService.getAccessToken()),
      responseType: 'blob' as const
    };
    this.get(url, options, true)
      .subscribe((response: any) => {
        FileSaver.saveAs(response,  (projectName ? projectName  : projectId) + '.xlsx');
      });
  }

  resetFinancialModel(projectId: string, projectFinancialModelId: string): Observable<any> {
    return this.post(FinancialModelApiConstants.reset(projectId, projectFinancialModelId));
  }
}
