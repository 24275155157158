import { Component, Input, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';

import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { SubmitValidationService } from '@finance/developer-portfolio/shared/submission/submit-validation.service';
import { FinanceService, PORTFOLIO_PAGE } from '@finance/developer-portfolio/shared/finance.service';
import { ProjectSetPortfolioService } from '@project/offtakers/project-set-portfolio/project-set-portfolio.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSetPortfolioNavigationService } from '@finance/developer-project-set-portfolio/project-set-portfolio-navigation/project-set-portfolio-navigation.service';

@Component({
  selector: 'oes-project-set-portfolio-business-plan-form-custom',
  templateUrl: './project-set-portfolio-form-custom.component.html',
  styleUrls: ['./project-set-portfolio-form-custom.component.scss'],
  providers: [
    DynamicFormService,
    ProjectSetPortfolioService
  ]
})
export class ProjectSetPortfolioBusinessPlanFormCustomComponent {
  @Input() projectSetPortfolio: ProjectSetPortfolio;
  @Input() json: {};
  @Input() simple = false;
  @Input() readOnly: boolean;
  @Input() isPastSubmissionDeadline = false;
  @Output() customFormAction: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _submitValidationService: SubmitValidationService,
              private _dynamicFormService: DynamicFormService,
              private _financeService: FinanceService,
              private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService,
              private _projectSetPortfolioService: ProjectSetPortfolioService) {
  }

  /**
   * callback oes-dynamic-form
   * @param event: {}; only contains updated values
   */
  public customFormUpdated(event) {
    if (this.isPastSubmissionDeadline) {
      return this._submitValidationService.sendErrorMessage('too-late');
    }
    const newJson = this._dynamicFormService.updateDefaultValue(this.json, event);

    const formValid = this._dynamicFormService.checkRequiredFields(newJson);
    this._financeService.setPageStatus(PORTFOLIO_PAGE.PORTFOLIO_DESCRIPTION, formValid);

    this._projectSetPortfolioService.uploadForm(
      this.projectSetPortfolio.id,
      this._projectSetPortfolioService.getFormFilesList()['portfolioDescription'],
      newJson,
      formValid
    )
    .pipe(take(1))
    .subscribe(savedFormData => {
      this._projectSetPortfolioNavigationService.getProjectSetPortfolio();
      if (savedFormData) {
        this.customFormAction.emit('save');
      }
    });
  }
}
