import {Injectable} from '@angular/core';
import {from as observableFrom, Subject} from 'rxjs';

import {EVENT_TYPE} from './event-types';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  listeners;
  eventSubject;
  event;

  constructor() {
    this.listeners = {};
    this.eventSubject = new Subject();

    this.event = observableFrom(this.eventSubject);

    this.event.subscribe(
      ({eventType, args}) => {
        if (this.listeners[eventType]) {
          for (const listener of this.listeners[eventType]) {
            listener(...args);
          }
        }
      });
  }

  // TODO Remove listener and unsubscribe when subscribing components are destroyed
  on(eventType: EVENT_TYPE, listener) {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = [];
    }
    this.listeners[eventType].push(listener);
  }

  broadcast(eventType: EVENT_TYPE, ...args) {
    this.eventSubject.next({
      eventType,
      args
    });
  }

  public success(message: string) {
    this.broadcast(EVENT_TYPE.SUCCESS_MESSAGE, message);
  }

  public warning(message: string) {
    this.broadcast(EVENT_TYPE.WARNING_MESSAGE, message);
  }

  public error(message: string) {
    this.broadcast(EVENT_TYPE.ERROR_MESSAGE, message);
  }
}
