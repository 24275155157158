<ng-container [formGroup]="projectSitesFormGroup">
  <p class="text-xs color-font-tertiary mb-2">{{'deal-description.project-sites.innovation-description.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="siteInnovationDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.siteInnovationDescription"
    [placeholder]="'deal-description.project-sites.innovation-description.textarea-placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.project-sites.general-description.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="siteDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.siteDescription"
    [placeholder]="'deal-description.project-sites.general-description.textarea-placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.project-sites.impact-description.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="siteImpactDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.siteImpactDescription"
    [placeholder]="'deal-description.project-sites.impact-description.textarea-placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.project-sites.ownership-description.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="siteOwnershipDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.siteOwnershipDescription"
    [placeholder]="'deal-description.project-sites.ownership-description.textarea-placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.project-sites.describe-process.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="siteSelectionDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.siteSelectionDescription"
    [placeholder]="'deal-description.project-sites.describe-process.textarea-placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.project-sites.permit-status.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="sitePermitDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="2000"
    [initialValue]="financeBusinessPlan?.sitePermitDescription"
    [placeholder]="'deal-description.project-sites.permit-status.textarea-placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.project-sites.sustainability-description.textarea-label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="siteSustainabilityDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.siteSustainabilityDescription"
    [placeholder]="'deal-description.project-sites.sustainability-description.textarea-placeholder' | translate">
  </oes-quill>
</ng-container>
