import {BaseModel} from '../../base.model';
import {Country} from '../country/country.model';

export class Region extends BaseModel<Region> {
  id: string;
  name: string;
  code: string;
  country: Country;

  constructor(regionInfo: any) {
    super();
    this.id = regionInfo.id;
    this.code = regionInfo.code;
    this.name = regionInfo.name;
    if (regionInfo.country != null) {
      this.country = new Country(regionInfo.country);
    }
  }
}
