import { environment } from '@environments/environment';

export class ProjectConnectionApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;
  public static connection = {
    detail: (projectId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections`;
    },
    share: (projectId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections`;
    },
    incoming: (projectId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections/incoming`;
    },
    search: (projectId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections/search`;
    },
    defaultProfile: (projectId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections/default-profile`;
    },
    update: (projectId: string, projectConnectionId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections/${projectConnectionId}`;
    },
    delete: (projectId: string, projectConnectionId: string) => {
      return `${ProjectConnectionApiConstants.projects}/${projectId}/connections/${projectConnectionId}`;
    }
  };
}
