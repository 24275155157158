import { Injectable } from '@angular/core';
import { FinanceStatusUpdate } from '@finance/investor-portfolio/shared/finance-status-update';
import { ProjectGroup } from '@shared/services/project-group.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { take } from 'rxjs/operators';

@Injectable()
export class FinanceProgressUpdateService extends BaseRestApiService {
  private fmProjectGroups = environment.serverUri + `api/${environment.apiVersion}/fm/project-groups/finance`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public updateDealProgress(deal: ProjectGroup) {
    this.put(
      `${this.fmProjectGroups}/${deal.id}/connections/${deal.currentConnection.id}/progress`,
      new FinanceStatusUpdate({financeDealStatus: deal.currentConnection.financeDealStatus})
    )
    .pipe(take(1))
    .subscribe(resp => {});
  }
}
