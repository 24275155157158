<div class="project-action" [ngClass]="{'assets': listType === projectListType.ASSETS}">
  <!-- Create project(s) -->
  <ng-container *ngIf="listType === projectListType.ASSETS">
    <div class="create-project">
      <div dropdown class="dropdown-container">
        <button
          class="btn btn-primary"
          data-testid="create-project-button"
          dropdownToggle>
          {{'project-list.create-projects.title' | translate}}
          <span class="arrow-down-icon">
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.5L6 6.5L11 1.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </button>
        <ul class="dropdown-menu dropdown-menu-right" data-testid="create-project-dropdown" role="menu">
          <li role="menuitem">
            <a class="dropdown-item" (click)="newProject(projectType.minigrid)">
              {{'project-list.create-projects.create-minigrid' | translate}}
            </a>
          </li>
          <li role="menuitem">
            <a class="dropdown-item" (click)="newProject(projectType.ci)">
              {{'project-list.create-projects.create-ci' | translate}}
            </a>
          </li>
          <ng-container *ngIf="isAdminUser()">
            <li role="menuitem">
              <a (click)="emitInstallSampleProject()" class="dropdown-item" data-testid="install-sample-button">
                {{'project-list.action.install-sample.title' | translate}}
                <div class="description">{{'project-list.action.install-sample.description' | translate}}</div>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="list-header">
    <ng-container *ngIf="listType === 'ASSETS' || listType === 'CLAIMS'">
      <div class="bulk-stage-button-wrapper">
        <button *ngIf="!selectedProjects.length"
                [tooltip]="'project-sharing.bulk-button-tooltip' | translate"
                class="btn btn-primary btn-hover-tooltip"
                placement="bottom"
                container="body">
        </button>
        <button [disabled]="!selectedProjects.length"
                class="btn btn-primary"
                (click)="changeStageBulk.emit(true)">{{ 'project-sharing.bulk-button' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="listType === 'MAIN'">
      <div class="ml-2">
        <oes-view-toggle-button [mapView]="true">
        </oes-view-toggle-button>
      </div>
    </ng-container>

  </div>
</div>
