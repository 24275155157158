<ng-container *ngIf="chart">
  <div class="stacked-bar-chart">
    <h4>{{ chart?.title }}</h4>
    <div class="stacked-bar-with-y d-flex">
      <canvas baseChart
              [data]="barChartData"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [height]="280"
              [type]="'bar'">
      </canvas>
    </div>
  </div>
</ng-container>