<oes-nav-header></oes-nav-header>

<div class="offtakers-list">

  <div class="top-page-heading">
    <h3>{{ 'offtaker.list.offtakers' | translate }}</h3>
    <div class="create-offtaker">
      <oes-button
        class="btn btn-primary btn-lg"
        (click)="addOfftakerClick()"
        [leadingIconSrc]="'assets/icons/line-icons/shared/white/plus.svg'"
        data-testid="create-offtaker-button">
        {{ 'offtaker.create' | translate }}
      </oes-button>
    </div>
  </div>

  <oes-grid-sub-action
    class="project-list-sub-actions"
    *ngIf="gridApi"
    [gridState]="gridState"
    [defaultGridState]="defaultGridState"
    (action)="subAction($event)">
  </oes-grid-sub-action>

  <!-- C&I Customers List -->
  <div class="grid-container">
    <div class="ag-grid-header-sticky">
      <ag-grid-angular
        #C&ICustomersList
        id="C&ICustomersList"
        style="width: auto; min-height: 400px;"
        class="grid ag-theme-alpine"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (rowClicked)="editOfftakerClick($event)"
        (filterChanged)="gridStatusChanged($event, 'filter')"
        (sortChanged)="gridStatusChanged($event, 'sort')"
        (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
      </ag-grid-angular>
    </div>
  </div>
</div>

<oes-offtaker-form
  #offtakerForm
  [offtakers]="offtakers"
  [organization]="organization"
  (refreshOfftakers)="getOfftakers()"
></oes-offtaker-form>
