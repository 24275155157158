"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var flat_ejs_1 = require("./flat.ejs");
var form_ejs_1 = require("./form.ejs");
exports.default = {
  flat: flat_ejs_1.default,
  form: form_ejs_1.default
};