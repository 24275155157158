import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { FilesService } from '@shared/components/files/shared/files.service';
import { DeleteTag } from '@shared/components/files/shared/models/delete-tag.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { EventService } from '@shared/event.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-association-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class AssociationDeleteComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;

  tags: any[];

  constructor(private _documentDataService: DocumentDataService,
              private _filesService: FilesService,
              private _translateService: TranslateService,
              private _eventService: EventService) {
  }

  ngOnInit() {
    this._filesService.selectedNodes$
    .subscribe(selectedNode => {
      if (selectedNode && selectedNode.length > 0) {
        const data = selectedNode[0].data;
        const tags = [];
        tags.push(...data.associations);
        tags.push(...data.programTags);
        tags.push(...data.projectTags);
        tags.push(...data.projectGroupTags);
        tags.push(...data.projectSetPortfolioTags);
        this.tags = tags
        .filter(tag => tag.tagType !== DOCUMENT_TAG_TYPE.USER)
        .map(tag => {
          return {
            id: tag.id,
            tag: tag.tag,
            tagType: tag.tagType
          };
        });
      } else {
        this.tags = [];
      }
    });
  }

  show() {
    this.modalDialog.show();
  }

  hide() {
    this.modalDialog.hide();
  }

  complete() {
    this._filesService.clickActionButton(ActionType.completed);
  }

  delete(tagId: string) {
    if (tagId && tagId !== '') {
      const selectedNode = this._filesService.getSelectedNodes();
      const documentTag = <DeleteTag>{
        documentId: selectedNode[0].data.id,
        documentTagsId: tagId,
        documentTagType: selectedNode[0].data.documentTagType
      };
      this._documentDataService.disassociateTag(documentTag)
      .pipe(take(1))
      .subscribe(result => {
        const data = result.find(res => res.id === documentTag.documentId);
        const tags = [];
        tags.push(...data.documentTags);
        tags.push(...data.programTags);
        tags.push(...data.projectTags);
        tags.push(...data.projectGroupTags);
        tags.push(...data.projectSetPortfolioTags);
        this.tags = tags
        .filter(tag => tag.tagType !== DOCUMENT_TAG_TYPE.USER)
        .map(tag => {
          return {
            id: tag.id,
            tag: tag.tag,
            tagType: tag.tagType
          };
        });
        this._eventService.success(this._translateService.instant('data-room.dialogs.tag.deleted'));
      });
    }
  }
}
