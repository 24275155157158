import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { insideRectCheck } from "@shared/utility";

@Directive({
  selector: '[oesAgColumns]'
})
export class AgColumnsDirective implements AfterViewInit, OnDestroy {
  private columnsButton: HTMLElement;
  private columnListContainer: HTMLElement;
  private columnListViewport: HTMLElement;
  private columnListWidthSet = false;
  private columnListWrapper: HTMLElement;
  private labelWidthsDict: {[key: string]: number} = {};
  private loadingOverlay: HTMLElement;
  private observer: MutationObserver;
  private toolPanel: HTMLElement;

  private DEFAULT_PANEL_WIDTH = 248;
  private MAX_PANEL_WIDTH = 600;
  private LABEL_PADDING = 92; // 24px(handle) + 32px(checkbox) + 24px(padding) + 12px(extra slack)

  constructor(private el: ElementRef, private _translateService: TranslateService) { }

  ngAfterViewInit(): void {
    this.columnsButton = this.el.nativeElement.querySelector('.ag-side-button:first-child button');
    this.toolPanel = this.el.nativeElement.querySelector('.ag-tool-panel-wrapper');
    this.columnListContainer = this.el.nativeElement.querySelector('.ag-column-select-virtual-list-container');
    this.columnListViewport = this.columnListContainer?.parentElement;
    this.columnListWrapper = this.columnListViewport?.parentElement;
    this.initLoadingOverlay();
  }

  private determineColumnsPanelWidth() {
    // Menu options aren't populated until the panel is opened, except the first one
    const firstLabel = this.columnListContainer.firstElementChild?.querySelector('.ag-column-select-column-label') as HTMLElement;
    if (firstLabel) {
      this.labelWidthsDict[firstLabel.innerText] = firstLabel.offsetWidth;
    }
    // An observer is needed to detect menu entries as they get populated
    this.observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        mutation.addedNodes.forEach((node: any) => {
          const label = node.querySelector('.ag-column-select-column-label');
          if (label) {
            this.labelWidthsDict[label.innerText] = label.offsetWidth;
          }
        });
      });
      const isScrolledToBottom = this.columnListViewport.scrollTop + this.columnListViewport.clientHeight >= this.columnListContainer.scrollHeight - 10;
      if (!isScrolledToBottom) {
        // AG-Grid logic only populates the menu as entries come into view,
        // therefore we need to scroll to bottom in order to grab them.
        // 'smooth' behavior is crucial here too, in order not to skip any entries
        this.columnListViewport.scrollTo({
          top: this.columnListContainer.scrollHeight,
          behavior: 'smooth'
        });
      } else {
        // All entries are populated, disconnecting the observer
        this.observer.disconnect();
        this.columnListWidthSet = true;
        this.columnListViewport.scrollTop = 0; // Resetting scroll position
        this.setColumnsPanelWidth();
        this.columnListWrapper.removeChild(this.loadingOverlay);
      }
    });

    this.observer.observe(this.columnListContainer, { childList: true });
  }

  private setColumnsPanelWidth() {
    const largestLabelWidth = Math.max(...Object.values(this.labelWidthsDict));
    const expectedPanelWidth = largestLabelWidth + this.LABEL_PADDING;
    if (expectedPanelWidth > this.DEFAULT_PANEL_WIDTH) {
      this.toolPanel.style.width = expectedPanelWidth > this.MAX_PANEL_WIDTH
        ? `${this.MAX_PANEL_WIDTH}px`
        : `${expectedPanelWidth}px`;
    }
  }

  private initLoadingOverlay() {
    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    overlay.textContent = this._translateService.instant('ag-grid-global.loadingOoo');
    this.loadingOverlay = overlay;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.columnsButton || !this.toolPanel) {
      return;
    }
    const clickedInsideToolPanel = insideRectCheck(event.clientX, event.clientY, this.toolPanel);
    const clickedInsideSidebarButtons = insideRectCheck(event.clientX, event.clientY, this.columnsButton);

    if (!clickedInsideToolPanel
        && !clickedInsideSidebarButtons
        && !this.toolPanel.classList.contains('ag-hidden')) {
      this.columnsButton.click();
    } else if (clickedInsideSidebarButtons
      && !this.columnListWidthSet
      && this.columnListWrapper
      && this.columnListViewport
      && this.columnListContainer
    ) {
      this.columnListWrapper.appendChild(this.loadingOverlay);
      this.determineColumnsPanelWidth();
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
