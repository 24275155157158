<div class="actions">
  <ng-container *ngFor="let key of buttonKeys">
    <button
      type="button"
      [ngClass]="buttonClass(key)"
      (click)="click(key)"
      [disabled]="actionButtons[key]?.disabled"
      tooltip="{{'buttons.' + key | translate}}"
      container="body">
      <ng-container *ngIf="actionButtons[key]?.icon; else NoIcon">
        <div [innerHtml]="actionButtons[key].icon | safeHtml"></div>
      </ng-container>
      <ng-template #NoIcon>
        {{'buttons.' + key | translate}}
      </ng-template>
    </button>
  </ng-container>
</div>
