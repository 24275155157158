export class SystemLoadScale {
  maximumLoadScaleFactor: number;

  residentialLoadScaleFactor: number;
  commercialLoadScaleFactor: number;
  anchorLoadScaleFactor: number;
  internalLoadScaleFactor: number;
  productiveLoadScaleFactor: number;
  publicLoadScaleFactor: number;
  otherLoadScaleFactor: number;

  constructor(scales: any) {
    this.maximumLoadScaleFactor = scales.maximumLoadScaleFactor || 200;
    this.residentialLoadScaleFactor = scales.residentialLoadScaleFactor || 100;
    this.commercialLoadScaleFactor = scales.commercialLoadScaleFactor || 100;
    this.anchorLoadScaleFactor = scales.anchorLoadScaleFactor || 100;
    this.internalLoadScaleFactor = scales.internalLoadScaleFactor || 100;
    this.productiveLoadScaleFactor = scales.productiveLoadScaleFactor || 100;
    this.publicLoadScaleFactor = scales.publicLoadScaleFactor || 100;
    this.otherLoadScaleFactor = scales.otherLoadScaleFactor || 100;
  }
}
