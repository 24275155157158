import { environment } from '@environments/environment';
import { SYSTEM_TYPE } from './system-type.enum';

export class GenerationDesignApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;

  public static homer = {
    list: (projectId: string, generationDesignId: string) => {
      return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/jobs`;
    },
    // Seems like this is the download
    result: (projectId: string, generationDesignId: string, jobId: string) => {
      return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/jobs/${jobId}/results`;
    },
    status: (projectId: string, generationDesignId: string, jobId: string) => {
      return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/jobs/${jobId}/status`;
    },
    run: (projectId: string, generationDesignId: string) => {
      return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/run-homer`;
    },
  };

  public static list(projectId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs`;
  }
  public static create(projectId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs`;
  }
  public static detail(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}`;
  }
  public static update(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}`;
  }
  public static delete(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}`;
  }
  public static convert(projectId: string, generationDesignId: string, systemType: SYSTEM_TYPE) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/convert/${systemType}`;
  }
  public static copy(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/copy`;
  }
  public static finalize(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/finalize`;
  }

  public static copyToLibrary(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/library/copy-to-library`;
  }
  public static copyFromLibrary(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/library/copy-from-library/${generationDesignId}`;
  }
  public static updateCost(projectId: string, generationDesignId: string) {
    return `${GenerationDesignApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/update-design-calculations`;
  }
}
