import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

import { DIALOG_BUTTON_STATUS, DialogButtonAction, DialogInitialState } from './dialog-button-action.model';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesDialogService {
  private bsModalRef: BsModalRef;
  private _buttonActionStatusSubject = new BehaviorSubject<DialogButtonAction>(undefined);
  public buttonActionStatus$: Observable<DialogButtonAction> = this._buttonActionStatusSubject.asObservable();
  private nextUrl = '';

  constructor(private _router: Router,
              private _modalService: BsModalService) {
  }


  public open(component: any, initialState: DialogInitialState) {
    const newInitialState = initialState;
    // If buttons is not set, add default button set
    if (!newInitialState.buttons) {
      newInitialState.buttons = [
        DIALOG_BUTTON_STATUS.SAVE,
        DIALOG_BUTTON_STATUS.DO_NOT_SAVE
      ];
    }
    const config = {
      initialState: initialState,
      class: 'modal-md',
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true
    };
    this.nextUrl = initialState.nextUrl;
    this.bsModalRef = this._modalService.show(component, config);
    // this.bsModalRef.content.pageName = pageName;
  }

  public hide() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    this.initialize();
  }

  private initialize() {
    this.nextUrl = '';
    this.bsModalRef = undefined;
    this._buttonActionStatusSubject.next(undefined);
  }

  public execute(dialogButtonAction: DialogButtonAction) {
    if (dialogButtonAction.action === DIALOG_BUTTON_STATUS.CANCEL) {
      this.hide();
    } else {
      this._buttonActionStatusSubject.next(dialogButtonAction);
    }
  }

  get isActive() {
    return this.bsModalRef;
  }
}
