import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavItem } from '@main-navigation/shared/navi-item/nav-item.model';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SideNavigationSecondaryService } from 'src/app/side-navigation-secondary/side-navigation-secondary.service';

import { ProgramHeaderService, ProgramPinnedTabMapping } from './program-header.service';

@Component({
  selector: 'oes-program-header',
  templateUrl: './program-header.component.html',
  styleUrls: ['./program-header.component.scss'],
})
export class ProgramHeaderComponent implements OnInit, OnDestroy {
  allNavItems: NavItem[];
  navItem: NavItem;
  user: User;

  private ngUnsubscribe: Subject<any> = new Subject();
  private pinnedTabs: ProgramPinnedTabMapping;

  constructor(
    private _programHeaderService: ProgramHeaderService,
    private _sideNavigationSecondaryService: SideNavigationSecondaryService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.subscribeCurrentNavItem();
    this.subscribePinnedTabs();
    this._userService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getRouterLink(url: string): string {
    return url.split('/')[url.split('/').length - 1];
  }

  private subscribeCurrentNavItem() {
    this._sideNavigationSecondaryService.navItems$
      .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((items: NavItem[]) => {
          this.allNavItems = items;
        });

    this._sideNavigationSecondaryService.currentNavItem$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((item: NavItem) => {
          this.navItem = item;
        });
  }

  private subscribePinnedTabs() {
    this._programHeaderService.pinnedTabs$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((obj: ProgramPinnedTabMapping) => {
        if (obj) {
          this.pinnedTabs = obj;
        }
      });
  }

  handleClick(event, idx) {
    if (idx !== this.pinnedTabs[this.navItem.type]) {
      const newObj = {
        ...this.pinnedTabs,
        [this.navItem.type]: idx,
      };
      this._programHeaderService.pinnedTabs = newObj;
    }
  }
}
