import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { ManageProjectSetPortfoliosGridSettingService } from './manage-project-set-portfolios-grid-setting.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { Subject } from 'rxjs';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { TranslateService } from '@ngx-translate/core';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ManageProjectSetPortfoliosService } from './manage-project-set-portfolios.service';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';

@Component({
  selector: 'oes-manage-project-set-portfolios',
  templateUrl: 'manage-project-set-portfolios.component.html',
  styleUrls: ['manage-project-set-portfolios.component.scss']
})

export class ManageProjectSetPortfoliosComponent implements OnInit, OnDestroy {
  columnApi: ColumnApi;
  defaultGridState: GridState;
  displayedProjectSets: ProjectSet[];
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridState: GridState;
  projectSetPortfolios: ProjectSetPortfolio[];

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _manageProjectSetPortfoliosService: ManageProjectSetPortfoliosService,
              private _translateService: TranslateService,
              private _manageProjectSetPortfoliosGridSettingService: ManageProjectSetPortfoliosGridSettingService) {
    this.gridOptions = this._manageProjectSetPortfoliosGridSettingService.getGridOptions();
  }

  ngOnInit() {
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this._manageProjectSetPortfoliosGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._manageProjectSetPortfoliosGridSettingService.buildDefaultGridState();
    this.getAllProjectSetPortfolios();

  }

  getAllProjectSetPortfolios() {
    this._manageProjectSetPortfoliosService.findAllProjectSetPortfolios()
    .pipe(take(1))
    .subscribe((projectSetPortfolios: ProjectSetPortfolio[]) => {
      this.projectSetPortfolios = projectSetPortfolios.map(ot => new ProjectSetPortfolio(ot));
      this.displayedProjectSets = this.projectSetPortfolios.reduce((accum, portfolio) => {
        portfolio.projectSets.forEach(ps => {
          ps.portfolioName = portfolio.name;
          ps.portfolioSharedWithOdyssey = portfolio.sharedWithOdyssey;
          accum.push(ps);
        });
        return accum;
      }, []);
      this.setAgGrid(this.displayedProjectSets);
    });
  }

  private setAgGrid(projectSets: ProjectSet[]) {
    if (projectSets?.length > 0) {
      this.gridApi.setRowData(projectSets);
      this._manageProjectSetPortfoliosGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.collapseAll();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._manageProjectSetPortfoliosGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const siteGroupsText = this._translateService.instant('offtaker.site-groups');
        const contractingArrangementTypes = this._translateService.instant('offtaker.contracting-arrangement');
        const stages = this._translateService.instant('offtaker.project-stage');

        const processCellCallback = function(params: any) {
          switch (params.column?.colId) {
            case 'stage':
              return stages[params.value];
            case 'contractType':
              return contractingArrangementTypes[params.value];
            case 'currency':
            case 'totalSites':
            case 'country.name':
            default:
              return params.value;
          }
        };
        this._manageProjectSetPortfoliosGridSettingService.exportCsv(this.gridApi, 'Manage Site Groups', true, processCellCallback);
        break;
      }
      case GridSubAction.reload:
        this.getAllProjectSetPortfolios();
        break;
      case GridSubAction.clearFilter:
        this._manageProjectSetPortfoliosGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private gridInit() {
    this.gridStateStorageKey = this._manageProjectSetPortfoliosGridSettingService.buildGridStateStorageKey('manageSiteGroupPortfolios');
  }
}
