<div class="header-plain">
  <h1>{{ 'files.file-manager' | translate }}</h1>
</div>
<div class="tab-container">
  <tabset>
    <tab [active]="activeTab === 'my-files'"
        (selectTab)="selectTab('my-files')"
        heading="{{ 'files.my-files' | translate }}"
        index="0">
      <ng-container *ngIf="activeTab === 'my-files'">
        <oes-files [title]="'data-room.dialogs.my-files' | translate"
                   [childDataRoom]="false"></oes-files>
      </ng-container>
    </tab>
    <ng-container *featureToggle="'shared-files'">
      <tab [active]="activeTab === 'shared-files'"
            (selectTab)="selectTab('shared-files')"
            heading="{{ 'files.shared-files' | translate }}"
            index="1">
        <ng-container *ngIf="activeTab === 'shared-files'">
          <oes-files [sharedFiles]="true"
                     [title]="'data-room.dialogs.shared-title' | translate"
                     [childDataRoom]="false"></oes-files>
        </ng-container>
      </tab>
    </ng-container>
  </tabset>
</div>
