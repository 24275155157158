import {Component, ElementRef, OnChanges, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {EventService} from '@shared/event.service';
import {IMultiSelectOption, IMultiSelectSettings} from 'ngx-bootstrap-multiselect';

import {TouPeriod} from '@project/detail/revenue/revenue-model-item/tou-period.model';
import {BlockRate} from '@project/detail/revenue/revenue-model-item/block-rate.model';
import {TariffType} from '@project/detail/revenue/tariff-type.model';
import {RevenueModelItem} from '@project/detail/revenue/revenue-model-item/revenue-model-item.model';
import {RevenueModel} from '@project/detail/revenue/revenue-model.model';
import {ReferenceType} from '@shared/references.model';
import {REFERENCE_TYPE} from '@shared/reference-types';
import {ProjectService} from '@project/shared/project.service';
import {CustomerTypeFilter} from './customer-type-filter';
import {BulkRevenueModelUpdateRequest} from './bulk-revenue-model-update-request.model';
import { take } from 'rxjs/operators';

// TODO: Let's replace with FormGroup/FormControl
@Component({
  selector: 'oes-revenue-assumptions-editor',
  templateUrl: './revenue-assumptions-editor.component.html',
  styleUrls: ['./revenue-assumptions-editor.component.scss']
})
export class RevenueAssumptionsEditorComponent implements OnInit, OnChanges {

  tariffTypes: TariffType[];
  useBlockRateStandard: boolean;
  useBlockRateBlock: boolean;
  revenueModel: RevenueModel = new RevenueModel({baseCurrency: 'USD'});
  revenueModelItem: RevenueModelItem = new RevenueModelItem({tariffType: 'NONE', useTimeOfUsePlan: false});
  filter: CustomerTypeFilter = new CustomerTypeFilter();
  buttonClasses = 'dropdown-select d-flex justify-content-between align-items-center';

  selectedTierCategoryIndexes: number[] = [];
  selectedTierCategories: ReferenceType [];
  tierCategories: ReferenceType[];
  tierCategoryOptions: IMultiSelectOption[] = [];
  tierCateggoryFilterSettings: IMultiSelectSettings = {};

  constructor(private _translateService: TranslateService,
              private _eventService: EventService,
              private _renderer: Renderer2,
              private _elRef: ElementRef,
              private _projectService: ProjectService) {
    this.tierCateggoryFilterSettings.enableSearch = true;
    this.tierCateggoryFilterSettings.dynamicTitleMaxItems = 1;
    this.tierCateggoryFilterSettings.selectionLimit = 0;
    this.tierCateggoryFilterSettings.autoUnselect = false;
    this.tierCateggoryFilterSettings.buttonClasses = this.buttonClasses;

  }

  ngOnInit() {
    this.getTierCategories();
    this.tariffTypes = [];
    this.tariffTypes.push(new TariffType({id: 'NONE', code: 'revenue-model-item.none'}));
    this.tariffTypes.push(new TariffType({id: 'STANDARD', code: 'revenue-model-item.standard'}));
    this.tariffTypes.push(new TariffType({id: 'BLOCK', code: 'revenue-model-item.block'}));
    this.initializeRevenueModel();
  }

  ngOnChanges() {
    this.initializeRevenueModel();
  }

  getCustomers() {
    return this.selectedTierCategoryIndexes.map(index => this.indexToCustomerName(index));
  }

  private indexToCustomerName(index) {
    return this.tierCategoryOptions.filter(option => {
      return option.id === index;
    }).map( cust => cust.name);
  }

  getTierCategories() {
    this._projectService.getReferenceType(REFERENCE_TYPE.TIER_CATEGORY)
    .pipe(take(1))
    .subscribe((result: ReferenceType[]) => {
      this.tierCategories = [];
      this.tierCategoryOptions = [];
      if (result) {
        result.forEach((statusType: ReferenceType, index: number) => {
          this._translateService.get(statusType.code)
          .pipe(take(1))
          .subscribe(translatedCode => {
            this.tierCategories.push(new ReferenceType(statusType));
            this.tierCategoryOptions.push({
              id: index,
              name: translatedCode
            });
          });
        });
      }
    });
  }

  private updateAndGetSelectedTierCategories() {
    this.selectedTierCategories = this.selectedTierCategoryIndexes.map(index => {
      return this.tierCategories[index];
    });
    this.filter.categories = this.selectedTierCategories;
    return this.selectedTierCategories; // TODO: why return?
  }

  handleFocus(idx_class) {
    this._elRef.nativeElement.querySelectorAll(idx_class).forEach(val => {
      this._renderer.addClass(val, 'not-editing');
    });
  }

  handleBlur(idx_class) {
    this._elRef.nativeElement.querySelectorAll(idx_class).forEach(val => {
      this._renderer.removeClass(val, 'not-editing');
    });
  }

  initializeRevenueModel() {
    this.revenueModelItem = new RevenueModelItem({tariffType: 'NONE', useTimeOfUsePlan: false});
    this.refreshSetup();
  }

  changeTariffType(tariffType: string) {
    switch (tariffType) {
      case 'STANDARD':
        this.useBlockRateStandard = true;
        this.useBlockRateBlock = false;
        this.revenueModelItem.blockRates = [];
        break;
      case 'BLOCK':
        this.useBlockRateStandard = false;
        this.useBlockRateBlock = true;
        this.revenueModelItem.standardTariffRate = null;
        this.revenueModelItem.blockRates = [];
        this.revenueModelItem.blockRates.push(new BlockRate({minimum: 0}));
        break;
      case 'NONE':
      default:
        this.revenueModelItem.tariffType = 'NONE';
        this.useBlockRateStandard = false;
        this.useBlockRateBlock = false;
        this.revenueModelItem.standardTariffRate = null;
        this.revenueModelItem.blockRates = [];
    }
  }

  changeTouSelection(toggle: boolean) {
    this.revenueModelItem.touPeriods = [];
    if (toggle === true) {
      this.revenueModelItem.touPeriods.push(new TouPeriod({}));
    }
  }

  addBlock() {
    if (this.validateBlockRateData()) {
      this.revenueModelItem.blockRates.push(new BlockRate({
        minimum: this.revenueModelItem.blockRates[this.revenueModelItem.blockRates.length - 1].maximum
      }));
    }
  }

  removeBlock(index: number) {
    if (index === 0) {
      this.changeTariffType('NONE');
    } else {
      this.revenueModelItem.blockRates.splice(index, 1);
    }
  }

  addTouPeriod() {
    if (this.validateTOUData()) {
      this.revenueModelItem.touPeriods.push(new TouPeriod({}));
    }
  }

  removeTouPeriod(index: number) {
    if (index === 0 && this.revenueModelItem.touPeriods.length === 1) {
      this.revenueModelItem.useTimeOfUsePlan = false;
    }
    this.revenueModelItem.touPeriods.splice(index, 1);
  }

  onSubmit() {
    this.addRevenueModelItem(this.revenueModel.id);
  }

  clear() {
    this.revenueModel = new RevenueModel({baseCurrency: 'USD'});
    this.revenueModelItem = new RevenueModelItem({tariffType: 'NONE', useTimeOfUsePlan: false});
    this.refreshSetup();
  }

  request(projectIds: string[]): BulkRevenueModelUpdateRequest {
    const request = new BulkRevenueModelUpdateRequest();
    if (this.validateBlockRateData() &&
      this.validateTOUData() &&
      this.touSaveValidation() &&
      this.blockRateSaveValidation()) {
      this.standardRateValidtaion();
      request.filter = this.filter;
      request.items.push(this.revenueModelItem);
      request.items.forEach(item => item.touPeriods.forEach(touPeriod => touPeriod.modifier = touPeriod.modifier / 100));
      request.model = this.revenueModel;
      request.projects = projectIds;
      return request;
    }
    return null;
  }

  addRevenueModelItem(revenueModelId: string) {
    if (this.validateBlockRateData() &&
      this.validateTOUData() &&
      this.touSaveValidation() &&
      this.blockRateSaveValidation()) {
      this.standardRateValidtaion();
    }
  }

  validateBlockRateData(): boolean {
    let isValid = true;

    this.revenueModelItem.blockRates.forEach((obj: BlockRate, i: number, arr: BlockRate[]) => {
      // For every row but the last, make sure the following business rules are met:
      if (i < arr.length - 1 || obj.maximum) {
        // Make sure 'Max Cum. Energy' > 'Min Cum. Energy'
        if (obj.minimum >= obj.maximum) {
          this._translateService.get('revenue-model-item.errors.block-rates.max-min',
            {row: `${i + 1}`, max: `${obj.maximum}`, min: `${obj.minimum}`})
            .subscribe((message: string) => {
              this._eventService.error(message);
            });
          isValid = false;
        }
      }
    });

    return isValid;
  }

  validateTOUData(): boolean {
    let isValid = true;

    this.revenueModelItem.touPeriods.sort((a, b) => {
      return a.start - b.start;
    }).forEach((obj: TouPeriod, i: number, arr: TouPeriod[]) => {
      //  validate that the start time is before or equal to end time
      if (+obj.start >= +obj.end) {
        this._translateService.get('revenue-model-item.errors.tou-periods.start-end', {
          row: `${i + 1}`,
          'start-time': `${obj.start}`,
          'end-time': `${obj.end}`
        })
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
      //  validate that the start time of the current row is not greater than the end time of the previous row, except last row
      if (i < arr.length - 1 && +obj.end > +arr[i + 1].start) {
        this._translateService.get('revenue-model-item.errors.tou-periods.overlapping',
          {
            'end-row': `${i + 1}`,
            'start-row': `${i + 2}`,
            'start-time': `${arr[i + 1].start}`,
            'end-time': `${obj.end}`
          })
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
      // validate that start and end times are integers if they are not null || undefined
      if (!(obj.start == null || Number.isInteger(obj.start)) || !(obj.end == null || Number.isInteger(obj.end))) {
        this._translateService.get('revenue-model-item.errors.tou-periods.not-integer',
          {row: `${i + 1}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
      // validate that start time is between 0 and 23 and end time is between 1 - 24.
      if (+obj.start < 0 || +obj.end < 1 || +obj.start > 23 || +obj.end > 24) {
        this._translateService.get('revenue-model-item.errors.tou-periods.zero-twenty-four',
          {row: `${i + 1}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
    });
    return isValid;
  }

  blockRateSaveValidation(): boolean {
    let isValid = true;
    this.revenueModelItem.blockRates.forEach((obj: BlockRate, i: number, arr: BlockRate[]) => {
      // Make sure there is more than one line of Block rates
      if (arr.length === 1) {
        this._translateService.get('revenue-model-item.errors.block-rates.one-line')
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
        // Last row, make sure the maximum is empty
      } else if (i === arr.length - 1) {
        obj.maximum = null;
      }
      // Ensure Rate is not null to prevent Java Save error
      if (obj.rate == null && obj.altRate == null) {
        this._translateService.get('revenue-model-item.errors.block-rates.rate',
          {row: `${i + 1}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
      // Ensure Max Cum Energy is not null to prevent Java Save error (all but last line)
      if (i > 0 && arr[i - 1].maximum == null) {
        this._translateService.get('revenue-model-item.errors.block-rates.max',
          {row: `${i}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
    });
    return isValid;
  }

  touSaveValidation(): boolean {
    let isValid = true;
    this.revenueModelItem.touPeriods.forEach((obj: TouPeriod, i: number) => {
      // Ensure Modifier is not null to prevent Java Save error
      if (obj.modifier == null) {
        this._translateService.get('revenue-model-item.errors.tou-periods.modifier',
          {row: `${i + 1}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
      // Ensure End Time is not null to prevent Java Save error
      if (obj.end == null) {
        this._translateService.get('revenue-model-item.errors.tou-periods.end',
          {row: `${i + 1}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
      // Ensure Start Time is not null to prevent Java Save error
      if (obj.start == null) {
        this._translateService.get('revenue-model-item.errors.tou-periods.start',
          {row: `${i + 1}`})
          .subscribe((message: string) => {
            this._eventService.error(message);
          });
        isValid = false;
      }
    });
    return isValid;
  }

  standardRateValidtaion() {
    if (this.revenueModelItem.standardTariffRate == null) {
      this.revenueModelItem.standardTariffRate = 0;
    }
  }

  updateMin(index) {
    if (index < this.revenueModelItem.blockRates.length - 1) {
      this.revenueModelItem.blockRates[index + 1].minimum = this.revenueModelItem.blockRates[index].maximum;
    }
  }

  onTierCategoryChange(event) {
    this.updateAndGetSelectedTierCategories();
  }

  /**
   * This gets and returns a boolean value that is set (to true) when an investor clicks on a project on the L3 page
   * in my deals and used with ngClass (and in some case ngIf) to hide edit buttons and links.
   */
  isNotEditable() {
    return false;
  }

  refreshSetup() {
    if (this.revenueModelItem.tariffType === 'BLOCK') {
      this.useBlockRateStandard = false;
      this.useBlockRateBlock = true;
    }
    if (this.revenueModelItem.tariffType === 'STANDARD') {
      this.useBlockRateStandard = true;
      this.useBlockRateBlock = false;
    }
    if (this.revenueModelItem.tariffType === 'NONE') {
      this.useBlockRateStandard = false;
      this.useBlockRateBlock = false;
    }
  }

}
