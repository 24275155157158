import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectMenuCategory } from './project-menu-category.enum';

export type ProjectPinnedTabMapping = { [C in ProjectMenuCategory] : number };

@Injectable({
  providedIn: 'root'
})
export class ProjectHeaderService {
  private pinnedTabsSubject = new BehaviorSubject<ProjectPinnedTabMapping>({
    'overview': 0,
    'planning': 0,
    'submit': 0,
    'milestones': 0,
    'connections': 0,
    'messages': 0,
  });
  public pinnedTabs$: Observable<ProjectPinnedTabMapping> = this.pinnedTabsSubject.asObservable();

  constructor() {
  }

  set pinnedTabs(obj: ProjectPinnedTabMapping) {
    this.pinnedTabsSubject.next(obj);
  }
}
