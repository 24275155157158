<div class="excel-loader d-flex flex-row justify-content-end">
  <label class="btn btn-primary" for="import-excel-button">{{buttonUpload}}</label>
  <input id="import-excel-button"
         data-testid="loader-upload-button"
         type="file"
         (change)="uploadExcel($event)"
         accept=".xlsx,.xls"
         style="display: none;"
         [(ngModel)]="filename">
  <div *ngIf="buttonDownload && downloadFile" data-testid="loader-download-button">
    <a class="btn btn-secondary" oesTemplateDownload [fileName]="downloadFile">{{buttonDownload}}</a>
  </div>
</div>
