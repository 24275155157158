import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { ManageProjectSetPortfoliosApiConstants } from './manage-project-set-portfolios-api.constant';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class ManageProjectSetPortfoliosService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  findAllProjectSetPortfolios(): Observable<any> {
    return this.get<ProjectSetPortfolio[]>(ManageProjectSetPortfoliosApiConstants.findAllProjectSetPorfolios());
  }

}
