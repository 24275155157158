<div class="sub-action">
  <ng-container *ngIf="resetFilter">
    <oes-button type="button"
                class="btn btn-tertiary btn-gray btn-sm reset"
                data-testid="reset-filters-button"
                (click)="emitAction(subAction.resetFilter)"
                tooltip="{{'buttons.reset-filters' | translate}}">
      {{ 'grid-actions.reset-filters' | translate }}
    </oes-button>
  </ng-container>
  <ng-container *ngIf="clearFilter">
    <oes-button type="button"
            class="btn btn-tertiary btn-gray btn-sm"
            [disabled]="!(sortState || filterState || columnState)"
            data-testid="clear-filters-button"
            (click)="emitAction(subAction.clearFilter)"
            tooltip="{{'buttons.clear-filters' | translate}}">
      {{ 'grid-actions.clear-filters' | translate }}
    </oes-button>
  </ng-container>
  <ng-container *ngIf="reload">
    <oes-button type="button"
            class="btn btn-tertiary btn-gray btn-icon btn-sm reload"
            (click)="emitAction(subAction.reload)"
            tooltip="{{'buttons.reload' | translate}}">
      <img style="width: 16px;" src="assets/icons/line-icons/shared/grey/reload.svg" />
    </oes-button>
  </ng-container>
  <ng-container *ngIf="exportList">
    <oes-button type="button"
      class="btn btn-tertiary btn-gray btn-icon btn-sm export-csv"
      data-testid="export-list-button"
      (click)="emitAction(subAction.exportList)"
      tooltip="{{'buttons.export-list' | translate}}"
      placement="left">
      <img style="width: 15px;" src="assets/icons/line-icons/shared/grey/export.svg" />
    </oes-button>
  </ng-container>
  <ng-container *ngIf="exportAll">
    <oes-button type="button"
      class="btn btn-tertiary btn-gray btn-icon btn-sm export-csv"
      data-testid="export-all-button"
      (click)="emitAction(subAction.exportAll)"
      tooltip="{{'buttons.export-all' | translate}}"
      placement="left">
      <img style="width: 15px;" src="assets/icons/line-icons/shared/grey/export.svg" />
    </oes-button>
  </ng-container>
</div>
