<div class="stage-overview">

  <p>{{ 'project-sharing.bulk-modal-text' | translate }}:</p>
  <p class="selected-projects-list">
    <strong *ngFor="let project of selectedProjects;">
      {{ project.name }}
    </strong>
  </p>

  <form [formGroup]="submitForm">
    <div class="submit-message d-flex flex-column">
      <div class="btn-group status-dropdown" dropdown #dropdown="bs-dropdown" [autoClose]="true">
        <div class="required">{{ 'project-sharing.stage' | translate }}:</div>
        <button dropdownToggle type="button" class="dropdown-toggle">
          <div class="status-pill" [ngStyle]="{ 'background-color': getSelectColor(), 'color': getTextColor(getSelectColor()) }">
            {{ submitForm.controls['projectStage'].value }}
            <div class="caret"></div>
          </div>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li (click)="onSelectStage()" class="status-pill">None</li>
          <ng-container *ngFor="let stage of stages">
            <li [ngStyle]="{ 'background-color': stage.color, 'color': getTextColor(stage.color) }"
                class="status-pill"
                (click)="onSelectStage(stage.name)">
              {{ stage.name }}
            </li>
          </ng-container>
        </ul>
      </div>


      <!-- Message -->
      <oes-quill
        [characterLimit]="3000"
        formControlName="body"
        [option]="quillOptions"
        [placeholder]="'project-sharing.placeholder' | translate">
      </oes-quill>

      <div class="d-flex justify-content-end">
        <button class="btn btn-primary"
                (click)="submit()"
                [disabled]="submitForm?.invalid">
          {{ 'buttons.submit' | translate }}
        </button>
      </div>
    </div>
  </form>

</div>
