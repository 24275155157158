import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionResponsibility } from '@project/offtakers/connection-responsibility.enum';
import { Offtaker } from '@project/offtakers/offtaker.model';
import { ProjectStage } from '@project/offtakers/project-stage.enum';
import { EventService } from '@shared/event.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProjectSet } from '../../project-set.model';
import { ProjectSetService } from '../../project-set.service';
import { ProjectSetFinancialModel } from '../financial-model/project-set-financial-model.model';
import { ProjectSetFinancialModelService } from '../financial-model/project-set-financial-model.service';


@Component({
  selector: 'oes-project-set-details',
  templateUrl: 'project-set-details.component.html',
  styleUrls: ['./project-set-details.component.scss']
})
export class ProjectSetDetailsComponent implements OnInit, OnDestroy {
  @Input() activeTab = 'details';
  @Input() projectSetId: string;
  @Input() readOnly: boolean;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reloadParent: EventEmitter<boolean> = new EventEmitter<boolean>();

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    showWeekNumbers: false,
    dateInputFormat: 'MMMM YYYY',
    minMode: 'month',
    minDate: new Date()
  };
  displayDeveloperResponsibilitySection: boolean = false;
  displayGridTiedSection: boolean = false;
  displayQuarterlyProductions: boolean = false;
  displayQuarterlyConsumptions: boolean = false;
  displayStrandedAndTariff: boolean = false;
  displayDieselGeneratorDemands: boolean = false;
  displayGridDemands: boolean = false;
  displayMonthlySpend: boolean = false;
  displayMonthlySpendWarning: boolean = false;
  displayMonthsToOperationWarning: boolean = false;
  displayNonOperationsQuestions: boolean = false;
  displayPreConstructionQuestions: boolean = false;
  displayTariffEscalation: boolean = false;

  enableAvgGridConsumption: boolean = false;
  enableAvgDieselConsumption: boolean = false;
  enableFeedInTariff: boolean = false;

  ngUnsubscribe: Subject<any> = new Subject();
  offtaker: Offtaker;
  projectSet: ProjectSet;
  siteDetailForm: UntypedFormGroup;
  totalsDisplay = {
    systemSize: 0,
    capex: 0,
    pvProduction: 0
  };

  constructor(private _projectSetService: ProjectSetService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _projectSetFinancialModelService: ProjectSetFinancialModelService) {
  }

  ngOnInit() {
    this.getProjectSet();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  submitForm(event) {
    this.getFormValuesForProjectSet();
    this.updateProjectSet();
  }

  emitCloseEvent() {
    this.close.emit(true);
  }

  private getProjectSet() {
    this._projectSetService.detail(this.projectSetId)
      .pipe(take(1))
      .subscribe((projectSet: ProjectSet) => {
        this.projectSet = projectSet;
        this.displayGridTiedSection = projectSet.gridTied;
        this.displayDieselGeneratorDemands = projectSet.dieselGenerator;
        this.displayPreConstructionQuestions = projectSet.stage === ProjectStage.PRE_CONSTRUCTION;
        this.displayNonOperationsQuestions = projectSet.stage !== ProjectStage.OPERATIONS;
        this.offtaker = projectSet.offtaker;
        this.buildForm(projectSet);
      });
  }

  private updateProjectSet() {
    this._projectSetService.update(this.projectSet)
      .pipe(take(1))
      .subscribe((projectSet: ProjectSet) => {
        this._eventService.success(this._translateService.instant('offtaker.project-set-edit.changes-saved'));
        if (projectSet?.stage === ProjectStage.PRE_CONSTRUCTION) {
          setTimeout(() => {
            this._eventService.success(this._translateService.instant('offtaker.project-set-edit.model-updating'));
            this.scrollToTop();
          }, 1000);
          this.projectSet = projectSet;
          this.runFinancialModel();
        } else {
          this.projectSet = projectSet;
          this.scrollToTop();
        }
      });
  }

  private runFinancialModel() {
    this._projectSetFinancialModelService.run(this.projectSetId)
      .pipe(take(1))
      .subscribe((projectSetFinancialModel: ProjectSetFinancialModel) => {
        this._eventService.success(this._translateService.instant('offtaker.project-set-edit.model-updated'));
        this.reloadParent.emit(true);
      });
  }

  private scrollToTop() {
    document.querySelector('.modal').scrollTo({top:0,behavior:'smooth'});
  }

  private buildForm(projectSet: ProjectSet) {
    this.siteDetailForm = new UntypedFormGroup({
      averageSystemSize: new UntypedFormControl(projectSet.averageSystemSize, { validators: [Validators.required, Validators.min(0)] }),
      totalCapex: new UntypedFormControl(projectSet.totalCapex, { validators: [Validators.required, Validators.min(0)] }),
      averageAnnualDegradation: new UntypedFormControl(projectSet.averageAnnualDegradation, { validators: [Validators.min(0), Validators.max(100)] }),
      averageContractLength: new UntypedFormControl(projectSet.averageContractLength, { validators: [Validators.required, Validators.min(0)] }),
      averageTariff: new UntypedFormControl(projectSet.averageTariff, { validators: [Validators.required, Validators.min(0)] }),
      omExpense: new UntypedFormControl(projectSet.omExpense, { validators: [Validators.required, Validators.min(0)] }),
      insuranceExpense: new UntypedFormControl(projectSet.insuranceExpense, { validators: [Validators.required, Validators.min(0)] }),
      estimatedStartDate: new UntypedFormControl(projectSet.estimatedStartDate ? formatDate(projectSet.estimatedStartDate, 'MMMM YYYY', 'en') : new Date(), { validators: [Validators.required] }),
      monthsToOperation: new UntypedFormControl(projectSet.monthsToOperation, { validators: [Validators.required, Validators.min(0), Validators.max(12)] }),
      evenSpend: new UntypedFormControl(projectSet.evenSpend?.toString() || 'true'),
      spendM1: new UntypedFormControl(projectSet.spendM1, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM2: new UntypedFormControl(projectSet.spendM2, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM3: new UntypedFormControl(projectSet.spendM3, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM4: new UntypedFormControl(projectSet.spendM4, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM5: new UntypedFormControl(projectSet.spendM5, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM6: new UntypedFormControl(projectSet.spendM6, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM7: new UntypedFormControl(projectSet.spendM7, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM8: new UntypedFormControl(projectSet.spendM8, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM9: new UntypedFormControl(projectSet.spendM9, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM10: new UntypedFormControl(projectSet.spendM10, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM11: new UntypedFormControl(projectSet.spendM11, { validators: [Validators.min(0), Validators.max(100)] }),
      spendM12: new UntypedFormControl(projectSet.spendM12, { validators: [Validators.min(0), Validators.max(100)] }),

      averagePvProduction: new UntypedFormControl(projectSet.averagePvProduction, { validators: [Validators.required, Validators.min(0)] }),
      evenProductionPerQuarter: new UntypedFormControl(projectSet.evenProductionPerQuarter?.toString() || 'true'),
      pvProductionQ1: new UntypedFormControl(projectSet.pvProductionQ1, { validators: [Validators.min(0), Validators.max(100)] }),
      pvProductionQ2: new UntypedFormControl(projectSet.pvProductionQ2, { validators: [Validators.min(0), Validators.max(100)] }),
      pvProductionQ3: new UntypedFormControl(projectSet.pvProductionQ3, { validators: [Validators.min(0), Validators.max(100)] }),
      pvProductionQ4: new UntypedFormControl(projectSet.pvProductionQ4, { validators: [Validators.min(0), Validators.max(100)] }),

      averageCustomerConsumption: new UntypedFormControl(projectSet.averageCustomerConsumption, { validators: [Validators.required, Validators.min(0)] }),
      evenConsumptionPerQuarter: new UntypedFormControl(projectSet.evenConsumptionPerQuarter?.toString() || 'true'),
      consumptionQ1: new UntypedFormControl(projectSet.consumptionQ1, { validators: [Validators.min(0), Validators.max(100)] }),
      consumptionQ2: new UntypedFormControl(projectSet.consumptionQ2, { validators: [Validators.min(0), Validators.max(100)] }),
      consumptionQ3: new UntypedFormControl(projectSet.consumptionQ3, { validators: [Validators.min(0), Validators.max(100)] }),
      consumptionQ4: new UntypedFormControl(projectSet.consumptionQ4, { validators: [Validators.min(0), Validators.max(100)] }),

      takeOrPay: new UntypedFormControl(projectSet.takeOrPay?.toString() || 'true'),
      connectionResponsibility: new UntypedFormControl(projectSet.connectionResponsibility || ConnectionResponsibility.OFFTAKER),
      strandedProductionPercentage: new UntypedFormControl(projectSet.strandedProductionPercentage, { validators: [Validators.min(0), Validators.max(100)] }),
      feedInTariff: new UntypedFormControl(projectSet.feedInTariff, { validators: [Validators.min(0), Validators.max(100)] }),
      passThroughInflation: new UntypedFormControl(projectSet.passThroughInflation?.toString() || 'true'),
      tariffEscalation: new UntypedFormControl(projectSet.tariffEscalation, { validators: [Validators.min(0), Validators.max(100)] }),
      opexInflation: new UntypedFormControl(projectSet.opexInflation, { validators: [Validators.min(0), Validators.max(100)] }),
      offtakerConsumptionRequired: new UntypedFormControl(projectSet.offtakerConsumptionRequired?.toString() || 'false'),
      gridConsumptionPercentage: new UntypedFormControl(projectSet.gridConsumptionPercentage, { validators: [Validators.min(0), Validators.max(100)] }),
      averageGridConsumptionPrice: new UntypedFormControl(projectSet.averageGridConsumptionPrice, { validators: [Validators.min(0)] }),
      averageDieselConsumptionPrice: new UntypedFormControl(projectSet.averageDieselConsumptionPrice, { validators: [Validators.min(0)] }),

      comments: new UntypedFormControl(projectSet.comments)
    });
    if (this.readOnly) {
      this.siteDetailForm.disable();
    }
    this.subscribeFormChanges();
  }

  private subscribeFormChanges() {
    this.siteDetailForm.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(values => {
      this.totalsDisplay.systemSize = (values.averageSystemSize || 0) * this.projectSet.totalProjects;
      this.totalsDisplay.capex = values.totalCapex || 0;
      this.totalsDisplay.pvProduction = (values.averagePvProduction || 0) * this.projectSet.totalProjects;

      if (values.passThroughInflation === 'true') {
        this.siteDetailForm.controls['tariffEscalation'].patchValue(0, { emitEvent: false });
        this.siteDetailForm.controls['opexInflation'].patchValue(0, { emitEvent: false });
      }

      if (values.evenSpend === 'false') {
        const months = values?.monthsToOperation || 0;
        for (let i = 1; i <=12; i++) {
          if (i > months) {
            this.siteDetailForm.controls['spendM' + i].patchValue(0, { emitEvent: false });
            this.siteDetailForm.controls['spendM' + i].disable({ emitEvent: false });
          } else {
            if (!this.readOnly) {
              this.siteDetailForm.controls['spendM' + i].enable({ emitEvent: false });
            }
          }
        }
      } else {
        const months = values?.monthsToOperation || 0;
        const percentage = 100 / months;
        for (let i = 1; i <= 12; i++) {
          if (i <= months) {
            this.siteDetailForm.controls['spendM' + i].patchValue(percentage, { emitEvent: false });
          } else {
            this.siteDetailForm.controls['spendM' + i].patchValue(0, { emitEvent: false });
          }
        }
      }

      if (values.evenProductionPerQuarter === 'false') {
        if (this.displayQuarterlyProductions === false) {
          for (let i = 1; i <= 4; i++) {
            if (this.siteDetailForm.controls['pvProductionQ' + i].value === null) {
              this.siteDetailForm.controls['pvProductionQ' + i].patchValue(25, { emitEvent: false });
            }
          }
          this.displayQuarterlyProductions = true;
        }
      } else {
        for (let i = 1; i <= 4; i++) {
          this.siteDetailForm.controls['pvProductionQ' + i].patchValue(25, { emitEvent: false });
        }
        this.displayQuarterlyProductions = false;
      }

      if (values.evenConsumptionPerQuarter === 'false') {
        if (this.displayQuarterlyConsumptions === false) {
          for (let i = 1; i <= 4; i++) {
            if (this.siteDetailForm.controls['consumptionQ' + i].value === null) {
              this.siteDetailForm.controls['consumptionQ' + i].patchValue(25, { emitEvent: false });
            }
          }
          this.displayQuarterlyConsumptions = true;
        }
      } else {
        this.displayQuarterlyConsumptions = false;
        for (let i = 1; i <= 4; i++) {
          this.siteDetailForm.controls['consumptionQ' + i].patchValue(25, { emitEvent: false });
        }
      }

      this.displayStrandedAndTariff = values.takeOrPay === 'false' ? true : false;
      this.displayDeveloperResponsibilitySection = values.connectionResponsibility === ConnectionResponsibility.DEVELOPER ? true : false;
      this.displayGridDemands = values.offtakerConsumptionRequired === 'true' ? true : false;
      this.displayMonthlySpend = values.evenSpend === 'false' ? true : false;
      this.displayTariffEscalation = values.passThroughInflation === 'false' ? true : false;
      this.displayMonthsToOperationWarning = values.monthsToOperation && values.monthsToOperation > 12;

      if (!this.displayPreConstructionQuestions) {
        this.siteDetailForm.controls['totalCapex'].clearValidators();
        this.siteDetailForm.controls['totalCapex'].updateValueAndValidity({ emitEvent: false });
        this.siteDetailForm.controls['estimatedStartDate'].clearValidators();
        this.siteDetailForm.controls['estimatedStartDate'].updateValueAndValidity({ emitEvent: false });
        this.siteDetailForm.controls['monthsToOperation'].clearValidators();
        this.siteDetailForm.controls['monthsToOperation'].updateValueAndValidity({ emitEvent: false });
      }

      if (!this.displayNonOperationsQuestions) {
        this.siteDetailForm.controls['averageContractLength'].clearValidators();
        this.siteDetailForm.controls['averageContractLength'].updateValueAndValidity({ emitEvent: false });
      }

      let totalSpend = 0;
      for (let i = 1; i <= 12; i++) {
        totalSpend += this.siteDetailForm.controls['spendM' + i].value;
      }
      this.displayMonthlySpendWarning = totalSpend < 99.55 || totalSpend >= 100.45 ? true : false;

      if (values.gridConsumptionPercentage <= 0) {
        this.siteDetailForm.controls['averageGridConsumptionPrice'].disable({ emitEvent: false });
      } else {
        if (!this.readOnly) {
          this.siteDetailForm.controls['averageGridConsumptionPrice'].enable({ emitEvent: false });
        }
      }
      if (values.strandedProductionPercentage <= 0) {
        this.siteDetailForm.controls['feedInTariff'].disable({ emitEvent: false });
      } else {
        if (!this.readOnly) {
          this.siteDetailForm.controls['feedInTariff'].enable({ emitEvent: false });
        }
      }
    });

  }

  private getFormValuesForProjectSet() {
    this.projectSet.averageSystemSize = this.siteDetailForm.controls['averageSystemSize'].value || 0;
    this.projectSet.totalCapex = this.siteDetailForm.controls['totalCapex'].value || 0;
    this.projectSet.averageAnnualDegradation = this.siteDetailForm.controls['averageAnnualDegradation'].value || 0;
    this.projectSet.averageContractLength = this.siteDetailForm.controls['averageContractLength'].value || 0;
    this.projectSet.averageTariff = this.siteDetailForm.controls['averageTariff'].value || 0;
    // eslint-disable-next-line no-constant-binary-expression
    this.projectSet.estimatedStartDate = new Date(this.siteDetailForm.controls['estimatedStartDate'].value) || new Date();

    this.projectSet.monthsToOperation = this.siteDetailForm.controls['monthsToOperation'].value || 0;
    this.projectSet.evenSpend = this.siteDetailForm.controls['evenSpend'].value === 'false' ? false : true;
    this.projectSet.spendM1 = this.siteDetailForm.controls['spendM1'].value;
    this.projectSet.spendM2 = this.siteDetailForm.controls['spendM2'].value;
    this.projectSet.spendM3 = this.siteDetailForm.controls['spendM3'].value;
    this.projectSet.spendM4 = this.siteDetailForm.controls['spendM4'].value;
    this.projectSet.spendM5 = this.siteDetailForm.controls['spendM5'].value;
    this.projectSet.spendM6 = this.siteDetailForm.controls['spendM6'].value;
    this.projectSet.spendM7 = this.siteDetailForm.controls['spendM7'].value;
    this.projectSet.spendM8 = this.siteDetailForm.controls['spendM8'].value;
    this.projectSet.spendM9 = this.siteDetailForm.controls['spendM9'].value;
    this.projectSet.spendM10 = this.siteDetailForm.controls['spendM10'].value;
    this.projectSet.spendM11 = this.siteDetailForm.controls['spendM11'].value;
    this.projectSet.spendM12 = this.siteDetailForm.controls['spendM12'].value;

    this.projectSet.omExpense = this.siteDetailForm.controls['omExpense'].value || 0;
    this.projectSet.insuranceExpense = this.siteDetailForm.controls['insuranceExpense'].value || 0;
    this.projectSet.averagePvProduction = this.siteDetailForm.controls['averagePvProduction'].value || 0;
    this.projectSet.averageCustomerConsumption = this.siteDetailForm.controls['averageCustomerConsumption'].value || 0;

    this.projectSet.evenProductionPerQuarter = this.siteDetailForm.controls['evenProductionPerQuarter'].value === 'false' ? false : true;
    if (this.projectSet.evenProductionPerQuarter) {
      this.projectSet.pvProductionQ1 = 25;
      this.projectSet.pvProductionQ2 = 25;
      this.projectSet.pvProductionQ3 = 25;
      this.projectSet.pvProductionQ4 = 25;
    } else {
      this.projectSet.pvProductionQ1 = this.siteDetailForm.controls['pvProductionQ1'].value;
      this.projectSet.pvProductionQ2 = this.siteDetailForm.controls['pvProductionQ2'].value;
      this.projectSet.pvProductionQ3 = this.siteDetailForm.controls['pvProductionQ3'].value;
      this.projectSet.pvProductionQ4 = this.siteDetailForm.controls['pvProductionQ4'].value;
    }

    this.projectSet.evenConsumptionPerQuarter = this.siteDetailForm.controls['evenConsumptionPerQuarter'].value === 'false' ? false : true;
    if (this.projectSet.evenConsumptionPerQuarter) {
      this.projectSet.consumptionQ1 = 25;
      this.projectSet.consumptionQ2 = 25;
      this.projectSet.consumptionQ3 = 25;
      this.projectSet.consumptionQ4 = 25;
    } else {
      this.projectSet.consumptionQ1 = this.siteDetailForm.controls['consumptionQ1'].value;
      this.projectSet.consumptionQ2 = this.siteDetailForm.controls['consumptionQ2'].value;
      this.projectSet.consumptionQ3 = this.siteDetailForm.controls['consumptionQ3'].value;
      this.projectSet.consumptionQ4 = this.siteDetailForm.controls['consumptionQ4'].value;
    }

    this.projectSet.comments = this.siteDetailForm.controls['comments']?.value?.textControl || '';
    this.projectSet.takeOrPay = this.siteDetailForm.controls['takeOrPay'].value === 'false' ? false : true;
    this.projectSet.connectionResponsibility = this.siteDetailForm.controls['connectionResponsibility'].value || ConnectionResponsibility.OFFTAKER;
    this.projectSet.strandedProductionPercentage = this.siteDetailForm.controls['strandedProductionPercentage'].value || 0;
    this.projectSet.feedInTariff = this.siteDetailForm.controls['feedInTariff'].value || 0;
    this.projectSet.offtakerConsumptionRequired = this.siteDetailForm.controls['offtakerConsumptionRequired'].value === 'false' ? false : true;
    this.projectSet.gridConsumptionPercentage = this.siteDetailForm.controls['gridConsumptionPercentage'].value;
    this.projectSet.averageGridConsumptionPrice = this.siteDetailForm.controls['averageGridConsumptionPrice'].value;
    this.projectSet.averageDieselConsumptionPrice = this.siteDetailForm.controls['averageDieselConsumptionPrice'].value;
    this.projectSet.passThroughInflation = this.siteDetailForm.controls['passThroughInflation'].value === 'false' ? false : true;
    this.projectSet.tariffEscalation = this.siteDetailForm.controls['tariffEscalation'].value;
    this.projectSet.opexInflation = this.siteDetailForm.controls['opexInflation'].value;
  }
}
