export enum OfftakerType {
  RETAIL = 'RETAIL',
  MANUFACTURER = 'MANUFACTURER',
  WAREHOUSING = 'WAREHOUSING',
  AGRICULTURE = 'AGRICULTURE',
  OTHER = 'OTHER',
  MIXED = 'MIXED'
};

export enum OfftakerTypeLegacy {
  BANK = 'BANK',
  RETAIL_CHAIN = 'RETAIL_CHAIN',
  SME = 'SME',
};
