import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MILESTONE_STATUS } from '@program/shared/milestone-status.enum';
import { Project } from '@project/shared/project.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { Milestone } from '../milestone.model';
import { Document } from '@shared/components/files/shared/models/document.model';
import { User } from '@user/user.model';
import { MilestoneEditInputs, MilestoneFilesWrapper } from '../milestone-util-types';
import { MilestoneService } from '../milestone.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oes-milestone-edit',
  templateUrl: './milestone-edit.component.html',
  styleUrls: ['./milestone-edit.component.scss'],
})
export class MilestoneEditComponent implements OnInit, OnDestroy {
  @ViewChild('editForm', {static: false}) editForm: any;
  @ViewChild('editMilestoneModal', {static: false}) editMilestoneModal: ModalDialogComponent;
  @Input() project: Project;
  @Input() user: User;

  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  allDocuments: string[] = [];
  milestoneEdit: Milestone;
  milestone: Milestone;
  milestoneIndex: number;
  modalShowing = false;
  noteDocuments: Document[] = [];
  noteContent: string = '';
  noteIndex: number;
  programOwner: boolean;
  projectOwner: boolean;
  readOnly: boolean;
  showDocumentAddedError = false;
  showNeedsNoteContentError = false;
  statuses = MILESTONE_STATUS;
  uploadedDocuments: File[] = [];

  private openEditMilestoneSubscription: Subscription;

  constructor(private _milestoneService: MilestoneService) {
  };

  ngOnInit(): void {
    this.openEditMilestoneSubscription = this._milestoneService.openEditMilestone$
    .subscribe((data: MilestoneEditInputs) => {
      if (!data) {
        return;
      }
      this.milestone = data.milestone;
      this.milestoneEdit = JSON.parse(JSON.stringify(this.milestone));
      this.milestoneIndex = data.milestoneIndex;
      this.noteDocuments = data.noteDocuments ? data.noteDocuments : [];
      this.noteIndex = data.noteIndex;
      this.handleOpenEditMilestone();
    });

    const modulePermissions = this.project?.projectPermissions?.project?.modulePermissions;
    this.readOnly = modulePermissions?.milestones?.readOnly;
    this.programOwner = this.project.projectPermissions.currentUser.programOwner;
    this.projectOwner = this.project.projectPermissions.currentUser.projectOwner;
  };

  ngOnDestroy(): void {
    if (this.openEditMilestoneSubscription) {
      this.openEditMilestoneSubscription.unsubscribe();
    }
  }

  handleOpenEditMilestone() {
    this.initialiseModal();
    this.editMilestoneModal.show();
  }

  private initialiseModal() {
    this.uploadedDocuments = [];
    if (this.milestone.notes) {
      this.noteContent = this.noteIndex !== undefined ? this.milestone.notes[this.noteIndex]?.content || '' : '';
    }
    this.allDocuments = this.noteDocuments.map(doc => doc.path);
    this.modalShowing = true;
  }

  handleCloseEditMilestone() {
    this.modalShowing = false;
    this.editMilestoneModal.hide();
  }

  onStatusChange(status: MILESTONE_STATUS, radioBtn: HTMLInputElement) {
    this.milestoneEdit.status = status;
    radioBtn.checked = true;
  };

  uploadDocumentClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      const newDoc = input.files[0];
      if (!this.allDocuments.includes(newDoc.name)) {
        this.allDocuments.push(newDoc.name);
        this.uploadedDocuments.push(newDoc);
        this.showDocumentAddedError = false;
      } else {
        this.showDocumentAddedError = true;
      }
    };
    input.click();
  };

  removeDocumentClick(event: any, document: string) {
    event.preventDefault();
    this.milestoneEdit.documents = this.milestoneEdit.documents.filter(doc => doc.path !== document);
    this.allDocuments = this.allDocuments.filter(doc => doc !== document);
    this.uploadedDocuments = this.uploadedDocuments.filter(doc => doc.name !== document);
  };

  onSubmit() {
    if ((this.uploadedDocuments.length || this.allDocuments.length) && !this.noteContent.length) {
      this.showNeedsNoteContentError = true;
      return;
    }

    if (this.editForm.controls.note.touched) {
      this.buildNotes();
    }
    let filesAdded: MilestoneFilesWrapper = {files: []};
    if (this.uploadedDocuments.length || this.allDocuments.length !== this.noteDocuments.length) {
      filesAdded = this.wrapMilestoneNoteFiles();
    }
    this._milestoneService.updateFilesAdded(this.milestoneEdit, filesAdded);
    this.isLoading.emit(true);
    this.editMilestoneModal.hide();
  };

  buildNotes() {
    if (this.noteIndex !== undefined) {
      this.milestoneEdit.notes[this.noteIndex].content = this.noteContent.trim();
    } else {
      this.milestoneEdit.notes.push({
        id: undefined,
        content: this.noteContent.trim(),
        organization: this.user.organization,
      });
    }
  };

  private wrapMilestoneNoteFiles(): MilestoneFilesWrapper {
    return {
      files: this.uploadedDocuments,
      note: this.noteIndex !== undefined
      ? this.milestoneEdit.notes[this.noteIndex]
      : this.milestoneEdit.notes[this.milestoneEdit.notes.length - 1],
    };
  }
}
