import {BaseModel} from '@shared/base.model';
import {Country} from '@shared/services/country/country.model';

export class InvestorInformation extends BaseModel<InvestorInformation> {
  allCountries: boolean;
  concessionaryDebtCapital: boolean;
  corporateEquityCapital: boolean;
  countries: Country[];
  debtCapital: boolean;
  discountedMarketRate: boolean;
  epcActivities: boolean;
  financeActivities: boolean;
  grantCapital: boolean;
  grantRate: boolean;
  id: string;
  investedInMicroGrids: boolean;
  investmentObjectives: string;
  marketRate: boolean;
  maximumProjectSize: number;
  maximumTerm: number;
  minimumProjectSize: number;
  minimumTerm: number;
  noMaximumProjectSize: boolean;
  noMinimumProjectSize: boolean;
  noPreferredSize: boolean;
  noPreferredTerm: boolean;
  numberInvestmentsLastYear: number;
  numberInvestmentsTotal: number;
  preferredSize: number;
  preferredTerm: number;
  previousInvestment: boolean;
  projectDevelopmentActivities: boolean;
  projectEquityCapital: boolean;
  sectorExperience: string;
  supplyActivities: boolean;
  valueInvestmentsLastYear: number;
  valueInvestmentsTotal: number;
  yearsOfInvesting: number;
  zeroRate: boolean;

  constructor(investorInfo: any) {
    super();
    this.allCountries = investorInfo.allCountries;
    this.concessionaryDebtCapital = investorInfo.concessionaryDebtCapital;
    this.corporateEquityCapital = investorInfo.corporateEquityCapital;
    this.countries = [];
    this.debtCapital = investorInfo.debtCapital;
    this.discountedMarketRate = investorInfo.discountedMarketRate;
    this.epcActivities = investorInfo.epcActivities;
    this.financeActivities = investorInfo.financeActivities;
    this.grantCapital = investorInfo.grantCapital;
    this.grantRate = investorInfo.grantRate;
    this.id = investorInfo.id;
    this.investedInMicroGrids = investorInfo.investedInMicroGrids;
    this.investmentObjectives = investorInfo.investmentObjectives;
    this.marketRate = investorInfo.marketRate;
    this.maximumProjectSize = investorInfo.maximumProjectSize;
    this.maximumTerm = investorInfo.maximumTerm;
    this.minimumProjectSize = investorInfo.minimumProjectSize;
    this.minimumTerm = investorInfo.minimumTerm;
    this.noMaximumProjectSize = investorInfo.noMaximumProjectSize;
    this.noMinimumProjectSize = investorInfo.noMinimumProjectSize;
    this.noPreferredSize = investorInfo.noPreferredSize;
    this.noPreferredTerm = investorInfo.noPreferredTerm;
    this.numberInvestmentsLastYear = investorInfo.numberInvestmentsLastYear;
    this.numberInvestmentsTotal = investorInfo.numberInvestmentsTotal;
    this.preferredSize = investorInfo.preferredSize;
    this.preferredTerm = investorInfo.preferredTerm;
    this.previousInvestment = investorInfo.previousInvestment;
    this.projectDevelopmentActivities = investorInfo.projectDevelopmentActivities;
    this.projectEquityCapital = investorInfo.projectEquityCapital;
    this.sectorExperience = investorInfo.sectorExperience;
    this.supplyActivities = investorInfo.supplyActivities;
    this.valueInvestmentsLastYear = investorInfo.valueInvestmentsLastYear;
    this.valueInvestmentsTotal = investorInfo.valueInvestmentsTotal;
    this.yearsOfInvesting = investorInfo.yearsOfInvesting;
    this.zeroRate = investorInfo.zeroRate;

    if (investorInfo.countries) {
      this.countries = investorInfo.countries.map(country => new Country(country));
    }
  }

  get isInvestorInfoValid(): boolean {
    return this.sectorExperience &&
           this.investmentObjectives &&
           (this.allCountries || this.countries.length > 0) &&
           (this.concessionaryDebtCapital || this.corporateEquityCapital || this.debtCapital || this.grantCapital || this.projectEquityCapital) &&
           (this.epcActivities || this.financeActivities || this.projectDevelopmentActivities || this.supplyActivities);
  }
}
