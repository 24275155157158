import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Offtaker } from '@project/offtakers/offtaker.model';
import { EventService } from '@shared/event.service';
import FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProjectSetFinancialModel } from './project-set-financial-model.model';
import { ProjectSetFinancialModelService } from './project-set-financial-model.service';

@Component({
  selector: 'oes-project-set-financial-model',
  templateUrl: 'project-set-financial-model.component.html',
  styleUrls: ['./project-set-financial-model.component.scss']
})
export class ProjectSetFinancialModelComponent implements OnInit, OnDestroy {
  @Input() projectSetId: string;
  @Input() offtaker: Offtaker;
  ngUnsubscribe: Subject<any> = new Subject();
  psfm: ProjectSetFinancialModel;
  yearlyCashflowChartColors: string[] = [
    '#30819F',
    '#0DBC8C',
    '#FF7F00',
    'rgba(255, 127, 0, 0.1)'
  ];
  expensesBreakdownChartColors: string[] = [
    '#FF7F00',
    '#CC2A16',
    '#30819F',
    '#F5CE3C'
  ];
  lifeOfProjectEnergyChartColors: string[] = [
    '#FFE5CC',
    '#FF7F00',
    '#CC2A16'
  ];
  quarterlyEnergyKwhChartColors: string[] = [
    '#FFE5CC',
    '#FF7F00',
    '#CC2A16'
  ];

  constructor(private _projectSetFinancialModelService: ProjectSetFinancialModelService,
              private _eventService: EventService,
              private _translateService: TranslateService) {
  }

  ngOnInit() {
    this.getFinancialModel();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  updateToLatestModel(id: string) {
    this._projectSetFinancialModelService.updateToLatestModel(this.projectSetId, id)
      .pipe(take(1))
      .subscribe((psfm: ProjectSetFinancialModel) => {
        this.runModel();
        this._eventService.success(this._translateService.instant('offtaker.financial-model.updated-model-version') + ': ' + psfm?.template?.modelVersion);
      });
  }

  runModel() {
    this._projectSetFinancialModelService.run(this.projectSetId)
      .pipe(take(1))
      .subscribe((psfm: ProjectSetFinancialModel) => {
        this.getFinancialModel();
      });
  }

  downloadRun(runId: string) {
    this._projectSetFinancialModelService.downloadRun(this.projectSetId, runId)
      .pipe(take(1))
      .subscribe((data: any) => {
        const name = this.buildDownloadName(runId);
        FileSaver.saveAs(data, name);
      });
  }

  scrollToTop() {
    document.querySelector('.modal').scrollTo({top:0,behavior:'smooth'});
  }

  modelRunToday(createdDate: Date): boolean {
    const created = new Date(createdDate);
    const now = new Date();
    if (now.getDay() === created.getDay()) {
      return true;
    }
    return false;
  }

  private getFinancialModel() {
    this._projectSetFinancialModelService.detail(this.projectSetId)
      .pipe(take(1))
      .subscribe((psfm: ProjectSetFinancialModel) => {
        this.psfm = psfm;
        if (!this.psfm?.allRuns?.length) {
          this.runModel();
        }
      });
  }

  private buildDownloadName(runId: string) {
    const offtakerName = this.offtaker?.name;
    const groupName = 'Site Group ' + this.psfm?.projectSet?.name;
    return offtakerName + ' ' + groupName + ' - FINANCIAL MODEL.xlsx';
  }

}
