import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SecondaryMenuCategory } from './secondary-menu-category.enum';
import { UrlCategory } from './url-category.model';

@Injectable({
  providedIn: 'root'
})
export class UrlCheckService {

  constructor(private _router: Router) {
  }

  detectUrl(): UrlCategory {
    const url = this._router.url;
    if (!url || url === '') {
      return undefined;
    }
    const result = new UrlCategory();
    const offtakersPath = url.match(/projects\/ci-customers\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const projectPath = url.match(/projects\/.+\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const programPath = url.match(/finance\/programs\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const portfolioPath = url.match(/finance\/portfolios\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const ciPortfolioPath = url.match(/finance\/ci-portfolios\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const financeCiPortfolioPath = url.match(/finance\/finance-ci-portfolios\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const fianceMarketplacePath = url.match(/finance\/finance-marketplace\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const quotePath = url.match(/procurement\/my-quotes\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const rfqPath = url.match(/vendor-marketplace\/my-rfqs\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}\//);
    const organizationProfilePath = url.match(/organization-management\/profile\//);
    // project detail. get until the end of UUID
    if (offtakersPath?.length > 0) {
      result.url = offtakersPath[0];
      result.category = SecondaryMenuCategory.offtakers;
    } else if (projectPath?.length > 0) {
      result.url = projectPath[0];
      result.category = SecondaryMenuCategory.projects;
    } else if (programPath?.length > 0) {
    // Finance: Program
      result.url = programPath[0];
      result.category = SecondaryMenuCategory.program;
    } else if (portfolioPath?.length > 0) {
    // Finance: Portfolio
      result.url = portfolioPath[0];
      result.category = SecondaryMenuCategory.portfolio;
    } else if (ciPortfolioPath?.length > 0) {
    // Finance: C&I Portfolio
      result.url = ciPortfolioPath[0];
      result.category = SecondaryMenuCategory.ciPortfolio;
    } else if (financeCiPortfolioPath?.length > 0) {
    // Finance: Finance C&I Portfolio
      result.url = financeCiPortfolioPath[0];
      result.category = SecondaryMenuCategory.financeCiPortfolio;
    } else if (fianceMarketplacePath?.length > 0) {
      result.url = fianceMarketplacePath[0];
      result.category = SecondaryMenuCategory.financeMarketplace;
    } else if (quotePath?.length > 0) {
      result.url = quotePath[0];
      result.category = SecondaryMenuCategory.myQuotes;
    } else if (rfqPath?.length > 0) {
      result.url = rfqPath[0];
      result.category = SecondaryMenuCategory.myRfqs;
    } else if (organizationProfilePath?.length > 0) {
      result.url = organizationProfilePath[0];
      result.category = SecondaryMenuCategory.organizationProfile;
    }
    return result;
  }

  getProgramId(urlCategory: UrlCategory): string {
    const urlArray = urlCategory.url.split('/');
    const programsIndex = urlArray.findIndex(str => str === 'programs');
    return urlArray[programsIndex + 1];
  }

  getCiPortfolioId(urlCategory: UrlCategory): string {
    const urlArray = urlCategory.url.split('/');
    const portfoliosIndex = urlArray.findIndex(str => str === 'ci-portfolios');
    return urlArray[portfoliosIndex + 1];
  }

  getFinanceCiPortfolioId(urlCategory: UrlCategory): string {
    const urlArray = urlCategory.url.split('/');
    const portfoliosIndex = urlArray.findIndex(str => str === 'finance-ci-portfolios');
    return urlArray[portfoliosIndex + 1];
  }
}
