export class BlockRate {
  id: string;
  minimum: number;
  maximum: number;
  rate: number;
  altRate: number;

  constructor(blockRateInfo: any) {
    this.id = blockRateInfo.id;
    this.minimum = blockRateInfo.minimum;
    this.maximum = blockRateInfo.maximum;
    this.rate = blockRateInfo.rate;
    this.altRate = blockRateInfo.altRate;
  }
}
