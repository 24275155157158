import {BaseModel} from '@shared/base.model';

export class FinanceAppliance extends BaseModel<FinanceAppliance> {
  count: number;
  description: string;
  sourceType: string;

  constructor(financeApplianceInfo: any) {
    super();
    this.id = financeApplianceInfo.id;
    this.count = financeApplianceInfo.count;
    this.description = financeApplianceInfo.description;
    this.sourceType = financeApplianceInfo.sourceType;
  }
}
