import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ReferenceType } from './references.model';
import { BaseRestApiService } from './services/base-rest-api.service';
import { RestApiWrapperService } from './services/rest-api-wrapper.service';
import { environment } from '@environments/environment';
import { REFERENCE_TYPE } from './reference-types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService extends BaseRestApiService {
  private url = `${environment.serverUri}api/${environment.apiVersion}/references`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getType(referenceType: REFERENCE_TYPE): Observable<ReferenceType[]> {
    if (referenceType) {
      let params = new HttpParams();
      params = params.set('referenceType', referenceType.toString());
      return this.get<ReferenceType[]>(this.url, {params: params}).pipe(
        map(results => results.map(result => new ReferenceType(result)))
      );
    }
  }
}
