import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FinanceConnectionService } from '@finance/developer-portfolio/shared/finance-connection.service';
import { FinanceConnection } from '@finance/developer-portfolio/shared/finance-connection.model';


@Injectable()
export class ShareWithInvestorService {

  constructor(private _financeConnectionService: FinanceConnectionService) {

  }

  public getFinanceConnections(projectGroupId: string): Observable<FinanceConnection[]>  {
    return this._financeConnectionService.list(projectGroupId);
  }

  public submitToInvestor(projectGroupId: string, financeConnection: any): Observable<FinanceConnection> {
    return this._financeConnectionService.submitFinanceConnection(projectGroupId, financeConnection);
  }
}
