import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from './files.service';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  private _folders: any[];

  constructor(private _translateService: TranslateService,
              private _fileService: FilesService) {
  }

  set folders(folders: any[]) {
    this._folders = folders;
  }

  public generateSelectOptions(selectedPaths?: any[], tagTypes?: any[], orgId?: string): any {
    let selectOptions = [];
    let baseLevel = [];
    let omittedIds = [];
    if (selectedPaths && selectedPaths.length) {
      omittedIds = selectedPaths.reduce((acc, path) => {
        if (!acc.includes(path.id)) {
          acc.push(path.id);
        }
        if (!acc.includes(path.parendId)) {
          acc.push(path.parentId);
        }
        return acc;
      }, []);
    }
    if (this._folders) {
      selectOptions = this._folders.filter(folder => {
        if (selectedPaths && selectedPaths.length > 0) {
          return !omittedIds.includes(folder.id);
        }
        return true;
      }).map(folder => {
        const masterTagType = this._fileService.getMasterTag(folder.associations, orgId);
        if (masterTagType) {
          return {
            id: folder.id,
            name: folder.path,
            docTagType: masterTagType.docTagType,
            docTagItemId: masterTagType.docTagItemId
          };
        }
      });
    }
    // add 'base level'
    const topLevelTag = this._fileService.getMasterTag(tagTypes, orgId);
    if (!selectedPaths ||
        (selectedPaths && selectedPaths.every(selectedPath => !(selectedPath.parentId === 'ROOT_NODE_ID')))) {
      baseLevel = [{
        id: 'baseLevel',
        name: this._translateService.instant('data-room.base-level'),
        docTagType: topLevelTag.docTagType,
        docTagItemId: topLevelTag.docTagItemId
      }];
    }
    return [...baseLevel, ...selectOptions];
  }
}
