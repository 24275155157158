import { BaseModel } from '@shared/base.model';
import { Project } from '@project/shared/project.model';
import { MilestoneConfig } from '@program/shared/milestone-config.model';
import { MILESTONE_STATUS } from '@program/shared/milestone-status.enum';
import { Document } from '@shared/components/files/shared/models/document.model';
import { MilestoneNote } from './milestone-util-types';

export class Milestone extends BaseModel<Milestone> {
  project: Project;
  milestoneConfig: MilestoneConfig;
  name: string;
  description: string;
  version: number;
  status: MILESTONE_STATUS;
  inProgressDate: Date;
  completedDate: Date;
  details: string;
  documents: Document[];
  notes: MilestoneNote[];
  latest: boolean;
  updated: Date;
  updateUser: string;
  created: Date;

  constructor(milestoneInfo: any) {
    super();

    this.id = milestoneInfo.id;
    this.project = milestoneInfo.project;
    this.name = milestoneInfo.name;
    this.description = milestoneInfo.description;
    this.version = milestoneInfo.version;
    this.milestoneConfig = milestoneInfo.milestoneConfig;
    this.status = milestoneInfo.status;
    this.inProgressDate = milestoneInfo.inProgressDate;
    this.completedDate = milestoneInfo.completedDate;
    this.details = milestoneInfo.details;
    this.documents = milestoneInfo.documents;
    this.latest = milestoneInfo.latest;
    this.updated = milestoneInfo.updated;
    this.updateUser = milestoneInfo.updateUser;
    this.created = milestoneInfo.created;
  }
}
