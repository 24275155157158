import { Component, OnInit, ViewChild, Input, ElementRef, SecurityContext } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { FilesService } from '@shared/components/files/shared/files.service';
import { CreatePath } from '@shared/components/files/shared/models/create-path.model';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { FolderService } from '@shared/components/files/shared/folder.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AddTag } from '@shared/components/files/shared/models/add-tags.model';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'oes-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;
  @ViewChild('oesAutoFocus', {static: false}) input: ElementRef;
  @Input() tagTypes: TagType[];
  @Input() organizationId: string;

  public formGroup: UntypedFormGroup;
  public selectOptions: IMultiSelectOption[] = [];
  public slashError = false;
  public hasInput = false;
  public selectedOption: IMultiSelectOption;
  public tags: any;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _documentDataService: DocumentDataService,
              private _filesService: FilesService,
              private _sanitizer: DomSanitizer,
              private _translateService: TranslateService,
              private _eventService: EventService,
              private _folderService: FolderService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      selectOption: new UntypedFormControl(),
      folderName: new UntypedFormControl('', Validators.required),
      tag: new UntypedFormControl('')
    });
    this.subscribeFolderName();
  }

  subscribeFolderName() {
    this.formGroup.controls['folderName'].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((folderName: string) => {
        this.slashError = folderName && folderName.includes('/') ? true : false;
      });
  }

  onKeyDown(e) {
    if (e.keyCode === 13 && this.formGroup.valid && !this.slashError) {
      this.createNewFolder();
    }
  }

  public show() {
    this.tags = this._filesService.tagList;
    this.selectOptions = this._folderService.generateSelectOptions(undefined, this.tagTypes, this.organizationId);
    const highlightedNodes = this._filesService.getHighlightedNodes();
    if (highlightedNodes && highlightedNodes.length === 1 && highlightedNodes[0].data.documentKey.docType === 'PATH') {
      const selectedId = this.selectOptions.findIndex(opt => opt.id === highlightedNodes[0].id);
      this.selectedOption = this.selectOptions[selectedId];
    } else {
      this.selectedOption = this.selectOptions[0];
    }
    this.modalDialog.show();

    // Workaround for autofocus within a modal
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 500);
  }

  public hide() {
    this.formGroup.reset();
    this.modalDialog.hide();
  }

  // callback: button
  public createNewFolder() {
    const tag = this._sanitizer.sanitize(SecurityContext.HTML, this.formGroup.controls['tag'].value);
    const pathId = (this.formGroup.controls['selectOption'].value)[0];
    const newFolderName = this._sanitizer.sanitize(SecurityContext.HTML, this.formGroup.controls['folderName'].value);
    if (newFolderName && newFolderName !== '') {
      const path = this.selectOptions.find(option => option.id === pathId);
      const fullPath = pathId === 'baseLevel' ? newFolderName : path.name + '/' + newFolderName;
      const createPath: CreatePath = {
        path: fullPath,
        tagTypes: this.tagTypes
      };
      const masterTagType = this._filesService.getMasterTag(this.tagTypes);
      createPath['index'] = masterTagType.docTagItemId;
      createPath['systemDocumentType'] = masterTagType.docTagType;
      this._documentDataService.createFolder(createPath)
      .pipe(take(1))
      .subscribe(document => {
        tag ? this.addOptionalTag(document.id, tag) : this.finish();
      });
      this.hide();
    }
  }

  public selectTag(event) {
    this.formGroup.controls['tag'].setValue(event.target.value);
  }

  finish() {
    this.formGroup.reset();
    this._filesService.clickActionButton(ActionType.completed);
    this._eventService.success(this._translateService.instant('data-room.dialogs.create-folder.created'));
  }

  addOptionalTag(docId, tag) {
    const addTag: AddTag = {
      documentIds: [docId],
      tag: tag
    };
    const id = this._filesService.getTagId(tag);
    if (id && id !== '') {
      addTag['id'] = id;
    }

    this._documentDataService.addUserTag(addTag)
    .pipe(take(1))
    .subscribe(result => {
      this.finish();
    });
  }
}
