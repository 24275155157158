import { BaseModel } from '@shared/base.model';

import { ProjectSet } from '../../project-set.model';
import { ProjectSetFinancialModelRun } from './project-set-financial-model-run.model';
import { ProjectSetFinancialModelTemplate } from './project-set-financial-model-template.model';

export class ProjectSetFinancialModel extends BaseModel<ProjectSetFinancialModel> {
  allRuns: ProjectSetFinancialModelRun[];
  currentRun: ProjectSetFinancialModelRun;
  modelVersion: string;
  projectSet: ProjectSet;
  template: ProjectSetFinancialModelTemplate;

  constructor(projectSetFinancialModel: any) {
    super();
    this.id = projectSetFinancialModel?.id;
    this.allRuns = projectSetFinancialModel?.allRuns;
    this.currentRun = this.getCurrentRun();
    this.projectSet = projectSetFinancialModel?.projectSet;
    this.template = projectSetFinancialModel?.template;
  }

  private getCurrentRun() {
    if (this.allRuns?.length > 0) {
      const orderedRuns = this.allRuns?.sort((a, b) => {
        if (b.created > a.created) {
          return 1;
        }
        return -1;
      })[0];
      return orderedRuns;
    }
    return null;
  }
}
