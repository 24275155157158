<ng-container [formGroup]="customersFormGroup">
  <p class="text-xs color-font-tertiary mb-2">{{'deal-description.customers.general-description.label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="customerTypeDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerTypeDescription"
    [placeholder]="'deal-description.customers.general-description.placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.customers.describe-relations.label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="customerRelationsDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerRelationsDescription"
    [placeholder]="'deal-description.customers.describe-relations.placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.customers.describe-education.label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="customerEducationDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerEducationDescription"
    [placeholder]="'deal-description.customers.describe-education.placeholder' | translate">
  </oes-quill>
  <p class="text-xs color-font-tertiary mb-2 mt-4">{{'deal-description.customers.describe-pricing.label' | translate}}</p>
  <oes-quill
    class="style-overhaul-flag"
    formControlName="customerPricingDescription"
    [editorDisabled]="isPastSubmissionDeadline"
    [characterLimit]="3000"
    [initialValue]="financeBusinessPlan?.customerPricingDescription"
    [placeholder]="'deal-description.customers.describe-pricing.placeholder' | translate">
  </oes-quill>
</ng-container>
