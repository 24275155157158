import { Component } from '@angular/core';
import { ILoadingCellRendererAngularComp } from 'ag-grid-angular';
import { ILoadingCellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'oes-loading-cell-renderer',
  templateUrl: './loading-cell-renderer.component.html',
  styleUrls: ['./loading-cell-renderer.component.scss']
})
export class LoadingCellRendererComponent implements ILoadingCellRendererAngularComp {
  params!: ILoadingCellRendererParams & { loadingMessage: string };

  agInit(
    params: ILoadingCellRendererParams & { loadingMessage: string }
  ): void {
    this.params = params;
  }
}
