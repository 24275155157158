<div class="milestone-form">
  <div class="warnings">
    <p class="text-xs overwrite-warning"
    >{{ 'milestones.form.form-overwrite-warning' | translate }}</p>
    <ng-container *ngIf="isDirty">
      <p class="text-xs saving-guard"
      >{{ 'program.pre-qualification.claims-reporting.saving-guard' | translate }}</p>
    </ng-container>
  </div>

  <!-- Add form loading signage -->
  <ng-container *ngIf="formJson; else noData">
    <oes-dynamic-form
      class="dynamic-form"
      [json]="formJson"
      [readOnly]="readOnly"
      (beforeSubmission)="beforeSubmit($event)"
      (changed)="formChanged()"
      [enableSubmit]="true"
      (fileSave)="fileSave()"
      (formValid)="formValid = $event"
      (save)="formSave($event)"
      #oesDynamicForm>
    </oes-dynamic-form>
  </ng-container>
  <ng-template #noData>
    <div class="empty-outer">
      <div class="empty">
        <oes-notify
          [iconName]="'fa-exclamation'"
          [message]="'program.pre-qualification.no-form' | translate"
          [type]="'empty'">
        </oes-notify>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="updateUser && saveDate">
    <div class="milestone-form__footer">
      <p>
        {{ 'milestones.form.last-saved-by' | translate }}
        {{ updateUser }}, {{ saveDate | date: 'medium' }}
      </p>
    </div>
  </ng-container>
</div>
