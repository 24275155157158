import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavItem } from '@main-navigation/shared/navi-item/nav-item.model';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SideNavigationService {
  private navItemSubject = new BehaviorSubject<NavItem[]>([]);
  public navItems$: Observable<NavItem[]> = this.navItemSubject.asObservable();

  private currentNavItemSubject = new BehaviorSubject<NavItem>(null);
  public currentNavItem$: Observable<NavItem> = this.currentNavItemSubject.asObservable();

  private loadingCompletedSubject = new BehaviorSubject<boolean>(false);
  public loadingCompleted$: Observable<boolean> = this.loadingCompletedSubject.asObservable();

  private currentUrlSubject = new BehaviorSubject<string | null>(null);
  currentUrl$ = this.currentUrlSubject.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrlSubject.next(router.url);
      }
    });
  }

  set navItems(items: NavItem[]) {
    this.navItemSubject.next(items);
  }

  set currentNavItem(item: NavItem) {
    this.currentNavItemSubject.next(item);
  }

  set loadCompleted(status: boolean) {
    this.loadingCompletedSubject.next(status);
  }
}
