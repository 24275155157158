<ng-container *ngIf="isHome; else NavItem">
  <div class="nav-header home bg-color--home">
    <div class="nav-header__inner">
      <div class="nav-header__title-container">
        <div class="d-flex justify-space-between nav-header__title">
          <h2>{{ 'main-navigation.welcome-back' | translate }}, {{ user.firstName }}</h2>
        </div>
        <div class="nav-header__subtitle">
          {{ 'main-navigation.platform-statement' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #NavItem>
  <ng-container *ngIf="navItem; else Home">
    <div class="nav-header bg-color--{{ navItem.type }}">
      <div class="nav-header__inner">
        <div class="nav-header__title-container">
        <div class="d-flex justify-space-between nav-header__title">
          <h2>{{ navItem.title | translate }}</h2>
        </div>
      </div>
      <div class="nav-header__tabs" *ngIf="navItem.children">
        <ul>
          <li [class.selected]="isLinkActive(child.url)" *ngFor="let child of navItem.children; let i = index">
            <ng-container *ngIf="child.url">
              <a class="child"
                [attr.data-testid]="child.testId"
                [routerLink]="[child.url]"
                [queryParams]="child.queryParams"
                title="{{child.title | translate}}"
                (click)="handleClick($event, i)"
              >
                <div class="title">
                  {{child.title | translate}}
                </div>
              </a>
            </ng-container>
          </li>
        </ul>
      </div>
      </div>
    </div>
  </ng-container>
  
</ng-template>