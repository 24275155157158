<oes-notify
  class="warning"
  [new]="true"
  [iconName]="'info'"
  [message]="'offtaker.project-set-portfolio.submit.notify-message' | translate">
</oes-notify>

<ng-container *ngIf="projectSetPortfolio?.program?.id">
  <div class="submit-container">
    <h4>{{ 'offtaker.project-set-portfolio.submit.submit' | translate }}: <strong>{{ projectSetPortfolio.program.name }}</strong></h4>
    <p>{{ 'offtaker.project-set-portfolio.submit.submit-text' | translate }}</p>
    <ng-container *ngIf="submitDisabled && (this.projectSetPortfolio.businessPlanValidationDateTime === undefined && this.rules?.businessPlanModuleRequirementFlag === 'REQUIRED')">
      <oes-notify
        class="error"
        [new]="true"
        [type]="'error'"
        [iconName]="'error'"
        [message]="'offtaker.project-set-portfolio.submit.business-plan-incomplete' | translate">
      </oes-notify>
    </ng-container>
    <div class="submit-buttons d-flex">
      <button class="btn btn-primary"
              (click)="submitProgram()"
              [disabled]="submitDisabled">
        {{ 'buttons.submit-proposal' | translate }}
      </button>
      <button class="btn btn-primary"
              (click)="withdrawProgram()"
              [disabled]="!projectSetPortfolioProgramConnection?.programShareDateTime">
        {{ 'buttons.withdraw-proposal' | translate }}
      </button>
    </div>
  </div>
</ng-container>

<div class="submit-container">
  <h4>{{ 'offtaker.project-set-portfolio.submit.submit-directly' | translate }}</h4>
  <p>{{ 'offtaker.project-set-portfolio.submit.submit-directly-text' | translate }}</p>
  <button class="btn btn-primary" (click)="shareWithAnyInvestorModal.show()">{{ 'buttons.share-with-investor' | translate }}</button>
  <div bsModal #shareWithAnyInvestorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
       [config]="{backdrop:true,ignoreBackdropClick:true,keyboard:true}"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <oes-project-set-portfolio-share-with-any-investor
      [projectSetPortfolio]="projectSetPortfolio"
      (close)="shareWithAnyInvestorModal.hide()"
    ></oes-project-set-portfolio-share-with-any-investor>
  </div>
</div>
