import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { IconCardItem } from '../../../shared/components/icon-cards/icon-card-item.model';
import { ImageCardItem } from '../../../shared/components/image-cards/image-card-item.model';

@Component({
  selector: 'oes-procurement-home',
  templateUrl: './procurement-home.component.html',
  styleUrls: ['./procurement-home.component.scss']
})
export class ProcurementHomeComponent {

  iconCardItems: IconCardItem[] = [];
  imageCardContent: ImageCardItem[] = [];

  constructor(private _translateService: TranslateService) {
    this._translateService.get('procurement.home.cards')
    .pipe(take(1))
    .subscribe(iconCards => {
      this.iconCardItems = [
        {
          iconPath: './assets/icons/line-icons/shared/orange/power.svg',
          heading: iconCards['heading-1'],
          body: iconCards['body-1'],
        },
        {
          iconPath: './assets/icons/line-icons/shared/orange/finance.svg',
          heading: iconCards['heading-2'],
          body: iconCards['body-2'],
        },
        {
          iconPath: './assets/icons/line-icons/shared/orange/pricing.svg',
          heading: iconCards['heading-3'],
          body: iconCards['body-3'],
        },
        {
          iconPath: './assets/icons/line-icons/shared/orange/supply.svg',
          heading: iconCards['heading-4'],
          body: iconCards['body-4'],
        }
      ];
    });

    this._translateService.get('procurement.home.images')
    .pipe(take(1))
    .subscribe(imageCards => {
          this.imageCardContent = [
            {
              imagePath: './assets/images/solar-components/panels.svg',
              imageWidth: 125,
              heading: imageCards['heading-1'],
            },
            {
              imagePath: './assets/images/solar-components/inverters.svg',
              imageWidth: 110,
              heading: imageCards['heading-2'],
            },
            {
              imagePath: './assets/images/solar-components/meters.svg',
              imageWidth: 120,
              heading: imageCards['heading-3'],
            },
            {
              imagePath: './assets/images/solar-components/sensors.svg',
              imageWidth: 90,
              heading: imageCards['heading-4'],
            },
          ];
    });
  }

}
