
// need to send a string
export enum DOCUMENT_TAG_TYPE {
  PROJECT = 'PROJECT',
  MILESTONE = 'MILESTONE',
  MILESTONE_NOTE = 'MILESTONE_NOTE',
  DISTRIBUTION_DESIGN = 'DISTRIBUTION_DESIGN', // project distribution design
  VENDOR_INFORMATION = 'VENDOR_INFORMATION', // manage organization > Public profile
  DEVELOPER_INFORMATION = 'DEVELOPER_INFORMATION', // manage organization > Public profile
  PROJECT_GROUP = 'PROJECT_GROUP',  // portfolio
  PROJECT_SET_PORTFOLIO = 'PROJECT_SET_PORTFOLIO',  // portfolio > c&i portfolio
  PROGRAM = 'PROGRAM',
  PROGRAM_PARTICIPANT_MESSAGES = 'PROGRAM_PARTICIPANT_MESSAGES',
  PRE_QUALIFICATION = 'PREQUALIFICATION', // program pre-qualification
  PARTICIPANT_REPORT = 'PARTICIPANT_REPORT', // participant's report for program
  PARTICIPANT_REPORT_TEMPLATE = 'PARTICIPANT_REPORT_TEMPLATE', // program owner's template for participant report
  FINANCIAL_DOCUMENT = 'FINANCIAL_DOCUMENT', // unused?
  ORGANIZATION = 'ORGANIZATION',  // unused?
  USER = 'USER',
  SHARED_BY = 'SHARED_BY',
  SHARED_WITH = 'SHARED_WITH'
}
