import {BaseModel} from '../../base.model';

export class Country extends BaseModel<Country> {
  id: string;
  name: string;
  code: string;

  constructor(countryInfo: any) {
    super();
    this.id = countryInfo.id;
    this.code = countryInfo.code;
    this.name = countryInfo.name;
  }
}
