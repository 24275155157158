export class GenerationCapacity {
  value: number;
  unit: string;
  homerExcluded: boolean;
  capex: number;

  constructor(capacity: any) {
    this.value = capacity.value;
    this.unit = capacity.unit;
    this.homerExcluded = capacity.homerExcluded;
    this.capex = capacity.capex;
  }
}
