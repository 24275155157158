import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectSetPortfolioNavigationService } from '../project-set-portfolio-navigation/project-set-portfolio-navigation.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';


@Component({
  selector: 'oes-developer-project-set-portfolio-overview',
  templateUrl: 'developer-project-set-portfolio-overview.component.html',
  styleUrls: ['./developer-project-set-portfolio-overview.component.scss']
})
export class ProjectSetPortfolioOverviewComponent implements OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  projectSetPortfolio: ProjectSetPortfolio;
  projectSets: ProjectSet[];
  readOnly: boolean;

  constructor(private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService) {
    this.subscribeProjectSetPortfolio();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  reloadPortfolio() {
    this._projectSetPortfolioNavigationService.getProjectSetPortfolio();
  }

  private subscribeProjectSetPortfolio() {
    this._projectSetPortfolioNavigationService.portfolioObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolio: ProjectSetPortfolio) => {
        this.projectSetPortfolio = projectSetPortfolio;
        this.projectSets = projectSetPortfolio.projectSets;
      });

    this._projectSetPortfolioNavigationService.portfolioReadOnlyObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((readOnly: boolean) => {
        this.readOnly = readOnly;
      });
  }


}
