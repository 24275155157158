<ng-container
  *ngIf="projectGroup?.financeIntroduction?.representationType === 'IMAGE' && projectGroup.primaryImageUri; else Map">
  <div class="image"
       [style.background-image]="'url(' + projectGroup.primaryImageUri + ')'">
  </div>
</ng-container>
<ng-template #Map>
  <google-map
    *ngIf="mapOptions?.center && markerPositions"
    height="400px"
    width="100%"
    [options]="mapOptions">
    <map-marker-clusterer
      [imagePath]="imagePath"
      [imageExtension]="'png'">
      <map-marker
        *ngFor="let markerPosition of markerPositions"
        [position]="markerPosition">
      </map-marker>
    </map-marker-clusterer>
  </google-map>
</ng-template>
