<div class="product-offering-cell">
  <!-- storage -->
  <ng-container *ngIf="name === 'storage' || vendorInformation?.offerStorage">
    <img
      title="{{'product-select.storage' | translate}}"
      src="assets/images/organization/vendor/icn-storage.svg">
  </ng-container>
  <!-- PV Inverters -->
  <ng-container *ngIf="name === 'pv-inverters' || vendorInformation?.offerPvInverters">
    <img
      title="{{'product-select.pv-inverters' | translate}}"
      src="assets/images/organization/vendor/icn-pv-inverters.svg">
  </ng-container>
  <!-- Storage Converters -->
  <ng-container *ngIf="name === 'storage-converters' || vendorInformation?.offerStorageConverters">
    <img
      title="{{'product-select.storage-converters' | translate}}"
      src="assets/images/organization/vendor/icn-storage-converters.svg">
  </ng-container>
  <!-- Controllers -->
  <ng-container *ngIf="name === 'controllers' || vendorInformation?.offerControllers">
    <img
      title="{{'product-select.controllers' | translate}}"
      src="assets/images/organization/vendor/icn-controllers.svg">
  </ng-container>
  <!-- Integrated Kits -->
  <ng-container *ngIf="name === 'integrated-kits' || vendorInformation?.offerIntegratedKits">
    <img
      title="{{'product-select.integrated-kits' | translate}}"
      src="assets/images/organization/vendor/icn-integrated-kits.svg">
  </ng-container>
  <!-- EPC services -->
  <ng-container *ngIf="name === 'epc-services' || vendorInformation?.offerEpcServices">
    <img
      title="{{'product-select.epc-services' | translate}}"
      src="assets/images/organization/vendor/icn-epc-services.svg">
  </ng-container>
  <!-- Turnkey systems -->
  <ng-container *ngIf="name === 'turnkey-systems' || vendorInformation?.offerTurnkeySystems">
    <img
      title="{{'product-select.turnkey-systems' | translate}}"
      src="assets/images/organization/vendor/icn-turnkey-systems.svg">
  </ng-container>
  <!-- PV Panel -->
  <ng-container *ngIf="name === 'pv-panels' || vendorInformation?.offerPvPanels">
    <img
      title="{{'product-select.pv-panels' | translate}}"
      src="assets/images/organization/vendor/icn-pv-panels.svg">
  </ng-container>
  <!-- Meters -->
  <ng-container *ngIf="name === 'meters' || vendorInformation?.offerMeters">
    <img
      title="{{'product-select.meters' | translate}}"
      src="assets/images/organization/vendor/icn-meters.svg">
  </ng-container>
  <!-- OM Solutions -->
  <ng-container *ngIf="name === 'om-solutions' || vendorInformation?.offerOmSolutions">
    <img
      title="{{'product-select.om-solutions' | translate}}"
      src="assets/images/organization/vendor/icn-om-solutions.svg">
  </ng-container>
  <!-- Appliance -->
  <ng-container *ngIf="name === 'appliances' || vendorInformation?.offerAppliances">
    <img
      title="{{'product-select.appliances' | translate}}"
      src="assets/images/organization/vendor/icn-appliances.svg">
  </ng-container>
  <!-- Consulting Service -->
  <ng-container *ngIf="name === 'consulting-services' || vendorInformation?.offerConsultingServices">
    <img
      title="{{'product-select.consulting-services' | translate}}"
      src="assets/images/organization/vendor/icn-consulting-services.svg">
  </ng-container>
  <!-- for filter -->
  <ng-container *ngIf="name !== ''">
    {{'product-select.' + name | translate}}
  </ng-container>
</div>
