import { environment } from '@environments/environment';

export class ManageProjectSetFinancialModelTemplatesApiConstants {
  public static userUri = environment.serverUri + `api/${ environment.apiVersion }/project-set-financial-model-templates`;

  public static findAllTemplates = () => {
    return `${ ManageProjectSetFinancialModelTemplatesApiConstants.userUri }/`;
  };

  public static uploadTemplate = () => {
    return `${ ManageProjectSetFinancialModelTemplatesApiConstants.userUri }/`;
  };


}
