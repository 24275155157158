import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { OrganizationFinancialModel } from '@project/detail/financials/organization-financial-model/organization-financial-model.model';
import { ManageOrganizationFinancialModelsApiConstants } from './manage-organization-financial-models-api.constant';

@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationFinancialModelsService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  findAllOrgModels(organizationId: string): Observable<any> {
    return this.get<OrganizationFinancialModel[]>(ManageOrganizationFinancialModelsApiConstants.findAllOrgModels(organizationId));
  }

  uploadOrgModel(organizationId: string, organizationFinancialModelId: string, file: Object): Observable<any> {
    return this.post<OrganizationFinancialModel[]>(ManageOrganizationFinancialModelsApiConstants.uploadTemplate(organizationId, organizationFinancialModelId), file);
  }

  createNewModel(organizationId: string, body: OrganizationFinancialModel) {
    return this.post<OrganizationFinancialModel>(ManageOrganizationFinancialModelsApiConstants.createModel(organizationId), body);
  }

  editModel(organizationId: string, body: OrganizationFinancialModel) {
    return this.put<OrganizationFinancialModel>(ManageOrganizationFinancialModelsApiConstants.updateModel(organizationId, body.id), body);
  }

  deleteModel(organizationId: string, organizationFinancialModelId: string) {
    return this.delete<string>(ManageOrganizationFinancialModelsApiConstants.deleteModel(organizationId, organizationFinancialModelId));
  }
}
