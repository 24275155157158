<div class="profile-container">

  <!-- Profile Headers -->
  <div class="profile-headers">
    <div class="profile-logo">
      <div class="in-between">
        <ng-container *ngIf="logoUri; else NoImage">
          <img class="img-fluid" [src]="logoUri">
        </ng-container>
        <ng-template #NoImage>
          <div class="no-image img-fluid" title="no image"><i class="fa fa-picture-o" aria-hidden="true"></i></div>
        </ng-template>
      </div>
    </div>
    <div class="ph-right">
      <div class="ph-small d-flex align-items-center">
        <div>
          <h5 class="bold mb-1">{{ organization?.name }}</h5>
          <p class="small mb-0">{{ organization?.yearsInBusiness }}
            <span *ngIf="organization?.yearsInBusiness">{{ 'vendor-profile.years-in-business' | translate }}</span>
          </p>
          <p class="small mb-0">{{ organization?.numberOfEmployees }}
            <span *ngIf="organization?.numberOfEmployees">{{ 'vendor-profile.employees' | translate }}</span>
          </p>
        </div>
      </div>
      <div class="ph-small d-flex align-items-center">
        <img class="location-image" src="/assets/images/profiles/location-grey.svg" />
        <div>
          <p class="small mb-0">{{ 'vendor-profile.headquarters-location' | translate }}</p>
          <h5 class="bold mb-1">{{ organization?.headquartersLocation }}</h5>
        </div>
      </div>
      <div class="ph-small d-flex align-items-center">
        <img src="/assets/images/profiles/location-other-grey.svg" />
        <div>
          <p class="small mb-0">{{ 'vendor-profile.other-locations' | translate }}</p>
          <h5 class="bold mb-1">{{ organization?.otherLocations }}</h5>
        </div>
      </div>
    </div>
  </div>

  <!-- Profile Main Top w/ Product Offering -->
  <div class="profile-main">
    <div class="pm-left pm-left-top">
      <h5 class="bold mb-2">{{ 'vendor-profile.product-offering' | translate }}</h5>
      <oes-product-select [product]="vendorInformation" [readonly]="true"></oes-product-select>
    </div>

    <div class="pm-right">
      <h5 class="bold mb-2">{{ 'vendor-profile.company-overview' | translate }}</h5>
      <p class="mb-4" [innerHtml]="organization?.companyOverview | safeHtml"></p>
    </div>
  </div>

  <!-- Profile Main Bottom w/ Map -->
  <div class="profile-main">
    <div class="pm-left">
      <h5 class="bold mb-2">{{ 'vendor-profile.markets.' + activeCountryTab | translate }}</h5>
      <div class="left-1">
        <oes-countries-map>
        </oes-countries-map>
        <div *ngIf="allAfrica && countries && countries.length > 0" class="countries-list-africa" >
          <div *ngFor="let country of countries" class="small">
            {{ country }}
          </div>
        </div>
      </div>
      <div class="left-2 d-flex justify-content-between">
        <div *ngFor="let tab of allCountryTabs;" class="d-flex align-items-center" >
          <input type="radio"
                 [name]="tab"
                 [value]="tab"
                 [(ngModel)]="activeCountryTab"
                 [id]="tab"
                 (change)="updateCountryTab(tab)">
          <label [for]="tab" class="input-control--sm input-control--radio ml-1 pl-0 country-radio-buttons">{{ 'vendor-profile.markets.' + tab | translate }}</label>
        </div>
      </div>
    </div>

    <div class="pm-right">
      <h5 class="bold mb-2">{{ 'vendor-profile.support-services' | translate }}</h5>
      <p class="mb-4" [innerHtml]="vendorInformation?.productOffering | safeHtml"></p>
      <h5 class="bold mb-2">{{ 'vendor-profile.total-products-deployed' | translate }}</h5>
      <p class="mb-4">{{ vendorInformation?.totalProductsDeployed }}</p>
    </div>
  </div>

  <!-- Profile Data Room -->
  <div class="profile-files">
    <p class="bold mb-0">{{ 'vendor-marketplace.organization-profile.developer-marketing-files' | translate }}</p>
    <oes-files
      *ngIf="tagTypes"
      [storageKey]="'venderProfile'"
      [tagTypes]="tagTypes"
      [autoHeightOverride]="true"
      [accessControl]="accessControl"
      [anotherOrganizationId]="organization?.id">
    </oes-files>
  </div>

</div>
