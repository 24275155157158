<div
  bsModal
  #modalTemplate="bs-modal"
  class="unsaved-changes-dialog"
  [config]="config">
  <div class="modal-header d-flex flex-row justify-content-between">
    <h4 class="modal-title">{{'project-group.manage-tariffs' | translate}}</h4>
    <button
      type="button"
      class="close"
      (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <oes-revenue-assumptions-editor></oes-revenue-assumptions-editor>
  </div>

  <div class="modal-footer d-flex flex-row justify-content-end">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()">
      {{'buttons.apply' | translate}}
    </button>
  </div>
</div>
