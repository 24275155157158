<div class="profile-container">

  <!-- Profile Headers -->
  <div class="profile-headers">
    <div class="profile-logo">
      <div class="in-between">
        <ng-container *ngIf="logoUri; else NoImage">
          <img class="img-fluid" [src]="logoUri">
        </ng-container>
        <ng-template #NoImage>
          <div class="no-image img-fluid" title="no image"><i class="fa fa-picture-o" aria-hidden="true"></i></div>
        </ng-template>
      </div>
    </div>
    <div class="ph-right">
      <div class="ph-small d-flex align-items-center">
        <div>
          <h5 class="bold mb-1">{{ organization?.name }}</h5>
          <p class="small mb-0">{{ organization?.yearsInBusiness }}
            <span *ngIf="organization?.yearsInBusiness">{{ 'investor-profile.years-in-business' | translate }}</span>
          </p>
          <p class="small mb-0">{{ organization?.numberOfEmployees }}
            <span *ngIf="organization?.numberOfEmployees">{{ 'investor-profile.employees'|translate }}</span>
          </p>
        </div>
      </div>
      <div class="ph-small d-flex align-items-center">
        <img class="location-image" src="/assets/images/profiles/location-grey.svg" />
        <div>
          <p class="small mb-0">{{ 'investor-profile.headquarters-location' | translate }}</p>
          <h5 class="bold mb-1">{{ organization?.headquartersLocation }}</h5>
        </div>
      </div>
      <div class="ph-small d-flex align-items-center">
        <img src="/assets/images/profiles/location-other-grey.svg" />
        <div>
          <p class="small mb-0">{{ 'investor-profile.other-locations' | translate }}</p>
          <h5 class="bold mb-1">{{ organization?.otherLocations }}</h5>
        </div>
      </div>
    </div>
  </div>

  <!-- Profile Main -->
  <div class="profile-main">
    <div class="pm-left">
      <div class="pm-left-metrics pm-left-metrics-top">
        <p class="bold mb-2">{{ 'vendor-marketplace.organization-profile.developed-microgrids' | translate }}</p>
        <div class="small mb-0">{{ 'vendor-marketplace.organization-profile.developed-microgrids-status.' + developerInfo?.microGridStatus | translate }}</div>
      </div>
      <div class="pm-left-metrics">
        <p class="bold mb-2">{{ 'vendor-marketplace.organization-profile.organization-video' | translate }}</p>
        <div class="small mb-0">{{ developerInfo?.videoLinkDescription }}</div>
        <div class="mb-0">
          <a target="_blank" [href]="developerInfo?.videoLink">{{ developerInfo?.videoLink }}</a>
        </div>
      </div>
    </div>

    <div class="pm-right">
      <h5 class="bold mb-2">{{ 'investor-profile.company-overview' | translate }}</h5>
      <p class="mb-4" [innerHtml]="organization?.companyOverview | safeHtml"></p>
      <h5 class="bold mb-2">{{ 'vendor-marketplace.organization-profile.pictures' | translate }}</h5>
      <div class="d-flex justify-content-between image-box">
        <div class="col-3" *ngFor="let picUri of pictureUris">
          <img [src]="picUri" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>

  <!-- Profile Data Room -->
  <div class="profile-files">
    <p class="bold mb-0">{{ 'vendor-marketplace.organization-profile.developer-marketing-files' | translate }}</p>
    <oes-files
      *ngIf="tagTypes"
      [storageKey]="'developerProfile'"
      [tagTypes]="tagTypes"
      [autoHeightOverride]="true"
      [accessControl]="accessControl"
      [anotherOrganizationId]="organization?.id">
    </oes-files>
  </div>

</div>
