import { indexOf } from 'lodash';
import NativePromise from 'native-promise-only';

const url = (formio) => {
  const xhrRequest = (urlInScope, name, query, data, options, progressCallback, abortCallback) => {
    return new NativePromise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const json = (typeof data === 'string');
      const fd = new FormData();

      if (typeof progressCallback === 'function') {
        xhr.upload.onprogress = progressCallback;
      }

      if (typeof abortCallback === 'function') {
        abortCallback(() => xhr.abort());
      }

      if (!json) {
        // eslint-disable-next-line guard-for-in
        for (const key in data) {
          fd.append(key, data[key]);
        }
      }

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          // Need to test if xhr.response is decoded or not.
          let respData = {};
          try {
            respData = (typeof xhr.response === 'string') ? JSON.parse(xhr.response) : {};
            respData = (respData && respData['data']) ? respData['data'] : respData;
          }
          catch (err) {
            respData = {};
          }

          // Get the url of the file.
          let respUrl = respData.hasOwnProperty('url') ? respData['url'] : `${xhr.responseURL}/${name}`;

          // If they provide relative url, then prepend the url.
          if (respUrl && respUrl[0] === '/') {
            respUrl = `${urlInScope}${respUrl}`;
          }
          resolve({ url: respUrl, data: respData });
        }
        else {
          reject(xhr.response || 'Unable to upload file');
        }
      };

      xhr.onerror = () => reject(xhr);
      xhr.onabort = () => reject(xhr);

      let requestUrl = urlInScope + (indexOf('?') > -1 ? '&' : '?');
      // eslint-disable-next-line guard-for-in
      for (const key in query) {
        requestUrl += `${key}=${query[key]}&`;
      }
      if (requestUrl[requestUrl.length - 1] === '&') {
        requestUrl = requestUrl.substr(0, requestUrl.length - 1);
      }

      xhr.open('POST', requestUrl);
      if (json) {
        xhr.setRequestHeader('Content-Type', 'application/json');
      }

      const token = sessionStorage.getItem('access_token');

      if (token) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      }

      //Overrides previous request props
      if (options) {
        const parsedOptions = typeof options === 'string' ? JSON.parse(options) : options;
        // eslint-disable-next-line guard-for-in
        for (const prop in parsedOptions) {
          xhr[prop] = parsedOptions[prop];
        }
      }
      xhr.send(json ? data : fd);
    });
  };

  return {
    title: 'Url',
    name: 'url',
    uploadFile(file, name, dir, progressCallback, urlInScope, options, fileKey, groupPermissions, groupId, abortCallback) {
      const uploadRequest = function(form) {
        return xhrRequest(urlInScope, name, {
        }, {
          [fileKey]:file,
          name,
          dir
        }, options, progressCallback, abortCallback).then(response => {
          response.data = response.data || {};
          response.data.baseUrl = formio.projectUrl;
          response.data.project = form ? form.project : '';
          response.data.form = form ? form._id : '';
          return {
            storage: 'url',
            name: response.name,
            url: response.url,
            size: file.size,
            type: file.type,
            data: response.data
          };
        });
      };
      if (file.private && formio.formId) {
        return formio.loadForm().then((form) => uploadRequest(form));
      }
      else {
        return uploadRequest({});
      }
    },
    deleteFile(fileInfo) {
      // Handled in File Uploader Component
      // *************************************

      // return new NativePromise((resolve, reject) => {
      //   const xhr = new XMLHttpRequest();
      //   xhr.open('DELETE', fileInfo.url, true);
      //   xhr.onload = () => {
      //     if (xhr.status >= 200 && xhr.status < 300) {
      //       resolve('File deleted');
      //     }
      //     else {
      //       reject(xhr.response || 'Unable to delete file');
      //     }
      //   };
      //   xhr.send(null);
      // });
    },

    downloadFile(file) {
      // Handled in File Uploader Component
      // *************************************

      // if (file.private) {
      //   if (formio.submissionId && file.data) {
      //     file.data.submission = formio.submissionId;
      //   }
      //   return xhrRequest(file.url, file.name, {}, JSON.stringify(file), null, null, null).then(response => response.data);
      // }

      // // Return the original as there is nothing to do.
      // return NativePromise.resolve(file);
    }
  };
};

url.title = 'Url';
export default url;
