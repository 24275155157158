<div class="bos">
  <!-- title for Summary page -->
  <ng-container *ngIf="generationDesign">
    <h3>{{ 'generation-design.component.BOS' | translate }}</h3>
  </ng-container>
  <oes-notify
    *ngIf="!readOnly"
    [iconName]="'fa-info'"
    [message]="'generation-design-item.bos.what-to-add-tooltip' | translate"
    [type]="'hint'">
  </oes-notify>
  <oes-grid-action-button
    *ngIf="!readOnly"
    [buttons]="buttons"
    (clicked)="clicked($event)">
  </oes-grid-action-button>
  <oes-grid-sub-action
    *ngIf="!readOnly && gridApi"
    [gridState]="gridState"
    [defaultGridState]="defaultGridState"
    [reload]="false"
    (action)="subAction($event)">
  </oes-grid-sub-action>
  <div oesAgHorizontalScroll>
    <ag-grid-angular
      *ngIf="optionReady"
      style="width: auto;"
      class="ag-theme-alpine"
      [overlayNoRowsTemplate]="'distribution-design.ag-grid-empty-state' | translate"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="selectionChanged($event)"
      (cellEditingStarted)="cellEditingStarted($event)"
      (cellEditingStopped)="cellEditingStopped($event)"
      (rowValueChanged)="rowValueChanged($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
  <div class="bos-footer">
    <div class="total">
      <div>{{ 'generation-design-item.total-cost' | translate }}: {{ totalBosCost |  currency: currency : symbol : '1.2-2' }}</div>
    </div>
  </div>
</div>
