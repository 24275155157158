import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridStateComparatorService {

  constructor() { }

  // return: true - will save, false - won't save
  public compareSort(currentSort: Array<any>, latestSort: Array<any>): boolean {
    // treat undefined and empty array [] is equal
    if (!currentSort && !latestSort ||
        !currentSort && latestSort?.length === 0 ||
        currentSort?.length === 0 && !latestSort ||
        currentSort?.length === 0 && latestSort?.length === 0) {
      return false;
    }
    if (currentSort?.length > 0 && latestSort?.length > 0) {
      return !currentSort.every((item, index) => {
        return item.colId === latestSort[index].colId &&
               item.sort === latestSort[index].sort;
      });
    }
    return true;
  }

  /*
   * return: true - will save, false - won't save
   */
  public compareFilter(currentFilter: Object, latestFilter: Object): boolean {
    return JSON.stringify(currentFilter) !== JSON.stringify(latestFilter);
  }

  // return: true - will save, false - won't save
  public compareColumn(currentColumn: Array<any>, latestColumn: Array<any>): boolean {
    // treat undefined and empty array [] is equal
    if (!currentColumn && !latestColumn ||
        !currentColumn && latestColumn?.length === 0 ||
        currentColumn?.length === 0 && !latestColumn ||
        currentColumn?.length === 0 && latestColumn?.length === 0) {
      return false;
    }
    if (currentColumn?.length > 0 && latestColumn?.length > 0) {
      return !currentColumn?.every((item, index) => {
        // don't check "item.width" because sizeColumnsToFit changes width
        return (item.pinned === latestColumn[index]?.pinned &&
                item.aggFunc === latestColumn[index]?.aggFunc &&
                item.pivotIndex === latestColumn[index]?.pivotIndex &&
                item.rowGroupIndex === latestColumn[index]?.rowGroupIndex &&
                item.colId === latestColumn[index]?.colId &&
                item.hide === latestColumn[index]?.hide);
      });
    }
    return true;
  }
}
