<div [dragula]="dragDropId">
  <div class="system-component"
    [id]="componentType"
    [ngClass]="{'inUse': inUse, 'read-only': !draggable, 'load': componentType === type.LOAD}">
    <div class="icon">
      <img src="{{icon}}">
    </div>
    <div class="title-info">
      <div class="title">
        {{'generation-design.component.' + componentType | translate}}
      </div>
      <div class="info">
        <ng-container [ngSwitch]="componentType">
          <ng-container *ngSwitchCase="type.GRID">
            <div
              class="capacity"
              *ngIf="extra?.buyPrice">
              Buy: {{extra?.buyPrice | currency : currency : symbol : '1.0-2'}}
            </div>
            <div
              class="capacity"
              *ngIf="extra?.sellPrice">
              Sell: {{extra?.sellPrice | currency : currency : symbol : '1.0-2'}}
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="type.LOAD">
            <div
              class="capacity"
              *ngIf="extra?.dailyAverageLoad">
              Avg: {{extra?.dailyAverageLoad | number:'1.0-2'}} kWh/day
            </div>
            <div
              class="capacity"
              *ngIf="extra?.peakKw">
              Peak: {{extra?.peakKw | number:'1.0-2'}} kWh
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="capacity" *ngIf="capacity && capacity.value >= 0">
              Capacity: {{capacity.value | number:'1.0-2'}} {{'units.' + capacity?.unit?.toLowerCase() | translate}}
            </div>
            <div class="capacity" *ngIf="capacity && capacity.capex >= 0">
              Cost: {{capacity.capex | currency : currency : symbol : '1.0-2'}}
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="dots">
      <svg width="8px" height="13px" viewBox="0 0 8 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title></title>
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g id="{{componentType}}" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-551.000000, -674.000000)">
              <g transform="translate(405.000000, 650.000000)" fill="#C9C9C9">
                  <g transform="translate(146.000000, 24.000000)">
                      <rect id="Rectangle-9-Copy-3" x="5" y="0" width="3" height="3"></rect>
                      <rect id="Rectangle-9-Copy-3" x="5" y="5" width="3" height="3"></rect>
                      <rect id="Rectangle-9-Copy-3" x="5" y="10" width="3" height="3"></rect>
                      <rect id="Rectangle-9-Copy-3" x="0" y="0" width="3" height="3"></rect>
                      <rect id="Rectangle-9-Copy-3" x="0" y="5" width="3" height="3"></rect>
                      <rect id="Rectangle-9-Copy-3" x="0" y="10" width="3" height="3"></rect>
                  </g>
              </g>
          </g>
      </svg>
    </div>
  </div>
</div>