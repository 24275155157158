import {Injectable, OnDestroy} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FinanceConnection } from './finance-connection.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceConnectionService extends BaseRestApiService implements OnDestroy {
  public projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;
  private _currentLevels: string[];
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  set currentLevels(financeConnection: string[]) {
    this._currentLevels = financeConnection;
  }

  public levelOpenStatus(pageName: string): boolean {
    return this._currentLevels && this._currentLevels.includes(pageName);
  }

  public list(projectGroupId: string): Observable<FinanceConnection[]> {
    return this.get<FinanceConnection[]>(`${this.projectGroups}/${projectGroupId}/finance/connections`, {params: {page: 0, size: 1000}})
            .pipe(
              map(results => results.map(result => new FinanceConnection(result)))
            );
  }

  public getFinanceConnection(projectGroupId: string, financeConnectionId: string): Observable<FinanceConnection> {
    return this.get<FinanceConnection>(`${this.projectGroups}/${projectGroupId}/finance/connections/${financeConnectionId}`)
            .pipe(
              map(result => new FinanceConnection(result))
            );
  }

  public submitFinanceConnection(projectGroupId: string, financeConnection: FinanceConnection): Observable<FinanceConnection> {
    return this.post<any>(`${this.projectGroups}/${projectGroupId}/finance/connections`, JSON.stringify(financeConnection))
            .pipe(
              map(result => new FinanceConnection(result))
            );
  }

  updateFinanceConnection(projectGroupId) {
    this
    .hasFinanceConnection(projectGroupId)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((levels: string[]) => {
      this.currentLevels = levels;
    });
  }

  hasFinanceConnection(projectGroupId): Observable<string[]> {
    return this.list(projectGroupId)
    .pipe(
      map((financeConnections: FinanceConnection[]) => {
        let levels: string[] = this.getLevels();
        const activeLevels: string[] = [];
        if (financeConnections?.length) {
          financeConnections.forEach(financeConnection => {
            levels.forEach((level: string) => {
              if (financeConnection[level]) {
                activeLevels.push(level);
              }
            });
            // remove activeLevels from levels array to prevent checking (and pushing) levels that
            // have been identified as active on additional connections
            activeLevels.forEach((activeLevel: string) => {
              levels = levels.filter(level => level !== activeLevel);
            });
          });
        }
        // Returns levels that have been released to investor
        return activeLevels;
      })
    );
  }

  private getLevels() {
    const financeConnection = new FinanceConnection({});
    const regex = new RegExp('level[1-9](\\d+)?DateTime');
    const properties = Object.getOwnPropertyNames(financeConnection);
    const levels = properties.filter((property: string) => regex.test(property));
    return levels;
  }
}
