import { Component } from '@angular/core';
import { ROLE_TYPE } from '@user/role-type';
import { UserService } from '@user/user.service';

@Component({
  selector: 'oes-manage-c-and-i',
  templateUrl: 'manage-c&i.component.html',
  styleUrls: ['manage-c&i.component.scss']
})

export class ManageCAndIComponent {
  isSysAdmin: boolean = false;

  constructor(private _userService: UserService) {
    this.isSysAdmin = this._userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN);
  }
}
