import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject, take, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { ModalDialogComponent } from "../../../../shared/components/modal-dialog/modal-dialog.component";
import { OfftakerFormComponent } from "../../../offtakers/form/offtaker-form.component";

import { UserService } from "@user/user.service";

import { Project } from '@project/shared/project.model';
import { Offtaker, ProjectOfftaker } from "../../../offtakers/offtaker.model";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ProjectSet } from "../../../offtakers/project-set/project-set.model";
import { Organization } from "../../../../shared/models/organization/organization.model";
import { OfftakerService } from "../../../offtakers/offtaker.service";
import { EventService } from "../../../../shared/event.service";
import { User } from "@user/user.model";



@Component({
  selector: 'oes-assign-project-offtaker',
  templateUrl: './assign-project-offtaker.component.html',
  styleUrls: ['./assign-project-offtaker.component.scss']
})
export class AssignProjectOfftakerComponent implements OnInit, OnDestroy {
  @ViewChild('assignOfftakerModal', {static: false}) assignOfftakerModal: ModalDialogComponent;
  @ViewChild('offtakerForm', {static: false}) offtakerForm: OfftakerFormComponent;

  @Input() project: Project;

  canAssign: boolean;
  currentOfftaker: Offtaker;
  formGroup: UntypedFormGroup;
  offtakers: Offtaker[] = [];
  projectSet: ProjectSet;
  selectedOfftaker: Offtaker;
  showDropdownError = false;

  organization: Organization;

  private message = {};
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _offtakerService: OfftakerService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _userService: UserService) { }

  ngOnInit() {
    this._userService.getCurrentUser().subscribe((user: User) => {
      this.organization = user.organization;
      if (this.project.organization.id === this.organization.id) {
        this.canAssign = true;
      } else {
        this.canAssign = false;
      }
    });
    this.getOfftakers();
    this.message = this._translateService.instant('project.overview.message');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  newOfftakerClick() {
    this.offtakerForm.openModal('create');
  }

  getFilteredOfftakers(searchText: string) {
    this.getOfftakers(searchText);
  }

  refreshOfftakers(newOfftaker: Offtaker) {
    this.getOfftakers(undefined, newOfftaker);
  }

  getOfftakers(searchText: string = '', newOfftaker?: Offtaker) {
    this._offtakerService.listByOrgId(this.organization.id, searchText)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((offtakers: Offtaker[]) => {
      this.offtakers = offtakers.map(ot => new Offtaker(ot));
      for (const offtaker of this.offtakers) {
        const projectOfftakerFound: ProjectOfftaker | undefined = offtaker.projectOfftakers.find(ot => ot.project.id === this.project.id);
        if (projectOfftakerFound) {
          this.currentOfftaker = offtaker;
          this.selectedOfftaker = offtaker;
        }
        if (newOfftaker) {
          this.selectedOfftaker = newOfftaker;
          this.formGroup.patchValue({
            selectOption: [newOfftaker.id]
          });
        }
      }
      this.buildForm();
    });
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup({
      selectOption: new UntypedFormControl([this.selectedOfftaker?.id]),
    });
    this.subscribeForm();
  }

  subscribeForm() {
    this.formGroup.valueChanges.subscribe((values: any) => {
      if (values.selectOption) {
        const selectedFound = this.offtakers.find(o => o.id === values.selectOption[0]);
        if (selectedFound) {
          this.selectedOfftaker = selectedFound;
        }
      }
    });
  }

  assignOfftaker() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid || !this.selectedOfftaker) {
      this.showDropdownError = true;
      return;
    }
    this.showDropdownError = false;
    this._offtakerService.createProjectOfftaker(this.selectedOfftaker.id, this.project.id)
    .pipe(take(1))
    .subscribe((offtaker: Offtaker) => {
      this.selectedOfftaker = offtaker;
      this.currentOfftaker = offtaker;
      this._eventService.success(this.message['offtaker-assign-success']);
      this.closeAssignOfftakerModal();
    });
  }

  openAssignOfftakerModal() {
    this.assignOfftakerModal.show();
  }

  closeAssignOfftakerModal() {
    this.assignOfftakerModal.hide();
  }
}
