<!-- use #modalTemplate="bs-modal" format, the modal doens't go global -->
<div bsModal #modalTemplate="bs-modal" class="modal fade">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{'unsaved-data-modal.title' | translate}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p>{{'unsaved-data-modal.body-1' | translate}}</p>
        <p>{{'unsaved-data-modal.body-2' | translate}}</p>
        <p *ngIf="extraText" [innerHtml]="extraText"></p>
      </div>

      <div class="modal-footer d-flex flex-column">
        <div class="button-group d-flex flex-row justify-content-end">
          <button *ngIf="noSaveButton"
                  type="button"
                  class="btn btn-small btn-primary pull-right"
                  (click)="hide()"
                  aria-label="Cancel">
            {{'unsaved-data-modal.continue' | translate}}
          </button>
          <button *ngIf="!noDiscardButton"
                  type="button"
                  class="btn btn-small btn-secondary pull-right mr-2"
                  (click)="discardChanges()"
                  aria-label="Discard">
            {{'unsaved-data-modal.discard-changes' | translate}}
          </button>
          <button *ngIf="!noSaveButton"
                  type="button"
                  class="btn btn-small btn-primary pull-right"
                  (click)="saveData()"
                  aria-label="Confirm">
            {{'unsaved-data-modal.save-data' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
