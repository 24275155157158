<form [formGroup]="formGroup">
  <div class="select-wrapper">
    <select
      formControlName="project"
      class="form-control"
      (change)="onProjectChange($event)">
      <option *ngFor="let project of projects"
              [value]="project.id"
              title="{{project.name}}">{{project.displayName}}
      </option>
    </select>
  </div>
</form>
