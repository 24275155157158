import { environment } from '@environments/environment';

export class EvaluationConfigApiConstants {
  public static evaluationConfigs = environment.evalServerUri + `api/${environment.apiVersion}/evaluation-configs`;

  public static evaluation = {
    detail: (evaluationConfig: string) => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}/${evaluationConfig}`;
    },
    update: (evaluationConfig: string) => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}/${evaluationConfig}`;
    },
    delete: (evaluationConfig: string) => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}/${evaluationConfig}`;
    },
    create: () => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}`;
    },
    find: () => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}`;
    },
    publish: () => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}/publish`;
    },
    unpublish: () => {
      return `${EvaluationConfigApiConstants.evaluationConfigs}/unpublish`;
    }
  };
}
