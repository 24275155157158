import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectSetPortfolioService } from '@project/offtakers/project-set-portfolio/project-set-portfolio.service';
import { UrlCheckService } from '@main-navigation/shared/url-check.service';
import { ProjectSetPortfolioConnectionService } from '../developer-project-set-portfolio-connection.service';
import { ProjectSetPortfolioConnection } from '../developer-project-set-portfolio-connection.model';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';
import { User } from '@user/user.model';
import { ProgramService } from '@program/shared/program.service';
import { PROGRAM_RULE_TYPES } from '@program/shared/program-rule-types.enum';
import { ProgramPortfolioRules } from '@program/shared/program-project-rules.model.1';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetPortfolioNavigationService implements OnDestroy {

  projectSetPortfolio: ProjectSetPortfolio = undefined;
  projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection = undefined;
  readOnly: boolean = true;

  private ngUnsubscribe: Subject<any> = new Subject();
  private projectSetPortfolioId: string;

  private portfolioSource = new BehaviorSubject<ProjectSetPortfolio>(this.projectSetPortfolio);
  portfolioObservable = this.portfolioSource.asObservable();

  private portfolioProgramConnectionSource = new BehaviorSubject<ProjectSetPortfolioConnection>(this.projectSetPortfolioProgramConnection);
  portfolioProgramConnectionObservable = this.portfolioProgramConnectionSource.asObservable();

  private portfolioReadOnlySource = new BehaviorSubject<boolean>(this.readOnly);
  portfolioReadOnlyObservable = this.portfolioReadOnlySource.asObservable();

  private portfolioRuleSource = new BehaviorSubject<ProgramPortfolioRules>(null);
  portfolioRuleObservable = this.portfolioRuleSource.asObservable();

  private user: User;
  private userRole: ROLE_TYPE;

  constructor(private _projectSetPortfolioService: ProjectSetPortfolioService,
              private _userService: UserService,
              private _programService: ProgramService,
              private _projectSetPortfolioConnectionService: ProjectSetPortfolioConnectionService,
              private _urlCheckService: UrlCheckService) {
    this.findCurrentUserRole();
    this.getPortfolioIdFromRoute();
    this.getProjectSetPortfolio();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private findCurrentUserRole() {
    this._userService.getCurrentUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.userRole = this._userService.getSelectedRole();
  }

  getProjectSetPortfolio() {
    forkJoin([ this._projectSetPortfolioService.detail(this.projectSetPortfolioId),
               this._projectSetPortfolioConnectionService.list(this.projectSetPortfolioId) ])
      .pipe(take(1))
      .subscribe((responses: any) => {
        const portfolio: ProjectSetPortfolio = responses[0];
        this.projectSetPortfolio = portfolio;
        this.portfolioSource.next(this.projectSetPortfolio);

        const connections: ProjectSetPortfolioConnection[] = responses[1];
        this.projectSetPortfolioProgramConnection = connections.find(connection => connection?.financeOrganization?.id === portfolio?.program?.sponsor?.id);
        this.portfolioProgramConnectionSource.next(this.projectSetPortfolioProgramConnection);

        this.readOnly = this.user.organization.id !== this.projectSetPortfolio.ownerOrganization.id;
        this.portfolioReadOnlySource.next(this.readOnly);

        this.getProgramRule();
      });
  }

  private getProgramRule() {
    if (this.projectSetPortfolio?.program?.id) {
      this._programService.getProgramRules(this.projectSetPortfolio.program.id, PROGRAM_RULE_TYPES.PORTFOLIO_RULE)
        .pipe(take(1))
        .subscribe((rules: ProgramPortfolioRules) => {
          this.portfolioRuleSource.next(rules);
        });
    }
  }

  private getPortfolioIdFromRoute() {
    switch (this.userRole) {
      case ROLE_TYPE.DEVELOPER_USER:
        this.projectSetPortfolioId = this._urlCheckService.getCiPortfolioId(this._urlCheckService.detectUrl());
        break;
      case ROLE_TYPE.FINANCE_USER:
        this.projectSetPortfolioId = this._urlCheckService.getFinanceCiPortfolioId(this._urlCheckService.detectUrl());
        break;
      default:
        throw new Error('Only Developers and Investors can access C&I Portfolio Area');
    }
  }
}
