import { Component, Input, Output, EventEmitter } from '@angular/core';
import {View} from '@shared/components/view-toggle-button/view.enum';
import { ProjectListType } from '../project-list-type.enum';
import { ProjectType } from '@project/shared/project-type.enum';
import { Router } from '@angular/router';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';

@Component({
  selector: 'oes-shared-project-action',
  templateUrl: './shared-project-action.component.html',
  styleUrls: ['./shared-project-action.component.scss']
})
export class SharedProjectActionComponent {
  @Input() selectedProjects = [];
  @Input() listType: ProjectListType = ProjectListType.MAIN;
  @Output() exportCsv: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeStageBulk: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() installSampleProject: EventEmitter<boolean> = new EventEmitter<boolean>();
  projectListType = ProjectListType;
  projectType = ProjectType;
  view = View;

  constructor(private _router: Router, private _userService: UserService) {
  }

  public newProject(projectType: ProjectType) {
    this._router.navigate(['/oes/projects/create'], {queryParams: {projectType: projectType}});
  }

  isAdminUser() {
    return this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
  }

  emitInstallSampleProject() {
    this.installSampleProject.emit(true);
  }
}
