import { EventService } from '@shared/event.service';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { ModulePermissions } from '@project/shared/module-permissions.model';
import { Observable, of } from 'rxjs';
import { Project } from '@project/shared/project.model';
import { ProjectService } from '@project/shared/project.service';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash-es';

@Injectable()
export class ProjectRoutePermissionsGuard  {
  private project: Project;

  constructor(private _projectService: ProjectService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const id = this.uuid(state.url);
    const path = next.routeConfig.path;
    return this.getProjectPermissions(id[0], path, state);
  }

  private uuid(baseUrl: string): any {
    return baseUrl.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/);
  }

  private getProjectPermissions(projectId: string, path: string, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.project || projectId !== this.project.id) {
      return this._projectService.detail(projectId)
      .pipe(take(1))
      .pipe(map((project: Project) => {
        this.project = project;
        return this.parsePermissions(path, state);
      }));
    } else {
      return of(this.parsePermissions(path, state));
    }
  }

  private parsePermissions(path: string, state: RouterStateSnapshot): boolean {
    const application = this.project?.program?.projectApplicationFormRequired;
    const modulePermissions: ModulePermissions = this.project?.projectPermissions?.project?.modulePermissions;
    const permissionsGranted = modulePermissions[camelCase(path)]?.view;
    if (!permissionsGranted) {
      this.determineRedirect(modulePermissions, path, state, application);
    }
    return permissionsGranted;
  }

  private determineRedirect(modulePermissions: ModulePermissions, path: string, state: RouterStateSnapshot, application: boolean) {
    const category = this.getCategoryFromPath(path);
    const preferredRedirect = this.getPreferredRedirect(category, modulePermissions);
    if (preferredRedirect) {
      const url = state.url.replace(/(planning\/)/g, '');
      const fullRedirectUrl = url.replace(path, this.pathProtectedCamelToKebabCase(preferredRedirect));
      this._router.navigateByUrl(fullRedirectUrl);
    } else {
      this._eventService.error(this._translateService.instant('main-navigation.error.can-not-redirect'));
    }
  }

  private getPreferredRedirect(firstCategory: 'planning' | 'general', modulePermissions: ModulePermissions) {
    const planningPriorityOrder = [
      'information',
      'summary',
      'financial',
      'costs',
      'distributionDesign',
      'generationDesign',
      'load',
      'revenue',
    ];

    const generalPriorityOrder = [
      'overview',
      'messages',
      'milestones',
      'connections',
    ];

    let preferredRedirect;

    switch (firstCategory) {
      case 'planning':
        preferredRedirect = planningPriorityOrder.find(module => modulePermissions[module]?.view);
        if (preferredRedirect) {
          preferredRedirect = 'planning/' + preferredRedirect;
        } else {
          preferredRedirect = generalPriorityOrder.find(module => modulePermissions[module]?.view);
        }
        break;
      case 'general':
        preferredRedirect = generalPriorityOrder.find(module => modulePermissions[module]?.view);
        if (!preferredRedirect) {
          preferredRedirect = planningPriorityOrder.find(module => modulePermissions[module]?.view);
          preferredRedirect = 'planning/' + preferredRedirect;
        }
        break;
      default:
        console.error('Incorrect project category type: ', firstCategory);
        break;
    }

    return preferredRedirect;
  }

  private getCategoryFromPath(path: string): 'planning' | 'general' {
    let category;
    switch (path) {
      case 'information':
      case 'financial':
      case 'costs':
      case 'distribution-design':
      case 'generation-design':
      case 'load':
      case 'revenue':
      case 'summary':
        category = 'planning';
        break;
      default:
        category = 'general';
        break;
    }
    return category;
  }

  private pathProtectedCamelToKebabCase(str: string): string {
    return str.replace(/([^\/])([A-Z])/g, (match, prev, upper) => `${prev}-${upper.toLowerCase()}`);
  }
}
