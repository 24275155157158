import { Component, Input } from '@angular/core';
import { ChartColors } from './chart-colors.enum';
import { OesChart } from './oes-chart.model';

@Component({
  selector: 'oes-chart',
  templateUrl: 'oes-chart.component.html',
  styleUrls: ['./oes-chart.component.scss']
})
export class OesChartComponent {
  @Input() chart: OesChart;
  @Input() chartColors: string[] = Object.keys(ChartColors);
  @Input() currency: string;

  constructor() {
  }
}
