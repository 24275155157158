<div class="">
  <p class="bold mt-1 mb-5 alert">{{'document-library.note' | translate}}</p>

  <ng-container *ngIf="projectSetPortfolio?.ownerOrganization?.id === currentOrgId; else AnotherOrg">
    <oes-files
      *ngIf="tagTypes"
      [tagTypes]="tagTypes"
      [autoHeightOverride]="true"
      [storageKey]="'developerProjectSetPortfolioDataRoom'"
      [printable]="false"
      [accessControl]="accessControl">
    </oes-files>
  </ng-container>
  <ng-template #AnotherOrg>
    <oes-files
      *ngIf="tagTypes"
      [tagTypes]="tagTypes"
      [autoHeightOverride]="true"
      [storageKey]="'developerProjectSetPortfolioDataRoom'"
      [printable]="false"
      [anotherOrganizationId]="projectSetPortfolio?.ownerOrganization?.id"
      [accessControl]="accessControl">
    </oes-files>
  </ng-template>
</div>
