import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { GridState } from "@shared/ag-grid/gird-state";
import { ColumnApi, GridApi, GridOptions, ISimpleFilterModel } from "ag-grid-community";
import { InstallerService } from "../installer.service";
import { InstallerListGridSettingService } from "./installer-list-grid-setting.service";
import { User } from "@user/user.model";
import { UserService } from "@user/user.service";
import { Installer } from "../installer.model";
import { Organization } from "../../../shared/models/organization/organization.model";
import { InstallerFormComponent } from "../form/installer-form.component";
import { GridSubAction } from "@shared/ag-grid/component/sub-action/sub-action.enum";

@Component({
  selector: 'oes-installer-list',
  templateUrl: './installer-list.component.html',
  styleUrls: ['./installer-list.component.scss']
})
export class InstallerListComponent implements OnInit, OnDestroy {
  @ViewChild('installerForm', {static: false}) installerForm: InstallerFormComponent;

  columnApi: ColumnApi;
  defaultGridState: GridState;
  filterModel: ISimpleFilterModel;
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;

  installers: Installer[] = [];
  installerEditId: string = '';

  organization: Organization;
  user: User;

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _installerGridSettingService: InstallerListGridSettingService,
              private _installerService: InstallerService,
              private _userService: UserService) {}

  ngOnInit() {
    this._userService.getCurrentUser().subscribe((user: User) => {
      this.user = user;
      this.organization = user.organization;
    });
    this.gridOptions = this._installerGridSettingService.getGridOptions();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._installerGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._installerGridSettingService.buildDefaultGridState();
    this.gridStateStorageKey = this._installerGridSettingService.buildGridStateStorageKey('installers');
    this.getInstallers();
  }

  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._installerGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
      this.filterModel = this.gridApi.getFilterModel();
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._installerGridSettingService.exportCsv(this.gridApi, 'Installers', true);
        break;
      case GridSubAction.reload:
        this.getInstallers();
        break;
      case GridSubAction.clearFilter:
        this._installerGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        this.storeGridState();
        break;
    }
  }

  addInstallerClick() {
    this.installerForm.openModal('create');
  }

  editInstallerClick(event) {
    this.installerForm.openModal('edit', event);
  }

  getInstallers() {
    this._installerService.listInstallersByOrgId(this.organization.id)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((installers: Installer[]) => {
      this.installers = installers;
      const gridData = this.installers.map(inst => ({
        ...inst,
        projectInstallersCount: inst.projectInstallers?.length
      }));
      this.gridApi.setRowData(gridData);
    });
  }
}
