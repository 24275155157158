export enum DIALOG_BUTTON_STATUS {
  // use i18n key to value
  SAVE = 'save',
  CANCEL = 'cancel',
  DO_NOT_SAVE = 'do-not-save',
  CALCULATE = 'calculate',
  DO_NOT_CALCULATE = 'do-not-calculate'
}

export interface DialogButtonAction {
  pageName: string; // as ID
  action: DIALOG_BUTTON_STATUS;
  nextUrl: string;
}

export interface DialogInitialState {
  pageName: string;
  nextUrl: string;
  buttons?: string[];
}
