export enum CONNECTION_STATUS {
  ACCEPT,
  ARCHIVE,
  BAD_EXPERIENCE,
  DECLINE,
  END_RELATIONSHIP,
  LOCATION,
  MOU_SIGNED,
  NDA_SIGNED,
  OTHER_INVESTORS,
  PERMIT_LEVEL_1,
  PERMIT_LEVEL_2,
  PERMIT_LEVEL_3,
  PERMIT_LEVEL_4,
  PERMIT_LEVEL_5,
  TOU_SIGNED
}
