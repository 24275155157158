<oes-breadcrumb></oes-breadcrumb>

<tabset class="underline-tabs">
  <tab heading="{{ 'offtaker.manage.offtaker-heading' | translate }}">
    <oes-manage-offtakers></oes-manage-offtakers>
  </tab>

  <tab heading="{{ 'offtaker.manage.portfolio-heading' | translate }}">
    <oes-manage-project-set-portfolios></oes-manage-project-set-portfolios>
  </tab>

  <tab heading="{{ 'offtaker.manage.templates-heading' | translate }}">
    <oes-manage-project-set-financial-model-templates></oes-manage-project-set-financial-model-templates>
  </tab>

  <tab *ngIf="isSysAdmin" heading="{{ 'offtaker.manage.project-templates-heading' | translate }}">
    <oes-manage-organization-financial-models></oes-manage-organization-financial-models>
  </tab>
</tabset>
