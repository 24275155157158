import {BaseModel} from '@shared/base.model';

export class ProjectFinancialYearInput extends BaseModel<ProjectFinancialYearInput> {
  year: number;
  applianceRevenueChange = 0;
  otherRevenueChange = 0;
  applianceCostChange = 0;
  otherCostChange = 0;
  replacementCostChange = 0;
  fuelChangePercent = 0;
  opexChangePercent = 0;
  anchorCustomerChangePercent = 0;
  anchorLoadChangePercent = 0;
  residentialCustomerChangePercent = 0;
  residentialLoadChangePercent = 0;
  publicCustomerChangePercent = 0;
  publicLoadChangePercent = 0;
  productiveCustomerChangePercent = 0;
  productiveLoadChangePercent = 0;
  commercialCustomerChangePercent = 0;
  commercialLoadChangePercent = 0;

  constructor(projectFinancialYearInputInfo: any) {
    super();
    this.id = projectFinancialYearInputInfo.id;
    this.year = projectFinancialYearInputInfo.year;

    this.applianceRevenueChange = projectFinancialYearInputInfo.applianceRevenueChange;
    this.otherRevenueChange = projectFinancialYearInputInfo.otherRevenueChange;
    this.applianceCostChange = projectFinancialYearInputInfo.applianceCostChange;
    this.otherCostChange = projectFinancialYearInputInfo.otherCostChange;
    this.replacementCostChange = projectFinancialYearInputInfo.replacementCostChange;
    this.fuelChangePercent = projectFinancialYearInputInfo.fuelChangePercent;
    this.opexChangePercent = projectFinancialYearInputInfo.opexChangePercent;
    this.anchorCustomerChangePercent = projectFinancialYearInputInfo.anchorCustomerChangePercent;
    this.anchorLoadChangePercent = projectFinancialYearInputInfo.anchorLoadChangePercent;
    this.residentialCustomerChangePercent = projectFinancialYearInputInfo.residentialCustomerChangePercent;
    this.residentialLoadChangePercent = projectFinancialYearInputInfo.residentialLoadChangePercent;
    this.publicCustomerChangePercent = projectFinancialYearInputInfo.publicCustomerChangePercent;
    this.publicLoadChangePercent = projectFinancialYearInputInfo.publicLoadChangePercent;
    this.productiveCustomerChangePercent = projectFinancialYearInputInfo.productiveCustomerChangePercent;
    this.productiveLoadChangePercent = projectFinancialYearInputInfo.productiveLoadChangePercent;
    this.commercialCustomerChangePercent = projectFinancialYearInputInfo.commercialCustomerChangePercent;
    this.commercialLoadChangePercent = projectFinancialYearInputInfo.commercialLoadChangePercent;
  }
}
