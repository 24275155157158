import {BaseModel} from '@shared/base.model';
import {Organization} from '@shared/models/organization/organization.model';

export class QuoteConnection extends BaseModel<QuoteConnection> {
  acceptDateTime: Date;
  archiveDateTime: Date;
  customLeadTimeNotes: string;
  declineDateTime: Date;
  declineReason: string;
  endRelationshipDateTime: Date;
  interestDateTime: Date;
  leadTimeType: string;
  notes: string;
  quoteAppliances: boolean;
  quoteConsultingServices: boolean;
  quoteControllers: boolean;
  quoteEpcServices: boolean;
  quoteIntegratedKits: boolean;
  quoteMeters: boolean;
  quoteOmSolutions: boolean;
  quotePvInverters: boolean;
  quotePvPanels: boolean;
  quoteShipping: boolean;
  quoteStorage: boolean;
  quoteStorageConverters: boolean;
  quoteThirdParty: boolean;
  quoteTurnkeyProvider: boolean;
  vendorNote: string;
  vendorOrganization: Organization;

  constructor(quoteConnectionInfo: any) {
    super();
    this.id = quoteConnectionInfo.id;
    this.acceptDateTime = quoteConnectionInfo.acceptDateTime;
    this.archiveDateTime = quoteConnectionInfo.archiveDateTime;
    this.customLeadTimeNotes = quoteConnectionInfo.customLeadTimeNotes;
    this.declineDateTime = quoteConnectionInfo.declineDateTime;
    this.declineReason = quoteConnectionInfo.declineReason;
    this.endRelationshipDateTime = quoteConnectionInfo.endRelationshipDateTime;
    this.interestDateTime = quoteConnectionInfo.interestDateTime;
    this.leadTimeType = quoteConnectionInfo.leadTimeType;
    this.notes = quoteConnectionInfo.notes;
    this.quoteAppliances = quoteConnectionInfo.quoteAppliances;
    this.quoteConsultingServices = quoteConnectionInfo.quoteConsultingServices;
    this.quoteControllers = quoteConnectionInfo.quoteControllers;
    this.quoteEpcServices = quoteConnectionInfo.quoteEpcServices;
    this.quoteIntegratedKits = quoteConnectionInfo.quoteIntegratedKits;
    this.quoteMeters = quoteConnectionInfo.quoteMeters;
    this.quoteOmSolutions = quoteConnectionInfo.quoteOmSolutions;
    this.quotePvInverters = quoteConnectionInfo.quotePvInverters;
    this.quotePvPanels = quoteConnectionInfo.quotePvPanels;
    this.quoteShipping = quoteConnectionInfo.quoteShipping;
    this.quoteStorage = quoteConnectionInfo.quoteStorage;
    this.quoteStorageConverters = quoteConnectionInfo.quoteStorageConverters;
    this.quoteThirdParty = quoteConnectionInfo.quoteThirdParty;
    this.quoteTurnkeyProvider = quoteConnectionInfo.quoteTurnkeyProvider;
    this.vendorNote = quoteConnectionInfo.vendorNote;
    this.vendorOrganization = new Organization(quoteConnectionInfo.vendorOrganization || {});
  }
}
