<oes-modal-dialog
  [title]="'buttons.upload' | translate"
  [submitButtonText]="'buttons.upload' | translate"
  [activateSubmit]="!files.length"
  (submit)="upload()"
  (close)="reset()">
  <!-- the below goes to dialog-body -->
  <ng-container *ngIf="!showUploadProgress; else uploadProgress">
    <!-- extra waning -->
    <ng-container *ngIf="extraMessage && extraMessage !==''">
      <oes-notify
        [iconName]="'fa-bullhorn'"
        [message]="extraMessage"
        [type]="'warning'">
      </oes-notify>
    </ng-container>
    <div [formGroup]="formGroup">
      <div class="folder-selection required">
        {{'data-room.dialogs.upload.upload-to' | translate}}
        <oes-dropdown
          *ngIf="selectOptions"
          id="uploadFolderSelection"
          [parentFormGroup]="formGroup"
          [initialSelections]="[selectedOption]"
          [items]="selectOptions"
          [selection]="'single'"
          [enableSearch]="true">
        </oes-dropdown>
      </div>
      <label for="upload-tag-name">{{'data-room.dialogs.upload.tag' | translate}}</label>
      <input
        formControlName="tag"
        class="tag-input"
        id="upload-tag-name"
        autocomplete="off"
        (keydown)="onKeyDown($event)"
        (change)="selectTag($event)"
        list="uploadTagList">
      <datalist id="uploadTagList">
        <option *ngFor="let tag of tags" [value]="tag.tag">
          {{tag.tag}}
        </option>
      </datalist>
      <div class="file-uploader d-flex flex-row justify-content-between">
        <ul>
          <li *ngFor="let file of files">{{file.name}}</li>
        </ul>
        <label class="btn btn-primary" for="upload-button">
          {{'buttons.choose-files' | translate}}
        </label>
        <input
          type="file"
          multiple
          (change)="changeInput($event)"
          id="upload-button"
          data-testid="loader-upload-button"
          style="display: none;">
      </div>
      <oes-form-error-message
        *ngIf="files && files.length > 0"
      [showInvalid]="true"
      [text]="'data-room.dialogs.filename-notification' | translate">
      </oes-form-error-message>
    </div>
  </ng-container>
  <ng-template #uploadProgress>
    <div class="d-flex justify-content-between align-items-center results-box">
      <p class="mr-auto">{{ 'data-room.dialogs.upload.files-uploaded' | translate }}:</p>
      <p class="grey-result">{{ uploadsCompleted }}</p>
    </div>
    <p class="results-box mt-3">
      <span class="results-left">{{ 'data-room.dialogs.upload.current' | translate }}: </span>
      {{ files[uploadsCompleted]?.name }}
    </p>
  </ng-template>
</oes-modal-dialog>
