<div class="section milestone__notes">
  <div class="milestone__notes-row">
    <div class="milestone__notes-row-text">
      <h5>{{ 'milestones.notes-title' | translate }} ({{ milestone.notes?.length ? milestone.notes?.length : '0'}})</h5>
      <p>{{ 'milestones.detail.notes-subtitle' | translate }}</p>
    </div>
    <div class="actions">
      <oes-button [trailingIconSrc]="noteSort === 'desc' ? 'assets/icons/line-icons/shared/black/full-arrow-down.svg' : 'assets/icons/line-icons/shared/black/full-arrow-up.svg'"
                  (click)="toggleNoteSort()"
                  class="btn btn-sm btn-tertiary btn-gray">
        {{ (noteSort === 'desc' ? 'buttons.sort-by-newest' : 'buttons.sort-by-oldest') | translate }}
      </oes-button>
      <ng-container *ngIf="!readOnly">
        <oes-button
          class="btn btn-primary btn-sm"
          [leadingIconSrc]="'assets/icons/line-icons/shared/white/plus.svg'"
          (click)="handleOpenEditMilestoneAddNote(index)"
        >{{ 'milestones.add-note' | translate }}</oes-button>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="milestone.notes?.length; else notesEmpty">
    <div class="milestone__notes-list">
      <div class="milestone__notes-list-item-wrapper"
          *ngFor="let note of milestone.notes | slice:0:notesListLimit; index as noteIndex">
        <div class="milestone__notes-list-item">
          <div class="icon-round-bg" [ngStyle]="{
            'backgroundColor': note.organization.id === user.organization.id
              ? 'var(--color-brand-a-100)'
              : 'var(--color-baseline-100)'
          }">
            <span>{{ getNoteOrgInitials(note.organization.name) }}</span>
          </div>
          <div class="milestone__notes-list-item-text">
            <p class="weight-semibold title">
              {{ note.organization.name }}
              <span class="date-added">{{ note.created | date: 'dd MMMM yyyy HH:mm' }}</span>
              <ng-container *ngIf="note.organization.id === user.organization.id">
                <a class="link-button"
                  (click)="handleOpenEditMilestoneEditNote(index, note, noteIndex)"
                >{{ 'buttons.edit' | translate }}</a>
                <a class="delete-button" (click)="handleOpenNoteDeleteWarning(noteIndex)">{{ 'buttons.delete' | translate }}</a>
              </ng-container>
            </p>
            <pre class="content">{{ note.content }}</pre>
          </div>
        </div>
        <ng-container *ngIf="allNoteDocuments[note.id]?.length">
          <div class="milestone__notes-list-item-footer">
            <div class="attachment-small"
                *ngFor="let noteDoc of allNoteDocuments[note.id]; index as noteDocIndex"
                (click)="downloadDocumentsClick([noteDoc])">
              <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/black/attachment.svg" role="presentation" />
              <p>{{ noteDoc.path }}</p>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="expandButtonLabel">
        <div class="expand-notes-button" [ngClass]="{'expanded': notesListLimit === milestone.notes.length}">
          <a class="text-sm" (click)="toggleNotesListLimit()">
            <span>{{ expandButtonLabel | translate }}</span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.5 6.75L9 11.25L13.5 6.75" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #notesEmpty>
    <div class="box-container">
      <div class="icon-round-bg">
        <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/black/message.svg" role="presentation">
      </div>
      <p>{{ 'milestones.detail.no-notes' | translate }}</p>
      <ng-container *ngIf="!readOnly">
        <a class="link-button text-sm weight-medium"
          (click)="handleOpenEditMilestoneAddNote(index)"
        >{{ 'milestones.add-note' | translate }}</a>
      </ng-container>
    </div>
  </ng-template>
</div>

<oes-modal-dialog
  #deleteNoteWarningModal
  id="deleteNoteWarningModal"
  [size]="'small'"
  [allowCancel]="true"
  [allowSubmit]="true"
  [destructive]="true"
  [title]="'milestones.delete-modal.delete-note' | translate"
  (submit)="handleDeleteNote()"
  [submitButtonText]="'buttons.confirm' | translate"
  (close)="handleCloseNoteDeleteWarning()">
  {{ 'milestones.delete-modal.delete-note-warning' | translate }}
</oes-modal-dialog>
