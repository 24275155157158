import { GridState } from './gird-state';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, IServerSideGetRowsRequest } from 'ag-grid-community';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseAgGridSettingsService {
  currentLang = 'en';
  globalTranslations = {};

  private _formatterService: any;
  private timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  abstract currentGridState: GridState;
  abstract defaultGridState: GridState; // for reset states and sub-action.component
  abstract _gridStateStorageKey: string;
  abstract _gridApi: GridApi;
  abstract _columnApi: ColumnApi;

  constructor(
    private _agGridServicesWrapperService: AgGridServicesWrapperService
  ) {
    this.currentLang =
      this._agGridServicesWrapperService._languageService.getLanguage();
    this._agGridServicesWrapperService._translateService
      .get('ag-grid-global')
      .pipe(take(1))
      .subscribe((translations) => {
        this.globalTranslations = translations;
      });
    this._formatterService = _agGridServicesWrapperService._formatterService;
  }

  protected formatRequiredValue(params) {
    if (params.value && params.value.trim() !== '') {
      return params.value;
    }
    return 'Unknown';
  }

  protected formatProjectSyncStatus(params) {
    if (params.value && params.value.trim() !== '') {
      return params.value === 'Project Not Found' ? params.value : 'Published';
    }
    return 'Ready';
  }

  protected formatPercent(params): string {
    return this._formatterService.formatPercent(params, this.currentLang);
  }

  protected formatCurrency(params, currentLang): string {
    return this._formatterService.formatCurrency(params, currentLang);
  }

  // Used for formatting when a data is returned by the server, e.g. '2020-01-19'
  protected formatDate(params) {
    if (params.value) {
      return DateTime.fromISO(params.value).toFormat('yyyy-MM-dd');
    }
    return null;
  }

  protected formatDateHHMM(params) {
    if (params.value) {
      return DateTime.fromISO(params.value).toFormat('yyyy-MM-dd hh:mm');
    }
    return null;
  }

  protected dateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
    filterLocalDateAtMidnight.setHours(0,0,0);  // Ensures time is checking against midnight
    const dateAsString = cellValue.substring(0, 10);
    if (dateAsString == null) {
      return -1;
    }
    const dateParts = dateAsString.split('-');
    // adjusted the second param for customer connection.
    // Number(dateParts[1]) -> Number(dateParts[1] - 1)
    const cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  }

  protected caseInsensitiveSort(a, b) {
    if (typeof a === 'string') {
      return a.localeCompare(b);
    } else {
      return a > b ? 1 : a < b ? -1 : 0;
    }
  }

  // to use program
  protected getLocalDate(params, format: string = 'yyyy-MM-dd HH:mm:ss') {
    return this._formatterService.getLocalDate(
      params,
      format,
      this.currentLang
    );
  }

  protected getLocalDateWithTextTimezone(
    params,
    format: string = 'yyyy-MM-dd HH:mm:ss'
  ) {
    return this.getLocalDate(params, format) + ' (' + this.timeZone + ')';
  }

  protected numberSort(a, b, nodeA, nodeB, isInverted) {
    // used to always sort null/undefined to the bottom
    if (!a) {
      return isInverted ? -1 : 1;
    }
    const numberA = Number(a);
    const numberB = Number(b);
    return numberA > numberB ? 1 : numberA < numberB ? -1 : 0;
  }

  // For dropdown filter
  protected generateDropdownFilter(
    rawData: any,
    key: string,
    property?: string
  ): any {
    const list = rawData.map((value) => {
      if (property) {
        return value[key][property];
      } else {
        return value[key];
      }
    });
    return Array.from(new Set(list)).sort();
  }

  protected defaultFilterConfig() {
    return {
      caseSensitive: false,
      applyButton: true,
      clearButton: true,
    };
  }

  //////--- Synchronous Server-Side Helper Methods ---//////

  setGridApi(gridApi: GridApi, columnApi: ColumnApi) {
    this._gridApi = gridApi;
    this._columnApi = columnApi;
  }

  applyStoredGridState(
    gridStateStorageKey: string,
    defaultGridStateFallback?: GridState
  ) {
    const gridState: GridState = this.getGridState(gridStateStorageKey);
    if (gridState) {
      this._columnApi.applyColumnState({
        state: gridState.column,
        applyOrder: true,
      });
      this._gridApi.setFilterModel(gridState.filter);
    } else if (defaultGridStateFallback) {
      this._columnApi.applyColumnState({
        state: defaultGridStateFallback.column,
        applyOrder: true,
      });
      this._gridApi.setFilterModel(defaultGridStateFallback.filter);
    }
  }

  clearStoredGridState(gridStateStorageKey: string, resetRowHeights: boolean = false) {
    if (gridStateStorageKey) {
      if (this._gridApi) {
        this._gridApi.setFilterModel(null);
      }
      if (this._columnApi) {
        this._columnApi.resetColumnState();
      }
      if (resetRowHeights && this._gridApi) {
        this._gridApi?.resetRowHeights();
        this._gridApi?.redrawRows();
      }
    }
  }

  storeGridStateByApis(
    gridStateStorageKey: string,
    gridApi: GridApi,
    columnApi: ColumnApi
  ): GridState {
    const gridState = new GridState({
      filter: gridApi.getFilterModel(),
      column: columnApi.getColumnState(),
    });
    localStorage.setItem(gridStateStorageKey, JSON.stringify(gridState));
    return gridState;
  }

  getGridState(gridStateStorageKey: string): GridState {
    return JSON.parse(localStorage.getItem(gridStateStorageKey));
  }

  constructOptionsSortAndfilter(options, request: IServerSideGetRowsRequest) {
    if (request?.sortModel?.length) {
      const sortModel = request.sortModel[0];
      options.params['sort'] = sortModel.colId + ',' + sortModel.sort;
    }
    if (request?.filterModel && Object.keys(request.filterModel).length) {
      options.params['filter'] = JSON.stringify(request.filterModel);
    }
    return options;
  }

  buildGridStateStorageKey(baseKey: string, optionalId?: string): string {
    if (!optionalId) {
      return `${environment.name}_${baseKey}`;
    }
    return `${environment.name}_${baseKey}_${optionalId}`;
  }

  buildDefaultGridState(
    filterState = {},
    columnState = this._columnApi.getColumnState()
  ): GridState {
    const gridState: GridState = {
      filter: filterState,
      column: columnState,
    };
    return gridState;
  }

  /**
   * Export displaying columns to CSV
   * @param gridApi: Ag-Gid API
   * @param prefix: string; prefix of the csv filename. <prefix>_yyyy-MM-dd.csv
   * @param hasCheckbox: boolean; when the grid has a checkbox, remove it from csv
   * @param callback: function; when you use cellRenderer, you may need processCellCallback.
   */
  exportCsv(
    gridApi: any,
    prefix: string,
    hasCheckbox?: boolean,
    callback?: any
  ) {
    const config = {
      fileName:
        prefix +
        '_' +
        new DatePipe(this.currentLang).transform(new Date(), 'yyyy-MM-dd') +
        '.csv',
    };
    // remove checkbox
    if (hasCheckbox) {
      const columnKeys = gridApi.columnModel?.displayedColumns
        ?.filter((column) => {
          return !column.colDef.checkboxSelection;
        })
        .map((column) => {
          return column.colId;
        });
      config['columnKeys'] = columnKeys;
    }
    // add processCellCallback
    if (callback) {
      config['processCellCallback'] = callback;
    }
    gridApi.exportDataAsCsv(config);
  }
}
