export class ProgramClaimsKPIs {
  submittedConnections: number;
  approvedConnections: number;
  paidConnections: number;
  totalPayouts: number;

  constructor(kpis: any) {
    this.submittedConnections = kpis.submittedConnections || 0;
    this.approvedConnections = kpis.approvedConnections || 0;
    this.paidConnections = kpis.paidConnections || 0;
    this.totalPayouts = kpis.totalPayouts || 0;
  }
}
