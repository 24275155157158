import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, ValidationErrors, Validator } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Program, ProgramSubmissionType } from '@program/shared/program.model';
import { ProgramService } from '@program/shared/program.service';
import { ProjectPageStatusService } from '@project/shared/project-page-status.service';
import { ProjectType } from '@project/shared/project-type.enum';
import { Project } from '@project/shared/project.model';
import { ProjectService } from '@project/shared/project.service';
import { EventService } from '@shared/event.service';
import { CurrencyExchange } from '@shared/models/currency-exchange/currency-exchange.model';
import { CurrencyExchangeService } from '@shared/models/currency-exchange/currency-exchange.service';
import { REFERENCE_TYPE } from '@shared/reference-types';
import { ReferenceType } from '@shared/references.model';
import { Country } from '@shared/services/country/country.model';
import { CountryService } from '@shared/services/country/country.service';
import { Region } from '@shared/services/region/region.model';
import { RegionService } from '@shared/services/region/region.service';
import { ROLE_TYPE } from '@user/role-type';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject, zip } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';

import { FormErrorType } from '../form-error-message/form-error-type.enum';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { SpecificationFormRule } from './project-type-rules/specification-form-rule';
import { PROJECT_SPECIFICATION_FORM_RULES } from './project-type-rules/specification-form-rules';
import { SpecificationFormService } from './specification-form.service';

/*
 * For create a new project and edit a project
*/
@Component({
  selector: 'oes-project-specification-form',
  templateUrl: './specification-form.component.html',
  styleUrls: ['./specification-form.component.scss'],
  providers: [
    CurrencyExchangeService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectSpecificationFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProjectSpecificationFormComponent),
      multi: true
    }
  ]
})
export class ProjectSpecificationFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @ViewChild('exchangeRateModal', {static: false}) exchangeRateModal: ModalDialogComponent;
  @ViewChild('googleMapsModal', {static: false}) googleMapsModal: ModalDialogComponent;
  @Input() cancelButton = false;
  @Input() project: Project;
  @Input() readOnly = false;
  @Input() submitButtonName = 'save'; // pass to translation "'buttons.' + submitButtonName | translate"
  @Output() saved: EventEmitter<Project> = new EventEmitter<Project>();

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    showWeekNumbers: false,
    dateInputFormat: 'YYYY-MM-DD'
  };
  currentCurrency = 'USD';
  dataReady = false;
  errorType = FormErrorType;
  exchangeRates: CurrencyExchange[];
  isCreate: boolean;
  isRequired = false;
  mapOptions: google.maps.MapOptions = {};
  mapType: string;
  markerOption: google.maps.MarkerOptions = {draggable: true};
  markerPosition: google.maps.LatLngLiteral;
  projectTypeTitle = '';
  showTenderProjectField: boolean = false;
  specificationFormGroup: UntypedFormGroup;
  specificationFormRule: SpecificationFormRule;
  user: User;
  zoomLevel: number;

  private ngUnsubscribe: Subject<any> = new Subject();
  private programId: string;

  constructor(private _projectService: ProjectService,
              private _programService: ProgramService,
              private _userService: UserService,
              private _countryService: CountryService,
              private _regionService: RegionService,
              private _specificationFormService: SpecificationFormService,
              private _projectPageStatusService: ProjectPageStatusService,
              private _router: Router,
              private _currencyExchangeService: CurrencyExchangeService,
              private _eventService: EventService,
              private _translateService : TranslateService){
  }

  ngOnInit() {
    if (this.project?.projectType) {
      this.setSpecificationFormRule(this.project?.projectType);
    }
    this.specificationFormGroup = this._specificationFormService.createForm(this.readOnly, this.specificationFormRule);

    // map
    this.mapSetup();
    this.getOrganizationId();
    this.projectTypeTitle = this.project?.projectType;

    this.specificationFormGroup.valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(result => {
      if (this.specificationFormGroup.dirty && this.specificationFormGroup.touched) {
        this._specificationFormService.dirty = true;
      }
      this.setShowTenderProjectField(result?.programControl);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private setShowTenderProjectField(programId: string) {

    if (programId !== null) {
      const program = this.programs?.find(p => p.id === programId);
      if (program?.sponsor?.id === this.user?.organization?.id) {
        this.showTenderProjectField = true;
      } else {
        this.showTenderProjectField = false;
      }
    } else {
      this.showTenderProjectField = false;
    }

    if (this.programId !== programId) {
      if (this.project.tenderProject != null) {
        this.specificationFormGroup.controls.tenderProjectControl.setValue(this.project.tenderProject, { emitEvent: false });
      } else {
        this.specificationFormGroup.controls.tenderProjectControl.setValue(this.showTenderProjectField, { emitEvent: false });
      }
      this.specificationFormGroup.updateValueAndValidity({ emitEvent: false });
      this.programId = programId;
    }
  }

  private setSpecificationFormRule(projectType: ProjectType) {
    switch (projectType) {
      case ProjectType.minigrid:
        this.specificationFormRule = PROJECT_SPECIFICATION_FORM_RULES.MINIGRID;
        break;
      case ProjectType.ci:
        this.specificationFormRule = PROJECT_SPECIFICATION_FORM_RULES.CI_SITE;
        break;
      case ProjectType.cookstove:
        this.specificationFormRule = PROJECT_SPECIFICATION_FORM_RULES.COOKSTOVE;
        break;
      case ProjectType.pue:
        this.specificationFormRule = PROJECT_SPECIFICATION_FORM_RULES.PRODUCTIVE_USE_EQUIPMENT;
        break;
      case ProjectType.shs:
        this.specificationFormRule = PROJECT_SPECIFICATION_FORM_RULES.SOLAR_HOME_SYSTEM;
        break;
      default:
        break;
    }
  }

  private mapSetup() {
    this.mapOptions.center = {
      lat: this.project.latitude,
      lng: this.project.longitude
    };
    this.mapOptions.zoomControl = true;
    this.mapOptions.zoom = 16;
    this.mapOptions.mapTypeId = 'hybrid';
    this.mapOptions.scaleControl = true;
    this.mapOptions.mapTypeControl = true;
    this.mapOptions.streetViewControl = false;
    this.mapOptions.scrollwheel = false;

    // marker
    this.markerPosition = {
      lat: this.project?.latitude,
      lng: this.project?.longitude
    };
  }

  // map-marker callback
  public addMarker(event) {
    this.markerPosition = event.latLng.toJSON();
    if (this.markerPosition) {
      if (this.markerPosition.lat) {
        this.specificationFormGroup.controls['latitudeControl'].setValue(this.markerPosition.lat, {emitEvent: false});
      }
      if (this.markerPosition.lng) {
        this.specificationFormGroup.controls['longitudeControl'].setValue(this.markerPosition.lng, {emitEvent: false});
      }
      this.specificationFormGroup.markAsDirty();
      this.specificationFormGroup.markAsTouched();
    }
  }

  private getOrganizationId() {
    this._userService.getCurrentUser()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((user: User) => {
      if (user) {
        this.user = user;
        this.getSelectOptionItems();
      }
    });
  }

  private getSelectOptionItems() {
    if (this._userService.getSelectedRole() === ROLE_TYPE.DEVELOPER_USER) {
      this.getDeveloperPrograms();
    } else if (this._userService.getSelectedRole() === ROLE_TYPE.FINANCE_USER) {
      this.getInvestorPrograms();
    }
    // this.getStatusTypes();
    // this.getCurrencyTypes();
    // this.getProjectTypes(); // NOTE: Need for create a project
    // this.getCountries();
  }

  // Investor: for tendered project
  private getInvestorPrograms() {
    this._programService.getPrograms()
    .pipe(take(1))
    .subscribe((programs: Program[]) => {
      let filteredPrograms;
      if (this.project?.projectType === ProjectType.ci) {
        filteredPrograms = programs.filter(program => program.submissionType === ProgramSubmissionType.C_I);
      } else {
        filteredPrograms = programs.filter(program => program.submissionType === ProgramSubmissionType.PROJECT);
      }
      this._specificationFormService.programs = this.sortProgramsCaseInsensitive(filteredPrograms);
      this.getOtherOptionItems();
    });
  }

  // Developer
  private getDeveloperPrograms() {
    this._programService.getApproved()
    .pipe(take(1))
    .subscribe((approvedPrograms: Program[]) => {
      let filteredPrograms = [];
      if (this.project?.projectType === ProjectType.ci) {
        filteredPrograms = approvedPrograms.filter(program => program.submissionType === ProgramSubmissionType.C_I);
      } else if (this.project?.projectType) {
        filteredPrograms = approvedPrograms.filter(program => program.submissionType === ProgramSubmissionType.PROJECT);
      }
      this._specificationFormService.programs = this.sortProgramsCaseInsensitive(filteredPrograms);
      this.getOtherOptionItems();
    });
  }

  private sortProgramsCaseInsensitive(programs: Program[]): Program[] {
    return programs.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }

  expiredProgram(endDateTime: Date) {
    return new Date(endDateTime) < new Date();
  }

  submittedToProgram(programId: string) {
    if (this.project.program.id === programId &&
       (this.project.projectPermissions?.portfolio?.submittedToProgram ||
        this.project.projectPermissions?.project?.submittedToProgram)) {
      return true;
    }
    return false;
  }

  private getOtherOptionItems() {
    zip(
      this._projectService.getReferenceType(REFERENCE_TYPE.PROJECT_STATUS_TYPE),
      this._projectService.getReferenceType(REFERENCE_TYPE.CURRENCY_TYPE),
      this._projectService.getReferenceType(REFERENCE_TYPE.PROJECT_TYPE),
      this._countryService.getCountries()
    ).subscribe(results => {
      if (results && results.length > 0) {
        if (results[0] && results[0].length > 0) {
          this._specificationFormService.projectStatusTypes = results[0];
        }
        if (results[1] && results[1].length > 0) {
          this._specificationFormService.currencyTypes = results[1];
        }
        if (results[2] && results[2].length > 0) {
          this._specificationFormService.projectTypes = results[2];
        }
        if (results[3] && results[3].length > 0) {
          this._specificationFormService.countries = results[3];
        }
      }
      this.setupData();
    });
  }

  private setupData() {
    if (this.project) {
      if (this.project.country) {
        // To set state/province
        this.onCountryChanged(this.project.country.id);
      }
      this._specificationFormService.setFormValues(this.specificationFormGroup, this.project, this.project.cod, this.readOnly, this.specificationFormRule);
      this.dataReady = true;
    }
  }

  // callback: county select option
  public onCountryChanged(countryId?: string) {
    // ngValue add numbering to a value like "1: 0123abad123" and get value from formGroup.
    const id = countryId ? countryId : this.specificationFormGroup.controls.countryControl.value;
    if (id && id !== '' && this.specificationFormRule?.fields?.state?.display) {
      let params = new HttpParams()
        .append('countryId', id);
      // don't show deleted regions for a new project. Existing projects may have a soft-deleted region
      if (!this.project.id) {
        params = params.append('includeDeleted', false);
      }
      const option = {
        params: params
      };
      this._regionService.getRegions(option)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((regions: Region[]) => {
        this._specificationFormService.regions = regions;
        if (regions.length === 0) {
          this.specificationFormGroup.controls['regionControl'].setValue('', {emitEvent: false});
          this.specificationFormGroup.controls['regionControl'].disable({emitEvent: false});
          this.isRequired = false;
        } else {
          // force to set a region because it's required field and I couldn't find to changed the form validation type
          if (!this._specificationFormService.regions.map(reg => reg.id).includes(this.project.region.id)) {
            this.specificationFormGroup.controls['regionControl'].setValue(this.regions[0].id, {emitEvent: true});
          }
          this.specificationFormGroup.controls['regionControl'].enable({emitEvent: false});
          this.isRequired = true;
        }

        if (this.readOnly) { this.specificationFormGroup.controls['regionControl'].disable({emitEvent: false}); }
      });
    }
  }

  // callback: county select option
  public onCurrencyChanged() {
    // ngValue add numbering to a value like "1: 0123abad123" and get value from formGroup.
    this.currentCurrency = this.specificationFormGroup.controls.currencyControl.value;
    if (this.currentCurrency && this.currentCurrency === 'USD') {
      this.specificationFormGroup.controls['exchangeRateControl'].setValue(1, {emitEvent: false});
      this.specificationFormGroup.controls['exchangeRateControl'].disable({emitEvent: false});
    } else {
      this.specificationFormGroup.controls['exchangeRateControl'].setValue('', {emitEvent: false});
      this.specificationFormGroup.controls['exchangeRateControl'].enable({emitEvent: false});
    }
  }

  // callback: View Rates link
  public showExchangeRates() {
    let params = new HttpParams();
    const currencyId = this.specificationFormGroup.controls['currencyControl'].value;
    if (currencyId) {
      params = params.set('code', currencyId);
    }
    this._currencyExchangeService.list(params)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((exchangeRateResults: CurrencyExchange[]) => {
      this.exchangeRates = exchangeRateResults;
      if (this.exchangeRates?.length > 0) {
        this.exchangeRateModal.show();
      }
    });
  }

  public onSubmit() {
    const oldProgram = this.project.program;
    const updatedProject = this._specificationFormService.getFormValues(this.specificationFormGroup, this.specificationFormRule, this.project);

    if (this.project.id && oldProgram?.id !== updatedProject.program?.id) {
       this._projectService.updateWithSideEffects(updatedProject, true)
        .pipe(take(1))
        .subscribe(projectWithSideEffects => {
          const project = projectWithSideEffects.project;
          if (projectWithSideEffects.sideEffects?.projectConnectionSoftDeleted) {
            this.notifyProjectUnsharedWithPreviousProgramSponsor(project);
          }
          this.afterProjectSaved(project);
        });
    } else {
      this._projectService.createUpdate(updatedProject, true)
        .pipe(take(1))
        .subscribe(project => {
          this.afterProjectSaved(project);
        });
    }
  }

  private afterProjectSaved(project: Project) {
    if (project) {
      this.specificationFormGroup.markAsPristine();
      this.specificationFormGroup.markAsUntouched();
      this.project = project;
      this.saved.emit(project);
      if (project.projectType !== ProjectType.ci) {
        this._projectPageStatusService.getFinancialModel(project.id);
      }
    }
  }

  private notifyProjectUnsharedWithPreviousProgramSponsor(project: Project) {
    this._eventService.success(
        this._translateService.instant('success-message.project-unshared-with') + ' '
        + project.program.sponsor.name + '.'
        + this._translateService.instant('success-message.project-reshare-advice'));
  }

  // to grey out a default value
  public isDefaultValue(controlName: string) {
    if (controlName && controlName !== '') {
      const value = this.specificationFormGroup.controls[controlName].value;
      return {'select-w-placeholder' : value === undefined};
    }
  }

  // cancel button
  public cancel() {
    this.specificationFormGroup.markAsPristine();
    this._router.navigate(['/oes/projects']);
  }

  checkLocation() {
    this.googleMapsModal.show();
  }

  // access from html
  get projectStatusTypes(): ReferenceType[] {
    return this._specificationFormService.projectStatusTypes;
  }
  get countries(): Country[] {
    return this._specificationFormService.countries;
  }
  get regions(): Region[] {
    return this._specificationFormService.regions;
  }
  get programs(): Program[] {
    return this._specificationFormService.programs;
  }
  get projectTypes(): ReferenceType[] {
    return this._specificationFormService.projectTypes;
  }
  get currencyTypes(): ReferenceType[] {
    return this._specificationFormService.currencyTypes;
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.specificationFormGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.specificationFormGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.specificationFormGroup.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.specificationFormGroup.disable() : this.specificationFormGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.specificationFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
