import { Component, OnInit, ViewChild } from '@angular/core';
import { BulkRevenueModelUpdateService } from './revenue-assumptions-editor/bulk-revenue-model-update.service';
import { RevenueAssumptionsEditorComponent } from './revenue-assumptions-editor/revenue-assumptions-editor.component';
import { EventService } from '@shared/event.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '@user/user.service';
import { take } from 'rxjs/operators';
import { User } from '@user/user.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * When this modal hides, it will sent revenueAssumptionsEditor" as a hiding reason.
 */
@Component({
  selector: 'oes-project-dialog-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss']
})
export class ProjectTariffDialogComponent {
  @ViewChild(RevenueAssumptionsEditorComponent, {static: false}) public revenueAssumptionsEditor: RevenueAssumptionsEditorComponent;
  // the below variables are filled via this._bsModalService.show(component, config)
  public selectedProjectIds: string[];
  public config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false
  };

  constructor(public bsModalRef: BsModalRef,
              private _eventService: EventService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _bulkRevenueModelUpdateService: BulkRevenueModelUpdateService,
              private _bsModalService: BsModalService) {
  }

  onSubmit() {
    const bulkRevenueModelUpdateRequest = this.revenueAssumptionsEditor.request(this.selectedProjectIds);
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      if (user) {
        this._bulkRevenueModelUpdateService.apply(user.organization.id, bulkRevenueModelUpdateRequest)
        .subscribe(response => {
          this._bsModalService.setDismissReason('revenueAssumptionsEditor');
          this.bsModalRef.hide();
          this._eventService.success(this._translateService.instant('success-message.project-tariffs-applied'));
        });
      }
    });
  }
}
