import { BaseModel } from '@shared/base.model';
import { FORM_TYPE } from './form-type.enum';

export class FileUploadStorage {
  organizationId: string;
  formType: FORM_TYPE;
  index: string;

  constructor(fileUploadStorageInfo) {
    this.organizationId = fileUploadStorageInfo.organizationId;
    this.formType = fileUploadStorageInfo.formType;
    this.index = fileUploadStorageInfo.index;
  }
}
