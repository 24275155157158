import { AfterViewInit, Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[oesAgHorizontalScroll]'
})
export class AgHorizontalScrollDirective implements AfterViewInit{
  private isDown = false;
  private startX: number;
  private scrollLeft: number;
  private target: any;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.target = this.el.nativeElement.querySelector('.ag-center-cols-viewport');
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.target) {
      return;
    }
    const targetRect = this.target.getBoundingClientRect();
    const cursorOverTarget =
      event.clientX >= targetRect.left &&
      event.clientX <= targetRect.right &&
      event.clientY >= targetRect.top &&
      event.clientY <= targetRect.bottom;
    if (!cursorOverTarget) {
      return;
    }
    this.isDown = true;
    this.startX = event.pageX - this.target.offsetLeft;
    this.scrollLeft = this.target.scrollLeft;
    this.target.style.cursor = 'grabbing';
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.target) {
      return;
    }
    this.target.style.cursor = 'grab';
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (!this.target) {
      return;
    }
    this.isDown = false;
    this.target.style.cursor = 'grab';
  }

  @HostListener('mouseup')
  onMouseUp() {
    if (!this.target) {
      return;
    }
    this.isDown = false;
    this.target.style.cursor = 'grab';
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.target || !this.isDown) {
      return;
    };
    event.preventDefault();
    const x = event.pageX - this.target.offsetLeft;
    const walk = (x - this.startX) * 3; // The multiplier speeds up scrolling
    this.target.scrollLeft = this.scrollLeft - walk;
  }
}
