import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UrlCheckService } from '@main-navigation/shared/url-check.service';
import { ProgramProjectStageRules } from '@program/program-detail/program-configuration/program-stages/program-project-stage-rules.model';
import { ProgramSettings } from '@program/program-detail/program-configuration/settings/settings.model';
import { PROGRAM_RULE_TYPES } from '@program/shared/program-rule-types.enum';
import { ProgramService } from '@program/shared/program.service';
import { ProjectConnectionInteraction, ProjectConnectionInteractionService, ProjectConnectionStatus } from '@project/detail/sharing/shared/project-connection-interaction.service';
import { Project } from '@project/shared/project.model';
import { SimpleProject } from '@project/shared/simple-project.model';
import { Organization } from '@shared/models/organization/organization.model';
import { UtilityService } from '@shared/services/utility.service';
import { ROLE_TYPE } from '@user/role-type';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { forkJoin, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-stage-update-bulk',
  templateUrl: './stage-update-bulk.component.html',
  styleUrls: ['./stage-update-bulk.component.scss'],
  providers: [
  ]
})
export class StageUpdateBulkComponent implements OnInit, OnDestroy {
  @ViewChild('messageViewPort', {static: false}) private messageViewPort: ElementRef;

  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() selectedProjects: SimpleProject[];
  @Input() programProjectStages: any;

  quillOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, { 'list': 'bullet' }],
      ['link']
    ]
  };
  stages: ProgramProjectStageRules[];
  submitForm: UntypedFormGroup;
  user: User;

  private ngUnsubscribe: Subject<any> = new Subject();
  private programId: string;
  private projectConnectionStatus = new ProjectConnectionStatus();

  constructor(private _projectStageOverviewService: ProjectConnectionInteractionService,
              private _programService: ProgramService,
              private _utilityService: UtilityService,
              private _urlCheckService: UrlCheckService,
              private _userService: UserService) {

    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.user = new User(user);
    });
  }

  ngOnInit() {
    this.createForm();
    this.setProgramIdFromUrl();
    this.getProgramProjectRules();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getSelectColor(): string {
    return this.stages?.find(stage => stage.name === this.submitForm?.controls['projectStage']?.value)?.color;
  }

  getTextColor(hexCode: string) {
    if (hexCode) {
      return this._utilityService.findTextColor(hexCode);
    }
  }

  onSelectStage(stage?: string) {
    if (stage) {
      this.submitForm.controls['projectStage'].setValue(stage);
    } else {
      this.submitForm.controls['projectStage'].setValue(null);
    }
  }

  private setProgramIdFromUrl() {
    this.programId = this._urlCheckService.getProgramId(this._urlCheckService.detectUrl());
  };

  private getProgramProjectRules() {
    this._programService.getProgramRules(this.programId, PROGRAM_RULE_TYPES.PROJECT_STAGE_RULE)
      .pipe(take(1))
      .subscribe((stages: ProgramProjectStageRules[]) => {
        this.stages = stages;
      });
  }

  submit() {
    const statusName = this.submitForm.controls['projectStage'].value;
    const body = this.submitForm.controls['body'].value.textControl;
    const requests = this.selectedProjects.map(selectedProject => {
      const interaction: ProjectConnectionInteraction = <ProjectConnectionInteraction>{};

      interaction.sentDateTime = new Date();
      interaction.message = body;
      interaction.recipientOrganizationId = selectedProject.developerId;
      interaction.projectConnectionId = selectedProject.projectStageConnectionId;
      interaction.creatorUser = { id: this.user.id };
      if (statusName) {
        interaction.projectStage = statusName;
      }

      return this._projectStageOverviewService.sendInteractionDirect(interaction, selectedProject.id);
    });

    forkJoin([...requests])
    .subscribe(res => {
      this.submitForm.reset({
        projectStage: '',
        body: ''
      });
      this.reload.emit(true);
    });
  }

  private createForm() {
    this.submitForm = new UntypedFormGroup({
      projectStage: new UntypedFormControl(''),
      body: new UntypedFormControl('')
    });
  }
}
