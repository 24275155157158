<div class="status">
  <ng-container *ngIf="!status; else SomeReason">
    <!-- for filter -->
    <ng-container *ngIf="isFilter; else NotFilter">
      <div class="reason">
        {{'project-connections.blank' | translate}}
      </div>
    </ng-container>
    <!-- for cell -->
    <ng-template #NotFilter>
      <div title="{{reason[verificationStatus.NOT_VERIFIED]}}">&nbsp;</div>
    </ng-template>
  </ng-container>

  <ng-template #SomeReason>
    <div title="{{reason[status]}}">
      <ng-container *ngIf="status === verificationStatus.VERIFIED">
        <i class="fa fa-check" aria-hidden="true"></i>
      </ng-container>
      <ng-container *ngIf="status === verificationStatus.PAYMENT_NOT_FOUND ||
                           status === verificationStatus.ERROR_DURING_VERIFICATION ||
                           status === verificationStatus.METER_READING_NOT_FOUND ||
                           status === verificationStatus.DATA_OUT_OF_DATE_RANGE ||
                           status === verificationStatus.DATA_NOT_FOUND ||
                           status === verificationStatus.PROGRAM_NOT_FOUND ||
                           status === verificationStatus.VERIFICATION_RULE_NOT_FOUND">
        <i class="fa fa-question" aria-hidden="true"></i>
      </ng-container>
      <ng-container *ngIf="status === verificationStatus.VERIFICATION_ERROR ||
                           status === verificationStatus.NOT_VERIFIED ||
                           status === verificationStatus.DID_NOT_MEET_REQUIREMENTS">
        <i class="fa fa-times" aria-hidden="true"></i>
      </ng-container>
      <div class="reason">
        <ng-container *ngIf="status">
          {{reason[status]}}
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
