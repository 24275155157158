import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';
import { ReferenceType } from '@shared/references.model';
import { Country } from '@shared/services/country/country.model';
import { ConnectionResponsibility } from '../connection-responsibility.enum';
import { FinanceDealStatus } from '../finance-deal-status.enum';
import { Offtaker } from '../offtaker.model';
import { ProjectStage } from '../project-stage.enum';
import { ProjectSetFinancialModel } from './edit/financial-model/project-set-financial-model.model';
import { ProjectCluster } from './project-cluster.model';

export class ProjectSet extends BaseModel<ProjectSet> {
  averageAnnualDegradation: number;
  averageContractLength: number;
  averageCustomerConsumption: number;
  averageDieselConsumptionPrice: number;
  averageGridConsumptionPrice: number;
  averagePvProduction: number;
  averageSystemSize: number;
  averageTariff: number;
  batteryStorage: boolean;
  comments: string;
  connectionResponsibility: ConnectionResponsibility;
  consumptionQ1: number;
  consumptionQ2: number;
  consumptionQ3: number;
  consumptionQ4: number;
  country: Country;
  created: Date;
  currencyType: ReferenceType;
  definedProjectClusters: ProjectCluster[];
  developerOrganization: Organization;
  dieselGenerator: boolean;
  estimatedStartDate: Date;
  evenConsumptionPerQuarter: boolean;
  evenProductionPerQuarter: boolean;
  evenSpend: boolean;
  feedInTariff: number;
  gridConsumptionPercentage: number;
  gridTied: boolean;
  insuranceExpense: number;
  monthsToOperation: number;
  name: string;
  offtaker: Offtaker;
  offtakerConsumptionRequired: boolean;
  omExpense: number;
  opexInflation: number;
  passThroughInflation: boolean;
  projectSetFinancialModel: ProjectSetFinancialModel;
  pvProductionQ1: number;
  pvProductionQ2: number;
  pvProductionQ3: number;
  pvProductionQ4: number;
  spendM1: number;
  spendM2: number;
  spendM3: number;
  spendM4: number;
  spendM5: number;
  spendM6: number;
  spendM7: number;
  spendM8: number;
  spendM9: number;
  spendM10: number;
  spendM11: number;
  spendM12: number;
  stage: ProjectStage;
  strandedProductionPercentage: number;
  takeOrPay: boolean;
  tariffEscalation: number;
  totalCapex: number;
  totalProjects: number;
  updated: Date;

  gridConfiguration: string[];  //transient
  portfolioName: string;  //transient
  portfolioId: string;  //transient
  portfolioConnectionId: string;  //transient
  portfolioSharedWithOdyssey: boolean;  //transient
  programName: string;  //transient
  programId: string;  //transient
  status: FinanceDealStatus; //transient

  constructor(projectSet: any) {
    super();
    this.id = projectSet.id;
    this.averageAnnualDegradation = projectSet.averageAnnualDegradation;
    this.averageContractLength = projectSet.averageContractLength;
    this.averageCustomerConsumption = projectSet.averageCustomerConsumption;
    this.averageDieselConsumptionPrice = projectSet.averageDieselConsumptionPrice;
    this.averageGridConsumptionPrice = projectSet.averageGridConsumptionPrice;
    this.averagePvProduction = projectSet.averagePvProduction;
    this.averageSystemSize = projectSet.averageSystemSize;
    this.averageTariff = projectSet.averageTariff;
    this.batteryStorage = projectSet.batteryStorage;
    this.comments = projectSet.comments;
    this.connectionResponsibility = projectSet.connectionResponsibility;
    this.consumptionQ1 = projectSet.consumptionQ1;
    this.consumptionQ2 = projectSet.consumptionQ2;
    this.consumptionQ3 = projectSet.consumptionQ3;
    this.consumptionQ4 = projectSet.consumptionQ4;
    this.country = projectSet.country;
    this.created = projectSet.created;
    this.currencyType = projectSet.currencyType;
    this.definedProjectClusters = projectSet.definedProjectClusters;
    this.developerOrganization = projectSet.developerOrganization;
    this.dieselGenerator = projectSet.dieselGenerator;
    this.estimatedStartDate = projectSet.estimatedStartDate;
    this.evenConsumptionPerQuarter = projectSet.evenConsumptionPerQuarter;
    this.evenProductionPerQuarter = projectSet.evenProductionPerQuarter;
    this.evenSpend = projectSet.evenSpend;
    this.feedInTariff = projectSet.feedInTariff;
    this.gridConsumptionPercentage = projectSet.gridConsumptionPercentage;
    this.gridTied = projectSet.gridTied;
    this.insuranceExpense = projectSet.insuranceExpense;
    this.monthsToOperation = projectSet.monthsToOperation;
    this.name = projectSet.name;
    this.offtaker = projectSet.offtaker;
    this.offtakerConsumptionRequired = projectSet.offtakerConsumptionRequired;
    this.omExpense = projectSet.omExpense;
    this.opexInflation = projectSet.opexInflation;
    this.passThroughInflation = projectSet.passThroughInflation;
    this.projectSetFinancialModel = new ProjectSetFinancialModel(projectSet?.projectSetFinancialModel);
    this.pvProductionQ1 = projectSet.pvProductionQ1;
    this.pvProductionQ2 = projectSet.pvProductionQ2;
    this.pvProductionQ3 = projectSet.pvProductionQ3;
    this.pvProductionQ4 = projectSet.pvProductionQ4;
    this.spendM1 = projectSet.spendM1;
    this.spendM10 = projectSet.spendM10;
    this.spendM11 = projectSet.spendM11;
    this.spendM12 = projectSet.spendM12;
    this.spendM2 = projectSet.spendM2;
    this.spendM3 = projectSet.spendM3;
    this.spendM4 = projectSet.spendM4;
    this.spendM5 = projectSet.spendM5;
    this.spendM6 = projectSet.spendM6;
    this.spendM7 = projectSet.spendM7;
    this.spendM8 = projectSet.spendM8;
    this.spendM9 = projectSet.spendM9;
    this.stage = projectSet.stage;
    this.strandedProductionPercentage = projectSet.strandedProductionPercentage;
    this.takeOrPay = projectSet.takeOrPay;
    this.tariffEscalation = projectSet.tariffEscalation;
    this.totalCapex = projectSet.totalCapex;
    this.totalProjects = projectSet.totalProjects;
    this.updated = projectSet.updated;

    this.gridConfiguration = this.buildGridConfiguration(projectSet);
  }

  private buildGridConfiguration(projectSet: ProjectSet) {
    const result = [];
    if (projectSet.gridTied) {
      result.push('gridTied');
    }
    if (projectSet.batteryStorage) {
      result.push('batteryStorage');
    }
    if (projectSet.dieselGenerator) {
      result.push('dieselGenerator');
    }
    return result;
  }
}
