export enum MILESTONE_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
};

export enum MILESTONE_STATUS_LABEL {
  NOT_STARTED = 'milestones.statuses.NOT_STARTED',
  IN_PROGRESS = 'milestones.statuses.IN_PROGRESS',
  COMPLETED = 'milestones.statuses.COMPLETED'
};

export enum MILESTONE_STATUS_TEXT_COLOR {
  NOT_STARTED = 'var(--color-font-tertiary)',
  IN_PROGRESS = 'var(--color-font-in-progress)',
  COMPLETED = 'var(--color-font-success)'
};

export enum MILESTONE_STATUS_ICON {
  NOT_STARTED = 'grey/check.svg',
  IN_PROGRESS = 'blue/clock.svg',
  COMPLETED = 'green/check-large.svg'
};

export enum MILESTONE_STATUS_COLOR {
  NOT_STARTED = 'grey',
  IN_PROGRESS = 'blue',
  COMPLETED = 'green'
};

export enum MILESTONE_STATUS_TITLE_DECORATION {
  NOT_STARTED = 'none',
  IN_PROGRESS = 'none',
  COMPLETED = 'line-through'
};
