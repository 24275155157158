import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnConfigFactory } from '@project/shared/grid/column-config-factory';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class OfftakersListGridSettingService extends BaseAgGridSettingsService {
  private column = [];
  private currencyTypes = {};
  private gridConfigurationTypes = [];
  private offtaker = {};
  private stages = {};
  private responses = {};

  public columnValues = [];
  public operatorValues = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _translateService: TranslateService) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('offtaker.column');
    this.currencyTypes = _agGridServicesWrapperService._translateService.instant('currency-type');
    this.gridConfigurationTypes = _agGridServicesWrapperService._translateService.instant('offtaker.grid-configuration');
    this.offtaker = _agGridServicesWrapperService._translateService.instant('offtaker');
    this.stages = _agGridServicesWrapperService._translateService.instant('offtaker.project-stage');
    this.responses = _agGridServicesWrapperService._translateService.instant('offtaker.responses');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      autoGroupColumnDef: {
        headerName: this.column['customer-name']
      },
      columnDefs: this.getColumnSetting(),
      components: {
        agDateInput: DateFilterComponent
      },
      context: {
        currentLang: this.currentLang
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true
      },
      enableRangeSelection: true,
      groupDefaultExpanded: 1,
      groupDisplayType: 'singleColumn',
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      rowHeight: 80,
      rowMultiSelectWithClick: true,
      rowSelection: 'multiple',
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum', 'avg'] // options: 'count', 'sum', 'min', 'max', 'avg'
            },
            align: 'right'
          }
        ]
      },
      suppressRowClickSelection: true,
    };
  }

  public getColumnSetting(): any {
    return [
      {
        headerName: this.column['customer-name'],
        headerTooltip: this.column['customer-name'],
        field: 'name',
        width: 300,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return `<span style="text-decoration: underline">${ params?.value }</span>`;
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['business-type'],
        headerTooltip: this.column['customer-name'],
        field: 'type',
        width: 150,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellRenderer: (params) => (
          this._translateService.instant(`offtaker.offtaker-type.${params.value}`)
        )
      },
      {
        headerName: this.column['email'],
        headerTooltip: this.column['email'],
        field: 'email',
        minWidth: 300,
        flex: 1,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['projects-associated'],
        headerTooltip: this.column['projects-associated'],
        field: 'projectCount',
        width: 150,
        unSortIcon: true,
        enableValue: true,
        suppressSizeToFit: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
    ];
  }
}
