import { Component, OnInit, Input } from '@angular/core';
import { SliderItem } from './slider/slider-item';
import { Subject } from 'rxjs';
import { SystemLoadScaleName } from './system-load-scale-name.enum';
import { SystemLoadService } from '@project/detail/load/shared/system-load.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oes-show-scale-load',
  templateUrl: './show-scale-load.component.html',
  styleUrls: ['./show-scale-load.component.scss']
})
export class ShowScaleLoadComponent implements OnInit {
  @Input() readOnly: boolean;
  @Input() disableSlider = false;

  disabledAcceptButton = true;
  maxValue = 200;
  minValue = 1;
  showSlider = true;
  sliderItems: SliderItem[];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _systemLoadService: SystemLoadService) {
  }

  ngOnInit() {
    this.disabledAcceptButton = true;
    this.subscribeChartDataStatus();
  }

  private subscribeChartDataStatus() {
    this._systemLoadService.chartDataLoadedSubject$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((status: boolean) => {
      if (status) {
        this.setupScaleItems();
      }
    });
  }

  // Take scale values and maximum value
  private setupScaleItems() {
    const systemLoad = this._systemLoadService.systemLoad;
    if (systemLoad) {
      this.maxValue = systemLoad.systemLoadParameters.maximumLoadScaleFactor ?
                        systemLoad.systemLoadParameters.maximumLoadScaleFactor : 200;
      const data = Object.keys(systemLoad.systemLoadParameters)
      .filter(key => {
        return key !== 'maximumLoadScaleFactor' && this._systemLoadService.isActiveCustomerType(key);
      })
      .map(key => {
        return <SliderItem>{
          scale: systemLoad.systemLoadParameters[key] ? systemLoad.systemLoadParameters[key] : 100,
          isDisabled: false,
          name: SystemLoadScaleName[key]
        };
      });
      this.sliderItems = this._systemLoadService.sort(data);

      // push initial values
      this._systemLoadService.maximumUpdate(this.maxValue);
      this._systemLoadService.slideUpdate(<SliderItem[]>this.sliderItems);
    }
  }

  public updated(sliderItems: SliderItem[]) {
    this._systemLoadService.slideUpdate(sliderItems);
    this.disabledAcceptButton = false;
  }

  // expand/collapse slider
  public toggleScaler() {
    this.showSlider = this.showSlider ? false : true;
  }

  // Set Maximum field
  public updateMaxValue(value: string) {
    this.maxValue = Number(this.inputPercent(value));
    this._systemLoadService.maximumUpdate(this.maxValue);
    this.disabledAcceptButton = false;
  }

  // remove % from input value
  private inputPercent(value: string) {
    return value.replace(/[|&;$%@"<>()+,.]/g, '');
  }

  // Restore to 100% button
  public restoreScales() {
    this.sliderItems.forEach((value, index) => {
      this.sliderItems[index].scale = 100;
    });
    this.disabledAcceptButton = false;
  }

  //  Accept as New Load button
  public saveScales() {
    this._systemLoadService.acceptAsNewLoad(true);
    this.disabledAcceptButton = true;
  }
}
