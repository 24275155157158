import { HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadItemLibraryService extends BaseRestApiService {
  private library = environment.serverUri + `api/${environment.apiVersion}/organization/library/load-items`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

   public list(params: HttpParams): Observable<any[]> {
    return this.get<any[]>(this.library, params);
  }
}
