
import { Component, OnInit } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { VendorInformation } from '@organization-management/organization/vendor-information/vendor-information.model';
import { take, catchError } from 'rxjs/operators';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';
import { of } from 'rxjs';

@Component({
  selector: 'oes-vendor-profiles',
  styleUrls: ['vendor-profiles.component.scss'],
  templateUrl: 'vendor-profiles.component.html',
})
export class VendorProfilesComponent implements OnInit {
  public organizations: Organization[];
  public vendorSize = 0;
  constructor(private _projectGroupService: ProjectGroupService,
              private _dynamicHomeService: DynamicHomeService) {
  }

  ngOnInit() {
    this._dynamicHomeService.saveUrl(true);
    this.getOrganizations();
  }

  getOrganizations() {
    this._projectGroupService.vendorList()
    .subscribe((organizations: Organization[]) => {
      if (organizations && organizations.length > 0) {
        const vendors = organizations.filter(organization => organization.vendor);
        this.vendorSize = vendors.length;
        this.organizations = [];
        // TODO: backend should add vendorInformation at a new vendors endpoint
        vendors.map((organization: Organization) => {
          this._projectGroupService.vendorInformation(organization.id)
          .pipe(
            take(1),
            // Note: QA fails some vendor info request.
            catchError(() => of([]))
          )
          .subscribe((vendorInformation: VendorInformation) => {
            const vendorOrganization = organization;
            vendorOrganization['vendorInformation'] = vendorInformation;
            this.organizations.push(vendorOrganization);
          });
        });
      }
    });
  }
}
