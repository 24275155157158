import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';
import { ManageOfftakersApiConstants } from './manage-offtakers-api.constant';
import { Offtaker } from '@project/offtakers/offtaker.model';

@Injectable({
  providedIn: 'root'
})
export class ManageOfftakersService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  findAllProjectSets(): Observable<any> {
    return this.get<ProjectSet[]>(ManageOfftakersApiConstants.findAllProjectSets());
  }

  findAllOfftakers(): Observable<any> {
    return this.get<Offtaker[]>(ManageOfftakersApiConstants.findAllOfftakers());
  }
}
