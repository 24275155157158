import { Component, OnDestroy } from '@angular/core';
import { CountriesMapService } from './countries-map.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'oes-countries-map',
  templateUrl: './countries-map.component.html',
  styleUrls: ['./countries-map.component.scss']
})
export class CountriesMapComponent implements OnDestroy {
  countryClasses: any;
  allCountries = false;
  mapWindow = '200 70 1725 860';
  mapWidth = '100%';
  allInAfrica = false;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _countriesMapService: CountriesMapService) {
    this._countriesMapService.countryClasses$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(cc => {
      this.countryClasses = cc;
    });

    this._countriesMapService.allCountries$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(all => {
      this.allCountries = all;
    });

    this._countriesMapService.allSelectedCountriesAreInAfrica$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(allInAfrica => {
      if (allInAfrica) {
        this.allInAfrica = true;
        this.mapWindow = '872 326 400 475';
        this.mapWidth = '60%';
      } else {
        this.allInAfrica = false;
        this.mapWindow = '200 70 1725 860';
        this.mapWidth = '100%';
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
