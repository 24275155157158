<p class="wrapper">
  <input
    autocomplete="off"
    [(ngModel)]="calendar"
    [placeholder]="'calendar.format' | translate"
    bsDatepicker
    [bsConfig]="bsConfig"
    placement="bottom"
    readonly>
</p>
