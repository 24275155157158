import {BaseModel} from '../../base.model';

export class CurrencyExchange extends BaseModel<CurrencyExchange> {
  id: string;
  code: string;
  exchangeRate: number;
  quoteDateTime: Date;

  constructor(currencyExchangeInfo: any) {
    super();
    this.id = currencyExchangeInfo.id;
    this.code = currencyExchangeInfo.code;
    this.exchangeRate = currencyExchangeInfo.exchangeRate;
    this.quoteDateTime = currencyExchangeInfo.quoteDateTime;
  }
}
