import { Injectable } from '@angular/core';
import { ProjectFinancialModel } from '@project/detail/financials/project-financial-model.model';
import { ProjectFinancialModelService } from '@project/detail/financials/project-financial-model.service';
import { BehaviorSubject, Observable } from 'rxjs';

import { PROJECT_PAGE } from './project-page.enum';

@Injectable({
  providedIn: 'root'
})
export class ProjectPageStatusService {
  private statuses = {};
  private _statusUpdatedSubject = new BehaviorSubject<boolean>(true);
  public statusUpdated$: Observable<boolean> = this._statusUpdatedSubject.asObservable();
  private _financialModelUpdatedSubject = new BehaviorSubject<boolean>(true);
  // To share ProjectFinancialModel with the project header
  public financialModelUpdated$: Observable<boolean> = this._financialModelUpdatedSubject.asObservable();
  private _isDirty = false;
  private _projectFinancialModel: ProjectFinancialModel;
  private _initialProjectFinancialModel: ProjectFinancialModel;

  constructor(private _projectFinancialModelService: ProjectFinancialModelService) {
    this.initializePageStatus();
  }

  private initializePageStatus() {
    this.statuses[PROJECT_PAGE.SITE] = true;
    this.statuses[PROJECT_PAGE.LOAD] = true;
    this.statuses[PROJECT_PAGE.GENERATION_DESIGN] = true;
    this.statuses[PROJECT_PAGE.DISTRIBUTION_DESIGN] = true;
    this.statuses[PROJECT_PAGE.COSTS] = true;
    this.statuses[PROJECT_PAGE.REVENUE] = true;
    this.statuses[PROJECT_PAGE.FINANCIAL] = true;
  }

  public setPageUpdated(pageId: PROJECT_PAGE) {
    this.statuses[pageId] = false;
    this._isDirty = true;
    this._statusUpdatedSubject.next(false);
  }

  set isDirty(status: boolean) {
    this._isDirty = status;
  }

  get isDirty() {
    return this._isDirty;
  }

  set initialProjectFinancialModel(projectFinancialModel: ProjectFinancialModel) {
    this._initialProjectFinancialModel = new ProjectFinancialModel(projectFinancialModel);
  }

  set projectFinancialModel(projectFinancialModel: ProjectFinancialModel) {
    this._projectFinancialModel = projectFinancialModel;
    this._isDirty = false;
    this._financialModelUpdatedSubject.next(true);
  }

  get projectFinancialModel(): ProjectFinancialModel {
    return this._projectFinancialModel;
  }

  /**
   * project-navigation calls this.
   * @param projectId: string
   */
  public getFinancialModel(projectId: string) {
    this._projectFinancialModelService.getFinancialModel(projectId)
    .subscribe((projectFinancialModel: ProjectFinancialModel) => {
      this.projectFinancialModel = projectFinancialModel;
      this.initialProjectFinancialModel = projectFinancialModel;
    });
  }

  public updateFinancialModel(projectId: string, async = false) {
    return this._projectFinancialModelService.saveFinancialModel(projectId, this._projectFinancialModel, async);
  }

  public resetFinancialModel(projectId: string) {
    return this._projectFinancialModelService.resetFinancialModel(projectId, this._projectFinancialModel.id);
  }

  public resetLocalFinancialModel() {
    this._projectFinancialModel = new ProjectFinancialModel(this._initialProjectFinancialModel);
  }

  public modelUpdated() {
    return !(this.projectFinancialModel.targetYear === this._initialProjectFinancialModel.targetYear &&
           this.projectFinancialModel.projectLife === this._initialProjectFinancialModel.projectLife &&
           this.projectFinancialModel.financialModelTemplate === this._initialProjectFinancialModel.financialModelTemplate &&
           this.projectFinancialModel.tariffChangePercent === this._initialProjectFinancialModel.tariffChangePercent &&
           this.projectFinancialModel.expectedLoadSensitivity === this._initialProjectFinancialModel.expectedLoadSensitivity &&
           this.projectFinancialModel.nonTechnicalRevenueLossSensitivity === this._initialProjectFinancialModel.nonTechnicalRevenueLossSensitivity &&
           this.projectFinancialModel.costOverRunSensitivity === this._initialProjectFinancialModel.costOverRunSensitivity &&
           this.projectFinancialModel.subsidyPercent === this._initialProjectFinancialModel.subsidyPercent &&
           this.projectFinancialModel.subsidyAmountPerConnection === this._initialProjectFinancialModel.subsidyAmountPerConnection &&
           this.projectFinancialModel.subsidyType === this._initialProjectFinancialModel.subsidyType &&
           this.projectFinancialModel.discountRate === this._initialProjectFinancialModel.discountRate &&
           this.projectFinancialModel.currencyDepreciation === this._initialProjectFinancialModel.currencyDepreciation);
  }
}
