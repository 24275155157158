import { Injectable } from "@angular/core";

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { GridState } from '@shared/ag-grid/gird-state';

import { ColDef, ColumnApi, GridApi, GridOptions } from "ag-grid-community";


@Injectable({
  providedIn: 'root'
})
export class InstallerListGridSettingService extends BaseAgGridSettingsService {
  public currentGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public _columnApi: ColumnApi;
  public _gridApi: GridApi;
  public _gridStateStorageKey: string;

  private column = {};

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('installers.column');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(),
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      suppressRowClickSelection: true,
      rowMultiSelectWithClick: false,
      getRowHeight: (params) => {
        if (params.node.rowHeight > 80) {
          return params.node.rowHeight + 10;
        }
        return 80;
      },
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
    };
  }

  public getColumnSetting(): ColDef[] {
    return [
      {
        headerName: this.column['name'],
        field: 'name',
        headerTooltip: this.column['project-name'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        minWidth: 200,
        width: 450,
        flex: 1,
        filter: 'agTextColumnFilter',
        cellRenderer: (params) => {
          if (params.data) {
            return `<span style="text-decoration: underline">${params.data.name}</span>`;
          }
        },
        filterParams: {
          caseSensitive: false,
          clearButton: true
        },
        suppressSizeToFit: true,
        resizable: true
      },
      {
        headerName: this.column['email'],
        field: 'email',
        headerTooltip: this.column['email'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        minWidth: 250,
        width: 450,
        filter: 'agTextColumnFilter',
        filterParams: {
          caseSensitive: false,
          clearButton: true
        },
        suppressSizeToFit: true,
        resizable: true
      },
      {
        headerName: this.column['projects-associated-with'],
        field: 'projectInstallersCount',
        headerTooltip: this.column['projects-associated-with'],
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        minWidth: 200,
        suppressSizeToFit: true,
        resizable: true,
      }
    ];
  }
}
