<div
  bsModal
  #modalTemplate="bs-modal"
  class="unsaved-changes-dialog"
  [config]="config">
  <div class="modal-header d-flex flex-row justify-content-between">
    <h4 class="modal-title">{{'project-group.add-header'| translate}}</h4>
    <button
      type="button"
      class="close"
      (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="portfolioFormGroup">
      <div class="form-group">
        <label class="required" for="name">{{'project-group.name'| translate}}</label>
        <input type="text"
               class="form-control"
               name="name"
               id="name"
               placeholder="{{'project-group.name'| translate}}"
               formControlName="name">
      </div>
      <label class="required">{{'project-group.sub-title' | translate}}</label>
      <label for="financeDeal" class="input-control--sm input-control--checkbox">
        {{'project-group.finance-deal' | translate}}
        <input type="checkbox"
               name="financeDeal"
               formControlName="portfolio"
               id="financeDeal">
        <div class="input-control__indicator--sm"></div>
      </label>
      <!-- DT-3965
      <label for="quote" class="input-control--sm input-control--checkbox">
        {{'project-group.quote' | translate}}
        <input type="checkbox"
               name="quote"
               id="quote"
               formControlName="rfq">
        <div class="input-control__indicator--sm"></div>
      </label> -->
      <br/>
    </form>
  </div>

  <div class="modal-footer d-flex flex-row justify-content-end">
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="isDisable()"
      (click)="onSubmit()">
      {{'buttons.create' | translate}}
    </button>
  </div>
</div>
