import {ProjectDistributionDesignItem} from './design-item/project-distribution-design-item.model';
import {BaseModel} from '@shared/base.model';
import { SystemLoad } from '@project/detail/load/shared/system-load.model';

export class ProjectDistributionDesign extends BaseModel<ProjectDistributionDesign> {
  name: string;
  totalCost: number;
  selected: boolean;
  projectDistributionDesignItems: Array<ProjectDistributionDesignItem>;
  systemLoad: SystemLoad;

  constructor(projectDistributionDesignInfo: any) {
    super();
    this.id = projectDistributionDesignInfo.id;
    this.name = projectDistributionDesignInfo.name;
    this.selected = projectDistributionDesignInfo.selected;
    this.totalCost = projectDistributionDesignInfo.totalCost;
    this.projectDistributionDesignItems = [];

    if (projectDistributionDesignInfo.projectDistributionDesignItems != null && projectDistributionDesignInfo.projectDistributionDesignItems.length > 0) {
      for (let i = 0; i < projectDistributionDesignInfo.projectDistributionDesignItems.length; i++) {
        this.projectDistributionDesignItems.push(new ProjectDistributionDesignItem(projectDistributionDesignInfo.projectDistributionDesignItems[i]));
      }
    }
    this.systemLoad = projectDistributionDesignInfo.systemLoad;
  }
}
