import { Component, OnInit, Input } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { InvestorInformation } from '@organization-management/organization/investor-information/investor-information.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { CountriesMapService } from '../countries-map/countries-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Component({
  selector: 'oes-investor-profile',
  templateUrl: './investor-profile.component.html',
  styleUrls: ['./investor-profile.component.scss'],
})
export class InvestorProfileComponent implements OnInit {
  @Input() id: string;
  organization: Organization;
  public investorInformation: InvestorInformation;
  countries: string[];
  logoUri: string;
  capitalOffering: string[];
  sectorActivities: string[];
  allAfrica: boolean;

  private ngUnsubscribe: Subject<any> = new Subject();


  constructor(private _countriesMapService: CountriesMapService,
              private _projectGroupService: ProjectGroupService) {
    this._countriesMapService.allSelectedCountriesAreInAfrica$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(allAfrica => {
      this.allAfrica = allAfrica;
    });
  }

  ngOnInit() {
    this.getInvestorProfile();
  }

  private getInvestorProfile() {
    this._projectGroupService.investorDetail(this.id)
      .subscribe((organization: Organization) => {
        this.organization = organization;
        this.getInvestorInfo(organization.id);
        if (this.organization.logoImage && this.organization.logoImage.id) {
          this.logoUri = `${environment.organizationCdnUri}/${this.organization.logoImage.id}`;
        }
      });
  }

  private getInvestorInfo(organizationId: string) {
    this._projectGroupService.investorInformation(organizationId)
    .subscribe((investorInformation: InvestorInformation) => {
      this.investorInformation = investorInformation;
      this.countries = investorInformation.countries.map(country => country.name).sort();
      if (this.countries && this.countries.length > 10) {
        const display = this.countries.slice(0, 10);
        display.push(`and ${this.countries.length - 10} more...`);
        this.countries = display;
      }
      this._countriesMapService.buildCountryClasses(investorInformation.countries, investorInformation.allCountries);
      this.getCapitalOffering();
      this.getSectorActivities();
    });
  }

  private getCapitalOffering() {
    this.capitalOffering = [];
    if (this.investorInformation.grantCapital) {
      this.capitalOffering.push('capital.grant');
    }
    if (this.investorInformation.projectEquityCapital) {
      this.capitalOffering.push('capital.project-equity');
    }
    if (this.investorInformation.corporateEquityCapital) {
      this.capitalOffering.push('capital.corporate-equity');
    }
    if (this.investorInformation.debtCapital) {
      this.capitalOffering.push('capital.debt');
    }
    if (this.investorInformation.concessionaryDebtCapital) {
      this.capitalOffering.push('capital.concessionary-debt');
    }
  }

  private getSectorActivities() {
    this.sectorActivities = [];
    if (this.investorInformation.financeActivities) {
      this.sectorActivities.push('activities.finance');
    }
    if (this.investorInformation.projectDevelopmentActivities) {
      this.sectorActivities.push('activities.project-development');
    }
    if (this.investorInformation.supplyActivities) {
      this.sectorActivities.push('activities.supply');
    }
    if (this.investorInformation.epcActivities) {
      this.sectorActivities.push('activities.epc');
    }
  }
}
