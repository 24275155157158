<ng-container
  *ngIf="!editorDisabled; else readOnly"
  class="quill-reactive">
  <div
    class="quill-container"
    [formGroup]="quillFormGroup">
    <quill-editor
      class="textarea-fresh"
      formControlName="textControl"
      [placeholder]="placeholder"
      [maxLength]="characterLimit"
      [required]="required"
      [styles]="style"
      [sanitize]="true"
      (onEditorChanged)="contentChanged($event)"
      (onEditorCreated)="editorInit($event)"
      [modules]="option">
    </quill-editor>
    <div>
      <span [ngClass]="{'alert-danger': maxLengthError}">{{characters}}</span> / {{characterLimit}}  {{'quill.characters' | translate}}
    </div>
    <ng-container *ngIf="maxLengthError">
      <div class="error-message alert-danger">{{'form.error.max-length' | translate : {limit: characterLimit} }}</div>
    </ng-container>
    <ng-container *ngIf="requiredError">
      <div class="error-message alert-danger">{{'form.error.required' | translate }}</div>
    </ng-container>
  </div>
</ng-container>

<ng-template #readOnly>
  <quill-view-html [content]="initialValue ? initialValue : ''"></quill-view-html>
</ng-template>
