import { environment } from '@environments/environment';

export class EvaluationApiConstants {
  public static evaluations = environment.evalServerUri + `api/${environment.apiVersion}/evaluations`;

  public static evaluation = {
    detail: (evaluatedEntityId: string) => {
      return `${EvaluationApiConstants.evaluations}/${evaluatedEntityId}`;
    },
    update: (evaluatedEntityId: string) => {
      return `${EvaluationApiConstants.evaluations}/${evaluatedEntityId}`;
    },
    delete: (evaluatedEntityId: string) => {
      return `${EvaluationApiConstants.evaluations}/${evaluatedEntityId}`;
    },
    create: () => {
      return `${EvaluationApiConstants.evaluations}`;
    },
    find: () => {
      return `${EvaluationApiConstants.evaluations}`;
    },
    adminFindByConfiguredEntityId: (configuredEntityId: string) => {
      return `${EvaluationApiConstants.evaluations}/admin/configuredEntity/${configuredEntityId}`;
    },
    findByConfiguredEntityId: (configuredEntityId: string) => {
      return `${EvaluationApiConstants.evaluations}/configuredEntity/${configuredEntityId}`;
    },
    adminFindByEvaluatedEntityId: (evaluatedEntityId: string) => {
      return `${EvaluationApiConstants.evaluations}/admin/evaluatedEntity/${evaluatedEntityId}`;
    }
  };
}
