<header>
  <div class="go-back" (click)="emitCloseEvent()">
    <div class="icon-container">
      <img src="/assets/icons/line-icons/shared/orange/arrow-left.svg" width="15px" />
    </div>
    {{ 'offtaker.project-set-edit.back-to-customer' | translate }}
  </div>

  <h2>{{ 'offtaker.project-set-edit.site-group' | translate }} #{{ projectSet?.name }} </h2>
</header>

<div class="modal-body">
  <div class="project-set-metrics">
    <h5>{{ 'offtaker.project-set-edit.group-configuration' | translate }}</h5>
    <div class="project-set-metrics-row">
      <div class="project-set-metrics-item">
        <h5>{{ 'offtaker.project-set-edit.customer' | translate }}</h5>
        <p>{{ offtaker?.name }}</p>
      </div>
      <div class="project-set-metrics-item">
        <h5>{{ 'offtaker.project-set-edit.location' | translate }}</h5>
        <p>{{ projectSet?.country.name }}</p>
      </div>
      <div class="project-set-metrics-item">
        <h5>{{ 'offtaker.project-set-edit.currency' | translate }}</h5>
        <p>{{ 'currency-type.' + projectSet?.currencyType | translate }}</p>
      </div>
    </div>

    <div class="project-set-metrics-row">
      <div class="project-set-metrics-item">
        <h5>{{ 'offtaker.project-set-edit.number-of-sites' | translate }}</h5>
        <p>{{ projectSet?.totalProjects }}</p>
      </div>
      <div class="project-set-metrics-item">
        <h5>{{ 'offtaker.project-set-edit.stage' | translate }}</h5>
        <p>{{ 'offtaker.project-stage.' + projectSet?.stage | translate }}</p>
      </div>
      <div class="project-set-metrics-item">
        <h5>{{ 'offtaker.project-set-edit.grid-configuration' | translate }}</h5>
        <p>
          <ng-container *ngFor="let gridConfigItem of projectSet?.gridConfiguration; let i = index;">
            <span *ngIf="i !== 0">, </span><span>{{ 'offtaker.grid-configuration.' + gridConfigItem | translate }}</span>
          </ng-container>
        </p>
      </div>
    </div>
  </div>

  <tabset>
    <tab [active]="activeTab === 'details'"
         (selectTab)="selectTab('details')"
         heading="{{ 'offtaker.project-set-edit.details' | translate }}"
         index="0">
      <ng-container *ngIf="activeTab === 'details'">
        <oes-project-set-details
          (close)="emitCloseEvent()"
          (reloadParent)="getProjectSet()"
          [readOnly]="readOnly"
          [projectSetId]="projectSetId">
        </oes-project-set-details>
      </ng-container>
    </tab>
    <ng-container *ngIf="projectSet?.created !== projectSet?.updated && projectSet?.stage === 'PRE_CONSTRUCTION'">
      <tab [active]="activeTab === 'financialModel'"
           (selectTab)="selectTab('financialModel')"
           heading="{{ 'offtaker.project-set-edit.financial-model' | translate }}"
           index="1">
        <ng-container *ngIf="activeTab === 'financialModel'">
          <oes-project-set-financial-model
            [projectSetId]="projectSetId"
            [offtaker]="offtaker">
          </oes-project-set-financial-model>
        </ng-container>
      </tab>
    </ng-container>
  </tabset>

</div>
