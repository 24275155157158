import { environment } from '@environments/environment';

export class GenerationDesignCostItemApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;

  public static list(projectId: string, generationDesignId: string, ) {
    return `${GenerationDesignCostItemApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/cost-items`;
  }
  public static create(projectId: string, generationDesignId: string, ) {
    return `${GenerationDesignCostItemApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/cost-items`;
  }
  public static detail(projectId: string, generationDesignId: string, costItemId: string) {
    return `${GenerationDesignCostItemApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/cost-items/${costItemId}`;
  }
  public static update(projectId: string, generationDesignId: string, costItemId: string) {
    return `${GenerationDesignCostItemApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/cost-items/${costItemId}`;
  }
  public static delete(projectId: string, generationDesignId: string, costItemId: string) {
    return `${GenerationDesignCostItemApiConstants.projects}/${projectId}/generation-designs/${generationDesignId}/cost-items/${costItemId}`;
  }
}
