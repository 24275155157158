import { Injectable } from '@angular/core';
import { DOCUMENT_TAG_TYPE } from './document-tag-type.enum';
import { RequestItem } from './models/request-item.model';
import { TagType } from './models/tag-type.model';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor() {}

  getSelectedOption(eventNode: any, orgId: string) {
    const docTagItemId = eventNode.data.associations[0] && eventNode.data.associations[0].index ? eventNode.data.associations[0].index : orgId;
    const docTagType = eventNode.data.associations[0] && eventNode.data.associations[0].tagType ? eventNode.data.associations[0].tagType : DOCUMENT_TAG_TYPE.ORGANIZATION;
    return {
      docTagItemId: docTagItemId,
      docTagType: docTagType,
      id: eventNode.id,
      name: eventNode.data.path,
    };
  }

  getRequestItems(selectedParentNodes: any, selectedOption: any): RequestItem[] {
    let selectedPath = '';
    if (selectedOption.id !== 'baseLevel') {
      selectedPath = selectedOption.name;
    }
    const items = selectedParentNodes.map(node => {
      const targetNode = this.findRootNode(node, selectedParentNodes);
      const context = targetNode.data.documentKey.key;
      return {
        sourceDocumentKey: node.data.documentKey,
        context: context,
        targetPath: selectedPath
      };
    });
    return items;
  }

  findRootNode(node, selectedParentNodes) {
    if (node.parent) {
      if (selectedParentNodes.includes(node.parent) && node.parent.id !== 'ROOT_NODE_ID') {
        return this.findRootNode(node.parent, selectedParentNodes);
      } else {
        return node;
      }
    }
  }

  getDestinationTagType(selectedOption: any): TagType {
    return <TagType> {
      docTagItemId: selectedOption.docTagItemId,
      docTagType: selectedOption.docTagType
    };
  }

  validateDragMove(event) {
    return event.overNode.data.documentKey.docType === 'PATH' &&
           event.node.parent !== event.overNode &&
           !event.node.allLeafChildren.includes(event.overNode);
  }
}
