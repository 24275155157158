import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-numeric-editor',
  templateUrl: './numeric-editor.component.html',
  styleUrls: ['./numeric-editor.component.scss']
})
export class NumericEditorComponent implements ICellEditorAngularComp {
  @ViewChild('input', {static: false, read: ViewContainerRef}) public input;

  value: number;

  private params: any;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value;
  }

  onKeyDown(event): void {
    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return (typeof event.which === 'undefined') ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    // accept control keys
    if (charStr === 'Backspace' || charStr === 'Delete' ||
        charStr === 'ArrowLeft' || charStr === 'ArrowRight' ||
        charStr === 'ArrowUp' || charStr === 'ArrowDown' ||
        charStr === 'Tab' || charStr === '.') {
      // this stops the grid from receiving the event and executing keyboard navigation
      event.stopPropagation();
      return true;
    }
    return this.isCharNumeric(charStr);
  }
}
