import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilesService } from '@shared/components/files/shared/files.service';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { uniqBy } from 'lodash';
import { IMultiSelectSettings } from 'ngx-bootstrap-multiselect';

@Component({
  selector: 'oes-file-tag-filter',
  templateUrl: './file-tag-filter.component.html',
  styleUrls: ['./file-tag-filter.component.scss'],
})
export class FileTagFilterComponent implements IFilterAngularComp {
  @ViewChild('tagSelect', { static: false }) tagSelect;

  field: string;
  filterParams: any = {};

  public dropdownSettings: IMultiSelectSettings = {
    enableSearch: true,
    selectionLimit: 5,
    minSelectionLimit: 0,
    checkedStyle: 'fontawesome',
    containerClasses: 'oes-tag-select-container',
    buttonClasses: 'oes-tag-select-button',
    itemClasses: 'oes-tag-select-item',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: false,
    showCheckAll: false,
    showUncheckAll: false,
    autoUnselect: true,
    closeOnSelect: true,
  };

  formGroup: UntypedFormGroup;
  selectedTags: string[] = [];

  tags: any;

  constructor(private _filesService: FilesService) {}

  agInit(params: IFilterParams): void {
    this.filterParams = params;
    let tags;
    this.field = params?.colDef?.field;
    switch (this.field) {
      case 'associations':
        tags = this._filesService.associationList.map((assoc) => {
          return {
            id: assoc.tag,
            name: assoc.tag,
          };
        });
        break;
      case 'programTags':
        tags = this._filesService.programList.map((assoc) => {
          return {
            id: assoc.tag,
            name: assoc.tag,
          };
        });
        break;
      case 'projectGroupTags':
        tags = this._filesService.portfolioList.map((assoc) => {
          return {
            id: assoc.tag,
            name: assoc.tag,
          };
        });
        break;
      case 'projectSetPortfolioTags':
        tags = this._filesService.ciPortfolioList.map((assoc) => {
          return {
            id: assoc.tag,
            name: assoc.tag,
          };
        });
        break;
      case 'projectTags':
        tags = this._filesService.projectList.map((assoc) => {
          return {
            id: assoc.tag,
            name: assoc.tag,
          };
        });
        break;
      default:
        break;
    }

    const uniqueTags = uniqBy(tags, 'name');
    const sortedTags = uniqueTags.sort((a: any, b: any) => {
      if (b.name.toLowerCase() < a.name.toLowerCase()) {
        return 1;
      }
      return -1;
    });
    this.tags = sortedTags;
  }

  doesFilterPass(params: IDoesFilterPassParams<any>): boolean {
    if (params.data) {
      const rowNames = params.data[this.field].map((assoc: any) => assoc.tag);
      let result: boolean = false;
      this.selectedTags.forEach((selectedTag) => {
        if (rowNames.includes(selectedTag)) {
          result = true;
        }
      });
      return result;
    }
    return false;
  }

  isFilterActive(): boolean {
    return this.selectedTags?.length > 0;
  }

  getModel() {
    return { values: this.selectedTags };
  }

  setModel(model: any) {
    if (!model) {
      this.clearTags();
    } else {
      this.selectedTags = model.values;
    }
  }

  onInputChanged(event) {
    const tag = event.name;
    if (!this.selectedTags?.includes(tag)) {
      this.selectedTags.push(tag);
    } else {
      const index = this.selectedTags?.findIndex((t) => t === tag);
      this.selectedTags.splice(index);
    }
    this.filterParams.filterChangedCallback();
    this.filterParams.context.gridService.inComponentSelectionChanged(
      'inComponentSelectionChanged'
    );
  }

  clearTags() {
    this.selectedTags = [];
    this.filterParams.filterChangedCallback();
  }
}
