import { Injectable } from '@angular/core';
import {BulkRevenueModelUpdateRequest} from './bulk-revenue-model-update-request.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BulkRevenueModelUpdateService extends BaseRestApiService {
  public organizationUri = environment.serverUri + `api/${environment.apiVersion}/organizations`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public apply(organizationId: string, request: BulkRevenueModelUpdateRequest): Observable<BulkRevenueModelUpdateRequest> {
    return this.put<BulkRevenueModelUpdateRequest>(`${this.organizationUri}/${organizationId}/revenue-assumptions`, JSON.stringify(request));
  }
}
