import { environment } from '@environments/environment';

export class ProjectSetPortfolioConnectionApiConstants {
  public static base = environment.serverUri + `api/${environment.apiVersion}/project-set-portfolios`;

  public static portfolios = {
    list: (projectSetPortfolioId: string) => {
      return `${ProjectSetPortfolioConnectionApiConstants.base}/${projectSetPortfolioId}/connections`;
    },
    create: (projectSetPortfolioId: string) => {
      return `${ProjectSetPortfolioConnectionApiConstants.base}/${projectSetPortfolioId}/connections`;
    },
    detail: (projectSetPortfolioId: string, projectSetPortfolioConnectionId: string) => {
      return `${ProjectSetPortfolioConnectionApiConstants.base}/${projectSetPortfolioId}/connections/${projectSetPortfolioConnectionId}`;
    },
    delete: (projectSetPortfolioId: string, projectSetPortfolioConnectionId: string) => {
      return `${ProjectSetPortfolioConnectionApiConstants.base}/${projectSetPortfolioId}/connections/${projectSetPortfolioConnectionId}`;
    }
  };
}
