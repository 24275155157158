import { Component, OnInit, Input } from '@angular/core';
import { FinanceIntroduction } from '@finance/developer-portfolio/shared/finance-introduction.model';

@Component({
  selector: 'oes-portfolio-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.scss']
})
export class PortfolioCustomerSummaryComponent implements OnInit {
  @Input() financeIntroduction: FinanceIntroduction;

  public orderedTiers: Array<Object> = null;
  public totalTierConnections = 0;

  constructor() { }

  ngOnInit() {
    this.getOrderedTiers();
    this.getTotalTierConnections();
  }

  private getOrderedTiers() {
    const tierOrder = ['RESIDENTIAL', 'COMMERCIAL', 'PUBLIC', 'PRODUCTIVE', 'ANCHOR'];
    this.orderedTiers = tierOrder.reduce((ordered, tier) => {
      const matchedTier = this.financeIntroduction.financeTiers.find(item => item.tierCategory === tier);
      if (matchedTier) {
        ordered.push(matchedTier);
      }
      return ordered;
    }, []);
  }

  public getTotalTierConnections() {
    this.totalTierConnections = this.financeIntroduction.financeTiers
    .filter(tier => tier.tierCategory !== 'INTERNAL_LOAD')
    .map(tier => tier.count)
    .reduce((previousValue, currentValue) => {
      return previousValue += currentValue;
    }, 0);
  }
}
