import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { EventService } from '@shared/event.service';
import { Organization } from '@shared/models/organization/organization.model';
import { ShareWithInvestorService } from './share-with-investor.service';
import { ThemesService } from '@shared/themes.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { FinanceConnectionStatusUpdateService } from '@finance/developer-portfolio/shared/finance-connection-status-update.service';
import { FinanceConnection } from '@finance/developer-portfolio/shared/finance-connection.model';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'oes-portfolio-share-with-investor',
  templateUrl: './share-with-investor.component.html',
  styleUrls: ['./share-with-investor.component.scss'],
  providers: [
    ShareWithInvestorService,
    FinanceConnectionStatusUpdateService
  ]
})
export class PortfolioShareWithInvestorComponent implements OnInit, OnDestroy {
  @ViewChild('connectWithInvestorModal', {static: false}) public connectWithInvestorModal: ModalDirective;

  @Input() projectGroup: ProjectGroup;
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showSaveUpdateButton: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentTheme = 'odyssey';
  filteredFinanceOrganizations: Organization[] = [];
  formGroup: UntypedFormGroup;

  private currentUser: User;
  private existingFinanceConnections: FinanceConnection[] = [];
  private financeOrganizations: Organization[];
  private ngUnsubscribe: Subject<any> = new Subject();
  private projectGroupId: string;

  constructor(private _eventService: EventService,
              private _themesService: ThemesService,
              private _shareWithInvestorService: ShareWithInvestorService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _financeConnectionStatusUpdateService: FinanceConnectionStatusUpdateService,
              private _projectGroupService: ProjectGroupService) {
  }

  ngOnInit() {
    this.currentTheme = this._themesService.getTheme();
    this.getCurrentUser();
    this.buildForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  openDialog(projectGroupId: string) {
    this.projectGroupId = projectGroupId;
    this.getInvestorsList();
  }

  closeDialog() {
    this.connectWithInvestorModal.hide();
    this.formGroup.controls['investorList'].setValue(undefined);
  }

  submitToInvestor() {
    const investorId = this.formGroup.controls['investorList'].value;
    const fc = new FinanceConnection({ financeOrganization: new Organization({ id: investorId }) });

    this._shareWithInvestorService.submitToInvestor(this.projectGroupId, fc)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((financeConnection: FinanceConnection) => {
        this.closeDialog();
        this._financeConnectionStatusUpdateService.releaseToInvestor(this.projectGroupId, financeConnection, this.currentUser)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: FinanceConnection[]) => {
            this._eventService.success(this._translateService.instant('success-message.portfolio-shared-with') + ' ' + result[0].financeOrganization.name);
            this.completed.emit(true);
          });
      });
  }

  private buildForm() {
    this.formGroup = new UntypedFormGroup({
      investorList: new UntypedFormControl(false)
    });
    this.formGroup.controls['investorList'].setValue(undefined);
  }

  private getCurrentUser() {
    this._userService.getCurrentUser().subscribe((user: User) => {
      this.currentUser = new User(user);
    });
  }

  private getInvestorsList() {
    this._projectGroupService.investorList()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((organizations: Organization[]) => {
      this.financeOrganizations = organizations;
      this.getFinanceConnections();
    });
  }

  private getFinanceConnections() {
    if (this.projectGroupId) {
      this._shareWithInvestorService.getFinanceConnections(this.projectGroupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((financeConnections: FinanceConnection[]) => {
        if (financeConnections?.length) {
          this.showSaveUpdateButton.emit(false);
          this.existingFinanceConnections = [];
          this.existingFinanceConnections = financeConnections;
        }
        this.filterSortInvestorList();
        this.connectWithInvestorModal.show();
      });
    }
  }

  private filterSortInvestorList() {
    const existingConnectionOrgIds =
    this.existingFinanceConnections.map((financeConnection: FinanceConnection) => financeConnection.financeOrganization.id);

    if (this.financeOrganizations) {
      this.filteredFinanceOrganizations =
        this.financeOrganizations
        .filter((organization: Organization) => organization.id !== this.currentUser.organization.id)
        .filter((organization: Organization) => !existingConnectionOrgIds.includes(organization.id));
    }
    this.filteredFinanceOrganizations = this.sortOrgsByName(this.filteredFinanceOrganizations);
  }

  private sortOrgsByName(organizations: Organization[]): Organization[] {
    return organizations.sort((a, b) => {
      const tempA = a.name.toLowerCase();
      const tempB = b.name.toLowerCase();
      if (tempA > tempB) { return 1; }
      if (tempA < tempB) { return -1; }
      return 0;
    });
  }
}
