<div *ngIf="orderedTiers"
     class="customer-summary">
  <div class="d-flex flex-row mb-2">
    <img src="/assets/investor-images/icon-signal-tower.svg">
    <h2>{{'project-group-introduction.customer-summary.heading' | translate}}</h2>
  </div>
  <div class="section-content mt-3">
    <p class="mb-2"><strong>{{'finance-marketplace.customer-summary.total-connections'|translate}}:</strong></p>
    <span *ngFor="let tier of orderedTiers">
      <div class="d-flex align-items-center connections-bar mb-2" *ngIf="tier.tierCategory != 'INTERNAL_LOAD'">
        <strong class="count">{{tier.count}}</strong>
        <span>{{'financials.revenues-table.' + tier.tierCategory.toLocaleLowerCase() | translate}}</span>
      </div>
    </span>
    <!-- total -->
    <div class="d-flex align-items-center connections-bar connections-bar--total mt-4">
      <strong class="count">{{totalTierConnections}}</strong>
      <strong>{{'finance-marketplace.customer-summary.total'|translate}}</strong>
    </div>
  </div>
</div>
