import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { Organization } from '@shared/models/organization/organization.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProjectSetPortfolioConnection } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-connection.model';
import { ProjectSetPortfolioConnectionService } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-connection.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';

@Component({
  selector: 'oes-project-set-portfolio-share-with-any-investor',
  templateUrl: './share-with-any-investor.component.html',
  styleUrls: ['./share-with-any-investor.component.scss']
})
export class ProjectSetPortfolioShareWithAnyInvestorComponent implements OnInit {
  @Input() projectSetPortfolio: ProjectSetPortfolio;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  filteredFinanceOrganizations: Organization[] = [];
  formGroup: UntypedFormGroup;

  private currentUser: User;
  private existingFinanceConnections: ProjectSetPortfolioConnection[] = [];
  private financeOrganizations: Organization[];

  constructor(private _projectSetPortfolioConnectionService: ProjectSetPortfolioConnectionService,
              private _userService: UserService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _projectGroupService: ProjectGroupService) {
  }

  ngOnInit() {
    this.getInvestorsList();
    this.getCurrentUser();
    this.buildForm();
  }

  closeDialog() {
    this.close.next(true);
  }

  submitToInvestor() {
    const investorId = this.formGroup.controls['investorList'].value;
    const connection = new ProjectSetPortfolioConnection({
      financeOrganization: new Organization({ id: investorId }),
    });

    this._projectSetPortfolioConnectionService.create(this.projectSetPortfolio?.id, connection)
      .pipe(take(1))
      .subscribe((projectSetPortfolioConnection: ProjectSetPortfolioConnection) => {
        this.getFinanceConnections();
        this._eventService.success(this._translateService.instant('offtaker.project-set-portfolio.submit.submit-success'));
        this.close.next(true);
      });
  }

  private getCurrentUser() {
    this._userService.getCurrentUser().subscribe((user: User) => {
      this.currentUser = new User(user);
    });
  }

  private buildForm() {
    this.formGroup = new UntypedFormGroup({
      investorList: new UntypedFormControl(false)
    });
    this.formGroup.controls['investorList'].setValue(undefined);
  }

  private getInvestorsList() {
    this._projectGroupService.investorList()
    .pipe(take(1))
    .subscribe((organizations: Organization[]) => {
      this.financeOrganizations = organizations;
      this.getFinanceConnections();
    });
  }

  private getFinanceConnections() {
    if (this.projectSetPortfolio) {
      this._projectSetPortfolioConnectionService.list(this.projectSetPortfolio.id)
      .pipe(take(1))
      .subscribe((connections: ProjectSetPortfolioConnection[]) => {
        if (connections?.length) {
          this.existingFinanceConnections = [];
          this.existingFinanceConnections = connections;
        }
        this.filterSortInvestorList();
      });
    }
  }

  private filterSortInvestorList() {
    const existingConnectionOrgIds =
    this.existingFinanceConnections.map((connection: ProjectSetPortfolioConnection) => connection.financeOrganization.id);

    if (this.financeOrganizations) {
      this.filteredFinanceOrganizations =
        this.financeOrganizations
        .filter((organization: Organization) => organization.id !== this.currentUser.organization.id)
        .filter((organization: Organization) => !existingConnectionOrgIds.includes(organization.id))
        .filter((organization: Organization) => organization.id !== this.projectSetPortfolio?.program?.sponsor?.id);
    }
    this.filteredFinanceOrganizations = this.sortOrgsByName(this.filteredFinanceOrganizations);
  }

  private sortOrgsByName(organizations: Organization[]): Organization[] {
    return organizations.sort((a, b) => {
      const tempA = a.name.toLowerCase();
      const tempB = b.name.toLowerCase();
      if (tempA > tempB) { return 1; }
      if (tempA < tempB) { return -1; }
      return 0;
    });
  }
}
