import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExtensionToFiletypeService {

  constructor() { }

  /**
   * return FontAwesome Icon name. e.g. fa-file-o
   * @param extension: string
   */
  public getFileIcon(extension: string) {
    let name = 'fa-file-o';
    extension = extension ? extension.toLowerCase() : extension;
    switch (extension) {
      case 'json':
      case 'c':
      case 'cpp':
      case 'java':
      case 'py':
      case 'xml':
      case 'html':
        name = 'fa-file-code-o';
        break;
      case 'png':
      case 'bmp':
      case 'gif':
      case 'jpeg':
      case 'jpg':
        name = 'fa-file-image-o';
        break;
      case 'pdf':
        name = 'fa-file-pdf-o';
        break;
      case 'pptx':
      case 'ppt':
        name = 'fa-file-powerpoint-o';
        break;
      case 'docx':
      case 'doc':
        name = 'fa-file-word-o';
        break;
      case 'xlsx':
      case 'xls':
        name = 'fa-file-excel-o';
        break;
      case '7z':
      case 'rar':
      case 'zip':
        name = 'fa-file-archive-o';
        break;
      case 'mp4':
      case 'flv':
      case 'mkv':
      case 'avi':
        name = 'fa-file-video-o';
        break;
      case 'txt':
        name = 'fa-file-text-o';
        break;
      case 'folder':
        name = 'fa-folder';
        break;
      case 'wav':
      case 'aiff':
      case 'mp3':
        name = 'fa-file-audio-o';
        break;
      default:
        name = 'fa-file-o';
        break;
    }
    return name;
  }
}
