import { environment } from '@environments/environment';

export class ConnectionsPayoutApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;
  public static payout = {
    getById: (projectId: string, connectionsPayoutId: string) => {
      return `${ConnectionsPayoutApiConstants.projects}/${projectId}/connections-payout/${connectionsPayoutId}`;
    },
    findByProjectId: (projectId: string) => {
      return `${ConnectionsPayoutApiConstants.projects}/${projectId}/connections-payout/`;
    },
    create: (projectId: string) => {
      return `${ConnectionsPayoutApiConstants.projects}/${projectId}/connections-payout/`;
    }
  };
  public static exchangeRate = {
    update: (projectId: string, currency: string, exchangeRate: number) => {
      return `${ConnectionsPayoutApiConstants.projects}/${projectId}/connections-payout/project-payout-exchange-rate/${currency}/${exchangeRate}`;
    },
    apply: (projectId: string) => {
      return `${ConnectionsPayoutApiConstants.projects}/${projectId}/connections-payout/apply-exchange-rate`;
    }
  };
}
