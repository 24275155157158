<div *ngIf="content"
     class="content-container">
  <div *ngFor="let item of content"
       class="content-item"
       [ngStyle]="{ 'width': 'calc((100% / ' + maxCardsPerRow + ') - ((10px * (' + maxCardsPerRow + ' - 1)) / ' + maxCardsPerRow + '))' }">
    <div class="image-container">
      <img [src]="item.imagePath" [ngStyle]="{ 'width': item.imageWidth + 'px' }">
    </div>
    <h4 [ngStyle]="{ 'font-size': headingSize + 'px' }">{{ item.heading }}</h4>
  </div>
</div>
