<div class="modal-body-inside">

  <oes-notify
    *ngIf="!readOnly"
    [new]="true"
    [iconName]="'info'"
    [message]="'offtaker.project-set-edit.hint' | translate">
  </oes-notify>

  <form *ngIf="siteDetailForm" [formGroup]="siteDetailForm" class="site-detail-form">

    <h4>{{ 'offtaker.project-set-edit.project-fundamentals' | translate }}</h4>
    <p>{{ 'offtaker.project-set-edit.include-development' | translate }}</p>
    <div class="input-row">
      <!-- Avg. System Size Per Site -->
      <div class="site-detail-input input-width-33">
        <label for="average-system-size" class="required">{{ 'offtaker.project-set-edit.avg-system-size' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="averageSystemSize" />
          <div class="input-unit">{{ 'units.kWp' | translate }}</div>
        </div>
      </div>
      <ng-container *ngIf="displayPreConstructionQuestions">
        <!-- Total CapEx -->
        <div class="site-detail-input input-width-33">
          <label for="total-capex" class="required">{{ 'offtaker.project-set-edit.total-capex' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="totalCapex" />
            <div class="input-unit">{{ projectSet?.currencyType }}</div>
          </div>
        </div>
      </ng-container>
      <!-- Avg. Annual Degredation -->
      <div class="site-detail-input input-width-33">
        <label for="average-annual-degradation">{{ 'offtaker.project-set-edit.avg-annual-degradation' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="averageAnnualDegradation" />
          <div class="input-unit">%</div>
        </div>
      </div>
    </div>

    <div class="input-row">
      <ng-container *ngIf="displayPreConstructionQuestions">
        <!-- Est. Start Date of Construction of First Site -->
        <div class="site-detail-input input-width-33">
          <label for="estimated-start-date" class="required">{{ 'offtaker.project-set-edit.estimated-start-date' | translate }}</label>
          <div class="input-unit-container">
            <input
              type="text"
              id="estimatedStartDate"
              formControlName="estimatedStartDate"
              class="rounded"
              autocomplete="off"
              [placeholder]="'calendar.format' | translate"
              bsDatepicker
              [bsConfig]="bsConfig"
              placement="top" />
          </div>
        </div>

        <!-- Time to Commercial Operation date of all sites -->
        <div class="site-detail-input input-width-33">
          <label for="months-to-operation" class="required">{{ 'offtaker.project-set-edit.months-to-operation' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="monthsToOperation" />
            <div class="input-unit">{{ 'units.months' | translate }}</div>
          </div>
          <ng-container *ngIf="displayMonthsToOperationWarning">
            <p class="input-warning">{{ 'offtaker.project-set-edit.months-to-operation-warning' | translate }}</p>
          </ng-container>
        </div>

        <!-- Even spend in each month of construction? -->
        <div class="site-detail-input site-detail-radio-input input-width-33">
          <h5>{{ 'offtaker.project-set-edit.even-spend' | translate }}</h5>
          <div class="radio-inputs">
            <div class="radio-input">
              <input type="radio" value="true" name="evenSpend" formControlName="evenSpend" />
              <label for="even-spend">{{ 'offtaker.project-set-edit.yes' | translate }}</label>
            </div>
            <div class="radio-input">
              <input type="radio" value="false" name="evenSpend" formControlName="evenSpend" />
              <label for="even-spend">{{ 'offtaker.project-set-edit.no-plain' | translate }}</label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="displayMonthlySpend" class="input-row">
      <div class="site-detail-input input-width-17">
        <label for="spend-m1">{{ 'offtaker.project-set-edit.spend-m1' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM1" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m2">{{ 'offtaker.project-set-edit.spend-m2' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM2" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m3">{{ 'offtaker.project-set-edit.spend-m3' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM3" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m4">{{ 'offtaker.project-set-edit.spend-m4' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM4" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m5">{{ 'offtaker.project-set-edit.spend-m5' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM5" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m6">{{ 'offtaker.project-set-edit.spend-m6' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM6" />
          <div class="input-unit">%</div>
        </div>
      </div>
    </div>

    <div *ngIf="displayMonthlySpend" class="input-row">
      <div class="site-detail-input input-width-17">
        <label for="spend-m7">{{ 'offtaker.project-set-edit.spend-m7' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM7" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m8">{{ 'offtaker.project-set-edit.spend-m8' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM8" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m9">{{ 'offtaker.project-set-edit.spend-m9' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM9" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m10">{{ 'offtaker.project-set-edit.spend-m10' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM10" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m11">{{ 'offtaker.project-set-edit.spend-m11' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM11" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input input-width-17">
        <label for="spend-m12">{{ 'offtaker.project-set-edit.spend-m12' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="spendM12" />
          <div class="input-unit">%</div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="displayMonthlySpend && displayMonthlySpendWarning" class="input-row">
      <p class="input-warning">{{ 'offtaker.project-set-edit.monthly-spend-warning' | translate }}</p>
    </ng-container>

    <hr />

    <h4>{{ 'offtaker.project-set-edit.pv-production' | translate }}</h4>
    <div class="input-row">
      <div class="site-detail-input">
        <label for="average-pv-production" class="required">{{ 'offtaker.project-set-edit.avg-pv-production' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="averagePvProduction" />
          <div class="input-unit">{{ 'units.kWh' | translate }}/{{ 'units.year' | translate }}/{{ 'units.kWp' | translate }}</div>
        </div>
      </div>

      <div class="site-detail-input site-detail-radio-input">
        <h5>{{ 'offtaker.project-set-edit.even-production-per-quarter' | translate }}</h5>
        <div class="radio-inputs">
          <div class="radio-input">
            <input type="radio" value="true" name="evenProductionPerQuarter" formControlName="evenProductionPerQuarter" />
            <label for="even-production-per-quarter">{{ 'offtaker.project-set-edit.yes' | translate }}</label>
          </div>
          <div class="radio-input">
            <input type="radio" value="false" name="evenProductionPerQuarter" formControlName="evenProductionPerQuarter" />
            <label for="even-production-per-quarter">{{ 'offtaker.project-set-edit.no-plain' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayQuarterlyProductions" class="input-row">
      <div class="site-detail-input">
        <label for="pv-production-q1">{{ 'offtaker.project-set-edit.q1' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="pvProductionQ1" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input">
        <label for="pv-production-q2">{{ 'offtaker.project-set-edit.q2' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="pvProductionQ2" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input">
        <label for="pv-production-q3">{{ 'offtaker.project-set-edit.q3' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="pvProductionQ3" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input">
        <label for="pv-production-q4">{{ 'offtaker.project-set-edit.q4' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="pvProductionQ4" />
          <div class="input-unit">%</div>
        </div>
      </div>
    </div>

    <hr />

    <h4>{{ 'offtaker.project-set-edit.customer-consumption' | translate }}</h4>
    <div class="input-row">
      <div class="site-detail-input">
        <label for="average-customer-consumption" class="required">{{ 'offtaker.project-set-edit.avg-customer-consumption' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="averageCustomerConsumption" />
          <div class="input-unit">{{ 'units.kWh' | translate }}/{{ 'units.year' | translate }}/{{ 'units.kWp' | translate }}</div>
        </div>
      </div>

      <div class="site-detail-input site-detail-radio-input">
        <h5>{{ 'offtaker.project-set-edit.even-consumption-per-quarter' | translate }}</h5>
        <div class="radio-inputs">
          <div class="radio-input">
            <input type="radio" value="true" name="evenConsumptionPerQuarter" formControlName="evenConsumptionPerQuarter" />
            <label for="even-consumption-per-quarter">{{ 'offtaker.project-set-edit.yes' | translate }}</label>
          </div>
          <div class="radio-input">
            <input type="radio" value="false" name="evenConsumptionPerQuarter" formControlName="evenConsumptionPerQuarter" />
            <label for="even-consumption-per-quarter">{{ 'offtaker.project-set-edit.no-plain' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayQuarterlyConsumptions" class="input-row">
      <div class="site-detail-input">
        <label for="consumption-q1">{{ 'offtaker.project-set-edit.q1' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="consumptionQ1" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input">
        <label for="consumption-q2">{{ 'offtaker.project-set-edit.q2' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="consumptionQ2" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input">
        <label for="consumption-q3">{{ 'offtaker.project-set-edit.q3' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="consumptionQ3" />
          <div class="input-unit">%</div>
        </div>
      </div>
      <div class="site-detail-input">
        <label for="consumption-q4">{{ 'offtaker.project-set-edit.q4' | translate }} %</label>
        <div class="input-unit-container">
          <input type="number" formControlName="consumptionQ4" />
          <div class="input-unit">%</div>
        </div>
      </div>
    </div>

    <hr />

    <h4>{{ 'offtaker.project-set-edit.tariffs' | translate }}</h4>
    <div class="input-row">
      <ng-container *ngIf="displayNonOperationsQuestions">
        <!-- Average Contract Length -->
        <div class="site-detail-input input-width-50">
          <label for="average-contract-length" class="required">{{ 'offtaker.project-set-edit.avg-contract-length' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="averageContractLength" />
            <div class="input-unit">{{ 'offtaker.project-set-edit.years' | translate }}</div>
          </div>
        </div>
      </ng-container>

      <!-- Blended Tariff Charged to Customer -->
      <div class="site-detail-input input-width-50">
        <label for="average-tariff" class="required">{{ 'offtaker.project-set-edit.avg-tariff' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="averageTariff" />
          <div class="input-unit">{{ projectSet?.currencyType }}/{{ 'units.kWh' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="input-row input-row-spacer">
      <div class="site-detail-input input-width-33">
        <!-- Pass-through of inflation, FX, and diesel costs?* -->
        <label>{{ 'offtaker.project-set-edit.pass-through-inflation' | translate }}</label>
        <div class="radio-inputs">
          <div class="radio-input">
            <input type="radio" value="true" name="passThroughInflation" formControlName="passThroughInflation" />
            <label for="pass-through-inflation">{{ 'offtaker.project-set-edit.yes' | translate }}</label>
          </div>
          <div class="radio-input">
            <input type="radio" value="false" name="passThroughInflation" formControlName="passThroughInflation" />
            <label for="pass-through-inflation">{{ 'offtaker.project-set-edit.no-plain' | translate }}</label>
          </div>
        </div>
        <p class="input-comment">{{ 'offtaker.project-set-edit.pass-through-comment' | translate }}</p>
      </div>
      <ng-container *ngIf="displayTariffEscalation">
        <!-- Tariff escalation* -->
        <div class="site-detail-input input-width-33">
          <label for="tariff-escalation" class="required">{{ 'offtaker.project-set-edit.tariff-escalation' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="tariffEscalation" />
            <div class="input-unit">%</div>
          </div>
        </div>
        <!-- OpEx Inflation* -->
        <div class="site-detail-input input-width-33">
          <label for="opex-inflation" class="required">{{ 'offtaker.project-set-edit.opex-inflation' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="opexInflation" />
            <div class="input-unit">%</div>
          </div>
        </div>
      </ng-container>
    </div>


    <div class="input-row input-row-spacer">
      <div class="site-detail-input input-width-33">
        <!-- Take-or-Pay Contract -->
        <label>{{ 'offtaker.project-set-edit.take-or-pay' | translate }}</label>
        <div class="radio-inputs">
          <div class="radio-input">
            <input type="radio" value="true" name="takeOrPay" formControlName="takeOrPay" />
            <label for="take-or-pay">{{ 'offtaker.project-set-edit.yes' | translate }}</label>
          </div>
          <div class="radio-input">
            <input type="radio" value="false" name="takeOrPay" formControlName="takeOrPay" />
            <label for="take-or-pay">{{ 'offtaker.project-set-edit.no-plain' | translate }}</label>
          </div>
        </div>
        <p class="input-comment">{{ 'offtaker.project-set-edit.take-or-pay-comment' | translate }}</p>
      </div>
      <ng-container *ngIf="displayStrandedAndTariff">
        <!-- Avg % of annual production that will be stranded -->
        <div class="site-detail-input input-width-33">
          <label for="stranded-production-percentage" class="required">{{ 'offtaker.project-set-edit.stranded-production' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="strandedProductionPercentage" />
            <div class="input-unit">%</div>
          </div>
          <p class="input-comment">{{ 'offtaker.project-set-edit.stranded-comment' | translate }}</p>
        </div>
        <!-- Feed-in-tariff -->
        <div class="site-detail-input input-width-33">
          <label for="feed-in-tariff" class="required">{{ 'offtaker.project-set-edit.feed-in-tariff' | translate }}</label>
          <div class="input-unit-container">
            <input type="number" formControlName="feedInTariff" />
            <div class="input-unit">{{ projectSet?.currencyType }}/{{ 'units.kWh' | translate }}</div>
          </div>
          <p class="input-comment">{{ 'offtaker.project-set-edit.feed-in-tariff-comment' | translate }}</p>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="displayGridTiedSection">
      <div class="input-row">
        <div class="site-detail-input input-width-33">
          <!-- Who is Responsible for Grid Connection? -->
          <label>{{ 'offtaker.project-set-edit.responsibility' | translate }}</label>
          <div class="radio-inputs">
            <div class="radio-input">
              <input type="radio" value="OFFTAKER" name="connectionResponsibility" formControlName="connectionResponsibility" />
              <label for="connection-responsibility">{{ 'offtaker.project-set-edit.responsibility-options.OFFTAKER' | translate }}</label>
            </div>
            <div class="radio-input">
              <input type="radio" value="DEVELOPER" name="connectionResponsibility" formControlName="connectionResponsibility" />
              <label for="connection-responsibility">{{ 'offtaker.project-set-edit.responsibility-options.DEVELOPER' | translate }}</label>
            </div>
          </div>
        </div>

        <ng-container *ngIf="displayDeveloperResponsibilitySection">
          <div class="site-detail-input input-width-33">
            <!-- Are you required to meet consumption of the Off-taker? -->
            <label>{{ 'offtaker.project-set-edit.consumption-required' | translate }}</label>
            <div class="radio-inputs">
              <div class="radio-input">
                <input type="radio" value="true" name="offtakerConsumptionRequired" formControlName="offtakerConsumptionRequired" />
                <label for="offtaker-consumption-required">{{ 'offtaker.project-set-edit.yes' | translate }}</label>
              </div>
              <div class="radio-input">
                <input type="radio" value="false" name="offtakerConsumptionRequired" formControlName="offtakerConsumptionRequired" />
                <label for="offtaker-consumption-required">{{ 'offtaker.project-set-edit.no-plain' | translate }}</label>
              </div>
            </div>
          </div>
          <!-- Max % consumption met by grid -->
          <ng-container *ngIf="displayGridDemands">
            <div class="site-detail-input input-width-33">
              <label for="grid-consumption-percentage" class="required">{{ 'offtaker.project-set-edit.grid-consumption-percentage' | translate }}</label>
              <div class="input-unit-container">
                <input type="number" formControlName="gridConsumptionPercentage" />
                <div class="input-unit">%</div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="displayDeveloperResponsibilitySection">
        <ng-container *ngIf="displayGridDemands; else GridDemandsComments">
          <div class="input-row">
            <div class="site-detail-input input-width-33"></div>

            <!-- Est. Avg. Price of Diesel Consumption -->
            <div *ngIf="displayDieselGeneratorDemands; else Spacer" class="site-detail-input input-width-33">
              <label for="average-diesel-consumption-price" class="required">{{ 'offtaker.project-set-edit.average-diesel-consumption-price' | translate }}</label>
              <div class="input-unit-container">
                <input type="number" formControlName="averageDieselConsumptionPrice" />
                <div class="input-unit">{{ projectSet?.currencyType }}/{{ 'units.kWh' | translate }}</div>
              </div>
            </div>
            <ng-template #Spacer>
              <div class="site-detail-input input-width-33"></div>
            </ng-template>
            <!-- Est. Avg. Price of Grid Consumption -->
            <div class="site-detail-input input-width-33">
              <label for="average-grid-consumption-price" class="required">{{ 'offtaker.project-set-edit.average-grid-consumption-price' | translate }}</label>
              <div class="input-unit-container">
                <input type="number" formControlName="averageGridConsumptionPrice" />
                <div class="input-unit">{{ projectSet?.currencyType }}/{{ 'units.kWh' | translate }}</div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #GridDemandsComments>
          <div class="input-row">
            <div class="site-detail-input input-width-33"></div>
            <div class="site-detail-input input-width-66">
              <p><i>{{ 'offtaker.project-set-edit.grid-demands-comments' | translate }}</i></p>
            </div>
          </div>
        </ng-template>

        <ng-container *ngIf="displayDieselGeneratorDemands && displayGridDemands">
          <div class="input-row">
            <!-- % Consumption demands met from diesel generator -->
            <div class="site-detail-input input-width-33"></div>
            <div class="site-detail-input input-width-33">
            </div>

          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <hr />

    <h4>{{ 'offtaker.project-set-edit.variable-costs' | translate }}</h4>
    <p>{{ 'offtaker.project-set-edit.if-variable-costs' | translate }}</p>
    <div class="input-row">
      <div class="site-detail-input input-width-50">
        <label for="om-expense" class="required">{{ 'offtaker.project-set-edit.om-expense' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="omExpense" />
          <div class="input-unit">{{ projectSet?.currencyType }}/{{ 'units.kWp' | translate }}{{ 'units.per-yr' | translate }}</div>
        </div>
      </div>
      <div class="site-detail-input input-width-50">
        <label for="insurance-expense" class="required">{{ 'offtaker.project-set-edit.insurance-expense' | translate }}</label>
        <div class="input-unit-container">
          <input type="number" formControlName="insuranceExpense" />
          <div class="input-unit">{{ projectSet?.currencyType }}/{{ 'units.kWp' | translate }}{{ 'units.per-yr' | translate }}</div>
        </div>
      </div>
    </div>

    <hr />

    <h4>{{ 'offtaker.project-set-edit.comments' | translate }}</h4>
    <p>{{ 'offtaker.project-set-edit.comments-notes' | translate }}</p>
    <div class="comments">
      <oes-quill
        id="comments"
        formControlName="comments"
        [placeholder]="'offtaker.project-set-edit.comments' | translate">
      </oes-quill>
    </div>
  </form>
</div>

<footer>
  <div class="footer-content-container">
    <div class="totals-metrics">
      <div class="totals-metric">
        <p>{{ 'offtaker.project-set-edit.total-system-size' | translate }}</p>
        <h4>{{ totalsDisplay.systemSize | number: '1.0-2' }} {{ 'units.kWp' | translate }}</h4>
      </div>
      <div class="totals-metric">
        <p>{{ 'offtaker.project-set-edit.total-est-capex' | translate }}</p>
        <h4>{{ projectSet?.currencyType === 'USD' ? '$' : null }}{{ totalsDisplay.capex | number: '1.0-2' }}{{ projectSet?.currencyType !== 'USD' ? ' ' + projectSet?.currencyType : null }}</h4>
      </div>
      <div class="totals-metric">
        <p>{{ 'offtaker.project-set-edit.total-pv-production' | translate }}</p>
        <h4>{{ totalsDisplay.pvProduction | number: '1.0-2' }} {{ 'units.kWh' | translate }}{{ 'units.per-yr' | translate }}</h4>
      </div>
    </div>

    <div class="bottom-buttons">
      <button class="btn btn-secondary ml-auto" (click)="emitCloseEvent()">{{ 'buttons.cancel' | translate }}</button>
      <button *ngIf="!readOnly"
              class="btn btn-primary"
              [disabled]="siteDetailForm?.invalid"
              (click)="submitForm($event)">
        {{ 'buttons.save-changes' | translate }}
      </button>
    </div>
  </div>
</footer>

