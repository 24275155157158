export enum ComponentType {
  PANELS = 'PANELS',
  CHARGE_CONTROLLER = 'CHARGE_CONTROLLER',
  STORAGE = 'STORAGE',
  SOLAR_INVERTER = 'SOLAR_INVERTER',
  SYSTEM_INVERTER = 'SYSTEM_INVERTER',
  BATTERY_INVERTER = 'BATTERY_INVERTER',
  GRID = 'GRID',
  LOAD = 'LOAD',
  GENERATOR = 'GENERATOR',
}
