import { FinanceDealStatus } from '@project/offtakers/finance-deal-status.enum';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';

export class ProjectSetPortfolioConnection extends BaseModel<ProjectSetPortfolioConnection> {
  projectSetPortfolio: ProjectSetPortfolio;
  financeOrganization: Organization;
  programShareDateTime: Date;
  status: FinanceDealStatus;

  constructor(connectionInfo: any) {
    super();
    this.id = connectionInfo.id;
    this.projectSetPortfolio = connectionInfo.projectSetPortfolio;
    this.financeOrganization = connectionInfo.financeOrganization;
    this.programShareDateTime = connectionInfo.programShareDateTime;
    this.status = connectionInfo.status;
  }
}
