export class BreadcrumbItem {
  label?: string;
  url?: string;
  params?: any;

  constructor(label: string = '', url: string = '', params: any = {}) {
    this.label = label;
    this.url = url;
    this.params = params;
  }
};
