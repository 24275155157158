import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DragDrop } from './drag-drop.model';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {
  private itemStatusSubject = new BehaviorSubject<DragDrop>(undefined);
  public itemStatus$ = this.itemStatusSubject.asObservable();
  private _dragDropId = 'generation-system-design-bag';

  constructor() {
  }

  get dragDropId() {
    return this._dragDropId;
  }

  public updateStatus(item: DragDrop) {
    this.itemStatusSubject.next(item);
  }
}
