import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Project } from '@project/shared/project.model';

@Injectable({
  providedIn: 'root'
})
export class SelectedProjectsService {
  private _projectsSubject = new BehaviorSubject<Project[]>([]);
  public projects$: Observable<Project[]> = this._projectsSubject.asObservable();

  constructor() {
  }

  get projects(): Project[] {
    return this._projectsSubject.getValue();
  }

  set projects(projects: Project[]) {
    this._projectsSubject.next(projects);
  }

  public clear() {
    this._projectsSubject.next([]);
  }
}
