import { DataProvider } from 'src/app/analytics/data-integrations/shared/data-provider.enum';
import { BaseModel } from '../../base.model';

export class OrganizationBootstrapProperties extends BaseModel<OrganizationBootstrapProperties> {
  privateDataProviders?: DataProvider[];
  apiUrl?: string;

  constructor(obpInfo: any) {
    super();
    this.privateDataProviders = obpInfo.privateDataProviders;
    this.apiUrl = obpInfo.apiUrl;
  }
}
