import { Component, OnInit, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Offtaker, OfftakerTypeUnion } from '../offtaker.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { OfftakerType } from '../offtaker-type.enum';
import { OfftakerService } from '../offtaker.service';
import { Subject, take } from 'rxjs';
import { Organization } from '@shared/models/organization/organization.model';

@Component({
  selector: 'oes-offtaker-form',
  templateUrl: './offtaker-form.component.html',
  styleUrls: ['./offtaker-form.component.scss']
})
export class OfftakerFormComponent implements OnInit, OnDestroy {
  @ViewChild('offtakerModal', {static: false}) offtakerModal: ModalDirective;
  @ViewChild('deleteOfftakerModal', {static: false}) deleteOfftakerModal: ModalDirective;

  @Input() offtakers: Offtaker[] = [];
  @Input() offtaker: Offtaker;
  @Input() organization: Organization;

  @Output() refreshOfftakers: EventEmitter<Offtaker> = new EventEmitter<Offtaker>();

  action: 'create' | 'edit';
  businessType: OfftakerType | undefined;
  duplicateName = false;
  duplicateEmail = false;
  formGroup: UntypedFormGroup;
  offtakerEditId: string = '';
  offtakerTypeOptions = Object.values(OfftakerType).map((type) => ({
    id: type,
    name: this._translateService.instant(`offtaker.offtaker-type.${type}`)
  }));
  showBusinessTypeError = false;

  private message = {};
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _eventService: EventService,
              private _offtakerService: OfftakerService,
              private _translateService: TranslateService) {}

  ngOnInit() {
    this.buildForm();
    this.message = this._translateService.instant('offtaker.form.message');
  }

  ngOnDestroy() {
    this.formGroup.reset();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup(
      {
        customerName: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
        selectOption: new UntypedFormControl([this.businessType]),
        email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(1000)]),
      }
    );
    this.subscribeForm();
  }

  subscribeForm() {
    this.formGroup?.valueChanges.subscribe((values: any) => {
      if (values.selectOption) {
        this.businessType = values.selectOption[0];
      }
    });
  }

  handleSubmitOfftaker() {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid || !this.checkDuplicateNameAndEmail() || !this.checkOfftakerType()) {
      if (!this.businessType) {
        this.showBusinessTypeError = true;
      }
      return;
    }
    this.showBusinessTypeError = false;
    if (this.action === 'create') {
      this.createOfftaker();
    } else {
      this.updateOfftaker();
    }
    this.closeOfftakerModal();
    this.formGroup.reset();
  }

  checkDuplicateNameAndEmail() {
    this.duplicateName = false;
    this.duplicateEmail = false;
    this.offtakers.forEach(ot => {
      if (ot.id === this.offtakerEditId) {
        return;
      }
      if (ot.name === this.formGroup.value.customerName.trim()) {
        this.duplicateName = true;
      }
      if (ot.email === this.formGroup.value.email.trim()) {
        this.duplicateEmail = true;
      }
    });
    return !this.duplicateName && !this.duplicateEmail;
  }

  private checkOfftakerType(): boolean {
    return !!this.businessType;
  }

  createOfftaker() {
    this._offtakerService.create(new Offtaker({
      name: this.formGroup.value.customerName.trim(),
      email: this.formGroup.value.email.trim(),
      type: this.businessType,
      creatorOrganization: this.organization
    }))
    .pipe(take(1))
    .subscribe({
      next: (offtaker: Offtaker) => {
        this._eventService.success(this.message['create-success']);
        this.offtakers.push(offtaker);
        this.refreshOfftakers.emit(offtaker);
      },
      error: (_) => {
        this._eventService.error(this.message['create-error']);
      }
    });
  }

  updateOfftaker() {
    if (!this.offtakers.length) {
      return;
    }
    const targetOfftaker = this.offtakers.find(ot => ot.id === this.offtakerEditId);
    if (!targetOfftaker) {
      return;
    }
    targetOfftaker.name = this.formGroup.value.customerName.trim();
    targetOfftaker.type = this.businessType as OfftakerTypeUnion;
    targetOfftaker.email = this.formGroup.value.email.trim();
    this._offtakerService.update(targetOfftaker)
    .pipe(take(1))
    .subscribe({
      next: () => {
        this._eventService.success(this.message['update-success']);
        this.refreshOfftakers.emit();
      },
      error: () => {
        this._eventService.error(this.message['update-error']);
      }
    });
  }

  addOfftakerOpen() {
    this.businessType = undefined;
    this.formGroup.reset();
    this.formGroup.patchValue({
      customerName: '',
      selectOption: [this.businessType],
      email: ''
    });
    this.resetErrors();
    this.offtakerModal.show();
  }

  editOfftakerOpen(data: any) {
    this.formGroup.patchValue({
      customerName: data.name,
      selectOption: [data.type],
      email: data.email || ''
    });
    this.offtakerEditId = data.id;
    this.resetErrors();
    this.offtakerModal.show();
  }

  private resetErrors() {
    this.duplicateName = false;
    this.duplicateEmail = false;
    this.showBusinessTypeError = false;
  }

  public openModal(action: 'create' | 'edit', event: any = null) {
    this.action = action;
    if (action === 'create') {
      this.addOfftakerOpen();
    } else {
      this.editOfftakerOpen(event?.data);
    }
  }

  closeOfftakerModal() {
    this.offtakerEditId = '';
    this.offtakerModal.hide();
  }

  handleDeleteOfftaker() {
    const offtakerToDelete = this.offtakers.find(ot => ot.id === this.offtakerEditId);
    if (offtakerToDelete?.projectOfftakers.length) {
      this._eventService.error(this.message['delete-error-offtaker-assigned']);
      return;
    }
    this._offtakerService.remove(this.offtakerEditId)
    .pipe(take(1))
    .subscribe({
      next: () => {
        this._eventService.success(this.message['delete-success']);
        this.refreshOfftakers.emit();
      },
      error: () => {
        this._eventService.error(this.message['delete-error']);
      }
    });
    this.closeOfftakerModal();
    this.deleteOfftakerModal.hide();
  }

  showError(fieldName: string, errorName: string) {
    return this.formGroup?.touched && this.formGroup?.controls?.[fieldName]?.errors?.[errorName];
  }
}
