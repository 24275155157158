import { map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from './user.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { ROLE_TYPE } from './role-type';
import { environment } from '@environments/environment';

import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseRestApiService {
  public userUri = environment.serverUri + `api/${environment.apiVersion}/users`;
  private _currentUser: User;

  private _userRole = new BehaviorSubject<ROLE_TYPE>(null);
  userRole$ = this._userRole.asObservable();

  private getUser$ = this.get(`${this.userUri}/0`).pipe(shareReplay(1));

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public userAvailable() {
    return this._currentUser === undefined ? false : true;
  }

  // Note: When we switch organization to keycloak, we can get user information from access_token which has group and role
  public getCurrentUser(): Observable<User> {
    if (this._currentUser) {
      return of(this._currentUser);
    } else {
      return this.getUser$.pipe(
        map(response => {
          const user = new User(response);
          this._currentUser = user;
          return user;
        })
      );
    }
  }

  public createUpdate(user: User): Observable<User> {
    if (user.id) {
      return this.put<User>(`${this.userUri}/${user.id}`, JSON.stringify(user));
    }
    return this.post<User>(this.userUri, JSON.stringify(user));
  }

  /**
   * TODO: it won't work sometime, especially when a user reloads a page.
   */
  get organizationId(): string {
    if (this._currentUser) {
      return this._currentUser.organization.id;
    }
  }

  get passwordChanged(): boolean {
    if (this._currentUser) {
      return this._currentUser.passwordChanged ? true : false;
    }
    return false;
  }

  public hasRole(roleType: ROLE_TYPE): boolean {
    if (this._currentUser?.roles?.length > 0) {
      return this._currentUser.roles.some(role => role.name === roleType);
    }
    return false;
  }

  public setSelectedRole(role: ROLE_TYPE) {
    this._currentUser.selectedRole = role;
    localStorage.setItem('role_type', ROLE_TYPE[role]);
    this._userRole.next(ROLE_TYPE[role]);
  }

  // only care about developer, investor and vendor roles.
  public getSelectedRole(): ROLE_TYPE {
    if (this._currentUser) {
      let initialRoleType;
      // use saved role
      const savedRole = localStorage.getItem('role_type');
      // compare with text undefined
      if (savedRole && savedRole !== 'undefined' &&
          savedRole !== ROLE_TYPE.DATA_ANALYST &&
          savedRole !== ROLE_TYPE.ORGANIZATION_ADMIN &&
          savedRole !== ROLE_TYPE.SYSTEM_ADMIN) {
        // check developer, investor and vendor
        if (this.hasRole(ROLE_TYPE[savedRole])) {
          initialRoleType = ROLE_TYPE[savedRole];
        } else {
          initialRoleType = this.takeFirstRole();
        }
      } else {
        // no saved role, use dev, investor or vendor.
        if (this._currentUser.roles.length > 0) {
          initialRoleType = this.takeFirstRole();
        }
      }
      if (!this._currentUser.selectedRole || this._currentUser.selectedRole !== initialRoleType) {
        this._currentUser.selectedRole = initialRoleType;
        this._userRole.next(initialRoleType);
      }
      return this._currentUser.selectedRole;
    }
    return undefined;
  }

  private takeFirstRole(): ROLE_TYPE {
    if (this.hasRole(ROLE_TYPE.DEVELOPER_USER)) {
      return ROLE_TYPE.DEVELOPER_USER;
    } else if (this.hasRole(ROLE_TYPE.FINANCE_USER)) {
      return ROLE_TYPE.FINANCE_USER;
    } else if (this.hasRole(ROLE_TYPE.VENDOR_USER)) {
      return ROLE_TYPE.VENDOR_USER;
    }
  }

  /**
   * remove role_type from session storage
   */
  public clear() {
    this._currentUser = undefined;
    const savedRole = localStorage.getItem('role_type');
    if (savedRole === ROLE_TYPE.SYSTEM_ADMIN) {
      localStorage.removeItem('role_type');
    }
    this.getUser$ = this.get(`${this.userUri}/0`).pipe(shareReplay(1));
  }
}
