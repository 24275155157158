<form [formGroup]="dropdownForm" [ngClass]="class">
  <div class="form-control"
    [ngClass]="{'ng-invalid': dropdownForm?.invalid}">
    <ngx-bootstrap-multiselect
      [options]="items"
      [settings]="dropdownSettings"
      [texts]="customText"
      (lazyLoad)="emitSearch($event)"
      [formControlName]="controlName">
    </ngx-bootstrap-multiselect>
  </div>
  <div class="hint">{{ hint }}</div>
</form>
