export enum REFERENCE_TYPE {
  APPLIANCE_SOURCE_TYPE = 'APPLIANCE_SOURCE_TYPE',
  AREA_UNIT_TYPE = 'AREA_UNIT_TYPE',
  CONTROLLER_TYPE = 'CONTROLLER_TYPE',
  CURRENCY_TYPE = 'CURRENCY_TYPE',
  PROJECT_TYPE = 'PROJECT_TYPE',
  DECLINE_REASON_CODE = 'DECLINE_REASON_CODE',
  DESIGN_ITEM_TYPE = 'DESIGN_ITEM_TYPE',
  DISTANCE_UNIT_TYPE = 'DISTANCE_UNIT_TYPE',
  DISTRIBUTION_DESIGN_ITEM_CATEGORY_TYPE = 'DISTRIBUTION_DESIGN_ITEM_CATEGORY_TYPE',
  EXPENSE_TYPE = 'EXPENSE_TYPE',
  FUEL_TYPE = 'FUEL_TYPE',
  GENERATION_DESIGN_ITEM_TYPE = 'GENERATION_DESIGN_ITEM_TYPE',
  GENERATION_SOURCE_TYPE = 'GENERATION_SOURCE_TYPE',
  GRANT_SOURCE_TYPE = 'GRANT_SOURCE_TYPE',
  GRANT_TYPE = 'GRANT_TYPE',
  GROUP_REPRESENTATION_TYPE = 'GROUP_REPRESENTATION_TYPE',
  LEAD_TIME_TYPE = 'LEAD_TIME_TYPE',
  MICRO_GRID_STATUS = 'MICRO_GRID_STATUS',
  POWER_UNIT_TYPE = 'POWER_UNIT_TYPE',
  PROJECT_COST_TYPE = 'PROJECT_COST_TYPE',
  PROJECT_GENERATION_DESIGN_COST_CATEGORY = 'PROJECT_GENERATION_DESIGN_COST_CATEGORY',
  FINANCE_LEVEL_TYPE = 'FINANCE_LEVEL_TYPE',
  FINANCE_CAPITAL_STATUS = 'FINANCE_CAPITAL_STATUS',
  FINANCE_CAPITAL_TYPE = 'FINANCE_CAPITAL_TYPE',
  FINANCE_CONNECTION_STATUS = 'FINANCE_CONNECTION_STATUS',
  FINANCE_DOCUMENT_CATEGORY = 'FINANCE_DOCUMENT_CATEGORY',
  FINANCE_DOCUMENT_TYPE = 'FINANCE_DOCUMENT_TYPE',
  FINANCE_EQUIPMENT_CATEGORY = 'FINANCE_EQUIPMENT_CATEGORY',
  FINANCE_MILESTONE_TYPE = 'FINANCE_MILESTONE_TYPE',
  FINANCE_STATUS = 'FINANCE_STATUS',
  PROJECT_SPECIFICATION_SOURCE_TYPE = 'PROJECT_SPECIFICATION_SOURCE_TYPE',
  PROJECT_STATUS_TYPE = 'PROJECT_STATUS_TYPE',
  SPECIFICATION_UNIT_TYPE = 'SPECIFICATION_UNIT_TYPE',
  STORAGE_TYPE = 'STORAGE_TYPE',
  TARIFF_TYPE = 'TARIFF_TYPE',
  TIER_CATEGORY = 'TIER_CATEGORY',
  TIER_TYPE = 'TIER_TYPE',
  FINANCIAL_MODEL_TEMPLATE = 'FINANCIAL_MODEL_TEMPLATE'
}
