import { Injectable } from '@angular/core';
import { GenerationDesignCostSource } from './generation-design-cost-source.model';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { GenerationDesignCostSourceApiConstants } from './generation-design-cost-source-api.constant';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GenerationDesignCostSourceService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(): Observable<GenerationDesignCostSource[]> {
    return this.get<GenerationDesignCostSource[]>(GenerationDesignCostSourceApiConstants.list())
           .pipe(
             map((result: any) => {
               return result.map((costSource: GenerationDesignCostSource) => new GenerationDesignCostSource(costSource))
                      .sort((a, b) => this.sort(a, b));
             }),
           );
  }
  private sort(a, b) {
    const typeOrder = {
      'Enclosure': 0,
      'Monitoring': 1,
      'Protections': 2,
      'Installation': 3,
      'Shipping and Customs': 4,
      'Other': 5,
    };
    if (typeOrder[a.name] > typeOrder[b.name]) {
      return 1;
    } else if (typeOrder[a.name] < typeOrder[b.name]) {
      return -1;
    } else {
      return 0;
    }
  }
  public create(generationDesignCostSource: GenerationDesignCostSource): Observable<GenerationDesignCostSource> {
    return this.post<GenerationDesignCostSource>(
      GenerationDesignCostSourceApiConstants.create(),
      JSON.stringify(generationDesignCostSource)
    );
  }
  public detail(generationDesignCostSourceId: string): Observable<GenerationDesignCostSource> {
    return this.get<GenerationDesignCostSource>(GenerationDesignCostSourceApiConstants.detail(generationDesignCostSourceId))
            .pipe(
              map(costSource => new GenerationDesignCostSource(costSource))
            );
  }
  public update(generationDesignCostSource: GenerationDesignCostSource): Observable<GenerationDesignCostSource> {
    return this.post<GenerationDesignCostSource>(
      GenerationDesignCostSourceApiConstants.update(generationDesignCostSource.id),
      JSON.stringify(generationDesignCostSource)
    );
  }
  public remove(generationDesignCostSourceId: string): Observable<GenerationDesignCostSource> {
    return this.delete<GenerationDesignCostSource>(GenerationDesignCostSourceApiConstants.delete(generationDesignCostSourceId));
  }
}
