import { Component, Input } from '@angular/core';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { ProjectStage } from '@project/offtakers/project-stage.enum';

@Component({
  selector: 'oes-display-psp-overview-metrics',
  templateUrl: 'display-psp-overview-metrics.component.html',
  styleUrls: ['./display-psp-overview-metrics.component.scss']
})
export class DisplayPSPOverviewMetricsComponent {
  @Input() projectSetPortfolio: ProjectSetPortfolio;

  constructor() {}

  getAllSitesCount() {
    if (!this.projectSetPortfolio || !this.projectSetPortfolio.projectSets) {
      return 0;
    }
    return this.projectSetPortfolio.projectSets.reduce((accum, projectSet) => {
      accum += projectSet.totalProjects;
      return accum;
    }, 0);
  }

  getSitesCountByStage(stage: ProjectStage) {
    if (!this.projectSetPortfolio || !this.projectSetPortfolio.projectSets) {
      return 0;
    }
    return this.projectSetPortfolio.projectSets
      .filter(projectSet => projectSet.stage === stage)
      .reduce((accum, projectSet) => {
        accum += projectSet.totalProjects;
        return accum;
      }, 0);
  }
}
