import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { take } from 'rxjs/operators';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from '@shared/components/files/shared/files.service';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { FolderService } from '@shared/components/files/shared/folder.service';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { ActionService } from '@shared/components/files/shared/action.service';

@Component({
  selector: 'oes-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;
  @Input() type = 'copy';
  @Input() tagTypes: TagType[]; // to add systemTags to dropdown folder selection
  @Input() organizationId: string;

  public formGroup: UntypedFormGroup;
  private message = {};
  public selectOptions: IMultiSelectOption[] = [];
  constructor(private _formBuilder: UntypedFormBuilder,
              private _documentDataService: DocumentDataService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _filesService: FilesService,
              private _actionService: ActionService,
              private _folderService: FolderService) {
    this.message = this._translateService.instant('data-room.dialogs');
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({
      selectOption: new UntypedFormControl()
    });
  }

  public callFunction(type: string) {
    if (type === 'move') {
      this.move();
    } else if (type === 'copy') {
      this.copy();
    }
  }

  private move() {
    const selectedNode = this._filesService.getSelectedNodes();
    const selectedOption = this.getSelection();
    const requestItems = this._actionService.getRequestItems(selectedNode, selectedOption);
    const destinationTagType = this._actionService.getDestinationTagType(selectedOption);
    this._documentDataService.moveCopyDocuments(requestItems, destinationTagType, 'move')
    .pipe(take(1))
    .subscribe(results => {
      this._eventService.success(this.message['moved']);
      this._filesService.clickActionButton(ActionType.completed);
      this.modalDialog.hide();
      this.formGroup.reset();
    });
  }

  private copy() {
    const selectedNode = this._filesService.getSelectedNodes();
    const selectedOption = this.getSelection();
    const requestItems = this._actionService.getRequestItems(selectedNode, selectedOption);
    const destinationTagType = this._actionService.getDestinationTagType(selectedOption);
    if (destinationTagType) {
      this._documentDataService.moveCopyDocuments(requestItems, destinationTagType, 'copy')
      .pipe(take(1))
      .subscribe((results) => {
        this._eventService.success(this.message['copied']);
        this._filesService.clickActionButton(ActionType.completed);
        this.modalDialog.hide();
        this.formGroup.reset();
      });
    }
  }

  private getSelection(): any {
    const result = this.selectOptions.filter(item => {
      const path = this.formGroup.controls['selectOption'].value;
      if (path && path.length > 0) {
        return item.id === path[0];
      }
      return false;
    });
    if (result && result.length > 0) {
      return result[0];
    }
  }

  public show() {
    // folders list
    const selectedNodes = this._filesService.getSelectedNodes()
    .map(item => {
      // if it's FILE, remove the file name
      if (item.data.documentKey.docType === 'FILE') {
        const pathArray = item.data.path.split('/');
        pathArray.splice(pathArray.length - 1, 1);
        return {
          path: pathArray.join('/'),
          docType: item.data.documentKey.docType,
          parentId: item.parent.id,
          id: item.id
        };
      }
      return {
        path: item.data.path,
        docType: item.data.documentKey.docType,
        parentId: item.parent.id,
        id: item.id
      };
    });

    // make options for dropdown
    this.selectOptions = this._folderService.generateSelectOptions(selectedNodes, this.tagTypes, this.organizationId);
    this.modalDialog.show();
  }

  public close() {
    this.formGroup.reset();
  }
}
