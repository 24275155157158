import {BaseModel} from '@shared/base.model';
import { GenerationDesignCostSource } from './generation-design-cost-source.model';
import { BosCategory } from '@shared/components/project-generation-design/bos/bos-category.enum';

export class GenerationDesignCostItem extends BaseModel<GenerationDesignCostItem> {
  canAlter: boolean;
  category: BosCategory;
  count: number;
  description: string;
  source: GenerationDesignCostSource;
  totalCost: number;
  unitCost: number;
  units: string;

  constructor(projectCostItemInfo: any) {
    super();
    this.canAlter = projectCostItemInfo.canAlter;
    this.category = projectCostItemInfo.category;
    this.count = projectCostItemInfo.count;
    this.description = projectCostItemInfo.description;
    this.id = projectCostItemInfo.id;
    this.source = new GenerationDesignCostSource(projectCostItemInfo.source || {});
    this.totalCost = projectCostItemInfo.totalCost;
    this.unitCost = projectCostItemInfo.unitCost;
    this.units = projectCostItemInfo.units;
  }
}
