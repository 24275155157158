import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Country } from '@shared/services/country/country.model';
import { CountryService } from '@shared/services/country/country.service';
import { IMultiSelectTexts } from 'ngx-bootstrap-multiselect';
import { take } from 'rxjs/operators';
import { CurrencyExchangeService } from '@shared/models/currency-exchange/currency-exchange.service';
import { REFERENCE_TYPE } from '@shared/reference-types';
import { ReferenceService } from '@shared/references.service';
import { ReferenceType } from '@shared/references.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { OfftakerType } from '../offtaker-type.enum';
import { OfftakerService } from '../offtaker.service';
import { Offtaker } from '../offtaker.model';
import { ProjectSet } from '../project-set/project-set.model';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { ProjectSetService } from '../project-set/project-set.service';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectStage } from '../project-stage.enum';
import { Router } from '@angular/router';
import { isNil, parseInt } from 'lodash-es';
import { OfftakerCardinality } from '../offtaker-cardinality.enum';

@Component({
  selector: 'oes-offtaker-create',
  templateUrl: 'offtaker-create.component.html',
  styleUrls: ['./offtaker-create.component.scss'],
  providers: [CurrencyExchangeService]
})
export class OfftakerCreateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() existingOfftakerId: string;
  @Input() editMode: boolean = false;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  configurationForms = {};
  countryOptions: Country[];
  currencyOptions: ReferenceType[];
  customText: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select countries',
    allSelected: 'All selected',
  };
  existingOfftaker: Offtaker;
  existingCountrySelections: string[] = [];
  multipleSetup: boolean = false;
  ngUnsubscribe: Subject<any> = new Subject();
  offtakerForm: UntypedFormGroup;
  offtakerType: string[] = Object.keys(OfftakerType);
  projectStageOptions: string[] = Object.keys(ProjectStage);
  selectedCountries: Country[];
  step = 2;
  user: User;

  constructor(private _countryService: CountryService,
              private _offtakerService: OfftakerService,
              private _projectSetService: ProjectSetService,
              private _router: Router,
              private _userService: UserService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _referenceService: ReferenceService) {}

  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    if (this.editMode) {
      this.initializeEditMode();
    } else {
      this.initializeCreateMode();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.existingOfftakerId?.currentValue !== changes.existingOfftakerId?.previousValue) {
      if (this.editMode) {
        this.getExistingOfftaker(this.existingOfftakerId);
      }
    }
  }

  initializeEditMode() {
    this.createOfftakerForm();
    this.getCurrencyOptions();
    this.getCountryOptionsAndOfftaker();
    this.setUser();
  }

  initializeCreateMode() {
    this.createOfftakerForm();
    this.getCurrencyOptions();
    this.getCountryOptions();
    this.setUser();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getExistingOfftaker(existingOfftakerId: string) {
    this._offtakerService.detail(existingOfftakerId)
      .pipe(take(1))
      .subscribe((offtaker: Offtaker) => {
        this.existingOfftaker = offtaker;
        this.patchOfftakerFormValues();
        this.updateAndPatchCountrySetups(this.existingOfftaker.projectSets);
      });
  }

  setUser() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  emitCloseEvent() {
    this.close.emit(true);
    if (!this.editMode) {
      this.offtakerForm.reset();
    }
  }

  createOfftakerForm() {
    this.offtakerForm = new UntypedFormGroup({
      setup: new UntypedFormControl(OfftakerCardinality.SINGLE, Validators.required),
      name: new UntypedFormControl(null, Validators.required),
      offtakerType: new UntypedFormControl(null, Validators.required),
      selectOption: new UntypedFormControl(null, Validators.required)
    });
    this.subscribeOfftakerForm();
  }

  private subscribeOfftakerForm() {
    this.offtakerForm.controls['setup'].valueChanges.subscribe(setup => {
      this.multipleSetup = setup === OfftakerCardinality.MULTIPLE ? true : false;
      if (this.multipleSetup) {
        this.offtakerForm.controls['offtakerType'].patchValue(OfftakerType.MIXED);
      }
    });
  }

  patchOfftakerFormValues() {
    this.selectedCountries = this.existingOfftaker.projectSets.reduce((accum, ps: ProjectSet) => {
      if (!accum.find(country => country.id === ps.country.id)) {
        accum.push(ps.country);
      }
      return accum;
    }, []);
    const existingCountryIds = this.existingOfftaker.projectSets.reduce((accum, ps) => {
      if (!accum.includes(ps.country.id)) {
        accum.push(ps.country.id);
      }
      return accum;
    }, []);
    this.offtakerForm.controls['setup'].patchValue(this.existingOfftaker.cardinality);
    this.offtakerForm.controls['name'].patchValue(this.existingOfftaker.name);
    this.offtakerForm.controls['offtakerType'].patchValue(this.existingOfftaker.type);
    this.offtakerForm.controls['selectOption'].patchValue(existingCountryIds);

    this.offtakerForm.controls['setup'].disable();
    this.offtakerForm.controls['name'].disable();
    this.offtakerForm.controls['offtakerType'].disable();
  }

  private updateAndPatchCountrySetups(projectSets: ProjectSet[]) {
    this.appendCountriesWithIds(projectSets);
  }

  updateCountrySetups(countryIds: string[]) {
    this.appendAddedCountries(countryIds);
    this.deleteRemovedCountries();
  }

  getCurrencyOptions() {
    this._referenceService.getType(REFERENCE_TYPE.CURRENCY_TYPE)
    .pipe(take(1))
    .subscribe((currencyTypes: ReferenceType[]) => {
      this.currencyOptions = currencyTypes;
    });
  }

  getCountryOptionsAndOfftaker() {
    this.getCountryOptions(true);
  }

  getCountryOptions(editMode = false) {
    this._countryService.getCountries()
    .pipe(take(1))
    .subscribe((countries: Country[]) => {
      this.countryOptions = countries;
      if (editMode) {
        this.getExistingOfftaker(this.existingOfftakerId);
      }
    });
  }

  submitForm(event) {
    if (this.editMode) {
      this.runProjectSetRequests(this.existingOfftaker);
    } else {
      const cardinality = this.offtakerForm.controls['setup'].value;
      let type;
      if (cardinality === OfftakerCardinality.MULTIPLE) {
        type = OfftakerType.MIXED;
      } else {
        type = this.offtakerForm.controls['offtakerType'].value;
      }
      const offtaker = new Offtaker({
        cardinality: cardinality,
        name: this.offtakerForm.controls['name'].value,
        type: type
      });
      this._offtakerService.create(offtaker)
      .pipe(take(1))
      .subscribe((offtakerResponse: Offtaker) => {
        this.runProjectSetRequests(offtakerResponse);
      });
    }
  }

  private getAvailablePsIndexes(currentPsIndexes: any[]) {
    const availablePsIndexes = [];
    for (let i = 1; i <= 1000; i++) {
      if (!currentPsIndexes?.includes(i)) {
        availablePsIndexes.push(i);
      }
    }
    return availablePsIndexes;
  }

  private runProjectSetRequests(offtaker: Offtaker) {
    const projectSetCreateRequests = [];
    const configFormsKeys = Object.keys(this.configurationForms);
    const currentPsIndexes = offtaker?.projectSets?.map(ps => ps.name).filter(name => name != null).map(name => {
      if (parseInt(name)) {
        return parseInt(name);
      }
      return name;
    });
    const availablePsIndexes = this.getAvailablePsIndexes(currentPsIndexes);
    let index = 0;
    configFormsKeys.forEach(key => {
      this.configurationForms[key].forEach((form: UntypedFormGroup) => {
        let name;
        if (!isNil(form.controls['name'].value)) {
          name = form.controls['name'].value;
        } else {
          const splice = availablePsIndexes.splice(0, 1)[0];
          name = splice;
        }
        const ps = new ProjectSet({
          id: form.controls['id'].value,
          developerOrganization: this.user.organization,
          country: form.controls['country'].value,
          offtaker: offtaker,
          totalProjects: form.controls['quantity'].value,
          gridTied: form.controls['gridTied'].value,
          batteryStorage: form.controls['batteryStorage'].value,
          dieselGenerator: form.controls['dieselGenerator'].value,
          currencyType: form.controls['currency'].value,
          stage: form.controls['projectStage'].value,
          name: name
        });
        if (ps.id) {
          projectSetCreateRequests.push(this._projectSetService.update(ps).pipe(take(1)));
        } else {
          projectSetCreateRequests.push(this._projectSetService.create(ps).pipe(take(1)));
        }
        index++;
      });
    });
    forkJoin(projectSetCreateRequests)
      .pipe(take(1))
      .subscribe((response: ProjectSet[]) => {
        this._eventService.success(this._translateService.instant('offtaker.created'));
        this.close.emit(true);
        this._router.navigate([`/oes/projects/ci-customers/${ offtaker.id }/overview`], { queryParams: { bc: offtaker.name } });
      });
  }

  addSetup(countryCode, existingProjectSet?: ProjectSet) {
    const configurationForm = this.buildConfigForm(countryCode);
    this.configurationForms[countryCode].push(configurationForm);
    if (existingProjectSet) {
      this.patchConfigForm(configurationForm, countryCode, existingProjectSet);
    }
  }

  submitActive(): boolean {
    if (this.offtakerForm.invalid) {
      return false;
    }
    let result = true;
    const keys = Object.keys(this.configurationForms);
    keys?.forEach(key => {
      this.configurationForms[key]?.forEach((configurationForm: UntypedFormGroup) => {
        if (configurationForm.invalid) {
          result = false;
        }
      });
    });
    return result;
  }

  private appendCountriesWithIds(projectSets: ProjectSet[]) {
    this.configurationForms = {};
    projectSets.forEach(ps => {
      const country = ps.country;
      if (!this.configurationForms[country.code]) {
        const configurationForm = this.buildConfigForm(country.code);
        this.patchConfigForm(configurationForm, country.code, ps);
        this.configurationForms[country.code] = [];
        this.configurationForms[country.code].push(configurationForm);
      } else {
        this.addSetup(country.code, ps);
      }
    });
  }


  private appendAddedCountries(countryIds: string[]) {
    this.selectedCountries = countryIds?.map(country => this.countryOptions?.find(c => c.id === country));
    this.selectedCountries?.forEach(country => {
      if (!this.configurationForms[country.code]) {
        const configurationForm = this.buildConfigForm(country.code);
        this.configurationForms[country.code] = [];
        this.configurationForms[country.code].push(configurationForm);
      }
    });
  }

  private deleteRemovedCountries() {
    const countryKeys = Object.keys(this.configurationForms);
    const selectedCountryCodes = this.selectedCountries?.map(sc => sc.code);
    const keysToRemove = countryKeys?.filter(countryKey => !selectedCountryCodes?.includes(countryKey));
    keysToRemove.forEach(key => delete this.configurationForms[key]);
  }

  countryChanged(countries: Country[]) {
    this.updateCountrySetups(countries.map(c => c.id));
  }

  private buildConfigForm(countryCode: string): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(null),
      country: new UntypedFormControl({ value: this.countryOptions?.find(option => option.code === countryCode), disabled: true }),
      gridTied: new UntypedFormControl(false, Validators.required),
      batteryStorage: new UntypedFormControl(false, Validators.required),
      dieselGenerator: new UntypedFormControl(false, Validators.required),
      currency: new UntypedFormControl(null, Validators.required),
      quantity: new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1)]),
      projectStage: new UntypedFormControl(null, Validators.required),
      name: new UntypedFormControl(null)
    });
  }

  private patchConfigForm(form: UntypedFormGroup, countryCode: string, projectSet: ProjectSet) {
    form.controls['id'].patchValue(projectSet.id);
    form.controls['country'].patchValue(this.countryOptions?.find(option => option.code === countryCode));
    form.controls['gridTied'].patchValue(projectSet.gridTied);
    form.controls['batteryStorage'].patchValue(projectSet.batteryStorage);
    form.controls['dieselGenerator'].patchValue(projectSet.dieselGenerator);
    form.controls['currency'].patchValue(projectSet.currencyType);
    form.controls['quantity'].patchValue(projectSet.totalProjects);
    form.controls['projectStage'].patchValue(projectSet.stage);
    form.controls['name'].patchValue(projectSet.name);
  }

  removeConfig(country: Country, form: UntypedFormGroup) {
    const index = this.configurationForms[country.code].findIndex(f => f === form);
    this.configurationForms[country.code].splice(index, 1);
    if (!this.configurationForms[country.code].length) {
      const removeId: string = country.id;
      const countryIds: string[] = this.offtakerForm.controls['selectOption'].value;
      const indexToRemove = countryIds.findIndex(id => id === removeId);
      countryIds.splice(indexToRemove, 1);
      this.offtakerForm.controls['selectOption'].patchValue(countryIds);
    }
  }
}
