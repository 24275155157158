import { Injectable } from '@angular/core';
import { ComponentType } from '../../shared/component-type.enum';
import { GenerationDesign } from '../../shared/generation-design.model';
import { SourceType } from '../../shared/source-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SystemSelectionTabService {

  constructor() {}


  public createHomerExisting(generationDesign: GenerationDesign): any {
    const componentStatus =  {};
    const componentTypes = Object.keys(ComponentType);
    componentTypes.forEach(componentType => {
      const inUse = !generationDesign.projectGenerationDesignItems.every(item => item.type !== componentType);
      componentStatus[componentType] = {
        'available': !(generationDesign && generationDesign.sourceType === SourceType.Homer && inUse),
        'inUse': inUse
      };
    });
    return componentStatus;
  }
}
