import { environment } from '@environments/environment';
import { FinanceDealStatus } from '@project/offtakers/finance-deal-status.enum';

export class InvestorProjectSetPortfolioConnectionApiConstants {
  public static base = environment.serverUri + `api/${environment.apiVersion}/finance-project-set-portfolio-connections`;

  public static connections = {
    list: () => {
      return `${InvestorProjectSetPortfolioConnectionApiConstants.base}`;
    },
    detail: (projectSetPortfolioConnectionId: string) => {
      return `${InvestorProjectSetPortfolioConnectionApiConstants.base}/${projectSetPortfolioConnectionId}`;
    },
    updateStatus: (projectSetPortfolioConnectionId: string, status: FinanceDealStatus) => {
      return `${InvestorProjectSetPortfolioConnectionApiConstants.base}/${projectSetPortfolioConnectionId}/status/${status}`;
    }
  };
}
