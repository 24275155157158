import { Component, OnInit, Input } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { DeveloperInformation } from '@organization-management/organization/developer-information/developer-information.model';
import { DeveloperProfileService } from '@shared/components/developer-profiles/developer-profile.service';
import { DeveloperProfileImageService } from '@shared/components/developer-profiles/developer-profile-image.service';
import { OrganizationImage } from '@organization-management/organization/organization-image.model';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { environment } from '@environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-developer-profile',
  templateUrl: './developer-profile.component.html',
  styleUrls: ['./developer-profile.component.scss'],
  providers: []
})
export class DeveloperProfileComponent implements OnInit {
  @Input() id: string;

  developerInfo: DeveloperInformation;
  logoUri: string;
  organization: Organization;
  organizationImage: OrganizationImage;
  pictureUris: string[];
  tagTypes: TagType[];

  constructor(private _developerProfileService: DeveloperProfileService,
              private _developerProfileImageService: DeveloperProfileImageService) {
  }

  ngOnInit() {
    this.getDeveloperProfile();
  }

  getDeveloperProfile() {
    this._developerProfileService.getDeveloperInformation(this.id)
    .pipe(take(1))
    .subscribe((developerInfo: DeveloperInformation) => {
      this.developerInfo = new DeveloperInformation(developerInfo);

      this._developerProfileService.getOrganizationDetail(this.id)
        .subscribe(organization => {
          this.organization = new Organization(organization);
          this.tagTypes = [{
            docTagItemId: this.developerInfo.id,
            docTagType: DOCUMENT_TAG_TYPE.DEVELOPER_INFORMATION,
            name: (DOCUMENT_TAG_TYPE.DEVELOPER_INFORMATION).replace('_', ' ').toLowerCase()
          }, {
            docTagItemId: organization.id,
            docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION,
            name: organization.name
          }];
          this.getDeveloperImages();
          if (this.organization.logoImage && this.organization.logoImage.id) {
            this.logoUri = `${environment.organizationCdnUri}/${this.organization.logoImage.id}`;
          }
        });
    });
  }

  getDeveloperImages() {
    this._developerProfileImageService.list(this.organization.id)
      .subscribe((orgImages: OrganizationImage[]) => {
        if (orgImages?.length > 0) {
          this.organizationImage = orgImages[0];
          this.pictureUris = [`${environment.organizationCdnUri}/${this.organizationImage.id}`];
        }
      });
  }

  get accessControl (): any {
    return new FilesActions({
      download: true,
      preview: true
    });
  }
}
