import { environment } from '@environments/environment';


export class SharingApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;
  public static sharing = {
    find: (projectId: string, connectionId: string) => {
      return `${SharingApiConstants.projects}/${projectId}/connections/${connectionId}/interactions`;
    },
    create: (projectId: string, connectionId: string) => {
      return `${SharingApiConstants.projects}/${projectId}/connections/${connectionId}/interactions`;
    },
    get: (projectId: string, connectionId: string, interactionId: string) => {
      return `${SharingApiConstants.projects}/${projectId}/connections/${connectionId}/interactions/${interactionId}`;
    },
    update: (projectId: string, connectionId: string, interactionId: string) => {
      return `${SharingApiConstants.projects}/${projectId}/connections/${connectionId}/interactions/${interactionId}`;
    },
    delete: (projectId: string, connectionId: string, interactionId: string) => {
      return `${SharingApiConstants.projects}/${projectId}/connections/${connectionId}/interactions/${interactionId}`;
    }
  };
}
