<div>
  <ng-container *ngIf="currentRole === 'FINANCE_USER' && programOwner">
    <div class="d-flex flex-row justify-content-between list-header">
      <button class="btn btn-primary"
              (click)="makePayoutModal.show()">{{ 'connections-payout.make-payout' | translate }}</button>
      <!-- Hiding for now -->
      <!-- <ng-container *ngIf="project.projectPayoutCurrency !== 'USD'">
        <button class="btn btn-secondary"
                (click)="applyExchangeRateModal.show()">{{ 'connections-payout.apply-exchange-rate' | translate }}</button>
      </ng-container> -->
    </div>
  </ng-container>
  <div class="d-flex flex-row justify-content-end sub-actions-container">
    <oes-grid-sub-action
      *ngIf="gridApi"
      [gridState]="gridState"
      [defaultGridState]="defaultGridState"
      (action)="subAction($event)">
    </oes-grid-sub-action>
  </div>
  <div class="ag-grid-header-sticky top-offset" oesAgColumns oesAgHorizontalScroll>
    <ag-grid-angular
      id="connections-payouts"
      style="width: auto;"
      class="grid ag-theme-alpine"
      [overlayNoRowsTemplate]="'connections-payout.grid.empty-state' | translate"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>

<!-- Make Payout Modal -->
<oes-modal-dialog
  #makePayoutModal
  [activateSubmit]="connectionsPayoutForm.invalid"
  [size]="'large'"
  [title]="'connections-payout.make-payout' | translate"
  (submit)="onSubmit($event)">
  <form [formGroup]="connectionsPayoutForm">
    <div class="formGroup">

      <div class="indicators">

        <!-- Total Claim Value -->
        <div class="item border-right">
          <ng-container *ngIf="project.connectionsAmountPotential || project.connectionsAmountPotential === 0; else noConnectionsAmount">
            <ng-container *ngIf="project.projectPayoutCurrency === 'USD'; else altCurrencyTotalClaimValue">
              <div class="indicate">{{ project.connectionsAmountPotential | currency:'USD':'symbol':'1.0-0' }}</div>
            </ng-container>
            <ng-template #altCurrencyTotalClaimValue>
              <div class="indicate">{{ project.projectPayoutCurrency }} {{ project.connectionsAmountPotentialInCurrency | number:'1.2-6' }}</div>
            </ng-template>
          </ng-container>
          <ng-template #noConnectionsAmount>
            <div class="indicate">-</div>
          </ng-template>
          <div class="title">{{ 'connections-payout.total-claim-amount' | translate }}</div>
        </div>

        <!-- Connections Approved to Date -->
        <div class="item border-right">
          <div class="indicate" [ngClass]="{ 'strikethrough' : connectionsPayoutForm.controls['connectionsApproved']?.value > 0 }">
            {{ project.totalConnectionsApproved }}
          </div>
          <div *ngIf="connectionsPayoutForm.controls['connectionsApproved']?.value > 0" class="new-indicate">
            <div class="indicate">{{ project.totalConnectionsApproved + connectionsPayoutForm.controls['connectionsApproved']?.value }}</div>
          </div>

          <div class="title">{{ 'connections-payout.connections-approved' | translate }}</div>
        </div>

        <!-- Connections Paid to Date -->
        <div class="item border-right">
          <div class="indicate" [ngClass]="{ 'strikethrough' : connectionsPayoutForm.controls['connectionsPaid']?.value > 0 }">
            {{ project.totalConnectionsPaid }}
          </div>
          <div *ngIf="connectionsPayoutForm.controls['connectionsPaid']?.value > 0" class="new-indicate">
            <div class="indicate">{{ project.totalConnectionsPaid + connectionsPayoutForm.controls['connectionsPaid']?.value }}</div>
          </div>

          <div class="title">{{ 'connections-payout.connections-paid' | translate }}</div>
        </div>

        <!-- Amount Paid to Date -->
        <div class="item border-right">
          <ng-container *ngIf="project.projectPayoutCurrency === 'USD'; else altCurrencyAmountPaid">
            <div class="indicate" [ngClass]="{ 'strikethrough' : connectionsPayoutForm.controls['payoutAmount']?.value > 0 }">
              {{ project.connectionsAmountPaid | currency:'USD':'symbol':'1.0-0' }}
            </div>

            <div *ngIf="connectionsPayoutForm.controls['payoutAmount']?.value > 0" class="new-indicate">
              <div class="indicate">
                {{ (parseFloat(project.connectionsAmountPaid) + parseFloat(connectionsPayoutForm.controls['payoutAmount']?.value)) | currency:'USD':'symbol':'1.0-0' }}
              </div>
            </div>
          </ng-container>
          <ng-template #altCurrencyAmountPaid>
            <div class="indicate" [ngClass]="{ 'strikethrough' : connectionsPayoutForm.controls['payoutAmountInCurrency']?.value > 0 }">
              {{ project.projectPayoutCurrency }} {{ project.connectionsAmountPaidInCurrency | number:'1.2-6' }}
            </div>

            <div *ngIf="connectionsPayoutForm.controls['payoutAmountInCurrency']?.value > 0" class="new-indicate">
              <div class="indicate">
                {{ project.projectPayoutCurrency }} {{ (parseFloat(project.connectionsAmountPaidInCurrency) + parseFloat(connectionsPayoutForm.controls['payoutAmountInCurrency']?.value)) | number:'1.2-6' }}
              </div>
            </div>
          </ng-template>

          <div class="title">{{ 'connections-payout.amount-paid' | translate }}</div>
        </div>

        <!-- Exchange Rate (When not in USD) -->
        <ng-container *ngIf="project.projectPayoutCurrency !== 'USD'">
          <div class="item item-grey">
            <div class="indicate">{{ project.projectPayoutExchangeRate | number:'1.2-6' }}</div>
            <div class="title">
              {{ 'connections-payout.exchange-rate.exchange-rate' | translate }} ({{ project.projectPayoutCurrency }})
              <i class="fa fa-question-circle primary"
                tooltip="{{ 'connections-payout.exchange-rate-tt' | translate }}"
                placement="top"
                containerClass="tool-tip">
              </i>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="input-row">
        <!-- Name -->
        <div class="input-item">
          <label for="name">{{ 'connections-payout.columns.name' | translate }}</label>
          <input class="formControl"
                 formControlName="name"
                 id="name"
                 name="name"
                 placeholder="{{ 'connections-payout.columns.name' | translate }}"
                 type="text" />
        </div>

        <!-- Payout Type -->
        <div class="input-item">
          <label class="required"
                for="payout-type">{{ 'connections-payout.columns.payout-type' | translate }}</label>
          <div class="select-wrapper">
            <select formControlName="payoutType"
                    class="form-control">
              <option *ngFor="let payoutType of payoutTypes"
                      [ngValue]="payoutType">{{ 'connections-payout.type.' + payoutType | translate }}</option>
            </select>
          </div>
        </div>

        <!-- Connections Approved -->
        <div class="input-item">
          <label for="connections-approved">{{ 'connections-payout.columns.connections-approved' | translate }}</label>
          <input class="formControl"
                 formControlName="connectionsApproved"
                 id="connections-approved"
                 name="connections-approved"
                 placeholder="{{ 'connections-payout.columns.connections-approved' | translate }}"
                 type="number" />
        </div>

        <!-- Connections Paid -->
        <div class="input-item">
          <label for="connections-paid">{{ 'connections-payout.columns.connections-paid' | translate }}</label>
          <input class="formControl"
                 formControlName="connectionsPaid"
                 id="connections-paid"
                 name="connections-paid"
                 placeholder="{{ 'connections-payout.columns.connections-paid' | translate }}"
                 type="number" />
        </div>
      </div>

      <div class="input-row">
        <!-- Payout Amount -->
        <div class="input-item">
          <label class="required"
                for="payout-amount">{{ 'connections-payout.columns.payout-amount' | translate }}</label>
          <input (keyup)="updateFormValues($event)"
                (focus)="onPayoutFocus($event)"
                (blur)="onPayoutBlur($event)"
                class="formControl"
                formControlName="payoutAmount"
                id="payout-amount"
                name="payout-amount"
                placeholder="{{ 'connections-payout.columns.payout-amount' | translate }}"
                type="number" />
        </div>

        <!-- Payout Amount in Currency -->
        <ng-container *ngIf="project.projectPayoutCurrency !== 'USD'">
          <div class="input-item">
            <label class="required"
                  for="payout-amount-in-currency">{{ 'connections-payout.columns.payout-amount-in-currency' | translate }} ({{ project.projectPayoutCurrency }})</label>
              <input (keyup)="updateFormValues($event)"
                    (focus)="onPayoutFocus($event)"
                    (blur)="onPayoutBlur($event)"
                    class="formControl"
                    formControlName="payoutAmountInCurrency"
                    id="payout-amount-in-currency"
                    name="payout-amount-in-currency"
                    placeholder="{{ ('connections-payout.columns.payout-amount-in-currency' | translate) + ' ' + '(' + project.projectPayoutCurrency + ')' }}"
                    type="number" />
          </div>
        </ng-container>

        <!-- Total Claim Percent -->
        <ng-container *ngIf="project.connectionsAmountPotential">
          <div class="input-item">
            <label class="required"
                  for="total-claim-percent">{{ 'connections-payout.columns.total-claim-percent' | translate }}</label>
              <input (keyup)="updateFormValues($event)"
                    (focus)="onPayoutFocus($event)"
                    (blur)="onPayoutBlur($event)"
                    class="formControl"
                    formControlName="totalClaimPercent"
                    id="total-claim-percent"
                    name="total-claim-percent"
                    placeholder="{{ 'connections-payout.columns.total-claim-percent' | translate }}"
                    type="number" />
          </div>
        </ng-container>
      </div>

      <!-- Notes -->
      <div class="input-item">
        <label for="notes">{{ 'connections-payout.columns.notes' | translate }}</label>
        <input class="formControl"
               formControlName="notes"
               id="notes"
               name="notes"
               placeholder="{{ 'connections-payout.columns.notes' | translate }}"
               type="text" />
      </div>
    </div>
  </form>
</oes-modal-dialog>

<!-- Confirmation Modal -->
<oes-modal-dialog
  #confirmationModal
  [size]="'small'"
  [title]="'connections-payout.make-payout' | translate"
  [submitButtonText]="'buttons.create-payout' | translate"
  (submit)="onConfirm($event)">
  {{ 'connections-payout.confirm-text' | translate }}
</oes-modal-dialog>

<!-- Apply Rate Modal -->
<oes-modal-dialog
  #applyExchangeRateModal
  [size]="'small'"
  [title]="'connections-payout.apply-exchange-rate' | translate"
  [submitButtonText]="'buttons.apply' | translate"
  (submit)="onConfirmApply($event)">
  {{ 'connections-payout.apply-confirm-text' | translate }}
</oes-modal-dialog>
