import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Project } from '@project/shared/project.model';
import { ProjectType } from '@project/shared/project-type.enum';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-grid-cell-create-project-button',
  templateUrl: './cell-create-project-button.component.html',
  styleUrls: ['./cell-create-project-button.component.scss']
})
export class CellCreateProjectButtonComponent implements ICellRendererAngularComp {
  public params;
  private project: Project;
  public buttonDisabled = true;

  constructor(private _eventService: EventService,
              private _translateService: TranslateService) { }

  // ICellRendererAngularComp requires
  agInit(params: any): void {
    this.params = params;
    this.project = params.data ? params.data.odysseyProject : undefined;
    this.buttonDisabled = params.data.odysseyProject.id !== 'create-project';
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  // call from button
  public click() {
    if (this.validate) {
      this.params.context.gridService.cellButtonClicked(this.project);
    } else {
      this._eventService.error(this._translateService.instant('form.error.fill-required'));
    }
  }

  get validate(): any {
    if (this.project.id === 'create-project') {
      switch (this.project.projectType) {
        case ProjectType.ci:
        case ProjectType.minigrid:
          return this.project.name && this.project.name !== '' &&
                 this.project.latitude && this.project.latitude >= -90 && this.project.latitude <= 90 &&
                 this.project.longitude && this.project.longitude >= -90 && this.project.longitude <= 90 &&
                 this.project.country &&
                 this.project.region &&
                 this.project.cod;
        case ProjectType.shs:
          return this.project.name && this.project.name !== '' &&
                 this.project.country;
        default:
          return false;
      }
    }
    return false;
  }

}
