import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, Validator,
         AbstractControl, ValidationErrors, UntypedFormArray } from '@angular/forms';
import { FinanceBusinessPlan } from '@finance/developer-portfolio/business-plan/finance-business-plan.model';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'oes-business-plan-risks',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessPlanRisksComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessPlanRisksComponent),
      multi: true
    }
  ]
})
export class BusinessPlanRisksComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() financeBusinessPlan: FinanceBusinessPlan;
  @Input() isPastSubmissionDeadline = false;

  public risksFormGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.risksFormGroup = new UntypedFormGroup({
      financeSummaryRisks: this.addRiskForms(this.financeBusinessPlan, this.isPastSubmissionDeadline),
    });
  }

  private addRiskForms(financeBusinessPlan: FinanceBusinessPlan, isPastSubmissionDeadline: boolean): UntypedFormArray {
   const arr = financeBusinessPlan.financeSummaryRisks.map(risk => {
      return new UntypedFormGroup({
        id: new UntypedFormControl({value: risk.id, disabled: isPastSubmissionDeadline}, null),
        description: new UntypedFormControl({value: risk.description, disabled: isPastSubmissionDeadline}, null)
      });
    });
    return new UntypedFormArray(arr);
  }

  public addRiskRow() {
    (<UntypedFormArray>this.risksFormGroup.controls.financeSummaryRisks).push(
      new UntypedFormGroup({
        description: new UntypedFormControl('')
      })
    );
    this.risksFormGroup.markAsTouched();
  }

  public removeRiskRow(index: number) {
    (<UntypedFormArray>this.risksFormGroup.controls.financeSummaryRisks).removeAt(index);
    this.risksFormGroup.markAsTouched();
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.risksFormGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.risksFormGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.risksFormGroup.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.risksFormGroup.disable() : this.risksFormGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.risksFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
