import { BaseModel } from '@shared/base.model';

export class FilesActions extends BaseModel<FilesActions> {
  copy = false;
  create = false;
  delete = false;
  download = false;
  move = false;
  preview = false;
  search = false;
  share = false;
  upload = false;
  tag = false;
  editName = false;

  constructor(dataRoomActionInfo: any) {
    super();

    // TODO: suppose to be boolean but it will have undefined when a key is not presented.
    this.copy = dataRoomActionInfo.copy;
    this.create = dataRoomActionInfo.create;
    this.delete = dataRoomActionInfo.delete;
    this.download = dataRoomActionInfo.download;
    this.move = dataRoomActionInfo.move;
    this.preview = dataRoomActionInfo.preview;
    this.search = dataRoomActionInfo.search;
    this.share = dataRoomActionInfo.share;
    this.upload = dataRoomActionInfo.upload;
    this.tag = dataRoomActionInfo.tag;
    this.editName = dataRoomActionInfo.editName;
  }
}
