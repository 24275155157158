import {Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy} from '@angular/core';
import { GenerationCapacity } from '../generation-system-component.model';
import { ComponentType } from '@project/detail/generation-design/shared/component-type.enum';
import { DragDropService } from '@project/detail/generation-design/shared/drag-drop.service';
import { ProjectService } from '@project/shared/project.service';

@Component({
  selector: 'oes-generation-system-component',
  templateUrl: './generation-system-component.component.html',
  styleUrls: ['./generation-system-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerationSystemComponentComponent implements OnInit, OnChanges {
  @Input() inUse: boolean;
  @Input() name: string;
  @Input() capacity: GenerationCapacity;
  @Input() componentType: ComponentType;
  @Input() draggable = false;
  @Input() extra?: any;
  public currency: string = 'USD';
  public symbol: string = 'symbol';
  public icon   = '';
  public type = ComponentType;
  public dragDropId = '';

  constructor(private _dragDropService: DragDropService, private _projectService: ProjectService) {}

  ngOnInit() {
    if (this.draggable) {
      this.dragDropId = this._dragDropService.dragDropId;
    }
    switch (this.componentType) {
      case ComponentType.PANELS:
        this.icon = '../../../../assets/images/generation-design/icn-solar-pv.svg';
        break;
      case ComponentType.GRID:
        this.icon = '../../../../assets/images/generation-design/icn-utility-grid.svg';
        break;
      case  ComponentType.CHARGE_CONTROLLER:
        this.icon = '../../../../assets/images/generation-design/icn-charge-controller.svg';
        break;
      case ComponentType.LOAD:
        this.icon = '../../../../assets/images/generation-design/icn-loads.svg';
        break;
      case ComponentType.STORAGE:
        this.icon = '../../../../assets/images/generation-design/icn-storage.svg';
        break;
      case ComponentType.SYSTEM_INVERTER:
        this.icon = '../../../../assets/images/generation-design/icn-system-inverter.svg';
        break;
      case ComponentType.GENERATOR:
        this.icon = '../../../../assets/images/generation-design/icn-generator.svg';
        break;
      case ComponentType.SOLAR_INVERTER:
        this.icon = '../../../../assets/images/generation-design/icn-solar-inverter.svg';
        break;
      case ComponentType.BATTERY_INVERTER:
        this.icon = '../../../../assets/images/generation-design/icn-battery-inverter.svg';
        break;
    }

    const { currency, symbol } = this._projectService.currencyInfo();
    this.currency = currency;
    this.symbol = symbol;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // draggable is changed
    if (simpleChanges?.draggable) {
      if (this.draggable) {
        this.dragDropId = this._dragDropService.dragDropId;
      } else {
        this.dragDropId = '';
      }
    }
  }

}
