import { Component, OnDestroy, OnInit, Input, ViewChild, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup, Validator,
  AbstractControl, ValidationErrors } from '@angular/forms';
import { delay, takeUntil } from 'rxjs/operators';
import { EventService } from '@shared/event.service';
import { Project } from '@project/shared/project.model';
import { PROJECT_PAGE } from '@project/shared/project-page.enum';
import { ProjectPageStatusService } from '@project/shared/project-page-status.service';
import { ProjectService } from '@project/shared/project.service';
import { ProjectSpecificationFormComponent } from '../specification-form/specification-form.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';

@Component({
  selector: 'oes-project-specification',
  templateUrl: 'specification.component.html',
  styleUrls: ['specification.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectSpecificationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProjectSpecificationComponent),
      multi: true
    }
  ]
})
export class ProjectSpecificationComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @ViewChild(ProjectSpecificationFormComponent, {static: false}) projectSpecificationFormComponent: ProjectSpecificationFormComponent;
  @Input() readOnly: boolean;
  @Output() save: EventEmitter<boolean> = new EventEmitter();

  project: Project;
  specificationGroup: UntypedFormGroup;

  private message = '';
  private nextUrl: string;
  private ngUnsubscribe: Subject<any> = new Subject();
  private user: User;

  constructor(private _projectService: ProjectService,
              private _router: Router,
              private _translate: TranslateService,
              private _userService: UserService,
              private _projectPageStatusService: ProjectPageStatusService,
              private _eventService: EventService) {
    this._translate.get('form.changes-saved')
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      this.message = message;
    });
  }

  ngOnInit() {
    this.specificationGroup = new UntypedFormGroup({
      specificationFormControl: new UntypedFormControl('')
    });

    if (this._projectService.projectId && this._projectService.projectId !== '') {
      this.getProject(this._projectService.projectId);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private getProject(id: string) {
    this._projectService.detail(id)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((project: Project) => {
      this.project = project;
      this.getPagePermissions();
    });
  }

  private getPagePermissions() {
    this._userService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
  }

  public onSubmit(nextUrl?: string) {
    this.nextUrl = nextUrl;
    this.projectSpecificationFormComponent.onSubmit();
  }

  // callback: oes-project-specification-form
  public saved(event) {
    this.needFinancialModelUpdate(event);

    this.specificationGroup.markAsUntouched();
    this.specificationGroup.markAsPristine();
    this.save.emit(true);
    this._eventService.success(this.message);
    // When onSubmit call from a modal
    if (this.nextUrl && this.nextUrl !== '') {
      this._router.navigateByUrl(this.nextUrl);
    } else {
      // update project information
      this._projectService.programUpdate();
    }
  }

  private needFinancialModelUpdate(project: Project) {
    if (project) {
      // Tariff effects the business model calculation.
      if (project.exchangeRate !== this.project.exchangeRate) {
        this._projectPageStatusService.setPageUpdated(PROJECT_PAGE.SITE);
      }
    }
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val?.projectTypeControl) {
      this.specificationGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.specificationGroup.valueChanges.pipe(
      // prevent Previous value: 'ng-pristine: true'. Current value: 'ng-pristine: false' error
      delay(0)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.specificationGroup.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.specificationGroup.disable() : this.specificationGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.specificationGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
