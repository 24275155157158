import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Milestone } from "../../milestone.model";
import { Project } from "@project/shared/project.model";
import { ModalDialogComponent } from "@shared/components/modal-dialog/modal-dialog.component";
import { ProjectMilestonesService } from "../../project-milestones.service";
import { Subject, takeUntil } from "rxjs";
import { DynamicFormService } from "@shared/components/dynamic-form/dynamic-form.service";
import { EventService } from "@shared/event.service";
import { TranslateService } from "@ngx-translate/core";
import { DocumentDataService } from "@shared/components/files/shared/document-data.service";
import { FORM_TYPE } from "@program/shared/formio-custom-components/form-type.enum";
import { User } from "@user/user.model";
import { MilestoneService } from "../../milestone.service";

@Component({
  selector: 'oes-milestone-content-form',
  templateUrl: './milestone-content-form.component.html',
  styleUrls: ['./milestone-content-form.component.scss'],
})
export class MilestoneContentFormComponent implements OnInit, OnDestroy {
  @ViewChild(ModalDialogComponent, {static: false}) modalDialogComponent: ModalDialogComponent;

  @Input() formJson: any;
  @Input() milestone: Milestone;
  @Input() project: Project;
  @Input() readOnly: boolean;
  @Input() user: User;

  formValid: boolean = false;
  isDirty: boolean = false;
  updateUser: string;
  saveDate: Date;

  private firstChange = true;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _documentDataService: DocumentDataService,
              private _dynamicFormService: DynamicFormService,
              private _eventService: EventService,
              private _milestoneService: MilestoneService,
              private _projectMilestonesService: ProjectMilestonesService,
              private _translateService: TranslateService) {}

  ngOnInit() {
    this._documentDataService.setFileUploadStorageModel(
      this.user.organization.id,
      FORM_TYPE.MILESTONE_FORM,
      this.milestone.id
    );
    this.updateUser = this.formJson?.metadata?.updateUser;
    this.saveDate = this.formJson?.metadata?.updated;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  fileSave() {
    this._milestoneService.triggerRefetchMilestones();
  }

  formChanged() {
    if (this.firstChange) {
      this.firstChange = false;
    } else {
      this.isDirty = true;
    }
  }

  formSave(event) {
    this.formJson.metadata = {...this.formJson.metadata, valid: this.formValid };
    this._projectMilestonesService.saveFormData(
      this.project.id,
      this.milestone.id,
      this._dynamicFormService.updateDefaultValue(this.formJson, event),
    )
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((_) => {
      this._eventService.success(this._translateService.instant('milestones.form.saved'));
      this.isDirty = false;
    });
  }
}
