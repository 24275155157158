<h2>{{ 'offtaker.manage.portfolio-heading' | translate }}</h2>
<p class="sub-grey">{{ 'offtaker.manage.portfolio-sub-heading' | translate }}</p>
<oes-grid-sub-action
  class="manage-site-group-portfolios-sub-actions"
  *ngIf="gridApi"
  [gridState]="gridState"
  [defaultGridState]="defaultGridState"
  (action)="subAction($event)">
</oes-grid-sub-action>
<div class="grid-container ag-grid-header-sticky" oesAgHorizontalScroll>
  <ag-grid-angular
    #ManageC&IPortfoliosList
    id="ManageC&IPortfoliosList"
    style="width: auto; min-height: 400px;"
    class="grid ag-theme-alpine"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (filterChanged)="gridStatusChanged($event, 'filter')"
    (sortChanged)="gridStatusChanged($event, 'sort')"
    (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
  </ag-grid-angular>
</div>
