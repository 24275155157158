import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-open-organization-profile',
  templateUrl: './open-organization-profile.component.html',
  styleUrls: ['./open-organization-profile.component.scss']
})
export class OpenOrganizationProfileComponent implements ICellRendererAngularComp {
  organizationName = '';

  private params;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.organizationName = this.params.value;
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  openModal() {
    if (this.params && this.params.context && this.params.context.gridService) {
      this.params.context.gridService.openProfile(this.params.data.id);
    }
  }
}
