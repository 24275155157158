<oes-modal-dialog
  [title]="'buttons.' + type | translate"
  [submitButtonText]="'buttons.' + type | translate"
  (submit)="callFunction(type)"
  (close)="close()">
  <!-- the below goes to dialog-body -->
  <h5 class="bold">{{'data-room.dialogs.select-destination' | translate}}</h5>
  <form [formGroup]="formGroup">
    <oes-dropdown
      *ngIf="selectOptions"
      id="folderSelect{{type}}"
      [parentFormGroup]="formGroup"
      [initialSelections]="[selectOptions[0]]"
      [items]="selectOptions"
      [selection]="'single'"
      [enableSearch]="true">
    </oes-dropdown>
  </form>
</oes-modal-dialog>
