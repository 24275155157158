import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray, AbstractControl } from '@angular/forms';

import { FinanceBusinessPlan } from '../finance-business-plan.model';
import { FinanceBusinessPlanRisk } from '../finance-business-plan-risk.model';

@Injectable()
export class FormDefaultService {

  constructor() {
  }

  public getFormValues(oldFinanceBusinessPlan: FinanceBusinessPlan, control: AbstractControl): FinanceBusinessPlan {
    const financeBusinessPlan: FinanceBusinessPlan = oldFinanceBusinessPlan;
    // Project Sites
    if (control.value.projectSitesControl.siteInnovationDescription && control.value.projectSitesControl.siteInnovationDescription.textControl !== '') {
      financeBusinessPlan.siteInnovationDescription = control.value.projectSitesControl.siteInnovationDescription.textControl;
    }
    if (control.value.projectSitesControl.siteDescription && control.value.projectSitesControl.siteDescription.textControl !== '') {
      financeBusinessPlan.siteDescription = control.value.projectSitesControl.siteDescription.textControl;
    }
    if (control.value.projectSitesControl.siteImpactDescription && control.value.projectSitesControl.siteImpactDescription.textControl !== '') {
      financeBusinessPlan.siteImpactDescription = control.value.projectSitesControl.siteImpactDescription.textControl;
    }
    if (control.value.projectSitesControl.siteOwnershipDescription && control.value.projectSitesControl.siteOwnershipDescription.textControl !== '') {
      financeBusinessPlan.siteOwnershipDescription = control.value.projectSitesControl.siteOwnershipDescription.textControl;
    }
    if (control.value.projectSitesControl.siteSelectionDescription && control.value.projectSitesControl.siteSelectionDescription.textControl !== '') {
      financeBusinessPlan.siteSelectionDescription = control.value.projectSitesControl.siteSelectionDescription.textControl;
    }
    if (control.value.projectSitesControl.sitePermitDescription && control.value.projectSitesControl.sitePermitDescription.textControl !== '') {
      financeBusinessPlan.sitePermitDescription = control.value.projectSitesControl.sitePermitDescription.textControl;
    }
    if (control.value.projectSitesControl.siteSustainabilityDescription && control.value.projectSitesControl.siteSustainabilityDescription.textControl !== '') {
      financeBusinessPlan.siteSustainabilityDescription = control.value.projectSitesControl.siteSustainabilityDescription.textControl;
    }
    // Customers
    if (control.value.customersControl.customerTypeDescription && control.value.customersControl.customerTypeDescription.textControl !== '') {
      financeBusinessPlan.customerTypeDescription = control.value.customersControl.customerTypeDescription.textControl;
    }
    if (control.value.customersControl.customerRelationsDescription && control.value.customersControl.customerRelationsDescription.textControl !== '') {
      financeBusinessPlan.customerRelationsDescription = control.value.customersControl.customerRelationsDescription.textControl;
    }
    if (control.value.customersControl.customerEducationDescription && control.value.customersControl.customerEducationDescription.textControl !== '') {
      financeBusinessPlan.customerEducationDescription = control.value.customersControl.customerEducationDescription.textControl;
    }
    if (control.value.customersControl.customerPricingDescription && control.value.customersControl.customerPricingDescription.textControl !== '') {
      financeBusinessPlan.customerPricingDescription = control.value.customersControl.customerPricingDescription.textControl;
    }
    // Other Activities
    if (control.value.otherActivitiesControl.equipmentLeasingDescription && control.value.otherActivitiesControl.equipmentLeasingDescription.textControl !== '') {
      financeBusinessPlan.equipmentLeasingDescription = control.value.otherActivitiesControl.equipmentLeasingDescription.textControl;
    }
    if (control.value.otherActivitiesControl.waterSanitationDescription && control.value.otherActivitiesControl.waterSanitationDescription.textControl !== '') {
      financeBusinessPlan.waterSanitationDescription = control.value.otherActivitiesControl.waterSanitationDescription.textControl;
    }
    if (control.value.otherActivitiesControl.smallBusinessFinancingDescription && control.value.otherActivitiesControl.smallBusinessFinancingDescription.textControl !== '') {
      financeBusinessPlan.smallBusinessFinancingDescription = control.value.otherActivitiesControl.smallBusinessFinancingDescription.textControl;
    }
    if (control.value.otherActivitiesControl.otherOpportunitiesDescription && control.value.otherActivitiesControl.otherOpportunitiesDescription.textControl !== '') {
      financeBusinessPlan.otherOpportunitiesDescription = control.value.otherActivitiesControl.otherOpportunitiesDescription.textControl;
    }
    if (control.value.otherActivitiesControl.equipmentLeasing) {
      financeBusinessPlan.equipmentLeasing = control.value.otherActivitiesControl.equipmentLeasing;
    }
    if (control.value.otherActivitiesControl.waterSanitation) {
      financeBusinessPlan.waterSanitation = control.value.otherActivitiesControl.waterSanitation;
    }
    if (control.value.otherActivitiesControl.smallBusinessFinancing) {
      financeBusinessPlan.smallBusinessFinancing = control.value.otherActivitiesControl.smallBusinessFinancing;
    }
    if (control.value.otherActivitiesControl.otherOpportunities) {
      financeBusinessPlan.otherOpportunities = control.value.otherActivitiesControl.otherOpportunities;
    }
    // Construction
    if (control.value.constructionControl.constructionDescription && control.value.constructionControl.constructionDescription.textControl !== '') {
      financeBusinessPlan.constructionDescription = control.value.constructionControl.constructionDescription.textControl;
    }
    if (control.value.constructionControl.maintenanceDescription && control.value.constructionControl.maintenanceDescription.textControl !== '') {
      financeBusinessPlan.maintenanceDescription = control.value.constructionControl.maintenanceDescription.textControl;
    }
    if (control.value.constructionControl.monitoringDescription && control.value.constructionControl.monitoringDescription.textControl !== '') {
      financeBusinessPlan.monitoringDescription = control.value.constructionControl.monitoringDescription.textControl;
    }
    if (control.value.constructionControl.designSpecStatus) {
      financeBusinessPlan.designSpecStatus = control.value.constructionControl.designSpecStatus;
    }
    if (control.value.constructionControl.budgetEstimatedStatus) {
      financeBusinessPlan.budgetEstimatedStatus = control.value.constructionControl.budgetEstimatedStatus;
    }
    if (control.value.constructionControl.detailedQuoteStatus) {
      financeBusinessPlan.detailedQuoteStatus = control.value.constructionControl.detailedQuoteStatus;
    }
    if (control.value.constructionControl.poIssuedStatus) {
      financeBusinessPlan.poIssuedStatus = control.value.constructionControl.poIssuedStatus;
    }
    if (control.value.constructionControl.executedAgreementsStatus) {
      financeBusinessPlan.executedAgreementsStatus = control.value.constructionControl.executedAgreementsStatus;
    }
    // Risks
    if (control.value.risksControl.financeSummaryRisks && control.value.risksControl.financeSummaryRisks.length > 0) {
      financeBusinessPlan.financeSummaryRisks = control.value.risksControl.financeSummaryRisks.map(items => {
        return new FinanceBusinessPlanRisk(items);
      });
    }
    return financeBusinessPlan;
  }
}
