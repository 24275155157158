<div class="procure-banner">
  <div class="banner-left">
    <h4>{{ 'banners.procure.title' | translate }}</h4>
    <h2>{{ 'banners.procure.main' | translate }}</h2>
    <p>{{ 'banners.procure.text' | translate }}</p>
    <a class="btn btn-blue" href="https://procure.odyssey.energy/?utm_source=platform_gen" target="_blank">{{ 'buttons.learn-more' | translate }}<img src='./assets/icons/line-icons/shared/white/arrow-right.svg' /></a>
  </div>
  <div class="banner-right">
    <img class="image-box-1" src="https://oes-hosted-images-public.s3.amazonaws.com/bpt-1.png" />
    <img class="image-box-2" src="https://oes-hosted-images-public.s3.amazonaws.com/bpt-2.png" />
    <img class="image-box-3" src="https://oes-hosted-images-public.s3.amazonaws.com/bpt-3.png" />
    <img class="image-box-4" src="https://oes-hosted-images-public.s3.amazonaws.com/bpt-4.png" />
    <img class="image-box-5" src="https://oes-hosted-images-public.s3.amazonaws.com/bpt-5.png" />
  </div>
</div>
