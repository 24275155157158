import {BaseModel} from '@shared/base.model';

export class FinanceConnectionStatusUpdate extends BaseModel<FinanceConnectionStatusUpdate> {
  declineReason: string;
  declineReasonCode: string;
  financeConnectionStatus: string;

  constructor(financeConnectionStatusUpdateInfo: any) {
    super();
    this.id = financeConnectionStatusUpdateInfo.id;
    this.declineReason = financeConnectionStatusUpdateInfo.declineReason;
    this.declineReasonCode = financeConnectionStatusUpdateInfo.declineReasonCode;
    this.financeConnectionStatus = financeConnectionStatusUpdateInfo.financeConnectionStatus;
  }
}
