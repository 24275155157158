import { Injectable } from '@angular/core';
import { BaseRestApiService } from './base-rest-api.service';
import { RestApiWrapperService } from './rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { ProjectGroup } from '@shared/services/project-group.model';
import { map } from 'rxjs/operators';
import { ProjectGroupApiConstants } from './project-group.constant';
import { Organization } from '@shared/models/organization/organization.model';
import { Project } from '@project/shared/project.model';
import { FinanceConnection } from '@finance/developer-portfolio/shared/finance-connection.model';
import { InvestorInformation } from '@organization-management/organization/investor-information/investor-information.model';
import { VendorInformation } from '@organization-management/organization/vendor-information/vendor-information.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectGroupService extends BaseRestApiService {
  private _currentProjectGroupId: string;
  private _currentProjectGroup: ProjectGroup;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  set currentProjectGroupId(currentProjectGroupId: string) {
    this._currentProjectGroupId = currentProjectGroupId;
  }

  get currentProjectGroupId(): string {
    return this._currentProjectGroupId;
  }

  set currentProjectGroup(currentProjectGroup: ProjectGroup) {
    this._currentProjectGroup = currentProjectGroup;
  }

  get currentProjectGroup(): ProjectGroup {
    return this._currentProjectGroup;
  }

  public list(): Observable<ProjectGroup[]> {
    return this.get<ProjectGroup[]>(ProjectGroupApiConstants.projectGroup.list())
           .pipe(
             map(projectGroups => projectGroups.map(projectGroup => new ProjectGroup(projectGroup)))
           );
  }

  public createProjectGroup(projectGroup: ProjectGroup): Observable<any> {
    return this.post<ProjectGroup>(
             ProjectGroupApiConstants.projectGroup.create(),
             JSON.stringify(projectGroup)
           );
  }

  public detail(projectGroupId: string): Observable<ProjectGroup> {
    return this.get<ProjectGroup>(ProjectGroupApiConstants.projectGroup.get(projectGroupId))
           .pipe(
             map(projectGroup => new ProjectGroup(projectGroup))
           );
  }

  public update(projectGroup: ProjectGroup): Observable<ProjectGroup> {
    return this.put<ProjectGroup>(
             ProjectGroupApiConstants.projectGroup.update(projectGroup.id),
             JSON.stringify(projectGroup)
           );
  }

  public deletePortfolio(projectGroupId: string): Observable<any> {
    return this.delete(ProjectGroupApiConstants.projectGroup.delete(projectGroupId))
           .pipe(
             map(projectGroup => new ProjectGroup(projectGroup))
           );
  }

  public developerOrganization(projectGroupId: string): Observable<Organization> {
    return this.get<Organization>(ProjectGroupApiConstants.developerOrganization.get(projectGroupId))
           .pipe(
             map(organization => new Organization(organization))
           );
  }

  // Finance
  public getFinance(projectGroupId: string): Observable<ProjectGroup> {
    return this.get<ProjectGroup>(ProjectGroupApiConstants.finance.get(projectGroupId))
           .pipe(
             map(projectGroup => new ProjectGroup(projectGroup))
           );
  }
  public recalculateFinance(projectGroupId: string): Observable<ProjectGroup> {
    return this.post<ProjectGroup>(ProjectGroupApiConstants.finance.recalculate(projectGroupId))
           .pipe(
             map(projectGroup => new ProjectGroup(projectGroup))
           );
  }
  public updateFinance(projectGroupId: string): Observable<ProjectGroup> {
    return this.put<ProjectGroup>(ProjectGroupApiConstants.finance.updateStatus(projectGroupId))
           .pipe(
             map(projectGroup => new ProjectGroup(projectGroup))
           );
  }

  // Form
  public getForm(projectGroupId: string, formId: string): Observable<string> {
    return this.get<string>(ProjectGroupApiConstants.forms.get(projectGroupId, formId));
  }
  public saveForm(projectGroupId: string, formId: string, file: Object): Observable<string> {
    return this.put<string>(ProjectGroupApiConstants.forms.save(projectGroupId, formId), file);
  }

  // Projects
  public projects(projectGroupId: string): Observable<Project[]> {
    return this.get<Project[]> (ProjectGroupApiConstants.projects.get(projectGroupId))
          .pipe(
            map(projects => projects.map(project => new Project(project)))
          );
  }

  // Tender
  public tenderPublish(projectGroupId: string): Observable<FinanceConnection> {
    return this.post<FinanceConnection> (ProjectGroupApiConstants.tender.publish(projectGroupId))
          .pipe(
            map(project => new FinanceConnection(project))
          );
  }
  public tenderRepublish(projectGroupId: string): Observable<FinanceConnection> {
    return this.put<FinanceConnection> (ProjectGroupApiConstants.tender.republish(projectGroupId))
          .pipe(
            map(project => new FinanceConnection(project))
          );
  }
  public tenderWithdrawBid(projectGroupId: string): Observable<any> {
    return this.delete(ProjectGroupApiConstants.tender.withdrawBid(projectGroupId));
  }

  // Investor
  public investor(): Observable<ProjectGroup> {
    return this.get<ProjectGroup> (ProjectGroupApiConstants.investor.get())
          .pipe(
            map(project => new ProjectGroup(project))
          );
  }
  public investorList(): Observable<Organization[]> {
    // backend returns 10 in default, add page and size
    return this.get<Organization[]> (ProjectGroupApiConstants.investor.list(), {params: {page: 0, size: 1000}})
          .pipe(
            map(organizations => organizations.map(organization => new Organization(organization)))
          );
  }
  public investorDetail(organizationId: string): Observable<Organization> {
    return this.get<Organization>(ProjectGroupApiConstants.investor.detail(organizationId))
          .pipe(
            map(organization => new Organization(organization))
          );
  }
  public investorInformation(organizationId: string): Observable<InvestorInformation> {
    return this.get<InvestorInformation>(ProjectGroupApiConstants.investor.information(organizationId))
          .pipe(
            map(investorInformation => new InvestorInformation(investorInformation))
          );
  }

  // Quotes
  public quotes(): Observable<ProjectGroup> {
    return this.get<ProjectGroup> (ProjectGroupApiConstants.quotes.get())
          .pipe(
            map(projectGroup => new ProjectGroup(projectGroup))
          );
  }

  // Vendor
  public vendorList(): Observable<Organization[]> {
    // backend returns 10 in default, add page and size
    return this.get<Organization[]> (ProjectGroupApiConstants.vendor.list(), {params: {page: 0, size: 1000}})
          .pipe(
            map(organizations => organizations.map(organization => new Organization(organization)))
          );
  }
  public vendorDetail(organizationId: string): Observable<Organization> {
    return this.get<Organization>(ProjectGroupApiConstants.vendor.detail(organizationId))
          .pipe(
            map(organization => new Organization(organization))
          );
  }
  public vendorInformation(organizationId: string): Observable<VendorInformation> {
    return this.get<VendorInformation>(ProjectGroupApiConstants.vendor.information(organizationId))
          .pipe(
            map(vendorInformation => new VendorInformation(vendorInformation))
          );
  }
}
