<div class="grid-container">
  <oes-grid-sub-action
    class="c&i-portfolio-list-sub-actions"
    *ngIf="gridApi"
    [gridState]="gridState"
    [defaultGridState]="defaultGridState"
    (action)="subAction($event)">
  </oes-grid-sub-action>
  <div class="ag-grid-header-sticky" oesAgHorizontalScroll oesAgColumns>
    <ag-grid-angular
      #C&IPortfoliosList
      id="DeveloperC&ICPortfoliosList"
      style="width: auto; min-height: 400px;"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>
