import { Injectable } from '@angular/core';
import { GenerationDesignCostItem } from './generation-design-cost-item.model';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { GenerationDesignCostItemApiConstants } from './generation-design-cost-item-api.constant';
import { map } from 'rxjs/operators';
/**
 * For BOS
 */
@Injectable({
  providedIn: 'root'
})
export class GenerationDesignCostItemService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  // cost rows
  public list(projectId: string, generationDesignId: string): Observable<GenerationDesignCostItem[]> {
    return this.get<GenerationDesignCostItem[]>(GenerationDesignCostItemApiConstants.list(projectId, generationDesignId))
           .pipe(
             map((result: any) => {
               return result.content.map((generationDesignCostItem: GenerationDesignCostItem) => {
                 return new GenerationDesignCostItem(generationDesignCostItem);
               });
             })
           );
  }
  // add row
  public create(projectId: string, generationDesignId: string, generationDesignCostItem: GenerationDesignCostItem): Observable<GenerationDesignCostItem> {
    return this.post<GenerationDesignCostItem>(
      GenerationDesignCostItemApiConstants.create(projectId, generationDesignId),
      JSON.stringify(generationDesignCostItem)
    );
  }
  // get a cost row
  public detail(projectId: string, generationDesignId: string, generationDesignCostItemId: string): Observable<GenerationDesignCostItem> {
    return this.get<GenerationDesignCostItem>(GenerationDesignCostItemApiConstants.detail(projectId, generationDesignId, generationDesignCostItemId))
            .pipe(
              map(generationDesignCostItem => new GenerationDesignCostItem(generationDesignCostItem))
            );
  }
  // update a cost row
  public update(projectId: string, generationDesignId: string, generationDesignCostItem: GenerationDesignCostItem): Observable<GenerationDesignCostItem> {
    return this.put<GenerationDesignCostItem>(
      GenerationDesignCostItemApiConstants.update(projectId, generationDesignId, generationDesignCostItem.id),
      JSON.stringify(generationDesignCostItem)
    );
  }
  // remove a cost row
  public remove(projectId: string, generationDesignId: string, generationDesignCostItemId: string): Observable<GenerationDesignCostItem> {
    return this.delete<GenerationDesignCostItem>(GenerationDesignCostItemApiConstants.delete(projectId, generationDesignId, generationDesignCostItemId));
  }
}
