
<oes-modal-dialog
  [title]="'data-room.dialogs.association.title' | translate"
  [submitButtonText]="'buttons.add' | translate"
  [cancelButtonText]="'buttons.close' | translate"
  (submit)="add()"
  (close)="hide()">
  <!-- the below goes to dialog-body -->
  <form [formGroup]="formGroup" class="add-form d-flex flex-column">
    <!-- Select association -->
    <div>
      <label for="associationCategory">{{ 'data-room.dialogs.association.category' | translate }}</label>
      <div class="select-wrapper">
        <select
          id="associationCategory"
          formControlName="category"
          class="form-input form-control"
          (change)="changedAssociationCategory($event)">
          <option
            *ngFor="let category of associationCategories"
            [value]="category.id">
            {{ category?.name }}
          </option>
        </select>
      </div>
    </div>

    <!-- Project/Program/Portfolio List -->
    <div class="d-flex flex-row mt-2">
      <ng-container
        *ngIf="selectedAssociationCategory === associationCategory.Project ||
              selectedAssociationCategory === associationCategory.ProjectDistributionDesign">
        <label for="listItems">{{ 'data-room.dialogs.association.label.project' | translate }}</label>
      </ng-container>
      <ng-container
        *ngIf="selectedAssociationCategory === associationCategory.ProgramDocument ||
              selectedAssociationCategory === associationCategory.ProgramPreQualificationApplication">
        <label for="listItems">{{ 'data-room.dialogs.association.label.program' | translate }}</label>
      </ng-container>
      <ng-container
        *ngIf="selectedAssociationCategory === associationCategory.ProjectGroup">
        <label for="listItems">{{ 'data-room.dialogs.association.label.portfolio' | translate }}</label>
      </ng-container>

      <ng-container
        *ngIf="selectedAssociationCategory === associationCategory.Project ||
               selectedAssociationCategory === associationCategory.ProgramDocument ||
               selectedAssociationCategory === associationCategory.ProjectDistributionDesign ||
               selectedAssociationCategory === associationCategory.ProgramPreQualificationApplication ||
               selectedAssociationCategory === associationCategory.ProjectGroup">
        <oes-dropdown
          *ngIf="options"
          id="listItems"
          [parentFormGroup]="formGroup"
          [items]="options"
          [selection]="'multiple'"
          [enableSearch]="true">
        </oes-dropdown>
      </ng-container>

    </div>
  </form>
</oes-modal-dialog>
