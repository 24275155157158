import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { map } from 'rxjs/operators';
import { ConnectionsPayout } from './connections-payout.model';
import { ConnectionsPayoutApiConstants } from './connections-payout-api.constant';
import { Project } from '@project/shared/project.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConnectionsPayoutService extends BaseRestApiService {
  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  getConnectionsPayoutById(projectId: string, connectionsPayoutId: string): Observable<ConnectionsPayout> {
    return this.get<ConnectionsPayout>(ConnectionsPayoutApiConstants.payout.getById(projectId, connectionsPayoutId))
      .pipe(map((payout: ConnectionsPayout) => new ConnectionsPayout(payout)));
  }

  getConnectionsPayoutsByProjectId(projectId: string): Observable<ConnectionsPayout[]> {
    return this.get<ConnectionsPayout[]>(ConnectionsPayoutApiConstants.payout.findByProjectId(projectId))
      .pipe(map((payouts: ConnectionsPayout[]) => payouts.map(payout => new ConnectionsPayout(payout))));
  }

  createConnectionsPayout(projectId: string, connectionsPayout: ConnectionsPayout): Observable<ConnectionsPayout> {
    return this.post<ConnectionsPayout>(ConnectionsPayoutApiConstants.payout.create(projectId), connectionsPayout)
      .pipe(map((payout: ConnectionsPayout) => new ConnectionsPayout(payout)));
  }

  updateExchangeRate(projectId: string, currency: string, exchangeRate: number, apply: boolean): Observable<Project> {
    let params = new HttpParams();
    params = params.set('apply', apply.toString());
    const options = { params };
    return this.put<Project>(ConnectionsPayoutApiConstants.exchangeRate.update(projectId, currency, exchangeRate), null, options)
      .pipe(map((project: Project) => new Project(project)));
  }

  applyExchangeRate(projectId: string): Observable<Project> {
    return this.put<Project>(ConnectionsPayoutApiConstants.exchangeRate.apply(projectId))
      .pipe(map((project: Project) => new Project(project)));
  }
}
