"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var align_ejs_1 = require("./align.ejs");
var form_ejs_1 = require("./form.ejs");
exports.default = {
  align: align_ejs_1.default,
  form: form_ejs_1.default
};