import { Component, Input, OnDestroy } from '@angular/core';
import { FilesService } from '@shared/components/files/shared/files.service';
import { Subject } from 'rxjs';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { takeUntil } from 'rxjs/operators';
import { DownloadService } from '../../shared/download.service';

@Component({
  selector: 'oes-files-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class FilesActionComponent implements OnDestroy {
  @Input() accessControl: FilesActions;
  @Input() readOnly: boolean = false;

  actionType = ActionType;

  private highlightedNodes: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  private selectedNodes: any;

  constructor(
    private _filesService: FilesService,
    private _downloadService: DownloadService
  ) {
    this._filesService.selectedNodes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((selectedNodes: any) => {
        this.selectedNodes = selectedNodes;
      });

    this._filesService.highlightedNodes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((highlightedNodes: any) => {
        this.highlightedNodes = highlightedNodes;
      });
  }

  ngOnDestroy() {
    this._filesService.clear();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  emitAction(type: ActionType) {
    this._filesService.clickActionButton(type);
  }

  // We don't create a new folder under multiple places.
  disableCreateFolder(): boolean {
    return !this.accessControl.create;
  }

  disableUpload(): boolean {
    return !this.accessControl.upload;
  }

  disableMove(): boolean {
    return !(this.accessControl.move && this.selectedNodes?.length > 0);
  }

  disableCopy(): boolean {
    return !(
      this.accessControl.copy &&
      this.selectedNodes?.length > 0 &&
      this.selectedNodes?.filter((node) => node?.data.formUpload).length === 0
    );
  }

  disableDownload(): boolean {
    return !(
      this.accessControl.download &&
      this.selectedNodes?.length > 0 &&
      this.selectedNodes.filter((node) => node.allChildrenCount === null)
        .length <= 100
    );
  }

  disableDelete(): boolean {
    return !(
      this.accessControl.delete &&
      this.selectedNodes?.length > 0 &&
      this.selectedNodes?.filter((node) => !node?.data?.allowDelete).length ===
        0 &&
      this.selectedNodes?.filter((node) => node?.data.formUpload).length === 0
    );
  }

  disableTag(): boolean {
    return !(this.accessControl.tag && this.selectedNodes?.length > 0);
  }

  // can add tags to multiple documents
  disableTagAdd(): boolean {
    return !(this.accessControl.tag && this.highlightedNodes?.length > 0);
  }

  // only can delete tags one document at a time
  disableTagDelete(): boolean {
    return !(this.accessControl.tag && this.highlightedNodes?.length === 1);
  }

  disableAssociation(): boolean {
    return !(this.accessControl.tag && this.selectedNodes?.length > 0);
  }

  // can add associations to multiple documents
  disableAssociationAdd(): boolean {
    return !(this.accessControl.tag && this.highlightedNodes?.length > 0);
  }

  // only can delete associations one document at a time
  disableAssociationDelete(): boolean {
    return !(
      this.accessControl.tag &&
      this.highlightedNodes?.length === 1 &&
      this.selectedNodes?.filter((node) => !node?.data?.allowDelete).length ===
        0 &&
      this.selectedNodes?.filter((node) => node?.data.formUpload).length === 0
    );
  }

  disableEditName(): boolean {
    return !(
      this.accessControl.editName &&
      this.highlightedNodes?.length === 1 &&
      this.selectedNodes?.filter((node) => !node?.data?.allowRename).length ===
        0 &&
      this.selectedNodes?.filter((node) => node?.data.formUpload).length === 0
    );
  }

  disablePreview(): boolean {
    return !(
      this.accessControl.preview &&
      this.highlightedNodes?.length === 1 &&
      this.highlightedNodes[0]?.data?.size < 1024 * 1024 * 2 &&
      this._downloadService.previewableExtensions.includes(
        this._downloadService.getFileType(this.highlightedNodes[0].data.path)
      )
    );
  }
}
