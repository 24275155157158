<div class="milestone-view">
  <div class="milestone-view__main">
    <div class="milestone-view__controls">
      <p class="status">
        <oes-dropdown
          [ngClass]="[ 'color-' + milestoneStatus.color ]"
          class="style-overhaul-flag"
          [items]="statusDropdownOptions"
          [initialSelections]="initialStatusSelection"
          [enableSearch]="false"
          [required]="true"
          [selection]="'single'"
          (click)="stopPropagation($event)"
          (selectionChanged)="statusChanged($event)">
        </oes-dropdown>
      </p>
      <oes-button
        class="btn btn-gray btn-tertiary"
        (click)="close()"
        [leadingIconSrc]="'/assets/icons/line-icons/shared/black/x.svg'"
        >{{ 'buttons.close' | translate }}</oes-button>
    </div>
    <div class="milestone-view__header">
      <h4>{{ milestone.name }}</h4>
      <p *ngIf="milestone.description?.length" class="milestone-view__header-description">
        {{ milestone.description }}
      </p>
      <ng-container *ngIf="!readOnly && (isProgramOwner || milestoneType === milestoneTypes.PROJECT)">
        <a class="link-button" (click)="handleOpenMilestoneConfigEdit()">
          <svg width="14" height="14" viewBox="0 0 20 20" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4999 17.5H10.8333M2.08325 17.9167L6.70764 16.1381C7.00343 16.0243 7.15132 15.9674 7.28968 15.8931C7.41259 15.8272 7.52975 15.751 7.63995 15.6655C7.76402 15.5692 7.87606 15.4572 8.10015 15.2331L17.4999 5.83334C18.4204 4.91286 18.4204 3.42048 17.4999 2.5C16.5795 1.57953 15.0871 1.57953 14.1666 2.5L4.76682 11.8998C4.54273 12.1239 4.43069 12.2359 4.33441 12.36C4.2489 12.4702 4.17275 12.5873 4.10678 12.7102C4.03251 12.8486 3.97563 12.9965 3.86186 13.2923L2.08325 17.9167ZM2.08325 17.9167L3.79835 13.4575C3.92108 13.1384 3.98245 12.9788 4.0877 12.9057C4.17969 12.8419 4.2935 12.8177 4.4035 12.8387C4.52936 12.8628 4.65024 12.9836 4.89199 13.2254L6.77456 15.108C7.01631 15.3497 7.13719 15.4706 7.16122 15.5964C7.18223 15.7064 7.15807 15.8203 7.09421 15.9122C7.02112 16.0175 6.86158 16.0789 6.54248 16.2016L2.08325 17.9167Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ 'milestones.edit' | translate }}
        </a>
      </ng-container>
    </div>
    <div class="milestone-view__tabs" #tabContainer>
      <tabset class="plain-tabs">
        <tab *ngIf="milestone.milestoneConfig.contentType === 'FORM'"
          heading="{{ 'milestones.form-title' | translate }}"
          (selectTab)="tabs.form = true"
          (deselect)="tabs.form = false"
          [active]="tabs.form"
          #tabElement>
          <oes-milestone-content-form
            [milestone]="milestone"
            [formJson]="milestoneForm"
            [project]="project"
            [readOnly]="readOnly"
            [user]="user"
          ></oes-milestone-content-form>
        </tab>
        <tab heading="{{ 'milestones.notes-title' | translate }}"
            (selectTab)="tabs.notes = true"
            (deselect)="tabs.notes = false"
            [active]="tabs.notes"
            #tabElement>
          <oes-milestone-content-notes
            [milestone]="milestone"
            [project]="project"
            [readOnly]="readOnly"
          ></oes-milestone-content-notes>
        </tab>
        <!-- attachments -->
        <tab heading="{{ 'milestones.attachments-title' | translate }}"
            (selectTab)="tabs.attachments = true"
            (deselect)="tabs.attachments = false"
            [active]="tabs.attachments"
            #tabElement>
          <div class="milestone-view__attachments">
            <div class="milestone-view__attachments-row">
              <h5>{{ 'milestones.attachments-title' | translate }} ({{ documentsToDisplay?.length ? documentsToDisplay?.length : '0' }})</h5>
              <div class="actions">
                <oes-button class="btn btn-secondary btn-gray btn-sm"
                            (click)="downloadDocumentsClick(documentsToDisplay)"
                            [disabled]="!documentsToDisplay?.length"
                            [leadingIconSrc]="'assets/icons/line-icons/shared/grey/download-cloud.svg'">
                  {{ 'buttons.download-all-files' | translate }}
                </oes-button>
                <ng-container *ngIf="!readOnly">
                  <oes-button class="btn btn-secondary btn-sm"
                              (click)="uploadDocumentClick($event)"
                              [leadingIconSrc]="'assets/icons/line-icons/shared/orange/upload-cloud.svg'">
                    {{ 'buttons.upload-files' | translate }}
                  </oes-button>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="documentsToDisplay?.length; else documentsEmpty">
              <div class="milestone-view__attachments-list" *ngFor="let document of documentsToDisplay; index as docIndex">
                <div class="milestone-view__attachments-list-item-wrapper">
                  <div (click)="downloadDocumentsClick([document])" class="milestone__attachments-list-item">
                    <div class="icon-round-bg" [ngStyle]="{
                      'backgroundColor': document.owner.id === user.organization.id
                        ? 'var(--color-brand-a-100)'
                        : 'var(--color-baseline-100)'
                    }">
                      <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/black/attachment.svg" role="presentation" />
                    </div>
                    <div class="milestone-view__attachments-list-item-text">
                      <p class="weight-semibold title">
                        {{ document.owner.name }}
                        <span class="date-added">{{ document.created | date: 'dd MMMM yyyy HH:mm' }}</span>
                        <a *ngIf="document.owner.id === user.organization.id"
                            (click)="handleOpenAttachmentDeleteWarning($event, docIndex)"
                            class="delete-button"
                        >{{ 'buttons.delete' | translate }}</a>
                      </p>
                      <p style="margin: 0;">{{ document.path }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #documentsEmpty>
              <div class="box-container">
                <div class="icon-round-bg">
                  <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/grey/upload-cloud.svg" role="presentation">
                </div>
                <p>{{ 'milestones.detail.no-attachments' | translate }}</p>
                <a
                  *ngIf="!readOnly"
                  class="box-container__link"
                  (click)="uploadDocumentClick($event)"
                  href="#"
                >
                  {{ 'buttons.upload-files' | translate }}
                </a>
              </div>
            </ng-template>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<oes-modal-dialog
  #deleteAttachmentWarningModal
  id="deleteAttachmentWarningModal"
  [allowCancel]="true"
  [allowSubmit]="true"
  [destructive]="true"
  [size]="'small'"
  (submit)="handleDeleteAttachment()"
  [submitButtonText]="'buttons.confirm' | translate"
  [title]="'milestones.delete-modal.delete-attachment' | translate"
  (close)="handleCloseAttachmentDeleteWarning()">
  {{ 'milestones.delete-modal.delete-attachment-warning' | translate }}
</oes-modal-dialog>

<ng-container *ngIf="isLoading">
  <div class="loading-ring-container">
    <div class="loading-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</ng-container>
