import { BaseModel } from "../../shared/base.model";
import { Organization } from "../../shared/models/organization/organization.model";

export interface ProjectInstaller {
  id: string;
  project: {
    id: string;
    name: string;
    organization: { id: string };
  };
};

export class Installer extends BaseModel<Installer> {
  name: string;
  email: string;
  ownerOrganization: Organization;
  projectInstallers: ProjectInstaller[];
  updated: Date;
  updateUser: string;
  created: Date;

  constructor(installerInfo: any) {
    super();

    this.id = installerInfo.id;
    this.name = installerInfo.name;
    this.email = installerInfo.email;
    this.ownerOrganization = installerInfo.ownerOrganization;
    this.projectInstallers = installerInfo.projectInstallers;
    this.updated = installerInfo.updated;
    this.updateUser = installerInfo.updateUser;
    this.created = installerInfo.created;
  }
}

export class InstallerFilter extends BaseModel<InstallerFilter> {
  name: string;
  email: string;

  constructor(filterInfo: any) {
    super();
    this.name = filterInfo.name;
    this.email = filterInfo.email;
  }
}
