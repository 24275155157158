import { forkJoin, Subject } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { take, takeUntil } from 'rxjs/operators';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { OfftakersListGridSettingService } from './offtakers-list-grid-setting.service';
import { OfftakerService } from './offtaker.service';
import { Offtaker } from './offtaker.model';
import { ProjectSet } from './project-set/project-set.model';
import { ProjectSetService } from './project-set/project-set.service';
import { OfftakerFormComponent } from './form/offtaker-form.component';
import { UserService } from '@user/user.service';
import { Organization } from '@shared/models/organization/organization.model';

@Component({
  selector: 'oes-offtakers-list',
  templateUrl: 'offtakers-list.component.html',
  styleUrls: ['./offtakers-list.component.scss'],
  providers: []
})
export class OfftakersListComponent implements OnInit, OnDestroy {
  @ViewChild('offtakerForm', {static: false}) offtakerForm: OfftakerFormComponent;

  activeTab = 'offtakers';
  columnApi: ColumnApi;
  copying: boolean = false;
  defaultGridState: GridState;
  displayedProjectSets: ProjectSet[];
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  offtakers: Offtaker[];
  organization: Organization;
  selectedProjectSetIds = [];

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _offtakerService: OfftakerService,
              public _offtakersListGridSettingService: OfftakersListGridSettingService,
              private _projectSetService: ProjectSetService,
              private _userService: UserService) {
    this.gridOptions = this._offtakersListGridSettingService.getGridOptions();
  }

  ngOnInit() {
    this._userService.getCurrentUser().subscribe((user) => {
      this.organization = user.organization;
    });
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this._offtakersListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._offtakersListGridSettingService.buildDefaultGridState();
    this.getOfftakers();
  }

  getOfftakers() {
    this._offtakerService.listByOrgId(this.organization.id)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((offtakers: Offtaker[]) => {
      this.offtakers = offtakers.map(ot => new Offtaker(ot));
      this.setAgGrid();
    });
  }

  private setAgGrid() {
    if (this.offtakers?.length) {
      const offtakerData = this.offtakers.map((offtaker: Offtaker) => ({
        ...offtaker,
        projectCount: offtaker.projectOfftakers.length
      }));
      this.gridApi.setRowData(offtakerData);
      this._offtakersListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.collapseAll();

    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._offtakersListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  prepareDelete(projectSetIds: string[]) {
    const deleteProjectSetRequests = projectSetIds.map(id => {
      return this._projectSetService.remove(id);
    });
    forkJoin(deleteProjectSetRequests)
    .pipe(take(1))
    .subscribe((res) => {
      this.deleteEmptyOfftakers();
    });
  }

  deleteEmptyOfftakers() {
    this._offtakerService.list()
    .pipe(take(1))
    .subscribe((offtakers: Offtaker[]) => {
      const deleteOfftakerRequests = offtakers.filter(ot => {
        if (!ot.projectSets?.length) {
          return true;
        }
        return false;
      }).map((offtakerToDelete: Offtaker) => {
        return this._offtakerService.remove(offtakerToDelete.id);
      });
      if (!deleteOfftakerRequests.length) {
        this.getOfftakers();
      }
      forkJoin(deleteOfftakerRequests)
        .pipe(take(1))
        .subscribe(res => {
          this.getOfftakers();
        });
    });
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        this._offtakersListGridSettingService.exportCsv(this.gridApi, 'Customers', true);
        break;
      }
      case GridSubAction.reload:
        this.getOfftakers();
        break;
      case GridSubAction.clearFilter:
        this._offtakersListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private gridInit() {
    this.gridStateStorageKey = this._offtakersListGridSettingService.buildGridStateStorageKey('myOfftakers');
  }

  addOfftakerClick() {
    this.offtakerForm.openModal('create');
  }

  editOfftakerClick(event) {
    this.offtakerForm.openModal('edit', event);
  }
}
