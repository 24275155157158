import { FinancialModelTemplate } from '@program/shared/program.model';
import { ProjectFinancialYearInput } from './project-financial-year-input.model';
import { ProjectFinancialModelRun } from '@project/detail/financials/project-financial-model-run.model';
import { BaseModel } from '@shared/base.model';
import { OrganizationFinancialModel } from './organization-financial-model/organization-financial-model.model';
import { CiProjectDealType } from '../summary/summary-ci/ci-project-deal-type.enum';

export class ProjectFinancialModel extends BaseModel<ProjectFinancialModel> {
  financialModelTemplate: FinancialModelTemplate;
  name: string;
  projectLife: number;
  targetYear: number;
  costGrowth: number;
  tariffChangePercent: number;
  expectedLoadSensitivity: number;
  nonTechnicalRevenueLossSensitivity: number;
  costOverRunSensitivity: number;
  subsidyPercent: number;
  subsidyType: string;
  subsidyAmountPerConnection: number;
  subsidyDisplayAmountPerConnection = false;
  discountRate: number;
  activeFinancialModel: OrganizationFinancialModel;

  capex: number;
  adjustedCapex: number;
  irr: number;
  payback: number;

  // Output
  arpuAnchor: number;
  arpuCommercial: number;
  arpuProductive: number;
  arpuPublic: number;
  arpuResidential: number;
  averageDSCR: number;
  averageOperatingMargin: number;
  capexPerConnection: number;
  currencyDepreciation: number;
  equityIRR: number;
  equityPayback: number;
  lcoe: number;
  minimumDSCR: number;
  npvToEquity: number;
  projectFinancialYearInputs: Array<ProjectFinancialYearInput>;
  run?: ProjectFinancialModelRun;
  totalExpenses: number;
  totalKwhSold: number;
  totalRevenue: number;
  updated: Date;
  version: string;

  // C&I-specific Outputs
  annualSaving: number;
  annualSolarYield: number;
  batteryCost: number;
  batterySystemSize: number;
  dealType: CiProjectDealType;
  emissionsOffset: number;
  forecastedEnergyYieldY1: number;
  gridCostsOffset: number;
  lifetimeProfit: number;
  solarCost: number;
  solarSystemSize: number;

  constructor(projectFinancialModelInfo: any) {
    super();
    this.id = projectFinancialModelInfo.id;
    this.financialModelTemplate = projectFinancialModelInfo.financialModelTemplate;
    this.name = projectFinancialModelInfo.name;
    this.projectLife = projectFinancialModelInfo.projectLife;
    this.targetYear = projectFinancialModelInfo.targetYear;
    this.tariffChangePercent = projectFinancialModelInfo.tariffChangePercent;
    this.costGrowth = projectFinancialModelInfo.costGrowth;
    this.expectedLoadSensitivity = projectFinancialModelInfo.expectedLoadSensitivity;
    this.nonTechnicalRevenueLossSensitivity = projectFinancialModelInfo.nonTechnicalRevenueLossSensitivity;
    this.costOverRunSensitivity = projectFinancialModelInfo.costOverRunSensitivity;
    this.capex = projectFinancialModelInfo.capex;
    this.adjustedCapex = projectFinancialModelInfo.adjustedCapex;
    this.subsidyType = projectFinancialModelInfo.subsidyType;
    this.subsidyPercent = projectFinancialModelInfo.subsidyPercent;
    this.subsidyAmountPerConnection = projectFinancialModelInfo.subsidyAmountPerConnection;
    this.subsidyDisplayAmountPerConnection = this.subsidyType === 'AMOUNT_PER_CONNECTION';
    this.discountRate = projectFinancialModelInfo.discountRate;
    this.irr = projectFinancialModelInfo.irr;
    this.payback = projectFinancialModelInfo.payback;
    this.activeFinancialModel = projectFinancialModelInfo.activeFinancialModel;
    //
    this.totalExpenses = projectFinancialModelInfo.totalExpenses;
    this.totalRevenue = projectFinancialModelInfo.totalRevenue;
    this.averageOperatingMargin = projectFinancialModelInfo.averageOperatingMargin;
    this.arpuResidential = projectFinancialModelInfo.arpuResidential;
    this.arpuAnchor = projectFinancialModelInfo.arpuAnchor;
    this.arpuProductive = projectFinancialModelInfo.arpuProductive;
    this.arpuCommercial = projectFinancialModelInfo.arpuCommercial;
    this.arpuPublic = projectFinancialModelInfo.arpuPublic;
    this.equityIRR = projectFinancialModelInfo.equityIRR;
    this.npvToEquity = projectFinancialModelInfo.npvToEquity;
    this.equityPayback = projectFinancialModelInfo.equityPayback;
    this.minimumDSCR = projectFinancialModelInfo.minimumDSCR;
    this.averageDSCR = projectFinancialModelInfo.averageDSCR;
    this.capexPerConnection = projectFinancialModelInfo.capexPerConnection;
    this.totalKwhSold = projectFinancialModelInfo.totalKwhSold;
    this.lcoe = projectFinancialModelInfo.lcoe;
    this.currencyDepreciation = projectFinancialModelInfo.currencyDepreciation;
    this.projectFinancialYearInputs = [];
    if (projectFinancialModelInfo.projectFinancialYearInputs != null &&
      projectFinancialModelInfo.projectFinancialYearInputs.length > 0) {
      for (let i = 0; i < projectFinancialModelInfo.projectFinancialYearInputs.length; i++) {
        this.projectFinancialYearInputs.push(
          new ProjectFinancialYearInput(projectFinancialModelInfo.projectFinancialYearInputs[i]));
      }
      // Sort by Year
      this.projectFinancialYearInputs.sort(this.compareYearFunction);
    }
    this.updated = projectFinancialModelInfo.updated;
    this.version = projectFinancialModelInfo.version;
    this.run = projectFinancialModelInfo.run;

    this.annualSaving = projectFinancialModelInfo.annualSaving;
    this.annualSolarYield = projectFinancialModelInfo.annualSolarYield;
    this.batteryCost = projectFinancialModelInfo.batteryCost;
    this.batterySystemSize = projectFinancialModelInfo.batterySystemSize;
    this.dealType = projectFinancialModelInfo.dealType;
    this.emissionsOffset = projectFinancialModelInfo.emissionsOffset;
    this.forecastedEnergyYieldY1 = projectFinancialModelInfo.forecastedEnergyYieldY1;
    this.gridCostsOffset = projectFinancialModelInfo.gridCostsOffset;
    this.lifetimeProfit = projectFinancialModelInfo.lifetimeProfit;
    this.solarCost = projectFinancialModelInfo.solarCost;
    this.solarSystemSize = projectFinancialModelInfo.solarSystemSize;
  }

  compareYearFunction(a: ProjectFinancialYearInput, b: ProjectFinancialYearInput) {
    return a.year - b.year;
  }
}
