import { Component } from '@angular/core';
import { GenerationDesignService } from '@project/detail/generation-design/shared/generation-design.service';
import { SystemSelectionTabService } from '@project/detail/generation-design/detail/system-selection-tab/system-selection-tab.service';
import { SystemLoadService } from '@project/detail/load/shared/system-load.service';
import { AcDcCoupledComponent } from '../ac-dc-coupled.component';

@Component({
  selector: 'oes-ac-coupled',
  templateUrl: './ac-coupled.component.html',
  styleUrls: ['./ac-coupled.component.scss']
})
export class AcCoupledComponent extends AcDcCoupledComponent {
  constructor(_generationDesignService: GenerationDesignService,
              _systemSelectionTabService: SystemSelectionTabService,
              _systemLoadService: SystemLoadService) {
    super(_generationDesignService, _systemSelectionTabService, _systemLoadService);
  }
}
