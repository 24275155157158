import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { parseInt } from 'lodash-es';
import { ChartColors } from '../chart-colors.enum';
import { ChartDataset } from '../chart-dataset.model';
import { OesChart } from '../oes-chart.model';

@Component({
  selector: 'oes-stacked-bar-chart',
  templateUrl: 'stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent implements OnInit {
  @Input() chart: OesChart;
  @Input() chartColors = Object.keys(ChartColors);

  barChartData: ChartConfiguration<'bar'>['data'];
  barChartLegend: boolean;
  barChartPlugins = [];
  barChartOptions: ChartConfiguration<'bar'>['options'];

  constructor() {
  }

  ngOnInit() {
    this.buildStackedBarChart();
  }

  private buildDatasets(datasets: ChartDataset[]) {
    return datasets.map((dataset, index) => {
      return {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: this.chartColors[index],
        hoverBackgroundColor: this.chartColors[index],
        maxBarThickness: 40
      };
    });
  }

  private buildStackedBarChart() {
    const barChartLegend = this.chart?.labels?.length > 0;
    const barChartPlugins = [];
    const barChartData: ChartConfiguration<'bar'>['data'] = {
      labels: this.chart.labels,
      datasets: this.buildDatasets(this.chart.datasets)
    };
    const barChartOptions: ChartConfiguration<'bar'>['options'] = {
      plugins: {
        title: {
          display: false
        },
        legend: {
          display: true,
          position: 'top',
          labels: {
            padding: 15,
            boxHeight: 5,
            boxPadding: 12,
            boxWidth: 25
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            callback: (label, index, labels) => {
              if (labels.length === 4 && labels[index]?.value === label) {
                return 'Q' + (label + 1);
              }
              return label;
            }
          },
          title: {
            display: true,
            text: this.chart?.options?.xname
          }
        },
        y: {
          stacked: true,
          grace: '10%',
          title: {
            display: true,
            text: this.chart?.options?.yname
          }
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        bar: {
          borderRadius: 6
        }
      }
    };
    this.barChartLegend = barChartLegend;
    this.barChartPlugins = barChartPlugins;
    this.barChartData = barChartData;
    this.barChartOptions = barChartOptions;
  }



}
