<div class="section-border">
  <div class="offtaker-container">
    <div class="offtaker">
      <h5>{{ 'project.overview.offtaker' | translate }}</h5>
      <h4>{{ currentOfftaker?.name || '--' }}</h4>
    </div>
    <div *ngIf="canAssign" class="assign">
      <button *ngIf="!currentOfftaker" class="btn btn-primary-light" (click)="openAssignOfftakerModal()">
        {{ 'project.overview.assign-offtaker' | translate }}
      </button>
      <button *ngIf="currentOfftaker" class="btn btn-grey" (click)="openAssignOfftakerModal()">
        <img style="width: 15px; height: 15px;" src="assets/icons/line-icons/shared/black/change.svg" role="presentation" />
        {{ 'buttons.change' | translate }}
      </button>
    </div>
  </div>
  <div class="line-break"></div>
  <div class="d-flex justify-content-between offtaker-projects">
    <div>
      {{ 'offtaker.form.business-type' | translate }}
    </div>
    <div class="weight-medium">
      {{ currentOfftaker?.type ? ('offtaker.offtaker-type.' + currentOfftaker.type | translate) : '--' }}
    </div>
  </div>
</div>

<oes-modal-dialog
  #assignOfftakerModal
  [submitButtonText]="'buttons.save'| translate"
  [size]="'small'"
  [allowSubmit]="formGroup?.valid"
  [hideCloseBtn]="true"
  [submitButtonText]="'buttons.confirm' | translate"
  (submit)="assignOfftaker()"
  (close)="closeAssignOfftakerModal()">
  <div class="form-header">
    <h3>{{ 'project.overview.assign-offtaker' | translate }}</h3>
    <button
      class="btn btn-fresh btn-link"
      (click)="newOfftakerClick()">
      <img src="assets/icons/line-icons/shared/orange/plus.svg" role="presentation" />
      {{ 'project.overview.new-offtaker' | translate }}
    </button>
  </div>
  <form *ngIf="formGroup" [formGroup]="formGroup" class="dialog-form">
    <div class="offtaker-dropdown">
      <oes-dropdown
        id="offtakerSelector"
        class="style-overhaul-flag lg"
        [lazySearch]="true"
        [parentFormGroup]="formGroup"
        [items]="offtakers"
        [selection]="'single'"
        [enableSearch]="true"
        [enableUncheckAll]="false"
        (searchChanged)="getFilteredOfftakers($event)">
      </oes-dropdown>
      <span class="placeholder" *ngIf="!selectedOfftaker">{{ 'offtaker.form.select-offtaker-type' | translate }}</span>
      <span class="color-error" *ngIf="showDropdownError">
        {{ 'offtaker.form.error.required' | translate }}
      </span>
    </div>
  </form>
</oes-modal-dialog>

<oes-offtaker-form
  #offtakerForm
  [offtakers]="offtakers"
  [organization]="organization"
  (refreshOfftakers)="refreshOfftakers($event)">
></oes-offtaker-form>
