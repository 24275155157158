<!-- Header image -->
<oes-fm-portfolio-header-image
  *ngIf="projectGroup && projects"
  [projectGroup]="projectGroup"
  [projects]="projects">
</oes-fm-portfolio-header-image>

<!-- Summary Box -->
<oes-fm-portfolio-summary-box
  *ngIf="projectGroup"
  [projectGroup]="projectGroup">
</oes-fm-portfolio-summary-box>
  
<div class="fm-introduction oes-page-break">
  <!-- customer summary -->
  <section>
    <oes-portfolio-customer-summary
      *ngIf="financeIntroduction"
      [financeIntroduction]="financeIntroduction">
    </oes-portfolio-customer-summary>
  </section>

  <!-- generation system -->
  <section>
    <oes-portfolio-generation-system
      *ngIf="financeIntroduction"
      [financeIntroduction]="financeIntroduction">
    </oes-portfolio-generation-system>
  </section>
</div>
