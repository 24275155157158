import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { environment } from '@environments/environment';
import { FinanceIntroduction } from '@finance/developer-portfolio/shared/finance-introduction.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FmFinanceIntroductionService extends BaseRestApiService {
  private introduction = environment.serverUri + `api/${environment.apiVersion}/fm/market-place`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getFinanceIntroduction(projectGroupId: string): Observable<FinanceIntroduction> {
    return this.get<FinanceIntroduction>(`${this.introduction}/${projectGroupId}/introductions`)
      .pipe(
        map((financeIntroduction: FinanceIntroduction) => new FinanceIntroduction(financeIntroduction))
      );
  }
}
