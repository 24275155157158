<oes-nav-header></oes-nav-header>
<div class="container-fluid" style="padding: 0;">
  <div class="top-page-heading align-items-center">
    <h3>{{ 'project-tabs.installers' | translate }}</h3>
    <oes-button
      class="btn btn-primary btn-lg"
      data-testid="add-installer-button"
      [leadingIconSrc]="'assets/icons/line-icons/shared/white/plus.svg'"
      (click)="addInstallerClick()">
      {{'installers.list.add-installer' | translate}}
    </oes-button>
  </div>

  <oes-grid-sub-action
    class="project-list-sub-actions"
    *ngIf="gridApi"
    [gridState]="gridState"
    [defaultGridState]="defaultGridState"
    (action)="subAction($event)">
  </oes-grid-sub-action>

  <div class="grid ag-grid-header-sticky">
    <ag-grid-angular
      id="installersList"
      style="width: auto;"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (rowClicked)="editInstallerClick($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')">
    </ag-grid-angular>
  </div>
</div>

<oes-installer-form
  #installerForm
  [installers]="installers"
  [organization]="organization"
  (refreshInstallers)="getInstallers()"
></oes-installer-form>
