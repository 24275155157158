import { Component } from '@angular/core';
import { CountryService } from '@shared/services/country/country.service';
import { Country } from '@shared/services/country/country.model';
import { take } from 'rxjs/operators';
import { ICellEditorAngularComp, INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { IMultiSelectSettings, IMultiSelectOption } from 'ngx-bootstrap-multiselect';

@Component({
  selector: 'oes-cell-country-selector',
  templateUrl: './cell-country-selector.component.html',
  styleUrls: ['./cell-country-selector.component.scss']
})
export class CellCountrySelectorComponent implements INoRowsOverlayAngularComp {
  public countries: IMultiSelectOption[];
  public selectedCountry: string[];
  public dropdownSettings: IMultiSelectSettings = {
    enableSearch: true,
    selectionLimit: 1,
    minSelectionLimit: 1,
    checkedStyle: 'fontawesome',
    containerClasses: 'oes-country-select-container',
    buttonClasses: 'oes-country-select-button',
    itemClasses: 'oes-country-select-item',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: false,
    showCheckAll: false,
    showUncheckAll: false,
    autoUnselect: true,
    closeOnSelect: true
  };

  constructor(private _countryService: CountryService) { }

  agInit(params: any) {
    this._countryService.getCountries()
    .pipe(take(1))
    .subscribe((countries: Country[]) => {
      this._countryService.countries = countries;
      this.countries = countries;
    });
  }

  getValue(): any {
    // single selection
    if (this.countries && this.countries.length > 0 && this.selectedCountry && this.selectedCountry.length > 0) {
      return this.countries.find(country => country.id === this.selectedCountry[0]);
    }
    return undefined;
  }

  isPopup() {
    return true;
  }
}
