import {BaseModel} from '@shared/base.model';

export class FinanceCapital extends BaseModel<FinanceCapital> {
  amount: number;
  percent: number;
  status: string;
  type: string;

  constructor(financeCapitalInfo: any) {
    super();
    this.id = financeCapitalInfo.id;
    this.amount = financeCapitalInfo.amount;
    this.percent = financeCapitalInfo.percent;
    this.status = financeCapitalInfo.status;
    this.type = financeCapitalInfo.type;
  }
}
