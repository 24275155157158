import { Component, Input, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';

import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { ProjectGroupFormService } from '@finance/developer-portfolio/shared/project-group-form.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { SubmitValidationService } from '@finance/developer-portfolio/shared/submission/submit-validation.service';
import { FinanceService, PORTFOLIO_PAGE } from '@finance/developer-portfolio/shared/finance.service';

@Component({
  selector: 'oes-portfolio-business-plan-form-custom',
  templateUrl: './form-custom.component.html',
  styleUrls: ['./form-custom.component.scss'],
  providers: [
    DynamicFormService,
    ProjectGroupFormService
  ]
})
export class PortfolioBusinessPlanFormCustomComponent {
  @Input() projectGroup: ProjectGroup;
  @Input() json: {};
  @Input() simple = false;
  @Input() isPastSubmissionDeadline = false;
  @Output() customFormAction: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _submitValidationService: SubmitValidationService,
              private _dynamicFormService: DynamicFormService,
              private _financeService: FinanceService,
              private _projectGroupFormService: ProjectGroupFormService) {
  }

  /**
   * callback oes-dynamic-form
   * @param event: {}; only contains updated values
   */
  customFormUpdated(event) {
    if (this._submitValidationService.checkDeadline(this.projectGroup)) {
      return this._submitValidationService.sendErrorMessage('too-late');
    }
    const newJson = this._dynamicFormService.updateDefaultValue(this.json, event);
    // Set page validation status
    const formValid = this._dynamicFormService.checkRequiredFields(newJson);
    this._financeService.setPageStatus(PORTFOLIO_PAGE.PORTFOLIO_DESCRIPTION, formValid);
    // send to back-end
    this._projectGroupFormService.uploadForm(
      this.projectGroup.id,
      this._projectGroupFormService.getFormFilesList()['portfolioDescription'],
      newJson,
      formValid
    )
    .pipe(take(1))
    .subscribe(savedFormData => {
      if (savedFormData) {
        // 'save' is not used but added it for future.
        this.customFormAction.emit('save');
      }
    });
  }
}
