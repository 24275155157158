import { BaseModel } from '@shared/base.model';

export class ProgramProjectStageRules extends BaseModel<ProgramProjectStageRules> {
  name: string;
  color: string;
  timestamp: Date;
  disabled: boolean;
  editableProject: boolean;
  editableProjectFinancier: boolean;
  allowFileUploads: boolean;
  allowConnectionsUpdates: boolean;
  showInClaims: boolean;

  constructor(payload: any) {
    super();
    this.id = payload.id;
    this.name = payload.name;
    this.color = payload.color;
    this.timestamp = payload.timestamp;
    this.disabled = payload.disabled;
    this.editableProject = payload.editableProject;
    this.editableProjectFinancier = payload.editableProjectFinancier;
    this.allowFileUploads = payload.allowFileUploads;
    this.allowConnectionsUpdates = payload.allowConnectionsUpdates;
    this.showInClaims = payload.showInClaims;
  }
}
