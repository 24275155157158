
<oes-modal-dialog
  [title]="'buttons.create' | translate"
  [submitButtonText]="'buttons.save' | translate"
  [activateSubmit]="slashError || !formGroup.valid"
  (submit)="createNewFolder()">
  <!-- the below goes to dialog-body -->
  <div [formGroup]="formGroup">
    <div>
      {{'data-room.dialogs.create-folder.create-under' | translate}}
      <oes-dropdown
        *ngIf="selectOptions"
        id="createFolderFolderSelection"
        [parentFormGroup]="formGroup"
        [initialSelections]="[selectedOption]"
        [items]="selectOptions"
        [selection]="'single'"
        [enableSearch]="true">
      </oes-dropdown>
    </div>
    <div class="form-group">
      <label for="new-folder-with-path required">
        {{'data-room.dialogs.create-folder.name' | translate}}
      </label>
      <div class="input-group">
        <input
          #oesAutoFocus
          type="text"
          class="form-control"
          id="new-folder"
          name="new-folder"
          (keydown)="onKeyDown($event)"
          formControlName="folderName">
      </div>
      <p class="slash-error" *ngIf="slashError">{{'data-room.dialogs.slash-error' | translate}}</p>
      <label for="create-folder-tag-name">{{'data-room.dialogs.upload.tag' | translate}}</label>
      <input 
        formControlName="tag"
        class="tag-input"
        id="create-folder-tag-name"
        autocomplete="off"
        (keydown)="onKeyDown($event)"
        (change)="selectTag($event)"
        list="createTagList">
      <datalist id="createTagList">
        <option *ngFor="let tag of tags" [value]="tag.tag">
          {{tag.tag}}
        </option>
      </datalist>
    </div>
  </div>
</oes-modal-dialog>
