import { Component } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'oes-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default ag-custom-component-popup',
    showWeekNumbers: false, // default is true
    dateInputFormat: 'YYYY-MM-DD'
  };
  public formGroup: UntypedFormGroup;
  private date: Date;
  private params: any;

  constructor() {
  }

  agInit(params: any) {
    this.params = params;
    this.formGroup = new UntypedFormGroup({
      calendar: new UntypedFormControl(null)
    });

    this.formGroup.controls['calendar'].valueChanges
    .subscribe(date => {
      this.calendarUpdated(date);
    });
  }

  private calendarUpdated(selectedDates) {
    this.date = selectedDates;
    this.params.onDateChanged();
  }

  getDate(): Date {
    return this.date;
  }

  setDate(date: any): void {
    this.date = date || null;
    this.formGroup.controls['calendar'].setValue(date, {emitEvent: false});
  }
}
