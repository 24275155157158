import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Milestone } from "../../milestone.model";
import { UserService } from "@user/user.service";
import { take } from "rxjs";
import { User } from "@user/user.model";
import { DOCUMENT_TAG_TYPE } from "@shared/components/files/shared/document-tag-type.enum";
import { Project } from "@project/shared/project.model";
import { ModalDialogComponent } from "@shared/components/modal-dialog/modal-dialog.component";
import { MilestoneService } from "../../milestone.service";
import { DocumentDataService } from "@shared/components/files/shared/document-data.service";
import FileSaver from "file-saver";
import { DateTime } from "luxon";
import { Document } from "@shared/components/files/shared/models/document.model";

@Component({
  selector: 'oes-milestone-content-notes',
  templateUrl: './milestone-content-notes.component.html',
  styleUrls: ['./milestone-content-notes.component.scss'],
})
export class MilestoneContentNotesComponent implements OnInit, OnChanges {
  @ViewChild('deleteNoteWarningModal', {static: false}) deleteNoteWarningModal: ModalDialogComponent;

  @Input() milestone: Milestone;
  @Input() project: Project;
  @Input() readOnly: boolean;

  DEFAULT_NOTES_LIST_LIMIT = 3;

  allNoteDocuments: {[key: string]: Document[]};
  expandButtonLabel = 'Expand';
  notesListLimit = this.DEFAULT_NOTES_LIST_LIMIT;
  noteSort: 'asc' | 'desc' = 'desc';
  user: User;

  private noteIndexToDelete: number;

  constructor(private _documentDataService: DocumentDataService,
              private _milestoneService: MilestoneService,
              private _userService: UserService) {}

  ngOnInit(): void {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    this.initialise();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.milestone && changes.milestone.currentValue) {
      this.initialise();
    }
  }

  initialise() {
    this.populateAllNoteDocuments();
    this.sortNotes();
    this.expandButtonLabel = this.resolveExpandButtonLabel();
  }

  handleOpenEditMilestoneAddNote(milestoneIndex) {
    this._milestoneService.openEditMilestone({
      milestone: this.milestone,
      milestoneIndex: milestoneIndex,
    });
  }

  handleOpenEditMilestoneEditNote(milestoneIndex, note, noteIndex) {
    this._milestoneService.openEditMilestone({
      milestone: this.milestone,
      milestoneIndex: milestoneIndex,
      noteDocuments: this.allNoteDocuments?.[note.id],
      noteIndex: noteIndex,
    });
  }

  toggleNoteSort() {
    this.noteSort = this.noteSort === 'asc' ? 'desc' : 'asc';
    this.expandButtonLabel = this.resolveExpandButtonLabel();
    this.sortNotes();
  }

  sortNotes() {
    this.milestone.notes?.sort((a, b) => {
      if (this.noteSort === 'asc') {
        return a.created > b.created ? 1 : -1;
      } else {
        return a.created < b.created ? 1 : -1;
      }
    });
  }

  resolveExpandButtonLabel(): string {
    if (this.milestone.notes?.length > this.DEFAULT_NOTES_LIST_LIMIT) {
      if (this.notesListLimit > this.DEFAULT_NOTES_LIST_LIMIT && this.noteSort === 'asc') {
        return 'milestones.hide-newer-notes';
      } else if (this.notesListLimit > this.DEFAULT_NOTES_LIST_LIMIT && this.noteSort === 'desc') {
        return 'milestones.hide-older-notes';
      } else if (this.notesListLimit === this.DEFAULT_NOTES_LIST_LIMIT && this.noteSort === 'asc') {
        return 'milestones.show-newer-notes';
      } else if (this.notesListLimit === this.DEFAULT_NOTES_LIST_LIMIT && this.noteSort === 'desc') {
        return 'milestones.show-older-notes';
      }
    }
    return '';
  }

  populateAllNoteDocuments() {
    this.allNoteDocuments = this.milestone.documents?.reduce((acc, doc) => {
      const key = doc.documentTags.find(tag => tag.tagType === DOCUMENT_TAG_TYPE.MILESTONE_NOTE)?.index;
      if (!key) {
        return acc;
      }
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(doc);
      return acc;
    }, {});
  }

  getNoteOrgInitials(orgName: string) {
    const initialInitials = orgName.split(/\s/).map(word => word[0]).join('');
    return initialInitials.length > 2 ? initialInitials.slice(0, 2) : initialInitials;
  }

  handleDeleteNote() {
    const noteId = this.milestone.notes[this.noteIndexToDelete].id;
    const remainingDocuments = this.milestone.documents.filter(doc =>
      !doc.documentTags.find(tag => tag.index === noteId));
    delete this.allNoteDocuments[noteId];
    const updatedMilestone = JSON.parse(JSON.stringify(this.milestone));
    updatedMilestone.notes.splice(this.noteIndexToDelete, 1);
    updatedMilestone.documents = remainingDocuments;
    this._milestoneService.updateMilestone(updatedMilestone);
    this.handleCloseNoteDeleteWarning();
  }

  downloadDocumentsClick(documents: Document[]) {
    const requestItems = [...new Set(documents.map(doc => doc.documentKey.key))];
    this._documentDataService.downloadDocuments(requestItems, this.project.organization.id)
    .pipe(take(1))
    .subscribe((data: any) => {
      if (data) {
        let name = '';
        if (documents && documents.length === 1) {
          name = documents[0].path;
        } else {
          name = 'Documents_' + DateTime.local().toSQLDate() + '.zip';
        }
        FileSaver.saveAs(data, name);
      }
    });
  }

  toggleNotesListLimit() {
    this.notesListLimit = this.notesListLimit === this.DEFAULT_NOTES_LIST_LIMIT
      ? this.milestone.notes.length
      : this.DEFAULT_NOTES_LIST_LIMIT;
    this.expandButtonLabel = this.resolveExpandButtonLabel();
  }

  handleOpenNoteDeleteWarning(noteIndexToDelete: number) {
    this.deleteNoteWarningModal.show();
    this.noteIndexToDelete = noteIndexToDelete;
  }

  handleCloseNoteDeleteWarning() {
    this.noteIndexToDelete = null;
    this.deleteNoteWarningModal.hide();
  }
}
