import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { Organization } from '@shared/models/organization/organization.model';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { OrganizationListGridSettingService } from './organization-list-grid-setting.service';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { ProfileType } from './profile-type.enum';
import { GridState } from '@shared/ag-grid/gird-state';

@Component({
  selector: 'oes-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  providers: [OrganizationListGridSettingService]
})
export class OrganizationListComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: true}) private profileDialog: ModalDialogComponent;
  @Input() organizations: Organization[];
  @Input() exportName = 'Exported list';
  @Input() profileType: ProfileType;
  @Input() url: string;

  columnApi: ColumnApi;
  defaultGridState: GridState;
  gridApi: GridApi;
  gridOptions: GridOptions = {};
  gridState: GridState;
  organizationId = '';
  organizationProfileType = ProfileType;

  private gridStateStorageKey: string;

  constructor(public _organizationListGridSettingService: OrganizationListGridSettingService) {
  }

  ngOnInit() {
    this.gridStateStorageKey = this._organizationListGridSettingService.buildGridStateStorageKey(this.profileType + 'List');
    this.gridOptions = this._organizationListGridSettingService.getGridOptions(this.profileType);
    this.gridOptions['context'] = {
      gridService: this
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._organizationListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._organizationListGridSettingService.buildDefaultGridState();
    if (this.organizations && this.organizations.length > 0) {
      this.gridApi.showLoadingOverlay();
      this.gridApi.setRowData(this.organizations);
      this._organizationListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._organizationListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const processCellCallback = (params: any) => {
          if (typeof params?.value === 'object') {
            const keys = Object.keys(params.value);
            if (keys.includes('filename')) {
              return '-';
            }
          }
          return params.value;
        };
        this._organizationListGridSettingService.exportCsv(this.gridApi, this.exportName, true, processCellCallback);
        break;
      }
      case GridSubAction.clearFilter:
        this._organizationListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  // call from _organizationListGridSettingService via context
  openProfile(id: string) {
    if (id && id !== '') {
      this.organizationId = id;
      this.profileDialog.show();
    }
  }
}
