import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { FilesService } from '@shared/components/files/shared/files.service';
import { take } from 'rxjs/operators';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { DeleteTag } from '@shared/components/files/shared/models/delete-tag.model';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';

@Component({
  selector: 'oes-tag-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class TagDeleteComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;
  public tags: any[];

  constructor(private _documentDataService: DocumentDataService,
              private _filesService: FilesService,
              private _translateService: TranslateService,
              private _eventService: EventService) {
  }

  ngOnInit() {
    // need to get the latest tags
    this._filesService.selectedNodes$
    .subscribe(selectedNode => {
      if (selectedNode && selectedNode.length > 0) {
        // Delete tag only works for a single selection
        this.tags = selectedNode[0].data.tags
        .filter(tag => tag.tagType === DOCUMENT_TAG_TYPE.USER)
        .map(tag => {
          return {
            id: tag.id,
            tag: tag.tag,
            tagType: tag.tagType
          };
        });
      } else {
        this.tags = [];
      }
    });
  }

  public show() {
    this.modalDialog.show();
  }

  public hide() {
    this.modalDialog.hide();
  }

  public delete(tagId: string) {
    if (tagId && tagId !== '') {
      const selectedNode = this._filesService.getSelectedNodes();
      const documentTag = <DeleteTag>{
        documentId: selectedNode[0].data.id,
        documentTagsId: tagId,
      };
      this._documentDataService.disassociateTag(documentTag)
      .pipe(take(1))
      .subscribe(result => {
        this.tags = this.tags.filter(tag => tag.id !== documentTag.documentTagsId);
        this._eventService.success(this._translateService.instant('data-room.dialogs.tag.deleted'));
      });
    }
  }

  public completed() {
    this._filesService.clickActionButton(ActionType.completed);
  }
}
