export enum View {
  LIST = 'list',
  CARD = 'card',
  GRID = 'grid',
  MAP = 'map',
  TARIFF = 'tariff',
  ANALYTICS = 'analytics',
  FINANCIAL = 'financial',
  ACTION = 'action',
  PORTFOLIO = 'portfolio',
  BULK_UPLOAD = 'bulk_upload',
  PAY_CONNECTIONS = 'pay_connections'
}
