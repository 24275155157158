<div class="project-header-tags">
  <ng-container *ngIf="project?.program?.name">
    <div class="header-tag header-tag-brand">
      <a href="#" [routerLink]="['../../../finance/programs/' + project.program.id]">{{ project.program.name }}
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 5H8.5M8.5 5L5 1.5M8.5 5L5 8.5" stroke="#EE7802" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>

    </div>
  </ng-container>
  <div class="header-tag">
    <p>{{ 'project.types.' + project?.projectType | translate }}</p>
  </div>
  <div class="header-tag">
    <p>{{ 'project-status.' + project?.status | translate }}</p>
  </div>
</div>
