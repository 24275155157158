import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, SecurityContext } from '@angular/core';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { take, map, finalize } from 'rxjs/operators';
import { FilesService } from '@shared/components/files/shared/files.service';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { FolderService } from '@shared/components/files/shared/folder.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject, concat } from 'rxjs';
import { AddTag } from '@shared/components/files/shared/models/add-tags.model';
import { FileNameCheckerService } from '@shared/services/file-name-checker.service';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { Document } from '@shared/components/files/shared/models/document.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'oes-files-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit, OnDestroy {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;

  @Input() tagTypes: TagType[];
  @Input() extraMessage: string;

  @Output() uploadProcessCompleted: EventEmitter<Document[]> = new EventEmitter<Document[]>();

  files: File[] = [];
  formGroup: UntypedFormGroup;
  selectedOption: IMultiSelectOption;
  selectOptions: IMultiSelectOption[] = [];
  showUploadProgress: boolean;
  tags: any;
  uploadsCompleted: number;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _eventService: EventService,
              private _translateService: TranslateService,
              private _filesService: FilesService,
              private _sanitizer: DomSanitizer,
              private _documentDataService: DocumentDataService,
              private _folderService: FolderService,
              private _fileNameCheckerService: FileNameCheckerService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      selectOption: new UntypedFormControl(null, Validators.required),
      tag: new UntypedFormControl('')
    });
    this.uploadsCompleted = 0;
    this.showUploadProgress = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onKeyDown(e) {
    const y = this.formGroup.controls['tag'].value;
    if (e.keyCode === 13 && this.formGroup.valid) {
      this.upload();
    }
  }

  public show() {
    this.tags = this._filesService.tagList;
    this.selectOptions = this._folderService.generateSelectOptions(undefined, this.tagTypes);
    const highlightedNodes = this._filesService.getHighlightedNodes();
    if (highlightedNodes && highlightedNodes.length === 1 && highlightedNodes[0]?.data?.documentKey?.docType === 'PATH') {
      const selectedId = this.selectOptions.findIndex(opt => opt.id === highlightedNodes[0].id);
      this.selectedOption = this.selectOptions[selectedId];
    } else {
      this.selectedOption = this.selectOptions[0];
    }
    this.modalDialog.show();
  }

  public reset() {
    this.formGroup.reset();
    this.files = [];
    this.showUploadProgress = false;
    this.uploadsCompleted = 0;
    this.modalDialog.hide();
  }

  // angular doesn't support valueChanges.subscribe for input type="file" and use (change)
  public changeInput(event) {
    if (event && event.target && event.target.files) {
      this.files = event.target.files;
    }
  }

  public selectTag(event) {
    this.formGroup.controls['tag'].setValue(event.target.value);
  }

  public upload() {
    const tag = this._sanitizer.sanitize(SecurityContext.HTML, this.formGroup.get('tag').value);
    if (this.files && this.files.length > 0) {
      // add docTagItemId and docTagType
      const masterTagType = this._filesService.getMasterTag(this.tagTypes);
      const pathId = (this.formGroup.controls['selectOption'].value)[0];
      const path = this.selectOptions.find(option => option.id === pathId);
      const fullPath = pathId === 'baseLevel' ? '' : path.name;
      const documentIds = [];
      const responseIds = [];

      const fileIndexes = Object.keys(this.files);
      fileIndexes.forEach(fileIndex => {
        const file = this.files[fileIndex];
        // replace file name with s3 safe string
        const s3SafeName = this._fileNameCheckerService.s3Safe(file.name);
        const formData = new FormData();
        // rename file object file name
        formData.append('file', file, s3SafeName);
        formData.append('index', masterTagType.docTagItemId);
        formData.append('systemDocumentType', masterTagType.systemDocumentType ? masterTagType.systemDocumentType : masterTagType.docTagType);
        this.tagTypes.forEach(tagType => {
          formData.append('docTagItemId', tagType.docTagItemId);
          formData.append('docTagType', tagType.docTagType);
        });
        formData.append('uploadPath', fullPath);
        documentIds.push(this._documentDataService.uploadDocuments(formData));
      });
      this.showUploadProgress = true;

      const filesToSend = [];

      // Makes requests sequentially instead of in parallel like forkJoin (protects API by limiting concurrency)
      concat(...documentIds)
      .pipe(finalize(() => {
        this.uploadProcessCompleted.emit(filesToSend);
      }))
      .subscribe((document: Document) => {
        filesToSend.push(document);
        this.uploadsCompleted++;
        responseIds.push(document?.id);
        if (responseIds.length === documentIds.length) {
          tag ? this.addOptionalTags(responseIds, tag) : this.finish();
        }
      }, error => {
        this.reset();
        console.log(error?.error?.message);
        this._eventService.error(this._translateService.instant('data-room.dialogs.uploading-failed'));
        this._filesService.clickActionButton(ActionType.completed);
      });
    }
  }

  finish() {
    this._filesService.clickActionButton(ActionType.completed);
    this.reset();
    this._eventService.success(this._translateService.instant('data-room.dialogs.files-uploaded'));
  }

  addOptionalTags(docIds, tag) {
    const addTag: AddTag = {
      documentIds: docIds,
      tag: tag
    };
    const id = this._filesService.getTagId(tag);
    if (id && id !== '') {
      addTag['id'] = id;
    }

    this._documentDataService.addUserTag(addTag)
    .pipe(take(1))
    .subscribe(result => {
      this.finish();
    });
  }
}
