<h5>{{ 'program.evaluation.evaluator' | translate }}: {{ evaluations[0].editorName }}</h5>

<div oesAgHorizontalScroll>
  <ag-grid-angular
    id="evaluationCriteriaGrid"
    style="width: auto;"
    class="grid ag-theme-alpine"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>

<ng-container *ngIf="evaluations?.length">
  <div class="totals">
    <p>{{ 'program.evaluation.total-score' | translate }}</p>
    <p class="tag pass-fail-tag" [ngClass]="{ 'pass' : totals.passFail === 'PASS', 'fail' : totals.passFail === 'FAIL' }">{{ 'program.evaluation.' + totals.passFail | translate }}</p>
    <p class="tag">{{ totals.totalScore | number: '1.0-1' }}/100</p>
  </div>
</ng-container>