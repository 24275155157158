import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormioComponent } from '@formio/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { DynamicFormService } from './dynamic-form.service';
import { ValidationType } from './validation-type.enum';

@Component({
  selector: 'oes-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FormioComponent, {static: false}) formioComponent: FormioComponent;

  @Input() json: any;
  @Input() readOnly: boolean;
  @Input() simple = false;
  @Input() enableSubmit = false;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() invalid: EventEmitter<any> = new EventEmitter<any>();
  @Output() beforeSubmission: EventEmitter<any> = new EventEmitter<any>();
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileSave: EventEmitter<any> = new EventEmitter<any>();

  submissionData: any;
  type = ValidationType;
  updatedForm: any;

  private _invalidItems = [];
  private isInitialUpdate = true;
  private message = {};
  private ngUnsubscribe: Subject<any> = new Subject();

  private _changeValueSubject = new BehaviorSubject<any>(false);
  private _changeValues$: Observable<any> = this._changeValueSubject.asObservable();

  constructor(private _dynamicFormService: DynamicFormService,
              private _eventService: EventService,
              private _translateService: TranslateService) {
    this.message = this._translateService.instant('general-message');
  }

  ngOnInit() {
    // for old form
    this.exportDefaultValues(this.json);
    // if json has data, validate
    if (this.json?.components) {
      // remove old submit button and make a clone
      this.updatedForm = {...this._dynamicFormService.checkSubmitButton(this.json)};
      if (this.updatedForm.data) {
        this.invalidItems = this._dynamicFormService.validate(this.json?.components, this.updatedForm.data);
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    if (!this.readOnly) {
      // formio is ready
      this.formioComponent.ready
      .pipe(take(1))
      .subscribe((formio: FormioComponent) => {
        if (!this.readOnly) {
          this.subscribeChanges();
        }
      });
    }
  }

  get invalidItems() {
    return this._invalidItems;
  }

  set invalidItems(value) {
    this._invalidItems = value;
    this.formValid.emit(this.invalidItems.length ? false : true);
  }

  // Old form doesn't have json.data because we are using defaultValue as a data storage before. Now it stores in json['data']
  // To run a formio mandatory fields validation, we need to use [submission] of formio
  private exportDefaultValues(json) {
    if (json && !json.data) {
      const result = this._dynamicFormService.exportDefaultValues(json);
      if (result) {
        this.submissionData = {data: result};
      }
    } else {
      this.submissionData = {data: json.data};
    }
  }

  // subscribe Formio (change) and emit saving after 30 sec
  private subscribeChanges() {
    this._changeValues$.pipe(
      debounceTime(30000),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
      const event = this._changeValueSubject.getValue();
      if (event.data) {
        this.updateInvalidItems(event);
        this.save.emit(event);
      }
    });
  }

  // Formio button event is 'submit'
  // public submit(event) {
  //   this.submission.emit(event);
  //   this.ngUnsubscribe.next(null);
  //   this.ngUnsubscribe.complete();
  // }

  // Submit button
  // Parent will show up a modal dialog for a final verification before submitting.
  public beforeSubmit() {
    if (this.updatedForm) {
      this.beforeSubmission.emit(this.updatedForm);
    }
  }

  // Formio button event is 'event' (save or validate)
  public emitEvent(event) {
    // event.data is a responses object
    if (event.data) {
      if (event.type === 'save') {
        const newEvent = event;
        newEvent.data = this.removeWhiteSpace(event.data);
        this.updateInvalidItems(event);
        this.save.emit(newEvent);
      } else if (event.type === 'validate') {
        this.updateInvalidItems(event, true);
      }
    }
  }

  public saveTriggered() {
    this.save.emit(this.updatedForm);
  }

  private updateInvalidItems(event, alertUser = false) {
    this.invalidItems = this._dynamicFormService.validate(this.updatedForm?.components, event.data);
    if (alertUser) {
      if (this.invalidItems?.length > 0) {
        this._eventService.error(this.message['error']['form-invalid']);
      } else {
        this._eventService.success(this.message['success']['form-valid']);
      }
    }
  }

  public emitInvalid(event) {
    this.invalid.emit(event);
  }

  // Formio (change) callback
  public onChange(event) {
    if (event?.changed?.component?.type === 'file') {
      const ev = this._changeValueSubject.getValue();
      if (ev.data) {
        this.updateInvalidItems(ev);
        this.save.emit(ev);
        this.fileSave.emit(ev);
      }
    }
    if (event.data) {
      this.updatedForm['data'] = event.data;
      this.invalidItems = this._dynamicFormService.validate(this.updatedForm?.components, event.data);
    }

    if (this.isInitialUpdate) {
      this.isInitialUpdate = false;
    } else {
      // no changes, no saving
      if (event.changed && event.changed.value) {
        // CKEditor returns '<p>&nbsp;</p>' when you removed all text.
        if (event.changed.value === '<p>&nbsp;</p>' || // CKEditor's empty
            event.changed.value === '<p><br></p>') {   // Quill's empty
          event.changed.value = '';
        }
        if (!this.readOnly) {
          // parent set a dirty flag
          this.changed.emit(true);
          // for auto saving
          this._changeValueSubject.next(event);
        }
      }
    }
  }

  private removeWhiteSpace(data) {
    const newData = data;
    Object.keys(newData).forEach(key => {
      if (newData[key] === '<p>&nbsp;</p>' || // CKEditor's empty
          newData[key] === '<p><br></p>') {   // Quill's empty
        newData[key] = '';
      }
    });
    return newData;
  }
}
