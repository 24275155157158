export class TouPeriod {
  id: string;
  start: number;
  end: number;
  modifier: number;
  // Used as the display value in the frontend
  modifierDisplayValue: number;

  constructor(touPeriodInfo: any) {
    this.id = touPeriodInfo.id;
    this.start = touPeriodInfo.start;
    this.end = touPeriodInfo.end;
    this.modifier = touPeriodInfo.modifier;
    this.modifierDisplayValue = touPeriodInfo.modifier * 100;
  }
}
