import { Program } from '@program/shared/program.model';
import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';
import { Country } from '@shared/services/country/country.model';
import { Region } from '@shared/services/region/region.model';

import { ProjectPermissions } from './project-permissions.model';
import { ProjectSpec } from './project-specs.model';
import { ProjectType } from './project-type.enum';

export class Project extends BaseModel<Project> {
  address1: string;
  address2: string;
  alternateCurrency: string;
  postalCode: string;
  city: string;
  cod: Date;
  country: Country;
  description: string;
  exchangeRate: number;
  latitude: number;
  lockDateTime: string;
  longitude: number;
  name: string;
  originalProjectCreatorId: string;
  organization: Organization;
  peakKw: number;
  program: Program;
  projectReportName: string;
  projectReportProvider: string;
  projectReportUrl: string;
  projectSpecifications: Array<ProjectSpec>;
  projectStage: string;
  projectType: ProjectType;
  projectVersion: number;
  region: Region;
  status: string;
  tenderProject: boolean;
  totalCapex: number;
  totalCustomers: number;
  totalPV: number;
  totalConnectionsNotSubmitted: number;
  totalConnectionsSubmitted: number;
  totalConnectionsApproved: number;
  totalConnectionsPaid: number;
  totalConnectionsRejected: number;
  totalConnectionsEverSubmitted: number;
  totalConnectionsVerified: number;
  totalConnectionsVerifiedSubmitted: number;
  connectionsAmountOwed?: number;
  connectionsAmountPaid?: number;
  connectionsAmountPotential?: number;
  connectionsAmountPotentialInCurrency?: number;
  connectionsAuditedPercent: number;
  connectionsAuditSuccessfulPercent: number;
  connectionsAuditedCount: number;
  connectionsAuditSuccessfulCount: number;
  applicationFormComplete: boolean;
  submittedToProgramDateTime: Date;
  projectPayoutExchangeRate: number;
  projectPayoutCurrency: string;
  connectionsAmountPaidInCurrency: number;
  projectPermissions: ProjectPermissions;
  duplicationCheckDateTime: Date;

  constructor(projectInfo: any) {
    super();
    this.id = projectInfo.id;
    this.alternateCurrency = projectInfo.alternateCurrency;
    this.projectType = projectInfo.projectType;
    this.city = projectInfo.city;
    this.cod = projectInfo.cod;
    this.country = new Country(projectInfo.country || {});
    this.description = projectInfo.description;
    this.exchangeRate = projectInfo.exchangeRate;
    this.latitude = projectInfo.latitude;
    this.lockDateTime = projectInfo.lockDateTime;
    this.longitude = projectInfo.longitude;
    this.name = projectInfo.name;
    this.peakKw = projectInfo.peakKw;
    this.projectVersion = projectInfo.projectVersion;
    this.region = new Region(projectInfo.region || {});
    this.organization = new Organization(projectInfo.organization || {});
    this.status = projectInfo.status;
    this.program = new Program(projectInfo.program || {});
    this.projectStage = projectInfo.projectStage;
    this.tenderProject = projectInfo.tenderProject;
    this.totalCapex = projectInfo.totalCapex;
    this.totalCustomers = projectInfo.totalCustomers;
    this.totalPV = projectInfo.totalPV;
    this.totalConnectionsNotSubmitted = projectInfo.totalConnectionsNotSubmitted;
    this.totalConnectionsSubmitted = projectInfo.totalConnectionsSubmitted;
    this.totalConnectionsApproved = projectInfo.totalConnectionsApproved;
    this.totalConnectionsPaid = projectInfo.totalConnectionsPaid;
    this.totalConnectionsRejected = projectInfo.totalConnectionsRejected;
    this.totalConnectionsEverSubmitted = projectInfo.totalConnectionsEverSubmitted;
    this.totalConnectionsVerified = projectInfo.totalConnectionsVerified;
    this.totalConnectionsVerifiedSubmitted = projectInfo.totalConnectionsVerifiedSubmitted;
    this.connectionsAmountOwed = projectInfo.connectionsAmountOwed;
    this.connectionsAmountPaid = projectInfo.connectionsAmountPaid;
    this.connectionsAmountPotential = projectInfo.connectionsAmountPotential;
    this.connectionsAmountPotentialInCurrency = projectInfo.connectionsAmountPotentialInCurrency;
    this.connectionsAuditedPercent = projectInfo.connectionsAuditedPercent;
    this.connectionsAuditSuccessfulPercent = projectInfo.connectionsAuditSuccessfulPercent;
    this.connectionsAuditedCount = projectInfo.connectionsAuditedCount;
    this.connectionsAuditSuccessfulCount = projectInfo.connectionsAuditSuccessfulCount;
    this.applicationFormComplete = projectInfo.applicationFormComplete;
    this.submittedToProgramDateTime = projectInfo.submittedToProgramDateTime;

    this.projectSpecifications = [];
    if (projectInfo.projectSpecifications != null && projectInfo.projectSpecifications.length > 0) {
      projectInfo.projectSpecifications.forEach(element => {
        this.projectSpecifications.push(new ProjectSpec(element, element.specificationSource));
      });
    }

    this.projectReportName = projectInfo.projectReportName;
    this.projectReportUrl = projectInfo.projectReportUrl;
    this.projectReportProvider = projectInfo.projectReportProvider;
    this.originalProjectCreatorId = projectInfo.originalProjectCreatorId;
    this.projectPayoutExchangeRate = projectInfo.projectPayoutExchangeRate;
    this.projectPayoutCurrency = projectInfo.projectPayoutCurrency;
    this.connectionsAmountPaidInCurrency = projectInfo.connectionsAmountPaidInCurrency;
    this.projectPermissions = projectInfo.projectPermissions;
    this.duplicationCheckDateTime = projectInfo.duplicationCheckDateTime;
    this.address1 = projectInfo.address1;
    this.address2 = projectInfo.address2;
    this.postalCode = projectInfo.postalCode;
  }
}
