
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenerationDesign } from './generation-design.model';
import { GenerationDesignJob } from './generation-design-job.model';
import { SYSTEM_TYPE } from './system-type.enum';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { GenerationDesignApiConstants } from './generation-design-api.constant';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GenerationDesignService extends BaseRestApiService {
  private generationDesignSubject = new BehaviorSubject<GenerationDesign>(undefined);
  public generationDesign$ = this.generationDesignSubject.asObservable();

  private reloadGenerationDesignSubject = new BehaviorSubject<boolean>(false);
  public reloadGenerationDesign$ = this.reloadGenerationDesignSubject.asObservable();

  private _generationDesign: GenerationDesign;
  private _generationDesignJob: GenerationDesignJob;

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _translateService: TranslateService) {
    super(_restApiWrapperService);
  }

  public reloadDesign() {
    this.reloadGenerationDesignSubject.next(true);
  }

  public list(projectId: string): Observable<GenerationDesign[]> {
    return this.get(GenerationDesignApiConstants.list(projectId))
           .pipe(
             map((response: any) => {
               return response.map(content => new GenerationDesign(content));
             })
           );
  }

  public create(projectId: string, generationDesign: GenerationDesign): Observable<GenerationDesign> {
    return this.post<GenerationDesign>(
      GenerationDesignApiConstants.create(projectId),
      JSON.stringify(generationDesign)
    );
  }
  public detail(projectId: string, generationDesignId: string): Observable<any> {
    return this.get(GenerationDesignApiConstants.detail(projectId, generationDesignId)).pipe(
      map(item => new GenerationDesign(item))
    );
  }
  public update(projectId: string, generationDesign: GenerationDesign): Observable<GenerationDesign> {
    return this.put<GenerationDesign>(
      GenerationDesignApiConstants.update(projectId, generationDesign.id),
      JSON.stringify(generationDesign)
    );
  }
  public remove(projectId: string, generationDesignId: string): Observable<any> {
    return this.delete(GenerationDesignApiConstants.delete(projectId, generationDesignId));
  }
  public convert(projectId: string, generationDesignId: string, systemType: SYSTEM_TYPE): Observable<GenerationDesign> {
    return this.post<GenerationDesign>(GenerationDesignApiConstants.convert(projectId, generationDesignId, systemType));
  }
  public copy(projectId: string, generationDesign: GenerationDesign): Observable<any> {
    return this.post(
      GenerationDesignApiConstants.copy(projectId, generationDesign.id),
      JSON.stringify(generationDesign)
    );
  }
  public finalize(projectId: string, generationDesign: GenerationDesign): Observable<any> {
    return this.put(
      GenerationDesignApiConstants.finalize(projectId, generationDesign.id),
      JSON.stringify(generationDesign)
    );
  }
  public copyToLibrary(projectId: string, generationDesignId: string, name?: string): Observable<any> {
    return this.post(
      GenerationDesignApiConstants.copyToLibrary(projectId, generationDesignId),
      JSON.stringify(name ? {name: name} : {})
    );
  }
  public copyFromLibrary(projectId: string, generationDesignId: string, name: string): Observable<any> {
    return this.post(
      GenerationDesignApiConstants.copyFromLibrary(projectId, generationDesignId),
      JSON.stringify({name: name})
    );
  }
  public updateCost(projectId: string, generationDesignId: string): Observable<any> {
    return this.post(GenerationDesignApiConstants.updateCost(projectId, generationDesignId));
  }

  public notFeasible(): boolean {
    if (this._generationDesignJob) {
      return this._generationDesignJob.winningResultId === 'calculated' &&
             !this._generationDesign.costOfEnergy &&
             !this._generationDesign.excessElectricity &&
             !this._generationDesign.totalLoadAvailability &&
             !this._generationDesign.totalRenewableGeneration;
    }
    return false;
  }

  get generationDesign(): GenerationDesign {
    return this._generationDesign;
  }

  set generationDesignJob(generationDesignJob: GenerationDesignJob) {
    this._generationDesignJob = generationDesignJob;
  }

  get generationDesignJob(): GenerationDesignJob {
    return this._generationDesignJob;
  }

  set generationDesign(generationDesign: GenerationDesign) {
    this._generationDesign = generationDesign;
    this.generationDesignSubject.next(this.generationDesign);
  }

  set systemType(systemType: SYSTEM_TYPE) {
    this._generationDesign.systemType = systemType;
    this.generationDesignSubject.next(this.generationDesign);
  }

  public getLoadAvailabilityWarning(projectId: string): Observable<string> {
    return this.list(projectId)
      .pipe(map((generationDesigns: GenerationDesign[]) => {
        const activeGenerationDesign =
          generationDesigns.find(genDesign => genDesign.finalizeDateTime);
        if (!activeGenerationDesign) {
            return this._translateService.instant('revenue-model.no-active-gen-design-warning');
        } else if (activeGenerationDesign.totalLoadAvailability === 0) {
            return this._translateService.instant('revenue-model.load-availability-zero-warning');
        } else if (!activeGenerationDesign.totalLoadAvailability) {
          return this._translateService.instant('revenue-model.load-availability-null-warning');
        } else {
          return null;
        }
      }));
  }
}
