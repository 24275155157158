import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActionType } from './action-type.enum';
import { TagType } from './models/tag-type.model';
import { DOCUMENT_TAG_TYPE } from './document-tag-type.enum';

/**
 * FilesService: Deliver selections and action statuses to subscribers
 */
@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private _actionSubject = new BehaviorSubject<ActionType>(undefined);
  public actions$: Observable<ActionType> = this._actionSubject.asObservable();

  // Note: use BehaviorSubject because need to deliver a value to multiple subscribers.
  // Each subscriber will decide how handle the data
  private _selectedNodes = new BehaviorSubject<any>(undefined);
  public selectedNodes$: Observable<any> = this._selectedNodes.asObservable();

  private _tagList = new BehaviorSubject<any[]>([]);
  public tagList$: Observable<any> = this._tagList.asObservable();

  private _associationList = new BehaviorSubject<any[]>([]);
  public associationList$: Observable<any> =
    this._associationList.asObservable();

  private _programList = new BehaviorSubject<any[]>([]);
  public programList$: Observable<any> = this._programList.asObservable();

  private _portfolioList = new BehaviorSubject<any[]>([]);
  public portfolioList$: Observable<any> = this._portfolioList.asObservable();

  private _ciPortfolioList = new BehaviorSubject<any[]>([]);
  public ciPortfolioList$: Observable<any> =
    this._ciPortfolioList.asObservable();

  private _projectList = new BehaviorSubject<any[]>([]);
  public projectList$: Observable<any> = this._projectList.asObservable();

  private _highlightedNodes = new BehaviorSubject<any>(undefined);
  public highlightedNodes$: Observable<any> =
    this._highlightedNodes.asObservable();

  constructor() {}

  // Handle all action buttons
  public clickActionButton(action: ActionType) {
    this._actionSubject.next(action);
  }

  public getSelectedNodes() {
    return this._selectedNodes.getValue();
  }

  public getHighlightedNodes() {
    return this._highlightedNodes.getValue();
  }

  public selected(value: any) {
    this._selectedNodes.next(value);
  }

  public highlighted(value: any) {
    this._highlightedNodes.next(value);
  }

  public clear() {
    this._actionSubject.next(undefined);
    this._selectedNodes.next(undefined);
    this._tagList.next(undefined);
  }

  get tagList() {
    return this._tagList.getValue();
  }

  set tagList(tags: any[]) {
    tags.sort((a, b) => {
      if (a.tag < b.tag) {
        return -1;
      }
      if (a.tag > b.tag) {
        return 1;
      }
      return 0;
    });
    this._tagList.next(tags);
  }

  get associationList() {
    return this._associationList.getValue();
  }

  set associationList(associations: any[]) {
    this._associationList.next(associations);
  }

  get programList() {
    return this._programList.getValue();
  }

  set programList(programs: any[]) {
    this._programList.next(programs);
  }

  get portfolioList() {
    return this._portfolioList.getValue();
  }

  set portfolioList(portfolios: any[]) {
    this._portfolioList.next(portfolios);
  }

  get ciPortfolioList() {
    return this._ciPortfolioList.getValue();
  }

  set ciPortfolioList(ciPortfolios: any[]) {
    this._ciPortfolioList.next(ciPortfolios);
  }

  get projectList() {
    return this._projectList.getValue();
  }

  set projectList(projects: any[]) {
    this._projectList.next(projects);
  }

  public getTagId(tagName: string): string {
    const tags = this._tagList.getValue();
    if (tags && tags.length > 0) {
      const tag = tags.find((item) => item.tag === tagName);
      if (tag) {
        return tag.id;
      }
    }
    return undefined;
  }

  public getAssociationId(associationName: string): string {
    const associations = this._associationList.getValue();
    if (associations && associations.length > 0) {
      const tag = associations.find((item) => item.tag === associationName);
      if (tag) {
        return tag.id;
      }
    }
    return undefined;
  }

  public getMasterTag(tagTypes: any[], orgId?: string): TagType {
    if (tagTypes && tagTypes.length > 0) {
      // convert to TagType
      const response = {
        docTagType: tagTypes[0].docTagType
          ? tagTypes[0].docTagType
          : tagTypes[0].tagType,
        docTagItemId: tagTypes[0].docTagItemId
          ? tagTypes[0].docTagItemId
          : tagTypes[0].index,
      };
      if (tagTypes[0].systemDocumentType) {
        response['systemDocumentType'] = tagTypes[0].systemDocumentType;
      }
      return response;
    } else {
      return {
        docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION,
        docTagItemId: orgId,
      };
    }
  }
}
