import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { EventService } from '@shared/event.service';
import { Project } from '@project/shared/project.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProjectGroup } from '@shared/services/project-group.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';
import { take, catchError } from 'rxjs/operators';

@Component({
  selector: 'oes-project-dialog-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class ProjectPortfolioDialogComponent implements OnInit {
  // the below variables are filled via this._bsModalService.show(component, config)
  public selectedProjectIds: string[];
  public projectListItems: any[];

  public portfolioFormGroup: UntypedFormGroup;
  private projectGroup = new ProjectGroup({});
  private errorMessage = '';
  // DT-2175: comment out for now.
  // private regionErrorMessage = '';
  public config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false
  };

  constructor(public bsModalRef: BsModalRef,
              private _router: Router,
              private _eventService: EventService,
              private _projectGroupService: ProjectGroupService,
              private _translateService: TranslateService,
              private _userService: UserService) {
    this._translateService.get('project-list.action.portfolio.error').subscribe(message => {
      this.errorMessage = message['programs'];
      // DT-2175: comment out for now.
      // this.regionErrorMessage = message['regions'];
    });
  }

  ngOnInit() {
    this.validateProjects();
    this.createForm();
  }

  private createForm() {
    this.portfolioFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      portfolio: new UntypedFormControl('', null),
      // DT-3965 rfq: new FormControl('', null)
    });
  }

  private getFormValue() {
    this.projectGroup.name = this.portfolioFormGroup.controls['name'].value;
    // convert undefined/null
    this.projectGroup.financeDeal = this.portfolioFormGroup.controls['portfolio'].value ? true : false;
    // DT-3965 this.projectGroup.quote = this.portfolioFormGroup.controls['rfq'].value ? true : false;
  }

  public onSubmit() {
    this.projectGroup.projects = this.selectedProjectIds.map(id => {
      const project = new Project({});
      project.id = id;
      // Need to create a unique name so that the data is unique on the server side.  This name will not be used
      project.name = project.id;
      return project;
    });
    this.getFormValue();
    this._projectGroupService.createProjectGroup(this.projectGroup)
    .pipe(take(1), catchError((error: any) => {
      if (error?.error?.message?.includes('does not have a finalized Generation Design')) {
        const projectId = error.error.message.split(' ')[1];
        const projectName = this.projectListItems.find(project => project.id === projectId).name;
        this._eventService.error(
          this._translateService.instant('project-group.gen-design-error-1') + ': ' + projectName +
          '.  ' + this._translateService.instant('project-group.gen-design-error-2'));
      } else {
        this._eventService.error(error.error.message);
      }
      return [];
    }))
    .subscribe(projectGroup => {
      this.bsModalRef.hide();
      if (projectGroup.financeDeal && !projectGroup.quote) {
        if (this._userService.getSelectedRole() === ROLE_TYPE.DEVELOPER_USER) {
          this._router.navigate([`/oes/finance/portfolios`]);
        } else if (this._userService.getSelectedRole() === ROLE_TYPE.FINANCE_USER) {
          this._router.navigate([`/oes/finance/finance-marketplace`]);
        }
      } else if (!projectGroup.financeDeal && projectGroup.quote) {
        this._router.navigate([`/oes/procurement/my-quotes`]);
      }
    });
  }

  // create button enable/disable
  public isDisable(): boolean {
    if (this.portfolioFormGroup.invalid) {
      return true;
    }
    if (this.portfolioFormGroup.controls['portfolio']/* DT-3965 && this.portfolioFormGroup.controls['rfq'] */) {
      if (this.portfolioFormGroup.controls['portfolio'].value/* DT-3965 || this.portfolioFormGroup.controls['rfq'].value */) {
        return false;
      }
    }
    return true;
  }

  private validateProjects() {
    // You can't create a portfolio from projects which belong to a different program.
    const programs = this.projectListItems.filter(project => {
      return this.selectedProjectIds.indexOf(project.id) > -1;
    })
    .map(project => project.program);

    // remove duplicate, it should be one program
    const filteredPrograms = Array.from(new Set(programs));
    if (filteredPrograms.length > 1) {
      this._eventService.error(this.errorMessage);
      return false;
    }

    // You can't create a portfolio from projects which belong to a different state.
    // DT-2175: commented out.
    // Back-end changed, you need to create new logic.
    return true;
  }
}
