import {Injectable} from '@angular/core';
import { ProjectGroup } from '@shared/services/project-group.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

export enum PORTFOLIO_PAGE {
  PORTFOLIO_DESCRIPTION = 'portfolioDescription',
  DATA_ROOM = 'dataRoom'
}

@Injectable({
  providedIn: 'root'
})
export class FinanceService extends BaseRestApiService {
  private projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;
  private _pageValidation = {
    portfolioDescription: false,
    dataRoom: false
  };

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(params: HttpParams): Observable<any> {
    const options = { params: params };
    return this.get<any>(this.projectGroups, options, true);
  }

  public submittedListByProgram(programId: string): Observable<ProjectGroup[]> {
    return this.get<any>(`${this.projectGroups}/programs/${programId}`);
  }

  public getProjectGroup(projectGroupId: string): Observable<ProjectGroup> {
    return this.get<ProjectGroup>(`${this.projectGroups}/${projectGroupId}`)
            .pipe(
              map(result => new ProjectGroup(result))
            );
  }

  public saveProjectGroup(projectGroup: ProjectGroup): Observable<ProjectGroup> {
    return this.put<ProjectGroup>(`${this.projectGroups}/${projectGroup.id}`, JSON.stringify(projectGroup));
  }

  /**
   * Set portfolio page field validation status
   * @param pageName: PORTFOLIO_PAGE
   * @param status: boolean
   */
  public setPageStatus(pageName: PORTFOLIO_PAGE, status: boolean) {
    this._pageValidation[pageName] = status;
  }

  /**
   * Introduction page checks all necessary fields are filled on each pages before submitting
   */
  public getPageStatus(): {} {
    return this._pageValidation;
  }
}
