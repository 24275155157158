export class FinanceStatusUpdate {
  id = null;
  financeStatus: string;
  financeDealStatus: string;

  constructor(financeStatusUpdateInfo: any) {
    this.financeStatus = financeStatusUpdateInfo.financeStatus;
    this.financeDealStatus = financeStatusUpdateInfo.financeDealStatus;
  }
}
