import {BaseModel} from '@shared/base.model';
export class GenerationDesignCostSource extends BaseModel<GenerationDesignCostSource> {
  code: string;
  expenseType: string;
  name: number;

  constructor(GenerationDesignCostSourceInfo: any) {
    super();
    this.id = GenerationDesignCostSourceInfo.id;
    this.code = GenerationDesignCostSourceInfo.code;
    this.expenseType = GenerationDesignCostSourceInfo.expenseType;
    this.name = GenerationDesignCostSourceInfo.name;
  }
}
