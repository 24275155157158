import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef} from '@angular/core';
import { SliderItem } from './slider-item';

@Component({
  selector: 'oes-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() readOnly = false;
  @Input() rangeMin = 0;
  @Input() rangeMax = 100;
  @Input() rangeMiddle = 50;
  @Input() sliderItems: SliderItem[];
  @Input() showSlider = true;
  @Input() orientation = 'vertical';
  @Output() updated: EventEmitter<SliderItem[]> = new EventEmitter<SliderItem[]>();

  public config: any;
  public reload = false; // to apply new maxValue, force reloading the scaler

  constructor(private _changeDetect: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.setupConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.rangeMax && changes.rangeMax.currentValue) {
      if (this.config && this.config.range) {
        this.reload = true;
        this._changeDetect.detectChanges();
        this.config.range.max = changes.rangeMax.currentValue;
        this.reload = false;
      }
    }
  }

  private setupConfig() {
    this.config = {
      animate: true,
      animationDuration: 300,
      behaviour: 'drag tap',
      connect: [true, false],
      margin: 0,
      start: this.rangeMiddle,
      step: 1,
      range: {
        'min': this.rangeMin,
        'max': this.rangeMax
      }
    };
  }

  public trackById(index: number, value: number) {
    return index;
  }

  public inputPercent(value: string) {
    return value.replace(/[|&;$%@"<>()+,.]/g, '');
  }

  // index of updated slide item and new scale value
  public onSlide(sliderValue, target) {
    this.sliderItems[target].scale = sliderValue;
    // parent handle the value
    this.updated.emit(<SliderItem[]>this.sliderItems);
  }

  // index of disabled item
  public toggleItem(name: string, target: number) {
    this.sliderItems[target].isDisabled = this.sliderItems[target].isDisabled ? false : true;
    // eslint-disable-next-line no-self-assign
    this.sliderItems[target].scale = this.sliderItems[target].scale;
    // parent handle the value
    this.updated.emit(<SliderItem[]>this.sliderItems);
  }

  public checkOrientation(): any {
    if (this.orientation === 'vertical') {
      return {'flex-column': true};
    } else if (this.orientation === 'horizontal') {
      return {'flex-row': true};
    }
  }
}
