<ng-container *ngIf="readOnly; else DynamicForm">
  <oes-formio-simple-form
    [json]="json">
  </oes-formio-simple-form>
</ng-container>

<ng-template #DynamicForm>
  <oes-dynamic-form
    [json]="json"
    [readOnly]="isPastSubmissionDeadline"
    [simple]="simple"
    (save)="customFormUpdated($event)">
  </oes-dynamic-form>
</ng-template>
