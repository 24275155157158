import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ChartColors } from '../chart-colors.enum';
import { ChartDataset } from '../chart-dataset.model';
import { OesChart } from '../oes-chart.model';

@Component({
  selector: 'oes-pie-chart',
  templateUrl: 'pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() chart: OesChart;
  @Input() chartColors = Object.keys(ChartColors);

  pieChartData: ChartConfiguration<'pie'>['data'];
  pieChartLegend: boolean;
  pieChartPlugins = [];
  pieChartOptions: ChartConfiguration<'pie'>['options'];


  constructor() {
  }

  ngOnInit() {
    this.buildPieChart();
  }

  private buildPieChart() {
    const pieChartLegend = this.chart?.labels?.length > 0;
    const pieChartPlugins = [];
    const pieChartData: ChartConfiguration<'pie'>['data'] = {
      datasets: [{
        data: this.chart?.datasets[0]?.data,
        backgroundColor: this.chartColors,
        hoverBackgroundColor: this.chartColors,
        clip: 0
      }]
    };
    const pieChartOptions: ChartConfiguration<'pie'>['options'] = {
      plugins: {
        title: {
          display: false
        }
      },
      layout: {
        padding: 30
      },
      cutout: '70%',
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    };
    this.pieChartLegend = pieChartLegend;
    this.pieChartPlugins = pieChartPlugins;
    this.pieChartData = pieChartData;
    this.pieChartOptions = pieChartOptions;
  }



}
