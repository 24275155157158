import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-cell-dropdown-action',
  templateUrl: './cell-dropdown-action.component.html',
  styleUrls: ['./cell-dropdown-action.component.scss']
})
export class CellDropdownActionComponent implements ICellRendererAngularComp {
  displayActions = true;
  params;

  private masterGridApi;
  private masterRowIndex;
  private rowData: any;

  constructor() { }

  // ICellRendererAngularComp requires
  agInit(params: any): void {
    if (params.node.allChildrenCount) {
      this.displayActions = false;
    }
    this.params = params;
    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;
    this.rowData = params.data;
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  // call from button
  click(key: string) {
    switch (key) {
      case 'toggle-enabled':
      case 'toggle-disabled':
        this.params.context.gridService.cellButtonClicked({
          key: 'toggle',
          data: this.rowData
        });
        break;
      case 'share-with-program-disabled':
      case 'share-with-program-enabled':
        this.params.context.gridService.cellButtonClicked({
          key: 'toggle-sharing',
          data: this.rowData
        });
        break;
      default:
        this.params.context.gridService.cellButtonClicked({
          key: key,
          data: this.rowData
        });
        break;
    }
  }
}
