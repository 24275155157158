
import { environment } from '@environments/environment';

export class UserAdminApiConstants {
  public static userUri = environment.serverUri + `api/${environment.apiVersion}/system-admin/users`;

  public static list = () => {
    return UserAdminApiConstants.userUri;
  };
  public static detail = () => {
    return UserAdminApiConstants.userUri;
  };
  public static saveUpdate = (userId?: string) => {
    if (userId) {
      return `${UserAdminApiConstants.userUri}/${userId}`;
    } else {
      return `${UserAdminApiConstants.userUri}`;
    }
  };
  public static softDelete = (userId?: string) => {
    return `${UserAdminApiConstants.userUri}/${userId}/soft-delete`;
  };
  public static addRoles = (userId: string) => {
    return `${UserAdminApiConstants.userUri}/${userId}/add-roles`;
  };
  public static removeRole = (userId: string) => {
    return `${UserAdminApiConstants.userUri}/${userId}/remove-roles`;
  };
  public static resetPassword = (userId: string) => {
    return `${UserAdminApiConstants.userUri}/${userId}/reset-password`;
  };
}
