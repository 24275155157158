<form [formGroup]="formGroup">
  <input 
    autofocus
    formControlName="adminNotes"
    id="notes"
    autocomplete="off"
    list="TagListId">
    <datalist id="TagListId">
      <option *ngFor="let tag of tags" [value]="tag">
        {{tag}}
      </option>
    </datalist>
</form>
