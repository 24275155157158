import {Directive, HostListener, forwardRef} from '@angular/core';
import {NumberValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export const FIXED_NUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FixedNumberValueAccessorDirective),
  multi: true
};

/**
 * Note: this is a temporary solution.
 * Please track the bug status at https://github.com/angular/angular/issues/12540
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=number][formControl][fixedNumberField],input[type=number][formControlName][fixedNumberField],input[type=number][ngModel][fixedNumberField]',
  providers: [FIXED_NUMBER_VALUE_ACCESSOR],
})
export class FixedNumberValueAccessorDirective extends NumberValueAccessor {
  private lastValue: string;

  @HostListener('change', ['$event.target.value']) handleOnChange(value) {
    this.handleUpdate(value);
  }

  @HostListener('input', ['$event.target.value']) handleOnInput(value) {
     this.handleUpdate(value);
  }

  private handleUpdate(value: string) {
    if (value === this.lastValue) {
      return;
    }
    this.lastValue = value;
    this.onChange(value);
  }
}
