import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-cell-button',
  templateUrl: './cell-button.component.html',
  styleUrls: ['./cell-button.component.scss']
})
export class CellButtonComponent implements ICellRendererAngularComp {
  private masterGridApi;
  private masterRowIndex;
  private rowData: any;

  displayActions = true;
  params;

  constructor() { }

  // ICellRendererAngularComp requires
  agInit(params: any): void {
    if (params.node.allChildrenCount) {
      this.displayActions = false;
    }
    this.params = params;
    this.masterGridApi = params.api;
    this.masterRowIndex = params.rowIndex;
    this.rowData = params.data;
  }

  // ICellRendererAngularComp
  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  // call from button
  click(key: string) {
    this.params.context.gridService.cellButtonClicked({
      key: key,
      data: this.rowData
    });
  }

  // callback: input type="file"
  getFilePath(event) {
    this.params.context.gridService.cellButtonClicked({
      key: 'upload',
      data: this.rowData,
      file: event
    });
  }
}
