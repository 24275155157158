import { environment } from "../../../environments/environment";

export class InstallerApiConstants {
  public static installers = environment.serverUri + `api/${environment.apiVersion}/installers`;

  public static installer = {
    listAllByOrgId: (orgId: string) => {
      return `${InstallerApiConstants.installers}/list/${orgId}`;
    },
    create: () => {
      return `${InstallerApiConstants.installers}`;
    },
    update: (id: string) => {
      return `${InstallerApiConstants.installers}/${id}`;
    },
    delete: (id: string) => {
      return `${InstallerApiConstants.installers}/${id}`;
    }
  };

  public static projectInstaller = {
    create: (installerId: string, projectId: string) => {
      return `${InstallerApiConstants.installers}/project-installer/${installerId}/${projectId}`;
    }
  };
}
