import { Component, ViewChild } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from '@shared/components/files/shared/files.service';
import { ActionType } from '@shared/components/files/shared/action-type.enum';

@Component({
  selector: 'oes-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;
  deleteItems: string[] = [];
  private allLeafChildren: any[] = [];

  constructor(private _documentDataService: DocumentDataService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _filesService: FilesService) {
  }

  delete() {
    const selectedNodes = this._filesService.getSelectedNodes();
    if (selectedNodes && selectedNodes.length > 0) {
      const documents = selectedNodes.map(node => {
        return node.data ? node.data.documentKey : '';
      });
      this._documentDataService.deleteDocuments(documents)
      .subscribe((result) => {
        this._eventService.success(this._translateService.instant('data-room.dialogs.deleted'));
        this._filesService.clickActionButton(ActionType.completed);
        this.modalDialog.hide();
      });
    }
    this.allLeafChildren = [];
    this.deleteItems = [];
  }

  show() {
    const selectedNodes = this._filesService.getSelectedNodes();
    if (selectedNodes && selectedNodes.length > 0) {
      this.deleteItems = selectedNodes.map(node => {
        return node.data ? node.data.path : '';
      });
    }
    this.modalDialog.show();
  }

  close() {
    this.allLeafChildren = [];
    this.deleteItems = [];
  }
}
