<ng-container *ngIf="chart">
  <div class="bar-line-chart">
    <h4>{{ chart?.title }}</h4>
    <div class="bar-line-with-y d-flex">
      <canvas baseChart
              [data]="barLineChartData"
              [options]="barLineChartOptions"
              [plugins]="barLineChartPlugins"
              [legend]="barLineChartLegend"
              [height]="240"
              [type]="'scatter'">
      </canvas>
    </div>
  </div>
</ng-container>