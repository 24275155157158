import {BaseModel} from '@shared/base.model';
import {Organization} from '@shared/models/organization/organization.model';

export class FinanceConnection extends BaseModel<FinanceConnection> {
  acceptDateTime: string;
  archiveDateTime: string;
  coDevelopmentInterest: boolean;
  connectionCreateUser: any;
  debtInterest: boolean;
  declineDateTime: string;
  declineReason: string;
  declineReasonCode: string;
  endRelationshipDateTime: Date;
  equityInterest: boolean;
  financeDealStatus: string;
  financeOrganization: Organization;
  grantInterest: boolean;
  interestDateTime: Date;
  level1DateTime: string;
  level2DateTime: string;
  level3DateTime: string;
  level4DateTime: string;
  level5DateTime: string;
  mouSignedDateTime: Date;
  ndaSignedDateTime: Date;
  noteFromInvestor: string;
  otherInterest: boolean;
  otherInterestDescription: string;
  /**
   * @deprecated tenderBidDateTime
   */
  tenderBidDateTime: string;
  programBidDateTime: string;
  touSignedDateTime: Date;

  constructor(financeConnectionInfo: any) {
    super();
    this.id = financeConnectionInfo.id;
    this.acceptDateTime = financeConnectionInfo.acceptDateTime;
    this.archiveDateTime = financeConnectionInfo.archiveDateTime;
    this.coDevelopmentInterest = financeConnectionInfo.coDevelopmentInterest;
    this.connectionCreateUser = financeConnectionInfo.connectionCreateUser;
    this.debtInterest = financeConnectionInfo.debtInterest;
    this.declineDateTime = financeConnectionInfo.declineDateTime;
    this.declineReason = financeConnectionInfo.declineReason;
    this.declineReasonCode = financeConnectionInfo.declineReasonCode;
    this.endRelationshipDateTime = financeConnectionInfo.endRelationshipDateTime;
    this.equityInterest = financeConnectionInfo.equityInterest;
    this.financeOrganization = new Organization(financeConnectionInfo.financeOrganization || {});
    this.grantInterest = financeConnectionInfo.grantInterest;
    this.interestDateTime = financeConnectionInfo.interestDateTime;
    /**
     * @deprecated tenderBidDateTime
     * TODO remove || statement from below
     */
    this.tenderBidDateTime = financeConnectionInfo.programBidDateTime || financeConnectionInfo.tenderBidDateTime;
    this.programBidDateTime = financeConnectionInfo.programBidDateTime || financeConnectionInfo.tenderBidDateTime;
    this.level1DateTime = financeConnectionInfo.level1DateTime;
    this.level2DateTime = financeConnectionInfo.level2DateTime;
    this.level3DateTime = financeConnectionInfo.level3DateTime;
    this.level4DateTime = financeConnectionInfo.level4DateTime;
    this.level5DateTime = financeConnectionInfo.level5DateTime;
    this.mouSignedDateTime = financeConnectionInfo.mouSignedDateTime;
    this.ndaSignedDateTime = financeConnectionInfo.ndaSignedDateTime;
    this.noteFromInvestor = financeConnectionInfo.noteFromInvestor;
    this.otherInterest = financeConnectionInfo.otherInterest;
    this.otherInterestDescription = financeConnectionInfo.otherInterestDescription;
    this.touSignedDateTime = financeConnectionInfo.touSignedDateTime;
    this.financeDealStatus = financeConnectionInfo.financeDealStatus;
  }

  accepted(): boolean {
    return this.acceptDateTime && this.acceptDateTime !== '';
  }

  declined(): boolean {
    return this.declineDateTime && this.declineDateTime !== '';
  }

  ended(): boolean {
    return !!this.endRelationshipDateTime;
  }

  interested(): boolean {
    return !!this.interestDateTime;
  }

  levelIsActive(level: number) {
    return this[`level${level}DateTime`];
  }
}
