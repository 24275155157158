import { SpecificationFormRule } from './specification-form-rule';

export class CiSiteRule {
  public static rule(): SpecificationFormRule {
    return {
      fields: {
        postalCode: {
          display: true,
          required: false
        },
        address1: {
          display: true,
          required: false
        },
        address2: {
          display: true,
          required: false
        },
        name: {
          display: true,
          required: true,
          headingKey: 'site-name'
        },
        projectType: {
          display: true,
          required: true
        },
        description: {
          display: true,
          required: false
        },
        map: {
          display: true,
          required: false
        },
        country: {
          display: true,
          required: true
        },
        state: {
          display: true,
          required: true
        },
        city: {
          display: true,
          required: true
        },
        latitude: {
          display: true,
          required: true
        },
        longitude: {
          display: true,
          required: true
        },
        cod: {
          display: true,
          required: false,
          headingKey: 'operational-date'
        },
        tenderProject: {
          display: true,
          required: false
        },
        currency: {
          display: true,
          required: false,
          headingKey: 'ci-currency'
        },
        exchangeRate: {
          display: false,
          required: false
        },
        program: {
          display: true,
          required: false
        },
        status: {
          display: true,
          required: false
        }
      },
      keys: {
        header: 'site-location',
        info: 'basic-info',
        location: 'site-location',
        currency: 'currency'
      }
    };
  }
}
