!function (e, r) {
  "object" == typeof exports && "object" == typeof module ? module.exports = r() : "function" == typeof define && define.amd ? define([], r) : "object" == typeof exports ? exports.vanillaTextMask = r() : e.vanillaTextMask = r();
}(this, function () {
  return function (e) {
    function r(n) {
      if (t[n]) return t[n].exports;
      var o = t[n] = {
        exports: {},
        id: n,
        loaded: !1
      };
      return e[n].call(o.exports, o, o.exports, r), o.loaded = !0, o.exports;
    }
    var t = {};
    return r.m = e, r.c = t, r.p = "", r(0);
  }([function (e, r, t) {
    "use strict";

    function n(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    function o(e) {
      var r = e.inputElement,
        t = e.afterUpdate,
        n = (0, u.default)(e),
        o = function (e) {
          var r = e.target.value;
          n.update(r), "function" == typeof t && t(r);
        };
      return r.addEventListener("input", o), n.update(r.value), {
        textMaskInputElement: n,
        destroy: function () {
          r.removeEventListener("input", o);
        }
      };
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.conformToMask = void 0, r.maskInput = o;
    var i = t(2);
    Object.defineProperty(r, "conformToMask", {
      enumerable: !0,
      get: function () {
        return n(i).default;
      }
    });
    var a = t(5),
      u = n(a);
    r.default = o;
  }, function (e, r) {
    "use strict";

    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.placeholderChar = "_", r.strFunction = "function";
  }, function (e, r, t) {
    "use strict";

    function n() {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : l,
        r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : u,
        t = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
      if (!(0, i.isArray)(r)) {
        if (("undefined" == typeof r ? "undefined" : o(r)) !== a.strFunction) throw new Error("Text-mask:conformToMask; The mask property must be an array.");
        r = r(e, t), r = (0, i.processCaretTraps)(r).maskWithoutCaretTraps;
      }
      var n = t.guide,
        s = void 0 === n || n,
        f = t.previousConformedValue,
        d = void 0 === f ? l : f,
        c = t.placeholderChar,
        p = void 0 === c ? a.placeholderChar : c,
        v = t.placeholder,
        h = void 0 === v ? (0, i.convertMaskToPlaceholder)(r, p) : v,
        m = t.currentCaretPosition,
        y = t.keepCharPositions,
        g = s === !1 && void 0 !== d,
        b = e.length,
        C = d.length,
        k = h.length,
        x = r.length,
        P = b - C,
        T = P > 0,
        O = m + (T ? -P : 0),
        w = O + Math.abs(P);
      if (y === !0 && !T) {
        for (var M = l, S = O; S < w; S++) h[S] === p && (M += p);
        e = e.slice(0, O) + M + e.slice(O, b);
      }
      for (var _ = e.split(l).map(function (e, r) {
          return {
            char: e,
            isNew: r >= O && r < w
          };
        }), j = b - 1; j >= 0; j--) {
        var V = _[j].char;
        if (V !== p) {
          var E = j >= O && C === x;
          V === h[E ? j - P : j] && _.splice(j, 1);
        }
      }
      var A = l,
        N = !1;
      e: for (var F = 0; F < k; F++) {
        var R = h[F];
        if (R === p) {
          if (_.length > 0) for (; _.length > 0;) {
            var I = _.shift(),
              L = I.char,
              J = I.isNew;
            if (L === p && g !== !0) {
              A += p;
              continue e;
            }
            if (r[F].test(L)) {
              if (y === !0 && J !== !1 && d !== l && s !== !1 && T) {
                for (var W = _.length, q = null, U = 0; U < W; U++) {
                  var z = _[U];
                  if (z.char !== p && z.isNew === !1) break;
                  if (z.char === p) {
                    q = U;
                    break;
                  }
                }
                null !== q ? (A += L, _.splice(q, 1)) : F--;
              } else A += L;
              continue e;
            }
            N = !0;
          }
          g === !1 && (A += h.substr(F, k));
          break;
        }
        A += R;
      }
      if (g && T === !1) {
        for (var B = null, D = 0; D < A.length; D++) h[D] === p && (B = D);
        A = null !== B ? A.substr(0, B + 1) : l;
      }
      return {
        conformedValue: A,
        meta: {
          someCharsRejected: N
        }
      };
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    });
    var o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    };
    r.default = n;
    var i = t(3),
      a = t(1),
      u = [],
      l = "";
  }, function (e, r, t) {
    "use strict";

    function n() {
      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : f,
        r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : s.placeholderChar;
      if (!o(e)) throw new Error("Text-mask:convertMaskToPlaceholder; The mask property must be an array.");
      if (e.indexOf(r) !== -1) throw new Error("Placeholder character must not be used as part of the mask. Please specify a character that is not present in your mask as your placeholder character.\n\n" + ("The placeholder character that was received is: " + JSON.stringify(r) + "\n\n") + ("The mask that was received is: " + JSON.stringify(e)));
      return e.map(function (e) {
        return e instanceof RegExp ? r : e;
      }).join("");
    }
    function o(e) {
      return Array.isArray && Array.isArray(e) || e instanceof Array;
    }
    function i(e) {
      return "string" == typeof e || e instanceof String;
    }
    function a(e) {
      return "number" == typeof e && void 0 === e.length && !isNaN(e);
    }
    function u(e) {
      return "undefined" == typeof e || null === e;
    }
    function l(e) {
      for (var r = [], t = void 0; t = e.indexOf(d), t !== -1;) r.push(t), e.splice(t, 1);
      return {
        maskWithoutCaretTraps: e,
        indexes: r
      };
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.convertMaskToPlaceholder = n, r.isArray = o, r.isString = i, r.isNumber = a, r.isNil = u, r.processCaretTraps = l;
    var s = t(1),
      f = [],
      d = "[]";
  }, function (e, r) {
    "use strict";

    function t(e) {
      var r = e.previousConformedValue,
        t = void 0 === r ? o : r,
        i = e.previousPlaceholder,
        a = void 0 === i ? o : i,
        u = e.currentCaretPosition,
        l = void 0 === u ? 0 : u,
        s = e.conformedValue,
        f = e.rawValue,
        d = e.placeholderChar,
        c = e.placeholder,
        p = e.indexesOfPipedChars,
        v = void 0 === p ? n : p,
        h = e.caretTrapIndexes,
        m = void 0 === h ? n : h;
      if (0 === l || !f.length) return 0;
      var y = f.length,
        g = t.length,
        b = c.length,
        C = s.length,
        k = y - g,
        x = k > 0,
        P = 0 === g,
        T = k > 1 && !x && !P;
      if (T) return l;
      var O = x && (t === s || s === c),
        w = 0,
        M = void 0,
        S = void 0;
      if (O) w = l - k;else {
        var _ = s.toLowerCase(),
          j = f.toLowerCase(),
          V = j.substr(0, l).split(o),
          E = V.filter(function (e) {
            return _.indexOf(e) !== -1;
          });
        S = E[E.length - 1];
        var A = a.substr(0, E.length).split(o).filter(function (e) {
            return e !== d;
          }).length,
          N = c.substr(0, E.length).split(o).filter(function (e) {
            return e !== d;
          }).length,
          F = N !== A,
          R = void 0 !== a[E.length - 1] && void 0 !== c[E.length - 2] && a[E.length - 1] !== d && a[E.length - 1] !== c[E.length - 1] && a[E.length - 1] === c[E.length - 2];
        !x && (F || R) && A > 0 && c.indexOf(S) > -1 && void 0 !== f[l] && (M = !0, S = f[l]);
        for (var I = v.map(function (e) {
            return _[e];
          }), L = I.filter(function (e) {
            return e === S;
          }).length, J = E.filter(function (e) {
            return e === S;
          }).length, W = c.substr(0, c.indexOf(d)).split(o).filter(function (e, r) {
            return e === S && f[r] !== e;
          }).length, q = W + J + L + (M ? 1 : 0), U = 0, z = 0; z < C; z++) {
          var B = _[z];
          if (w = z + 1, B === S && U++, U >= q) break;
        }
      }
      if (x) {
        for (var D = w, G = w; G <= b; G++) if (c[G] === d && (D = G), c[G] === d || m.indexOf(G) !== -1 || G === b) return D;
      } else if (M) {
        for (var H = w - 1; H >= 0; H--) if (s[H] === S || m.indexOf(H) !== -1 || 0 === H) return H;
      } else for (var K = w; K >= 0; K--) if (c[K - 1] === d || m.indexOf(K) !== -1 || 0 === K) return K;
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    }), r.default = t;
    var n = [],
      o = "";
  }, function (e, r, t) {
    "use strict";

    function n(e) {
      return e && e.__esModule ? e : {
        default: e
      };
    }
    function o(e) {
      var r = {
        previousConformedValue: void 0,
        previousPlaceholder: void 0
      };
      return {
        state: r,
        update: function (t) {
          var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e,
            o = n.inputElement,
            s = n.mask,
            d = n.guide,
            m = n.pipe,
            g = n.placeholderChar,
            b = void 0 === g ? v.placeholderChar : g,
            C = n.keepCharPositions,
            k = void 0 !== C && C,
            x = n.showMask,
            P = void 0 !== x && x;
          if ("undefined" == typeof t && (t = o.value), t !== r.previousConformedValue) {
            ("undefined" == typeof s ? "undefined" : l(s)) === y && void 0 !== s.pipe && void 0 !== s.mask && (m = s.pipe, s = s.mask);
            var T = void 0,
              O = void 0;
            if (s instanceof Array && (T = (0, p.convertMaskToPlaceholder)(s, b)), s !== !1) {
              var w = a(t),
                M = o.selectionEnd,
                S = r.previousConformedValue,
                _ = r.previousPlaceholder,
                j = void 0;
              if (("undefined" == typeof s ? "undefined" : l(s)) === v.strFunction) {
                if (O = s(w, {
                  currentCaretPosition: M,
                  previousConformedValue: S,
                  placeholderChar: b
                }), O === !1) return;
                var V = (0, p.processCaretTraps)(O),
                  E = V.maskWithoutCaretTraps,
                  A = V.indexes;
                O = E, j = A, T = (0, p.convertMaskToPlaceholder)(O, b);
              } else O = s;
              var N = {
                  previousConformedValue: S,
                  guide: d,
                  placeholderChar: b,
                  pipe: m,
                  placeholder: T,
                  currentCaretPosition: M,
                  keepCharPositions: k
                },
                F = (0, c.default)(w, O, N),
                R = F.conformedValue,
                I = ("undefined" == typeof m ? "undefined" : l(m)) === v.strFunction,
                L = {};
              I && (L = m(R, u({
                rawValue: w
              }, N)), L === !1 ? L = {
                value: S,
                rejected: !0
              } : (0, p.isString)(L) && (L = {
                value: L
              }));
              var J = I ? L.value : R,
                W = (0, f.default)({
                  previousConformedValue: S,
                  previousPlaceholder: _,
                  conformedValue: J,
                  placeholder: T,
                  rawValue: w,
                  currentCaretPosition: M,
                  placeholderChar: b,
                  indexesOfPipedChars: L.indexesOfPipedChars,
                  caretTrapIndexes: j
                }),
                q = J === T && 0 === W,
                U = P ? T : h,
                z = q ? U : J;
              r.previousConformedValue = z, r.previousPlaceholder = T, o.value !== z && (w.includes("e-") ? o.value = w : o.value = z, i(o, W, e.shadowRoot));
            }
          }
        }
      };
    }
    function i(e, r, t) {
      var n = t ? t.activeElement : document.activeElement;
      n === e && (g ? b(function () {
        return e.setSelectionRange(r, r, m);
      }, 0) : e.setSelectionRange(r, r, m));
    }
    function a(e) {
      if ((0, p.isString)(e)) return e;
      if ((0, p.isNumber)(e)) return String(e);
      if (void 0 === e || null === e) return h;
      throw new Error("The 'value' provided to Text Mask needs to be a string or a number. The value received was:\n\n " + JSON.stringify(e));
    }
    Object.defineProperty(r, "__esModule", {
      value: !0
    });
    var u = Object.assign || function (e) {
        for (var r = 1; r < arguments.length; r++) {
          var t = arguments[r];
          for (var n in t) Object.prototype.hasOwnProperty.call(t, n) && (e[n] = t[n]);
        }
        return e;
      },
      l = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      };
    r.default = o;
    var s = t(4),
      f = n(s),
      d = t(2),
      c = n(d),
      p = t(3),
      v = t(1),
      h = "",
      m = "none",
      y = "object",
      g = "undefined" != typeof navigator && /Android/i.test(navigator.userAgent),
      b = "undefined" != typeof requestAnimationFrame ? requestAnimationFrame : setTimeout;
  }]);
});