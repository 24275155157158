import {BaseModel} from '@shared/base.model';
import {SystemLoadScale} from './system-load-scale.model';

export class SystemLoad extends BaseModel<SystemLoad> {
  name: string;
  weekdayAverageLoad = 0;
  weekendAverageLoad = 0;
  dailyAverageLoad = 0;
  householdsConnected = 0;
  peakKw = 0;
  peakHour = -1;
  hourlyVariability = 0;
  dailyVariability = 0;
  anchorLoadDescription: string;
  productiveLoadDescription: string;
  systemLoadParameters: SystemLoadScale;

  constructor(systemLoadInfo: any) {
    super();
    this.id = systemLoadInfo.id;
    this.name = systemLoadInfo.name;
    if (systemLoadInfo.peakKw == null) {
      systemLoadInfo.peakKw = 0;
    }
    if (systemLoadInfo.weekdayAverageLoad == null) {
      systemLoadInfo.weekdayAverageLoad = 0;
    }
    this.hourlyVariability = systemLoadInfo.hourlyVariability;
    this.dailyVariability = systemLoadInfo.dailyVariability;
    this.anchorLoadDescription = systemLoadInfo.anchorLoadDescription;
    this.productiveLoadDescription = systemLoadInfo.productiveLoadDescription;
    this.weekdayAverageLoad = systemLoadInfo.weekdayAverageLoad;
    this.weekendAverageLoad = systemLoadInfo.weekendAverageLoad;
    this.dailyAverageLoad = systemLoadInfo.dailyAverageLoad;
    this.peakKw = Number(systemLoadInfo.peakKw);
    this.peakHour = systemLoadInfo.peakHour;
    this.householdsConnected = systemLoadInfo.householdsConnected;
    // if (systemLoadInfo.systemLoadParameters) {
    //   this.systemLoadParameters = systemLoadInfo.systemLoadParameters;
    // }
    this.systemLoadParameters = new SystemLoadScale(systemLoadInfo.systemLoadParameters || {});
  }
}
