import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { GridState } from '@shared/ag-grid/gird-state';
import { Subject } from 'rxjs';

import { GridStateComparatorService } from '../grid-state-comparator.service';
import { GridSubAction } from './sub-action.enum';

@Component({
  selector: 'oes-grid-sub-action',
  templateUrl: './sub-action.component.html',
  styleUrls: ['./sub-action.component.scss'],
})
export class GridSubActionComponent implements OnDestroy, OnChanges {
  @Input() clearFilter = true;
  @Input() defaultGridState: GridState;
  @Input() exportAll = false;
  @Input() exportList = true;
  @Input() showColumnsButton = true;
  @Input() gridSettingService: any;
  @Input() gridState: GridState;
  @Input() mode: string = 'icon';
  @Input() reload = true;
  @Input() resetFilter = false; // when the list has a default filter
  @Output() action: EventEmitter<GridSubAction> =
    new EventEmitter<GridSubAction>();

  columnState = false;
  filterState = false;
  sortState = false;
  subAction = GridSubAction;

  private agSideButton: Element;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private _gridStateComparatorService: GridStateComparatorService,
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.gridState?.currentValue) {
      this.updateState(changes.gridState.currentValue);
    } else if (changes?.defaultGridState?.currentValue
              && this.showColumnsButton
              && !this.agSideButton) {
      // AG-Grid is now ready
      this.moveColumnsButton();
    } else if (this.agSideButton
              && changes?.showColumnsButton?.currentValue === false
              && changes?.showColumnsButton?.previousValue === true) {
      this.agSideButton.remove();
      this.agSideButton = undefined;
    }
  }

  moveColumnsButton(): void {
    this.agSideButton = document.querySelector('.ag-side-button');

    if (this.agSideButton) {
      const subActionContainer = this._el.nativeElement.querySelector('.sub-action');
      this._renderer.appendChild(subActionContainer, this.agSideButton);
    }
  }

  private updateState(gridState: GridState) {
    this.filterState = Object.keys(gridState.filter)?.length > 0;
    this.columnState = this._gridStateComparatorService.compareColumn(
      gridState.column,
      this.defaultGridState.column
    );
    this.sortState = this.gridState?.column?.find(
      (column) => column.sort !== null
    )
      ? true
      : false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  emitAction(action: GridSubAction) {
    this.action.emit(action);
  }
}
