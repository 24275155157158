import {BaseModel} from '@shared/base.model';
import {Organization} from '../../shared/models/organization/organization.model';
import { Project } from '@project/shared/project.model';
import { ProgramProjectStageRules } from '@program/program-detail/program-configuration/program-stages/program-project-stage-rules.model';

export class ProjectConnection extends BaseModel<ProjectConnection> {
  administrator = false;
  projectInfo: Project; // project.id in project-sharing-list-grid
                        // projectInfo.organization.id in organization-sharing.component
  projectStage: string;
  projectStages: ProgramProjectStageRules[];
  recipient: Organization;  // recipient.id and .name in project-sharing-list-grid
  revokeAll: boolean;
  shareCostModelData: boolean;
  shareCustomerData: boolean;
  shareDistributionDesignData: boolean;
  shareFinancialModelData: boolean;
  shareGenerationDesignData: boolean;
  shareProjectFiles: boolean;
  shareProjectSpecificationData: boolean;
  shareProjectSummaryData: boolean;
  shareProjectOverviewData: boolean;
  shareRevenueModelData: boolean;
  shareSystemLoadData: boolean;
  shareOperatingData: boolean;  // API data integration
  shareDatasetData: boolean;  // File data integration
  shareAnalyticsData: boolean;
  shareMilestoneData: boolean;
  integrationPermissions: string; // used in OrganizationPermissionComponent
  datasetPermissions: string; // used in OrganizationPermissionComponent
  mostRecentMessageDate: Date;
  primaryConnection: boolean;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.administrator = data.administrator;
    this.projectInfo = new Project(data.projectInfo || {});
    this.projectStage = data.projectStage;
    this.projectStages = data.projectStages;
    this.recipient = new Organization(data.recipient || {});
    this.revokeAll = data.revokeAll;
    this.shareCostModelData = data.shareCostModelData;
    this.shareCustomerData = data.shareCustomerData;
    this.shareDistributionDesignData = data.shareDistributionDesignData;
    this.shareFinancialModelData = data.shareFinancialModelData;
    this.shareGenerationDesignData = data.shareGenerationDesignData;
    this.shareProjectFiles = data.shareProjectFiles;
    this.shareProjectSpecificationData = data.shareProjectSpecificationData;
    this.shareProjectSummaryData = data.shareProjectSummaryData;
    this.shareProjectOverviewData = data.shareProjectOverviewData;
    this.shareRevenueModelData = data.shareRevenueModelData;
    this.shareSystemLoadData = data.shareSystemLoadData;
    this.shareOperatingData = data.shareOperatingData;
    this.shareDatasetData = data.shareDatasetData;
    this.integrationPermissions = data.integrationPermissions;
    this.datasetPermissions = data.datasetPermissions;
    this.shareAnalyticsData = data.shareAnalyticsData;
    this.shareMilestoneData = data.shareMilestoneData;
    this.mostRecentMessageDate = data.mostRecentMessageDate;
    this.primaryConnection = data.primaryConnection;
  }

  get isReadOnly() {
    return  this.projectInfo !== null &&
            this.recipient !== null &&
            this.projectInfo.organization.id !== this.recipient.id;
  }

  get link() {
    return `/oes/projects/my-projects/${this.projectInfo.id}/sharing/${this.recipient.id}`;
  }
}
