<oes-modal-dialog
  [title]="'buttons.delete' | translate"
  [submitButtonText]="'buttons.delete' | translate"
  [destructive]="true"
  (submit)="delete()"
  (close)="close()">
  <!-- the below goes to dialog-body -->
  <div>
    {{'data-room.delete-modal.message' | translate}}
  </div>
  <ul>
    <li *ngFor="let item of deleteItems">{{item}}</li>
  </ul>
</oes-modal-dialog>
