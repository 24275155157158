<ng-container *ngIf="displayType === 'all'">

  <div class="overview-section">
    <h4>{{ 'project.ci-financial-model.project-summary' | translate }}</h4>
    <div class="ci-kpis">
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.solar-system-size' | translate }}</p>
        <h5>{{ (projectFinancialModel?.solarSystemSize | number : '1.0-2' ) || '--' }}<span class="units">{{ 'units.kWp' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.battery-system-size' | translate }}</p>
        <h5>{{ (projectFinancialModel?.batterySystemSize | number : '1.0-2' ) || '--' }}<span class="units">{{ 'units.kWh' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.annual-solar-yield' | translate }}</p>
        <h5>{{ (projectFinancialModel?.annualSolarYield | number : '1.0-2' ) || '--' }}<span class="units">{{ 'units.kWh' | translate }}/{{ 'units.kWp' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.forecasted-yield' | translate }}</p>
        <h5>{{ (projectFinancialModel?.forecastedEnergyYieldY1 | number : '1.0-2') || '--' }}<span class="units">{{ 'units.kWh' | translate }}</span></h5>
      </div>
    </div>
  </div>

  <div class="overview-section">
    <h4>{{ 'project.ci-financial-model.system-costs' | translate }}</h4>
    <div class="ci-kpis">
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.total-capex' | translate }}</p>
        <h5>{{ projectFinancialModel?.adjustedCapex && projectFinancialModel?.adjustedCapex > 0 ? (projectFinancialModel?.adjustedCapex | currency: project?.alternateCurrency : 'symbol' : '1.2-2' ) : '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.solar-cost' | translate }}</p>
        <h5>{{ (projectFinancialModel?.solarCost | currency: project.alternateCurrency : 'symbol' : '1.2-2' ) || '--' }}<span class="units">/{{ 'units.Wp' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.battery-cost' | translate }}</p>
        <h5>{{ (projectFinancialModel?.batteryCost | currency: project.alternateCurrency : 'symbol' : '1.2-2' ) || '--' }}<span class="units">/{{ 'units.kWh' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.lcoe' | translate }}</p>
        <h5>{{ projectFinancialModel?.lcoe && projectFinancialModel?.lcoe > 0 ? (projectFinancialModel?.lcoe | currency: project.alternateCurrency : 'symbol' : '1.2-2' ) : '--' }}<span class="units">/{{ 'units.kWh' | translate }}</span></h5>
      </div>
    </div>
  </div>

  <div class="overview-section">
    <h4>{{ 'project.ci-financial-model.key-financials' | translate }}</h4>
    <div class="ci-kpis">
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.type' | translate }}</p>
        <h5>{{ projectFinancialModel?.dealType ? ('project.ci-financial-model.deal-type.' + projectFinancialModel?.dealType | translate) : '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.irr' | translate }}</p>
        <h5>{{ projectFinancialModel?.irr !== null && projectFinancialModel?.irr !== -10000 ? (projectFinancialModel?.irr | percent : '1.0-1') : '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.breakeven' | translate }}</p>
        <h5>{{ projectFinancialModel?.payback !== null && projectFinancialModel?.payback !== -10000 ? (projectFinancialModel?.payback | number : '1.0-1') : '--' }}<span class="units">{{ 'units.years' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.annual-co2' | translate }}</p>
        <h5>{{ (projectFinancialModel?.emissionsOffset | number: '1.0-2' ) || '--' }}<span class="units">{{ 'units.tons' | translate }}</span></h5>
      </div>
    </div>
    <div class="ci-kpis ci-kpis-mt">
      <div class="ci-kpi kpi-33 emphasize">
        <p>{{ 'project.ci-financial-model.grid-costs-offset' | translate }}</p>
        <h5>{{ (projectFinancialModel?.gridCostsOffset | percent: '1.0-2' ) || '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-33 emphasize">
        <p>{{ 'project.ci-financial-model.annual-saving' | translate }}</p>
        <h5>{{ (projectFinancialModel?.annualSaving | currency: project.alternateCurrency : 'symbol' : '1.2-2' ) || '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-33 emphasize">
        <p>{{ 'project.ci-financial-model.lifetime-profit' | translate }}</p>
        <h5>{{ (projectFinancialModel?.lifetimeProfit | currency: project.alternateCurrency : 'symbol' : '1.2-2' ) || '--' }}</h5>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="displayType === 'overview'">
  <div class="overview-section">

    <h4>{{ 'project.ci-financial-model.deal-information' | translate }}</h4>
    <button class="btn btn-grey btn-med" [routerLink]="['../planning/summary']">
      {{ 'project.ci-financial-model.view-design' | translate }}
    </button>

    <div class="ci-kpis">
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.type' | translate }}</p>
        <h5>{{ projectFinancialModel?.dealType ? ('project.ci-financial-model.deal-type.' + projectFinancialModel?.dealType | translate) : '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.irr' | translate }}</p>
        <h5>{{ projectFinancialModel?.irr !== null && projectFinancialModel?.irr !== -10000 ? (projectFinancialModel?.irr | percent : '1.0-1') : '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.breakeven' | translate }}</p>
        <h5>{{ projectFinancialModel?.payback !== null && projectFinancialModel?.payback !== -10000 ? (projectFinancialModel?.payback | number : '1.0-1') : '--' }}<span class="units">{{ 'units.years' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.annual-co2' | translate }}</p>
        <h5>{{ (projectFinancialModel?.emissionsOffset | number: '1.0-2' ) || '--' }}<span class="units">{{ 'units.tons' | translate }}</span></h5>
      </div>
    </div>
    <div class="ci-kpis ci-kpis-mt">
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.forecasted-yield' | translate }}</p>
        <h5>{{ (projectFinancialModel?.forecastedEnergyYieldY1 | number : '1.0-2') || '--' }}<span class="units">{{ 'units.kWh' | translate }}</span></h5>
      </div>
      <div class="ci-kpi kpi-25">
        <p>{{ 'project.ci-financial-model.total-capex' | translate }}</p>
        <h5>{{ projectFinancialModel?.adjustedCapex && projectFinancialModel?.adjustedCapex > 0 ? (projectFinancialModel?.adjustedCapex | currency: project?.alternateCurrency : 'symbol' : '1.2-2' ) : '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25 emphasize">
        <p>{{ 'project.ci-financial-model.grid-costs-offset' | translate }}</p>
        <h5>{{ (projectFinancialModel?.gridCostsOffset | percent: '1.0-2' ) || '--' }}</h5>
      </div>
      <div class="ci-kpi kpi-25 emphasize">
        <p>{{ 'project.ci-financial-model.lifetime-profit' | translate }}</p>
        <h5>{{ (projectFinancialModel?.lifetimeProfit | currency: project.alternateCurrency : 'symbol' : '1.2-2' ) || '--' }}</h5>
      </div>
    </div>
  </div>
</ng-container>
