import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgramClaimsKPIService } from '@program/program-detail/program-claims/program-claims-kpi.service';
import { ProgramProjectStageRules } from '@program/program-detail/program-configuration/program-stages/program-project-stage-rules.model';
import { Evaluation } from '@program/shared/evaluation/evaluation.model';
import { EvaluationService } from '@program/shared/evaluation/evaluation.service';
import { Program } from '@program/shared/program.model';
import { ProjectConnection } from '@project/shared/project-connection.model';
import { ProjectConnectionService } from '@project/shared/project-connection.service';
import { ProjectService } from '@project/shared/project.service';
import { SelectedProjectsService } from '@project/shared/selected-projects.service';
import { SimpleProject } from '@project/shared/simple-project.model';
import { CellAuditScoreService } from '@shared/ag-grid/component/cell-audit-score/cell-audit-score.service';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { GridState } from '@shared/ag-grid/gird-state';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { View } from '@shared/components/view-toggle-button/view.enum';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';
import { UtilityService } from '@shared/services/utility.service';
import { ROLE_TYPE } from '@user/role-type';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { forkJoin, Subject } from 'rxjs';
import { flatMap, take, takeUntil } from 'rxjs/operators';

import { ProjectListType } from './project-list-type.enum';
import { SharedProjectListGridSettingService } from './shared-project-list-grid.service';
import { Project } from '@project/shared/project.model';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-shared-projects-list',
  templateUrl: './shared-projects-list.component.html',
  styleUrls: ['./shared-projects-list.component.scss']
})
export class SharedProjectsListComponent implements OnInit, OnDestroy {
  @Input() program: Program;
  @Input() listType: ProjectListType = ProjectListType.MAIN;
  @ViewChild('changeStageSingle', {static: false}) changeStageSingleDialog: ModalDialogComponent;
  @ViewChild('changeStageBulk', {static: false}) changeStageBulkDialog: ModalDialogComponent;

  columnApi: ColumnApi;
  currentView: View = View.LIST;
  defaultGridState: GridState;
  evaluations: Evaluation[];
  gridApi: GridApi;
  gridOptions = <GridOptions>{};
  gridState: GridState;
  gridStateStorageKey: string;
  isOrgAdmin: boolean = false;
  mapProjects: SimpleProject[];
  params: HttpParams = new HttpParams();
  programProjectStages: any;
  projectConnection: ProjectConnection;
  projectListType = ProjectListType;
  projects = [];
  selectedProjects = [];
  view = View;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _activeRoute: ActivatedRoute,
              private _cellAuditScoreService: CellAuditScoreService,
              private _dynamicHomeService: DynamicHomeService,
              private _evaluationService: EvaluationService,
              private _eventService: EventService,
              private _programClaimsKpiService: ProgramClaimsKPIService,
              private _projectConnectionService: ProjectConnectionService,
              private _projectService: ProjectService,
              private _selectedProjectsService: SelectedProjectsService,
              private _translateService: TranslateService,
              private _userService: UserService,
              private _utilityService: UtilityService,
              public _sharedProjectListGridSettingService: SharedProjectListGridSettingService) {
  }

  ngOnInit() {
    this.isOrgAdmin = this._userService.hasRole(ROLE_TYPE.ORGANIZATION_ADMIN);
    this.gridOptions = this._sharedProjectListGridSettingService.getGridOptions(this.listType);
    this.gridInit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  // ag-grid is ready
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._sharedProjectListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._sharedProjectListGridSettingService.buildDefaultGridState();
    this.getSharedProjects();
  }

  installSampleProject() {
    this._projectService.createSampleProject()
    .pipe(take(1))
    .subscribe((project: Project) => {
      this.getSharedProjects();
      this._eventService.success(this._translateService.instant('success-message.sample-project-created'));
    });
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._sharedProjectListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  // ag-grid callback: checkbox
  selectionChanged(event) {
    const selectedProjects = event.api.getSelectedNodes().map(item => item.data);
    this._selectedProjectsService.projects = selectedProjects;
    this.selectedProjects = selectedProjects;
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList: {
        const processCellCallback = (params: any) => {
          if (params?.column?.userProvidedColDef?.field === 'evaluations' && params.value) {
            const averages = this._cellAuditScoreService.renderEvaluations(params.value);
            let result = '';
            if (averages.passFail) {
              result += averages.passFail;
            }
            if (averages.averageTotal) {
              result += ' ' + averages.averageTotal.toString();
            }
            return result;
          }
          if (params?.column?.userProvidedColDef?.field === 'projectStages' && params.value) {
            const statuses: ProgramProjectStageRules[] = JSON.parse(params.value);
            const ordered = statuses.filter(status => status.timestamp !== null).sort((a, b) => {
              const aTime = a.timestamp;
              const bTime = b.timestamp;
              if (aTime && bTime) {
                let winner;
                while(winner === undefined) {
                  for (let i = 0; i < 7; i++) {
                    if (aTime[i] > bTime[i]) {
                      winner = 1;
                      break;
                    }
                    if (bTime[i] > aTime[i]) {
                      winner = -1;
                      break;
                    }
                  }
                }
                return winner;
              }
            });
            return ordered?.length > 0 ? ordered[ordered.length - 1].name : '';
          }
          return params.value;
        };
        this._sharedProjectListGridSettingService.exportCsv(this.gridApi, 'SharedProjects', true, processCellCallback);
        break;
      }
      case GridSubAction.reload:
        this.getSharedProjects();
        break;
      case GridSubAction.clearFilter:
        this._sharedProjectListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  // ag-grid callback
  onCellClicked(event: any) {
    if (event?.column?.colId === 'projectStages' && event?.data?.id !== '') {
      this.getConnection(event.data.id);
    }
  }

  reload() {
    this.projectConnection = undefined;
    this._projectConnectionService.projectConnection = undefined;
    this.getSharedProjects();
    this.selectedProjects = [];
  }

  openChangeStageBulkModal() {
    this.programProjectStages = this.selectedProjects[0].projectStages;
    this.changeStageBulkDialog.show();
  }

  private getSharedProjects() {
    if (this.listType !== ProjectListType.MAIN && this.program?.id) {
      const requests = [this._projectService.sharedListByProgram(this.program?.id),
                        this._projectService.sharedMilestonesByProgram(this.program?.id)];
      if (this.listType === ProjectListType.ASSETS) {
        if (this.isOrgAdmin) {
          requests.push(this._evaluationService.adminListByConfiguredEntityId(this.program.projectEvalConfigId));
        } else {
          requests.push(this._evaluationService.listByConfiguredEntityId(this.program.projectEvalConfigId));
        }
      }
      forkJoin(requests).subscribe(responses => {
        const projects: SimpleProject[] = responses[0];
        const milestonesMap: any = responses[1];
        this.currentView = View.LIST;
        let mappedProjects = this.addMilestoneMap(projects, milestonesMap);
        if (this.listType === ProjectListType.ASSETS) {
          this.evaluations = responses[2];
          this.addEvaluations(mappedProjects, this.evaluations);
        }
        if (this.listType === ProjectListType.CLAIMS) {
          mappedProjects = mappedProjects.filter(mappedProject => {
            const statuses = JSON.parse(mappedProject.projectStages);
            const orderedStatuses = this._utilityService.orderJSONStatusesByDate(statuses);
            const currentStatus = orderedStatuses && orderedStatuses[orderedStatuses.length - 1]?.timestamp ? orderedStatuses[orderedStatuses.length - 1] : null;
            return currentStatus?.showInClaims;
          });
          this._programClaimsKpiService.buildClaimsKPIs(mappedProjects);
        }

        this.setAgGrid(mappedProjects);
        this.mapProjects = undefined;
      });
    } else {
      const requests = [this._projectService.sharedList(),
                        this._projectService.sharedProjectMilestoneMap()];
      forkJoin(requests).subscribe(responses => {
        const projects: SimpleProject[] = responses[0];
        const milestonesMap: any = responses[1];
        if (this.currentView === View.LIST) {
          let combinedProjects = this.addMilestoneMap(projects, milestonesMap);
          this.setAgGrid(combinedProjects);
          combinedProjects = undefined;
          this.mapProjects = undefined;
        } else if (this.currentView === View.MAP) {
          this.mapProjects = projects;
        }
        this._dynamicHomeService.saveUrl();
      });
    }
  }

  private getConnection(projectId: string) {
    this._userService.getCurrentUser()
    .pipe(
      flatMap((user: User) => {
        return this._projectConnectionService.getProjectConnection(projectId, user.organization.id, 'true');
      }),
      take(1)
    )
    .subscribe((response: ProjectConnection) => {
      this.projectConnection = response;
      // for isSharedOrganization of create.component
      this._projectConnectionService.projectConnection = this.projectConnection;
      this.changeStageSingleDialog.show();
    });
  }

  private gridInit() {
    this.gridStateStorageKey = this._sharedProjectListGridSettingService.buildGridStateStorageKey(`sharedProjects-${this.listType}-${this.program?.id}`);
    this.checkQueryParams();
  }

  private checkQueryParams() {
    this._activeRoute.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((params: any) => {
      if (params && params.type) {
        this.currentView = params.type;
        if (this.currentView === View.MAP) {
          this.getSharedProjects();
        }
      }
    });
  }

  private addEvaluations(projects: SimpleProject[], evaluations: Evaluation[]) {
    projects.forEach(project => {
      project.evaluations = [];
      evaluations.forEach(evaluation => {
        if (evaluation.evaluatedEntityId === project.id) {
          project.evaluations.push(evaluation);
        }
      });
    });
  }

  private addMilestoneMap(projects: SimpleProject[], milestonesMap: any): SimpleProject[] {
    return projects.map(project => {
      if (milestonesMap[project.id]) {
        project.milestones = milestonesMap[project.id];
      }
      return project;
    });
  }

  private setAgGrid(projects: any[]) {
    if (!this.evaluations?.length) {
      this.removeColumn('evaluations');
    }
    if (projects?.length > 0) {
      this.gridApi.showLoadingOverlay();
      this.projects = projects;
      this.gridApi.setRowData(this.projects);
      this._sharedProjectListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);  // Must be applied after setRowData
      this.gridApi.hideOverlay();

    } else {
      // ag-grid No date overlay
      this.gridApi.showNoRowsOverlay();
    }
  }

  private removeColumn(colId: string) {
    const columnState = this.columnApi.getColumnState();
    const newColumnState = columnState.map(col =>
      col.colId === colId ? { ...col, hide: true } : col);
    this.columnApi.applyColumnState({ state: newColumnState, applyOrder: true });
  }
}
