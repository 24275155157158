<ng-container [formGroup]="otherActivitiesFormGroup">
  <div class="other-activities">
    <p class="text-xs color-font-tertiary mb-2">{{'deal-description.activities.header' | translate}}</p>
    <div class="d-flex">
      <input
        class="style-overhaul-flag"
        [ngClass]="{'disabled': isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.equipmentLeasing?.value}"
        type="checkbox"
        formControlName="equipmentLeasing"
        id="equipmentLeasing">
      <label class="input-control input-control--checkbox mb-3" for="equipmentLeasing">
        {{'deal-description.activities.appliance-label' | translate}}
      </label>
    </div>
    <div class="activities-textarea-wrap mb-4">
      <oes-quill
        class="style-overhaul-flag"
        formControlName="equipmentLeasingDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.equipmentLeasing?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.equipmentLeasingDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>

    <div class="d-flex">
      <input
        class="style-overhaul-flag"
        [ngClass]="{'disabled': isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.waterSanitation?.value}"
        type="checkbox"
        formControlName="waterSanitation"
        id="waterSanitation">
      <label class="input-control input-control--checkbox mb-3" for="waterSanitation">
        {{'deal-description.activities.water-label' | translate}}
      </label>
    </div>
    <div class="activities-textarea-wrap mb-4">
      <oes-quill
        class="style-overhaul-flag"
        formControlName="waterSanitationDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.waterSanitation?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.waterSanitationDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>

    <div class="d-flex">
      <input
        class="style-overhaul-flag"
        [ngClass]="{'disabled': isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.smallBusinessFinancing?.value}"
        type="checkbox"
        formControlName="smallBusinessFinancing"
        id="smallBusinessFinancing">
      <label class="input-control input-control--checkbox mb-3" for="smallBusinessFinancing">
        {{'deal-description.activities.business-label' | translate}}
      </label>
    </div>
    <div class="activities-textarea-wrap mb-4">
      <oes-quill
        class="style-overhaul-flag"
        formControlName="smallBusinessFinancingDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.smallBusinessFinancing?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.smallBusinessFinancingDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>

    <div class="d-flex">
      <input
        class="style-overhaul-flag"
        [ngClass]="{'disabled': isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.otherOpportunities?.value}"
        type="checkbox"
        formControlName="otherOpportunities"
        id="otherOpportunities">
      <label class="input-control input-control--checkbox mb-3" for="otherOpportunities">
        {{'deal-description.activities.other-label' | translate}}
      </label>
    </div>
    <div class="activities-textarea-wrap">
      <oes-quill
        class="style-overhaul-flag"
        formControlName="otherOpportunitiesDescription"
        [editorDisabled]="isPastSubmissionDeadline || !otherActivitiesFormGroup?.controls?.otherOpportunities?.value"
        [characterLimit]="1000"
        [initialValue]="financeBusinessPlan?.otherOpportunitiesDescription"
        [placeholder]="'deal-description.activities.description' | translate">
      </oes-quill>
    </div>
  </div>
</ng-container>
