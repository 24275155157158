import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavItem } from '@main-navigation/shared/navi-item/nav-item.model';
import { Program } from '@program/shared/program.model';
import { Project } from '@project/shared/project.model';
import { ProjectGroup } from '@shared/services/project-group.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavigationSecondaryService {
  private navItemSubject = new BehaviorSubject<NavItem[]>([]);
  public navItems$: Observable<NavItem[]> = this.navItemSubject.asObservable();

  set navItems(items: NavItem[]) {
    this.navItemSubject.next(items);
  }

  private currentNavItemSubject = new BehaviorSubject<NavItem>(null);
  public currentNavItem$: Observable<NavItem> = this.currentNavItemSubject.asObservable();

  set currentNavItem(item: NavItem) {
    this.currentNavItemSubject.next(item);
  }

  private openSubject = new BehaviorSubject<boolean>(false);
  public open$: Observable<boolean> = this.openSubject.asObservable();

  set open(status: boolean) {
    this.openSubject.next(status);
  }

  private reloadSubject = new BehaviorSubject<boolean>(false);
  public reload$: Observable<boolean> = this.reloadSubject.asObservable();

  set reload(reload: boolean) {
    this.reloadSubject.next(reload);
  }

  private projectSubject = new BehaviorSubject<Project>(null);
  public project$: Observable<Project> = this.projectSubject.asObservable();

  set project(project: Project) {
    this.projectSubject.next(project);
  }

  private programSubject = new BehaviorSubject<Program>(null);
  public program$: Observable<Program> = this.programSubject.asObservable();

  set program(program: Program) {
    this.programSubject.next(program);
  }

  private portfolioSubject = new BehaviorSubject<ProjectGroup>(null);
  public portfolio$: Observable<ProjectGroup> = this.portfolioSubject.asObservable();

  set portfolio(portfolio: ProjectGroup) {
    this.portfolioSubject.next(portfolio);
  }

  private currentUrlSubject = new BehaviorSubject<string | null>(null);
  currentUrl$ = this.currentUrlSubject.asObservable();

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrlSubject.next(router.url);
      }
    });
  }


  /**
   * Replace after UUID.
   * e.g.) '<UUID>/overview' to '<UUID>/information'
   * This component is root and the current url is always #/oes/, we can't use a relative url like ./overview.
   * @param base: string
   * @param detailNavItems: NavItem[]
   */
  public joinParentPath(base: string, detailNavItems: NavItem[], horizontalNav = false) {
    if (horizontalNav) {
      this.open = false;
    } else {
      this.open = detailNavItems.length > 0;
    }
    this.navItems = detailNavItems.map(item => {
      item?.children?.forEach(child => {
        child.url = base + child.url;
      });
      const res = {
        title: item.title,
        url: base + item.url,
        icon: item.icon,
        testId: item.testId,
        queryParams: item.queryParams,
        children: item.children,
        class: item.class
      };
      return res;
    });
  }
}
