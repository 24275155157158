<div
  class="toggle-switch">
  <form [formGroup]="toggleSwitchFormGroup">
    <label class="switch">
      <input
        [id]="id"
        type="checkbox"
        formControlName="toggleSwitch">
      <span class="dot"
        [ngStyle]="customColor"
        [ngClass]="{
          'active-brand': !customColor && toggleSwitchFormGroup?.controls?.toggleSwitch?.value,
          'disabled': disable
        }">
      </span>
    </label>
  </form>
</div>
<ng-content></ng-content>
