import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private _translateService: TranslateService,
              private _oauthService: OAuthService) {
  }

  public getLanguage(): string {
    const idToken: any = this._oauthService.getIdToken();
    if (idToken) {
      const decodedToken: any = jwt_decode(idToken);
      if (decodedToken.locale && decodedToken.locale !== '') {
        const locale = decodedToken.locale === 'pt-BR' ? 'pt' : decodedToken.locale;
        this._translateService.use(locale);
        return locale;
      } else {
        const browserLang = this._translateService.getBrowserLang();
        // If a browser language is not supported, use 'en'.
        if (browserLang !== 'en' && browserLang !== 'fr' && browserLang !== 'pt') {
          return 'en';
        }
        return browserLang;
      }
    }
    return 'en';
  }
}
