import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';
import { VerificationStatus } from '@project/detail/connections/shared/verification-status.enum';

@Component({
  selector: 'oes-verification-status',
  templateUrl: './verification-status.component.html',
  styleUrls: ['./verification-status.component.scss'],
})
export class VerificationStatusComponent implements INoRowsOverlayAngularComp {
  public status: VerificationStatus;
  public verificationStatus = VerificationStatus;
  public reason = {};
  public isFilter = false;

  constructor(private _translateService: TranslateService) {
    this.reason = this._translateService.instant(
      'project-connections.verification-status'
    );
  }

  agInit(params): void {
    if (params) {
      if (params.rowIndex === undefined) {
        // call from grid
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.status = params.value;
    }
  }
}
