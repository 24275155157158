<div *ngIf="projectSetPortfolio" class="project-group-main-content">
  <!-- Do not remove portfolio-header-background.  See css file for reason why -->
  <div class="portfolio-header-background"></div>
  <div class="portfolio-header-container">
    <oes-breadcrumb></oes-breadcrumb>
    <div class="portfolio-header">
      <h2 class="portfolio-name">
        {{ projectSetPortfolio?.name }}
      </h2>
      <div *ngIf="program" class="program-detail">
        <oes-display-program-date-time
          [name]="program?.name"
          [submissionDeadline]="program?.endDateTime"
          [submittedDate]="projectSetPortfolioProgramConnection?.programShareDateTime">
        </oes-display-program-date-time>
      </div>
    </div>
  </div>

  <div class="portfolio-body">
    <router-outlet></router-outlet>
  </div>
</div>
