import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { VendorInformation } from '@organization-management/organization/vendor-information/vendor-information.model';

@Component({
  selector: 'oes-product-offering',
  templateUrl: './product-offering.component.html',
  styleUrls: ['./product-offering.component.scss']
})
export class ProductOfferingComponent implements INoRowsOverlayAngularComp {
  public vendorInformation: VendorInformation;
  public name = '';

  constructor() {
  }

  agInit(params): void {
    if (params && params.value) {
      if (typeof params.value === 'object' && params.value.length > 0) {
        this.vendorInformation = new VendorInformation({
          offerStorage: params.value.includes('storage'),
          offerPvInverters: params.value.includes('pv-inverters'),
          offerStorageConverters: params.value.includes('storage-converters'),
          offerControllers: params.value.includes('controllers'),
          offerIntegratedKits: params.value.includes('integrated-kits'),
          offerEpcServices: params.value.includes('epc-services'),
          offerTurnkeySystems: params.value.includes('turnkey-systems'),
          offerPvPanels: params.value.includes('pv-panels'),
          offerMeters: params.value.includes('meters'),
          offerOmSolutions: params.value.includes('om-solutions'),
          offerAppliances: params.value.includes('appliances'),
          offerConsultingServices: params.value.includes('consulting-services'),
        });
      } else if (typeof params.value === 'string' && params.value !== '') {
        this.name = params.value;
      }
    }
  }
}
