export enum AssociationCategory {
  Project = 'project',
  ProjectDistributionDesign = 'project-distribution-design',
  ProgramDocument = 'program-document',
  ProgramPreQualificationApplication = 'program-prequalification-application',
  ProjectGroup = 'project-group',
  Organization = 'organization',
  VendorPublicProfile = 'vendor-public-profile',
  DeveloperPublicProfile = 'developer-public-profile',
}
