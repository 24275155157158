import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oes-date-time-selector',
  templateUrl: './date-time-selector.component.html',
  styleUrls: ['./date-time-selector.component.scss']
})
export class DateTimeSelectorComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() controlName: string;
  @Input() label = 'Date and Time';
  @Input() required = true;
  @Input() date: Date = new Date();
  @Input() customClass: string;

  dateTimeFormGroup: UntypedFormGroup;
  datePlaceholder: Date = new Date();
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private initForm() {
    const today = new Date();
    // this FormControls are used at oes-create-program component
    this.dateTimeFormGroup = this._formBuilder.group({
      dateSelector: new UntypedFormControl(today, null),
      timeSelector: new UntypedFormControl(today, null)
    });

    this.dateTimeFormGroup.controls['dateSelector'].valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value => {
      if (value) {
        this.datePlaceholder.setTime(value.getTime());
        this.formGroup.controls[this.controlName].setValue(this.datePlaceholder);
      }
    });

    this.dateTimeFormGroup.controls['timeSelector'].valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(value => {
      if (value) {
        this.datePlaceholder.setHours(value.getHours());
        this.datePlaceholder.setMinutes(value.getMinutes());
        this.datePlaceholder.setSeconds(value.getSeconds());
        this.datePlaceholder.setMilliseconds(value.getMilliseconds());
        this.formGroup.controls[this.controlName].setValue(this.datePlaceholder);
      }
    });
  }
}
