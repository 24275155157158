import { Injectable } from '@angular/core';
import { ProjectGroup } from '@shared/services/project-group.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '@project/shared/project.model';

@Injectable({
  providedIn: 'root'
})
export class FmProjectGroupService extends BaseRestApiService {
  private finance = environment.serverUri + `api/${ environment.apiVersion }/fm/project-groups/finance`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  getFinanceProjectGroups(programId?: string): Observable<ProjectGroup[]> {
    const params = { params: { page: 0, size: 1000 } };
    if (programId) {
      params.params['sponsoredProgramId'] = programId;
    }
    return this.get<ProjectGroup[]>(`${ this.finance }`, params)
      .pipe(
        map((projectGroups: ProjectGroup[]) => projectGroups.map((projectGroup: ProjectGroup) => new ProjectGroup(projectGroup)))
      );
  }

  getFinanceProjects(projectGroupId: string): Observable<Project[]> {
    return this.get<Project[]>(`${ this.finance }/${ projectGroupId }/projects`, { params: { page: 0, size: 1000 } })
      .pipe(
        map((projects: Project[]) => projects.map((project: Project) => new Project(project)))
      );
  }
}
