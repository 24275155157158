import {FinanceIntroduction} from './finance-introduction.model';
import {Injectable} from '@angular/core';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinanceIntroductionService extends BaseRestApiService {
  private projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;
  private _isActive: boolean;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public saveFinanceIntroduction(projectGroupId: string, financeIntroduction: FinanceIntroduction): Observable<any> {
    return this.put<any>(`${this.projectGroups}/${projectGroupId}/finance/introductions`, JSON.stringify(financeIntroduction));
  }

  public getFinanceIntroduction(projectGroupId: string): Observable<FinanceIntroduction> {
    return this.get<FinanceIntroduction>(`${this.projectGroups}/${projectGroupId}/finance/introductions`)
            .pipe(
              map(result => new FinanceIntroduction(result))
            );
  }

  get isActive(): boolean  {
    return this._isActive;
  }

  set isActive(isActive: boolean) {
    this._isActive = isActive;
  }
}
