<ng-container *ngIf="chart">
  <div class="horizontal-bar-chart">
    <h4>{{ chart?.title }}</h4>
    <div class="horizontal-bar-with-y d-flex">
      <div class="y-name">
        {{ chart?.options?.yname }}
      </div>
      <canvas baseChart
              [data]="horizontalBarChartData"
              [options]="horizontalBarChartOptions"
              [plugins]="horizontalBarChartPlugins"
              [legend]="horizontalBarChartLegend"
              [type]="'bar'">
      </canvas>
    </div>
    <ng-container *ngIf="chart?.options?.keyWithValues">
      <oes-key-with-values [keys]="chart?.labels"
                           [currencyValues]="chart?.datasets[0]?.data"
                           [currency]="currency"
                           [chartColors]="chartColors"
                           [percentages]="chart?.datasets[1]?.data"></oes-key-with-values>
    </ng-container>
    <div class="x-name">
      {{ chart?.options?.xname }}
    </div>
  </div>
</ng-container>