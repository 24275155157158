<div class="fm-header">
  <div class="fm-header-left">
    <h4>{{ 'offtaker.financial-model.heading' | translate }}</h4>
    <p>{{ 'offtaker.financial-model.last-updated' | translate }}: {{ modelRunToday(psfm?.currentRun?.created) ?
      ('offtaker.financial-model.today' | translate) + ', ' + (psfm?.currentRun?.created | date: 'H:mm') :
      (psfm?.currentRun?.created | date: 'EEEE, MMMM d, y, H:mm') }}</p>
  </div>
  <ng-container *ngIf="psfm && !psfm?.template?.isVersionLatest">
    <button class="btn btn-grey" (click)="updateToLatestModel(psfm?.id)">{{ 'offtaker.financial-model.update-model-version' | translate }}</button>
  </ng-container>
  <button class="btn btn-primary" (click)="downloadRun(psfm?.currentRun?.id)">
    <span class="download-financial-model"></span>{{ 'offtaker.financial-model.download' | translate }}
  </button>
</div>

<div class="metrics">
  <div class="metrics-row-4">
    <!-- Number of Sites -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.number-of-sites' | translate }}
      </div>
      <h3 class="metric-value">
        {{ psfm?.currentRun?.totalProjects | number:'0.0-0' }}
      </h3>
      <p class="metric-tail">
        {{ 'offtaker.project-set.financial-model.sites' | translate }}
      </p>
    </div>

    <!-- Total System Size -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.total-system-size' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.averageSystemSize * psfm?.currentRun?.totalProjects) | number:'0.0-0' }}
      </h3>
      <p class="metric-tail">
        {{ 'units.kWp' | translate }}
      </p>
    </div>

    <!-- Annual PV Production -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.annual-pv-production' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.averagePvProduction * psfm?.currentRun?.totalProjects) | number:'0.0-0' }}
      </h3>
      <p class="metric-tail">
        {{ 'units.kWh' | translate }}{{ 'units.per-yr' | translate }}
      </p>
    </div>

    <!-- Total Customer Consumption -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.total-customer-consumption' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.averageCustomerConsumption * psfm?.currentRun?.totalProjects) | number:'0.0-0' }}
      </h3>
      <p class="metric-tail">
        {{ 'units.kWh' | translate }}{{ 'units.per-yr' | translate }}
      </p>
    </div>
  </div>

  <div class="metrics-row-4">
    <!-- Total Est CapEx -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.total-est-capex' | translate }}
      </div>
      <h3 class="metric-value">
        <ng-container *ngIf="psfm?.projectSet?.currencyType === 'USD'; else OtherCurrencyTotalCapEx">
          {{ (psfm?.projectSet?.totalCapex || 0) | currency: 'USD':'symbol':'1.0-0' }}
        </ng-container>
        <ng-template #OtherCurrencyTotalCapEx>
          {{ (psfm?.projectSet?.totalCapex || 0) | number: '1.0-0' }}
        </ng-template>
      </h3>
      <p class="metric-tail">
        {{ psfm?.projectSet?.currencyType }}
      </p>
    </div>

    <!-- Cost per Watt -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.cost-per-watt' | translate }}
      </div>
      <h3 class="metric-value">
        <ng-container *ngIf="psfm?.projectSet?.currencyType === 'USD'; else OtherCurrencyCPW">
          {{ psfm?.currentRun?.costPerWatt | currency: 'USD':'symbol':'1.2-2' }}
        </ng-container>
        <ng-template #OtherCurrencyCPW>
          {{ psfm?.currentRun?.costPerWatt | number: '1.2-2' }}
        </ng-template>
      </h3>
      <p class="metric-tail">
        {{ psfm?.projectSet?.currencyType }}
      </p>
    </div>

    <!-- Capacity Factor -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.capacity-factor' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.capacityFactor || 0) | percent:'1.1-1' }}
      </h3>
      <p class="metric-tail"></p>
    </div>
    <!-- Tariff -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.tariff' | translate }}
      </div>
      <h3 class="metric-value">
        <ng-container *ngIf="psfm?.projectSet?.currencyType === 'USD'; else OtherCurrencyTariff">
          {{ psfm?.currentRun?.tariff | currency: 'USD':'symbol':'1.2-2' }}
        </ng-container>
        <ng-template #OtherCurrencyTariff>
          {{ psfm?.currentRun?.tariff | number: '1.2-2' }}
        </ng-template>
      </h3>
      <p class="metric-tail">
        {{ psfm?.projectSet?.currencyType }}/{{ 'units.kWh' | translate }}
      </p>
    </div>
  </div>

  <div class="metrics-row-3">
    <!-- Avg EBITDA Margin -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.avg-ebitda-margin' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.ebitdaMargin || 0) | percent:'1.1-1' }}
      </h3>
      <p class="metric-tail"></p>
    </div>
    <!-- Unlevered Project IRR -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.unlevered-project-irr' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.unleveredIrr || 0) | percent:'1.1-1' }}
      </h3>
      <p class="metric-tail"></p>
    </div>
    <!-- Payback Period -->
    <div class="metric">
      <div class="metric-title">
        {{ 'offtaker.project-set.financial-model.payback-period' | translate }}
      </div>
      <h3 class="metric-value">
        {{ (psfm?.currentRun?.paybackPeriod || 0) | number:'1.0-1' }}
      </h3>
      <p class="metric-tail">
        {{ 'units.years' | translate }}
      </p>
    </div>
  </div>
</div>

<div *ngIf="psfm?.currentRun" class="charts">
  <div class="chart w-100">
    <oes-chart [chart]="psfm?.currentRun?.yearlyCashflowChart"
               [chartColors]="yearlyCashflowChartColors">
    </oes-chart>
  </div>
</div>

<div *ngIf="psfm?.currentRun" class="charts">
  <div class="chart chart-33">
    <oes-chart [chart]="psfm?.currentRun?.lifeOfProjectEnergyChart"
               [chartColors]="lifeOfProjectEnergyChartColors">
    </oes-chart>
  </div>
  <div class="chart chart-33">
    <oes-chart [chart]="psfm?.currentRun?.quarterlyEnergyKwhChart"
               [chartColors]="quarterlyEnergyKwhChartColors">
    </oes-chart>
  </div>
  <div class="chart chart-33">
    <oes-chart [chart]="psfm?.currentRun?.expensesBreakdownChart"
               [chartColors]="expensesBreakdownChartColors"
               [currency]="psfm?.projectSet?.currencyType">
    </oes-chart>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-grey back-to-top-button" (click)="scrollToTop()">
    {{ 'buttons.back-to-top' | translate }}
    <img src="/assets/icons/line-icons/shared/grey/arrow-up.svg" />
  </button>
</div>
