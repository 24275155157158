import { Component, Output, EventEmitter, Input } from '@angular/core';
import { utils, read, WorkBook, WorkSheet } from 'xlsx';
import { EventService } from '../../event.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'oes-excel-loader',
  templateUrl: './excel-loader.component.html',
  styleUrls: ['./excel-loader.component.scss']
})
export class ExcelLoaderComponent {
  @Input() sheets: string[];
  @Input() buttonUpload = 'Upload';
  @Input() buttonDownload: string;
  @Input() downloadFile: string;
  @Output() loaded: EventEmitter<any> = new EventEmitter<any>();

  filename: string;

  private xlsxData = {};

  constructor(private _eventService: EventService,
              private _translateService: TranslateService) {
  }

  uploadExcel(event) {
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files && target.files.length === 1) {
      const reader: FileReader = new FileReader();
      reader.onload = (e => {
        const workBook: WorkBook = read(e.target['result'], {type: 'binary'});

        this.sheets.forEach(sheet => {
          const workSheet: WorkSheet = workBook.Sheets[sheet];
          if (!workSheet) {
            this._eventService.error(this._translateService.instant('error-message.not-able') + ' ' + sheet + ' ' + this._translateService.instant('error-message.sheet'));
            return;
          }
          this.xlsxData[sheet] = utils.sheet_to_json(workSheet);
        });
      });

      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = () => {
        const status = this.sheets.filter(sheet => this.xlsxData[sheet].length > 0);
        if (status.length > 0) {
          // emit a json data
          this.loaded.emit(this.xlsxData);
        } else {
          this._eventService.error(this._translateService.instant('error-message.excel-blank'));
        }
        // clear input field to upload the same name of file.
        this.filename = null;
      };
    }
  }
}
