import { BaseModel } from '@shared/base.model';
import { Project } from '@project/shared/project.model';
import { CONNECTIONS_PAYOUT_TYPE } from './connections-payout-type.enum';
import { CONNECTIONS_PAYOUT_ORIGIN } from './connections-payout-origin.enum';

export class ConnectionsPayout extends BaseModel<ConnectionsPayout> {
  id: string;
  connectionsPaid: number;
  connectionsApproved: number;
  name: string;
  notes: string;
  payoutAmount: number;
  payoutDate: Date;
  payoutOrigin: CONNECTIONS_PAYOUT_ORIGIN;
  payoutType: CONNECTIONS_PAYOUT_TYPE;
  project: Project;
  totalClaimAmount: number;
  payoutExchangeRate: number;
  payoutCurrency: string;

  constructor(data: any) {
    super();
    this.id = data.id;
    this.connectionsPaid = data.connectionsPaid;
    this.connectionsApproved = data.connectionsApproved;
    this.name = data.name;
    this.notes = data.notes;
    this.payoutAmount = data.payoutAmount;
    this.payoutDate = data.payoutDate;
    this.payoutOrigin = data.payoutOrigin;
    this.payoutType = data.payoutType;
    this.project = data.project;
    this.totalClaimAmount = data.totalClaimAmount;
    this.payoutExchangeRate = data.payoutExchangeRate;
    this.payoutCurrency = data.payoutCurrency;
  }
}
