import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'oes-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id = 'toggleSwitchComponent';
  @Input() value = false;
  @Input() disable = false;
  @Input() customColor: any;
  @Output() updated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public ngUnsubscribe: Subject<any> = new Subject();
  public toggleSwitchFormGroup: UntypedFormGroup;

  constructor() {
  }

  ngOnInit() {
    this.toggleSwitchFormGroup = new UntypedFormGroup({
      toggleSwitch: new UntypedFormControl({value: this.value, disabled: this.disable})
    });
    // subscribe form
    this.toggleSwitchFormGroup.controls['toggleSwitch'].valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(status => {
      this.updated.emit(status);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    // check value
    if (this.toggleSwitchFormGroup && changes.value && typeof changes.value.currentValue === 'boolean') {
      this.toggleSwitchFormGroup.controls['toggleSwitch'].setValue(this.value, {emitEvent: false});
    }
    // check disable status
    if (this.toggleSwitchFormGroup && changes.disable && typeof changes.disable.currentValue === 'boolean') {
      if (this.disable) {
        this.toggleSwitchFormGroup.controls['toggleSwitch'].disable();
      } else {
        this.toggleSwitchFormGroup.controls['toggleSwitch'].enable();
      }
    }
  }
}
