<p class="wrapper" [formGroup]="formGroup">
  <input
    type="text"
    class="form-control"
    autocomplete="off"
    formControlName="calendar"
    [placeholder]="'calendar.format' | translate"
    bsDatepicker
    [bsConfig]="bsConfig"
    placement="bottom">
</p>
