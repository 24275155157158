import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ProjectSetPortfolioNavigationService } from './project-set-portfolio-navigation.service';
import { ProjectSetPortfolio } from '@project/offtakers/project-set-portfolio/project-set-portfolio.model';
import { Program } from '@program/shared/program.model';
import { ProjectSetPortfolioConnection } from '../developer-project-set-portfolio-connection.model';

@Component({
  selector: 'oes-project-set-portfolio-navigation',
  templateUrl: './project-set-portfolio-navigation.component.html',
  styleUrls: ['./project-set-portfolio-navigation.component.scss'],
  providers: [ ProjectSetPortfolioNavigationService ]
})
export class ProjectSetPortfolioNavigationComponent implements OnDestroy {
  program: Program;
  projectSetPortfolio: ProjectSetPortfolio;
  projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _projectSetPortfolioNavigationService: ProjectSetPortfolioNavigationService) {
    this.subscribeProjectSetPortfolio();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeProjectSetPortfolio() {
    this._projectSetPortfolioNavigationService.portfolioObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolio: ProjectSetPortfolio) => {
        this.projectSetPortfolio = projectSetPortfolio;
        if (projectSetPortfolio?.program) {
          this.program = projectSetPortfolio.program;
        }
      });
    this._projectSetPortfolioNavigationService.portfolioProgramConnectionObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectSetPortfolioProgramConnection: ProjectSetPortfolioConnection) => {
        this.projectSetPortfolioProgramConnection = projectSetPortfolioProgramConnection;
      });
  }
}
