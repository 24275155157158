import { Component, OnInit, Input } from '@angular/core';
import { Organization } from '@shared/models/organization/organization.model';
import { VendorInformation } from '@organization-management/organization/vendor-information/vendor-information.model';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { CountriesMapService } from '../countries-map/countries-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActiveCountryTab } from './active-country-tab.enum';
import { environment } from '@environments/environment';

@Component({
  selector: 'oes-vendor-profile',
  templateUrl: './vendor-profile.component.html',
  styleUrls: ['./vendor-profile.component.scss'],
})
export class VendorProfileComponent implements OnInit {
  @Input() id: string;
  public organization: Organization;
  public vendorInformation: VendorInformation;
  logoUri: string;
  capitalOffering: string[];
  sectorActivities: string[];
  public tagTypes: TagType[];
  countries: string[];
  allAfrica: boolean;
  activeCountryTab: ActiveCountryTab;
  allCountryTabs: string[];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _countriesMapService: CountriesMapService,
              private _projectGroupService: ProjectGroupService) {
    this._countriesMapService.allSelectedCountriesAreInAfrica$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(allAfrica => {
      this.allAfrica = allAfrica;
    });
    this.allCountryTabs = Object.keys(ActiveCountryTab);
  }

  ngOnInit() {
    this.getVendorProfile();
    this.activeCountryTab = ActiveCountryTab.marketsOfSaleCountries;
  }

  private getVendorProfile() {
    this._projectGroupService.vendorDetail(this.id)
    .subscribe((organization: Organization) => {
      this.organization = organization;
      this.getVendorInfo();
      if (this.organization.logoImage && this.organization.logoImage.id) {
        this.logoUri = `${environment.organizationCdnUri}/${this.organization.logoImage.id}`;
      }
    });
  }

  private getVendorInfo() {
    this._projectGroupService
      .vendorInformation(this.organization.id)
      .subscribe((vendorInformation: VendorInformation) => {
        this.vendorInformation = vendorInformation;
        this.displayCountries(this.vendorInformation);
        this.tagTypes = [{
          docTagItemId: this.vendorInformation.id,
          docTagType: DOCUMENT_TAG_TYPE.VENDOR_INFORMATION,
          name: (DOCUMENT_TAG_TYPE.VENDOR_INFORMATION).replace('_', ' ').toLowerCase()
        }, {
          docTagItemId: this.organization.id,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION,
          name: this.organization.name
        }];
      });
  }

  private displayCountries(vendorInformation: VendorInformation) {
    this.countries = vendorInformation[this.activeCountryTab].map(country => country.name).sort();
    if (this.countries && this.countries.length > 10) {
      const display = this.countries.slice(0, 10);
      display.push(`and ${this.countries.length - 10} more...`);
      this.countries = display;
    }
    this._countriesMapService.buildCountryClasses(vendorInformation[this.activeCountryTab], false);
  }

  updateCountryTab(tab: ActiveCountryTab) {
    this.activeCountryTab = tab;
    this.displayCountries(this.vendorInformation);
  }

  get accessControl (): any {
    return new FilesActions({
      download: true,
      preview: true
    });
  }
}
