import { Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RestApiWrapperService } from './rest-api-wrapper.service';
import { REFERENCE_TYPE } from '@shared/reference-types';
import { environment } from '@environments/environment';
import { ReferenceType } from '@shared/references.model';


export abstract class BaseRestApiService {
  private _http: HttpClient;

  constructor(private _restApiWrapperService: RestApiWrapperService) {
    this._http = this._restApiWrapperService.http;
  }

  public get<T>(url: string, options?: Object, raw = false): Observable<T> {
    return this._http.get<T>(url, options).pipe(map(response => {
      let body: any;
      let original: any;
      if (!raw) {
        if (response) {
          body = response['content'];
        }
      } else {
        original = response;
      }
      return <T>body || <T>response || original;
    }));
  }

  public post<T>(url: string, body?: any | any, options?: Object): Observable<T> {
    return this._http.post<T>(url, body, options);
  }

  public put<T>(url: string, body?: any, options?: Object): Observable<T> {
    return this._http.put<T>(url, body, options);
  }

  public delete<T>(url: string, options?: Object): Observable<T> {
    return this._http.delete<T>(url, options);
  }

  /**
   * @deprecated: use ReferenceService
   */
  public getReferenceType(referenceType: REFERENCE_TYPE): Observable<ReferenceType[]> {
    let params = new HttpParams();
    params = params.set('referenceType', REFERENCE_TYPE[referenceType]);
    const options = {params: params};
    const url = environment.serverUri + `api/${environment.apiVersion}/references`;
    return this._http.get<ReferenceType[]>(url, options)
          .pipe(
            map(results => results.map(result => new ReferenceType(result)))
          );
  }
}
