import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgramClaimsKPIs } from './program-claims-kpi.model';
import { SimpleProject } from '@project/shared/simple-project.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramClaimsKPIService {
  private _claimsKPIsSubject = new BehaviorSubject<ProgramClaimsKPIs>(new ProgramClaimsKPIs({}));
  public claimsKPIs: Observable<ProgramClaimsKPIs> = this._claimsKPIsSubject.asObservable();

  constructor() {
  }

  buildClaimsKPIs(projects: SimpleProject[]) {
    const kpis: ProgramClaimsKPIs = projects?.reduce((accum, project) => {
      accum.approvedConnections += project.totalConnectionsApproved;
      accum.paidConnections += project.totalConnectionsPaid;
      accum.submittedConnections += project.totalConnectionsSubmitted;
      accum.totalPayouts += project.connectionsAmountPaid;
      return accum;
    }, new ProgramClaimsKPIs({}));

    this._claimsKPIsSubject.next(kpis);
  }

}
