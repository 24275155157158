<div class="status-cell d-flex justify-content-between">
  <span class="bullet"
        [ngStyle]="{ 'backgroundColor' : colorHex, 'color' : textColorHex }">
    {{ nativeStatuses.includes(name.toLowerCase().replace(' ', '-')) ? ('program.pre-qualification.status.' + name.toLowerCase().replace(' ', '-') | translate) : name }}
    <ng-container *ngIf="name === 'Selected' && !lastStage">{{ 'program.pre-qualification.status.for-next' | translate }}</ng-container>
  </span>
  <ng-container *ngIf="paramsActive">
    <i class="fa fa-caret-down" aria-hidden="true"></i>
  </ng-container>
</div>

