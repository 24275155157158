import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'oes-customer-category',
  templateUrl: './customer-category.component.html',
  styleUrls: ['./customer-category.component.scss']
})
export class CustomerCategoryComponent implements ICellEditorAngularComp {
  private categories = {};
  public css = '';
  public name = '';

  constructor(private _translateService: TranslateService) {
    this.categories = this._translateService.instant('tier-category');
  }

  agInit(params): void {
    if (params.value) {
      this.css = params.value.toLowerCase();
      this.name = this.categories[params.value];
    }
  }

  getValue(): string {
    return this.name;
  }

  isPopup() {
    // don't return true, the editor doesn't fit the cell width
    return false;
  }
}
