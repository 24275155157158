import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationStatusRules } from '@program/program-detail/program-configuration/program-application-statuses/application-status-rules.model';
import { ProgramProjectStageRules } from '@program/program-detail/program-configuration/program-stages/program-project-stage-rules.model';
import { ProgramSettings } from '@program/program-detail/program-configuration/settings/settings.model';
import { ProjectCopyIdsModel } from '@program/program-project-list/project-copy-ids.model';
import { AssignPayoutRule } from '@project/detail/connections/action-components/assign-payout/assign-payout.model';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MilestoneConfig } from './milestone-config.model';
import { ProgramProjectRules } from './program-project-rules.model';
import { ProgramPortfolioRules } from './program-project-rules.model.1';
import { PROGRAM_RULE_TYPES } from './program-rule-types.enum';
import { ProgramVerificationRules } from './program-verification-rules.model';
import { ProgramConstant } from './program.constant';
import { Program, PROGRAM_TYPE, ProgramSubmissionType } from './program.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends BaseRestApiService {
  private _programId: string;
  private _program: Program;
  private type = {};

  // Note: program-general-rules is controlling this.
  public updateSubject = new BehaviorSubject<boolean>(false);
  public update$ = this.updateSubject.asObservable();

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _translateService: TranslateService) {
    super(_restApiWrapperService);
    this.type = this._translateService.instant('program.type');
  }

  set program(program: Program) {
    this._program = program;
  }

  get program() {
    return this._program;
  }

  set programId(programId: string) {
    this._programId = programId;
  }

  get programId() {
    return this._programId;
  }

  public copyProjects(projectIds: ProjectCopyIdsModel) {
    return this.post(ProgramConstant.program.copyProject(), JSON.stringify(projectIds));
  }

  public copyProjectGroups(projectGroupIds: string[]) {
    const ids = { projectGroupIds: projectGroupIds };
    return this.post(ProgramConstant.program.copyProjectGroups(), JSON.stringify(ids));
  }

  /**
   * Programs list
   */
  public getPrograms(): Observable<Program[]> {
    return this.get<Program[]>(ProgramConstant.program.list(), {params: {page: 0, size: 1000}})
    .pipe(
      map((result: Program[]) => {
        return result.map(program => new Program(program)).sort((a: Program, b: Program) => {
          return new Date(a.beginDateTime).getTime() - new Date(b.beginDateTime).getTime();
        });
     })
    );
  }

  /**
   * Program detail
   * @param programId: string
   */
  public getProgram(programId: string): Observable<Program> {
    return this.get<Program>(ProgramConstant.program.detail(programId))
      .pipe(map(result => new Program(result)));
  }

  public create(program: Program): Observable<Program> {
    return this.post<Program>(ProgramConstant.program.create(), program)
      .pipe(map(result => new Program(result)));
  }

  public update(programId: string, program: Program): Observable<Program> {
    return this.put<Program>(ProgramConstant.program.update(programId), program)
      .pipe(map(result => new Program(result)));
  }

  /**
   * Program rules
   * @param programId: string
   * @param type: PROGRAM_RULE_TYPES
   */
  public getProgramRules(programId: string, type: PROGRAM_RULE_TYPES): Observable<any> {
    return this.get<any>(ProgramConstant.rule.list(programId, type))
    .pipe(
      map((result: any) => {
        switch (type) {
          case PROGRAM_RULE_TYPES.PROJECT_RULE:
          return result?.value ? new ProgramProjectRules(result.value.projectRules[0]) : new ProgramProjectRules({});

          case PROGRAM_RULE_TYPES.PORTFOLIO_RULE:
          return result?.value ? new ProgramPortfolioRules(result.value.portfolioRules[0]) : new ProgramPortfolioRules({});

          case PROGRAM_RULE_TYPES.VERIFICATION_RULE:
          return result?.value ? result.value.verificationRules.map(rule => new ProgramVerificationRules(rule)) : [new ProgramVerificationRules({})];

          case PROGRAM_RULE_TYPES.PAYOUT_RULE:
          return result?.value ? result.value.payoutRules.map(rule => new AssignPayoutRule(rule)) : [new AssignPayoutRule({})];

          case PROGRAM_RULE_TYPES.APPLICATION_STATUS_RULE:
          return result?.value ? result.value.applicationStatusRules.map(rule => new ApplicationStatusRules(rule)) : [new ApplicationStatusRules({})];

          case PROGRAM_RULE_TYPES.PROGRAM_SETTINGS:
          return result?.value ?  result.value.programSettings.map(rule => new ProgramSettings(rule)) : [new ProgramSettings({})];

          case PROGRAM_RULE_TYPES.PROJECT_STAGE_RULE:
          return result?.value ?  result.value.projectStageRules.map(rule => new ProgramProjectStageRules(rule)) : [new ProgramProjectStageRules({})];
        }
      })
    );
  }

  public saveProgramRules(programId: string, body: any): Observable<any> {
    return this.put(ProgramConstant.rule.save(programId), body);
  }

  /**
   * get participating programs
   */
  public getParticipating(): Observable<Program[]> {
    return this.get<Program[]>(ProgramConstant.program.participating(), {params: {page: 0, size: 1000}})
      .pipe(
        map((result: Program[]) => result.map((program: Program) => new Program(program)))
      );
  }

  /**
   * get all PARTICIPANT_REPORT documents for this program (Investor Only)
   */
  public getParticipantReports(programId: string): Observable<any[]> {
    return this.get<any[]>(ProgramConstant.program.participantReports(programId), {params: {page: 0, size: 1000}});
  }

  public getApproved(): Observable<Program[]> {
    return this.get<Program[]>(ProgramConstant.program.approved(), {params: {page: 0, size: 1000}})
    .pipe(
      map((result: Program[]) => result.map((program: Program) => new Program(program)))
    );
  }

  public getApprovedByType(submissionType: ProgramSubmissionType): Observable<Program[]> {
    return this.get<Program[]>(ProgramConstant.program.approvedByType(submissionType))
    .pipe(
      map((result: Program[]) => result.map((program: Program) => new Program(program)))
    );
  }

  public getProgramType(program: Program): any {
    if (program) {
      const inviteOnly = program.inviteOnly;
      const prequal = program.requirePreQualification;
      if (!inviteOnly && !prequal) {
        return {
          enum: PROGRAM_TYPE.PUBLIC_NO_PRE_QUALIFICATION,
          title: this.type['public'] + ', ' + this.type['no-pre-qualification']
        };
      } else if (!inviteOnly && prequal) {
        return {
          enum: PROGRAM_TYPE.PUBLIC_PRE_QUALIFICATION,
          title: this.type['public'] + ', ' + this.type['pre-qualification']
        };
      } else if (inviteOnly && !prequal) {
        return {
          enum: PROGRAM_TYPE.INVITE_ONLY_NO_PRE_QUALIFICATION,
          title: this.type['invite-only'] + ', ' + this.type['no-pre-qualification']
        };
      } else if (inviteOnly && prequal) {
        return {
          enum: PROGRAM_TYPE.INVITE_ONLY_PRE_QUALIFICATION,
          title: this.type['invite-only'] + ', ' + this.type['pre-qualification']
        };
      }
    }
    return '';
  }

  getProgramMilestoneConfigs(programId: string): Observable<MilestoneConfig[]> {
    return this.get<MilestoneConfig[]>(ProgramConstant.milestoneConfig.findByProgramId(programId), {params: {page: 0, size: 1000}})
    .pipe(map(milestoneConfigs => milestoneConfigs.sort((a, b) => {
      return a.index - b.index;
    })));
  }

  saveProgramMilestoneConfigs(programId: string, body: any): Observable<MilestoneConfig[]> {
    return this.put<MilestoneConfig[]>(ProgramConstant.milestoneConfig.updateAllByProgramId(programId), body)
    .pipe(map(milestoneConfigs => milestoneConfigs.sort((a, b) => {
      return a.index - b.index;
    })));
  }

  updateProgramMilestoneConfig(programId: string, milestoneConfigId: string, milestoneConfig: MilestoneConfig): Observable<MilestoneConfig> {
    return this.put<MilestoneConfig>(ProgramConstant.milestoneConfig.updateMilestoneConfig(programId, milestoneConfigId), milestoneConfig);
  }

  deleteProgramMilestoneConfig(programId: string, milestoneConfigId: string): Observable<any> {
    return this.delete<any>(ProgramConstant.milestoneConfig.delete(programId, milestoneConfigId));
  }
}
