import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { environment } from '@environments/environment';
import { ProductOfferingComponent } from '@shared/ag-grid/component/product-offering/product-offering.component';
import { ProductOfferingFilterComponent } from '@shared/ag-grid/component/product-offering-filter/product-offering-filter.component';
import { OpenOrganizationProfileComponent } from '@shared/ag-grid/component/open-organization-profile/open-organization-profile.component';
import { ProfileType } from './profile-type.enum';
import { GridState } from '@shared/ag-grid/gird-state';

// Do not use global provider to keep variable values for each callers.
@Injectable()
export class OrganizationListGridSettingService extends BaseAgGridSettingsService {
  private columns = {};
  private productOfferingKeys = [];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.columns = _agGridServicesWrapperService._translateService.instant('organization-list.column');
    const productOffering = _agGridServicesWrapperService._translateService.instant('product-select');
    this.productOfferingKeys = Object.keys(productOffering);
  }

  public getGridOptions(profileType: ProfileType): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(profileType),
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      suppressRowClickSelection: false,
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      suppressDragLeaveHidesColumns: true,
      enableRangeSelection: true,
      groupHideOpenParents: true,
      rowGroupPanelShow: 'always',
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      rowHeight: 80,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        ]
      },
      components: {
        'productOfferingComponent': ProductOfferingComponent,
        'productOfferingFilterComponent': ProductOfferingFilterComponent,
        'openOrganizationProfileComponent': OpenOrganizationProfileComponent,
      }
    };
  }

  // Column Properties
  // https://www.ag-grid.com/javascript-grid-column-properties/
  public getColumnSetting(profileType: ProfileType): any {
    const colDef = [
      {
        headerName: this.columns['logo'],
        headerTooltip: this.columns['logo'],
        field: 'logoImage',
        resizable: true,
        suppressSizeToFit: true,
        width: 80,
        cellRenderer: (params) => {
          if (params?.data?.logoImage && params?.data?.logoImage.id) {
            return `<img class="company-logo" width="63px" height="63px" src="${environment.organizationCdnUri}/${params?.data?.logoImage.id}">`;
          }
          return '<div class="no-image" title="no image"><i class="fa fa-picture-o" aria-hidden="true"></i></div>';
        },
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        filter: false,
        sortable: false,
        suppressMenu: true
      },
      // Name
      {
        headerName: this.columns['name'],
        headerTooltip: this.columns['name'],
        field: 'name',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: 'openOrganizationProfileComponent',
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // contact person
      {
        headerName: this.columns['contact-person'],
        headerTooltip: this.columns['contact-person'],
        field: 'name',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.data?.contactFirstName) {
            return params.data.contactFirstName + ' ' + params.data.contactLastName;
          }
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // email
      {
        headerName: this.columns['email'],
        headerTooltip: this.columns['email'],
        field: 'contactEmailAddress',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          if (params?.data?.contactEmailAddress) {
            return `<a class="hyperlink" href="mailto:${params.data.contactEmailAddress}">${params.data.contactEmailAddress}</a>`;
          }
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // phone
      {
        headerName: this.columns['phone'],
        headerTooltip: this.columns['phone'],
        field: 'contactPrimaryPhone',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // Location
      {
        headerName: this.columns['location'],
        headerTooltip: this.columns['location'],
        field: 'headquartersLocation',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // Years in Business
      {
        headerName: this.columns['years-in-business'],
        headerTooltip: this.columns['years-in-business'],
        field: 'yearsInBusiness',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // Number of Employees
      {
        headerName: this.columns['number-of-employees'],
        headerTooltip: this.columns['number-of-employees'],
        field: 'numberOfEmployees',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // language
      {
        headerName: this.columns['language'],
        headerTooltip: this.columns['language'],
        field: 'languagesSpoken',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
      // overview
      {
        headerName: this.columns['overview'],
        headerTooltip: this.columns['overview'],
        field: 'companyOverview',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return params?.data?.companyOverview;
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        cellClass: 'text-middle',
        enableRowGroup: true,
      },
    ];
    // Vendor Profiles: Product Offering
    if (profileType && profileType === ProfileType.vendor) {
      colDef.splice(
        5,
        0,
        <any>{
          headerName: this.columns['product-offering'],
          headerTooltip: this.columns['product-offering'],
          field: 'vendorInformation',
          unSortIcon: true,
          enableValue: true,
          resizable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            selectAllOnMiniFilter: false,
            clearButton: true,
            values: this.productOfferingKeys,
            cellRenderer: 'productOfferingComponent',
          },
          valueGetter: (params) => {
            if (params?.data?.vendorInformation) {
              const values = [];
              if (params.data.vendorInformation.offerStorage) {
                values.push('storage');
              }
              if (params.data.vendorInformation.offerPvInverters) {
                values.push('pv-inverters');
              }
              if (params.data.vendorInformation.offerStorageConverters) {
                values.push('storage-converters');
              }
              if (params.data.vendorInformation.offerControllers) {
                values.push('controllers');
              }
              if (params.data.vendorInformation.offerIntegratedKits) {
                values.push('integrated-kits');
              }
              if (params.data.vendorInformation.offerEpcServices) {
                values.push('epc-services');
              }
              if (params.data.vendorInformation.offerTurnkeySystems) {
                values.push('turnkey-systems');
              }
              if (params.data.vendorInformation.offerPvPanels) {
                values.push('pv-panels');
              }
              if (params.data.vendorInformation.offerMeters) {
                values.push('meters');
              }
              if (params.data.vendorInformation.offerOmSolutions) {
                values.push('om-solutions');
              }
              if (params.data.vendorInformation.offerAppliances) {
                values.push('appliances');
              }
              if (params.data.vendorInformation.offerConsultingServices) {
                values.push('consulting-services');
              }
              return values;
            }
          },
          cellClass: ['text-middle'],
          cellRenderer: 'productOfferingComponent',
        }
      );
    }
    return colDef;
  }
}
