import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from "ngx-bootstrap/modal";
import { Installer } from "../installer.model";
import { Organization } from "@shared/models/organization/organization.model";
import { InstallerService } from "../installer.service";
import { TranslateService } from "@ngx-translate/core";
import { EventService } from "@shared/event.service";
import { take } from "rxjs";


@Component({
  selector: 'oes-installer-form',
  templateUrl: './installer-form.component.html',
  styleUrls: ['./installer-form.component.scss']
})
export class InstallerFormComponent implements OnInit {
  @ViewChild('installerModal', {static: false}) installerModal: ModalDirective;
  @ViewChild('deleteInstallerModal', {static: false}) deleteInstallerModal: ModalDirective;

  @Input() installers: Installer[] = [];
  @Input() organization: Organization;

  @Output() refreshInstallers: EventEmitter<Installer> = new EventEmitter<Installer>();

  action: 'create' | 'edit';
  duplicateName = false;
  duplicateEmail = false;
  formGroup: UntypedFormGroup;
  installerEditId: string = '';

  private message = {};

  constructor(private _eventService: EventService,
              private _installerService: InstallerService,
              private _translateService: TranslateService) { }

  ngOnInit() {
    this.buildForm();
    this.message = this._translateService.instant('installers.form.message');
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup(
      {
        companyName: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
        email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.maxLength(1000)]),
      }
    );
  }

  public openModal(action: 'create' | 'edit', event: any = null) {
    this.action = action;
    if (action === 'create') {
      this.addInstallerOpen();
    } else {
      this.editInstallerOpen(event?.data);
    }
  }

  addInstallerOpen() {
    this.formGroup.patchValue({
      companyName: '',
      email: ''
    });
    this.duplicateName = false;
    this.duplicateEmail = false;
    this.installerModal.show();
  }

  editInstallerOpen(data: any) {
    this.formGroup.patchValue({
      companyName: data.name,
      email: data.email
    });
    this.installerEditId = data.id;
    this.duplicateName = false;
    this.duplicateEmail = false;
    this.installerModal.show();
  }

  handleSubmitInstaller() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid && this.checkDuplicateNameAndEmail()) {
      if (this.action === 'create') {
        this.createInstaller();
      } else {
        this.updateInstaller();
      }
      this.closeInstallerModal();
      this.formGroup.reset();
    }
  }

  createInstaller() {
    this._installerService.createInstaller(new Installer({
      name: this.formGroup.value.companyName.trim(),
      email: this.formGroup.value.email.trim(),
      ownerOrganization: this.organization
    }))
    .pipe(take(1))
    .subscribe({
      next: (newInstaller: Installer) => {
        this._eventService.success(this.message['create-success']);
        this.refreshInstallers.emit(newInstaller);
      },
      error: () => {
        this._eventService.error(this.message['create-error']);
      }
    });
  }

  updateInstaller() {
    const targetInstaller = this.installers.find(installer => installer.id === this.installerEditId);
    if (!targetInstaller) {
      return;
    }
    targetInstaller.name = this.formGroup.value.companyName.trim();
    targetInstaller.email = this.formGroup.value.email.trim();
    this._installerService.updateInstaller(targetInstaller)
    .pipe(take(1))
    .subscribe({
      next: () => {
        this._eventService.success(this.message['update-success']);
        this.refreshInstallers.emit();
      },
      error: () => {
        this._eventService.error(this.message['update-error']);
      }
    });
  }

  handleDeleteInstaller() {
    const installerToDelete = this.installers.find(installer => installer.id === this.installerEditId);
    if (installerToDelete?.projectInstallers.length) {
      this._eventService.error(this.message['delete-error-project-installer']);
      return;
    }
    this._installerService.deleteInstaller(this.installerEditId)
    .pipe(take(1))
    .subscribe({
      next: () => {
        this._eventService.success(this.message['delete-success']);
        this.refreshInstallers.emit();
      },
      error: () => {
        this._eventService.error(this.message['delete-error']);
      }
    });
    this.closeInstallerModal();
    this.deleteInstallerModal.hide();
  }

  showError(fieldName: string, errorName: string) {
    return this.formGroup?.touched && this.formGroup?.controls?.[fieldName]?.errors?.[errorName];
  }

  checkDuplicateNameAndEmail(): boolean {
    this.duplicateName = false;
    this.duplicateEmail = false;
    this.installers.forEach(installer => {
      if (installer.id === this.installerEditId) {
        return;
      }
      if (installer.name === this.formGroup.value.companyName.trim()) {
        this.duplicateName = true;
      }
      if (installer.email === this.formGroup.value.email.trim()) {
        this.duplicateEmail = true;
      }
    });
    return !this.duplicateName && !this.duplicateEmail;
  }

  closeInstallerModal() {
    this.installerEditId = '';
    this.installerModal.hide();
  }
}
