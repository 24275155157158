import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { concatAll, last, map, take, takeUntil } from 'rxjs/operators';
import { FilesService } from '@shared/components/files/shared/files.service';
import { ActionType } from '@shared/components/files/shared/action-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { AssociationCategory } from '@shared/components/files/shared/association-category.enum';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { HttpParams } from '@angular/common/http';
import { ProjectService } from '@project/shared/project.service';
import { forkJoin, from, Subject } from 'rxjs';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { UserService } from '@user/user.service';
import { User } from '@user/user.model';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { ProjectDistributionDesignService } from '@project/detail/distribution-design/project-distribution-design.service';
import { ROLE_TYPE } from '@user/role-type';
import { DeveloperInformationService } from '@organization-management/organization/developer-information/developer-information.service';
import { VendorInformationService } from '@organization-management/organization/vendor-information/vendor-information.service';
import { ProgramService } from '@program/shared/program.service';
import { FinanceService } from '@finance/developer-portfolio/shared/finance.service';
import { DeveloperInformation } from '@organization-management/organization/developer-information/developer-information.model';
import { VendorInformation } from '@organization-management/organization/vendor-information/vendor-information.model';
import { SimpleProject } from '@project/shared/simple-project.model';
import { ProjectDistributionDesign } from '@project/detail/distribution-design/project-distribution-design.model';
import { flatten } from 'lodash';

@Component({
  selector: 'oes-association-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AssociationAddComponent implements OnInit {
  @ViewChild(ModalDialogComponent, {static: false}) private modalDialog: ModalDialogComponent;

  associationCategories = [];
  associationCategory = AssociationCategory;
  formGroup: UntypedFormGroup;
  options: IMultiSelectOption[];
  selectedAssociationCategory: AssociationCategory;

  private ngUnsubscribe: Subject<any> = new Subject();
  private user: User;

  constructor(private _documentDataService: DocumentDataService,
              private _filesService: FilesService,
              private _translateService: TranslateService,
              private _eventService: EventService,
              private _financeService: FinanceService,
              private _projectService: ProjectService,
              private _programService: ProgramService,
              private _userService: UserService,
              private _projectDistributionDesignService: ProjectDistributionDesignService,
              private _developerInformationService: DeveloperInformationService,
              private _vendorInformationService: VendorInformationService) {
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      category: new UntypedFormControl('', null),
      selectOption: new UntypedFormControl()
    });
    this.generateAssociationRoot();
  }

  private generateAssociationRoot() {
    const categories = this._translateService.instant('data-room.dialogs.association.categories');
    if (typeof categories === 'object') {
      this.associationCategories = Object.keys(categories).filter(key => {
        if (this._userService.getSelectedRole() === ROLE_TYPE.DEVELOPER_USER) {
          if (key === 'project' ||
              key === 'project-distribution-design' ||
              // key === 'program-prequalification-application' || // enable if we need
              key === 'project-group' ||
              key === 'organization' ||
              key === 'developer-public-profile') {
            return true;
          }
        }
        if (this._userService.getSelectedRole() === ROLE_TYPE.FINANCE_USER) {
          if (key === 'project' ||
              key === 'project-distribution-design' ||
              key === 'program-document' ||
              key === 'project-group' ||
              key === 'organization') {
            return true;
          }
        }
        if (this._userService.getSelectedRole() === ROLE_TYPE.VENDOR_USER) {
          if (key === 'project' ||
              key === 'project-distribution-design' ||
              key === 'organization' ||
              key === 'vendor-public-profile') {
            return true;
          }
        }
        return false;
      }).map(key => {
        return {
          id: key,
          name: categories[key]
        };
      });
    }
  }

  // callback: associate category dropdown
  changedAssociationCategory(event) {
    this.selectedAssociationCategory = event.target.value;
    switch (this.selectedAssociationCategory) {
      case AssociationCategory.Project:
      case AssociationCategory.ProjectDistributionDesign:
        this.getProject();
        break;
      case AssociationCategory.ProgramDocument:
      case AssociationCategory.ProgramPreQualificationApplication:
        this.getProgram();
        break;
      case AssociationCategory.ProjectGroup:
        this.getPortfolio();
        break;
    }
  }

  private getProject() {
    this._projectService.myList()
    .pipe(take(1))
    .subscribe((projects: SimpleProject[]) => {
      if (projects?.length > 0 ) {
        this.options = projects.map(project => {
          return {
            id: project.id,
            name: project.name
          };
        });
      } else {
        this.options = [];
      }
    });
  }

  private getProgram() {
    this._programService.getPrograms()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((programs) => {
      if (programs?.length > 0 ) {
        this.options = programs.map(program => {
          return {
            id: program.id,
            name: program.name
          };
        });
      } else {
        this.options = [];
      }
    });
  }

  private getPortfolio() {
    let params: HttpParams = new HttpParams();
    params = params.set('financeDeal', 'true');
    this._financeService.list( params)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(portfolios => {
      if (portfolios && portfolios.content && portfolios.content.length > 0 ) {
        this.options = portfolios.content.map(portfolio => {
          return {
            id: portfolio.id,
            name: portfolio.name
          };
        });
      } else {
        this.options = [];
      }
    });
  }

  show() {
    this.modalDialog.show();

  }

  hide() {
    this.formGroup.controls['selectOption'].reset();
    this.modalDialog.hide();
  }

  add() {
    const selectedType = this.formGroup.controls['category'].value;
    let baseTagTypes: TagType[][];
    let baseTagType: TagType[];
    let tagIds: string[];
    switch (selectedType) {
      case AssociationCategory.Project:
        tagIds = this.formGroup.controls['selectOption'].value;
        baseTagTypes = tagIds.map(id => {
          return [{
            docTagItemId: id,
            docTagType: DOCUMENT_TAG_TYPE.PROJECT
          }];
        });
        this.addAssociation(baseTagTypes);
        break;
      case AssociationCategory.ProjectDistributionDesign:
        tagIds = this.formGroup.controls['selectOption'].value;
        baseTagTypes = tagIds.map(id => {
          return [{
            docTagItemId: id,
            docTagType: DOCUMENT_TAG_TYPE.PROJECT
          }, {
            docTagItemId: '',
            docTagType: DOCUMENT_TAG_TYPE.DISTRIBUTION_DESIGN
          }];
        });
        this.getDistribution(baseTagTypes);
        break;
      case AssociationCategory.ProgramDocument:
        tagIds = this.formGroup.controls['selectOption'].value;
        baseTagTypes = tagIds.map(id => {
          return [{
            docTagItemId: id,
            docTagType: DOCUMENT_TAG_TYPE.PROGRAM
          }];
        });
        this.addAssociation(baseTagTypes);
        break;
      case AssociationCategory.ProgramPreQualificationApplication:
        tagIds = this.formGroup.controls['selectOption'].value;
        baseTagTypes = tagIds.map(id => {
          return [{
            docTagItemId: id,
            docTagType: DOCUMENT_TAG_TYPE.PROGRAM
          }];
        });
        this.addAssociation(baseTagTypes);
        break;
      case AssociationCategory.ProjectGroup:
        tagIds = this.formGroup.controls['selectOption'].value;
        baseTagTypes = tagIds.map(id => {
          return [{
            docTagItemId: id,
            docTagType: DOCUMENT_TAG_TYPE.PROJECT_GROUP
          }];
        });
        this.addAssociation(baseTagTypes);
        break;
      case AssociationCategory.Organization:
        baseTagTypes = [[{
          docTagItemId: this.user.organization.id,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION
        }]];
        this.addAssociation(baseTagTypes);
        break;
      case AssociationCategory.DeveloperPublicProfile:
        baseTagType = [{
          docTagItemId: this.user.organization.id,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION
        }, {
          docTagItemId: '',
          docTagType: DOCUMENT_TAG_TYPE.DEVELOPER_INFORMATION
        }];
        this.getDeveloperInformation(baseTagType);
        break;
      case AssociationCategory.VendorPublicProfile:
        baseTagType = [{
          docTagItemId: this.user.organization.id,
          docTagType: DOCUMENT_TAG_TYPE.ORGANIZATION
        }, {
          docTagItemId: '',
          docTagType: DOCUMENT_TAG_TYPE.VENDOR_INFORMATION
        }];
        this.getVendorInformation(baseTagType);
        break;
    }
  }

  private getDistribution(tagTypesArray: TagType[][]) {
    const requests =
      tagTypesArray.map((tagTypes: TagType[]) => this._projectDistributionDesignService.list(tagTypes[0].docTagItemId));

    forkJoin([...requests])
      .subscribe((response: ProjectDistributionDesign[][]) => {
        response.map((distributionDesigns: ProjectDistributionDesign[], index: number) => {
          if (distributionDesigns?.length > 0) {
            tagTypesArray[index][1].docTagItemId = distributionDesigns[0].id;
          }
        });
        this.addAssociation([flatten(tagTypesArray)]);
      });
  }

  private getDeveloperInformation(tagTypes: TagType[]) {
    this._developerInformationService.list(this.user.organization.id)
    .pipe(take(1))
    .subscribe((developerInfo: DeveloperInformation) => {
      tagTypes[1].docTagItemId = developerInfo.id;
      this.addAssociation([tagTypes]);
    });
  }

  private getVendorInformation(tagTypes: TagType[]) {
    this._vendorInformationService.list(this.user.organization.id)
    .pipe(take(1))
    .subscribe((vendorInfo: VendorInformation) => {
      tagTypes[1].docTagItemId = vendorInfo.id;
      this.addAssociation([tagTypes]);
    });
  }

  private addAssociation(tagTypes: TagType[][]) {
    const selectedNode = this._filesService.getHighlightedNodes();
    const documentIds = selectedNode.map(node => node.data.id);
    const requests = tagTypes.map(tag => {
      return  {
        documentIds: documentIds,
        tagTypes: tag
      };
    });

    from(requests)
    .pipe(map(request => this._documentDataService.addSystemTag(request)))
    .pipe(concatAll())
    .pipe(last())
    .subscribe(res => {
      this._filesService.clickActionButton(ActionType.completed);
      this._eventService.success(this._translateService.instant('data-room.dialogs.tag.added'));
      this.hide();
    });
  }
}
