<oes-modal-dialog
  [title]="'buttons.edit-name' | translate"
  [submitButtonText]="'buttons.save' | translate"
  [activateSubmit]="nameError || slashError || !formGroup.valid"
  (submit)="editDocumentName()">
  <!-- the below goes to dialog-body -->
  <div [formGroup]="formGroup">
    <div>
      {{ 'data-room.dialogs.edit-name.current-name' | translate }}: <b>{{ editName }}</b>
    </div>
    <div class="form-group">
      <label for="new-name required">
        {{ 'data-room.dialogs.edit-name.new-name' | translate }}
      </label>
      <div class="input-group">
        <input
          #oesAutoFocus
          type="text"
          class="form-control"
          id="new-name"
          name="new-name"
          [value]="extension"
          (keydown)="onKeyDown($event)"
          formControlName="newName">
      </div>
      <p class="error-text" *ngIf="slashError">{{ 'data-room.dialogs.slash-error' | translate }}</p>
      <p class="error-text" *ngIf="nameError">{{ 'data-room.dialogs.name-error' | translate }}</p>
    </div>
  </div>
</oes-modal-dialog>
