<ng-container *ngIf="navItem">
  <ul class="nav-items">
    <li *ngFor="let item of allNavItems">
      <a routerLink="{{ item.url.split('/')[item.url.split('/').length - 1] }}"
          [queryParams]="item.queryParams"
          routerLinkActive="selected"
          [title]="item.title | translate"
          (click)="handleClick($event, i)">{{ item.title | translate }}
      </a>
    </li>
  </ul>
</ng-container>
