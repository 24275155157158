import { Injectable } from '@angular/core';
import { BaseRestApiService } from '../base-rest-api.service';
import { RestApiWrapperService } from '../rest-api-wrapper.service';
import { Region } from './region.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionService extends BaseRestApiService {
  public regionsUri = `api/${environment.apiVersion}/regions/`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public getRegions(option?: object): Observable<Region[]> {
    return this.get<Region[]>(environment.serverUri + this.regionsUri, option)
          .pipe(
            map(regions => regions.map(region => new Region(region)))
          );
  }
}
