
<oes-modal-dialog
  [title]="'buttons.delete' | translate"
  [cancelButtonText]="'buttons.close' | translate"
  [allowSubmit]="false"
  (close)="complete()"
  [destructive]="true">

  <ng-container *ngFor="let tag of tags">
    <div class="pill {{ tag.tagType.toLowerCase() }}">
      <div class="d-flex flex-row">
        <div>
          {{ tag.tag }}
          <span (click)="delete(tag.id)"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
      </div>
    </div>
  </ng-container>

</oes-modal-dialog>
