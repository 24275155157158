import {BaseModel} from '@shared/base.model';
import {MICROGRID_STATUS} from '@shared/microgrid-statuses';

export class DeveloperInformation extends BaseModel<DeveloperInformation> {
  id: string;
  installedCapacity: number;
  keyProjectDescription: string;
  microGridStatus: MICROGRID_STATUS;
  totalOperationalProjects: number;
  totalProjects: number;
  totalServedCustomers: number;
  videoLink: string;
  videoLinkDescription: string;
  companyOverview: string;

  constructor(developerInfo: any) {
    super();
    this.id = developerInfo.id;
    this.installedCapacity = developerInfo.installedCapacity;
    this.keyProjectDescription = developerInfo.keyProjectDescription;
    this.microGridStatus = developerInfo.microGridStatus;
    this.totalOperationalProjects = developerInfo.totalOperationalProjects;
    this.totalProjects = developerInfo.totalProjects;
    this.totalServedCustomers = developerInfo.totalServedCustomers;
    this.videoLink = developerInfo.videoLink;
    this.videoLinkDescription = developerInfo.videoLinkDescription;
  }

  get isDeveloperInfoValid(): boolean {
    return !!this.microGridStatus;
  }
}
