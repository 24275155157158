<div class="view-toggle-button map-toggle">
  <oes-button class="btn btn-secondary btn-sm view-button"
    [ngButtonClass]="{'btn-gray': !isLinkActive(view.LIST)}"
    (click)="updateQueryParams(view.LIST)"
    title="List">
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3333 7.33333C14.2668 7.33333 14.7335 7.33333 15.09 7.15168C15.4036 6.99189 15.6586 6.73692 15.8183 6.42332C16 6.0668 16 5.60009 16 4.66667V4.16667C16 3.23325 16 2.76654 15.8183 2.41002C15.6586 2.09641 15.4036 1.84145 15.09 1.68166C14.7335 1.5 14.2668 1.5 13.3333 1.5L3.66667 1.5C2.73325 1.5 2.26654 1.5 1.91002 1.68166C1.59641 1.84144 1.34144 2.09641 1.18166 2.41002C1 2.76654 1 3.23325 1 4.16667L1 4.66667C1 5.60009 1 6.0668 1.18166 6.42332C1.34144 6.73692 1.59641 6.99189 1.91002 7.15168C2.26654 7.33333 2.73325 7.33333 3.66667 7.33333L13.3333 7.33333Z"
              stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3333 16.5C14.2668 16.5 14.7335 16.5 15.09 16.3183C15.4036 16.1586 15.6586 15.9036 15.8183 15.59C16 15.2335 16 14.7668 16 13.8333V13.3333C16 12.3999 16 11.9332 15.8183 11.5767C15.6586 11.2631 15.4036 11.0081 15.09 10.8483C14.7335 10.6667 14.2668 10.6667 13.3333 10.6667L3.66667 10.6667C2.73325 10.6667 2.26654 10.6667 1.91002 10.8483C1.59641 11.0081 1.34144 11.2631 1.18166 11.5767C1 11.9332 1 12.3999 1 13.3333L1 13.8333C1 14.7668 1 15.2335 1.18166 15.59C1.34144 15.9036 1.59641 16.1586 1.91002 16.3183C2.26654 16.5 2.73325 16.5 3.66667 16.5H13.3333Z"
              stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {{ 'toggle-button.list' | translate }}
  </oes-button>
  <ng-container *ngIf="mapView">
    <oes-button class="btn btn-secondary btn-sm view-button"
      [ngButtonClass]="{'btn-gray': !isLinkActive(view.MAP)}"
      (click)="updateQueryParams(view.MAP)"
      title="Map">
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99999 15.0001L1.16666 18.3334V5.00008L6.99999 1.66675M6.99999 15.0001L12.8333 18.3334M6.99999 15.0001V1.66675M12.8333 18.3334L17.8333 15.0001V1.66675L12.8333 5.00008M12.8333 18.3334V5.00008M12.8333 5.00008L6.99999 1.66675"
                stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ 'toggle-button.map' | translate }}
    </oes-button>
  </ng-container>
  <ng-container *ngIf="gridView">
    <oes-button class="btn btn-secondary btn-sm view-button"
      [ngButtonClass]="{'btn-gray': !isLinkActive(view.GRID)}"
      (click)="updateQueryParams(view.GRID)"
      title="Map">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 2.5H3.83333C3.36662 2.5 3.13327 2.5 2.95501 2.59083C2.79821 2.67072 2.67072 2.79821 2.59083 2.95501C2.5 3.13327 2.5 3.36662 2.5 3.83333V7C2.5 7.46671 2.5 7.70007 2.59083 7.87833C2.67072 8.03513 2.79821 8.16261 2.95501 8.24251C3.13327 8.33333 3.36662 8.33333 3.83333 8.33333H7C7.46671 8.33333 7.70007 8.33333 7.87833 8.24251C8.03513 8.16261 8.16261 8.03513 8.24251 7.87833C8.33333 7.70007 8.33333 7.46671 8.33333 7V3.83333C8.33333 3.36662 8.33333 3.13327 8.24251 2.95501C8.16261 2.79821 8.03513 2.67072 7.87833 2.59083C7.70007 2.5 7.46671 2.5 7 2.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.1667 2.5H13C12.5333 2.5 12.2999 2.5 12.1217 2.59083C11.9649 2.67072 11.8374 2.79821 11.7575 2.95501C11.6667 3.13327 11.6667 3.36662 11.6667 3.83333V7C11.6667 7.46671 11.6667 7.70007 11.7575 7.87833C11.8374 8.03513 11.9649 8.16261 12.1217 8.24251C12.2999 8.33333 12.5333 8.33333 13 8.33333H16.1667C16.6334 8.33333 16.8667 8.33333 17.045 8.24251C17.2018 8.16261 17.3293 8.03513 17.4092 7.87833C17.5 7.70007 17.5 7.46671 17.5 7V3.83333C17.5 3.36662 17.5 3.13327 17.4092 2.95501C17.3293 2.79821 17.2018 2.67072 17.045 2.59083C16.8667 2.5 16.6334 2.5 16.1667 2.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.1667 11.6667H13C12.5333 11.6667 12.2999 11.6667 12.1217 11.7575C11.9649 11.8374 11.8374 11.9649 11.7575 12.1217C11.6667 12.2999 11.6667 12.5333 11.6667 13V16.1667C11.6667 16.6334 11.6667 16.8667 11.7575 17.045C11.8374 17.2018 11.9649 17.3293 12.1217 17.4092C12.2999 17.5 12.5333 17.5 13 17.5H16.1667C16.6334 17.5 16.8667 17.5 17.045 17.4092C17.2018 17.3293 17.3293 17.2018 17.4092 17.045C17.5 16.8667 17.5 16.6334 17.5 16.1667V13C17.5 12.5333 17.5 12.2999 17.4092 12.1217C17.3293 11.9649 17.2018 11.8374 17.045 11.7575C16.8667 11.6667 16.6334 11.6667 16.1667 11.6667Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 11.6667H3.83333C3.36662 11.6667 3.13327 11.6667 2.95501 11.7575C2.79821 11.8374 2.67072 11.9649 2.59083 12.1217C2.5 12.2999 2.5 12.5333 2.5 13V16.1667C2.5 16.6334 2.5 16.8667 2.59083 17.045C2.67072 17.2018 2.79821 17.3293 2.95501 17.4092C3.13327 17.5 3.36662 17.5 3.83333 17.5H7C7.46671 17.5 7.70007 17.5 7.87833 17.4092C8.03513 17.3293 8.16261 17.2018 8.24251 17.045C8.33333 16.8667 8.33333 16.6334 8.33333 16.1667V13C8.33333 12.5333 8.33333 12.2999 8.24251 12.1217C8.16261 11.9649 8.03513 11.8374 7.87833 11.7575C7.70007 11.6667 7.46671 11.6667 7 11.6667Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>     
        {{ 'toggle-button.grid' | translate }}
    </oes-button>
  </ng-container>
</div>
