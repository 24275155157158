<ng-container *ngIf="!reload">
  <div class="d-flex" [ngClass]="checkOrientation()">
    <div class="d-flex justify-content-start align-items-center"
        *ngFor="let item of sliderItems?.slice().reverse(); let i = index; trackBy: trackById"
        [ngClass]="item.name | camelCase">
      <div class="title-pill">
        <span class="title"
              (click)="toggleItem(item.name, sliderItems?.length - 1 - i)"
              [ngClass]="{'disabled': item.isDisabled}">{{item.name}}</span>
      </div>
      <ng-container *ngIf="showSlider">
        <nouislider
          *ngIf="config"
          [config]="config"
          [disabled]="item.isDisabled || readOnly"
          [(ngModel)]="item.scale"
          (ngModelChange)="onSlide($event, sliderItems?.length - 1 - i)">
        </nouislider>
        <div class="current-value">
          <input type="text" name="slider-{{item.name | camelCase}}"
                [ngModel]="item.scale/100 | percent"
                (ngModelChange)="item.scale=inputPercent($event)"
                [disabled]="readOnly">
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
