import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { BreadcrumbItem } from "./breadcrumb-item.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private currentUrlUpdatedSubject = new BehaviorSubject<string>('');

  private _breadcrumbHistory: BreadcrumbItem[] = [];
  private _currentUrl: string;
  private _previousUrl: string;
  private _defaultPrependedCrumb = new BreadcrumbItem('main-navigation.home', '/oes/home');
  private _prependedCrumbs: BreadcrumbItem[] = [this._defaultPrependedCrumb];
  private _generatedCrumbIndex = 0;

  currentUrlUpdated$ = this.currentUrlUpdatedSubject.asObservable();
  currentBreadcrumbs: BreadcrumbItem[] = [];

  constructor(private router: Router) {
    this._currentUrl = this.router.url.split('?')[0];
    this.currentUrlUpdatedSubject.next(this._currentUrl);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._previousUrl = this._currentUrl;
        this.breadcrumbHistory = this.currentBreadcrumbs;
      }
      if (event instanceof NavigationEnd) {
        this._currentUrl = event.urlAfterRedirects.split('?')[0];
        this.currentUrlUpdatedSubject.next(this._currentUrl);
      }
    });
  }

  checkIfCurrentUrlIsPrepended() {
    const isPrepended = this._prependedCrumbs.find(crumb => crumb.url === this._currentUrl);
    if (isPrepended) {
      this.resetPrependedCrumbs();
    }
  }

  resetPrependedCrumbs() {
    this._prependedCrumbs = [this._defaultPrependedCrumb];
    this._generatedCrumbIndex = 0;
  }

  sameAsPreviousUrl(): boolean {
    const currentUrlWithoutParams = this._currentUrl ? this._currentUrl.split('?')[0] : '';
    return this._previousUrl === currentUrlWithoutParams;
  }

  get previousUrl(): string | null {
    return this._previousUrl;
  }

  get breadcrumbHistory(): BreadcrumbItem[] {
    return this._breadcrumbHistory;
  }

  set breadcrumbHistory(breadcrumbHistory: BreadcrumbItem[]) {
    this._breadcrumbHistory = breadcrumbHistory;
  }

  get prependedCrumbs(): BreadcrumbItem[] {
    return this._prependedCrumbs;
  }

  set prependedCrumbs(prependedCrumbs: BreadcrumbItem[]) {
    this._prependedCrumbs = [this._defaultPrependedCrumb, ...prependedCrumbs];
  }

  get generatedCrumbIndex(): number {
    return this._generatedCrumbIndex;
  }

  set generatedCrumbIndex(index: number) {
    this._generatedCrumbIndex = index;
  }
}
