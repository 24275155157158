<div class="files" *ngIf="_tagTypes">
  <h2>{{ title }}</h2>
  <ng-container *ngIf="!printable">
    <div class="action-buttons"
        [ngClass]="{'icon': mode === 'icon'}">
      <oes-files-action
        [accessControl]="accessControl"
        [readOnly]="sharedFiles">
      </oes-files-action>
      <div class="sub-controller" [ngClass]="{ 'sub-controller-read-only' : sharedFiles }">
        <div class="ml-auto selections-container">
          <input type="checkbox"
                  [indeterminate]="expandState === 'indeterminate'"
                  [checked]="expandState === 'expanded'"
                  (click)="toggleExpand()" />{{ 'buttons.expand-all-folders' | translate }}
        </div>
      </div>
      <div class="d-flex justify-content-end w-100 sub-action-container">
        <oes-grid-sub-action
          *ngIf="gridApi"
          [gridState]="gridState"
          [defaultGridState]="defaultGridState"
          (action)="subAction($event)">
        </oes-grid-sub-action>
      </div>
    </div>
  </ng-container>

  <!-- Grid -->
  <div class="ag-grid-header-sticky" oesAgHorizontalScroll>
    <ag-grid-angular
      #agGrid
      id="filesGrid"
      style="width: auto;"
      [ngStyle]="{ 'height': !autoHeightOverride ? 'calc(100vh - ' + removeHeight + ')' : 'unset' }"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (rowGroupOpened)="onStateChange($event)"
      (rowClicked)="selectionChanged($event)"
      (rowDragEnd)="onRowDragEnd($event)"
      (selectionChanged)="selectionChanged($event)"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>

<ng-container *ngIf="!printable">
  <!-- Create folder modal dialog -->
  <oes-create-folder
    [organizationId]="organizationId"
    [tagTypes]="_tagTypes">
  </oes-create-folder>

  <!-- Upload -->
  <oes-files-uploader
    #uploadModalDialog
    (uploadProcessCompleted)="uploadProcessCompleted.emit($event)"
    [tagTypes]="_tagTypes"
    [extraMessage]="uploadExtraMessage">
  </oes-files-uploader>

  <!-- Copy -->
  <oes-copy
    #copyModalDialog
    [organizationId]="organizationId"
    [type]="'copy'"
    [tagTypes]="_tagTypes">
  </oes-copy>

  <!-- Move -->
  <oes-copy
    #moveModalDialog
    [organizationId]="organizationId"
    [type]="'move'"
    [tagTypes]="_tagTypes">
  </oes-copy>

  <oes-delete>
  </oes-delete>

  <oes-association-add
    #associationAddModalDialog>
  </oes-association-add>

  <oes-association-delete
    #associationDeleteModalDialog>
  </oes-association-delete>

  <oes-tag-add
    #tagAddModalDialog
    *ngIf="dataCompleted">
  </oes-tag-add>

  <oes-tag-delete
    #tagDeleteModalDialog>
  </oes-tag-delete>

  <oes-edit-name
    #editNameDialog
    [docPathList]="docPathList">
  </oes-edit-name>
</ng-container>
