import { Injectable } from '@angular/core';
import { ProjectListType } from '@project/shared-projects-list/project-list-type.enum';
import { ColumnConfigFactory } from '@project/shared/grid/column-config-factory';
import { ProjectType } from '@project/shared/project-type.enum';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class MyProjectGridSettingService extends BaseAgGridSettingsService {
  private column = [];
  private projectTypes = {};

  public columnValues = [];
  public operatorValues = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _columnConfigFactory: ColumnConfigFactory) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('projects.column');
    this.projectTypes = _agGridServicesWrapperService._translateService.instant('project.types');
  }

  private getPageByProjectType(listType: ProjectListType, params, icon: boolean) {
    let page = 'planning/summary';
    if (params.data?.projectType === ProjectType.ci) {
      page = 'overview';
    }
    if (listType === ProjectListType.CLAIMS) {
      page = 'connections';
    }
    if (params?.data) {
      if (icon) {
        return `<a class="hyperlink" href="#/oes/projects/my-projects/${params.data.id}/${page}" title="Open ${params.data.name}"><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i></a>`;
      }
      return `<a class="hyperlink" href="#/oes/projects/my-projects/${params.data.id}/${page}" title="Open ${params.data.name}">${params.data.name}</a><p style="margin: 0;">${this.projectTypes[params.data?.projectType]}</p>`;
    }
  }

  public getGridOptions(listType: ProjectListType): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(listType),
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      suppressRowClickSelection: true,
      rowMultiSelectWithClick: false,
      groupHideOpenParents: true,
      groupSelectsChildren: true,
      rowSelection: 'multiple',
      getRowHeight: (params) => {
        if (params.node.rowHeight > 80) {
          return params.node.rowHeight + 10;
        }
        return 80;
      },
      rowClass: 'row-with-tags',
      suppressDragLeaveHidesColumns: false,
      rowGroupPanelShow: 'always',
      enableRangeSelection: true,
      autoGroupColumnDef: {
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true
        }
      },
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      sideBar: {
        toolPanels: ['columns']
      },
      // to access 'this' from cellRenderer/valueFormatter
      context: {
        currentLang: this.currentLang
      },
      components: {
        agDateInput: DateFilterComponent
      },
      localeText: this.globalTranslations
    };
  }

  // Column Properties
  // https://www.ag-grid.com/javascript-grid-column-properties/
  public getColumnSetting(listType: ProjectListType): any {
    const columnConfigs = this._columnConfigFactory;
    const colDefs = [
      columnConfigs.get('checkbox'),
      {
        headerName: this.column['project-name'],
        headerTooltip: this.column['project-name'],
        field: 'name',
        width: 300,
        unSortIcon: true,
        enablePivot: true,
        enableRowGroup: true,
        enableValue: true,
        pinned: 'left',
        suppressSizeToFit: true,
        cellRenderer: (params) => {
          return this.getPageByProjectType(listType, params, false);
        },
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        resizable: true
      },
      columnConfigs.get('latitude'),
      columnConfigs.get('longitude'),
      columnConfigs.get('country'),
      columnConfigs.get('region'),
      columnConfigs.get('totalCustomers'),
      columnConfigs.get('totalPV'),
      columnConfigs.get('subsidyAmountPerConnection'),
      columnConfigs.get('irr'),
      columnConfigs.get('payback'),
      columnConfigs.get('lcoe'),
      columnConfigs.get('totalCapex'),
      columnConfigs.get('capexPerConnection'),
      columnConfigs.get('storage'),
      columnConfigs.get('generator'),
      columnConfigs.get('cod'),
      columnConfigs.get('submittedToProgramDateTime'),
      columnConfigs.get('status'),
      columnConfigs.get('projectType'),
      columnConfigs.get('arpuResidential'),
      columnConfigs.get('arpuCommercial'),
      columnConfigs.get('arpuPublic'),
      columnConfigs.get('arpuProductive'),
      columnConfigs.get('arpuAnchor'),
      columnConfigs.get('equityIRR'),
      columnConfigs.get('equityPayback')
    ];

    switch (listType) {
      case ProjectListType.ASSETS:
        break;
      case ProjectListType.CLAIMS:
        break;
      case ProjectListType.MAIN:
        colDefs.splice(17, 0, columnConfigs.get('program'));
        colDefs.splice(28, 0, columnConfigs.get('inProgressMilestones'));
        colDefs.splice(29, 0, columnConfigs.get('completedMilestones'));
        colDefs.splice(30, 0, columnConfigs.get('latestCompletedMilestone'));
        colDefs.splice(31, 0, columnConfigs.get('latestMilestoneUpdate'));
        break;
      default:
        break;
    }

    return colDefs;
  }
}
