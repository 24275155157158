<h2>{{ 'program.configuration.financial-model.title' | translate }}</h2>
<p class="sub-grey">{{ 'program.configuration.financial-model.subtitle' | translate }}</p>

<button class="btn btn-primary ml-auto" (click)="openCreateEditModal()">
  {{ 'buttons.create-new' | translate }}
</button>

<ag-grid-angular
  id="organizationFinancialModels"
  class="grid ag-theme-alpine"
  [gridOptions]="gridOptions"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

<!-- Create/Edit Modal -->
<oes-modal-dialog
  #createEditModal
  [size]="'medium'"
  (close)="closeModal()"
  [allowDelete]="selectedOrganizationFinancialModel?.type === 'PRIVATE'"
  [deleteButtonText]="'buttons.delete' | translate"
  [allowSubmit]="validForm()"
  (submit)="createEditModel()"
  (delete)="deleteModel($event)"
  [title]="'program.configuration.financial-model.create' | translate">
  <form [formGroup]="modelFormGroup">

    <ng-container *ngIf="isModalShown">
      <!-- Name -->
      <div class="input">
        <label for="name" class="required label">{{ 'program.configuration.financial-model.name' | translate }}</label>
        <input type="text"
                class="mb-2"
                id="name"
                formControlName="name" />
      </div>

      <!-- Description -->
      <div class="input">
        <label class="label" for="description">{{ 'program.configuration.financial-model.description' | translate }}</label>
        <textarea
          id="description"
          maxlength="500"
          formControlName="description">
        </textarea>
      </div>

      <!-- Project Model Type (Minigrid or C&I) -->
      <div class="input">
        <label class="label" for="projectModelType">{{ 'program.configuration.financial-model.project-model-type' | translate }}</label>
        <oes-dropdown
          [parentFormGroup]="modelFormGroup"
          [items]="submissionTypes"
          [controlName]="'projectModelType'"
          name="projectModelType"
          [required]="true"
          [initialSelections]="[selectedProjectModelType]"
          [enableSearch]="false"
          [selection]="'single'">
        </oes-dropdown>
      </div>

      <!-- Upload -->
      <div class="input">
        <ng-container *ngIf="editMode && file?.name">
          <p>{{ 'program.configuration.financial-model.warning' | translate }}</p>
        </ng-container>
        <div class="file-name">{{ file?.name }}</div>
        <label class="btn btn-primary-light">
          {{ 'program.configuration.financial-model.select-model' | translate }}
          <input
            #fileInput
            type="file"
            (change)="changeFileInput($event)"
            accept=".xlsx"
            style="display: none;">
        </label>
      </div>
    </ng-container>

  </form>
</oes-modal-dialog>
