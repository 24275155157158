import { Component, Input, OnInit } from '@angular/core';
import { Evaluation } from '../../evaluation/evaluation.model';
import { ColumnApi, GridOptions } from 'ag-grid-community';
import { EvaluationsDisplayGridSettingService } from './evaluations-display-grid-setting-service';
import { PASS_FAIL } from '@program/shared/evaluation/pass-fail.enum';
import { EvaluationQuestionType } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-question-type.enum';

@Component({
  selector: 'oes-evaluations-display',
  templateUrl: './evaluations-display.component.html',
  styleUrls: ['./evaluations-display.component.scss']
})
export class EvaluationsDisplayComponent implements OnInit {
  @Input() evaluations: Evaluation[];

  columnApi: ColumnApi;
  gridOptions: GridOptions;
  totals: {
    passFail: PASS_FAIL,
    totalScore: number
  };

  private gridApi: any;

  constructor(private _gridSettingService: EvaluationsDisplayGridSettingService) {
    this.gridOptions = this._gridSettingService.getGridOptions();
  }

  ngOnInit(): void {
    this.calculateTotals();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._gridSettingService.setGridApi(params.api, params.columnApi);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setRowData(this.evaluations);
  }

  private calculateTotals() {
    this.totals = this.evaluations.reduce((accum, evaluation) => {
      if (evaluation.evaluationConfig.type === EvaluationQuestionType.PASS_FAIL) {
        if (evaluation.boolValue !== null) {
          if (evaluation.boolValue === true && accum.passFail !== PASS_FAIL.FAIL) {
            accum.passFail = PASS_FAIL.PASS;
          }
          if (evaluation.boolValue === false) {
            accum.passFail = PASS_FAIL.FAIL;
          }
        }
      }
      if (evaluation.evaluationConfig.type === EvaluationQuestionType.SCORE) {
        if (evaluation.scoreValue !== null && evaluation.scoreValue !== undefined) {
          accum.totalScore += ((evaluation.scoreValue / evaluation.evaluationConfig.rangeEnd) * evaluation.evaluationConfig.weight);
        }
      }
      return accum;
    }, {  passFail: null, totalScore: 0 });
  }
}
