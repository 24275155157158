import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'oes-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideDrawerComponent {
  @Input() isOpen: boolean;
  @Output() close = new EventEmitter<void>();

  closeDrawer() {
    this.close.emit();
  }
}
