import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';

@Injectable()
export class SubmitBitService extends BaseRestApiService  {
  public projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  // public buildUri(): URL {
  //   return new URL(environment.serverUri + this._configuration.projectGroupUri);
  // }

  /**
   * @deprecated /publish-tender
   * TODO: replace with /publish-program
   */
  public submitBid(projectGroupId: string): Observable<any> {
    const url = `${this.projectGroups}/${projectGroupId}/publish-tender`;
    // let fullUrl: string = url.href + projectGroupId + '/publish-tender';
    // fullUrl = this.cleanUrl(new URL(fullUrl)).href;
    return this.post<any>(url, JSON.stringify({}));
  }

  public resubmitBid(projectGroupId: string) {
    const url = `${this.projectGroups}/${projectGroupId}/re-publish-tender`;
    // let fullUrl: string = url.href + projectGroupId + '/re-publish-tender';
    // fullUrl = this.cleanUrl(new URL(fullUrl)).href;
    return this.put(url, JSON.stringify({}));
  }

  /**
   * @deprecated /withdraw-tender-bid
   * TODO: replace with /withdraw-program-bid
   */
  public withdrawBid( projectGroupId: string) {
    const url = `${this.projectGroups}/${projectGroupId}/withdraw-tender-bid`;
    // let fullUrl: string = url.href + projectGroupId + '/withdraw-tender-bid';
    // fullUrl = this.cleanUrl(new URL(fullUrl)).href;
    return this.delete(url);
  }
}
