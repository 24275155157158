import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { CONNECTIONS_PAYOUT_ORIGIN } from './connections-payout-origin.enum';
import { CONNECTIONS_PAYOUT_TYPE } from './connections-payout-type.enum';
import { ConnectionsPayout } from './connections-payout.model';
import { ConnectionsPayoutGridSettingService } from './connections-payout-grid-setting.service';
import { ConnectionsPayoutService } from './connections-payout.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { Project } from '@project/shared/project.model';
import { ROLE_TYPE } from '@user/role-type';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from '@user/user.service';
import { GridState } from '@shared/ag-grid/gird-state';

@Component({
  selector: 'oes-connections-payout',
  templateUrl: './connections-payout.component.html',
  styleUrls: ['./connections-payout.component.scss']
})
export class ConnectionsPayoutComponent implements OnInit, OnDestroy {
  @ViewChild('makePayoutModal', { static: false }) makePayoutModal: ModalDialogComponent;
  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDialogComponent;
  @ViewChild('applyExchangeRateModal', { static: false }) applyExchangeRateModal: ModalDialogComponent;
  @Input() project: Project;
  @Input() currentRole: ROLE_TYPE;
  @Output() newPayoutComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  columnApi: ColumnApi;
  connectionsPayoutForm: UntypedFormGroup;
  defaultGridState: GridState;
  gridApi: GridApi;
  gridOptions: GridOptions;
  gridState: GridState;
  payouts: ConnectionsPayout[];
  payoutTypes = Object.keys(CONNECTIONS_PAYOUT_TYPE);
  programOwner: boolean;

  private gridStateStorageKey: string;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _connectionsPayoutService: ConnectionsPayoutService,
              private _connectionsPayoutGridSettingService: ConnectionsPayoutGridSettingService,
              private _userService: UserService) {
    this.gridOptions = this._connectionsPayoutGridSettingService.getGridOptions();
  }

  ngOnInit() {
    this.gridStateStorageKey = this._connectionsPayoutGridSettingService.buildGridStateStorageKey('connectionsPayout', this.project.id);
    this.formInit();
    this.setPayoutPermissions();
  }

  setPayoutPermissions() {
    const orgId = this._userService.organizationId;
    if (this.project?.program?.sponsor?.id === orgId) {
      this.programOwner = true;
    } else {
      this.programOwner = false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private formInit() {
    this.connectionsPayoutForm = new UntypedFormGroup({
      name: new UntypedFormControl(null),
      connectionsPaid: new UntypedFormControl(null),
      connectionsApproved: new UntypedFormControl(null),
      payoutAmount: new UntypedFormControl(null, { validators: Validators.required }),
      payoutType: new UntypedFormControl(null, { validators: Validators.required }),
      totalClaimPercent: new UntypedFormControl(null, { validators: Validators.required }),
      payoutAmountInCurrency: new UntypedFormControl(null, { validators: Validators.required }),
      notes: new UntypedFormControl(null)
    });

    this.subscribeForm();
  }

  private subscribeForm() {
    this.connectionsPayoutForm.controls['connectionsPaid'].valueChanges.subscribe(value => {
      this.connectionsPayoutForm.controls['connectionsApproved'].setValue(value);
    });
  }


  updateFormValues(event: any) {
    switch (event.target.id) {
      case 'payout-amount':
        this.connectionsPayoutForm.controls['totalClaimPercent'].setValue(
          (this.connectionsPayoutForm.controls['payoutAmount'].value / this.project.connectionsAmountPotential * 100).toFixed(2));
        this.connectionsPayoutForm.controls['payoutAmountInCurrency'].setValue(
          (this.connectionsPayoutForm.controls['payoutAmount'].value * this.project.projectPayoutExchangeRate).toFixed(2));
        break;
      case 'total-claim-percent':
        this.connectionsPayoutForm.controls['payoutAmount'].setValue(
          (this.project.connectionsAmountPotential * this.connectionsPayoutForm.controls['totalClaimPercent'].value / 100).toFixed(2));
        this.connectionsPayoutForm.controls['payoutAmountInCurrency'].setValue(
          (this.connectionsPayoutForm.controls['payoutAmount'].value * this.project.projectPayoutExchangeRate).toFixed(2));
        break;
      case 'payout-amount-in-currency':
        this.connectionsPayoutForm.controls['payoutAmount'].setValue(
          (this.connectionsPayoutForm.controls['payoutAmountInCurrency'].value /  this.project.projectPayoutExchangeRate));
        this.connectionsPayoutForm.controls['totalClaimPercent'].setValue(
          (this.connectionsPayoutForm.controls['payoutAmount'].value / this.project.connectionsAmountPotential * 100).toFixed(2));
        break;
      default:
        break;
    }
  }

  onPayoutFocus(event: any) {
    switch (event.target.id) {
      case 'payout-amount':
        this.connectionsPayoutForm.controls['totalClaimPercent'].disable();
        this.connectionsPayoutForm.controls['payoutAmountInCurrency'].disable();
        break;
      case 'total-claim-percent':
        this.connectionsPayoutForm.controls['payoutAmountInCurrency'].disable();
        this.connectionsPayoutForm.controls['payoutAmount'].disable();
        break;
      case 'payout-amount-in-currency':
        this.connectionsPayoutForm.controls['totalClaimPercent'].disable();
        this.connectionsPayoutForm.controls['payoutAmount'].disable();
        break;
      default:
        break;
    }
  }

  onPayoutBlur(event: any) {
    this.connectionsPayoutForm.controls['totalClaimPercent'].enable();
    this.connectionsPayoutForm.controls['payoutAmountInCurrency'].enable();
    this.connectionsPayoutForm.controls['payoutAmount'].enable();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._connectionsPayoutGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._connectionsPayoutGridSettingService.buildDefaultGridState();
    this.getConnectionsPayouts(this.project.id);
  }

  getConnectionsPayouts(projectId: string) {
    this._connectionsPayoutService.getConnectionsPayoutsByProjectId(projectId)
    .pipe(take(1))
    .subscribe((payouts: ConnectionsPayout[]) => {
      this.payouts = payouts.map(payout => new ConnectionsPayout(payout));
      this.setDataToGrid(this.payouts);
    });
  }

  private setDataToGrid(payouts: ConnectionsPayout[]) {
    if (payouts && payouts.length > 0) {
      this.gridApi.showLoadingOverlay();
      this._connectionsPayoutGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      this.gridApi.setRowData(payouts);
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  parseFloat(value: string) {
    return parseFloat(value);
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._connectionsPayoutGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._connectionsPayoutGridSettingService.exportCsv(this.gridApi, 'connectionsPayout');
        break;
      case GridSubAction.reload:
        this.getConnectionsPayouts(this.project.id);
        break;
      case GridSubAction.clearFilter:
        this._connectionsPayoutGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  onSubmit(event: any) {
    this.confirmationModal.show();
  }

  onConfirm(event: any) {
    const payout = this.buildPayout();
    this._connectionsPayoutService.createConnectionsPayout(this.project.id, payout)
    .pipe(take(1))
    .subscribe(response => {
      this.newPayoutComplete.emit(true);
      this.getConnectionsPayouts(this.project.id);
      this.confirmationModal.hide();
      this.makePayoutModal.hide();
      this.connectionsPayoutForm.reset();
    });
  }

  onConfirmApply(event: any) {
    this._connectionsPayoutService.applyExchangeRate(this.project.id)
    .pipe(take(1))
    .subscribe(response => {
      this.newPayoutComplete.emit(true);
      this.getConnectionsPayouts(this.project.id);
      this.applyExchangeRateModal.hide();
    });
  }

  private buildPayout(): ConnectionsPayout {
    const payout = new ConnectionsPayout({});
    payout.connectionsPaid = this.connectionsPayoutForm.controls['connectionsPaid'].value;
    payout.connectionsApproved = this.connectionsPayoutForm.controls['connectionsApproved'].value;
    payout.name = this.connectionsPayoutForm.controls['name'].value;
    payout.notes = this.connectionsPayoutForm.controls['notes'].value;
    payout.payoutAmount = this.connectionsPayoutForm.controls['payoutAmount'].value;
    payout.payoutOrigin = CONNECTIONS_PAYOUT_ORIGIN.USER;
    payout.payoutType = this.connectionsPayoutForm.controls['payoutType'].value;
    payout.project = this.project;
    payout.totalClaimAmount = this.project.connectionsAmountPotential;
    return payout;
  }
}
