export enum BosCategory {
  'Generator' = 'GENERATOR_BOS_COST',
  'Solar PV' = 'SOLAR_PV_BOS_COST',
  'Storage' = 'STORAGE_BOS_COST',
  'Battery Inverter' = 'BATTERY_INVERTER_BOS_COST',
  'Solar PV Inverter' = 'PV_INVERTER_BOS_COST',
  'Other' = 'OTHER_BOS_COST',
  'Charger Controller' = 'CHARGE_CONTROLLER_BOS_COST',
  'System Inverter' = 'SYSTEM_INVERTER_BOS_COST'
}
