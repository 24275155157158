import {Injectable} from '@angular/core';
import {ProjectDistributionDesign} from './project-distribution-design.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDistributionDesignService extends BaseRestApiService {
  public projectsUri = environment.serverUri + `api/${environment.apiVersion}/projects`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(projectId: string): Observable<ProjectDistributionDesign[]> {
    return this.get<ProjectDistributionDesign[]>(
      `${this.projectsUri}/${projectId}/distribution-designs`,
      {params: {page: 0, size: 1000}}
    ).pipe(
      map(results => results.map(result => new ProjectDistributionDesign(result)))
    );
  }
}
