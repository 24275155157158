import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormGroup,
         AbstractControl, ValidationErrors } from '@angular/forms';
import { FinanceBusinessPlan } from '@finance/developer-portfolio/business-plan/finance-business-plan.model';

@Component({
  selector: 'oes-business-plan-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessPlanCustomersComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessPlanCustomersComponent),
      multi: true
    }
  ]
})
// TODO: may need to add ControlValueAccessor to implements
export class BusinessPlanCustomersComponent implements OnInit {
  @Input() financeBusinessPlan: FinanceBusinessPlan;
  @Input() isPastSubmissionDeadline = false;

  public customersFormGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit() {
    this.customersFormGroup = new UntypedFormGroup({
      customerTypeDescription: new UntypedFormControl(''),
      customerRelationsDescription: new UntypedFormControl(''),
      customerEducationDescription: new UntypedFormControl(''),
      customerPricingDescription: new UntypedFormControl('')
    });
  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    if (val) {
      this.customersFormGroup.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.customersFormGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customersFormGroup.disable() : this.customersFormGroup.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.customersFormGroup.valid ? null : {invalidForm: {valid: false, message: 'Invalid'}};
  }
}
