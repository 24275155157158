import { Injectable } from '@angular/core';

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { take } from 'rxjs/operators';
import { ExtensionToFiletypeService } from '@shared/services/extension-to-filetype.service';
import { TextFilterOption } from '@shared/components/files/shared/text-filter-option.enum';
import { TagType } from '../shared/models/tag-type.model';
import { DOCUMENT_TAG_TYPE } from '../shared/document-tag-type.enum';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { GridState, OpenState } from '@shared/ag-grid/gird-state';
import { StorageMap } from '@ngx-pwa/local-storage';
import { FilesService } from '../shared/files.service';
import { FileTagFilterComponent } from '@shared/ag-grid/component/file-tag-filter/file-tag-filter.component';

// Do not use global provider for ag-grid
@Injectable()
export class FilesGridSettingService extends BaseAgGridSettingsService {
  _columnApi: ColumnApi;
  _gridApi: GridApi;
  _gridStateStorageKey: string;

  allowDrag = false;
  columnValues = [];
  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();
  operatorValues = [];
  sharedFiles: boolean = false;

  private column = [];
  private responses = {};
  public openState = '_openState';
  private storageMap: StorageMap;
  private textFilterOptions = [
    TextFilterOption.contain,
    TextFilterOption.notContain,
    TextFilterOption.equal,
    TextFilterOption.notEqual,
  ];

  constructor(
    _agGridServicesWrapperService: AgGridServicesWrapperService,
    private _filesService: FilesService,
    private _extensionToFiletypeService: ExtensionToFiletypeService
  ) {
    super(_agGridServicesWrapperService);
    _agGridServicesWrapperService._translateService
      .get('data-room.column')
      .pipe(take(1))
      .subscribe((column) => {
        this.column = column;
      });
    this.storageMap = _agGridServicesWrapperService._storageMap;
  }

  public getGridOptions(
    tagTypes: TagType[],
    printable: boolean,
    sharedFiles: boolean,
    childDataRoom: boolean
  ): any {
    const result = {
      animateRows: false,
      autoGroupColumnDef: {
        headerName: this.column['sharedDocumentPath'],
        headerTooltip: this.column['sharedDocumentPath'],
        width: 350,
        unSortIcon: true,
        cellRendererParams: {
          checkbox: false,
          suppressCount: true,
          innerRenderer: 'fileCellRenderer',
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
        },
        rowDrag: this.allowDrag,
      },
      columnDefs: this.getColumnSetting(tagTypes, printable, sharedFiles, childDataRoom),
      components: {
        fileCellRenderer: this.getFileCellRenderer(),
        fileTagFilterComponent: FileTagFilterComponent,
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true,
        autoheight: true,
      },
      getDataPath: (data) => {
        if (sharedFiles && data?.sharedDisplayPath?.length) {
          return data.sharedDisplayPath.split('/');
        } else {
          return data?.path?.split('/');
        }
      },
      getRowId: (params) => {
        return params.data.id;
      },
      getRowHeight: (params) => {
        return params.node.rowHeight + 10;
      },
      rowClass: 'row-with-tags',
      groupDefaultExpanded: -1, // 0: expand none, 1: first level..., -1: expand all
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      rowMultiSelectWithClick: true,
      rowSelection: 'multiple',
      suppressColumnMoveAnimation: true,
      suppressContextMenu: true,
      suppressRowClickSelection: true,
      treeData: true,
    };

    return result;
  }

  // For innerRenderer of ag-grid grouping
  private getFileCellRenderer() {
    let eGui;
    function FileCellRenderer() {}
    FileCellRenderer.prototype.init = (params) => {
      const tempDiv = document.createElement('div');
      if (params.data) {
        if (params.data.documentKey.docType === 'PATH') {
          tempDiv.innerHTML = `<span class="ag-folder-display">${params.value} (${
            params.node?.childrenAfterGroup?.length ? params.node.childrenAfterGroup?.length : 0
          })</span>`;
        } else if (params.data.documentKey.docType === 'FILE') {
          const fileName = params.value.split('.');
          const ext = fileName[fileName.length - 1];
          tempDiv.innerHTML = `<span class="ag-file-display">${params.value}</span>`;
        }
      } else {
        tempDiv.innerHTML = `<span class="ag-folder-display">${params.value} (${
          params.node?.childrenAfterGroup?.length ? params.node.childrenAfterGroup?.length : 0
        })</span>`;
      }
      eGui = tempDiv.firstChild;
    };
    FileCellRenderer.prototype.getGui = () => {
      return eGui;
    };
    return FileCellRenderer;
  }

  // Column Properties
  // https://www.ag-grid.com/javascript-grid-column-properties/
  public getColumnSetting(
    tagTypes: TagType[],
    printable: boolean,
    sharedFiles: boolean,
    childDataRoom: boolean
  ): any[] {
    const colDef = [
      // Tag
      {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params?.data ? true : false;
        },
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left',
      },
      // Uploaded Date
      {
        headerName: this.column['upload-date'],
        headerTooltip: this.column['upload-date'],
        field: 'created',
        width: 150,
        filterParams: {
          comparator: this.dateComparator,
          clearButton: true,
        },
        cellStyle: { 'text-align': 'right' },
        filter: 'agDateColumnFilter',
        unSortIcon: true,
        cellRenderer: (params: any) => {
          if (params.value && params.data.documentKey.docType === 'FILE') {
            if (printable) {
              return this.getLocalDate(params, 'dd MMMM yyyy');
            }
            return this.getLocalDate(params, 'dd MMMM yyyy HH:mm');
          }
          return '-';
        },
      },
      // Form Upload
      {
        headerName: this.column['form-upload'],
        headerTooltip: this.column['form-upload'],
        field: 'formUpload',
        width: 160,
        cellStyle: { justifyContent: 'center' },
        valueFormatter: (params) => {
          if (params.value !== null) {
            return this.column['responses'][params.value];
          }
          return '';
        },
        filterParams: {
          clearButton: true,
          valueGetter: (params) => {
            return this.column['responses'][params?.data?.formUpload];
          },
        },
      },
    ];

    if (!printable) {
      // Size
      colDef.splice(1, 0, <any>{
        headerName: this.column['size'],
        headerTooltip: this.column['size'],
        field: 'size',
        width: 90,
        aggFunc: 'sum', // sum will show up 'Folder' row
        cellStyle: { 'text-align': 'right' },
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
        },
        unSortIcon: true,
        cellRenderer: (params) => this.formatFileSize(params),
      });
    }
    if (!sharedFiles) {
      colDef.splice(0, 0, <any>{
        headerName: this.column['tag'],
        headerTooltip: this.column['tag'],
        field: 'tags',
        unSortIcon: true,
        cellClass: 'tag-container',
        filter: 'agTextColumnFilter',
        filterParams: {
          applyButton: true,
          clearButton: true,
          filterOptions: this.textFilterOptions,
          // valueGetter response goes to this.textMatcher
          valueGetter: (param) => {
            if (
              param &&
              param.data &&
              param.data.tags &&
              param.data.tags.length > 0
            ) {
              return this.valueGetter(param, 'tags');
            }
          },
          textMatcher: this.textMatcher,
        },
        autoHeight: true,
        cellRenderer: this.tagRenderer,
      });
    }

    // Association. Investor can't see the association column from Portfolio > Data Room
    if (tagTypes && tagTypes[0].docTagType === DOCUMENT_TAG_TYPE.ORGANIZATION) {
      colDef.splice(0, 0, <any>{
        headerName: this.column['association'],
        headerTooltip: this.column['association'],
        field: 'associations',
        unSortIcon: true,
        cellClass: 'tag-container',
        filter: 'fileTagFilterComponent',
        filterParams: {
          debounceMs: 0,
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
        },
        autoHeight: true,
        width: 200,
        cellRenderer: this.associationRenderer,
      });
    }

    if (!childDataRoom) {
      colDef.splice(0, 0, <any>{
        headerName: this.column['project-tags'],
        headerTooltip: this.column['project-tags'],
        field: 'projectTags',
        unSortIcon: true,
        cellClass: 'tag-container',
        filter: 'fileTagFilterComponent',
        filterParams: {
          debounceMs: 0,
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
        },
        autoHeight: true,
        width: 200,
        cellRenderer: this.associationRenderer,
      });
      colDef.splice(0, 0, <any>{
        headerName: this.column['project-set-portfolio-tags'],
        headerTooltip: this.column['project-set-portfolio-tags'],
        field: 'projectSetPortfolioTags',
        unSortIcon: true,
        cellClass: 'tag-container',
        filter: 'fileTagFilterComponent',
        filterParams: {
          debounceMs: 0,
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
        },
        autoHeight: true,
        width: 200,
        cellRenderer: this.associationRenderer,
        cellRendererParams: {
          sharedFiles: sharedFiles,
        },
      });
      colDef.splice(0, 0, <any>{
        headerName: this.column['project-group-tags'],
        headerTooltip: this.column['project-group-tags'],
        field: 'projectGroupTags',
        unSortIcon: true,
        cellClass: 'tag-container',
        filter: 'fileTagFilterComponent',
        filterParams: {
          debounceMs: 0,
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
        },
        autoHeight: true,
        width: 200,
        cellRenderer: this.associationRenderer,
        cellRendererParams: {
          sharedFiles: sharedFiles,
        },
      });
      colDef.splice(0, 0, <any>{
        headerName: this.column['program-tags'],
        headerTooltip: this.column['program-tags'],
        field: 'programTags',
        unSortIcon: true,
        cellClass: 'tag-container',
        filter: 'fileTagFilterComponent',
        filterParams: {
          debounceMs: 0,
          newRowsAction: 'keep',
          applyButton: true,
          clearButton: true,
        },
        enableSorting: true,
        autoHeight: true,
        width: 200,
        cellRenderer: this.associationRenderer,
      });
    }


    return colDef;
  }

  private valueGetter(param, key) {
    const result = param.data[key].map((item) =>
      item.tag ? item.tag.toLowerCase() : ''
    );
    if (result && result.length > 0) {
      // don't return array because ag-grid will join with ','
      return result.join('$separate$');
    }
  }

  private tagRenderer(params) {
    if (
      params.colDef.field &&
      params.colDef.field !== '' &&
      params.data &&
      params.data[params.colDef.field] &&
      params.data[params.colDef.field].length > 0
    ) {
      const bullets = params.data[params.colDef.field].map((item) => {
        if (item.tag !== '') {
          return `<span class="bullet-tag ${params.colDef.field}">${item.tag}</span>`;
        }
      });
      if (bullets && bullets.length > 0) {
        return bullets.join('');
      }
    }
  }

  private associationRenderer(params) {
    if (
      params.colDef.field &&
      params.colDef.field !== '' &&
      params.data &&
      params.data[params.colDef.field] &&
      params.data[params.colDef.field].length > 0
    ) {
      const bullets = params.data[params.colDef.field].map((item) => {
        if (item.tag !== '') {
          switch (item.tagType) {
            case DOCUMENT_TAG_TYPE.PROJECT_SET_PORTFOLIO:
              if (params.sharedFiles) {
                return `<span class="bullet-tag ${params.colDef.field} hot-link"><a href="#/oes/finance/finance-ci-portfolios/${item.index}/project-overview">${item.tag}</a></span>`;
              } else {
                return `<span class="bullet-tag ${params.colDef.field} hot-link"><a href="#/oes/finance/ci-portfolios/${item.index}/project-overview">${item.tag}</a></span>`;
              }
            case DOCUMENT_TAG_TYPE.PROJECT_GROUP:
              if (params.sharedFiles) {
                return `<span class="bullet-tag ${params.colDef.field} hot-link"><a href="#/oes/finance/finance-marketplace/${item.index}/project-overview">${item.tag}</a></span>`;
              } else {
                return `<span class="bullet-tag ${params.colDef.field} hot-link"><a href="#/oes/finance/portfolios/${item.index}/project-overview">${item.tag}</a></span>`;
              }
            case DOCUMENT_TAG_TYPE.PROGRAM:
              return `<span class="bullet-tag ${params.colDef.field} hot-link"><a href="#/oes/finance/programs/${item.index}/home">${item.tag}</a></span>`;
            case DOCUMENT_TAG_TYPE.PROJECT:
              return `<span class="bullet-tag ${params.colDef.field} hot-link"><a href="#/oes/projects/my-projects/${item.index}/planning/information">${item.tag}</a></span>`;
            case DOCUMENT_TAG_TYPE.PRE_QUALIFICATION: // Can't guarantee correct program here so can't build link
            default:
              return `<span class="bullet-tag ${params.colDef.field}">${item.tag}</span>`;
          }
        }
      });
      if (bullets && bullets.length > 0) {
        return bullets.join('');
      }
    }
  }

  private textMatcher(params) {
    const valueArray = params.value
      .split('$separate$')
      .map((item) => item.toLowerCase());
    let result = false;
    const filterTextLower = params.filterText.toLowerCase();

    switch (params.filterOption) {
      case TextFilterOption.contain:
        result = !valueArray.every((item) => !item.includes(filterTextLower));
        break;
      case TextFilterOption.notContain:
        result = valueArray.every((item) => !item.includes(filterTextLower));
        break;
      case TextFilterOption.equal:
        result = valueArray.includes(filterTextLower);
        break;
      case TextFilterOption.notEqual:
        result = !valueArray.includes(filterTextLower);
        break;
    }
    return result;
  }

  // also call this from component
  public formatFileSize(params) {
    // if docType === PATH, it will be a total file size under the path.
    if (params.value) {
      // 1024 * 1024 = 1048576
      if (params.value > 0 && params.value < 1048576) {
        return Math.round(params.value / 1024) + ' KB';
      } else {
        return Math.round(params.value / 1048576) + ' MB';
      }
    }
    return '0 KB';
  }

  // Save collapse folders because folder is set open in getGridOptions
  public saveAllOpenState(expanded?: boolean) {
    // expanded
    if (expanded) {
      this.storageMap
        .delete(this._gridStateStorageKey + this.openState)
        .pipe(take(1))
        .subscribe(() => {});
    } else {
      // collapse
      if (this._gridApi && this._gridStateStorageKey) {
        const newOpenStates = [];
        this._gridApi.forEachNode((node) => {
          if (node?.allChildrenCount > 0) {
            newOpenStates.push({
              id: node.id,
              expanded: false,
            });
          }
        });
        // overwrite everything
        this.storageMap
          .set(this._gridStateStorageKey + this.openState, newOpenStates)
          .pipe(take(1))
          .subscribe(() => {});
      }
    }
  }

  // folder status
  public saveCloseState(row: OpenState) {
    if (this._gridStateStorageKey) {
      this.storageMap
        .get(this._gridStateStorageKey + this.openState)
        .pipe(take(1))
        .subscribe((result: any[]) => {
          let currentState = result;
          if (!currentState) {
            currentState = [];
          }
          // check the current saved data
          const index = currentState.findIndex(
            (item: OpenState) => item.id === row.id
          );

          if (index > -1) {
            currentState[index].expanded = row.expanded;
          } else {
            currentState.push(row);
          }
          if (currentState?.length > 0) {
            this.storageMap
              .set(this._gridStateStorageKey + this.openState, currentState)
              .pipe(take(1))
              .subscribe(() => {});
          } else if (currentState.length === 0) {
            this.storageMap
              .delete(this._gridStateStorageKey + this.openState)
              .pipe(take(1))
              .subscribe(() => {});
          }
        });
    }
  }

  public loadCloseState() {
    if (this._gridApi) {
      this.storageMap
        .get(this._gridStateStorageKey + this.openState)
        .pipe(take(1))
        .subscribe((openStates: OpenState[]) => {
          if (openStates?.length > 0) {
            openStates.forEach((row) => {
              this._gridApi.forEachNode((node) => {
                if (row.id === node.id) {
                  node.setExpanded(row.expanded);
                }
              });
              this._gridApi.onGroupExpandedOrCollapsed();
            });
          }
        });
    }
  }

  public findExpandState(): 'expanded' | 'collapsed' | 'indeterminate' {
    let totalNodes = 0;
    let displayedNodes = [];
    this._gridApi.forEachNode((node, index) => {
      totalNodes++;
      if (node.displayed) {
        displayedNodes.push(node);
      }
    });
    let allCollapsed = true;
    displayedNodes.forEach((node) => {
      if (node.data?.documentKey?.docType !== 'FILE' &&
          node.allChildrenCount !== null &&
          node.allChildrenCount > 0 &&
          node.expanded === true) {
        allCollapsed = false;
      }
    });
    if (displayedNodes.length === totalNodes) {
      return 'expanded';
    } else if (allCollapsed) {
      return 'collapsed';
    } else {
      return 'indeterminate';
    }
  }
}
