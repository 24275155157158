<div class="overview-actions">
  <h4>{{ title }}</h4>

  <ng-container *ngIf="required !== 'NOT_REQUIRED' && required">
    <span class="required">
      <img src="./assets/icons/line-icons/shared/green/check-verified.svg" />
      Required
    </span>
  </ng-container>

  <ng-container *ngIf="guideLink && projectOwner">
    <button type="button" class="btn btn-grey">
      <a [href]="guideLink" target="_blank">
        <img src="./assets/icons/line-icons/shared/grey/info.svg" />
        {{ 'buttons.view-guide' | translate }}
      </a>
    </button>
  </ng-container>

  <ng-container *ngIf="editLink && projectOwner">
    <button type="button" class="btn btn-primary-light ml-2" [routerLink]="editLink">
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.49998 15.6667H16M1 15.6667H2.39545C2.8031 15.6667 3.00693 15.6667 3.19874 15.6206C3.3688 15.5798 3.53138 15.5125 3.6805 15.4211C3.84869 15.318 3.99282 15.1739 4.28107 14.8856L14.75 4.41669C15.4404 3.72634 15.4404 2.60705 14.75 1.91669C14.0597 1.22634 12.9404 1.22634 12.25 1.91669L1.78105 12.3856C1.4928 12.6739 1.34867 12.818 1.2456 12.9862C1.15422 13.1353 1.08688 13.2979 1.04605 13.468C1 13.6598 1 13.8636 1 14.2713V15.6667Z"
              stroke="#EE7802" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {{ 'buttons.edit' | translate }}
    </button>
  </ng-container>
</div>
