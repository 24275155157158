import { Component, Input } from '@angular/core';
import { ImageCardItem } from './image-card-item.model';

@Component({
  selector: 'oes-image-cards',
  templateUrl: './image-cards.component.html',
  styleUrls: ['./image-cards.component.scss'],
  providers: []
})
export class ImageCardsComponent {
  @Input() content: ImageCardItem[];
  @Input() maxCardsPerRow: number = 4;
  @Input() headingSize: number = 20;

  constructor() {
  }
}
