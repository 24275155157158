import {BaseModel} from '@shared/base.model';
import {FinanceCapital} from './finance-capital.model';
import {FinanceAppliance} from './finance-appliance.model';
import {FinanceTier} from './finance-tier.model';
import {ProFormaDocument} from './finance-pro-forma-document.model';

export class FinanceIntroduction extends BaseModel<FinanceIntroduction> {
  additionalApplianceFinancing: number;
  additionalWorkingCapital: number;
  additionalHardwareDescription: string;
  coDevelopmentPartnership: boolean;
  customPaybackYears: number;
  customerSummaryNotes: string;
  dealDescription: string;
  grantSize: number;
  dealSize: number;
  dealStartDate: number;
  developmentCost: number;
  distributionCost: number;
  financingCost: number;
  generalCost: number;
  generationCost: number;
  generationNotes: string;
  keyAssumptionNotes: string;
  lastUpdatedDateTime: string;
  maximumIrr: number;
  maximumPayback: number;
  containsInfeasiblePayback: boolean;
  meteringType: string;
  minimumIrr: number;
  minimumPayback: number;
  otherCost: number;
  otherCostDescription: string;
  paybackYears: number;
  /**
   * @deprecated tenderBidPostedDateTime && tenderBidWithdrawDateTime
   */
  tenderBidPostedDateTime: Date;
  tenderBidWithdrawDateTime: Date;
  programBidPostedDateTime: Date;
  programBidWithdrawDateTime: Date;
  postedToMarketplaceDateTime: Date;
  proFormaDocuments: ProFormaDocument[];
  projectCost: number;
  projectCount: number;
  financeAppliances: FinanceAppliance[];
  financeCapitals: FinanceCapital[];
  financeTiers: FinanceTier[];
  removedFromMarketplaceDateTime: Date;
  representationType: string;
  statusOfCapitalDescription: string;
  totalBiomassSize: number;
  totalConverterSize: number;
  totalDieselSize: number;
  totalInverterSize: number;
  totalSolarSize: number;
  totalStorageSize: number;
  totalConnections: number;

  constructor(financeIntroductionInfo: any) {
    super();
    this.id = financeIntroductionInfo.id;
    this.additionalApplianceFinancing = financeIntroductionInfo.additionalApplianceFinancing;
    this.additionalWorkingCapital = financeIntroductionInfo.additionalWorkingCapital;
    this.additionalHardwareDescription = financeIntroductionInfo.additionalHardwareDescription;
    this.coDevelopmentPartnership = financeIntroductionInfo.coDevelopmentPartnership;
    this.customPaybackYears = financeIntroductionInfo.customPaybackYears;
    this.customerSummaryNotes = financeIntroductionInfo.customerSummaryNotes;
    this.dealDescription = financeIntroductionInfo.dealDescription;
    this.grantSize = financeIntroductionInfo.grantSize;
    if (!this.grantSize) {
      this.grantSize = 0;
    }
    this.dealSize = financeIntroductionInfo.dealSize;
    this.dealStartDate = financeIntroductionInfo.dealStartDate;
    this.developmentCost = financeIntroductionInfo.developmentCost;
    if (this.developmentCost == null) {
      this.developmentCost = 0;
    }
    this.distributionCost = financeIntroductionInfo.distributionCost;
    if (this.distributionCost == null) {
      this.distributionCost = 0;
    }
    this.financingCost = financeIntroductionInfo.financingCost;
    if (this.financingCost == null) {
      this.financingCost = 0;
    }
    this.generalCost = financeIntroductionInfo.generalCost;
    this.generationCost = financeIntroductionInfo.generationCost;
    this.generationNotes = financeIntroductionInfo.generationNotes;
    this.keyAssumptionNotes = financeIntroductionInfo.keyAssumptionNotes;
    this.lastUpdatedDateTime = financeIntroductionInfo.lastUpdatedDateTime;
    this.maximumIrr = financeIntroductionInfo.maximumIrr;
    this.maximumPayback = financeIntroductionInfo.maximumPayback;
    this.containsInfeasiblePayback = financeIntroductionInfo.containsInfeasiblePayback;
    this.meteringType = financeIntroductionInfo.meteringType;
    this.minimumIrr = financeIntroductionInfo.minimumIrr;
    this.minimumPayback = financeIntroductionInfo.minimumPayback;
    this.otherCost = financeIntroductionInfo.otherCost;
    if (this.otherCost == null) {
      this.otherCost = 0;
    }
    this.otherCostDescription = financeIntroductionInfo.otherCostDescription;
    this.paybackYears = financeIntroductionInfo.customPaybackYears;
    this.postedToMarketplaceDateTime = financeIntroductionInfo.postedToMarketplaceDateTime;
    this.projectCost = financeIntroductionInfo.projectCost;
    this.projectCount = financeIntroductionInfo.projectCount;
    this.removedFromMarketplaceDateTime = financeIntroductionInfo.removedFromMarketplaceDateTime;
    /**
     * @deprecated tenderBidPostedDateTime && tenderBidWithdrawDateTime
     * TODO: remove || statements from below
     */
    this.tenderBidPostedDateTime = financeIntroductionInfo.programBidPostedDateTime || financeIntroductionInfo.tenderBidPostedDateTime;
    this.tenderBidWithdrawDateTime = financeIntroductionInfo.programBidWithdrawDateTime || financeIntroductionInfo.tenderBidWithdrawDateTime;
    this.programBidPostedDateTime = financeIntroductionInfo.programBidPostedDateTime || financeIntroductionInfo.tenderBidPostedDateTime;
    this.programBidWithdrawDateTime = financeIntroductionInfo.programBidWithdrawDateTime || financeIntroductionInfo.tenderBidWithdrawDateTime;
    this.representationType = financeIntroductionInfo.representationType;
    this.statusOfCapitalDescription = financeIntroductionInfo.statusOfCapitalDescription;
    this.totalBiomassSize = financeIntroductionInfo.totalBiomassSize;
    this.totalConverterSize = financeIntroductionInfo.totalConverterSize;
    this.totalDieselSize = financeIntroductionInfo.totalDieselSize;
    this.totalInverterSize = financeIntroductionInfo.totalInverterSize;
    this.totalSolarSize = financeIntroductionInfo.totalSolarSize;
    this.totalStorageSize = financeIntroductionInfo.totalStorageSize;
    this.financeAppliances = [];
    this.financeCapitals = [];
    this.financeTiers = [];
    if (financeIntroductionInfo.financeAppliances != null && financeIntroductionInfo.financeAppliances.length > 0) {
      for (let i = 0; i < financeIntroductionInfo.financeAppliances.length; i++) {
        this.financeAppliances.push(new FinanceAppliance(financeIntroductionInfo.financeAppliances[i]));
      }
    }
    if (financeIntroductionInfo.financeCapitals != null && financeIntroductionInfo.financeCapitals.length > 0) {
      for (let i = 0; i < financeIntroductionInfo.financeCapitals.length; i++) {
        this.financeCapitals.push(new FinanceCapital(financeIntroductionInfo.financeCapitals[i]));
      }
    }
    if (financeIntroductionInfo.financeTiers != null && financeIntroductionInfo.financeTiers.length > 0) {
      for (let i = 0; i < financeIntroductionInfo.financeTiers.length; i++) {
        this.financeTiers.push(new FinanceTier(financeIntroductionInfo.financeTiers[i]));
      }
    }
    this.proFormaDocuments = [];
    if (financeIntroductionInfo.proFormaDocuments != null && financeIntroductionInfo.proFormaDocuments.length > 0) {
      for (let i = 0; i < financeIntroductionInfo.proFormaDocuments.length; i++) {
        this.proFormaDocuments.push(new ProFormaDocument(financeIntroductionInfo.proFormaDocuments[i]));
      }
    }
  }

  isRemoved() {
    return this.removedFromMarketplaceDateTime ? true : false;
  }
}
