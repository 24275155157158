<h2>{{ 'offtaker.manage.templates-heading' | translate }}</h2>
<p class="sub-grey">{{ 'offtaker.manage.templates-sub-heading' | translate }}</p>

<h4>{{ 'offtaker.manage.current-templates' | translate }}:</h4>
<ul>
  <li>{{ 'offtaker.manage.pre-construction' | translate }}</li>
  <li>{{ 'offtaker.manage.version' | translate }}: {{ currentPreConstructionTemplate?.modelVersion }}</li>
</ul>

<label class="btn btn-primary">
  {{ 'offtaker.manage.upload-preconstruction' | translate }}
  <input
    type="file"
    (change)="uploadModel($event)"
    accept=".xlsx"
    style="display: none;">
</label>
