import { Component, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ViewChildren } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { Project } from '@project/shared/project.model';
import { ROLE_TYPE } from '@user/role-type';
import { UserService } from '@user/user.service';

@Component({
  selector: 'oes-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss'],
})
export class ProjectMapComponent implements OnChanges, AfterViewInit {
  @ViewChild(GoogleMap, {static: false}) googleMap: GoogleMap;
  @ViewChildren(MapInfoWindow, {emitDistinctChangesOnly: false}) infoWindows: MapInfoWindow[];
  @Input() height: string = '500px';
  @Input() mapTypeControl: boolean = true;
  @Input() mapTypeId: string = 'satellite'; // google.maps.MapTypeId enum
  @Input() zoomControl = true;
  @Input() set projects (projects: any[]) {
    this.mapProjects = projects ? projects.filter(project => project.latitude && project.longitude) : [];
  };

  imagePath = `${window.location.origin}/assets/images/map-cluster/map-cluster`;
  mapOptions: google.maps.MapOptions = {};
  markerPositions: google.maps.LatLngLiteral[] = [];
  markerOption: google.maps.MarkerOptions = {draggable: false};
  url: string = '/#/oes/projects/my-projects/';

  private bounds: google.maps.LatLngBounds;
  private mapProjects: any[];

  constructor(private _userService: UserService) {
  }

  ngAfterViewInit() {
    this.googleMap?.fitBounds(this.bounds);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges?.projects?.currentValue) {
      this.generateHref();
      this.initMap();
    }
  }

  private generateHref() {
    if (window.location.hash.includes('my-projects')) {
      this.url = '/#/oes/projects/my-projects/';
    } else if (window.location.hash.includes('shared-projects')) {
      this.url = '/#/oes/projects/shared-projects/';
    } else if (window.location.hash.includes('oes/home')) {
      if (this._userService.getSelectedRole() === ROLE_TYPE.DEVELOPER_USER) {
        this.url = '/#/oes/projects/my-projects/';
      } else if (this._userService.getSelectedRole() === ROLE_TYPE.FINANCE_USER) {
        this.url = '/#/oes/projects/shared-projects/';
      }
    }
  }

  // TODO: move to a service
  private initMap(){
    this.markerPositions = this.mapProjects.map((project: Project) => {
      return {
        lat: project.latitude,
        lng: project.longitude
      };
    });
    let minLat = 0.0;
    let maxLat = 0.0;
    let minLon = 0.0;
    let maxLon = 0.0;
    this.mapProjects.forEach((project, index) => {
      if (index === 0) {
        minLat = project.latitude;
        maxLat = project.latitude;
        minLon = project.longitude;
        maxLon = project.longitude;
      } else {
        if (project.latitude < minLat) {
          minLat = project.latitude;
        }
        if (project.latitude > maxLat) {
          maxLat = project.latitude;
        }
        if (project.longitude < minLon) {
          minLon = project.longitude;
        }
        if (project.longitude > maxLon) {
          maxLon = project.longitude;
        }
      }
    });
    this.bounds = new google.maps.LatLngBounds(
      {lat: minLat, lng: minLon}, // sw
      {lat: maxLat, lng: maxLon}  // ne
    );
    this.mapOptions.center = this.bounds.getCenter().toJSON();
    this.mapSetup();
  }

  private mapSetup() {
    this.mapOptions.zoomControl = this.zoomControl;
    // this.mapOptions.zoom = 4;
    this.mapOptions.minZoom = 2;
    this.mapOptions.mapTypeId = this.mapTypeId;
    this.mapOptions.scaleControl = false;
    this.mapOptions.mapTypeControl = this.mapTypeControl;
    this.mapOptions.streetViewControl = false;
    this.mapOptions.scrollwheel = false;
    this.mapOptions.draggable = true;
    this.mapOptions.fullscreenControl = false;
  }

  // if we want to close map-info-windows automatically, use this
  public closeOthers(index: number) {
    this.infoWindows.forEach((info: MapInfoWindow, i: number) => {
      if (i !== index) {
        info.close();
      }
    });
  }
}
