<div bsModal #bidWithdrawalConfirmation="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     [config]="{backdrop:true,ignoreBackdropClick:true,keyboard:true}"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'project-group-introduction.withdraw-bid-header' | translate }}</h4>
        <button type="button" class="close pull-right" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{ 'project-group-introduction.withdraw-bid-text-1' | translate }}
          <strong> {{ 'project-group-introduction.withdraw-bid-text-2' | translate }}.</strong>
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeDialog()" aria-label="Cancel">
          {{ 'project-group-introduction.cancel' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="withdrawBid()" aria-label="Confirm">
          {{ 'project-group-introduction.withdraw' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
