<ng-container *ngIf="chart">
  <ng-container [ngSwitch]="chart?.type">

    <!-- Stacked Bar -->
    <ng-container *ngSwitchCase="'STACKED_BAR'">
      <oes-stacked-bar-chart [chart]="chart"
                             [chartColors]="chartColors">
      </oes-stacked-bar-chart>
    </ng-container>

    <!-- Pie -->
    <ng-container *ngSwitchCase="'PIE'">
      <oes-pie-chart [chart]="chart"
                     [chartColors]="chartColors">
      </oes-pie-chart>
    </ng-container>

    <!-- Horizontal Bar -->
    <ng-container *ngSwitchCase="'HORIZONTAL_BAR'">
      <oes-horizontal-bar-chart [chart]="chart"
                                [chartColors]="chartColors"
                                [currency]="currency">
      </oes-horizontal-bar-chart>
    </ng-container>

    <!-- Bar/Line -->
    <ng-container *ngSwitchCase="'BAR_LINE'">
      <oes-bar-line-chart [chart]="chart"
                          [chartColors]="chartColors">
      </oes-bar-line-chart>
    </ng-container>

  </ng-container>
</ng-container>
