import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Evaluation } from '../evaluation.model';
import { EvaluationService } from '../evaluation.service';
import { EvaluationQuestionType } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-question-type.enum';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { EventService } from '@shared/event.service';

@Component({
  selector: 'oes-evaluation-question',
  templateUrl: './evaluation-question.component.html',
  styleUrls: ['./evaluation-question.component.scss']
})
export class EvaluationQuestionComponent implements OnInit {
  @Input() evaluation: Evaluation;
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  boolValueInitial: IMultiSelectOption;
  EvaluationQuestionType = EvaluationQuestionType;  // Makes enum accessible in html
  formGroup: FormGroup;
  passFailItems: IMultiSelectOption[];
  state: 'basic' | 'full' | 'edit' = 'basic';
  type: EvaluationQuestionType;

  constructor(private _evaluationService: EvaluationService,
              private _eventService: EventService,
              private _translateService: TranslateService) {
    this.passFailItems = [
      { id: true, name: this._translateService.instant('program.evaluation.true') },
      { id: false, name: this._translateService.instant('program.evaluation.false') }
    ];
  }

  ngOnInit() {
    this.getStateFromLocalStorage();
    this.type = this.evaluation?.evaluationConfig?.type;
    this.createForm();
  }

  toggleStateView() {
    switch (this.state) {
      case 'basic':
        this.state = 'full';
        break;
      case 'full':
        this.state = 'basic';
        break;
      case 'edit':
        this.state = 'basic';
        break;
    }
    this.saveStateToLocalStorage();
  }

  toggleStateEdit() {
    switch (this.state) {
      case 'full':
        this.state = 'edit';
        break;
      case 'edit':
        this.state = 'full';
        break;
    }
    this.saveStateToLocalStorage();
  }

  private createForm() {
    this.formGroup = new FormGroup({
      comments: new FormControl<string>({ value: this.evaluation?.comments, disabled: false })
    });

    if (this.type === EvaluationQuestionType.PASS_FAIL) {
      this.boolValueInitial = this.passFailItems?.find(item => item.id === this.evaluation?.boolValue);
      this.formGroup.addControl('boolValue', new UntypedFormControl({ value: [ this.evaluation?.boolValue ], disabled: false }));
    }

    if (this.type === EvaluationQuestionType.SCORE) {
      this.formGroup.addControl('scoreValue', new FormControl<number>({ value: this.evaluation?.scoreValue, disabled: false }, [Validators.min(this.evaluation?.evaluationConfig?.rangeStart), Validators.max(this.evaluation?.evaluationConfig?.rangeEnd)]));
    }
  }

  saveEvaluation() {
    const evaluation = this.findEvaluationFromForm();
    if (evaluation?.id) {
      this._evaluationService.update(evaluation)
        .pipe(take(1))
        .subscribe((response: Evaluation) => {
          this.reload.next(true);
          this._eventService.success(this._translateService.instant('general-messages.saved'));
          this.toggleStateEdit();
        });
    } else {
      this._evaluationService.create(evaluation)
        .pipe(take(1))
        .subscribe((response: Evaluation) => {
          this.reload.next(true);
          this._eventService.success(this._translateService.instant('general-messages.saved'));
          this.toggleStateEdit();
        });
    }
  }

  private saveStateToLocalStorage() {
    localStorage.setItem('eval-' + this.evaluation.evaluationConfig.id, this.state);
  }

  private getStateFromLocalStorage() {
    const state = localStorage.getItem('eval-' + this.evaluation.evaluationConfig.id) as 'basic' | 'full' | 'edit';
    if (state) {
      this.state = state;
    }
  }

  private findEvaluationFromForm(): Evaluation {
    const evaluation = new Evaluation(this.evaluation);
    evaluation.comments = this.formGroup.controls?.comments?.value;

    switch (this.type) {
      case EvaluationQuestionType.PASS_FAIL:
        evaluation.boolValue = this.formGroup.controls?.boolValue?.value[0];
        break;
      case EvaluationQuestionType.SCORE:
        evaluation.scoreValue = this.formGroup.controls?.scoreValue?.value;
        break;
    }

    return evaluation;
  }
}
