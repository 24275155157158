import { Component, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Need to update
 */
@Component({
  selector: 'oes-unsaved-data-modal',
  templateUrl: './unsaved-data-modal.component.html',
  styleUrls: ['./unsaved-data-modal.component.scss']
})
export class UnsavedDataModalComponent {
  @Input() extraText = '';
  @Input() noDiscardButton = false;
  @Input() noSaveButton = false;
  @Input() observable: Observable<any>;

  @Output() discardChangesEmitter: EventEmitter<any> = new EventEmitter();
  @Output() saveDataEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalTemplate', {static: false}) modalTemplate: ModalDirective;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true
  };
  nextUrl: string;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _router: Router,
              private _modalService: BsModalService) {
  }

  show(nextUrl: string) {
    this.modalTemplate.show();
    this.nextUrl = nextUrl;
  }

  hide() {
    this.modalTemplate.hide();
  }

  saveData() {
    if (this.observable) {
      this.observable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.modalTemplate.hide();
        this._router.navigateByUrl(this.nextUrl);
      });
    } else {
      this.saveDataEmitter.emit();
    }
  }

  /**
   *  Call this from a parent after completed a saving
   */
  hideAndNavigate() {
    this.modalTemplate.hide();
    this._router.navigateByUrl(this.nextUrl);
  }

  hideAndStay() {
    this.modalTemplate.hide();
  }

  discardChanges() {
    this.discardChangesEmitter.emit();
    this.modalTemplate.hide();
    this._router.navigateByUrl(this.nextUrl);
  }

  private handleMessage() {
    if (this.modalTemplate && this._modalService.getModalsCount()) {
      this.hideAndNavigate();
    }
  }
}
