import { BaseModel } from '@shared/base.model';


export class AssignPayoutRule extends BaseModel<AssignPayoutRule> {
  tier: string;
  payout: number;

  constructor(payload: any) {
    super();
    this.tier = payload.tier;
    this.payout = payload.payout;
  }
}
