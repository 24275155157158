Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = function (ctx) {
  var __t,
    __p = '';
  __p += '<div class="text-muted text-center p-2">' + ((__t = ctx.t(ctx.component.title, {
    _userInput: true
  })) == null ? '' : __t) + '</div>\n';
  return __p;
};