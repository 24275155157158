import { Injectable } from '@angular/core';
import { FilesService } from './files.service';
import { DocumentDataService } from './document-data.service';
import * as FileSaver from 'file-saver';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { EventService } from '@shared/event.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  pdfExtension = ['.pdf'];
  imageExtensions = ['.jpg', '.png', '.gif'];
  previewableExtensions = [...this.pdfExtension, ...this.imageExtensions];

  private downloadReady = true;
  private downloadDebounce = 500;

  constructor(
    private _filesService: FilesService,
    private _eventService: EventService,
    private _translateService: TranslateService,
    private _documentDataService: DocumentDataService
  ) {}

  public download(targetOrganizationId: string) {
    if (this.downloadReady) {
      this.downloadReady = false;
      const nodes = this._filesService.getSelectedNodes();
      const items = nodes.reduce((acc, node) => {
        if (
          node.data &&
          node.data.documentKey &&
          node.data.documentKey.docType !== 'PATH'
        ) {
          acc.push(node.data.documentKey.key);
        }
        return acc;
      }, []);
      this._documentDataService
        .downloadDocuments(items, targetOrganizationId)
        .subscribe((data: any) => {
          if (data) {
            let name = '';
            if (items && items.length === 1) {
              const dirs = items[0].split('/');
              name = dirs[dirs.length - 1];
            } else {
              name = 'Files_' + DateTime.local().toSQLDate() + '.zip';
            }
            FileSaver.saveAs(data, name);
          }
        });
    }
    this.clearDownloadReady();
  }

  public preview(targetOrganizationId: string) {
    if (this.downloadReady) {
      this.downloadReady = false;
      const node = this._filesService.getSelectedNodes()[0];
      const item = [node.data.documentKey.key];
      const fileType = this.getFileType(node.data.path).toLowerCase();

      if (!this.previewableExtensions.includes(fileType)) {
        return this._eventService.error(
          fileType +
            ' ' +
            this._translateService.instant('data-room.dialogs.preview-error')
        );
      }
      this._documentDataService
        .downloadDocuments(item, targetOrganizationId)
        .pipe(take(1))
        .subscribe((data: any) => {
          if (this.pdfExtension.includes(fileType)) {
            this.displayPdf(data);
          } else if (this.imageExtensions.includes(fileType)) {
            this.displayImage(data);
          }
        });
    }
    this.clearDownloadReady();
  }

  private clearDownloadReady() {
    setTimeout(() => {
      this.downloadReady = true;
    }, this.downloadDebounce);
  }

  private displayPdf(data) {
    const file = new Blob([data], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl, '_blank');
  }

  private displayImage(data) {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result.toString();
      image.style.maxWidth = '800px';
      image.style.display = 'block';
      image.style.margin = 'auto';
      const w = window.open('');
      w.document.write(image.outerHTML);
    };
  }

  getFileType(fileName: string) {
    return (
      '.' + fileName.split('.')[fileName.split('.').length - 1].toLowerCase()
    );
  }
}
