import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { EvaluationConfig } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-config.model';
import { EvaluationConfigService } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-config.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { Evaluation } from './evaluation.model';
import { EvaluationService } from './evaluation.service';
import { PASS_FAIL } from './pass-fail.enum';
import { EvaluationQuestionType } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-question-type.enum';
import { EntityType } from '@program/program-detail/program-configuration/shared/evaluation-config/entity-type.enum';

@Component({
  selector: 'oes-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {
  @Input() configuredEntityId: string;
  @Input() evaluatedEntityId: string;
  @Input() entityType: EntityType;

  configurations: EvaluationConfig[];
  evaluations: Evaluation[];
  evalDisplay: Evaluation[];
  totals: {
    passFail: PASS_FAIL,
    totalScore: number
  };
  user: User;

  constructor(private _userService: UserService,
              private _evaluationService: EvaluationService,
              private _evaluationConfigService: EvaluationConfigService) {
  }

  ngOnInit() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;

        this.getFormData();
      });
  }

  private getFormData() {
    const formRequest = this._evaluationService.buildFormRequest(this.evaluatedEntityId, this.entityType);
    const configRequest = this._evaluationConfigService.buildConfigRequest(this.configuredEntityId, true);
    forkJoin([ formRequest, configRequest ])
      .pipe(take(1))
      .subscribe((response: any) => {
        const evaluations: Evaluation[] = response[0];
        const configs: EvaluationConfig[] = response[1];
        const evalDisplay: Evaluation[] = configs.reduce((accum, config) => {
          const matchedEvaluation = evaluations.find(ev => ev.evaluationConfig?.id === config.id);
          if (matchedEvaluation) {
            accum.push(matchedEvaluation);
          } else {
            const createdEvaluation = new Evaluation({ evaluationConfig: config, evaluatedEntityId: this.evaluatedEntityId });
            accum.push(createdEvaluation);
          }
          return accum;
        }, []).sort((a: Evaluation, b: Evaluation) => {
          if (a.evaluationConfig.index > b.evaluationConfig.index) {
            return 1;
          } else {
            return -1;
          }
        });
        this.evalDisplay = evalDisplay;
        this.calculateTotals();
      });
  }

  private calculateTotals() {
    this.totals = this.evalDisplay.reduce((accum, evaluation) => {
      if (evaluation.evaluationConfig.type === EvaluationQuestionType.PASS_FAIL) {
        if (evaluation.boolValue !== null) {
          if (evaluation.boolValue === true && accum.passFail !== PASS_FAIL.FAIL) {
            accum.passFail = PASS_FAIL.PASS;
          }
          if (evaluation.boolValue === false) {
            accum.passFail = PASS_FAIL.FAIL;
          }
        }
      }
      if (evaluation.evaluationConfig.type === EvaluationQuestionType.SCORE) {
        if (evaluation.scoreValue !== null && evaluation.scoreValue !== undefined) {
          accum.totalScore += ((evaluation.scoreValue / evaluation.evaluationConfig.rangeEnd) * evaluation.evaluationConfig.weight);
        }
      }
      return accum;
    }, {  passFail: null, totalScore: 0 });
  }
}
