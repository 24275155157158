<div bsModal #connectWithInvestorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     [config]="{backdrop:true,ignoreBackdropClick:true,keyboard:true}"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{'project-group-introduction.connect-with-investor.title' | translate}}</h4>
        <button type="button" class="close pull-right" (click)="closeDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5>{{'project-group-introduction.connect-with-investor.send-portfolio' | translate}}</h5>
        <div class="select-wrap">
          <form [formGroup]="formGroup">
            <select class="form-control" formControlName="investorList" id="investor-list" required>
              <option disabled selected [ngValue]="undefined">
                {{'form.select-default' | translate}}
              </option>
              <option *ngFor="let financeOrg of filteredFinanceOrganizations" [ngValue]="financeOrg.id">
                {{financeOrg.name}}
              </option>
            </select>
          </form>
        </div>
        <p class="pt-3">{{'project-group-introduction.connect-with-investor.disclaimer' | translate}}</p>
        <div class="text-right">
          <button class="btn btn-secondary" (click)="closeDialog()">{{'buttons.cancel' | translate}}</button>
          <button class="btn btn-primary ml-2" (click)="submitToInvestor()">{{'buttons.submit' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
