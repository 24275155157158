import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { Project } from '@project/shared/project.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { Installer, ProjectInstaller } from "../../../installers/installer.model";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { InstallerService } from "../../../installers/installer.service";
import { EventService } from '@shared/event.service';
import { UserService } from "@user/user.service";
import { Organization } from "../../../../shared/models/organization/organization.model";
import { User } from "@user/user.model";
import { Subject, skip, take, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ProjectConnection } from "@project/shared/project-connection.model";
import { ProjectConnectionService } from "@project/shared/project-connection.service";
import { InstallerFormComponent } from "@project/installers/form/installer-form.component";


@Component({
  selector: 'oes-assign-project-installer',
  templateUrl: './assign-project-installer.component.html',
  styleUrls: ['./assign-project-installer.component.scss']
})
export class AssignProjectInstallerComponent implements OnInit, OnDestroy {
  @ViewChild('assignInstallerModal', {static: false}) assignInstallerModal: ModalDialogComponent;
  @ViewChild('installerForm', {static: false}) installerForm: InstallerFormComponent;

  @Input() project: Project;

  canAssign: boolean;
  currentInstaller: Installer;
  installers: Installer[] = [];
  formGroup: UntypedFormGroup;
  projectConnection: ProjectConnection;
  projectInstaller: ProjectInstaller;
  selectedInstaller: Installer;

  organization: Organization;
  showDropdownError = false;
  showMissingDeveloperError = false;

  private message = {};
  private ngUnsubscribe: Subject<any> = new Subject();


  constructor(private _installerService: InstallerService,
              private _eventService: EventService,
              private _projectConnectionService: ProjectConnectionService,
              private _translateService: TranslateService,
              private _userService: UserService) { }

  ngOnInit() {
    this._userService.getCurrentUser().subscribe((user: User) => {
      this.organization = user.organization;
      if (this.project.organization.id === this.organization.id) {
        this.canAssign = true;
      } else {
        this.canAssign = false;
      }
    });
    this._projectConnectionService.projectConnection$
    .pipe(skip(1), takeUntil(this.ngUnsubscribe))
    .subscribe((pc: ProjectConnection) => {
      this.projectConnection = pc;
      if (pc?.recipient) {
        this.formGroup.get('sameAsDeveloper')?.enable();
      } else {
        this.formGroup.get('sameAsDeveloper')?.disable();
      }
    });
    this.getInstallers();
    this.message = this._translateService.instant('project.overview.message');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  newInstallerClick() {
    this.installerForm.openModal('create');
  }

  getFilteredInstallers(searchText: string) {
    this.getInstallers(searchText);
  }

  refreshInstallers(newInstaller: Installer) {
    this.getInstallers(undefined, newInstaller);
  }

  getInstallers(searchText: string = '', newInstaller?: Installer) {
    this._installerService.listInstallersByOrgId(this.organization.id, searchText)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((installers: Installer[]) => {
      this.installers = installers;
      for (const installer of this.installers) {
        const piFound: ProjectInstaller | undefined = installer.projectInstallers.find(pi => pi.project.id === this.project.id);
        if (piFound) {
          this.currentInstaller = installer;
          this.selectedInstaller = installer;
          this.projectInstaller = piFound;
        }
        if (newInstaller) {
          this.selectedInstaller = newInstaller;
          this.formGroup.patchValue({
            selectOption: [newInstaller.id],
            sameAsDeveloper: newInstaller.ownerOrganization.id === this.projectConnection?.recipient?.id
          });
        }
      }
      this.findProjectDeveloperConnection();
      this.buildForm();
    });
  }

  buildForm() {
    this.formGroup = new UntypedFormGroup({
      selectOption: new UntypedFormControl([this.selectedInstaller?.id]),
      sameAsDeveloper: new UntypedFormControl(false)
    });
    this.subscribeForm();
  }

  subscribeForm() {
    this.formGroup.valueChanges.subscribe((values: any) => {
      if (values.selectOption) {
        const selectedFound = this.installers.find(i => i.id === values.selectOption[0]);
        if (selectedFound) {
          this.selectedInstaller = selectedFound;
        }
      }
      if (values.sameAsDeveloper && this.formGroup.controls.selectOption.enabled) {
        setTimeout(() => {
          this.formGroup.controls.selectOption.disable();
          this.assignInstallerModal.elementRef.nativeElement.click();
        }, 0);
      } else if (values.sameAsDeveloper === false && this.formGroup.controls.selectOption.disabled) {
        setTimeout(() => {
          this.formGroup.controls.selectOption.enable();
          this.assignInstallerModal.elementRef.nativeElement.click();
        }, 0);
      }
    });
  }

  private findProjectDeveloperConnection() {
    this._projectConnectionService.getProjectConnections(this.project?.id, { page: 0, size: 100, sort: 'created,DESC', primaryConnection: true })
      .pipe(take(1))
      .subscribe((projectConnections: ProjectConnection[]) => {
        if (!projectConnections.length || !projectConnections[0].recipient) {
          this.formGroup.get('sameAsDeveloper')?.disable();
          return;
        }
        this.projectConnection = projectConnections[0];
        if (this.projectConnection.recipient.id === this.selectedInstaller.ownerOrganization.id) {
          this.formGroup.controls.selectOption.disable();
          this.formGroup.patchValue({
            sameAsDeveloper: true
          });
        }
        this.formGroup.get('sameAsDeveloper')?.enable();
      });
  }

  assignInstaller() {
    this.showDropdownError = false;
    if (this.formGroup.value.sameAsDeveloper) {
      if (!this.projectConnection) {
        this.showMissingDeveloperError = true;
        return;
      }
      const developerInstaller = this.installers.find(i => i.ownerOrganization.id === this.projectConnection.recipient?.id);
      if (developerInstaller) {
        this.selectedInstaller = developerInstaller;
        this.createOrUpdateProjectInstaller();
      } else {
        this._installerService.createInstaller(new Installer({
          name: this.projectConnection.recipient.name,
          email: this.projectConnection.recipient.contactEmailAddress,
          ownerOrganization: this.organization
        }))
        .pipe(take(1))
        .subscribe((installer: Installer) => {
          this.selectedInstaller = installer;
          this.createOrUpdateProjectInstaller();
        });
      }
    } else if (!this.selectedInstaller) {
      this.showDropdownError = true;
      return;
    } else {
      this.createOrUpdateProjectInstaller();
    }
  }

  createOrUpdateProjectInstaller() {
    this._installerService.createProjectInstaller(this.project.id, this.selectedInstaller.id)
    .pipe(take(1))
    .subscribe((installer: Installer) => {
      this.selectedInstaller = installer;
      this.currentInstaller = installer;
      this._eventService.success(this.message['installer-assign-success']);
      this.closeAssignInstallerModal();
      if (this.projectConnection?.recipient?.id === this.selectedInstaller.ownerOrganization.id) {
        this.formGroup.patchValue({
          sameAsDeveloper: true
        });
        this.formGroup.controls.selectOption.disable();
      }
    });
  }

  openAssignInstallerModal() {
    this.assignInstallerModal.show();
  }

  closeAssignInstallerModal() {
    this.assignInstallerModal.hide();
  }
}
