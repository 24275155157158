import { environment } from '@environments/environment';

export class DeveloperInformationApiConstants {
  public static organizations = environment.serverUri + `api/${environment.apiVersion}/organizations`;

  public static developerInformation = {
    list: (organizationId: string) => {
      return `${DeveloperInformationApiConstants.organizations}/${organizationId}/developer-information`;
    },
    detail: (organizationId: string, informationId: string) => {
      return `${DeveloperInformationApiConstants.organizations}/${organizationId}/developer-information/${informationId}`;
    },
    update: (organizationId: string, informationId: string) => {
      return `${DeveloperInformationApiConstants.organizations}/${organizationId}/developer-information/${informationId}`;
    }

  };
}
