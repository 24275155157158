<google-map
  *ngIf="mapOptions?.center && markerPositions"
  [height]="height"
  width="100%"
  [options]="mapOptions">
  <map-marker-clusterer
    [imagePath]="imagePath"
    [imageExtension]="'png'">
    <map-marker
      #marker="mapMarker"
      *ngFor="let project of mapProjects; let id = index"
      [position]="{lat: project.latitude, lng: project.longitude}"
      (mapClick)="window.open(marker); closeOthers(id)">
      <map-info-window #window="mapInfoWindow">
        <a href="{{url}}{{project?.id}}" class="bold">{{project?.name}}</a>
        <div><span class="mb-1 bold">Status: </span><span>{{project.status | camelCase}}</span></div>
        <div><span class="mb-1 bold">Total Customer: </span><span>{{project.totalCustomer}}</span></div>
        <div><span class="mb-1 bold">PV Capacity: </span><span>{{project.totalPV}}</span></div>
      </map-info-window>
    </map-marker>
  </map-marker-clusterer>
</google-map>
