import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-grid-status-cell',
  templateUrl: './grid-status-cell.component.html',
  styleUrls: ['./grid-status-cell.component.scss']
})
export class GridStatusCellComponent implements INoRowsOverlayAngularComp {
  colorHex = '';
  lastStage: boolean = false;
  name = '';
  nativeStatuses = [];
  paramsActive = false;  // to show fa-caret-down icon
  textColorHex = '';

  private allStatuses = [];

  constructor(private _translateService: TranslateService) {
    this._translateService.get('program').subscribe(text => {
      this.nativeStatuses = Object.keys(text['pre-qualification']['status']);
    });
  }

  agInit(params): void {
    const paramsLastStage = params?.data?.programApplicationStageConfiguration?.lastStage;
    const contextLastStage = params?.context?.gridService?.applications?.length > 0 &&
      params?.context?.gridService?.applications[0].programApplicationStageConfiguration.lastStage;
    if (paramsLastStage || contextLastStage) {
      this.lastStage = true;
    }
    let value = '';
    // cellRenderer uses params.data
    // cellEditorParams uses params.value
    if (params?.data?.status && !params.data.fixedStatus) {
      value = params.data.status;
      if (value === 'Selected') {
        this.paramsActive = false;
      } else {
        this.paramsActive = true;
      }
    } else if (params?.data?.status && params.data.fixedStatus) {
      value = params.data.status;
      this.paramsActive = false;
    } else if (params.value) {
      value = params.value;
      this.paramsActive = false;
    } else {
      value = 'Invited';
      this.paramsActive = false;
    }
    if (value === 'Invited') {
      this.allStatuses = null;
      this.colorHex = '#dddddd';
      this.name = 'Invited';
      this.textColorHex = this.findTextColor(this.colorHex);
    } else if (params?.api?.gridOptionsWrapper?.gridOptions?.context?.gridService?.allStatuses?.length > 0) {
      this.allStatuses = params?.api?.gridOptionsWrapper?.gridOptions?.context?.gridService?.allStatuses;
      this.colorHex = this.allStatuses.find(status => status.name === value)?.color;
      this.name = value;
      this.textColorHex = this.findTextColor(this.colorHex);
    }
  }

  private findTextColor(hexCode: string) {
    if (hexCode && hexCode !== '') {
      hexCode = hexCode.replace('#', '');
      const r = parseInt(hexCode.substr(0, 2), 16);
      const g = parseInt(hexCode.substr(2, 2), 16);
      const b = parseInt(hexCode.substr(4, 2), 16);
      const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return brightness >= 152 ? '#000000' : '#ffffff';
    }
    return undefined;
  }
}
