import { SpecSource } from '@project/detail/information/site-details/project-specs-source.model';
import { BaseModel } from '@shared/base.model';

export class ProjectSpec extends BaseModel<ProjectSpec> {
  source: string;
  sourceDetails: string;
  // TODO: this has been deprecated but may still be needed for previous projects
  specificationSource: SpecSource;
  unitType: string;
  value: string;
  name: string;

  constructor(projectSpecInfo: any, specSourceInfo?: SpecSource) {
    super();
    this.id = projectSpecInfo.id;
    this.source = projectSpecInfo.source;
    this.sourceDetails = projectSpecInfo.sourceDetails;
    this.specificationSource = specSourceInfo;
    this.unitType = projectSpecInfo.unitType;
    this.value = projectSpecInfo.value;
    this.name = projectSpecInfo.name;
  }
}
