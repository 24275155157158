import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { ProjectSetPortfolioConnectionApiConstants } from './developer-project-set-portfolio-connection.api.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectSetPortfolioConnection } from './developer-project-set-portfolio-connection.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetPortfolioConnectionService extends BaseRestApiService {
  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(projectSetPortfolioId: string, params: any = {page: 0, size: 1000}): Observable<ProjectSetPortfolioConnection[]> {
    return this.get<ProjectSetPortfolioConnection[]>(ProjectSetPortfolioConnectionApiConstants.portfolios.list(projectSetPortfolioId), {params: params})
      .pipe(map((projectSetPortfolioConnections: ProjectSetPortfolioConnection[]) => {
        return projectSetPortfolioConnections.map(projectSetPortfolioConnection => new ProjectSetPortfolioConnection(projectSetPortfolioConnection));
      }));
  }

  public create(projectSetPortfolioId: string, projectSetPortfolioConnection: ProjectSetPortfolioConnection): Observable<ProjectSetPortfolioConnection> {
    return this.post(ProjectSetPortfolioConnectionApiConstants.portfolios.create(projectSetPortfolioId), JSON.stringify(projectSetPortfolioConnection))
      .pipe(map((pspc: ProjectSetPortfolioConnection) => {
        return new ProjectSetPortfolioConnection(pspc);
      }));
  }

  public detail(projectSetPortfolioId: string, projectSetPortfolioConnectionId: string): Observable<ProjectSetPortfolioConnection> {
    return this.get<ProjectSetPortfolioConnection>(ProjectSetPortfolioConnectionApiConstants.portfolios.detail(projectSetPortfolioId, projectSetPortfolioConnectionId))
      .pipe(map((psp: ProjectSetPortfolioConnection) => {
        return new ProjectSetPortfolioConnection(psp);
      }));
  }

  public remove(projectSetPortfolioId: string, projectSetPortfolioConnectionId: string): Observable<any> {
    return this.delete(ProjectSetPortfolioConnectionApiConstants.portfolios.delete(projectSetPortfolioId, projectSetPortfolioConnectionId));
  }

}
