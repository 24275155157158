import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { ProjectSetFinancialModelTemplate } from '@project/offtakers/project-set/edit/financial-model/project-set-financial-model-template.model';
import { ManageProjectSetFinancialModelTemplatesApiConstants } from './manage-project-set-financial-model-templates-api.constant';

@Injectable({
  providedIn: 'root'
})
export class ManageProjectSetFinancialModelTemplatesService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  findAllTemplates(): Observable<any> {
    return this.get<ProjectSetFinancialModelTemplate[]>(ManageProjectSetFinancialModelTemplatesApiConstants.findAllTemplates());
  }

  uploadModel(file: Object): Observable<any> {
    return this.post<ProjectSetFinancialModelTemplate[]>(ManageProjectSetFinancialModelTemplatesApiConstants.uploadTemplate(), file);
  }
}
