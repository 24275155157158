<ng-container *ngIf="!disableSlider; else NoSlider">
  <div class="show-scale-load">
    <div *ngIf="disabledAllPills"
        class="no-item-selected d-flex justify-content-center align-items-center">
      <div>{{'project-summary.customers.no-item-selected' | translate}}</div>
    </div>
    <div class="slider-kit d-flex flex-column"
          [ngClass]="{'mini': !showSlider}">
      <div class="header-group mb-1 d-flex flex-row justify-content-between align-items-center">
        <div class="header d-flex flex-row justify-content-start flex-wrap">
          <div class="d-flex flex-row align-items-center toggle-scaler"
                (click)="toggleScaler()">
            <i class="fa" [ngClass]="{'fa-caret-down':showSlider, 'fa-caret-right':!showSlider}"></i>
            <h5 class="title">{{'system-load.show-and-scale-load.title'| translate}}</h5>
          </div>
          <div class="whats-this text-link--color-brand small"
                tooltip="{{'system-load.show-and-scale-load.description'| translate}}"
                containerClass="tool-tip"
                placement="top">{{'system-load.whats-this'| translate}}
          </div>
        </div>
      </div>
      <div class="guide">{{'system-load.show-and-scale-load.guide'| translate}}</div>
      <div *ngIf="showSlider"
            class="set-maximum d-flex justify-content-end">
        <label>{{'system-load.show-and-scale-load.set-maximum'| translate}}
          <input type="text"
                  name="max-value"
                  [ngModel]="maxValue/100 | percent"
                  (ngModelChange)="updateMaxValue($event)"
                  [disabled]="readOnly">
        </label>
      </div>
      <oes-slider
        *ngIf="sliderItems"
        [readOnly]="readOnly"
        [rangeMin]="minValue"
        [rangeMax]="maxValue"
        [rangeMiddle]="100"
        [sliderItems]="sliderItems"
        [showSlider]="showSlider"
        (updated)="updated($event)">
      </oes-slider>
      <div class="d-flex flex-row justify-content-end mt-3" *ngIf="showSlider && !readOnly">
        <button type="button"
                class="btn btn-secondary btn-small"
                (click)="restoreScales()">
          {{'system-load.show-and-scale-load.restore-to' | translate}}
        </button>
        <button type="button"
                class="btn btn-primary btn-small ml-2"
                (click)="saveScales()"
                [disabled]="disabledAcceptButton">
          {{'system-load.show-and-scale-load.accept' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #NoSlider>
  <oes-slider
    *ngIf="sliderItems"
    [readOnly]="readOnly"
    [rangeMin]="minValue"
    [rangeMax]="maxValue"
    [rangeMiddle]="100"
    [sliderItems]="sliderItems"
    [showSlider]="false"
    [orientation]="'horizontal'"
    (updated)="updated($event)">
  </oes-slider>
</ng-template>
