import {BaseModel} from '@shared/base.model';
import {RevenueModel} from '@project/detail/revenue/revenue-model.model';
import {RevenueModelItem} from '@project/detail/revenue/revenue-model-item/revenue-model-item.model';
import {CustomerTypeFilter} from './customer-type-filter';

export class BulkRevenueModelUpdateRequest extends BaseModel<BulkRevenueModelUpdateRequest> {
  projects: string[] = [];
  model: RevenueModel;
  items: RevenueModelItem [] = [];
  filter: CustomerTypeFilter;
}
