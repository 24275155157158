import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ChartColors } from '../chart-colors.enum';
import { ChartDataset } from '../chart-dataset.model';
import { OesChart } from '../oes-chart.model';

@Component({
  selector: 'oes-horizontal-bar-chart',
  templateUrl: 'horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent implements OnInit {
  @Input() chart: OesChart;
  @Input() chartColors: string[] = Object.keys(ChartColors);
  @Input() currency: string;

  horizontalBarChartData: ChartConfiguration<'bar'>['data'];
  horizontalBarChartLegend: boolean;
  horizontalBarChartPlugins = [];
  horizontalBarChartOptions: ChartConfiguration<'bar'>['options'];

  constructor() {
  }

  ngOnInit() {
    this.buildHorizontalBarChart();
  }

  private buildDatasets(datasets: ChartDataset[]) {
    return datasets.map((dataset, index) => {
      return {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: this.chartColors,
        hoverBackgroundColor: this.chartColors,
        maxBarThickness: 16
      };
    });
  }

  private buildHorizontalBarChart() {
    const barChartLegend = false;
    const barChartPlugins = [];
    const barChartData: ChartConfiguration<'bar'>['data'] = {
      labels: this.chart.labels,
      datasets: this.buildDatasets(this.chart.datasets)
    };
    const barChartOptions: ChartConfiguration<'bar'>['options'] = {
      plugins: {
        title: {
          display: false
        },
        legend: {
          display: false,
          position: 'top',
          labels: {
            padding: 15,
            boxHeight: 5,
            boxPadding: 12,
            boxWidth: 25
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          grace: '5%',
          ticks: {
            display: false
          },
          grid: {
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          grid: {
            display: false
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      elements: {
        bar: {
          borderRadius: 6
        }
      }
    };
    this.horizontalBarChartLegend = barChartLegend;
    this.horizontalBarChartPlugins = barChartPlugins;
    this.horizontalBarChartData = barChartData;
    this.horizontalBarChartOptions = barChartOptions;
  }



}
