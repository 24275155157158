import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SystemLoadService } from '@project/detail/load/shared/system-load.service';
import { ProjectService } from '@project/shared/project.service';
import { Color, CustomerCategoryPaletteService } from '@shared/components/color-palette/customer-category-palette.service';
import { EChartsOption } from 'echarts';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { LoadChartType } from '../load-chart-type.enum';
import { SliderItem } from '../show-scale-load/slider/slider-item';

@Component({
  selector: 'oes-utilization-factor-chart',
  templateUrl: './utilization-factor-chart.component.html',
  styleUrls: ['./utilization-factor-chart.component.scss'],
})
export class UtilizationFactorChartComponent implements OnInit, OnDestroy {
  private chartRawData: any[];
  private chartType: LoadChartType = LoadChartType.Hour;
  private ngUnsubscribe: Subject<any> = new Subject();
  private sourceColor: Color[];
  public chartOption: EChartsOption;
  private noDataMessage: string;
  private sliderItems: SliderItem[];

  constructor(private _systemLoadService: SystemLoadService,
              private _customerCategoryPaletteService: CustomerCategoryPaletteService,
              private _translateService: TranslateService,
              private _projectService: ProjectService) {
    this.sourceColor = this._customerCategoryPaletteService.palette;
    this.noDataMessage = this._translateService.instant('project.dashboard.no-data');
  }

  ngOnInit() {
    this.subscribeSlider();
    this.subscribeChartType();
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private subscribeSlider() {
    this._systemLoadService.slider$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((sliderItems: SliderItem[]) => {
      this.sliderItems = sliderItems;
      this.filterChartData();
    });
  }

  // Hour/Day/Month
  private subscribeChartType() {
    this._systemLoadService.chartTypeSubject$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((chartType: LoadChartType) => {
      this.chartType = chartType;
      this.getChartData(chartType);
    });
  }

  private getChartData(chartType: LoadChartType) {
    const systemLoad = this._systemLoadService.systemLoad;
    if (systemLoad) {
      this._systemLoadService.getChartData(
        this._projectService.projectId,
        systemLoad.id,
        chartType
      )
      .pipe(take(1))
      .subscribe(chartData => {
        if (chartData) {
          this._systemLoadService.customerTypes = chartData.map(data => data.name.toLowerCase());
          this.chartRawData = chartData;
          this.filterChartData();
          this._systemLoadService.chartDataLoaded = true;
        }
      });
    }
  }

  private filterChartData() {
    let filteredRawData = [];
    if (this.sliderItems && this.chartRawData) {
      filteredRawData = this.chartRawData.filter(raw => {
        const sliderItem = this.sliderItems.find(item => item.name.toLowerCase() === raw.name.toLowerCase());
        return sliderItem && !sliderItem.isDisabled;
      });
      filteredRawData = this._systemLoadService.sort(filteredRawData);
      this.chartOption = this.convertData(filteredRawData);
    }
  }

  private convertData(rawData: any[]): any {
    return {
      textStyle: {
      fontFamily: 'Inter'
      },
      title: {
        fontFamily: 'Inter',
        fontColor: '#373837',
        fontSize: 16,
        fontWeight: 'normal',
        horizontalAlign: 'center',
        verticalAlign: 'center',
        text: ''
      },
      yAxis: {
        type: 'value',
        name: 'kW',
        axisLabel: {
          show: true,
        }
      },
      xAxis: {
        type: 'category',
        name: new TitleCasePipe().transform(this.chartType),
        data: rawData[0]?.series?.map(item => item.name),
        axisLabel: {
          show: true,
        },
        boundaryGap: false,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            color: '#fff',
            backgroundColor: '#000'
          }
        },
        order: 'seriesDesc',
        formatter: (params) => {
          let xAxisLabel = params[0].axisValueLabel;
          let result = '<div style="text-align: left; margin-bottom: 5px; font-size: 16px; font-weight: bold;">' + xAxisLabel + '</div>';
          params = params.reverse();
          params.forEach((item) => {
            let roundedValue = item.value.toFixed(2);
            result += '<div style="display: flex; align-items: center; justify-content: space-between;">'
                    + '<span><span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ' + item.color + '; margin-right: 5px;"></span>'
                    + item.seriesName + '<span style="margin-right: 20px;"></span></span>'
                    + '<strong style="text-align: right;">' + roundedValue + '</strong>'
                    + '</div>';
          });
          return result;
        }
      },
      grid: {
        top: 10,
        left: 20,
        right: 8,
        bottom: 25,
        containLabel: true
      },
      series: rawData.map((data) => {
        const color = (this.sourceColor.find(source => source.name === data.name.toLowerCase())).value;
        return {
          name: data.name,
          type: 'line',
          symbol: 'none',
          stack: 'Total amount',
          areaStyle: {
            color: color,
          },
          itemStyle: {
            color: color, // effects icon color on tooltip
          },
          lineStyle: {
            width: 1,
            color: color,
          },
          data: data?.series?.map(item => {
            if (this.sliderItems?.length > 0) {
              const loadName = data.name.toLowerCase();
              const sliderItem = this.sliderItems?.find(slider => slider.name.toLowerCase() === loadName);

              if (sliderItem && !sliderItem.isDisabled) {
                // hackish name resolution because system load parameter names don't exactly match the load name
                const systemLoadParameterName = (loadName === 'internal load' ? 'internal' : loadName) + 'LoadScaleFactor';

                const initialScale = this._systemLoadService.systemLoad?.systemLoadParameters[systemLoadParameterName];
                if (!initialScale) {
                  console.error('Warning: cannot find systemLoadParameter for load "',  systemLoadParameterName, '". Scaling using saved load');
                  return item.value * (sliderItem.scale / 100);
                }
                // use the initial scale value to determine the original load value, then scale that using the slider amount
                const initialUnscaledLoad = (item.value / initialScale) * 100;
                return initialUnscaledLoad * (sliderItem.scale / 100);
              }
            }
            return item.value;
          }),
        };
      })
    };
  }

  // private noData() {
  //   if (this.chart && this.chart.options) {
  //     if (this.chart.options.title) {
  //       this.chart.options.title.text = this.noDataMessage;
  //       this.chart.options.title.fontColor = '#c9c9c9';
  //       this.chart.options.title.fontSize = 18;
  //       this.chart.options.title.fontWeight = 'normal';
  //     }
  //     if (this.chart.options.axisY) {
  //       this.chart.options.axisY.lineThickness = 0;
  //       this.chart.options.axisY.title = '';
  //       this.chart.options.axisY.minimum = '';
  //       this.chart.options.axisY.valueFormatString = ' '; // need a blank space to hide y-axis
  //     }
  //     if (this.chart.options.axisX) {
  //       this.chart.options.axisX.lineThickness = 0;
  //     }
  //   }
  // }
}
