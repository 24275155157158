<div class="grid-item" [ngClass]="{'inline-title': inlineTitle}">
  <div class="grid-item__header">
    <div class="logo-row">
      <ng-container *ngIf="icon?.length; else NoIcon">
        <div class="icon-wrapper">
          <img width="64px" src="{{ icon }}" alt="Program Owner logo">
        </div>
      </ng-container>
      <ng-template #NoIcon>
        <div class="no-image" title="no image">
          <i class="fa fa-picture-o" aria-hidden="true"></i>
        </div>
      </ng-template>
      <ng-container *ngIf="status">
        <oes-status-label [status]="status" [statusColor]="statusColor"></oes-status-label>
      </ng-container>
      <ng-container *ngIf="!status && inlineTitle">
        <p class="weight-medium title">{{ title }}</p>
      </ng-container>
    </div>
    <ng-container *ngIf="!inlineTitle">
      <p class="weight-medium title"
        (mouseenter)="onTitleMouseEnter($event)"
        (mouseleave)="onTitleMouseLeave($event)"
        #titleRef
      >{{ title }}</p>
      <p class="subtitle">{{ subtitle }}</p>
    </ng-container>
  </div>
  <ng-container *ngIf="contentProperties.length">
    <div class="grid-item__content">
      <ng-container *ngFor="let prop of contentProperties">
        <div class="property">
          <div class="property-label text-sm weight-medium">{{ prop.key }}</div>
          <div class="property-value text-sm weight-medium">{{ prop.value }}</div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>