<div class="cost-editor">
  <div class="header">
    <div class="d-flex flex-row">
      <h5 class="title">{{title}}</h5>
      <p *ngIf="tooltipTitle !== '' && tooltipMessage !== ''"
         class="text-link--color-brand"
         tooltip="{{tooltipTitle}}"
         placement="right"
         containerClass="tool-tip">{{tooltipMessage}}</p>
    </div>
    <div class="d-flex flex-row">
      <div *ngIf="connections" class="connections">{{isConnections()}}/{{'distribution-design.sub-headings.connection' | translate}}</div>
      <div class="total">{{totalCost | currency : currency : symbol : '1.0-0'}}{{extension}}</div>
    </div>
  </div>
  <oes-grid-action-button
    *ngIf="!readOnly"
    [buttons]="buttons"
    (clicked)="clicked($event)">
  </oes-grid-action-button>
  <div class="sub-action-container">
    <oes-grid-sub-action
      *ngIf="gridApi"
      [gridState]="gridState"
      [defaultGridState]="defaultGridState"
      [reload]="false"
      (action)="subAction($event)">
    </oes-grid-sub-action>
  </div>
  <ag-grid-angular
    style="width: auto;"
    class="ag-theme-alpine"
    [overlayNoRowsTemplate]="'distribution-design.ag-grid-empty-state' | translate"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="selectionChanged($event)"
    (cellEditingStarted)="cellEditingStarted($event)"
    (cellEditingStopped)="cellEditingStopped($event)"
    (rowValueChanged)="rowValueChanged($event)"
    (filterChanged)="gridStatusChanged($event, 'filter')"
    (sortChanged)="gridStatusChanged($event, 'sort')"
    (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
  </ag-grid-angular>
</div>
