import { ActionButton } from '@shared/ag-grid/component/action-button/action-button';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CostEditorGridSettingsService } from './cost-editor-grid-settings.service';
import { CurrencyPipe } from '@angular/common';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { LanguageService } from '@global/services/language.service';
import { Observable } from 'rxjs';
import { ProjectCostItem } from '@project/detail/cost/project-cost-item.model';
import { ProjectCostSources } from '@project/detail/cost/project-cost-sources.model';
import { GridState } from '@shared/ag-grid/gird-state';
import { Project } from '../project.model';
import { ProjectService } from '../project.service';

@Component({
  selector: 'oes-cost-editor',
  templateUrl: './cost-editor.component.html',
  styleUrls: ['./cost-editor.component.scss'],
  providers: [CostEditorGridSettingsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostEditorComponent implements OnInit, OnChanges {
  @Input() connections?: number;
  @Input() extension = '';
  @Input() project: Project;
  @Input() readOnly: boolean;
  @Input() rowData: ProjectCostItem[];
  @Input() storageKey: string;
  @Input() title = '';
  @Input() tooltipMessage = '';
  @Input() tooltipTitle = '';
  @Input() typeList: any[]; // {id: string, value: string}
  @Input() updateAll: Observable<void>;
  @Input() updatedItem: ProjectCostItem;
  @Output() delete: EventEmitter<ProjectCostItem[]> = new EventEmitter<
    ProjectCostItem[]
  >();
  @Output() update: EventEmitter<ProjectCostItem> =
    new EventEmitter<ProjectCostItem>();

  buttons = {
    add: <ActionButton>{
      disabled: false,
      class: 'primary',
      icon: '<i class="fa fa-plus" aria-hidden="true"></i>',
    },
    delete: <ActionButton>{
      disabled: true,
      class: 'destructive',
      icon: '<img src="assets/images/project-page/icn-delete.svg">',
    },
  };
  columnApi: ColumnApi;
  currency: string = 'USD';
  defaultGridState: GridState;
  gridApi: GridApi;
  gridDirty = false;
  gridOptions: GridOptions;
  gridState: GridState;
  symbol: string = 'symbol';
  totalCost = 0;

  private gridStateStorageKey: string;

  constructor(
    public _costEditorGridSettingsService: CostEditorGridSettingsService,
    private _languageService: LanguageService,
    private _projectService: ProjectService
  ) {}

  ngOnInit() {
    this.gridStateStorageKey =
      this._costEditorGridSettingsService.buildGridStateStorageKey(
        this.storageKey,
        this.project.id
      );
    this._costEditorGridSettingsService.typeList = this.typeList;
    this._costEditorGridSettingsService.isEditable = !this.readOnly;
    this.gridOptions = this._costEditorGridSettingsService.getGridOptions();
    this.updateAll.subscribe(() => {
      setTimeout(() => {
        this.gridApi.setRowData(this.rowData);
        this.getTotalCost();
      }, 0);
    });
    const { currency, symbol } = this._projectService.currencyInfo();
    this.currency = currency;
    this.symbol = symbol;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setColumnDefs(
      this._costEditorGridSettingsService.getColumnOption()
    );
    this._costEditorGridSettingsService.setGridApi(
      params.api,
      params.columnApi
    );
    this.defaultGridState =
      this._costEditorGridSettingsService.buildDefaultGridState();
    if (this.rowData.length > 0) {
      this.getTotalCost();
      this.gridApi.showLoadingOverlay();
      this.gridApi.setRowData(this.rowData);
      this._costEditorGridSettingsService.applyStoredGridState(
        this.gridStateStorageKey,
        this.defaultGridState
      );
      this.gridApi.sizeColumnsToFit();
      this.gridApi.hideOverlay();
    } else {
      this.gridApi.showNoRowsOverlay();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.rowData) {
      this.gridApi?.setRowData(this.rowData);
      this.getTotalCost();
    }
    if (changes.updatedItem && !changes.updatedItem.firstChange) {
      const updatedItem = new ProjectCostItem(changes.updatedItem.currentValue);

      // We don't have any bulk ProjectCostItem API, we won't receive multiple updated cost items.
      const index = this.rowData.findIndex((item) => item.id === undefined);
      if (index > -1) {
        // update rowData
        this.rowData[index] = updatedItem;
        this.getTotalCost();
        this.addUpdateGridData(index);
      } else {
        // update values
        const existItemId = this.rowData.findIndex(
          (item) => item.id === updatedItem.id
        );
        if (existItemId > -1) {
          // update rowData
          this.rowData[existItemId] = updatedItem;
          this.getTotalCost();

          // update grid data
          const rowNode = this.gridApi.getDisplayedRowAtIndex(existItemId);
          if (rowNode) {
            rowNode.setData(this.rowData[existItemId]);
          }
        }
      }
      // this.gridApi.refreshCells({force: true}); didn't work
      this.gridApi.redrawRows();
    }
  }

  addUpdateGridData(index = this.rowData.length - 1) {
    const rowNode = this.gridApi.getDisplayedRowAtIndex(index);
    if (rowNode === undefined) {
      this.gridApi.updateRowData({ add: [this.rowData[index]] });
      this.gridApi.refreshCells({ force: true });
    } else {
      rowNode.setData(this.rowData[index]);
    }
  }

  // Callback: call this when checkbox status is changed
  selectionChanged($event) {
    const rowCount = this.gridApi.getSelectedNodes().length;
    if (rowCount) {
      this.buttons.delete.disabled = false;
    } else {
      this.buttons.delete.disabled = true;
    }
  }

  // ag-grid callback: filter, sort and group
  gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._costEditorGridSettingsService.storeGridStateByApis(
        this.gridStateStorageKey,
        this.gridApi,
        this.columnApi
      );
    }
  }

  // Callback from rowValueChanged (finished editing a row)
  rowValueChanged(event) {
    if (!this.readOnly) {
      this._costEditorGridSettingsService.errorRows = this.checkForRepeats();
      this.gridApi.refreshCells({ rowNodes: [event.node], force: true });
      this.gridApi.redrawRows();
      const costItem = new ProjectCostItem(event.data);
      this.update.emit(costItem);
    }
  }

  cellEditingStarted(event) {
    this.buttons.add.disabled = true;
  }

  cellEditingStopped(event) {
    this.buttons.add.disabled = false;
  }

  checkForRepeats() {
    return this.rowData.reduce((accum, row1, index1) => {
      this.rowData.forEach((row2, index2) => {
        if (
          index1 !== index2 &&
          row1.description === row2.description &&
          row1.source.name === row2.source.name &&
          !accum.includes(index1)
        ) {
          accum.push(index1);
        }
      });
      return accum;
    }, []);
  }

  // callback of Action buttons
  clicked(event) {
    if (event === 'add') {
      this.addButton();
    } else if (event === 'delete') {
      this.deleteButton();
    }
  }

  isConnections(): string {
    let result = 0;
    if (this.connections > 0) {
      result = this.totalCost / this.connections;
    }
    return new CurrencyPipe(this._languageService.getLanguage()).transform(
      result,
      this.currency,
      this.symbol,
      '1.0-0'
    );
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this._costEditorGridSettingsService.exportCsv(
          this.gridApi,
          this.title,
          true
        );
        break;
      case GridSubAction.clearFilter:
        this._costEditorGridSettingsService.clearStoredGridState(
          this.gridStateStorageKey
        );
        break;
    }
  }

  private addButton() {
    const highestGenericValue = this.rowData
      .filter((item) => {
        return item.description.includes('Generic');
      })
      .reduce((accum, theRow) => {
        const arr = theRow.description.split(' ');
        if (arr[0] === 'Generic' && parseInt(arr[1], 10) > accum) {
          accum = parseInt(arr[1], 10);
        }
        return accum;
      }, 0);

    const sources = new ProjectCostSources({
      name: this.typeList[0].value,
      id: this.typeList[0].id,
    });
    const costItem = new ProjectCostItem({
      description: `Generic ${highestGenericValue + 1}`,
      source: sources,
      units: 'Unit',
    });
    this.update.emit(costItem);
  }

  private deleteButton() {
    const rows = this.gridApi.getSelectedRows();
    if (rows?.length > 0) {
      this.delete.emit(rows);
      this.buttons.add.disabled = false;
      this.buttons.delete.disabled = true;
    }
  }

  private getTotalCost() {
    this.totalCost = this.rowData
      .map((item) => item.totalCost)
      .reduce((a, b) => a + b, 0);
  }
}
