<header>
  <div class="go-back" (click)="emitCloseEvent()">
    <div class="icon-container">
      <img src="/assets/icons/line-icons/shared/orange/arrow-left.svg" width="15px" />
    </div>
    {{ 'offtaker.overview.back-to-sites' | translate }}
  </div>
  <h2>{{ 'offtaker.add-c-and-i' | translate }}</h2>
  <p>{{ 'offtaker.sub-heading' | translate }}</p>
</header>

<div class="modal-body">
  <h5 class="bold">{{ 'offtaker.overview-heading' | translate }}</h5>
  <p class="light">{{ 'offtaker.overview-text' | translate }}</p>

  <div class="mb-3">
    <oes-notify
      [new]="true"
      [iconName]="'info'"
      [message]="'offtaker.privacy-notice' | translate">
    </oes-notify>
  </div>

  <form [formGroup]="offtakerForm" class="offtaker-form">

    <!-- Offtaker Setup -->
    <div class="offtaker-setup">
      <label class="required">{{ 'offtaker.setup' | translate }}</label>
      <p class="m-0 light">{{ 'offtaker.setup-text' | translate }}</p>
      <div class="radio-inputs d-flex align-items-center">
        <div class="radio-input d-flex align-items-center">
          <input type="radio" value="SINGLE" name="setup" formControlName="setup" />
          <label for="setup">{{ 'offtaker.single' | translate }}</label>
        </div>
        <div class="radio-input d-flex align-items-center">
          <input type="radio" value="MULTIPLE" name="setup" formControlName="setup" />
          <label for="setup">{{ 'offtaker.multiple' | translate }}</label>
        </div>
      </div>
    </div>

    <div class="overview-container">

      <!-- Name -->
      <div class="name">
        <label for="name" class="required">{{ 'offtaker.customer-name-' + multipleSetup | translate }}</label>
        <input type="text" id="name" [placeholder]="'offtaker.customer-name-placeholder-' + multipleSetup | translate" formControlName="name" />
      </div>

      <!-- Type -->
      <ng-container *ngIf="!multipleSetup">
        <div class="type">
          <label for="offtakerType" class="required">{{ 'offtaker.type' | translate }}</label>
          <select formControlName="offtakerType">
            <option *ngFor="let option of offtakerType" [value]="option">
              {{ 'offtaker.offtaker-type.' + option | translate }}
            </option>
          </select>
        </div>
      </ng-container>

      <!-- Locations -->
      <div class="locations" [ngClass]="{ 'locations-60' : multipleSetup }">
        <label for="selectOption" class="required">{{ 'offtaker.sites-located' | translate }}</label>
        <oes-dropdown
          *ngIf="countryOptions"
          formControlName="selectOption"
          id="countries"
          [parentFormGroup]="offtakerForm"
          [items]="countryOptions"
          [selection]="'multiple'"
          [customText]="customText"
          [hint]="'offtaker.select-countries' | translate"
          (selectionChanged)="countryChanged($event)"
          [enableUncheckAll]="false"
          [enableCheckAll]="false"
          [enableSearch]="true"
          ngDefaultControl>
        </oes-dropdown>
      </div>
    </div>


    <ng-container *ngIf="selectedCountries?.length">

      <hr />

      <h5 class="bold">{{ 'offtaker.site-config' | translate }}</h5>
      <p class="light">{{ 'offtaker.site-config-text' | translate }}</p>

      <div *ngFor="let country of selectedCountries" class="country-container">
        <h5 class="bold"><img [src]="'https://oes-hosted-images-public.s3.amazonaws.com/country-icons/'  + country?.code + '.svg'" />{{ country.name }}</h5>

        <div *ngFor="let config of configurationForms[country.code]">
          <form [formGroup]="config" class="config-form">

            <!-- Project Stage -->
            <div class="project-stage project-set-input">
              <label for="projectStage" class="required">{{ 'offtaker.project-stage-label' | translate }}</label>
              <select formControlName="projectStage">
                <option *ngFor="let option of projectStageOptions" [value]="option">
                  {{ 'offtaker.project-stage.' + option | translate }}
                </option>
              </select>
            </div>

            <!-- Grid Configuration -->
            <div class="grid-configuration project-set-input">
              <label class="required">
                {{ 'offtaker.grid-configuration-label' | translate }}
              </label>

              <div class="grid-configuration-inputs-container">
                <label for="gridTied">
                  <input type="checkbox" formControlName="gridTied" />
                  {{ 'offtaker.grid-tied-label' | translate }}
                </label>
                <label for="batteryStorage">
                  <input type="checkbox" formControlName="batteryStorage" />
                  {{ 'offtaker.battery-storage-label' | translate }}
                </label>
                <label for="dieselGenerator">
                  <input type="checkbox" formControlName="dieselGenerator" />
                  {{ 'offtaker.diesel-generator-label' | translate }}
                </label>
              </div>
            </div>

            <!-- Battery Storage -->
            <!-- <div class="battery-storage project-set-input">
            </div> -->

            <!-- Diesel Generator -->
            <!-- <div class="dieselGenerator project-set-input">
            </div> -->

            <!-- Currency -->
            <div class="currency project-set-input">
              <label for="currency" class="required">{{ 'offtaker.currency-label' | translate }}</label>
              <select formControlName="currency">
                <option *ngFor="let option of currencyOptions" [value]="option.id">
                  {{ 'currency-type.' + option.id | translate }}
                </option>
              </select>
            </div>

            <!-- Quantity -->
            <div class="quantity project-set-input">
              <label for="quantity" class="required">{{ 'offtaker.quantity-label' | translate }}</label>
              <input type="number" formControlName="quantity" />
            </div>

            <div class="remove">
              <ng-container *ngIf="config.controls['id'].value === null">
                <button (click)="removeConfig(country, config)"><img src="/assets/icons/line-icons/shared/red/close.svg"></button>
              </ng-container>
            </div>
          </form>
        </div>

        <button class="add-setup-button" (click)="addSetup(country.code)"><span class="plus">+</span> {{ 'offtaker.add' | translate }}</button>
      </div>
    </ng-container>
  </form>

</div>

<!-- Buttons (Cancel & Submit) -->
<footer>
  <div class="new-customer-buttons footer-content-container">
    <button class="btn btn-secondary" (click)="emitCloseEvent()">{{ 'buttons.cancel' | translate }}</button>
    <button class="btn btn-primary"
            [disabled]="!submitActive()"
            (click)="submitForm($event)">
      {{ 'buttons.confirm' | translate }}
    </button>
  </div>
</footer>
