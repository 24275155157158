import { Component, OnInit, Input } from '@angular/core';
import { FmFinanceIntroductionService } from './fm-finance-introduction.service';
import { FinanceMarketplaceService } from '@finance/investor-portfolio/shared/finance-marketplace.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { FinanceIntroduction } from '@finance/developer-portfolio/shared/finance-introduction.model';
import { Project } from '@project/shared/project.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-fm-introduction',
  templateUrl: './fm-introduction.component.html',
  styleUrls: ['./fm-introduction.component.scss'],
})
export class FmIntroductionComponent implements OnInit {
  @Input() projectGroupId: string;  // finance-marketplace/portfolio is using this component
  public projectGroup: ProjectGroup;
  public financeIntroduction: FinanceIntroduction;
  private currentProjectGroupId: string;
  public projects: Project[];

  constructor(private _financeMarketplaceService: FinanceMarketplaceService,
              private _fmFinanceIntroductionService: FmFinanceIntroductionService) {
  }

  ngOnInit() {
    // finance-marketplace calls with reject GroupId, when we removed finance-marketplace, please delete if(){} and keep else{}
    if (this.projectGroupId && this.projectGroupId !== '') {
      this.currentProjectGroupId = this.projectGroupId;
      this.getProjectGroup();
    } else {
      this.currentProjectGroupId = this._financeMarketplaceService.currentProjectGroupId;
      this.getProjectGroup();
    }
  }

  private getProjectGroup() {
    this._financeMarketplaceService.getProjectGroup(this.currentProjectGroupId)
    .pipe(take(1))
    .subscribe((projectGroup: ProjectGroup) => {
      this.projectGroup = projectGroup;
      this.getFinanceIntroduction();
      this.getProjects();
    });
  }

  private getFinanceIntroduction() {
    this._fmFinanceIntroductionService.getFinanceIntroduction(this.currentProjectGroupId)
    .pipe(take(1))
    .subscribe((financeIntroduction: FinanceIntroduction) => {
      this.financeIntroduction = financeIntroduction;
    });
  }

  private getProjects() {
    this._financeMarketplaceService.getProjects(this.currentProjectGroupId)
    .pipe(take(1))
    .subscribe((projects: Project[]) => {
      if (projects?.length > 0) {
        this.projects = projects;
      }
    });
  }

}
