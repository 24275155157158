import { Component, Input } from '@angular/core';
import { PROGRAM_MODULE_FLAGS } from '@program/shared/program-module-flags.enum';

@Component({
  selector: 'oes-project-overview-actions',
  templateUrl: './overview-actions.component.html',
  styleUrls: ['./overview-actions.component.scss']
})
export class OverviewActionsComponent {
  @Input() title: string;
  @Input() required: PROGRAM_MODULE_FLAGS;
  @Input() guideLink: string;
  @Input() editLink: string;
  @Input() projectOwner: boolean;
}
