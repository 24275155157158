<oes-modal-dialog
  #editMilestoneModal
  id="editMilestoneModal"
  [size]="'small'"
  [allowCancel]="true"
  [allowSubmit]="true"
  [hideCloseBtn]="true"
  (submit)="onSubmit()"
  [submitButtonText]="'buttons.confirm' | translate"
  [modalStyle]="'fresh'"
  (close)="handleCloseEditMilestone()">
  <ng-container *ngIf="modalShowing">
    <div class="milestone-edit">
      <h5 class="h5">{{ 'milestones.detail.compose-note' | translate }}</h5>
      <div *ngIf="milestoneEdit?.description?.length" class="milestone-edit__description">
        <p>{{ milestone.description }}</p>
      </div>
      <form #editForm="ngForm">
        <div class="section milestone-edit__status">
          <h5>{{ 'milestones.detail.update-milestone-status' | translate }}</h5>
          <div class="milestone-edit__status-choices">
            <input
              type="radio"
              id="statusNotStarted"
              name="status"
              [value]="statuses.NOT_STARTED"
              class="input-control radio-fresh"
              [(ngModel)]="milestoneEdit.status"
              #notStartedRadio
            >
            <label for="statusNotStarted" (click)="onStatusChange(statuses.NOT_STARTED, notStartedRadio)">
              {{ 'milestones.statuses.NOT_STARTED' | translate }}
            </label>
            <input
              type="radio"
              id="statusInProgress"
              name="status"
              [value]="statuses.IN_PROGRESS"
              class="input-control radio-fresh"
              [(ngModel)]="milestoneEdit.status"
              #inProgressRadio
            >
            <label for="statusInProgress" (click)="onStatusChange(statuses.IN_PROGRESS, inProgressRadio)">
              {{ 'milestones.statuses.IN_PROGRESS' | translate }}
            </label>
            <input
              type="radio"
              id="statusComplete"
              name="status"
              [value]="statuses.COMPLETED"
              class="input-control radio-fresh"
              [(ngModel)]="milestoneEdit.status"
              #completedRadio
            >
            <label for="statusComplete" (click)="onStatusChange(statuses.COMPLETED, completedRadio)">
              {{ 'milestones.statuses.COMPLETED' | translate }}
            </label>
          </div>
        </div>
        <div class="section milestone-edit__note">
          <h5>{{ 'milestones.note' | translate }}</h5>
          <textarea
            [placeholder]="'milestones.enter-note' | translate"
            name="note"
            class="input-control textarea-fresh"
            [(ngModel)]="noteContent"
            maxlength="1000"
          ></textarea>
          <p
            class="form-control validation-error"
            *ngIf="editForm.controls['note']?.errors?.maxlength && editForm.controls['note']?.dirty"
            >
            {{ 'milestones.validation.note-max-length' | translate }}
          </p>
          <p
            class="form-control validation-error"
            *ngIf="noteContent.length === 1000 && editForm.controls['note']?.dirty"
            >
            {{ 'milestones.validation.note-at-max-length' | translate }}
          </p>
          <p
            class="form-control validation-error"
            *ngIf="showNeedsNoteContentError"
            >
            {{ 'milestones.validation.needs-note-content' | translate }}
          </p>
          <p class="note-content-info">{{ 'milestones.note-info' | translate }}</p>
        </div>
        <div class="section milestone-edit__attachments">
          <div class="milestone-edit__attachments-row">
            <h5>{{ 'milestones.detail.note-attachments' | translate }}</h5>
            <button class="btn btn-fresh btn-link" (click)="uploadDocumentClick($event)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0001 4.16666V15.8333M4.16675 9.99999H15.8334" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ 'buttons.upload-files' | translate }}
            </button>
          </div>
          <ng-container *ngIf="allDocuments.length else documentsEmptyEdit">
            <div class="milestone-edit__attachments-list" *ngFor="let document of allDocuments">
              <div class="milestone-edit__attachments-list-item">
                <div class="icon-round-bg">
                  <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/black/attachment.svg" role="presentation" />
                </div>
                <div class="milestone-edit__attachments-list-item-text">
                  <p class="weight-semibold title">{{ document }}</p>
                </div>
                <button
                  class="milestone-edit__attachments-list-item-remove btn btn-fresh"
                  (click)="removeDocumentClick($event, document)"
                >
                  <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/black/remove.svg" role="presentation">
                  {{ 'buttons.remove' | translate }}
                </button>
              </div>
            </div>
            <p
              class="form-control validation-error"
              *ngIf="showDocumentAddedError"
              >
              {{ 'milestones.validation.documentAddedError' | translate }}
            </p>
          </ng-container>
          <ng-template #documentsEmptyEdit>
            <div class="box-container d-flex flex-column justify-content-center align-items-center">
              <div class="icon-round-bg">
                <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/grey/upload-cloud.svg" role="presentation">
              </div>
              <p>{{ 'milestones.detail.no-attachments' | translate }}</p>
              <a
                class="box-container__link"
                (click)="uploadDocumentClick($event)"
                href="#"
              >{{ 'buttons.upload-files' | translate }}</a>
            </div>
          </ng-template>
        </div>
      </form>
    </div>
  </ng-container>
</oes-modal-dialog>
