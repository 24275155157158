<div class="profile-container">

  <!-- Profile Headers -->
  <div class="profile-headers">
    <div class="profile-logo">
      <div class="in-between">
        <ng-container *ngIf="logoUri; else NoImage">
          <img class="img-fluid" [src]="logoUri">
        </ng-container>
        <ng-template #NoImage>
          <div class="no-image img-fluid" title="no image"><i class="fa fa-picture-o" aria-hidden="true"></i></div>
        </ng-template>
      </div>
    </div>
    <div class="ph-right">
      <div class="ph-small d-flex align-items-center">
        <div>
          <h5 class="bold mb-1">{{ organization?.name }}</h5>
          <p class="small mb-0">{{ organization?.yearsInBusiness }}
            <span *ngIf="organization?.yearsInBusiness">{{ 'investor-profile.years-in-business' | translate }}</span>
          </p>
          <p class="small mb-0">{{ organization?.numberOfEmployees }}
            <span *ngIf="organization?.numberOfEmployees">{{ 'investor-profile.employees'|translate }}</span>
          </p>
        </div>
      </div>
      <div class="ph-small d-flex align-items-center">
        <img class="location-image" src="/assets/images/profiles/location-grey.svg" />
        <div>
          <p class="small mb-0">{{ 'investor-profile.headquarters-location' | translate }}</p>
          <h5 class="bold mb-1">{{ organization?.headquartersLocation }}</h5>
        </div>
      </div>
      <div class="ph-small d-flex align-items-center">
        <img src="/assets/images/profiles/location-other-grey.svg" />
        <div>
          <p class="small mb-0">{{ 'investor-profile.other-locations' | translate }}</p>
          <h5 class="bold mb-1">{{ organization?.otherLocations }}</h5>
        </div>
      </div>
    </div>
  </div>

  <!-- Profile Main w/ Map -->
  <div class="profile-main">
    <div class="pm-left">
      <h5 class="bold mb-2">{{ 'investor-profile.sector-experience.investment-countries' | translate }}</h5>
      <div class="left-1">
        <oes-countries-map>
        </oes-countries-map>
        <div *ngIf="allAfrica && countries && countries.length > 0" class="countries-list-africa" >
          <div *ngFor="let country of countries" class="small">
            {{ country }}
          </div>
        </div>
      </div>
    </div>

    <div class="pm-right">
      <h5 class="bold mb-2">{{'investor-profile.company-overview'|translate}}</h5>
      <p class="mb-4" [innerHtml]="organization?.companyOverview | safeHtml"></p>
      <h5 class="bold mb-2">{{'investor-profile.sector-experience.heading' | translate}}</h5>
      <p class="mb-1" [innerHtml]="investorInformation?.sectorExperience | safeHtml"></p>
    </div>
  </div>

  <!-- Profile Bottom -->
  <div class="profile-bottom d-flex">
    <div class="pb-capital">
      <p class="bold mb-2">{{ 'investor-profile.sector-experience.capital-offering' | translate }}</p>
      <div *ngFor="let capital of capitalOffering" class="small mb-0">
        {{ 'investor-profile.sector-experience.' + capital | translate }}
      </div>
    </div>
    <div class="pb-involvement">
      <p class="bold mb-2">{{ 'investor-profile.sector-experience.power-sector-involvement' | translate }}</p>
      <div *ngFor="let activity of sectorActivities" class="small mb-0">
          {{ 'investor-profile.sector-experience.' + activity | translate }}
      </div>
    </div>
    <div class="pb-investment">
      <h5 class="bold mb-2">{{ 'investor-profile.investment.heading' | translate }}</h5>
      <p class="mb-1" [innerHtml]="investorInformation?.investmentObjectives | safeHtml"></p>
    </div>
  </div>

  <!-- Profile Metrics -->
  <div class="profile-metrics d-flex justify-content-center">
    <div class="profile-metric border-right">
      <div>
        <p class="bold mb-2 text-center">{{ 'investor-profile.investment.minimum-investment-size' | translate }}</p>
        <h3 class="text-center bold">{{ investorInformation?.noMinimumProjectSize ?
          ('investor-profile.investment.no-minimum' |
          translate) : (investorInformation?.minimumProjectSize | currency:'USD':'symbol':'1.0-0') || '-' }}</h3>
      </div>
    </div>
    <div class="profile-metric border-right">
      <div>
        <p class="bold mb-2 text-center">{{ 'investor-profile.investment.maximum-investment-size' | translate }}</p>
        <h3 class="text-center bold">{{ investorInformation?.noMaximumProjectSize ?
            ('investor-profile.investment.no-maximum' |
            translate) : (investorInformation?.maximumProjectSize | currency:'USD':'symbol':'1.0-0') || '-' }}</h3>
      </div>
    </div>
    <div class="profile-metric border-right">
      <div>
        <p class="bold mb-2 text-center">{{'investor-profile.investment.preferred-size' | translate}}</p>
        <h3 class="text-center bold">{{ investorInformation?.noPreferredSize ?
            ('investor-profile.investment.no-preferred-size'
            | translate) : (investorInformation?.preferredSize | currency:'USD':'symbol':'1.0-0') || '-' }}</h3>
      </div>
    </div>
    <div class="profile-metric border-right">
      <div>
        <p class="bold mb-2 text-center">{{ 'investor-profile.investment.preferred-term' | translate }}</p>
        <h3 class="text-center bold" *ngIf="!investorInformation?.noPreferredTerm">
          {{ investorInformation?.preferredTerm ? (investorInformation.preferredTerm | number:'1.0-0') : '-' }}
            <span *ngIf="investorInformation?.preferredTerm">{{ 'units.years' | translate }}</span>
        </h3>
        <h3 class="text-center bold" *ngIf="investorInformation?.noPreferredTerm">
          {{ 'investor-profile.investment.no-preferred-term' | translate }}</h3>
      </div>
    </div>
    <div class="profile-metric">
      <div>
        <p class="bold mb-2 text-center">{{ 'investor-profile.investment.invested-in-microgrids' | translate }}?</p>
        <h3 class="text-center bold">{{ investorInformation?.investedInMicroGrids ? ('investor-profile.investment.yes' |
            translate) : ('investor-profile.investment.no' | translate) }}</h3>
      </div>
    </div>
  </div>
</div>
