import { Injectable } from '@angular/core';
import { ProjectSet } from '@project/offtakers/project-set/project-set.model';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { CellButtonComponent } from '@shared/ag-grid/component/cell-button/cell-button.component';

@Injectable({
  providedIn: 'root'
})
export class ProjectSetPortfolioListGridSettingService extends BaseAgGridSettingsService {
  private column = {};
  private units = {};
  private statusKeys = {};
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;
  private agGridServicesWrapperService: AgGridServicesWrapperService;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.agGridServicesWrapperService = _agGridServicesWrapperService;
    this.column = _agGridServicesWrapperService._translateService.instant('offtaker.project-set.portfolio.column');
    this.units = _agGridServicesWrapperService._translateService.instant('units');
    this.statusKeys = _agGridServicesWrapperService._translateService.instant('offtaker.project-set-portfolio.statuses');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      autoGroupColumnDef: {
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          checkbox: true
        }
      },
      columnDefs: this.getColumnSetting(),
      components: {
        agDateInput: DateFilterComponent,
        cellButtonComponent: CellButtonComponent
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      rowHeight: 80,
      sideBar: {
        toolPanels: ['columns']
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count']
            },
            align: 'right'
          }
        ]
      },
      suppressColumnMoveAnimation: true,
      suppressDragLeaveHidesColumns: true,
      suppressRowClickSelection: false
    };
  }

  public getColumnSetting(): any {
    const colDef = [
      // Name
      {
        headerName: this.column['name'],
        headerTooltip: this.column['name'],
        field: 'name',
        resizable: true,
        suppressSizeToFit: true,
        width: 400,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        filter: false,
        sortable: true,
        suppressMenu: true,
        cellRenderer: (params) => {
          return `<a class="hyperlink" href="#/oes/finance/ci-portfolios/${params.data.id}/project-overview" title="Open ${params.data.name}">${params.data.name}</a>`;
        }
      },
      // Program
      {
        headerName: this.column['program'],
        headerTooltip: this.column['program'],
        field: 'program.name',
        resizable: true,
        suppressSizeToFit: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        filter: false,
        sortable: true,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params?.value) {
            return `<span class="bold">${params.value}</span>`;
          }
          return this.column['not-linked'];
        }
      },
      // Status
      {
        headerName: this.column['status'],
        headerTooltip: this.column['status'],
        field: 'status',
        resizable: true,
        suppressSizeToFit: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        filter: false,
        sortable: true,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (!params?.data?.program?.id) {
            return '-';
          }
          if (params?.value) {
            const status = params.value;
            return `<span class="bullet ${ status }">
                      ${ this.statusKeys[status] }
                    </span>`;
          }
          return '-';
        }
      },
      // Total Projects
      {
        headerName: this.column['total-projects'],
        headerTooltip: this.column['total-projects'],
        field: 'projectSets',
        width: 240,
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        sortable: true,
        filter: false,
        enableRowGroup: true,
        cellRenderer: (params) => {
          const projectSets: ProjectSet[] = params?.value;
          const totals = projectSets?.reduce((accum, projectSet) => {
            accum += projectSet.totalProjects || 0;
            return accum;
          }, 0);
          if (totals?.toLocaleString !== null && totals?.toLocaleString() !== undefined) {
            return totals?.toLocaleString();
          }
          return '-';
        }
      },
      // Total System Size
      {
        headerName: this.column['total-system-size'],
        headerTooltip: this.column['total-system-size'],
        field: 'projectSets',
        width: 240,
        unSortIcon: true,
        enableValue: true,
        filter: false,
        enableRowGroup: true,
        sortable: true,
        cellRenderer: (params) => {
          const projectSets: ProjectSet[] = params?.value;
          const totals = projectSets?.reduce((accum, projectSet) => {
            accum += (projectSet.averageSystemSize || 0) * (projectSet.totalProjects || 0);
            return accum;
          }, 0);
          if (totals?.toLocaleString() !== null && totals?.toLocaleString() !== undefined) {
            return totals?.toLocaleString() + ' ' + this.units['kWp'];
          }
          return '-';
        }
      },
      // Total CapEx
      {
        headerName: this.column['total-capex'],
        headerTooltip: this.column['total-capex'],
        field: 'projectSets',
        unSortIcon: true,
        enableValue: true,
        filter: false,
        width: 240,
        enableRowGroup: true,
        sortable: true,
        cellRenderer: (params) => {
          const projectSets: ProjectSet[] = params?.value;
          const totals =  projectSets?.reduce((accum, projectSet) => {
            accum += (projectSet.totalCapex || 0);
            return accum;
          }, 0);
          const firstCurrencyType = projectSets && projectSets[0] ? projectSets[0].currencyType : null;
          if (projectSets?.every(projectSet => projectSet.currencyType === firstCurrencyType)) {
            if (firstCurrencyType?.toString() === 'USD') {
              return '$' + totals?.toLocaleString();
            } else {
              return totals?.toLocaleString() + ' ' + firstCurrencyType;
            }
          }
          return '-';
        }
      },
      // Avg. EBITDA Margin
      // {
      //   headerName: this.column['avg-ebitda-margin'],
      //   headerTooltip: this.column['avg-ebitda-margin'],
      //   field: 'projectSets',
      //   width: 240,
      //   unSortIcon: true,
      //   filter: false,
      //   enableValue: true,
      //   sortable: true,
      //   enableRowGroup: true,
      //   cellRenderer: (params) => {
      //     return 'TO DO';
      //   }
      // },
    ];
    return colDef;
  }
}
