
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { Organization } from '@shared/models/organization/organization.model';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';
import { ProjectGroupService } from '@shared/services/project-group.service';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';

@Component({
  selector: 'oes-investor-profiles',
  templateUrl: './investor-profiles.component.html',
  styleUrls: ['./investor-profiles.component.scss'],
})
export class InvestorProfilesComponent implements OnInit {
  public organizations: Organization[];
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _userService: UserService,
              private _projectGroupService: ProjectGroupService,
              private _dynamicHomeService: DynamicHomeService) {
  }

  ngOnInit() {
    this._dynamicHomeService.saveUrl(true);
    this.getInvestorList();
  }

  private getInvestorList() {
    if (this._userService.getSelectedRole() === ROLE_TYPE.DEVELOPER_USER) {
      this._projectGroupService.investorList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((organizations: Organization[]) => {
        this.organizations = organizations;
      });
    }
  }
}
