<h5>{{ 'program.evaluation.criteria' | translate }}: {{ evaluations[0].evaluationConfig?.name }}</h5>

<div oesAgHorizontalScroll>
  <ag-grid-angular
    id="evaluationCriteriaGrid"
    style="width: auto;"
    class="grid ag-theme-alpine"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>
</div>
