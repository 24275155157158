import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Offtaker } from '../../offtaker.model';
import { ProjectSet } from '../project-set.model';
import { ProjectSetService } from '../project-set.service';

@Component({
  selector: 'oes-project-set-edit',
  templateUrl: 'project-set-edit.component.html',
  styleUrls: ['./project-set-edit.component.scss']
})
export class ProjectSetEditComponent implements OnInit, OnDestroy {
  @Input() activeTab = 'details';
  @Input() projectSetId: string;
  @Input() readOnly: boolean;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngUnsubscribe: Subject<any> = new Subject();
  offtaker: Offtaker;
  projectSet: ProjectSet;

  constructor(private _projectSetService: ProjectSetService) {
  }

  ngOnInit() {
    this.getProjectSet();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  emitCloseEvent() {
    this.close.emit(true);
  }

  selectTab(value: string) {
    this.activeTab = value;
  }

  getProjectSet() {
    this._projectSetService.detail(this.projectSetId)
      .pipe(take(1))
      .subscribe((projectSet: ProjectSet) => {
        this.projectSet = projectSet;
        this.offtaker = projectSet.offtaker;
      });
  }
}
