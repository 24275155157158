import { Component, Input } from '@angular/core';
import { FinanceIntroduction } from '@finance/developer-portfolio/shared/finance-introduction.model';

@Component({
  selector: 'oes-portfolio-generation-system',
  templateUrl: './generation-system.component.html',
  styleUrls: ['./generation-system.component.scss']
})
export class PortfolioGenerationSystemComponent {
  @Input() financeIntroduction: FinanceIntroduction;

  constructor() {
  }

}
