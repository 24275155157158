import { Component } from '@angular/core';

import { DIALOG_BUTTON_STATUS, DialogButtonAction } from './dialog-button-action.model';
import { UnsavedChangesDialogService } from './unsaved-changes-dialog.service';

@Component({
  selector: 'oes-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {
  // The below variables are set by _modalService's initialState
  buttonDisabled = false;
  buttons = [];
  message = '';
  nextUrl = '';
  pageName = '';
  status = DIALOG_BUTTON_STATUS;

  constructor(private _unsavedChangesDialogService: UnsavedChangesDialogService) {
  }

  getButtonClass(name: string): string[] {
    // The default color is btn-secondary
    let buttonClass = ['btn-secondary'];
    // Special button color
    switch (name) {
      case DIALOG_BUTTON_STATUS.SAVE:
      case DIALOG_BUTTON_STATUS.CALCULATE:
        buttonClass = ['btn-primary'];
        if (this.buttonDisabled) {
          buttonClass = ['btn-primary loading-text'];
        }
        break;
      case DIALOG_BUTTON_STATUS.DO_NOT_SAVE:
      case DIALOG_BUTTON_STATUS.DO_NOT_CALCULATE:
        buttonClass = ['btn-destructive'];
        break;
    }
    return buttonClass;
  }

  clickButton(status: DIALOG_BUTTON_STATUS) {
    const action = <DialogButtonAction>{
      pageName: this.pageName,
      action: status,
      nextUrl: this.nextUrl
    };
    this._unsavedChangesDialogService.execute(action);
    this.buttonDisabled = true;
  }
}
