import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { ColumnConfigFactory } from '@project/shared/grid/column-config-factory';

@Injectable({
  providedIn: 'root'
})
export class ManageOfftakersGridSettingService extends BaseAgGridSettingsService {
  private column = [];
  private currencyTypes = {};
  private gridConfigurationTypes = [];
  private offtaker = {};
  private stages = {};

  public columnValues = [];
  public operatorValues = [];
  public typeValues = [
    'User Specified',
    'Fill from column'
  ];
  public currentGridState: GridState = new GridState();
  public initialGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
      private _columnConfigFactory: ColumnConfigFactory) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('offtaker.column');
    this.currencyTypes = _agGridServicesWrapperService._translateService.instant('currency-type');
    this.gridConfigurationTypes = _agGridServicesWrapperService._translateService.instant('offtaker.grid-configuration');
    this.offtaker = _agGridServicesWrapperService._translateService.instant('offtaker');
    this.stages = _agGridServicesWrapperService._translateService.instant('offtaker.project-stage');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      columnDefs: this.getColumnSetting(),
      components: {
        agDateInput: DateFilterComponent
      },
      context: {
        currentLang: this.currentLang
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true,
        enableRowGroup: true,
        suppressSizeToFit: true,
        unSortIcon: true,
        enableValue: true,
        width: 200
      },
      enableRangeSelection: true,
      groupDisplayType: 'singleColumn',
      localeText: this.globalTranslations,
      overlayLoadingTemplate: '<div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>',
      rowGroupPanelShow: 'always',
      rowHeight: 80,
      rowMultiSelectWithClick: true,
      rowSelection: 'multiple',
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum', 'avg'] // options: 'count', 'sum', 'min', 'max', 'avg'
            },
            align: 'right'
          }
        ]
      },
      suppressRowClickSelection: true,
    };
  }

  public getColumnSetting(): any {
    const columnConfigs = this._columnConfigFactory;
    return [
      {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params?.data ? true : false;
        },
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left'
      },
      {
        headerName: this.column['organization-name'],
        headerTooltip: this.column['organization-name'],
        field: 'developerOrganization.name',
        width: 400,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        pinned: 'left'
      },
      {
        headerName: this.column['customer-name'],
        headerTooltip: this.column['customer-name'],
        field: 'offtaker.name',
        width: 400,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['average-annual-degradation'],
        headerTooltip: this.column['average-annual-degradation'],
        field: 'averageAnnualDegradation',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['total-capex'],
        headerTooltip: this.column['total-capex'],
        field: 'totalCapex',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-contract-length'],
        headerTooltip: this.column['average-contract-length'],
        field: 'averageContractLength',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-customer-consumption'],
        headerTooltip: this.column['average-customer-consumption'],
        field: 'averageCustomerConsumption',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-diesel-consumption-price'],
        headerTooltip: this.column['average-diesel-consumption-price'],
        field: 'averageDieselConsumptionPrice',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-grid-consumption-price'],
        headerTooltip: this.column['average-grid-consumption-price'],
        field: 'averageGridConsumptionPrice',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-pv-production'],
        headerTooltip: this.column['average-pv-production'],
        field: 'averagePvProduction',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-system-size'],
        headerTooltip: this.column['average-system-size'],
        field: 'averageSystemSize',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['average-tariff'],
        headerTooltip: this.column['average-tariff'],
        field: 'averageTariff',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['battery-storage'],
        headerTooltip: this.column['battery-storage'],
        field: 'batteryStorage',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['comments'],
        headerTooltip: this.column['comments'],
        field: 'comments',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['connection-responsibility'],
        headerTooltip: this.column['connection-responsibility'],
        field: 'connectionResponsibility',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['consumption-q1'],
        headerTooltip: this.column['consumption-q1'],
        field: 'consumptionQ1',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['consumption-q2'],
        headerTooltip: this.column['consumption-q2'],
        field: 'consumptionQ2',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['consumption-q3'],
        headerTooltip: this.column['consumption-q3'],
        field: 'consumptionQ3',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['consumption-q4'],
        headerTooltip: this.column['consumption-q4'],
        field: 'consumptionQ4',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['location-of-sites'],
        headerTooltip: this.column['location-of-sites'],
        field: 'country.name',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['currency-type'],
        headerTooltip: this.column['currency-type'],
        field: 'currencyType',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['diesel-generator'],
        headerTooltip: this.column['diesel-generator'],
        field: 'dieselGenerator',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['estimated-start-date'],
        headerTooltip: this.column['estimated-start-date'],
        field: 'estimatedStartDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['even-consumption-per-quarter'],
        headerTooltip: this.column['even-consumption-per-quarter'],
        field: 'evenConsumptionPerQuarter',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['even-production-per-quarter'],
        headerTooltip: this.column['even-production-per-quarter'],
        field: 'evenProductionPerQuarter',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['even-spend'],
        headerTooltip: this.column['even-spend'],
        field: 'evenSpend',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['feed-in-tariff'],
        headerTooltip: this.column['feed-in-tariff'],
        field: 'feedInTariff',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['grid-consumption-percentage'],
        headerTooltip: this.column['grid-consumption-percentage'],
        field: 'gridConsumptionPercentage',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['grid-tied'],
        headerTooltip: this.column['grid-tied'],
        field: 'gridTied',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['insurance-expense'],
        headerTooltip: this.column['insurance-expense'],
        field: 'insuranceExpense',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['months-to-operation'],
        headerTooltip: this.column['months-to-operation'],
        field: 'monthsToOperation',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['offtaker-consumption-required'],
        headerTooltip: this.column['offtaker-consumption-required'],
        field: 'offtakerConsumptionRequired',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['om-expense'],
        headerTooltip: this.column['om-expense'],
        field: 'omExpense',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['opex-inflation'],
        headerTooltip: this.column['opex-inflation'],
        field: 'opexInflation',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['pass-through-inflation'],
        headerTooltip: this.column['pass-through-inflation'],
        field: 'passThroughInflation',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['pv-production-q1'],
        headerTooltip: this.column['pv-production-q1'],
        field: 'pvProductionQ1',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['pv-production-q2'],
        headerTooltip: this.column['pv-production-q2'],
        field: 'pvProductionQ2',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['pv-production-q3'],
        headerTooltip: this.column['pv-production-q3'],
        field: 'pvProductionQ3',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['pv-production-q4'],
        headerTooltip: this.column['pv-production-q4'],
        field: 'pvProductionQ4',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m1'],
        headerTooltip: this.column['spend-m1'],
        field: 'spendM1',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m2'],
        headerTooltip: this.column['spend-m2'],
        field: 'spendM2',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m3'],
        headerTooltip: this.column['spend-m3'],
        field: 'spendM3',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m4'],
        headerTooltip: this.column['spend-m4'],
        field: 'spendM4',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m5'],
        headerTooltip: this.column['spend-m5'],
        field: 'spendM5',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m6'],
        headerTooltip: this.column['spend-m6'],
        field: 'spendM6',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m7'],
        headerTooltip: this.column['spend-m7'],
        field: 'spendM7',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m8'],
        headerTooltip: this.column['spend-m8'],
        field: 'spendM8',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m9'],
        headerTooltip: this.column['spend-m9'],
        field: 'spendM9',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m10'],
        headerTooltip: this.column['spend-m10'],
        field: 'spendM10',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m11'],
        headerTooltip: this.column['spend-m11'],
        field: 'spendM11',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['spend-m12'],
        headerTooltip: this.column['spend-m12'],
        field: 'spendM12',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['stage'],
        headerTooltip: this.column['stage'],
        field: 'stage',
        cellRenderer: (params) => {
          return this.stages[params.value];
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
      {
        headerName: this.column['stranded-production-percentage'],
        headerTooltip: this.column['stranded-production-percentage'],
        field: 'strandedProductionPercentage',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['take-or-pay'],
        headerTooltip: this.column['take-or-pay'],
        field: 'takeOrPay',
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['tariff-escalation'],
        headerTooltip: this.column['tariff-escalation'],
        field: 'tariffEscalation',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        }
      },
      {
        headerName: this.column['total-sites'],
        headerTooltip: this.column['total-sites'],
        field: 'totalProjects',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
      },
    ];
  }
}
