<ng-container *ngFor="let evaluation of evalDisplay">
  <oes-evaluation-question
    [evaluation]="evaluation"
    (reload)="getFormData()">
  </oes-evaluation-question>
</ng-container>
<ng-container *ngIf="evalDisplay?.length">
  <div class="totals">
    <p>{{ 'program.evaluation.total-score' | translate }}</p>
    <p class="tag pass-fail-tag" [ngClass]="{ 'pass' : totals.passFail === 'PASS', 'fail' : totals.passFail === 'FAIL' }">{{ 'program.evaluation.' + totals.passFail | translate }}</p>
    <p class="tag">{{ totals.totalScore | number: '1.0-1' }}/100</p>
  </div>
</ng-container>
