import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '@project/shared/project.model';
import { ProjectGroup } from '@shared/services/project-group.model';

@Injectable({
  providedIn: 'root'
})
export class FinanceMarketplaceService extends BaseRestApiService {
  private fmMarketPlaceUri = environment.serverUri + `api/${environment.apiVersion}/fm/market-place`;
  private _currentProjectGroupId: string;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  /**
   * navigation/fm-navigation sets _currentProjectGroup
   */
  set currentProjectGroupId(projectGroupId: string) {
    this._currentProjectGroupId = projectGroupId;
  }

  get currentProjectGroupId() {
    return this._currentProjectGroupId;
  }

  public getProjectGroup(projectGroupId: string): Observable<ProjectGroup> {
    return this.get<ProjectGroup>(`${this.fmMarketPlaceUri}/${projectGroupId}`)
      .pipe(
        map((projectGroup: ProjectGroup) => new ProjectGroup(projectGroup))
      );
  }

  public getProjects(projectGroupId: string): Observable<Project[]> {
    return this.get<Project[]>(`${this.fmMarketPlaceUri}/${projectGroupId}/projects`, {params: {page: 0, size: 1000}})
      .pipe(
        map((projects: Project[]) => projects.map(project => new Project(project)))
      );
  }

  public formatNumber(num: number, fixed: number) {
    if (num == null) {
      return 0;
    } // terminate early
    if (num === 0) {
      return '0';
    } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    const b: any = (num).toPrecision(2).split('e'); // get power
    const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
    const c: any = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(fixed); // divide by power
    const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
    const e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
  }
}
