<div class="organization-list container-fluid">
  <div class="sub-action sub-action-background">
    <oes-grid-sub-action
      *ngIf="gridApi"
      [gridState]="gridState"
      [defaultGridState]="defaultGridState"
      [reload]="false"
      (action)="subAction($event)">
    </oes-grid-sub-action>
  </div>
  <div class="ag-grid-header-sticky" oesAgHorizontalScroll>
    <ag-grid-angular
      id="programList"
      style="width: auto;"
      class="grid ag-theme-alpine"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (filterChanged)="gridStatusChanged($event, 'filter')"
      (sortChanged)="gridStatusChanged($event, 'sort')"
      (displayedColumnsChanged)="gridStatusChanged($event, 'column')">
    </ag-grid-angular>
  </div>
</div>

<oes-modal-dialog
  class="organization-profile-dialog"
  [size]="'x-large'"
  [allowCancel]="false"
  [allowSubmit]="false"
  [title]="'organization-list.title' | translate"
  (close)="organizationId = ''">
  <div [ngSwitch]="profileType">
    <ng-container *ngSwitchCase="organizationProfileType.vendor">
      <oes-vendor-profile
        *ngIf="organizationId && organizationId !== ''"
        [id]="organizationId">
      </oes-vendor-profile>
    </ng-container>
    <ng-container *ngSwitchCase="organizationProfileType.developer">
      <oes-developer-profile
        *ngIf="organizationId && organizationId !== ''"
        [id]="organizationId">
      </oes-developer-profile>
    </ng-container>
    <ng-container *ngSwitchCase="organizationProfileType.investor">
      <oes-investor-profile
        *ngIf="organizationId && organizationId !== ''"
        [id]="organizationId">
      </oes-investor-profile>
    </ng-container>
  </div>
</oes-modal-dialog>

