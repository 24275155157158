<form [formGroup]="formGroup">
  <div class="date-selector">
    <p class="wrapper calendar-wrapper">
      <input
        type="text"
        autocomplete="off"
        [placeholder]="'calendar.format' | translate"
        bsDatepicker
        [bsConfig]="bsConfig"
        placement="top"
        formControlName="{{controlName}}">
    </p>
  </div>
</form>
