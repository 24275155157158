import {Injectable} from '@angular/core';
import {BaseModel} from '@shared/base.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class FinanceImage extends BaseModel<FinanceImage> {
  filename: string;
  fileType: string;
  primaryImage: boolean;

  constructor(financeImageInfo: any) {
    super();
    this.id = financeImageInfo.id;
    this.filename = financeImageInfo.filename;
    this.fileType = financeImageInfo.fileType;
    this.primaryImage = financeImageInfo.primaryImage;
}
}

@Injectable({
  providedIn: 'root'
})
export class FinanceImageService extends BaseRestApiService {
  public projectGroups = environment.serverUri + `api/${environment.apiVersion}/project-groups`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(projectGroupId: string): Observable<FinanceImage[]> {
    return this.get<FinanceImage[]>(`${this.projectGroups}/${projectGroupId}`, {params: {page: 0, size: 1000}})
            .pipe(
              map(results => results.map(result => new FinanceImage(result)))
            );
  }

  public saveImage(projectGroupId: string, image: any): Observable<any> {
    return this.put<any>(`${this.projectGroups}/${projectGroupId}/finance/images`);
  }

  public removeImage(projectGroupId: string, imageId: string): Observable<any> {
    return this.delete<any>(`${this.projectGroups}/${projectGroupId}/finance/images/${imageId}`);
  }
}
