<header>
  <div class="go-back" (click)="emitCloseEvent()">
    <div class="icon-container">
      <svg width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.1875 13.8125C7.09375 13.875 7 13.9062 6.90625 13.9062C6.78125 13.9062 6.71875 13.875 6.65625 13.8125L0.125 7.25C0.0312495 7.1875 -4.76837e-07 7.125 -4.76837e-07 7C-4.76837e-07 6.90625 0.0312495 6.8125 0.125 6.75L6.65625 0.1875C6.71875 0.125 6.78125 0.09375 6.90625 0.09375C7 0.09375 7.09375 0.125 7.1875 0.1875L7.8125 0.8125C7.875 0.875 7.90625 0.96875 7.90625 1.0625C7.90625 1.1875 7.875 1.28125 7.8125 1.34375L2.96875 6.1875H13.625C13.7187 6.1875 13.8125 6.25 13.875 6.3125C13.9375 6.375 14 6.46875 14 6.5625V7.4375C14 7.5625 13.9375 7.65625 13.875 7.71875C13.8125 7.78125 13.7187 7.8125 13.625 7.8125H2.96875L7.8125 12.6562C7.875 12.75 7.90625 12.8438 7.90625 12.9375C7.90625 13.0625 7.875 13.125 7.8125 13.1875L7.1875 13.8125Z" fill="#EE7802"/>
      </svg>
    </div>
    {{ 'program.evaluation.back-to-application' | translate }}
  </div>
</header>

<div class="page-container page-container-top">
  <div class="heading">
    <h2>{{ heading }}</h2>
    <p>{{ subheading }}</p>
    <div class="title">
      <h4>{{ title }}</h4>
      <button class="btn btn-primary-light print-button" (click)="onPrint()">{{ 'buttons.print' | translate }}</button>
    </div>
    <div class="dropdown-container">
      <p>{{ 'program.evaluation.group-by' | translate }}:</p>
      <oes-dropdown
        [parentFormGroup]="formGroup"
        [items]="dropdownOptions"
        [controlName]="'groupBy'"
        [initialSelections]="initialSelections"
        [required]="true"
        [selection]="'single'">
      </oes-dropdown>
    </div>
  </div>
  <ng-container *ngIf="formGroup?.controls?.groupBy?.value && formGroup?.controls?.groupBy?.value[0] === 0">
    <ng-container *ngFor="let evaluations of evaluationsDisplay">
      <oes-evaluations-display [evaluations]="evaluations"></oes-evaluations-display>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="formGroup?.controls?.groupBy?.value && formGroup?.controls?.groupBy?.value[0] === 1">
    <ng-container *ngFor="let evaluations of criteriaDisplay">
      <oes-criteria-display [evaluations]="evaluations"></oes-criteria-display>
    </ng-container>
  </ng-container>
</div>


