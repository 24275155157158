import { Component, Input } from '@angular/core';
import { Project } from '@project/shared/project.model';

@Component({
  selector: 'oes-project-header-tags',
  templateUrl: './project-header-tags.component.html',
  styleUrls: ['./project-header-tags.component.scss'],
})
export class ProjectHeaderTagsComponent {
  @Input() project: Project;

  constructor() {}
}
