import {BaseModel} from '@shared/base.model';
import {FinanceBusinessPlanRisk} from './project-set-portfolio-business-plan-risk.model';

export class FinanceBusinessPlan extends BaseModel<FinanceBusinessPlan> {
  budgetEstimatedStatus: number;
  constructionDescription: string;
  customerEducationDescription: string;
  customerPricingDescription: string;
  customerRelationsDescription: string;
  customerTypeDescription: string;
  designSpecStatus: number;
  detailedQuoteStatus: number;
  equipmentLeasing: boolean;
  equipmentLeasingDescription: string;
  executedAgreementsStatus: number;
  id: string;
  maintenanceDescription: string;
  otherOpportunities: boolean;
  otherOpportunitiesDescription: string;
  poIssuedStatus: number;
  financeSummaryRisks: FinanceBusinessPlanRisk[];
  siteDescription: string;
  siteSelectionDescription: string;
  sitePermitDescription: string;
  smallBusinessFinancing: boolean;
  smallBusinessFinancingDescription: string;
  useExecutiveSummaries: boolean;
  waterSanitation: boolean;
  waterSanitationDescription: string;
  siteInnovationDescription: string;
  siteImpactDescription: string;
  siteOwnershipDescription: string;
  siteSustainabilityDescription: string;
  monitoringDescription: string;

  constructor(financeSummaryInfo: any) {
    super();
    this.id = financeSummaryInfo.id;
    this.budgetEstimatedStatus = financeSummaryInfo.budgetEstimatedStatus;
    this.constructionDescription = financeSummaryInfo.constructionDescription;
    this.customerEducationDescription = financeSummaryInfo.customerEducationDescription;
    this.customerPricingDescription = financeSummaryInfo.customerPricingDescription;
    this.customerRelationsDescription = financeSummaryInfo.customerRelationsDescription;
    this.customerTypeDescription = financeSummaryInfo.customerTypeDescription;
    this.designSpecStatus = financeSummaryInfo.designSpecStatus;
    this.detailedQuoteStatus = financeSummaryInfo.detailedQuoteStatus;
    this.equipmentLeasing = financeSummaryInfo.equipmentLeasing;
    this.equipmentLeasingDescription = financeSummaryInfo.equipmentLeasingDescription;
    this.executedAgreementsStatus = financeSummaryInfo.executedAgreementsStatus;
    this.maintenanceDescription = financeSummaryInfo.maintenanceDescription;
    this.otherOpportunities = financeSummaryInfo.otherOpportunities;
    this.otherOpportunitiesDescription = financeSummaryInfo.otherOpportunitiesDescription;
    this.poIssuedStatus = financeSummaryInfo.poIssuedStatus;
    this.financeSummaryRisks = [];
    this.siteDescription = financeSummaryInfo.siteDescription;
    this.siteSelectionDescription = financeSummaryInfo.siteSelectionDescription;
    this.sitePermitDescription = financeSummaryInfo.sitePermitDescription;
    this.smallBusinessFinancing = financeSummaryInfo.smallBusinessFinancing;
    this.smallBusinessFinancingDescription = financeSummaryInfo.smallBusinessFinancingDescription;
    this.useExecutiveSummaries = financeSummaryInfo.useExecutiveSummaries;
    this.waterSanitation = financeSummaryInfo.waterSanitation;
    this.waterSanitationDescription = financeSummaryInfo.waterSanitationDescription;
    this.siteInnovationDescription = financeSummaryInfo.siteInnovationDescription;
    this.siteImpactDescription = financeSummaryInfo.siteImpactDescription;
    this.siteOwnershipDescription = financeSummaryInfo.siteOwnershipDescription;
    this.siteSustainabilityDescription = financeSummaryInfo.siteSustainabilityDescription;
    this.monitoringDescription = financeSummaryInfo.monitoringDescription;

    if (financeSummaryInfo.financeSummaryRisks != null
      && financeSummaryInfo.financeSummaryRisks.length > 0) {
      for (let i = 0; i < financeSummaryInfo.financeSummaryRisks.length; i++) {
        this.financeSummaryRisks.push(
          new FinanceBusinessPlanRisk(financeSummaryInfo.financeSummaryRisks[i])
        );
      }
    } else {
      // Push an empty object onto the stack so that there is always at least one to work with.
      this.financeSummaryRisks.push(new FinanceBusinessPlanRisk({}));
    }
  }
}
