import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Installer, InstallerFilter } from "./installer.model";
import { InstallerApiConstants } from "./installer.constant";


@Injectable({
  providedIn: 'root'
})
export class InstallerService extends BaseRestApiService {
  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  listInstallersByOrgId(orgId: string, searchText: string = ''): Observable<Installer[]> {
    const filter = new InstallerFilter({ name: searchText });
    const params = { params: { page: 0, size: 20, filter: JSON.stringify(filter) }};
    return this.get<Installer[]>(InstallerApiConstants.installer.listAllByOrgId(orgId), searchText ? params : '');
  }

  createInstaller(installer: Installer): Observable<Installer> {
    return this.post<Installer>(InstallerApiConstants.installer.create(), installer);
  }

  updateInstaller(installer: Installer): Observable<Installer> {
    return this.put<Installer>(InstallerApiConstants.installer.update(installer.id), installer);
  }

  deleteInstaller(installerId: string): Observable<Installer> {
    return this.delete<Installer>(InstallerApiConstants.installer.delete(installerId));
  }

  createProjectInstaller(projectId: string, installerId: string): Observable<any> {
    return this.post<any>(InstallerApiConstants.projectInstaller.create(installerId, projectId), {});
  }
}
