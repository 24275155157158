import { environment } from '@environments/environment';

export class GenerationDesignCostSourceApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;

  public static list() {
    return `${GenerationDesignCostSourceApiConstants.projects}/generation-design-sources`;
  }
  public static create() {
    return `${GenerationDesignCostSourceApiConstants.projects}/generation-design-sources`;
  }
  public static detail(costDesignId: string) {
    return `${GenerationDesignCostSourceApiConstants.projects}/generation-design-sources/${costDesignId}`;
  }
  public static update(costDesignId: string) {
    return `${GenerationDesignCostSourceApiConstants.projects}/generation-design-sources/${costDesignId}`;
  }
  public static delete(costDesignId: string) {
    return `${GenerationDesignCostSourceApiConstants.projects}/generation-design-sources/${costDesignId}`;
  }
}
