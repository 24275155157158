import { Component, Input } from '@angular/core';

/**
 * type: string: 'error', 'warning', 'success', 'hint', 'empty'
 *    error: red
 *    warning: yellow
 *    success: green
 *    hint: blue
 *    empty: med-grey
 */
@Component({
  selector: 'oes-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent {
  @Input() iconName: string; // fa-error, fa-exclamation, fa-bullhorn
  @Input() link: string;
  @Input() linkText: string;
  @Input() message: string;
  @Input() new: boolean = false;  // Set to true for New Version (Spring 2023)
  @Input() type: string; // 'error', 'warning', 'success', 'hint', 'empty'

  constructor() { }

}
