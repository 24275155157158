import { BaseModel } from '@shared/base.model';

import { PROGRAM_MODULE_FLAGS } from './program-module-flags.enum';

export class ProgramProjectRules extends BaseModel<ProgramProjectRules> {
  informationModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  loadModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  generationDesignModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  distributionDesignModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  costsModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  revenueModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  financialModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  connectionsModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  milestonesModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  analyticsDataRequirementFlag: PROGRAM_MODULE_FLAGS;
  sharingModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  summaryModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  overviewModuleRequirementFlag: PROGRAM_MODULE_FLAGS;
  projectFilesModuleRequirementFlag: PROGRAM_MODULE_FLAGS;

  constructor(rules?: any) {
    super();
    this.informationModuleRequirementFlag = rules && rules.informationModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.loadModuleRequirementFlag = rules && rules.loadModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.generationDesignModuleRequirementFlag = rules && rules.generationDesignModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.distributionDesignModuleRequirementFlag = rules && rules.distributionDesignModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.costsModuleRequirementFlag = rules && rules.costsModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.revenueModuleRequirementFlag = rules && rules.revenueModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.financialModuleRequirementFlag = rules && rules.financialModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.connectionsModuleRequirementFlag = rules && rules.connectionsModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.milestonesModuleRequirementFlag = rules && rules.milestonesModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.analyticsDataRequirementFlag = rules && rules.analyticsDataRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.sharingModuleRequirementFlag = rules && rules.sharingModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.summaryModuleRequirementFlag = rules && rules.summaryModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.overviewModuleRequirementFlag = rules && rules.overviewModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
    this.projectFilesModuleRequirementFlag = rules && rules.projectFilesModuleRequirementFlag || PROGRAM_MODULE_FLAGS.REQUIRED;
  }
}
