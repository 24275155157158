
import {takeUntil} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {DeveloperProfileService} from './developer-profile.service';
import {Organization} from '@shared/models/organization/organization.model';
import {Subject} from 'rxjs';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';

// This component is called from "Vendor Marketplace" and "Finance Marketplace"
@Component({
  selector: 'oes-developer-profiles',
  templateUrl: './developer-profiles.component.html',
  styleUrls: ['./developer-profiles.component.scss'],
  providers: []
})
export class DeveloperProfilesComponent implements OnInit {
  public developerOrganizations: Organization[];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _userService: UserService,
              private _developerProfileService: DeveloperProfileService,
              private _dynamicHomeService: DynamicHomeService) {
  }

  ngOnInit() {
    this._dynamicHomeService.saveUrl(true);
    this.getOrganizations();
  }

  private getOrganizations() {
    this._userService.getCurrentUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(user => {
        const role = this._userService.getSelectedRole();
        if (role === ROLE_TYPE.FINANCE_USER || role === ROLE_TYPE.VENDOR_USER) {
          this._developerProfileService.list()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((organizations: Organization[]) => {
            this.developerOrganizations = organizations;
          });
        }
      });
  }
}
