import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';

import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { DateFilterComponent } from '@shared/ag-grid/component/date-filter/date-filter.component';
import { FormatterService } from '@shared/ag-grid/formatters.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { UserService } from '@user/user.service';
import { ROLE_TYPE } from '@user/role-type';
import { LoadingCellRendererComponent } from '@shared/ag-grid/component/loading-cell-renderer/loading-cell-renderer.component';

@Injectable({
  providedIn: 'root'
})
export class UserAdminGridSettingService extends BaseAgGridSettingsService {
  currentGridState: GridState = new GridState();
  defaultGridState: GridState = new GridState();
  initialGridState: GridState = new GridState();

  _gridApi: GridApi;
  _gridStateStorageKey: string;
  _columnApi: ColumnApi;

  private columns = {};
  private formatterService: FormatterService;
  private roles = {};
  private userService: UserService;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService) {
    super(_agGridServicesWrapperService);
    this.formatterService = _agGridServicesWrapperService._formatterService;
    this.userService = _agGridServicesWrapperService._userService;
    this.roles = _agGridServicesWrapperService._translateService.instant('user-admin.role-list');
    this.columns = _agGridServicesWrapperService._translateService.instant('user-admin');
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      animateRows: false,
      columnDefs: this.getColumnSetting(),
      components: {
        agDateInput: DateFilterComponent,
        loadingCellRenderer: LoadingCellRendererComponent
      },
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true
      },
      enableRangeSelection: true,
      groupHideOpenParents: true,
      loadingCellRenderer: 'loadingCellRenderer',
      loadingCellRendererParams: {
        loadingMessage: ''
      },
      localeText: this.globalTranslations,
      pagination: false,
      rowHeight: 80,
      rowModelType: 'serverSide',
      rowGroupPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns']
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum'] // options: 'count', 'sum', 'min', 'max', 'avg'
            },
            align: 'right'
          }
        ]
      },
      suppressLoadingOverlay: true,
      suppressRowClickSelection: false,
      suppressDragLeaveHidesColumns: false
    };
  }

  // Column Properties
  // https://www.ag-grid.com/javascript-grid-column-properties/
  public getColumnSetting(): any {
    const colDef = [
      {
        headerName: this.columns['passwordReset'],
        headerTooltip: this.columns['passwordReset'],
        field: 'passwordReset',
        unSortIcon: true,
        enableValue: true,
        resizable: false,
        suppressSizeToFit: true,
        width: 115,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        filter: false,
        sortable: false,
        suppressMenu: true,
        cellRenderer: (params) => {
          return '<button class="btn-grid-cell">Reset</button>';
        },
        pinned: 'left',
        enableRowGroup: true,
      },
      {
        headerName: this.columns['emailAddress'],
        headerTooltip: this.columns['emailAddress'],
        field: 'emailAddress',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          applyButton: true
        },
        pinned: 'left'
      },
      {
        headerName: this.columns['username'],
        headerTooltip: this.columns['username'],
        field: 'username',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          applyButton: true
        },
        width: 200,
        cellClass: 'read-only',
      },
      {
        headerName: this.columns['firstName'],
        headerTooltip: this.columns['firstName'],
        field: 'firstName',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          applyButton: true
        },
        width: 120,
      },
      {
        headerName: this.columns['lastName'],
        headerTooltip: this.columns['lastName'],
        field: 'lastName',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          applyButton: true
        },
        width: 120,
      },
      {
        headerName: this.columns['organization'],
        headerTooltip: this.columns['organization'],
        field: 'organization.name',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: this.userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN) ? 'agTextColumnFilter' : false,
        filterParams: {
          clearButton: true,
          applyButton: true
        },
        enableRowGroup: true,
      },
      {
        headerName: this.columns['roles'],
        headerTooltip: this.columns['roles'],
        field: 'roles',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        cellRenderer: (params) => {
          if (params && params.value && params.value.length > 0) {
            const roles = params.value.map(role => {
              return this.roles[role.name];
            });
            if (roles && roles.length > 0) {
              return roles.sort().join(', ');
            }
          }
        },
        filter: 'agSetColumnFilter',
        sortable: false,
        filterParams: {
          clearButton: true,
          valueFormatter: (params) => {
            return this.roles[params.value];
          },
          values: Object.keys(this.roles),
          applyButton: true
        }
      },
      {
        headerName: this.columns['active'],
        headerTooltip: this.columns['active'],
        field: 'active',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agSetColumnFilter',
        filterParams: {
          clearButton: true,
          applyButton: true,
          values: [ true, false ]
        },
        width: 100,
        enableRowGroup: true,
      },
      {
        headerName: this.columns['created'],
        headerTooltip: this.columns['created'],
        field: 'created',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        filter: 'agDateColumnFilter',
        valueFormatter: this.formatDate,
        filterParams: {
          comparator: this.formatterService.dateComparator,
          clearButton: true
        },
        cellClass: 'read-only',
        width: 130,
      },
      {
        headerName: this.columns['lastLogin'],
        headerTooltip: this.columns['lastLogin'],
        field: 'lastLogin',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        filter: 'agDateColumnFilter',
        valueFormatter: this.formatDateHHMM,
        filterParams: {
          comparator: this.formatterService.dateComparator,
          clearButton: true
        },
        cellClass: 'read-only',
        width: 130,
      }
    ];

    if (this.userService.hasRole(ROLE_TYPE.SYSTEM_ADMIN)) {
      colDef.push({
        headerName: this.columns['deleteDate'],
        headerTooltip: this.columns['deleteDate'],
        field: 'deleteDate',
        unSortIcon: true,
        enableValue: true,
        resizable: true,
        suppressSizeToFit: true,
        filter: 'agDateColumnFilter',
        valueFormatter: this.formatDateHHMM,
        filterParams: {
          comparator: this.formatterService.dateComparator,
          clearButton: true
        },
        cellClass: 'read-only',
        width: 130,
      });
    }

    return colDef;
  }
}
