import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgramMenuCategory } from './program-menu-category.enum';

export type ProgramPinnedTabMapping = { [C in ProgramMenuCategory] : number };

@Injectable({
  providedIn: 'root'
})
export class ProgramHeaderService {
  private pinnedTabsSubject = new BehaviorSubject<ProgramPinnedTabMapping>({
    'overview': 0,
    'application': 0,
    'documents': 0,
    'tenderedProjects': 0,
    'tenderedPortfolios': 0,
    'reporting': 0,
    'claims': 0,
    'messages': 0,
    'configuration': 0
  });
  public pinnedTabs$: Observable<ProgramPinnedTabMapping> = this.pinnedTabsSubject.asObservable();

  constructor() {
  }

  set pinnedTabs(obj: ProgramPinnedTabMapping) {
    this.pinnedTabsSubject.next(obj);
  }
}
