import { Component, Input, ViewChild } from '@angular/core';
import { FinanceMarketplaceService } from '@finance/investor-portfolio/shared/finance-marketplace.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';

@Component({
  selector: 'oes-fm-portfolio-summary-box',
  templateUrl: './summary-box.component.html',
  styleUrls: ['./summary-box.component.scss']
})
export class FmPortfolioSummaryBoxComponent {
  @ViewChild('DeveloperProfileDialog', {static: true}) private profileDialog: ModalDialogComponent;
  @Input() projectGroup: ProjectGroup;

  constructor(private _financeMarketplaceService: FinanceMarketplaceService) {
  }

  formatNumber(num: number, fixed: number) {
    return this._financeMarketplaceService.formatNumber(num, fixed);
  }

  openProfile() {
    this.profileDialog.show();
  }
}
