import { BaseModel } from '@shared/base.model';
import { ProjectCostSources } from './project-cost-sources.model';

export class ProjectCostItem extends BaseModel<ProjectCostItem> {
  canAlter: boolean;
  count: number;
  description: string;
  source: ProjectCostSources;
  totalCost: number;
  unitCost: number;
  units: string;
  distributionDesignToolGeneratedFlag: boolean;

  constructor(projectCostItemInfo: any) {
    super();
    this.canAlter = projectCostItemInfo.canAlter;
    this.count = projectCostItemInfo.count || 0;
    this.description = projectCostItemInfo.description;
    this.id = projectCostItemInfo.id;
    this.source = new ProjectCostSources(projectCostItemInfo.source || {});
    this.totalCost = projectCostItemInfo.totalCost || 0;
    this.unitCost = projectCostItemInfo.unitCost || 0;
    this.units = projectCostItemInfo.units;
    this.distributionDesignToolGeneratedFlag = projectCostItemInfo.distributionDesignToolGeneratedFlag;

  }
}
