import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'oes-global-files',
  templateUrl: './global-files.component.html',
  styleUrls: ['./global-files.component.scss'],
})
export class GlobalFilesComponent implements OnInit {
  activeTab = 'my-files';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _router: Router
  ) {}

  ngOnInit() {
    this.selectTabFromRoute();
  }

  selectTab(tab: string) {
    this.activeTab = tab;
    const urlTree = this._router.createUrlTree([], {
      queryParams: { tab: tab },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    localStorage.setItem('latest-files-tab', tab);
    this._location.go(urlTree.toString());
  }

  private selectTabFromRoute() {
    this._activatedRoute.queryParams.pipe(take(1)).subscribe((queryParams) => {
      const latestTab = localStorage.getItem('latest-files-tab');
      this.activeTab = queryParams.tab
        ? queryParams.tab
        : latestTab
        ? latestTab
        : 'my-files';
      this.selectTab(this.activeTab);
    });
  }
}
