import { Component, Input } from '@angular/core';
import { LanguageService } from '@global/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FormatterService } from '@shared/ag-grid/formatters.service';

@Component({
  selector: 'oes-display-program-date-time',
  templateUrl: './display-program-date-time.component.html',
  styleUrls: ['./display-program-date-time.component.scss']
})
export class DisplayProgramDateTimeComponent {
  @Input() name: string;
  @Input() submissionDeadline: Date;
  @Input() submittedDate: Date;

  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  private text = {};
  private local: string = 'en';

  constructor(private _languageService: LanguageService,
              private _translateService: TranslateService,
              private _formatterService: FormatterService) {
      this.text = this._translateService.instant('project-group-submit');
      this.local = this._languageService.getLanguage();
  }

  getIsPastSubmissionDeadline(): boolean {
    if (this.submissionDeadline) {
      return new Date(this.submissionDeadline) < new Date();
    }
    return false;
  }

  getStatus() {
    if (this.submittedDate) {
      const format = 'dd MMMM yyyy HH:mm';
      return this.text['submitted'] +
             ' ' +
             this._formatterService.getLocalDate({value: this.submittedDate}, format, this.local);
    }
    return this.text['not-submitted'];
  }
}
