import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ComponentCanDeactivate } from './component-can-deactivate.interface';

@Injectable()
export class ProjectUnsavedGuard  {
  constructor() {}

  canDeactivate(component: ComponentCanDeactivate,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState: RouterStateSnapshot) {
    if (component && component.hasUnsavedChanges(currentRoute, currentState, nextState)) {
      return component.showDialog(nextState.url);
    }
    return true;
  }
}
