/**
 * String enum. The value is camelCase to use as object key
 */
export enum PROJECT_PAGE {
  SITE = 'site',
  LOAD = 'load',
  GENERATION_DESIGN = 'generationDesign',
  DISTRIBUTION_DESIGN = 'distributionDesign',
  COSTS = 'cost',
  REVENUE = 'revenue',
  FINANCIAL = 'financial'
};
