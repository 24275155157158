<div class="utilization-factor"
      *ngIf="systemLoad && systemLoad?.peakHour >= 0">

  <div class="chart-scale">
    <ul class="chart-picker">
      <span class="whats-this text-link--color-brand text-sm-left small"
            tooltip="{{'system-load.chart-tooltip'| translate}}"
            placement="right"
            containerClass="tool-tip">
        {{'system-load.whats-this' | translate}}
      </span>
      <li (click)="toggleChart(type.Hour)" [ngClass]="{'active': currentChartType === type.Hour }">{{'units.hour' | translate}}</li>
      <li (click)="toggleChart(type.Day)" [ngClass]="{'active': currentChartType === type.Day }">{{'units.day' | translate}}</li>
      <li (click)="toggleChart(type.Month)" [ngClass]="{'active': currentChartType === type.Month }">{{'units.month' | translate}}</li>
    </ul>

    <div class="chart">
      <oes-utilization-factor-chart>
      </oes-utilization-factor-chart>
    </div>
  </div>

  <div class="chart-slider">
    <oes-show-scale-load
      [readOnly]="readOnly">
    </oes-show-scale-load>
  </div>
</div>
