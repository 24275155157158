import { environment } from '@environments/environment';

export class FilesApiConstants {
  static organizations =
    environment.serverUri + `api/${environment.apiVersion}/organizations`;

  static formDocument = {
    prepareDownload: (documentId: string) => {
      return `${environment.serverUri}api/${environment.apiVersion}/form-documents/prepare-download/${documentId}`;
    },
    download: () => {
      return `${environment.serverUri}api/${environment.apiVersion}/form-documents/download`;
    },
  };

  static document = {
    list: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/browse`;
    },
    sharedList: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/browse-shared`;
    },
    sharedFilteredList: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/browse-shared-filtered`;
    },
    tagFiles: (organizationId: string, includeSponsorDocs: boolean = false) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/browse/tagged` +
        (includeSponsorDocs === true ? '?includeSponsorDocs=true' : '?includeSponsorDocs=false');
    },
    tag: {
      user: (organizationId: string) => {
        return `${FilesApiConstants.organizations}/${organizationId}/documents/tag/user`;
      },
      system: (organizationId: string) => {
        return `${FilesApiConstants.organizations}/${organizationId}/documents/tag/system`;
      },
      disassociate: (organizationId: string) => {
        return `${FilesApiConstants.organizations}/${organizationId}/documents/tag/disassociate`;
      },
      delete: (organizationId: string) => {
        return `${FilesApiConstants.organizations}/${organizationId}/documents/tag/delete`;
      },
    },
    copy: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/copy`;
    },
    move: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/move`;
    },
    delete: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/delete`;
    },
    prepareDownload: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/prepareDownload`;
    },
    download: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/download`;
    },
    path: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/path`;
    },
    editName: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/edit-name`;
    },
    share: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/share`;
    },
    upload: (organizationId: string) => {
      return `${FilesApiConstants.organizations}/${organizationId}/documents/upload`;
    },
  };
}
