export enum SecondaryMenuCategory {
  projects = 'projects',
  offtakers = 'offtakers',
  program = 'program',
  portfolio = 'portfolio',
  ciPortfolio = 'c&i portfolio',
  financeCiPortfolio = 'finance c&i portfolio',
  financeMarketplace = 'finance Marketplace',
  myQuotes = 'my quotes',
  myRfqs = 'my rfqs',
  organizationProfile = 'organization profile',
}
