import {BaseModel} from '@shared/base.model';

export class ProFormaDocument extends BaseModel<ProFormaDocument> {
  fileSize: number;
  fileType: string;
  filename: string;

  constructor(proFormaDocumentInfo: any) {
    super();
    this.id = proFormaDocumentInfo.id;
    this.fileSize = proFormaDocumentInfo.fileSize;
    this.fileType = proFormaDocumentInfo.fileType;
    this.filename = proFormaDocumentInfo.filename;
  }
}
