import { BaseModel } from '@shared/base.model';
import { OfftakerCardinality } from './offtaker-cardinality.enum';
import { OfftakerType, OfftakerTypeLegacy } from './offtaker-type.enum';
import { ProjectSet } from './project-set/project-set.model';

export interface ProjectOfftaker {
  id: string;
  project: {
    id: string;
    name: string;
    organization: { id: string };
  };
};

export type OfftakerTypeUnion = OfftakerType | OfftakerTypeLegacy;

export class Offtaker extends BaseModel<Offtaker> {
  cardinality: OfftakerCardinality;
  name: string;
  email: string;
  type: OfftakerTypeUnion;
  projectOfftakers: ProjectOfftaker[];
  projectSets: ProjectSet[];

  constructor(offtakerInfo: any) {
    super();
    this.id = offtakerInfo.id;
    this.cardinality = offtakerInfo.cardinality;
    this.name = offtakerInfo.name;
    this.email = offtakerInfo.email;
    this.type = offtakerInfo.type as OfftakerTypeUnion;
    this.projectOfftakers = offtakerInfo.projectOfftakers;
    this.projectSets = offtakerInfo.projectSets?.map(ps => new ProjectSet(ps));
  }
}
