import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionButton } from './action-button';

@Component({
  selector: 'oes-grid-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  @Input() buttons: Object;
  @Output() clicked: EventEmitter<string> = new EventEmitter<string>();

  actionButtons: Object;
  buttonKeys = [];

  constructor() {
  }

  ngOnInit() {
    this.buttonKeys = Object.keys(this.buttons);
    this.actionButtons = this.buttons;
  }

  click(key: string) {
    if (key && key !== '') {
      this.clicked.emit(key);
    }
  }

  buttonClass(key: string) {
    if (key && key !== '' && this.buttons[key]) {
      if (this.buttons[key]) {
        if (this.buttonKeys?.length === 1) {
          return 'single btn btn-' + (<ActionButton>this.buttons[key]).class;
        }
        return 'btn btn-' + (<ActionButton>this.buttons[key]).class;
      }
    }
    if (this.buttonKeys?.length === 1) {
      return 'single btn btn-secondary';
    }
    return 'btn btn-secondary';
  }
}
