import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Directive({
  selector: '[oesTemplateDownload]'
})
export class TemplateDownloadDirective implements OnInit {
  @Input() fileName: string;
  private s3Url = 'https://oes-data-templates.s3.amazonaws.com/';

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    if (this.fileName && this.fileName !== '') {
      this.elementRef.nativeElement.href = this.s3Url + environment.name + '/' + this.fileName;
    }
  }
}
