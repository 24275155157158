import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FmPortfolioListGridSettingService } from './fm-portfolio-list-grid-setting.service';
import { FmProjectGroupService } from '@finance/investor-portfolio/shared/fm-project-group.service';
import { ProjectGroup } from '@shared/services/project-group.model';
import { FinanceConnection } from '@finance/developer-portfolio/shared/finance-connection.model';
import { GridSubAction } from '@shared/ag-grid/component/sub-action/sub-action.enum';
import { DynamicHomeService } from '@shared/services/dynamic-home.service';
import { GridState } from '@shared/ag-grid/gird-state';
import { FinanceProgressUpdateService } from '../finance-progress-update.service';
import { Program } from '@program/shared/program.model';
import { FinanceService } from '@finance/developer-portfolio/shared/finance.service';
import { ProjectListType } from '@project/shared-projects-list/project-list-type.enum';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';

@Component({
  selector: 'oes-fm-portfolio-list',
  templateUrl: './fm-portfolio-list.component.html',
  styleUrls: ['./fm-portfolio-list.component.scss'],
  providers: [
    FinanceProgressUpdateService
  ]
})
export class FmPortfolioListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() program?: Program;
  @Input() listType: ProjectListType;

  columnApi: ColumnApi;
  defaultGridState: GridState;
  gridApi: GridApi;
  gridOptions = <GridOptions>{};
  gridState: GridState;
  localTimeZone: string;
  ngUnsubscribe: Subject<any> = new Subject();

  private gridStateStorageKey: string;
  private projectGroups: ProjectGroup[] = [];
  private user: User;

  constructor(private _router: Router,
              public _fmPortfolioListGridSettingService: FmPortfolioListGridSettingService,
              private _fmProjectGroupService: FmProjectGroupService,
              private _financeService: FinanceService,
              private _userService: UserService,
              private _fmDealProgressUpdateService: FinanceProgressUpdateService,
              private _dynamicHomeService: DynamicHomeService) {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
        this.gridOptions = this._fmPortfolioListGridSettingService.getGridOptions(this.listType, this.user);
      });
  }

  ngOnInit() {
    this.gridStateStorageKey = this._fmPortfolioListGridSettingService.buildGridStateStorageKey(`fmPortfolioList-${ this.program?.id || 'main' }`);
    this.gridOptions = this._fmPortfolioListGridSettingService.getGridOptions(this.listType, this.user, this.program);
    this._dynamicHomeService.saveUrl();
    this.getLocalTimeZone();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.deals) {
      if (this.gridApi) {
        this.gridApi.setRowData(this.projectGroups);
        this._fmPortfolioListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this._fmPortfolioListGridSettingService.setGridApi(params.api, params.columnApi);
    this.defaultGridState = this._fmPortfolioListGridSettingService.buildDefaultGridState();
    this.gridApi.showLoadingOverlay();
    this.getProjectGroups();
  }

  // ag-grid callback: filter, sort and group
  public gridStatusChanged(event, type) {
    this.storeGridState();
  }

  storeGridState() {
    if (this.gridApi && this.columnApi) {
      this.gridState = this._fmPortfolioListGridSettingService.storeGridStateByApis(this.gridStateStorageKey, this.gridApi, this.columnApi);
    }
  }

  cellValueChanged(event: {data: ProjectGroup, newValue: string, oldValue: string}) {
    const deal = event.data;
    const status = this.convert(event.newValue);
    const previousStatus = event.oldValue;
    if (status !== previousStatus) {
      const updateFinanceConnection = new FinanceConnection(deal.currentConnection);
      deal.currentConnection = updateFinanceConnection;
      updateFinanceConnection.financeDealStatus = status;
      this.updateFinanceConnection(deal);
    }
  }

  cellClicked(event) {
    if (event.column.colId === 'developerOrganization.name' || event.column.colId === 'name') {
      this.selectProjectGroup(event.data);
    }
  }

  getLocalTimeZone() {
    const date = new Date().toTimeString().split('(').splice(1, 1)[0];
    this.localTimeZone = date.substring(0, date.length - 1);
  }

  subAction(action: GridSubAction) {
    switch (action) {
      case GridSubAction.exportList:
        this.exportCsv();
        break;
      case GridSubAction.reload:
        this.getProjectGroups();
        break;
      case GridSubAction.clearFilter:
        this._fmPortfolioListGridSettingService.clearStoredGridState(this.gridStateStorageKey);
        break;
    }
  }

  private getProjectGroups() {
    if (this.program?.programPermissions?.enrolled) {
      this._financeService.submittedListByProgram(this.program.id)
        .pipe(take(1))
        .subscribe((projectGroups: ProjectGroup[]) => {
          const displayedGroups = projectGroups.reduce((accum, pg: ProjectGroup) => {
            if (!accum.find(projectGroup => projectGroup.id === pg.id)) {
              accum.push(pg);
            }
            return accum;
          }, []);
          this.displayProjectGroups(displayedGroups);
        });
    } else {
      this._fmProjectGroupService.getFinanceProjectGroups(this.program?.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((projectGroups: ProjectGroup[]) => {
        this.displayProjectGroups(projectGroups);
      });
    }
  }

  private displayProjectGroups(projectGroups: ProjectGroup[]) {
    this.projectGroups = [];
        if (projectGroups?.length > 0) {
          this.projectGroups = projectGroups.filter((item: ProjectGroup) => {
            return !item.program || !item.program.id || new Date(item.program.bidReviewDateTime) < new Date();
          });
        }
        if (this.projectGroups?.length > 0) {
          this.gridApi.setRowData(this.projectGroups);
          this._fmPortfolioListGridSettingService.applyStoredGridState(this.gridStateStorageKey, this.defaultGridState);
          this.gridApi.hideOverlay();
        } else {
          this.gridApi.showNoRowsOverlay();
        }
  }

  private updateFinanceConnection(group: ProjectGroup) {
    this._fmDealProgressUpdateService.updateDealProgress(group);
  }

  private convert(value: string): string {
    return value.replace(/ /g, '_').toUpperCase();
  }

  private selectProjectGroup(projectGroup: ProjectGroup) {
    if (projectGroup && projectGroup.currentConnection.declined()) {
      return false;
    }
    this._router.navigate([`/oes/finance/finance-marketplace/${projectGroup.id}/project-overview`]);
  }

  private exportCsv() {
    const processCellCallback = function(params: any) {
      if (params.value && params.value && params.value.contactFirstName && params.value.contactLastName) {
        return params.value.contactFirstName + ' ' + params.value.contactLastName;
      }
      return params.value;
    };
    this._fmPortfolioListGridSettingService.exportCsv(this.gridApi, 'Portfolios', null, processCellCallback);
  }
}
