<ng-container *ngIf="new; else OldImplementation">
  <div class="notify-new" [ngClass]="type">

    <div class="icon-container" [ngClass]="iconName"></div>

    <div class="message">
      <span *ngIf="message">{{ message }}</span>
      <span *ngIf="link" class="link">
        <a [href]="link" target="_blank">
          {{ linkText }}
          <div class="link-icon-container">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.66663 7.00008H13.3333M13.3333 7.00008L7.49996 1.16675M13.3333 7.00008L7.49996 12.8334" stroke="#ff7f00" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </a>
      </span>
    </div>

  </div>
</ng-container>

<ng-template #OldImplementation>
  <div class="notify" [ngClass]="type">
    <div class="icon-box">
      <i class="fa" [ngClass]="iconName" aria-hidden="true"></i>
    </div>
    <div *ngIf="message" class="message">
      <span>{{ message }}</span>
    </div>
    <ng-content></ng-content>
  </div>
</ng-template>