<div class="generation-system p-4">
  <div class="d-flex align-items-center mb-2">
    <img src="/assets/investor-images/icon-flash.svg">
    <h2>{{'finance-marketplace.generation-system.title' | translate}}</h2>
  </div>
  <div *ngIf="financeIntroduction"
        class="d-flex flex-row justify-content-between">
    <oes-portfolio-generation-block
      class="section-item"
      [imageUrl]="'/assets/images/generation-design/solar-pv@2x.png'"
      [title]="'finance-marketplace.generation-system.solar-pv' | translate"
      [average]="financeIntroduction?.totalSolarSize / financeIntroduction?.projectCount"
      [averageUnit]="'finance-marketplace.generation-system.avg-kw' | translate"
      [total]="financeIntroduction?.totalSolarSize"
      [totalUnit]="'finance-marketplace.generation-system.total-deal-kw' | translate">
    </oes-portfolio-generation-block>

    <oes-portfolio-generation-block
      class="section-item"
      [imageUrl]="'/assets/images/generation-design/storage@2x.png'"
      [title]="'finance-marketplace.generation-system.storage' | translate"
      [average]="financeIntroduction?.totalStorageSize / financeIntroduction?.projectCount"
      [averageUnit]="'finance-marketplace.generation-system.avg-kwh' | translate"
      [total]="financeIntroduction?.totalStorageSize"
      [totalUnit]="'finance-marketplace.generation-system.total-deal-kwh' | translate">
    </oes-portfolio-generation-block>

    <oes-portfolio-generation-block
      class="section-item"
      [imageUrl]="'/assets/images/generation-design/generator@2x.png'"
      [title]="'finance-marketplace.generation-system.diesel-generation' | translate"
      [average]="financeIntroduction?.totalDieselSize / financeIntroduction?.projectCount"
      [averageUnit]="'finance-marketplace.generation-system.avg-kw' | translate"
      [total]="financeIntroduction?.totalDieselSize"
      [totalUnit]="'finance-marketplace.generation-system.total-deal-kw' | translate">
    </oes-portfolio-generation-block>

    <oes-portfolio-generation-block
      class="section-item"
      [imageUrl]="'/assets/images/generation-design/biomass@2x.png'"
      [title]="'finance-marketplace.generation-system.biomass-generation' | translate"
      [average]="financeIntroduction?.totalBiomassSize / financeIntroduction?.projectCount"
      [averageUnit]="'finance-marketplace.generation-system.avg-kw' | translate"
      [total]="financeIntroduction?.totalBiomassSize"
      [totalUnit]="'finance-marketplace.generation-system.total-deal-kw' | translate">
    </oes-portfolio-generation-block>
  </div>
</div>
