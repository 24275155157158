import { BaseModel } from '@shared/base.model';
import { ProjectType } from '@project/shared/project-type.enum';
import { ProgramVerificationAggregation } from './program-verification-aggregation.enum';
import { ProgramVerificationComparator } from './program-verification-comparator.enum';
import { DateTime } from 'luxon';
import { DatasetType } from 'src/app/analytics/data-integrations/shared/dataset-type.enum';

export class ProgramVerificationRules extends BaseModel<ProgramVerificationRules> {
  projectType: ProjectType;
  datasetType: DatasetType;
  field: string;
  aggregation: ProgramVerificationAggregation;
  comparator: ProgramVerificationComparator;
  comparatorValue: number;
  servicePeriodDays: number;
  serviceStartDate: string;

  constructor(rules?: any) {
    super();
    this.projectType = rules.projectType;
    this.datasetType = rules.datasetType;
    this.field = rules.field;
    this.aggregation = rules.aggregation;
    this.comparator = rules.comparator;
    this.comparatorValue = rules.comparatorValue;
    this.servicePeriodDays = rules.servicePeriodDays;
    this.serviceStartDate = rules.serviceStartDate || DateTime.fromISO(new Date().toISOString()).toFormat('yyyy-MM-dd');
  }
}
