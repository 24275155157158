<div *ngIf="displayActions"
     class="dropdown-container"
     dropdown
     container="body">
  <span dropdownToggle
        aria-hidden="true"
        class="ellipses">
  </span>
  <ul *dropdownMenu
      role="menu"
      class="dropdown-menu dropdown-menu-right">
    <ng-container *ngFor="let key of params.keys">
        <li (click)="click(key)">{{ 'data-integration.all-actions.' + key | translate }}</li>
    </ng-container>
  </ul>
</div>
