import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ManageProjectSetFinancialModelTemplatesService } from './manage-project-set-financial-model-templates.service';
import { Subject } from 'rxjs';
import { ProjectSetFinancialModelTemplate } from '@project/offtakers/project-set/edit/financial-model/project-set-financial-model-template.model';
import { EventService } from '@shared/event.service';
import { FileNameCheckerService } from '@shared/services/file-name-checker.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-manage-project-set-financial-model-templates',
  templateUrl: 'manage-project-set-financial-model-templates.component.html',
  styleUrls: ['manage-project-set-financial-model-templates.component.scss']
})

export class ManageProjectSetFinancialModelTemplatesComponent implements OnInit, OnDestroy {
  currentPreConstructionTemplate: ProjectSetFinancialModelTemplate;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _manageProjectSetFinancialModelTemplatesService: ManageProjectSetFinancialModelTemplatesService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _fileNameCheckerService: FileNameCheckerService) {
  }

  ngOnInit() {
    this.getTemplates();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  getTemplates() {
    this._manageProjectSetFinancialModelTemplatesService.findAllTemplates()
      .pipe(take(1))
      .subscribe((templates: ProjectSetFinancialModelTemplate[]) => {
        this.currentPreConstructionTemplate = templates.sort((a, b) => {
          if (b.created >= a.created) {
            return 1;
          }
          return -1;
        })[0];
      });
  }

  uploadModel(event) {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];
      const s3SafeName = this._fileNameCheckerService.s3Safe(file.name);
      const formData = new FormData();

      formData.append('file', file, s3SafeName);

      this._manageProjectSetFinancialModelTemplatesService.uploadModel(formData)
        .pipe(take(1))
        .subscribe(res => {
          this._eventService.success(this._translateService.instant('offtaker.manage.successful-upload'));
          this.getTemplates();
        });
    }
  }

}
