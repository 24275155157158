import { Injectable } from '@angular/core';
import { BaseModel } from '@shared/base.model';
import { ProjectConnectionService } from '@project/shared/project-connection.service';
import { User } from '@user/user.model';
import { Organization } from '@shared/models/organization/organization.model';
import { Project } from '@project/shared/project.model';
import { UserService } from '@user/user.service';
import { take } from 'rxjs/operators';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { SharingApiConstants } from './sharing.constant';
import { BehaviorSubject } from 'rxjs';
import { ProjectConnection } from '@project/shared/project-connection.model';

// keys are for select-option, values are for back-end
export class ProjectConnectionStatus {
  public developer = {
    'No Status': 'NO_STATUS',
    'Submitted (initial)': 'SUBMITTED',
    'Under Construction': 'UNDER_CONSTRUCTION',
    'Re-submitted': 'RESUBMITTED',
    'Submitted (final)': 'SUBMITTED_FINAL',
    'Financing Secured': 'FINANCING_SECURED',
    'Purchase Order Assets': 'PURCHASE_ORDER_ASSETS',
    'All Goods Received (Cleared Customs)': 'ALL_GOODS_RECEIVED',
    'Installation (All Goods on Site)': 'INSTALLATION',
    'Commissioned': 'COMMISSIONED',
    'Customers Connected': 'CUSTOMERS_CONNECTED',
    'Milestone 1': 'MILESTONE_1',
    'Milestone 2': 'MILESTONE_2',
    'Milestone 3': 'MILESTONE_3'
  };

  public investor = {
    'No Status': 'NO_STATUS',
    'Under Review': 'UNDER_REVIEW',
    'Incomplete': 'INCOMPLETE',
    'Pre-approved': 'PREAPPROVED',
    'Declined': 'DECLINED',
    'Approved': 'APPROVED',
    'Disbursed': 'DISBURSED',
    'Grant Agreement Signature': 'GRANT_AGREEMENT_SIGNATURE',
    'Commissioned': 'COMMISSIONED',
    'Milestone 1': 'MILESTONE_1',
    'Milestone 2': 'MILESTONE_2',
    'Milestone 3': 'MILESTONE_3'
  };

  public all = {
    'Submitted (initial)': 'SUBMITTED',
    'Under Construction': 'UNDER_CONSTRUCTION',
    'Commissioned': 'COMMISSIONED',
    'Re-submitted': 'RESUBMITTED',
    'Submitted (final)': 'SUBMITTED_FINAL',
    'Milestone 1': 'MILESTONE_1',
    'Milestone 2': 'MILESTONE_2',
    'Milestone 3': 'MILESTONE_3',
    'Under Review': 'UNDER_REVIEW',
    'Incomplete': 'INCOMPLETE',
    'Pre-approved': 'PREAPPROVED',
    'Declined': 'DECLINED',
    'Approved': 'APPROVED',
    'Grant Agreement Signature': 'GRANT_AGREEMENT_SIGNATURE',
    'Disbursed': 'DISBURSED',
    'Financing Secured': 'FINANCING_SECURED',
    'Purchase Order Assets': 'PURCHASE_ORDER_ASSETS',
    'All Goods Received (Cleared Customs)': 'ALL_GOODS_RECEIVED',
    'Installation (All Goods on Site)': 'INSTALLATION',
    'Customers Connected': 'CUSTOMERS_CONNECTED'
  };
};

export class ProjectConnectionInteraction extends BaseModel<ProjectConnectionInteraction> {
  public creatorUser?: any;
  public message?: string;
  public projectConnectionStage?: string;
  public projectStage?: string;
  public version?: string;
  public sentDateTime?: Date;
  public created?: string;
  public projectConnectionId?: string;
  public recipientOrganizationId?: string;
  public deleteDate?: string;
  public updateUser?: string;
  public updated?: string;

  constructor(data: any) {
    super();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectConnectionInteractionService extends BaseRestApiService {
  private _recipient: Organization;
  private _projectInfo: Project;
  private userId: string;

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _projectConnectionService: ProjectConnectionService,
              private _userService: UserService) {
    super(_restApiWrapperService);
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.userId = user.id;
    });
  }

  get recipient() {
    return this._recipient;
  }

  get projectInfo() {
    return this._projectInfo;
  }

  public getProjectConnectionInteractions(connection?: ProjectConnection) {
    const projectConnection = connection ? connection : this._projectConnectionService.projectConnection;
    if (projectConnection?.projectInfo?.id && projectConnection?.recipient?.id) {
      this._recipient = projectConnection.recipient;
      this._projectInfo = projectConnection.projectInfo;
      return this.get<ProjectConnectionInteraction[]>(
        SharingApiConstants.sharing.find(projectConnection.projectInfo.id, projectConnection.id),
        {params: {page: 0, size: 1000}}
      );
    }
  }

  public sendProjectConnectionInteraction(data: ProjectConnectionInteraction) {
    const projectPermission = this._projectConnectionService.projectConnection;
    // add user id and permission id
    data.projectConnectionId = projectPermission.id;
    data.recipientOrganizationId = projectPermission.recipient.id;
    data.creatorUser = {
      id: this.userId
    };

    if (projectPermission && projectPermission.projectInfo.id && projectPermission.recipient.id) {
      return this.post<ProjectConnectionInteraction>(
        SharingApiConstants.sharing.create(projectPermission.projectInfo.id, projectPermission.id),
        data
      );
    }
  }

  public sendInteractionDirect(data: ProjectConnectionInteraction, projectId: string) {
    return this.post<ProjectConnectionInteraction>(
      SharingApiConstants.sharing.create(projectId, data.projectConnectionId),
      data
    );
  }
}
