import { FilesApiConstants } from './files.constants';
import { AddTag } from './models/add-tags.model';
import { CreatePath } from './models/create-path.model';
import { DeleteTag } from './models/delete-tag.model';
import { DocTag } from './models/doc-tag.model';
import { DownloadDocuments } from './models/download-documents.model';
import { EditName } from './models/edit-name.model';
import { RequestItem } from './models/request-item.model';
import { TagType } from './models/tag-type.model';
import { Injectable } from '@angular/core';
import { FileUploadStorage } from '@program/shared/formio-custom-components/file-upload-storage.model';
import { FORM_TYPE } from '@program/shared/formio-custom-components/form-type.enum';
import { CopyDocuments } from '@shared/components/files/shared/models/copy-documents.model';
import { DeleteDocuments } from '@shared/components/files/shared/models/delete-documents.model';
import { ShareDocuments } from '@shared/components/files/shared/models/share-documents.model';
import { Organization } from '@shared/models/organization/organization.model';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

/**
 * DocumentDataService: communicate with the backend
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentDataService extends BaseRestApiService {
  private organization: Organization;

  constructor(
    _restApiWrapperService: RestApiWrapperService,
    private _userService: UserService
  ) {
    super(_restApiWrapperService);
  }

  getList(organizationId: string): Observable<any[]> {
    // use a specific organization.id for Investor's Portfolio Data Room
    if (organizationId && organizationId !== '') {
      return this.get<any[]>(FilesApiConstants.document.list(organizationId));
    }
  }

  getSharedList(organizationId: string): Observable<any[]> {
    // use a specific organization.id for Investor's Portfolio Data Room
    if (organizationId && organizationId !== '') {
      return this.get<any[]>(
        FilesApiConstants.document.sharedList(organizationId)
      );
    }
  }

  getSharedFilteredList(
    organizationId: string,
    query: Object
  ): Observable<any[]> {
    // use a specific organization.id for Investor's Portfolio Data Room
    if (organizationId && organizationId !== '') {
      return this.put<any[]>(
        FilesApiConstants.document.sharedFilteredList(organizationId),
        query
      );
    }
  }

  // this can get a documents list by tag. using for Read only mode.
  getListByTag(
    organizationId: string,
    docTag: DocTag,
    includeSponsorDocs: boolean = false
  ): Observable<any[]> {
    if (organizationId && organizationId !== '') {
      return this.post<any[]>(
        FilesApiConstants.document.tagFiles(organizationId, includeSponsorDocs),
        docTag
      );
    }
  }

  addUserTag(documentTag: AddTag): Observable<any> {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post<any>(
          FilesApiConstants.document.tag.user(user.organization.id),
          documentTag
        );
      })
    );
  }

  addSystemTag(documentTag: AddTag): Observable<any> {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post<any>(
          FilesApiConstants.document.tag.system(user.organization.id),
          documentTag
        );
      })
    );
  }

  disassociateTag(documentTag: DeleteTag): Observable<any[]> {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post<any[]>(
          FilesApiConstants.document.tag.disassociate(user.organization.id),
          documentTag
        );
      })
    );
  }

  createFolder(createPath: CreatePath): Observable<any> {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post(
          FilesApiConstants.document.path(user.organization.id),
          createPath
        );
      })
    );
  }

  editName(editName: EditName) {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post(
          FilesApiConstants.document.editName(user.organization.id),
          editName
        );
      })
    );
  }

  moveCopyDocuments(
    requestItems: RequestItem[],
    destinationTagType: TagType,
    type: string
  ) {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        const documents: CopyDocuments = {
          organization: user.organization,
          requestItems: requestItems,
          systemDocumentType: destinationTagType.docTagType,
          index: destinationTagType.docTagItemId,
        };

        switch (type) {
          case 'copy':
            return this.post(
              FilesApiConstants.document.copy(user.organization.id),
              documents
            );

          case 'move':
            return this.post(
              FilesApiConstants.document.move(user.organization.id),
              documents
            );
        }
      })
    );
  }

  deleteDocuments(requestItems: RequestItem[]) {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        const documents: DeleteDocuments = {
          organization: user.organization,
          documentKeys: requestItems,
        };
        return this.post(
          FilesApiConstants.document.delete(user.organization.id),
          documents
        );
      })
    );
  }

  /**
   * targetOrganizationId: string. this id is used to make a directory to store a zip
   */
  downloadDocuments(
    requestItems: string[],
    targetOrganizationId: string
  ): Observable<any[]> {
    const documents: DownloadDocuments = {
      organizationId: targetOrganizationId,
      selectedKeys: requestItems,
    };
    return this.post<string>(
      FilesApiConstants.document.prepareDownload(targetOrganizationId),
      documents,
      { responseType: 'json' }
    ).pipe(
      flatMap((download: any) => {
        const downloadOption = {
          responseType: 'blob' as const,
          params: {
            documentKey: download?.downloadId,
          },
        };
        return this.get<any[]>(
          FilesApiConstants.document.download(targetOrganizationId),
          downloadOption
        );
      })
    );
  }

  downloadDocumentById(documentId: string): Observable<any[]> {
    return this.post<string>(
      FilesApiConstants.formDocument.prepareDownload(documentId),
      null,
      { responseType: 'json' }
    ).pipe(
      flatMap((download: any) => {
        const downloadOption = {
          responseType: 'blob' as const,
          params: {
            documentKey: download?.downloadId,
          },
        };
        return this.get<any[]>(
          FilesApiConstants.formDocument.download(),
          downloadOption
        );
      })
    );
  }

  uploadDocuments(file: Object, option?: Object): Observable<any> {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post<any>(
          FilesApiConstants.document.upload(user.organization.id),
          file,
          option
        );
      })
    );
  }

  public uploadMilestoneDocuments(file: Object, orgId: string, option?: Object): Observable<any> {
    return this.post<any>(
      FilesApiConstants.document.upload(orgId),
      file,
      option
    );
  }

  shareDocuments(shareDocuments: ShareDocuments) {
    return this._userService.getCurrentUser().pipe(
      flatMap((user: User) => {
        return this.post(
          FilesApiConstants.document.share(user.organization.id),
          shareDocuments
        );
      })
    );
  }

  shareItems(docs: Array<any>, recipients: Array<Organization>) {
    const requestItems: Array<any> = new Array<any>();
    recipients.forEach((recipient) => {
      requestItems.push(
        docs.map((doc) => {
          return {
            documentKey: doc.documentKey,
            recipient: recipient,
          };
        })
      );
    });
    const shareRequest: ShareDocuments = {
      organization: this.organization,
      requestItems: requestItems,
    };
    return this.shareDocuments(shareRequest);
  }

  setFileUploadStorageModel(
    organizationId: string,
    formType: FORM_TYPE,
    index: string
  ) {
    const storageModel = new FileUploadStorage({
      organizationId,
      formType,
      index,
    });
    localStorage.setItem(
      'file-upload-storage-model',
      JSON.stringify(storageModel)
    );
  }
}
