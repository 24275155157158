import {BaseModel} from '@shared/base.model';

export class AssignedLoadItemGroup extends BaseModel<AssignedLoadItemGroup> {
  count: number;
  name: string;
  tierCategory: string;
  tierType: string;
  weekdayAverageLoad = 0;
  weekendAverageLoad = 0;
  dailyAverageLoad = 0;
  selected;
  supportedItemType: string;
  // Not on backend model - needed for renaming copied library items
  newName: string;
  annualLoad: number;

  constructor(assignedLoadItemGroupInfo: any) {
    super();
    this.annualLoad = assignedLoadItemGroupInfo.annualLoad;
    this.id = assignedLoadItemGroupInfo.id;
    this.name = assignedLoadItemGroupInfo.name;
    this.tierType = assignedLoadItemGroupInfo.tierType;
    this.tierCategory = assignedLoadItemGroupInfo.tierCategory;
    this.count = assignedLoadItemGroupInfo.count;
    this.weekdayAverageLoad = assignedLoadItemGroupInfo.weekdayAverageLoad;
    this.weekendAverageLoad = assignedLoadItemGroupInfo.weekendAverageLoad;
    this.dailyAverageLoad = assignedLoadItemGroupInfo.dailyAverageLoad;
    this.supportedItemType = assignedLoadItemGroupInfo.supportedItemType;
  }
}
