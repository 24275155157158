import { BaseModel } from '@shared/base.model';
import { GenerationDesignItem } from './generation-design-item.model';
import { SYSTEM_TYPE } from './system-type.enum';
import { GenerationDesignCostItem } from './generation-design-cost-item.model';
import { Project } from '@project/shared/project.model';
import { SystemLoad } from '@project/detail/load/shared/system-load.model';
import { SourceType } from './source-type.enum';
import { ExternalType } from './external-type.enum';

export class GenerationDesign extends BaseModel<GenerationDesign> {
  finalizeDateTime: Date;
  minimumLoadAvailability = 0;
  minimumRenewableGeneration = 0;
  name: string;
  project: Project;
  projectGenerationDesignCostItems: GenerationDesignCostItem[];
  projectGenerationDesignItems: GenerationDesignItem[];
  sourceType: SourceType;
  systemLoad: SystemLoad;
  systemType: SYSTEM_TYPE;
  totalLoadAvailability = 0;
  totalRenewableGeneration = 0;
  totalSystemLosses = 0;
  loadScalingFactor = 0;
  discountRate = 0;
  excessElectricity = 0;
  costOfEnergy = 0;
  // Calculated values
  solarCapacity = 0;
  generatorCapacity = 0;
  generatorFuelType: string = null;
  controllerMake: string = null;
  storageCapacity = 0;
  converterCapacity = 0;
  systemInverterCapacity = 0;
  solarInverterCapacity = 0;
  batteryInverterCapacity = 0;
  chargeControllerCapacity = 0;
  bosTotalCost = 0;
  capex = 0;
  opex = 0;
  storageType: string = null;
  // Homer Jobs for list
  latestHomerJobStatus: string;
  newName: string;
  public isSolarCalculated;
  public isStorageCalculated;
  public isGeneratorCalculated;
  public isSystemInverterCalculated;
  public isBatteryInverterCalculated;
  public isSolarInverterCalculated;
  public isChargeControllerCalculated;
  public isStorageRemoved;
  public isGeneratorRemoved;
  public isSolarRemoved;
  public isCustomSystem;
  public isHomer;
  public isHomerCalculated;
  public showCapex;
  public quantityOfSystem;
  public updated: Date;

  constructor(projectGenerationDesignInfo: any) {
    super();
    this.id = projectGenerationDesignInfo.id;
    this.finalizeDateTime = projectGenerationDesignInfo.finalizeDateTime ? new Date(projectGenerationDesignInfo.finalizeDateTime) : undefined;
    this.minimumLoadAvailability = projectGenerationDesignInfo.minimumLoadAvailability;
    this.minimumRenewableGeneration = projectGenerationDesignInfo.minimumRenewableGeneration;
    this.name = projectGenerationDesignInfo.name;
    this.project = new Project(projectGenerationDesignInfo.project || {});
    this.projectGenerationDesignItems = [];
    this.sourceType = projectGenerationDesignInfo.sourceType; // === SourceType.Manual ? SourceType.Manual : SourceType.Homer;
    this.systemLoad = projectGenerationDesignInfo.systemLoad;
    this.systemType = projectGenerationDesignInfo.systemType;
    this.totalSystemLosses = projectGenerationDesignInfo.totalSystemLosses;
    this.loadScalingFactor = projectGenerationDesignInfo.loadScalingFactor;
    this.discountRate = projectGenerationDesignInfo.discountRate || 10;
    this.latestHomerJobStatus = projectGenerationDesignInfo.latestHomerJobStatus;
    if (projectGenerationDesignInfo.projectGenerationDesignCostItems) {
      this.projectGenerationDesignCostItems = [];
      projectGenerationDesignInfo.projectGenerationDesignCostItems.forEach((costItem: GenerationDesignCostItem) => {
        this.capex = this.capex + costItem.totalCost;
        this.bosTotalCost += costItem.totalCost;
        this.projectGenerationDesignCostItems.push(new GenerationDesignCostItem(costItem));
      });
    }
    if (projectGenerationDesignInfo.totalLoadAvailability && !isNaN(projectGenerationDesignInfo.totalLoadAvailability)) {
      this.totalLoadAvailability = +projectGenerationDesignInfo.totalLoadAvailability.toFixed(2);
    } else {
      this.totalLoadAvailability = projectGenerationDesignInfo.totalLoadAvailability;
    }
    if (projectGenerationDesignInfo.totalRenewableGeneration && !isNaN(projectGenerationDesignInfo.totalRenewableGeneration)) {
      this.totalRenewableGeneration = +projectGenerationDesignInfo.totalRenewableGeneration.toFixed(2);
    } else {
      this.totalRenewableGeneration = projectGenerationDesignInfo.totalRenewableGeneration;
    }
    if (projectGenerationDesignInfo.excessElectricity && !isNaN(projectGenerationDesignInfo.excessElectricity)) {
      this.excessElectricity = +projectGenerationDesignInfo.excessElectricity.toFixed(2);
    } else {
      this.excessElectricity = projectGenerationDesignInfo.excessElectricity;
    }
    if (projectGenerationDesignInfo.costOfEnergy && !isNaN(projectGenerationDesignInfo.costOfEnergy)) {
      this.costOfEnergy = +projectGenerationDesignInfo.costOfEnergy.toFixed(2);
    } else {
      this.costOfEnergy = projectGenerationDesignInfo.costOfEnergy;
    }
    const designItems = projectGenerationDesignInfo.projectGenerationDesignItems;
    if (designItems != null) {
      for (let i = 0; i < designItems.length; i++) {
        this.projectGenerationDesignItems.push(new GenerationDesignItem(designItems[i]));
        const designItem = designItems[i];
        if (designItem.opex != null) {
          this.opex = this.opex + designItem.opex;
        }
        if (designItem.capex != null) {
          this.capex = this.capex + designItem.capex;
        }
        if (designItem.type === 'PANELS') {
          this.solarCapacity += designItem.size;
        }
        if (designItem.type === 'SYSTEM_INVERTER') {
          this.systemInverterCapacity += designItem.size;
        }
        if (designItem.type === 'SOLAR_INVERTER') {
          this.solarInverterCapacity += designItem.size;
        }
        if (designItem.type === 'BATTERY_INVERTER') {
          this.batteryInverterCapacity += designItem.size;
        }
        if (designItem.type === 'CHARGE_CONTROLLER') {
          this.chargeControllerCapacity += designItem.size;
        }
        if (designItem.type === 'GENERATOR') {
          this.generatorCapacity += designItem.size;
          this.generatorFuelType = designItem.fuelType;
          this.opex += designItem.fuelCost;
        }
        if (designItem.type === 'STORAGE') {
          this.storageCapacity += designItem.size;
          this.storageType = designItem.storageType;
        }
        if (designItem.type === 'CONTROLLER') {
          this.controllerMake = designItem.make;
        }
        if (designItem.type === 'CONVERTER') {
          this.converterCapacity = designItem.size;
        }
      }
    }
    // TODO: need to calculate dynamically?
    this.isSolarCalculated = (this.sourceType === SourceType.Homer && this.solarCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isStorageCalculated = (this.sourceType === SourceType.Homer && this.storageCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isGeneratorCalculated = (this.sourceType === SourceType.Homer && this.generatorCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isSystemInverterCalculated = (this.sourceType === SourceType.Homer && this.systemInverterCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isBatteryInverterCalculated = (this.sourceType === SourceType.Homer && this.batteryInverterCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isSolarInverterCalculated = (this.sourceType === SourceType.Homer && this.solarInverterCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isChargeControllerCalculated = (this.sourceType === SourceType.Homer && this.chargeControllerCapacity > 0) || this.sourceType === SourceType.Manual;
    this.isStorageRemoved = this.storageCapacity === -1;
    this.isGeneratorRemoved = this.generatorCapacity === -1;
    this.isSolarRemoved = this.solarCapacity === -1;
    this.isCustomSystem = this.systemType === SYSTEM_TYPE[ SYSTEM_TYPE.CUSTOM_SYSTEM] && this.projectGenerationDesignItems.length > 0;
    // this.isActive = this.finalizeDateTime;
    this.isHomer = this.sourceType === SourceType.Homer;
    this.isHomerCalculated = this.isHomer && this.latestHomerJobStatus === 'calculated';
    this.showCapex = this.checkCapex();
    this.quantityOfSystem = projectGenerationDesignInfo.quantityOfSystem;
    this.updated = projectGenerationDesignInfo.updated ? new Date(projectGenerationDesignInfo.updated) : undefined;
  }

  private checkCapex() {
    if (this.sourceType === SourceType.Homer) {
      let anyAreOptimized: boolean;
      let allAreVerified: boolean;

      anyAreOptimized = this.projectGenerationDesignItems.some((designItem: GenerationDesignItem) => {
        return designItem.sourceType === ExternalType.Optimized;
      });
      // technically !anyAreOptimized = allAreVerified, but let's be verbose about it especially since it is a new feature
      // and designItem.sourceType may not be up to date, especially on the list view of items not opened and saved.
      if (!anyAreOptimized) {
        allAreVerified = this.projectGenerationDesignItems.every((designItem: GenerationDesignItem) => {
          return designItem.sourceType === ExternalType.Verified;
        });
      }
      return anyAreOptimized && this.latestHomerJobStatus === 'calculated' || allAreVerified;
    } else {
      return true;
    }
  }
}
