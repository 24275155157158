import {Injectable} from '@angular/core';
import {OrganizationImage} from '@organization-management/organization/organization-image.model';
import { environment } from '@environments/environment';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeveloperProfileImageService extends BaseRestApiService {
  public organizationUri = environment.serverUri + `api/${environment.apiVersion}/vm/organizations`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(organizationId: string): Observable<OrganizationImage[]> {
    return this.get<OrganizationImage[]>(`${this.organizationUri}/${organizationId}/images`, {params: {page: 0, size: 1000}})
            .pipe(
              map(results => results.map(result => new OrganizationImage(result)))
            );
  }
}
