import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EvaluationService } from '../evaluation/evaluation.service';
import { take } from 'rxjs/operators';
import { Evaluation } from '../evaluation/evaluation.model';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EntityType } from '@program/program-detail/program-configuration/shared/evaluation-config/entity-type.enum';

@Component({
  selector: 'oes-evaluations-admin-all',
  templateUrl: './evaluations-admin-all.component.html',
  styleUrls: ['./evaluations-admin-all.component.scss']
})
export class EvaluationsAdminAllComponent implements OnInit {
  @Input() evaluatedEntityId: string;
  @Input() entityType: EntityType;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() title: string;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  criteriaDisplay: Evaluation[][];
  dropdownOptions: IMultiSelectOption[];
  evaluationsDisplay: Evaluation[][];
  formGroup: FormGroup;
  initialSelections: IMultiSelectOption[];

  constructor(private _evaluationService: EvaluationService,
              private _translateService: TranslateService) {
    this.setDropdownOptions();
  }

  private setDropdownOptions() {
    const options = this._translateService.instant('program.evaluation.admin.dropdown-options');
    this.dropdownOptions = Object.values(options).map((option, index) => {
      return {
        id: index,
        name: option
      };
    }) as IMultiSelectOption[];
  }

  ngOnInit() {
    this.findEvaluationsByEntityId();
    this.createFormGroup();
  }

  private createFormGroup() {
    this.formGroup = new FormGroup({
      groupBy: new FormControl(null, Validators.required),
    });
    this.setInitialSelections();

    this.formGroup.controls.groupBy.valueChanges.subscribe((groupBy) => {
      const groupByName = this.dropdownOptions[groupBy[0]].name;
      this.saveGroupByToLocalStorage(groupBy);
    });
  }

  emitCloseEvent() {
    this.close.next(true);
  }

  onPrint() {
    window.print();
  }

  private setInitialSelections() {
    const initial = this.getGroupByFromLocalStorage() || 0;
    this.initialSelections = [this.dropdownOptions[initial]];
  }

  private saveGroupByToLocalStorage(value: string) {
    localStorage.setItem('evaluation-group-by-' + this.evaluatedEntityId, value);
  }

  private getGroupByFromLocalStorage(): string {
    const value = localStorage.getItem('evaluation-group-by-' + this.evaluatedEntityId);
    return value ? value : this._translateService.instant('program.evaluation.admin.dropdown-options')[0];
  }

  private findEvaluationsByEntityId() {
    this._evaluationService.adminListByEvaluatedEntityId(this.evaluatedEntityId, this.entityType)
      .pipe(take(1))
      .subscribe((evaluations: Evaluation[]) => {
        this.setEvaluationsDisplay(evaluations);
        this.setCriteriaDisplay(evaluations);
      });
  }

  private setEvaluationsDisplay(evaluations: Evaluation[]) {
    const groupedEvals = evaluations.reduce((accum, evaluation) => {
      if (!accum[evaluation?.editorId]) {
        accum[evaluation?.editorId] = [];
      }
      accum[evaluation?.editorId].push(evaluation);
      return accum;
    }, {});
    const keys = Object.keys(groupedEvals);
    const display = keys.map(key => {
      return groupedEvals[key];
    });

    const sortedDisplay = [];
    display.forEach((d, index) => {
      const sorted = d.sort((a: Evaluation, b: Evaluation) => {
        if (a.evaluationConfig?.index > b.evaluationConfig?.index) {
          return 1;
        }
        return -1;
      });
      sortedDisplay.push(sorted);
    });

    this.evaluationsDisplay = sortedDisplay.sort((a: Evaluation[], b: Evaluation[]) => {
      if (a[0].editorName > b[0].editorName) {
        return 1;
      }
      return -1;
    });
  }

  private setCriteriaDisplay(evaluations: Evaluation[]) {
    const groupedEvals = evaluations.reduce((accum, evaluation) => {
      if (!accum[evaluation?.evaluationConfig.id]) {
        accum[evaluation?.evaluationConfig.id] = [];
      }
      accum[evaluation?.evaluationConfig.id].push(evaluation);
      return accum;
    }, {});
    const keys = Object.keys(groupedEvals);
    this.criteriaDisplay = keys.map(key => {
      return groupedEvals[key];
    }).map((evaluationGroup: Evaluation[]) => {
      return evaluationGroup.sort((a: Evaluation, b: Evaluation) => {
        if (a.editorName.toLowerCase() >= b.editorName.toLowerCase()) {
          return 1;
        }
        return -1;
      });
    }).sort((a: Evaluation[], b: Evaluation[]) => {
      if (a[0].evaluationConfig?.index > b[0].evaluationConfig?.index) {
        return 1;
      }
      return -1;
    });
  }
}

