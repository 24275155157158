import { SecondaryMenuCategory } from './secondary-menu-category.enum';

export class UrlCategory {
  url: string;
  category: SecondaryMenuCategory;

  constructor() {
    this.url = '';
    this.category = undefined;
  }
}
