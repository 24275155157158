export interface OpenState {
  id: string;
  expanded: boolean;
}

export class GridState {
  filter: Object;
  column: Array<any>;

  constructor(state?: GridState) {
    this.filter = state?.filter && Object.keys(state.filter).length > 0 ? state.filter : {};
    this.column = state?.column?.length > 0 ? state.column : [];
  }
}
