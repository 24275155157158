import { AfterViewChecked, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProgramProjectStageRules } from '@program/program-detail/program-configuration/program-stages/program-project-stage-rules.model';
import { ProjectConnectionInteraction, ProjectConnectionInteractionService, ProjectConnectionStatus } from '@project/detail/sharing/shared/project-connection-interaction.service';
import { ProjectConnection } from '@project/shared/project-connection.model';
import { ProjectConnectionService } from '@project/shared/project-connection.service';
import { Project } from '@project/shared/project.model';
import { Organization } from '@shared/models/organization/organization.model';
import { UtilityService } from '@shared/services/utility.service';
import { ROLE_TYPE } from '@user/role-type';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'oes-stage-overview',
  templateUrl: './stage-overview.component.html',
  styleUrls: ['./stage-overview.component.scss'],
  providers: [
  ]
})
export class StageOverviewComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('messageViewPort', {static: false}) private messageViewPort: ElementRef;
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();

  interactions: ProjectConnectionInteraction[];
  programOwner: boolean;
  projectInfo: Project;
  quillOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, { 'list': 'bullet' }],
      ['link']
    ]
  };
  receiver = '';
  recipient: Organization;
  role: string;
  scrollTop: number = null;
  selectedStage: ProgramProjectStageRules;
  sender = '';
  stages: ProgramProjectStageRules[];
  submitForm: UntypedFormGroup;
  user: User;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _projectStageOverviewService: ProjectConnectionInteractionService,
              private _userService: UserService,
              private _utilityService: UtilityService,
              private _projectConnectionService: ProjectConnectionService) {
    // this.role is used to select a key of this.options
    if (this._userService.getSelectedRole() === ROLE_TYPE.DEVELOPER_USER) {
      this.role = 'developer';
    } else if (this._userService.getSelectedRole() === ROLE_TYPE.FINANCE_USER) {
      this.role = 'investor';
    }

    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.user = new User(user);
    });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnInit() {
    this.getMessages();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private createForm() {
    this.submitForm = new UntypedFormGroup({
      projectStage: new UntypedFormControl(''),
      body: new UntypedFormControl('')
    });
  }

  private getMessages() {
    this._projectConnectionService.projectConnection$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((connection: ProjectConnection) => {
      if ((connection?.projectInfo?.program?.sponsor.id === connection?.recipient?.id) && (connection?.recipient?.id === this.user?.organization?.id)) {
        this.programOwner = true;
        this.stages = connection?.projectStages?.filter(stage => stage.name !== 'Submitted (initial)');
        } else {
          this.programOwner = false;
        }
        this.createForm();
      if (connection) {
        this._projectStageOverviewService.getProjectConnectionInteractions(connection)
        .pipe(take(1))
        .subscribe(response => {
          this.recipient = this._projectStageOverviewService.recipient;
          this.projectInfo = this._projectStageOverviewService.projectInfo;
          this.interactions = response;

          if (this.interactions) {
            this.interactions.sort((a, b) => {
              const dateA = a['sentDateTime'];
              const dateB = b['sentDateTime'];
              if (dateA < dateB) {return -1;};
              if (dateA > dateB) {return 1;};
              return 0;
            });
            this.scrollToBottom();
          }

          this.getSenderReceiver();
        });
      }
    });
  }

  private sendMessage(interaction: ProjectConnectionInteraction) {
    if (this.submitForm.valid) {
      this._projectStageOverviewService.sendProjectConnectionInteraction(interaction)
      .pipe(take(1))
      .subscribe((response) => {
        this.interactions.push({...response});
        this.submitForm.reset({
          projectStage: '',
          body: ''
        });
        this.scrollToBottom();
        this.reload.emit(true);
      });
    }
  }

  private scrollToBottom() {
    if (this.messageViewPort.nativeElement) {
      this.scrollTop = this.messageViewPort.nativeElement.scrollHeight;
    }
  }

  getSelectColor(): string {
    return this.stages?.find(stage => stage.name === this.submitForm?.controls['projectStage']?.value)?.color;
  }

  getTextColor(hexCode: string) {
    if (hexCode) {
      return this._utilityService.findTextColor(hexCode);
    }
  }

  onSelectStage(stage?: string) {
    if (stage) {
      this.submitForm.controls['projectStage'].setValue(stage);
    } else {
      this.submitForm.controls['projectStage'].setValue(null);
    }
  }

  submit() {
    const statusName = this.submitForm.controls['projectStage'].value;
    const body = this.submitForm.controls['body'].value.textControl;

    const interaction: ProjectConnectionInteraction = <ProjectConnectionInteraction>{};
    interaction.sentDateTime = new Date();
    interaction.message = body;
    if (statusName) {
      interaction.projectStage = statusName;
    }
    this.sendMessage(interaction);
  }

  private getSenderReceiver() {
    if (this.user && this.recipient && this.projectInfo) {
      if (this.user.organization.id !== this.recipient.id) {
        this.sender = this.user.organization.name;
        this.receiver = this.recipient.name;
      } else {
        this.sender = this.recipient.name;
        this.receiver = this.projectInfo.organization.name;
      }
    }
  }
}
