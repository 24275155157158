import { Evaluation } from '@program/shared/evaluation/evaluation.model';
import { ProjectMilestoneResponseObject } from '@project/detail/milestones/project-milestone-response-object.model';

export class SimpleProject {
  id: string;
  subsidyAmountPerConnection: number;
  totalConnectionsNotSubmitted: number;
  totalConnectionsEverSubmitted: number;
  totalConnectionsSubmitted: number;
  totalConnectionsApproved: number;
  totalConnectionsPaid: number;
  totalConnectionsRejected: number;
  totalConnectionsVerified: number;
  totalConnectionsVerifiedSubmitted: number;
  connectionsAmountPaid: number;
  connectionsAmountPaidInCurrency: number;
  connectionsAmountPotential: number;
  connectionsAmountPotentialInCurrency: number;
  projectPayoutCurrency: string;
  status: string;
  projectType: string;
  totalCustomers: number;
  totalCapex: number;
  totalPV: number;
  averageDSCR: number;
  peakKw: number;
  minimumDSCR: number;
  arpuPublic: number;
  equityPayback: number;
  averageOperatingMargin: number;
  capexPerConnection: number;
  avgDailyKwh: number;
  arpuAnchor: number;
  developer: string;
  developerId: string;
  arpuCommercial: number;
  arpuProductive: number;
  equityIRR: number;
  arpuResidential: number;
  name: string;
  country: string;
  lcoe: number;
  payback: number;
  irr: number;
  storageSize: number;
  generatorSize: number;
  cod: Date;
  projectStages: string;
  projectStageConnectionId: string;
  submittedToProgramDateTime: Date;
  alternateCurrency: string;

  program?: string;
  programId?: string;
  latitude?: number;
  longitude?: number;
  region?: string;
  milestones?: ProjectMilestoneResponseObject;

  evaluations: Evaluation[]; // Transient

  constructor(data: any) {
    this.id = data.id;
    this.subsidyAmountPerConnection = data.subsidyAmountPerConnection;
    this.totalConnectionsNotSubmitted = data.totalConnectionsNotSubmitted;
    this.totalConnectionsEverSubmitted = data.totalConnectionsEverSubmitted;
    this.totalConnectionsSubmitted = data.totalConnectionsSubmitted;
    this.totalConnectionsApproved = data.totalConnectionsApproved;
    this.totalConnectionsPaid = data.totalConnectionsPaid;
    this.totalConnectionsRejected = data.totalConnectionsRejected;
    this.totalConnectionsVerified = data.totalConnectionsVerified;
    this.totalConnectionsVerifiedSubmitted = data.totalConnectionsVerifiedSubmitted;
    this.connectionsAmountPaid = data.connectionsAmountPaid;
    this.connectionsAmountPaidInCurrency = data.connectionsAmountPaidInCurrency;
    this.connectionsAmountPotential = data.connectionsAmountPotential;
    this.connectionsAmountPotentialInCurrency = data.connectionsAmountPotentialInCurrency;
    this.projectPayoutCurrency = data.projectPayoutCurrency;
    this.status = data.status;
    this.payback = data.payback;
    this.irr = data.irr;
    this.projectType = data.projectType;
    this.totalCustomers = data.totalCustomers;
    this.totalCapex = data.totalCapex;
    this.totalPV = data.totalPV;
    this.averageDSCR = data.averageDSCR;
    this.peakKw = data.peakKw;
    this.minimumDSCR = data.minimumDSCR;
    this.arpuPublic = data.arpuPublic;
    this.equityPayback = data.equityPayback;
    this.averageOperatingMargin = data.averageOperatingMargin;
    this.lcoe = data.lcoe;
    this.capexPerConnection = data.capexPerConnection;
    this.avgDailyKwh = data.avgDailyKwh;
    this.arpuAnchor = data.arpuAnchor;
    this.developer = data.developer;
    this.developerId = data.developerId;
    this.arpuCommercial = data.arpuCommercial;
    this.arpuProductive = data.arpuProductive;
    this.equityIRR = data.equityIRR;
    this.arpuResidential = data.arpuResidential;
    this.program = data.program;
    this.programId = data.programId;
    this.name = data.name;
    this.country = data.country;
    this.storageSize = data.storageSize;
    this.generatorSize = data.generatorSize;
    this.cod = data.cod;
    this.projectStages = data.projectStages;
    this.projectStageConnectionId = data.projectStageConnectionId;
    this.submittedToProgramDateTime = data.submittedToProgramDateTime;
    this.alternateCurrency = data.alternateCurrency;

    this.region = data.region;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }
}
