<div class="d-flex flex-column justify-content-between generation-block">
  <div class="d-flex flex-column header">
    <img src="{{imageUrl}}"/>
    <div class="title">{{title}}</div>
  </div>
  <div class="d-flex flex-row">
    <div class="d-flex flex-column average">
      <div class="value">{{average | number:'1.0-0'}}</div>
      <div class="unit">{{averageUnit}}</div>
    </div>
    <div class="d-flex flex-column total">
      <div class="value">{{total | number:'1.0-0'}}</div>
      <div class="unit">{{totalUnit}}</div>
    </div>
  </div>
</div>
