import { AfterViewInit, Component } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { HttpParams } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { LoadItemLibraryService } from '@project/detail/load/customer-type-list/load-item-library.service';
import { LoadLibraryType } from '@project/detail/load/customer-type-list/customer-type-modal/choose-from-library/load-library-type.enum';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss']
})
export class MasterDetailComponent implements ICellRendererAngularComp, AfterViewInit {
  public gridOptions: GridOptions;
  public parentRecord: any;

  constructor(private _loadItemLibraryService: LoadItemLibraryService) {
    this.gridOptions = <GridOptions>{};
    // this.gridOptions.domLayout = 'autoHeight';
    this.gridOptions.columnDefs = this.createColumnDefs();
  }

  agInit(params: any): void {
    this.parentRecord = params.node.parent.data;
  }

  // Sometimes the gridReady event can fire before the angular component is ready to receive it, so in an angular
  // environment its safer to on you cannot safely rely on AfterViewInit instead before using the API
  ngAfterViewInit() {
    this.getLoadItems()
    .pipe(take(1))
    .subscribe(response => {
      if (response?.length > 0) {
        this.gridOptions.api.showLoadingOverlay();
        this.gridOptions.api.setRowData(response);
        this.gridOptions.api.sizeColumnsToFit();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    });
  }

  refresh(): boolean {
    return false;
  }

  private createColumnDefs(): any {
    return [
      { field: 'name' },
      { field: 'count' },
      {
        field: 'totalPower',
        valueFormatter: (params) => {
          return params.value + 'W';
        }
      },
      {
        field: 'weekdayAverageLoad',
        valueFormatter: (params) => {
          return params.value + 'Wh';
        }
      },
      {
        field: 'weekendAverageLoad',
        valueFormatter: (params) => {
          return params.value + 'Wh';
        }
      }
    ];
  }

  private getLoadItems() {
    let params = new HttpParams().set('loadItemGroupId', this.parentRecord.id);
    params = this.parentRecord.activeComponent === LoadLibraryType.organization ?
             params.set('shared', 'false') : params.set('shared', 'true');
    return this._loadItemLibraryService.list(params);
  }
}
