export enum ROLE_TYPE {
  USER = 'USER',
  FINANCE_USER = 'FINANCE_USER',
  VENDOR_USER = 'VENDOR_USER',
  DEVELOPER_USER = 'DEVELOPER_USER',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  DATA_ANALYST = 'DATA_ANALYST',
  COMMERCIAL_INVESTOR_DEMO_USER = 'COMMERCIAL_INVESTOR_DEMO_USER'
}
