import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-grid-cell-boolean-selector',
  templateUrl: './cell-boolean-selector.component.html',
  styleUrls: ['./cell-boolean-selector.component.scss']
})
export class CellBooleanSelectorComponent implements ICellEditorAngularComp {
  value: boolean;
  params: any;
  rowData: any;

  constructor() {}

  agInit(params): void {
    this.params = params;
    this.rowData = params.data;
    if (params.value !== null) {
      this.value = params.value;
    }
  }

  onClick(event) {
    this.params.context.gridService.cellButtonClicked({
      key: this.params.colDef.field,
      data: this.rowData
    });
  }

  getValue(): boolean {
    return this.value;
  }
}
