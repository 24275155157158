import { environment } from '@environments/environment';

export class FinancialModelApiConstants {
  public static projects = environment.serverUri + `api/${environment.apiVersion}/projects`;

  public static get = (projectId: string) => {
    return `${FinancialModelApiConstants.projects}/${projectId}/financial-models`;
  };
  public static save = (projectId: string, financialModelId: string) => {
    return `${FinancialModelApiConstants.projects}/${projectId}/financial-models/${financialModelId}`;
  };
  public static import = (projectId: string) => {
    return `${FinancialModelApiConstants.projects}/${projectId}/financial-models/import`;
  };
  public static export = (projectId: string) => {
    return `${FinancialModelApiConstants.projects}/${projectId}/financial-models/export`;
  };
  public static update = (projectId: string, financialModelId: string) => {
    return `${FinancialModelApiConstants.projects}/${projectId}/financial-models/${financialModelId}/update-model`;
  };
  public static reset = (projectId: string, financialModelId: string) => {
    return `${FinancialModelApiConstants.projects}/${projectId}/financial-models/${financialModelId}/reset-model`;
  };
}
