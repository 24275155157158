/**
 * @fileoverview added by tsickle
 * Generated from: types/alerts-position.ts
 * @suppress {checkTypes,constantProperty,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @enum {number} */
var AlertsPosition = {
  none: 0,
  top: 1,
  bottom: 2,
  both: 3
};
export { AlertsPosition };
AlertsPosition[AlertsPosition.none] = 'none';
AlertsPosition[AlertsPosition.top] = 'top';
AlertsPosition[AlertsPosition.bottom] = 'bottom';
AlertsPosition[AlertsPosition.both] = 'both';