import { Injectable } from '@angular/core';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { BaseAgGridSettingsService } from '@shared/ag-grid/base-ag-grid-settings.service';
import { AgGridServicesWrapperService } from '@shared/ag-grid/services-wrapper.service';
import { NumericEditorComponent } from '@shared/ag-grid/component/numeric-editor/numeric-editor.component';
import { GridState } from '@shared/ag-grid/gird-state';
import { CurrencyPipe } from '@angular/common';
import { ProjectService } from '@project/shared/project.service';


// Do not use global provider for this ag-grid
@Injectable()
export class BosGridSettingsService extends BaseAgGridSettingsService {
  private columnOption = [];
  public detailCellRendererParams = {};
  private column = [];
  private _costSourceStringList: string[];
  private _categoryStringList: string[];
  private _units: string[];
  private unitString = {};
  private categoryString = {};
  private costSourceString = {};
  private _readOnly = true;
  public currency: string = 'USD';
  public currentGridState: GridState = new GridState();
  public defaultGridState: GridState = new GridState();
  public errorRows = [];
  public initialGridState: GridState = new GridState();
  public symbol: string = 'symbol';
  public _gridStateStorageKey: string;
  public _gridApi: GridApi;
  public _columnApi: ColumnApi;

  constructor(_agGridServicesWrapperService: AgGridServicesWrapperService,
              private _currencyPipe: CurrencyPipe,
              private _projectService: ProjectService) {
    super(_agGridServicesWrapperService);
    this.column = _agGridServicesWrapperService._translateService.instant('generation-design-item.bos.column');
    this.unitString = _agGridServicesWrapperService._translateService.instant('generation-design-item.bos.options.unit');
    this.categoryString = _agGridServicesWrapperService._translateService.instant('generation-design-item.bos.options.category');
    this.costSourceString = _agGridServicesWrapperService._translateService.instant('generation-design-item.bos.options.cost-source');
    const { currency, symbol } = this._projectService.currencyInfo();
    this.currency = currency;
    this.symbol = symbol;
  }

  set costSourceStringList(costSources: any[]) {
    this._costSourceStringList = costSources.map(item => this.costSourceString[item.code]);
  }

  set categoryStringList(categories: any[]) {
    this._categoryStringList = categories.map(item => this.categoryString[item.id]);
  }

  set units(units: any[]) {
    this._units = units.map(item => this.unitString[item.code]);
  }

  set readOnly(status: boolean) {
    this._readOnly = status;
  }
  get readOnly(): boolean {
    return this._readOnly;
  }

  public getGridOptions(): GridOptions {
    return <GridOptions>{
      columnDefs: [], // we will use setColumnDefs() from component
      defaultColDef: {
        floatingFilter: true,
        sortable: true,
        filter: true,
        resizable: true
      },
      enableRangeSelection: true,
      localeText: this.globalTranslations,
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      enableCellChangeFlash: true,
      stopEditingWhenCellsLoseFocus: true,
      singleClickEdit: false,
      editType: 'fullRow',
      components: {
        numericEditor: NumericEditorComponent
      },
      suppressPropertyNamesCheck : true, // remove unnecessary warning
      alwaysShowStatusBar: false,
      getRowStyle: (params) => {
        if (this.errorRows.includes(params.node.rowIndex)) {
          return { border: '1px solid #B52514' };
        }
      },
    };
  }

  public getColumnOption() {
    const basicColumn: any[] = [
      {
        headerName: '* ' + this.column['category'],
        headerTooltip: this.column['category'],
        field: 'category',
        unSortIcon: true,
        width: 160,
        comparator: this.caseInsensitiveSort,
        // filter: 'agTextColumnFilter', // to enable drop-down
        filterParams: {
          clearButton: true
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this._categoryStringList
        },
        editable: (params) => {
          return !this._readOnly;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return this._readOnly;
          },
          'required': (params) => {
            return !params?.value;
          }
        }
      },
      {
        headerName: '* ' + this.column['cost-source'],
        headerTooltip: this.column['cost-source'],
        field: 'source.name',
        unSortIcon: true,
        width: 160,
        comparator: this.caseInsensitiveSort,
        // filter: 'agTextColumnFilter', // to enable drop-down
        filterParams: {
          clearButton: true
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this._costSourceStringList
        },
        editable: (params) => {
          return !this._readOnly;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return this._readOnly;
          },
          'required': (params) => {
            return !params?.value;
          }
        }
      },
      {
        headerName: '* ' + this.column['details'],
        headerTooltip: this.column['details'],
        field: 'description',
        unSortIcon: true,
        comparator: this.caseInsensitiveSort,
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return !this._readOnly;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return this._readOnly;
          },
          'required': (params) => {
            return !params?.value;
          }
        }
      },
      {
        headerName: '* ' + this.column['quantity'],
        headerTooltip: this.column['quantity'],
        field: 'count',
        cellClass: 'number',
        unSortIcon: true,
        width: 140,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return !this._readOnly;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return this._readOnly;
          },
          'required': (params) => {
            return !(params?.value >= 0);
          }
        },
        cellEditor: 'numericEditor'
      },
      {
        headerName: '* ' + this.column['unit-cost'],
        headerTooltip: this.column['unit-cost'],
        field: 'unitCost',
        cellClass: 'number',
        unSortIcon: true,
        width: 140,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return !this._readOnly;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return this._readOnly;
          },
          'required': (params) => {
            return !(params?.value >= 0);
          }
        },
        cellRenderer: (param) => {
          if (param && param.value) {
            return this._currencyPipe.transform(param.value, this.currency, this.symbol, '1.2-2');
          }
        }
      },
      {
        headerName: '* ' + this.column['unit'],
        headerTooltip: this.column['unit'],
        field: 'units',
        cellClass: 'number',
        width: 140,
        unSortIcon: true,
        comparator: this.caseInsensitiveSort,
        // filter: 'agTextColumnFilter', // to enable drop-down
        filterParams: {
          clearButton: true
        },
        editable: (params) => {
          return !this._readOnly;
        },
        cellClassRules: {
          'not-editable': (params) => {
            return this._readOnly;
          },
          'required': (params) => {
            return !params?.value;
          }
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: this._units
        },
      },
      {
        headerName: this.column['total-cost'],
        headerTooltip: this.column['total-cost'],
        field: 'totalCost',
        cellClass: ['number', 'not-editable'],
        unSortIcon: true,
        width: 140,
        filter: 'agNumberColumnFilter',
        editable: false,
        filterParams: {
          clearButton: true
        },
        cellRenderer: (param) => {
          if (param?.value && this.currency !== 'USD') {
            return this._currencyPipe.transform(param.value, this.currency, this.symbol, '1.2-2');
          }
          return this.formatCurrency(param, this.currentLang);
        }
      },
    ];

    if (!this._readOnly) {
      const checkbox = {
        headerClass: 'checkboxColumn',
        cellClass: 'checkboxCell',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        width: 30,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressFiltersToolPanel: true,
        suppressColumnsToolPanel: true,
        pinned: 'left'
      };
      basicColumn.unshift(checkbox);
    }

    return basicColumn;
  }

  private getTotal(param) {
    if (param && param.data.unitCost && param.data.count) {
      return this.formatCurrency({value: param.data.unitCost * param.data.count}, this.currentLang);
    }
  }
}
