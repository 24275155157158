import {BaseModel} from '@shared/base.model';

export class ProjectDistributionDesignSources extends BaseModel<ProjectDistributionDesignSources> {
  code: string;
  distributionDesignItemCategoryType: string;
  expenseType: string;
  name: string;

  constructor(projectDistributionDesignSourcesInfo: any) {
    super();
    this.id = projectDistributionDesignSourcesInfo.id;
    this.code = projectDistributionDesignSourcesInfo.code;
    this.distributionDesignItemCategoryType = projectDistributionDesignSourcesInfo.distributionDesignItemCategoryType;
    this.expenseType = projectDistributionDesignSourcesInfo.expenseType;
    this.name = projectDistributionDesignSourcesInfo.name;
  }
}
