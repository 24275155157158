import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EvaluationConfigApiConstants } from './evaluation-config.constant';
import { EvaluationConfig } from './evaluation-config.model';

@Injectable({
  providedIn: 'root'
})
export class EvaluationConfigService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _eventService: EventService,
              private _translateService: TranslateService) {
    super(_restApiWrapperService);
  }

  detail(evaluationConfigId: string): Observable<EvaluationConfig> {
    return this.get<EvaluationConfig>(EvaluationConfigApiConstants.evaluation.detail(evaluationConfigId))
      .pipe(
        map((ec: EvaluationConfig) => {
          if (ec) {
            return new EvaluationConfig(ec);
          }
          return null;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  create(evaluationConfig: EvaluationConfig) {
    return this.post(EvaluationConfigApiConstants.evaluation.create(), JSON.stringify(evaluationConfig))
      .pipe(
        map((ec: EvaluationConfig) => {
          return new EvaluationConfig(ec);
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }


  buildConfigRequest(configuredEntityId: string, publishedOnly: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('configuredEntityId', configuredEntityId);
    params = params.set('publishedOnly', publishedOnly);
    return this.list(params);
  }

  list(params: any): Observable<EvaluationConfig[]> {
    return this.get<EvaluationConfig[]>(EvaluationConfigApiConstants.evaluation.find(), { params: params })
      .pipe(
        map((ecs: EvaluationConfig[]) => {
          return ecs.map(ec => new EvaluationConfig(ec))
            .sort((a, b) => {
              if (a.index > b.index) {
                return 1;
              }
              return -1;
            });
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  update(evaluationConfig: EvaluationConfig): Observable<EvaluationConfig> {
    return this.put(EvaluationConfigApiConstants.evaluation.update(evaluationConfig.id), JSON.stringify(evaluationConfig))
      .pipe(
        map((ec: EvaluationConfig) => {
          return new EvaluationConfig(ec);
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  remove(evaluationConfigId: string): Observable<any> {
    return this.delete(EvaluationConfigApiConstants.evaluation.delete(evaluationConfigId));
  }

  publish(params: any): Observable<any> {
    return this.put(EvaluationConfigApiConstants.evaluation.publish(), null, { params: params });
  }

  unpublish(params: any): Observable<any> {
    return this.put(EvaluationConfigApiConstants.evaluation.unpublish(), null, { params: params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 504 || error.status === 0) {
      this._eventService.error(this._translateService.instant('program.configuration.evaluation.server-error'));
      return EMPTY;
    }
    return throwError(error);
  }
}
