
<oes-modal-dialog
  [title]="'data-room.dialogs.tag.title' | translate"
  [allowSubmit]="false"
  [cancelButtonText]="'buttons.close' | translate"
  (close)="hide()">
  <!-- the below goes to dialog-body -->
  <div [formGroup]="formGroup" class="add-form d-flex justify-content-center">
    <label for="add-tag-name">{{'data-room.dialogs.tag.label' | translate}}</label>
    <!-- TODO: autofocus makes a warning in console because this modal is not visible when Files is loaded -->
    <input 
      autofocus
      formControlName="tag"
      id="add-tag-name"
      autocomplete="off"
      (keydown)="onKeyDown($event)"
      list="tagList">
    <datalist id="tagList">
      <option *ngFor="let tag of tags" [value]="tag.tag">
        {{tag.tag}}
      </option>
    </datalist>
    <button
      class="btn btn-primary"
      (click)="add()"
      [disabled]="formGroup?.invalid">
      {{'buttons.add' | translate}}
    </button>
  </div>
</oes-modal-dialog>
