import {Injectable} from '@angular/core';
import {CurrencyExchange} from './currency-exchange.model';
import { environment } from '@environments/environment';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class CurrencyExchangeService extends BaseRestApiService {
  private currencyExchangeUri = environment.serverUri + `api/${environment.apiVersion}/currency-exchanges`;

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  public list(params: HttpParams): Observable<CurrencyExchange[]> {
    return this.get<CurrencyExchange[]>(this.currencyExchangeUri, {params: params})
            .pipe(
              map(results => results.map(result => new CurrencyExchange(result)))
            );
  }
}
