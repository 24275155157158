<div class="custom-system-component-type-wrap product-select" *ngIf="product">
  <ng-container *ngFor="let key of filteredKeys">
    <button *ngIf="showButton(key)"
            type="button" class="btn br-2 mr-2 mb-3"
            (click)="toggleSelection(key)"
            [ngClass]="{'selected': isSelected(key)}"
            [attr.disabled]="readonly ? '' : null">
      <img [src]="getImage(key)"><br>
      {{'product-select.' + toDashCase(key) | translate}}
    </button> 
  </ng-container>
</div>
