import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oes-submission-status',
  templateUrl: './submission-status.component.html',
  styleUrls: ['./submission-status.component.scss']
})
export class SubmissionStatusComponent implements INoRowsOverlayAngularComp {
  private status = {};
  public css = '';
  public name = '';

  constructor(private _translateService: TranslateService) {
    this.status = this._translateService.instant('project-connections.submission-status');
  }

  agInit(params): void {
    if (params.value) {
      const value = params.value;
      this.css = value;
      this.name = this.status[value];
    }
  }
}
