import { Component } from '@angular/core';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'oes-product-offering-filter',
  templateUrl: './product-offering-filter.component.html',
  styleUrls: ['./product-offering-filter.component.scss']
})
export class ProductOfferingFilterComponent implements IFloatingFilterAngularComp {
  public text = '';

  constructor() {
  }

  agInit(params) {
    const a = params;
  }

  onParentModelChanged(parentModel) {
    if (parentModel && parentModel.values && parentModel.values.length > 0 ) {
    }
  }
}
