import {BaseModel} from '@shared/base.model';

export class RevenueModel extends BaseModel<RevenueModel> {
  name: string;
  initialRevenue = 0;
  annualRevenue = 0;
  averageMonthlyRevenue = 0;
  baseCurrency: string;
  exchangeRate: number;
  alternateCurrency: string;

  constructor(revenueModelInfo: any) {
    super();
    this.id = revenueModelInfo.id;
    this.name = revenueModelInfo.name;
    if (revenueModelInfo.initialRevenue == null) {
      revenueModelInfo.initialRevenue = 0;
    }
    if (revenueModelInfo.annualRevenue == null) {
      revenueModelInfo.annualRevenue = 0;
    }
    if (revenueModelInfo.averageMonthlyRevenue == null) {
      revenueModelInfo.averageMonthlyRevenue = 0;
    }
    this.initialRevenue = revenueModelInfo.initialRevenue;
    this.annualRevenue = revenueModelInfo.annualRevenue;
    this.averageMonthlyRevenue = revenueModelInfo.averageMonthlyRevenue;
    this.baseCurrency = revenueModelInfo.baseCurrency;
    this.alternateCurrency = revenueModelInfo.alternateCurrency;
    this.exchangeRate = revenueModelInfo.exchangeRate;
  }
}
